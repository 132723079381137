import React from 'react';
import Lottie from 'react-lottie';

// animation Json
import * as cloudAnimation from 'assets/animations/cloud_animation.json';
import * as arrowAnimation from 'assets/animations/arrow_animation.json';
import * as graphAnimation from 'assets/animations/graphs_animation.json';

// animation SVGS
import shelf from 'assets/svg/shelf_svg_2.svg';
import manFile from 'assets/svg/man_file.svg';
import file from 'assets/svg/file.svg';

const Animation = () => {
  const cloudOptions = {
    loop: true,
    autoplay: true,
    animationData: cloudAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const arrowOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const graphOptions = {
    loop: true,
    autoplay: true,
    animationData: graphAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="animationWrapper">
      <div className="animationWrapperCentered">
        <div className="animationRow1">
          <div style={{ flex: '33.33%' }}>
            <img className="imageIcons shelfIcon" src={shelf}></img>
          </div>
          <div style={{ flex: '33.33%' }}>
            {' '}
            <Lottie
              options={arrowOptions}
              height={'3.5em'}
              width={'3.5em'}
              isStopped={false}
              className="cloudAnimation"
            />
          </div>
          <div style={{ flex: '33.33%' }}>
            <Lottie
              options={cloudOptions}
              height={'9em'}
              width={'9em'}
              isStopped={false}
              style={{}}
            />
          </div>
        </div>
        <div className="animationRow2">
          <Lottie
            options={arrowOptions}
            height={'3.5em'}
            width={'3.5em'}
            isStopped={false}
            style={{
              transform: 'rotate(90deg) translateX(-20px)',
              margin: '10px'
            }}
            className="cloudAnimation"
          />
        </div>
        <div className="analyticsIconWrapper">
          <img className="imageIcons analyticsIcon" src={manFile}></img>
        </div>
        <div className="animationRow3">
          <Lottie
            options={graphOptions}
            width="100%"
            height="90%"
            isStopped={false}
            className="cloudAnimation"
          />
        </div>
        <div className="manIconWrapper">
          <img className="imageIcons manIcon" src={file}></img>
        </div>
      </div>
    </div>
  );
};

export default Animation;

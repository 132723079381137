import React, { useState, useEffect } from 'react';
import { array, func, number, object, oneOfType, string } from 'prop-types';
import { isEmpty, map } from 'lodash';
import enums from 'models/enums';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Loader from 'components/Loader/Loader';
import Dropdown from 'components/Dropdown/Dropdown';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TableWithTooltip from 'table/TableWithTooltip';
import Typography from 'components/Typography/Typography';

const styles = {
  root: {
    maxWidth: 'calc(100vw - 18rem )',
    overflowX: 'scroll',
    display: 'flex'
  },
  toolbar: {
    marginBottom: '13px',
    marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0
  },
  imgWrapper: {},
  canvas: {
    maxHeight: 'calc(100vh - 100px)'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative'
  },
  cardHeaderContainer: {
    padding: '1.25rem 0.75rem !important',
    marginBottom: 20
  },
  title: {
    '& h4': {
      fontSize: 14
    }
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'absolute',
    left: '20px',
    height: 'inherit',
    '& div[class*="control"]': {
      borderColor: '#214aa0',
      // borderRadius: '50px',
      borderWidth: '2px',
      paddingLeft: '6px',
      paddingRight: '6px',
      '& span[class*="indicatorSeparator"]': {
        margin: '0px'
      }
    },
    '& div[class*="menu"]': {
      border: '2px solid #214aa0',
      backgroundColor: 'transparent',
      '& div[class*="MenuList"]': {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  currentAIVersionButton: {
    border: '2px solid #797983',
    padding: '8px',
    color: '#797983',
    '& h4': {
      fontSize: '12px'
    }
  },
  previousAIVersionButton: {
    border: '2px solid #488b97',
    padding: '8px',
    color: '#488b97',
    '& h4': {
      fontSize: '12px'
    }
  }
};

const TableWithTooltipWrapper = ({
  classes,
  width,
  height,
  tableData,
  granularitiesToRender,
  title,
  apiRequestStatus,
  handleGranularityChange,
  setTableTooltipStatus,
  tableTooltipStatus,
  tableName
}) => {
  const [dropdowns, setDropdowns] = useState({});

  useEffect(() => {
    if (!isEmpty(granularitiesToRender)) {
      let fl = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const gr of granularitiesToRender) {
        fl = { ...fl, [gr.key]: gr.value ? gr.value : {} };
      }
      setDropdowns(fl);
    }
  }, [granularitiesToRender]);

  const onDropdownChange = ({ data, granularity }) => {
    setDropdowns(prevState => {
      const payload = {};
      map(prevState, (gr, key) => {
        payload[key] = gr.value;
      });
      if (!isEmpty(payload)) {
        handleGranularityChange({
          grValues: { ...payload, [granularity.key]: data.value }
        });
      }
      return { ...prevState, [granularity.key]: data };
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card} chart>
          <CardHeader
            chart
            float="right"
            className={classes.cardHeaderContainer}
          >
            <div className={classes.cardHeader}>
              <div className={classes.filterContainer}>
                {granularitiesToRender?.map(gr => (
                  <div style={{ marginRight: '20px' }} key={gr.key}>
                    <Dropdown
                      key={gr.key}
                      title={gr.label}
                      width={140}
                      handleValueChange={data =>
                        onDropdownChange({ data, granularity: gr })
                      }
                      options={gr.options}
                      value={dropdowns[gr.key] || gr.value}
                      useMenuPortalTarget={false}
                    />
                  </div>
                ))}
              </div>
              <div className={classes.title}>
                <Typography color="#d2d5dc" weight={700}>
                  {title}
                </Typography>
              </div>
            </div>
          </CardHeader>
          <CardBody chart maxWidth="98%" height="auto">
            {apiRequestStatus.status === enums.apiRequest.REQUEST ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: height
                }}
              >
                <Loader color="primary" simple circular />
              </div>
            ) : !isEmpty(tableData) ? (
              <TableWithTooltip
                width={width}
                height={height}
                tableData={tableData}
                setTableTooltipStatus={setTableTooltipStatus}
                tableTooltipStatus={tableTooltipStatus}
                tableName={tableName}
              />
            ) : (
              <Typography center color="light25" size="xs">
                No data to display
              </Typography>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

TableWithTooltipWrapper.propTypes = {
  apiRequestStatus: object.isRequired,
  classes: object.isRequired,
  granularitiesToRender: array.isRequired,
  handleGranularityChange: func.isRequired,
  height: oneOfType([number, string]),
  setTableTooltipStatus: func.isRequired,
  tableData: object.isRequired,
  tableName: string.isRequired,
  tableTooltipStatus: object.isRequired,
  title: string.isRequired,
  width: string
};

TableWithTooltipWrapper.defaultProps = {
  width: '90%',
  height: '300px'
};

export default withStyles(styles)(TableWithTooltipWrapper);

import React from 'react';

// assets
import loginTopLeft from 'assets/svg/login_top_left.svg';
import loginTopRight from 'assets/svg/login_top_right.svg';
import loginBottom from 'assets/svg/login_bottom.svg';

// stylesheet
import './index.css';

function LoginVectorBackground() {
  return (
    <>
      <img
        src={loginTopLeft}
        alt="vector_background_top"
        height={'250'}
        style={{ position: 'absolute', top: '0', left: 15 }}
      ></img>
      <img
        src={loginTopRight}
        alt="vector_background_right"
        height={'200'}
        style={{ position: 'absolute', top: '0', right: 0 }}
      ></img>
      <img
        src={loginBottom}
        alt="vector_background_bottom"
        height={'250'}
        style={{ position: 'absolute', bottom: '0', right: 0 }}
      ></img>
    </>
  );
}

export default LoginVectorBackground;

import React, { useReducer, useState } from 'react';
import { map, each } from 'lodash';
import PropTypes from 'prop-types';
// gtag manager utils
import getDataLayer from 'utils/gtagManager';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from 'components/Typography/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import ls from 'lib/core/storageFactory';

import Photos from './Photos';
import SKUTable from '../SKUTable/SKUTable';

const styles = {
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  marginLeft: {
    marginLeft: 'auto'
  },
  checkBoxGroup: {
    height: 40,
    marginRight: 5,
    marginBottom: 10,
    paddingRight: 10,
    '& h4': {
      fontSize: 15,
      fontWeight: 400
    }
  }
};

const initialState = {
  activeBoxes: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ACTIVE_BOXES':
      return { ...state, activeBoxes: action.payload };
    default:
      return state;
  }
};
let selectedRows = {};

const availableCheckboxes = [
  { name: 'displayExclusions', title: 'Show exclusions' }
];

// eslint-disable-next-line react/prefer-stateless-function
const PhotosContainer = ({
  classes,
  photosList,
  tableData,
  useDisplayTag,
  useExport,
  handleDataDownload
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [checkBoxState, setCheckBoxState] = useState({});

  const handleCheckBoxClick = ({
    row,
    columns,
    isChecked,
    i: index,
    selectAll = false
  }) => {
    const dataLayer = getDataLayer();
    if (isChecked) {
      if (selectAll) {
        each(row, (r, i) => {
          selectedRows[`${i}t`] = r.bnBox.map(re => ({
            ...re,
            rowID: i,
            rowDetails: { ...r, bnBox: null },
            columns
          }));
        });
        if (dataLayer !== null) {
          dataLayer.push({
            event: 'checkbox_change',
            brand: 'all',
            category: 'all',
            full_name: 'all'
          });
        }
      } else {
        selectedRows[`${index}t`] = row.bnBox.map(re => ({
          ...re,
          rowDetails: { ...row, bnBox: null },
          columns,
          rowID: index
        }));

        if (dataLayer !== null) {
          dataLayer.push({
            event: 'checkbox_change',
            brand: row.brand,
            category: row.category,
            full_name: row.full_name
          });
        }
      }
    } else if (selectAll) {
      selectedRows = {};
    } else {
      delete selectedRows[`${index}t`];
    }
    let boxesCoords = [];
    map(selectedRows, r => {
      boxesCoords = [...boxesCoords, ...r];
    });
    dispatch({ type: 'UPDATE_ACTIVE_BOXES', payload: boxesCoords });
  };

  const useExclusionsCheckBox = ls.get('client')?.includes('itc');

  const handleCheckBoxChange = checkBox => {
    const currentState = checkBoxState[checkBox.name];
    if (
      checkBox.name === 'displayBrand' ||
      checkBox.name === 'displaySegment'
    ) {
      let boxesCoords = [];
      if (!currentState) {
        tableData.datasets.forEach(set => {
          boxesCoords = [...boxesCoords, ...set.bnBox];
        });
      }
      dispatch({ type: 'UPDATE_ACTIVE_BOXES', payload: boxesCoords });
    }
    setCheckBoxState(p => ({
      ...p,
      [checkBox.name]: !p[checkBox.name]
    }));
  };

  return Object.keys(photosList).length > 0 ? (
    <>
      <Photos
        useDisplayTag={useDisplayTag}
        checkBoxState={checkBoxState}
        list={photosList}
        activeBoxes={state.activeBoxes}
      />
      {useExclusionsCheckBox && (
        <>
          {availableCheckboxes.map(checkBox => (
            <div
              key={checkBox.name}
              className={`${classes.flex} ${classes.checkBoxGroup}`}
            >
              <Checkbox
                key={checkBox.name}
                color="secondary"
                checked={checkBoxState[checkBox.name] || false}
                onChange={() => handleCheckBoxChange(checkBox)}
              />
              <Typography>{checkBox.title}</Typography>
            </div>
          ))}
        </>
      )}
      <SKUTable
        useExport={useExport}
        handleDataDownload={handleDataDownload}
        tableData={tableData}
        handleRowClick={() => {}}
        onCheckBoxClick={handleCheckBoxClick}
      />
    </>
  ) : null;
};

PhotosContainer.propTypes = {
  useExport: PropTypes.bool,
  useDisplayTag: PropTypes.bool,
  photosList: PropTypes.array.isRequired,
  tableData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleDataDownload: PropTypes.func
};

PhotosContainer.defaultProps = {
  useExport: false,
  useDisplayTag: false,
  handleDataDownload: () => {}
};

export default withStyles(styles)(PhotosContainer);

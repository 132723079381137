import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';

import { connect } from 'react-redux';
import { appSelector } from 'redux-utils/selectors/index';
import { appActions } from 'redux-utils/actions/index';

import withStyles from '@material-ui/core/styles/withStyles';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
import PowerBiWrapper from './PowerBIEmbed';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  analyticsRedirectButton: {
    background: theme.palette.type === 'light' ? '#fdbd32' : 'inherit',
    color: theme.palette.type === 'light' ? '#0f0d36' : 'inherit',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#fdbd32' : '#inherit',
      color: theme.palette.type === 'light' ? '#0f0d36' : 'inherit'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#fdbd32' : 'inherit',
      color: theme.palette.type === 'light' ? '#0f0d36' : 'inherit'
    }
  },
  downloadButton: {
    marginTop:
      theme.palette.type === 'light' ? '0px!important' : '30px!important',
    marginLeft: '20px!important',
    background: theme.palette.type === 'light' ? '#18c7fe' : '#27293d',
    color: theme.palette.type === 'light' && 'white',
    height: '36px',
    boxShadow: 'none !important',
    padding: '8px !important',
    border: theme.palette.type === 'light' ? 'none' : '1px solid #2b3553',
    borderRadius: '4px',
    textTransform: 'capitalize !important',
    marginBottom: '0px',
    marginTop: '10px',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white'
    }
  }
});

const TableauRedirect = ({
  analyticsRedirect,
  classes,
  dispatchAnalyticsRedirect,
  layoutCSS
}) => {
  const [hasError, setError] = useState({ state: false, message: '' });
  const [isAllowRedirect, setAllowRedirect] = useState(false);

  useEffect(() => {
    dispatchAnalyticsRedirect({ cacheRequest: false });
  }, [dispatchAnalyticsRedirect]);

  useEffect(() => {
    if (!isAllowRedirect) {
      openAnalyticsDashboard(false);
    }
  }, [analyticsRedirect]);

  useEffect(() => {
    const { data: redirect, request } = analyticsRedirect;

    if (isAllowRedirect && !request && redirect.dashboardType === 'tableau') {
      window.location.replace(redirect.redirect_url);
    }
  }, [analyticsRedirect.request]);

  const openAnalyticsDashboard = (allowRedirect = true) => {
    setAllowRedirect(allowRedirect);
    if (allowRedirect) {
      dispatchAnalyticsRedirect({ cacheRequest: false });
    }
    const { data: redirect, error, request } = analyticsRedirect;
    if (error) {
      setError({
        state: true,
        message: 'Internal server error. Please contact admin'
      });
    } else if (Object.keys(redirect).length > 0) {
      if (redirect.has_access) {
        if (redirect.redirect_url && redirect.redirect_url?.length === 0) {
          setError({
            state: true,
            message:
              'Internal server error. Redirection URL not available. Please contact admin'
          });
        } else if (redirect.embed_url && redirect.embed_url?.length === 0) {
          setError({
            state: true,
            message:
              'Internal server error. Embed URL not available. Please contact admin'
          });
        }
      } else {
        setError({
          state: true,
          message: redirect.error_message
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      {analyticsRedirect.request || false ? (
        <Loader plain circular color="secondary" inline />
      ) : analyticsRedirect.data?.dashboardType === 'tableau' ? (
        <>
          {hasError.state ? (
            <Typography align="center">{hasError.message}</Typography>
          ) : (
            <Typography align="center">
              Click the button below to open the analytics dashboard
            </Typography>
          )}
          <VSpacer amount={20} />
          <Button
            className={classes.downloadButton}
            color="gray"
            onClick={() => openAnalyticsDashboard(true)}
          >
            Go to Analytics
          </Button>
        </>
      ) : analyticsRedirect.data?.dashboardType === 'powerBI' &&
        analyticsRedirect.data?.embed_url ? (
        <>
          <PowerBiWrapper
            // height="100vh"
            width="98vw"
            accessToken={analyticsRedirect.data?.accessToken}
            embedURL={analyticsRedirect.data?.embed_url}
          />
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  analyticsRedirect: appSelector.getAnalyticsRedirect(state),
  layoutCSS: appSelector.getLayoutCSS(state)
});

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchAnalyticsRedirect: payload =>
    dispatch(appActions.analyticsRedirectAction(payload))
});

TableauRedirect.propTypes = {
  analyticsRedirect: object.isRequired,
  classes: object.isRequired,
  dispatchAnalyticsRedirect: func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TableauRedirect));

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { array, number, bool, object, func } from 'prop-types';
import useImage from 'use-image';
import { Image, Rect, Label, Tag, Text, Line } from 'react-konva';

import arrowRight from 'assets/svg/arrow_right.svg';
import arrowLeft from 'assets/svg/arrow_left.svg';
import arrowUp from 'assets/svg/arrow_up.svg';
import arrowDown from 'assets/svg/arrow_down.svg';
import ls from 'lib/core/storageFactory';

const DEFAULT_COLOR = '#44ec09';

let email = '';
if (ls.get('authProfile')) {
  email = ls.get('authProfile').email;
}
const scaledImageUsers = ['roma@infilect.com'];

const directionArrow = {
  top: arrowUp,
  up: arrowUp,
  down: arrowDown,
  bottom: arrowDown,
  left: arrowLeft,
  right: arrowRight
};

const Arrow = ({ arrow, type, scaleDownFactor, ...rest }) => {
  const [image] = useImage(arrow);
  return (
    (image && (
      <>
        <Image {...rest} image={image} />
      </>
    )) ||
    null
  );
};

const getRelativePoints = ({ image, boundary, offsetX, type = 'box' }) => {
  if (type === 'box') {
    return {
      left: (image.eye_level.left / image.width) * image.canvasWidth + offsetX,
      right:
        (image.eye_level.right / image.width) * image.canvasWidth + offsetX,
      top: (image.eye_level.top / image.height) * image.canvasHeight,
      bottom: (image.eye_level.bottom / image.height) * image.canvasHeight
    };
  }
  if (boundary) {
    const polygon = boundary.coords.split(',').map((point, i) => {
      if (i === 0 || i % 2 === 0) {
        return (point / image.width) * image.canvasWidth + offsetX;
      }
      return (point / image.height) * image.canvasHeight;
    });
    const start = { x: polygon[0], y: polygon[1] };
    return { polygon, ...start };
  }
  return null;
};

const ImageNode = ({
  nkey,
  image,
  displayEyeLevel,
  showDisplays,
  colors,
  onClick,
  scaleDownFactor,
  type,
  x,
  ...rest
}) => {
  const [imageObject] = useImage(image.path);

  return (
    (image && (
      <>
        <Image {...rest} x={x} image={imageObject} />
        <Rect
          stroke={type === 'shelf' ? colors.shelf : colors.display}
          strokeWidth={scaleDownFactor === 1 ? 3 : 1}
          x={x}
          {...rest}
        />
        {showDisplays &&
          image.displays.map(boundary => {
            const relativePoints = getRelativePoints({
              image,
              boundary,
              offsetX: x,
              type: 'polygon'
            });
            return (
              <Line
                onClick={() => onClick(boundary)}
                closed
                points={[...relativePoints.polygon]}
                strokeWidth={3}
                stroke={DEFAULT_COLOR}
              />
            );
          })}
      </>
    )) ||
    null
  );
};

ImageNode.propTypes = {
  image: object.isRequired,
  showDisplays: bool,
  displayEyeLevel: bool,
  x: number.isRequired
};

ImageNode.defaultProps = {
  displayEyeLevel: false,
  showDisplays: false
};

const StitchedImage = ({
  checkBoxState,
  photoList,
  scaleDownFactor,
  handleBoundaryClick,
  colors,
  nativeProps
}) => {
  let nextXPosition = 0;
  return (
    photoList &&
    photoList.map((image, i) => {
      const item = i > 0 ? photoList[i] : image;
      const photoType = image.type || 'shelf';
      const direction = item.direction ? item.direction.toLowerCase() : '';
      let displays = [];
      if (item.displays) {
        displays = [...item.displays];
      }
      const Node = (
        <>
          <ImageNode
            {...nativeProps}
            key={item.id * i - scaleDownFactor}
            colors={colors}
            url={scaledImageUsers.includes(email) ? item.scaled_img : item.path}
            onClick={handleBoundaryClick}
            image={item}
            type={photoType}
            x={nextXPosition}
            y={0}
            scaleDownFactor={scaleDownFactor}
            width={item.canvasWidth / scaleDownFactor}
            height={item.canvasHeight / scaleDownFactor}
            showDisplays={checkBoxState.displayPOSM}
          />
          {scaleDownFactor === 1
            ? displays.map((display, index) => (
                <>
                  <Label
                    x={nextXPosition + 50}
                    y={
                      item.canvasHeight / scaleDownFactor +
                      10 +
                      (index === 0 ? 0 : index * 20 * 2)
                    }
                  >
                    <Tag fill="#ffffff" />
                    <Text
                      padding={4}
                      text={`${index + 1}. Display: ${display.type},
    Brand: ${display.brand}`}
                      fontSize={13}
                      strokeWidth={1}
                      stroke="#000"
                      fill="#000"
                    />
                  </Label>
                </>
              ))
            : null}

          {scaleDownFactor === 1 ? (
            <>
              <Rect
                x={nextXPosition}
                y={item.canvasHeight / scaleDownFactor + 10}
                width={30}
                height={30}
                fill="#5b773c"
              />
              <Arrow
                arrow={directionArrow[direction]}
                x={nextXPosition + 5}
                y={item.canvasHeight / scaleDownFactor + 15}
                width={20}
                height={20}
              />
            </>
          ) : null}
        </>
      );
      nextXPosition += item.canvasWidth / scaleDownFactor;
      return Node;
    })
  );
};

StitchedImage.propTypes = {
  photoList: array.isRequired,
  scaleDownFactor: number,
  useDisplayTag: bool,
  nativeProps: object,
  checkBoxState: object,
  handleBoundaryClick: func
};

StitchedImage.defaultProps = {
  scaleDownFactor: 1,
  nativeProps: {},
  useDisplayTag: false,
  checkBoxState: { showDisplays: false },
  handleBoundaryClick: () => {}
};

export default StitchedImage;

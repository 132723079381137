/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { func, object, number, bool, string } from 'prop-types';
import { isEmpty } from 'lodash';
// redux-utils
import { connect } from 'react-redux';
import { adminActions } from 'redux-utils/actions/index';
import { adminSelector } from 'redux-utils/selectors/index';
// components
import Typography from 'components/Typography/Typography';
// material ui
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// utils
import { hasPermission } from '../utils';
import { PermissionsEnum } from '../enums';
// views
import DashboardUsersPage from './DashboardUsersPage';

const DashboardUsersContainer = ({
  getSubPanelPageData,
  postSubPanelPageData,
  transactionData,
  downloadAllTemplates,
  downloadExcel,
  uploadExcel,
  packshotUploadData,
  excelUploadData,
  apiRequestStatus,
  pageData,
  pageTotal,
  allTemplatesData,
  excelData,
  stopTaskPoll,
  startTaskPoll,
  updateSidePanel,
  pollTask,
  menuProps,
  loaderStatus,
  dashboardType
}) => {
  const [hasDownloadedExcelFile, setHasDownloadedExcelFile] = useState(
    !isEmpty(excelData)
  );
  const [
    hasDownloadedAllTemplatesFile,
    setHasDownloadedAllTemplatesFile
  ] = useState(!isEmpty(allTemplatesData));

  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  // const getPageData = () =>
  //   hasPermission(menuProps.permissions, PermissionsEnum.read) &&
  //   getSubPanelPageData({
  //     params: { subpanel: menuProps.name }
  //   });

  const getPageData = ({ offset = 0, limit = menuProps.row_limit, ...rest }) =>
    hasPermission(menuProps.permissions, PermissionsEnum.read) &&
    getSubPanelPageData({
      params: { subpanel: menuProps.name, offset, limit, ...rest }
    });

  const getSearchData = (
    col,
    searchValue,
    offset = 0,
    limit = menuProps.row_limit
  ) =>
    hasPermission(menuProps.permissions, PermissionsEnum.read) &&
    getPageData({ offset, limit, [col]: searchValue });

  useEffect(() => {
    if (
      menuProps.status !== undefined &&
      menuProps.status !== null &&
      menuProps.status.length > 0 &&
      menuProps.status.toLowerCase() === 'processing' &&
      !apiRequestStatus.pollTask.start &&
      !apiRequestStatus.pollTask.stop
    ) {
      startTaskPoll({
        params: { task_id: menuProps.task_id },
        other: { type: 'excelUpload', subpanel: menuProps.name }
      });
    } else {
      getPageData({});
    }
  }, [menuProps, startTaskPoll]);

  const stopPoll = () =>
    stopTaskPoll({
      params: { task_id: packshotUploadData.task_id },
      other: { type: 'packshotUpload', subpanel: menuProps.name }
    });

  useEffect(() => {
    if (
      apiRequestStatus.uploadPackshot.request &&
      apiRequestStatus.uploadPackshot.success &&
      !apiRequestStatus.pollTask.start
    ) {
      startTaskPoll({
        params: { task_id: packshotUploadData.task_id },
        other: { type: 'packshotUpload', subpanel: menuProps.name }
      });
    }
    return () => {};
  }, [apiRequestStatus.uploadPackshot, startTaskPoll, packshotUploadData]);

  useEffect(() => {
    if (
      apiRequestStatus.uploadExcel.request &&
      apiRequestStatus.uploadExcel.success &&
      !apiRequestStatus.pollTask.start
    ) {
      startTaskPoll({
        params: { task_id: excelUploadData.task_id },
        other: { type: 'excelUpload', subpanel: menuProps.name }
      });
    }
    return () => {};
  }, [apiRequestStatus.uploadExcel, startTaskPoll, excelUploadData]);

  useEffect(() => {
    if (
      apiRequestStatus.postSubPanelPageData.request &&
      apiRequestStatus.postSubPanelPageData.success &&
      !apiRequestStatus.pollTask.start
    ) {
      startTaskPoll({
        params: { task_id: transactionData.task_id },
        other: { type: 'transaction', subpanel: menuProps.name }
      });
    }
    return () => {};
  }, [apiRequestStatus.postSubPanelPageData, startTaskPoll, transactionData]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      (pollTask.task.type === 'packshotUpload' ||
        pollTask.task.type === 'transaction' ||
        pollTask.task.type === 'excelUpload')
    ) {
      if (apiRequestStatus.pollTask.error) {
        stopPoll();
      }
      if (apiRequestStatus.pollTask.success) {
        if (pollTask.data.result.status.toLowerCase() !== 'pending') {
          stopPoll();
          if (
            hasPermission(menuProps.permissions, PermissionsEnum.read) &&
            pollTask.data.result.status.toLowerCase() !== 'failed'
          ) {
            getPageData({});
          }
        }
      }
    }
    return () => {};
  }, [apiRequestStatus, stopTaskPoll]);

  const saveTransaction = payload => {
    postSubPanelPageData({
      params: payload,
      other: { subpanel: menuProps.name, method: 'POST' }
    });
  };

  const handleDownloadAllTemplates = () => {
    downloadAllTemplates({ params: { subpanel: 'all' } });
    setHasDownloadedAllTemplatesFile(false);
  };

  const handleDownloadExcel = () => {
    downloadExcel({ params: { subpanel: menuProps.name, file_type: 'excel' } });
    setHasDownloadedExcelFile(false);
  };

  if (!hasDownloadedExcelFile && !isEmpty(excelData)) {
    const { url } = excelData;
    if (url && url !== '') {
      const a = document.createElement('a');
      a.href = url;
      a.click();
      setHasDownloadedExcelFile(true);
    }
  }

  // show snackbar when there is no url present after user clicks on download excel
  useEffect(() => {
    if (excelData && !excelData.url && apiRequestStatus.downloadExcel.request) {
      setSnackBarState({
        isOpen: true,
        message: excelData.message,
        severity: 'error',
        duration: 4000
      });
    }
  }, [excelData]);

  if (!hasDownloadedAllTemplatesFile && !isEmpty(allTemplatesData)) {
    const { url } = allTemplatesData;
    if (url && url !== '') {
      const a = document.createElement('a');
      a.href = url;
      a.click();
      setHasDownloadedAllTemplatesFile(true);
    }
  }

  // show snackbar when there is no url present after user clicks on download all templates
  useEffect(() => {
    if (
      allTemplatesData &&
      !allTemplatesData.url &&
      apiRequestStatus.downloadAllTemplates.request
    ) {
      setSnackBarState({
        isOpen: true,
        message: allTemplatesData.message,
        severity: 'error',
        duration: 4000
      });
    }
  }, [allTemplatesData]);

  const getProcessingMessage = () => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'excelUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        updateSidePanel({
          params: { status: 'failed' },
          other: { subpanel: menuProps.name }
        });
        return (
          <Typography align="center" color="#f36">
            {apiRequestStatus.pollTask.message ||
              `Error happened while processing the data. Please upload the file
            again`}
          </Typography>
        );
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          updateSidePanel({
            params: { status: 'success' },
            other: { subpanel: menuProps.name }
          });
          return (
            <Typography align="center" color="#afa">
              Processed successfully
            </Typography>
          );
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          updateSidePanel({
            params: { status: 'failed' },
            other: { subpanel: menuProps.name }
          });
          return (
            <Typography align="center" color="#f36">
              {pollTask.data.result.detail}
            </Typography>
          );
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          return (
            <Typography align="center" color="rgba(255, 255, 255, 0.8)">
              {pollTask.data.result.detail}
            </Typography>
          );
        }
      }
      return (
        <Typography align="center" color="rgba(255, 255, 255, 0.8)">
          Processing your data. Please wait...
        </Typography>
      );
    }
    return (
      <Typography align="center" color="rgba(255, 255, 255, 0.8)">
        Processing your data. Please wait...
      </Typography>
    );
  };

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      {hasPermission(menuProps.permissions, PermissionsEnum.read) ? (
        menuProps.status.toLowerCase() === 'processing' ? (
          <>
            <Typography>{menuProps.title}</Typography>
            {getProcessingMessage()}
          </>
        ) : (
          <DashboardUsersPage
            pageData={pageData}
            pageTotal={pageTotal}
            getPageData={getPageData}
            getSearchData={getSearchData}
            menuProps={menuProps}
            downloadAllTemplates={handleDownloadAllTemplates}
            downloadExcel={handleDownloadExcel}
            handleExcelUpload={uploadExcel}
            saveTransaction={saveTransaction}
            packshotUpload={packshotUploadData}
            excelUploadData={excelUploadData}
            transactionData={transactionData}
            apiRequestStatus={apiRequestStatus}
            pollTask={pollTask}
            loaderStatus={loaderStatus}
            dashboardType={dashboardType}
          />
        )
      ) : (
        <>
          <Typography>{menuProps.title}</Typography>
          <Typography align="center" color="rgba(255,255,255,0.8)">
            {`You don't have enough permissions to view this content. Please contact
          administrator`}
          </Typography>
        </>
      )}
    </>
  );
};

DashboardUsersContainer.propTypes = {
  getSubPanelPageData: func.isRequired,
  postSubPanelPageData: func.isRequired,
  downloadAllTemplates: func.isRequired,
  downloadExcel: func.isRequired,
  uploadExcel: func.isRequired,
  stopTaskPoll: func.isRequired,
  startTaskPoll: func.isRequired,
  updateSidePanel: func.isRequired,
  pageData: object,
  pageTotal: number.isRequired,
  transactionData: object,
  allTemplatesData: object,
  excelData: object,
  excelUploadData: object,
  packshotUploadData: object,
  pollTask: object,
  menuProps: object.isRequired,
  apiRequestStatus: object.isRequired,
  loaderStatus: bool.isRequired,
  dashboardType: string.isRequired
};

DashboardUsersContainer.defaultProps = {
  pageData: undefined,
  allTemplatesData: undefined,
  excelData: undefined,
  excelUploadData: undefined,
  packshotUploadData: {},
  transactionData: {},
  pollTask: {}
};

const makeMapStateToProps = () => {
  const selectLoaderStatus = adminSelector.makeSelectLoaderStatus();
  const mapStateToProps = state => ({
    pageTotal: adminSelector.selectPageTotal(state),
    pageData: adminSelector.selectSubPanelPageData(state),
    allTemplatesData: adminSelector.selectDownloadAllTemplates(state),
    excelData: adminSelector.selectDownloadExcel(state),
    packshotUploadData: adminSelector.selectUploadPackshot(state),
    excelUploadData: adminSelector.selectUploadExcel(state),
    apiRequestStatus: adminSelector.selectApiRequestStatus(state),
    transactionData: adminSelector.selectTransactionData(state),
    pollTask: adminSelector.selectPollTask(state),
    loaderStatus: selectLoaderStatus(state),
    dashboardType: state.layoutStore.routes?.dashboardConfig?.version
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  updateSidePanel: payload => dispatch(adminActions.updateSidePanel(payload)),
  getSubPanelPageData: payload =>
    dispatch(adminActions.getSubPanelPageData(payload)),
  postSubPanelPageData: payload =>
    dispatch(adminActions.postSubPanelPageData(payload)),
  downloadAllTemplates: payload =>
    dispatch(adminActions.downloadAllTemplates(payload)),
  downloadExcel: payload => dispatch(adminActions.downloadExcel(payload)),
  uploadExcel: payload => dispatch(adminActions.uploadExcelAction(payload)),
  startTaskPoll: payload => dispatch(adminActions.startPollTaskAction(payload)),
  stopTaskPoll: payload => dispatch(adminActions.stopPollTaskAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DashboardUsersContainer);

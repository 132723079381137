import { createAction } from '@reduxjs/toolkit';
import { adminTypes } from 'redux-utils/types/index';
import reducerModel from 'models/reducerModel';

const routeChangeAction = payload =>
  createAction(adminTypes.routeChange)(payload);

const updateSidePanel = payload =>
  createAction(adminTypes.updateSidePanel)(payload);

const getSubPanelAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.getSubPanel.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getSubPanelPageData = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.getSubPanelPageData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const postSubPanelPageData = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.postSubPanelPageData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadAllTemplates = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.downloadAllTemplates.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadTemplate = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.downloadTemplate.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadExcel = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.downloadExcel.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadPackshots = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.downloadPackshots.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const uploadPackshotAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.uploadPackshot.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const uploadExcelAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.uploadExcel.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const uploadReferenceImagesAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.uploadReferenceImages.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getStickerValuesAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.getStickerValues.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getDisplayValuesAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.getDisplayValues.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const startPollTaskAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.pollTask.start)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const stopPollTaskAction = (
  payload,
  meta = { reducerID: [reducerModel.admin.id] }
) => {
  const action = createAction(adminTypes.pollTask.stop)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  getSubPanelAction,
  getSubPanelPageData,
  postSubPanelPageData,
  downloadAllTemplates,
  downloadTemplate,
  downloadExcel,
  downloadPackshots,
  routeChangeAction,
  updateSidePanel,
  uploadPackshotAction,
  uploadReferenceImagesAction,
  uploadExcelAction,
  getStickerValuesAction,
  getDisplayValuesAction,
  startPollTaskAction,
  stopPollTaskAction
};

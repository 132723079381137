import React, { useCallback, useEffect, useState } from 'react';
import { array, func, object } from 'prop-types';
import { isEmpty, map } from 'lodash';
// redux-utils
import { connect } from 'react-redux';
import { merchActions } from 'redux-utils/actions/index';
import { merchSelector, appSelector } from 'redux-utils/selectors/index';
// material ui core components
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// utils
import { getRouteID } from 'lib/utils/getActiveRoute';
import enums from 'models/enums';
import { getFilterValue } from 'utils/filters';

import MerchEvaluationPage from './MerchEvaluationPage';

const MerchEvaluationContainer = ({
  apiRequestStatus,
  configuredFilters,
  cumulativeCards,
  dispatchFilterChange,
  dispatchGetCumulativeCards,
  dispatchGetFilterList,
  dispatchGetPageComponents,
  dispatchGetPerformanceTable,
  dispatchDownloadReport,
  dispatchGetScheduledReports,
  dispatchGetScheduledReportsMetaData,
  dispatchPostScheduledReport,
  dispatchUpdateScheduledReport,
  dispatchDeleteScheduledReport,
  dispatchGetTabs,
  downloadReport,
  scheduledReports,
  scheduledReportsMetaData,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport,
  filterState,
  pageComponents,
  performanceTable,
  metricTabs
}) => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const [hasDownloadedReport, setHasDownloadedReport] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleFilterChange = ({ selectedOption, filter, filterID }) => {
    dispatchFilterChange({
      [filterID]: {
        ...filter,
        value: selectedOption
      }
    });
  };

  if (!hasDownloadedReport && !isEmpty(downloadReport)) {
    const { url } = downloadReport;
    if (url && url !== '') {
      const a = document.createElement('a');
      a.href = url;
      a.click();
      setHasDownloadedReport(true);
    }
  }

  const handleDownloadReport = payload => {
    dispatchDownloadReport({ ...getFilterParamsForAPI(), ...payload });
    setHasDownloadedReport(false);
  };

  useEffect(() => {
    dispatchGetFilterList({});
  }, [dispatchGetFilterList]);

  useEffect(() => {
    dispatchGetScheduledReports({});
  }, [dispatchGetScheduledReports]);

  useEffect(() => {
    dispatchGetScheduledReportsMetaData({});
  }, [dispatchGetScheduledReportsMetaData]);

  const getLastFilter = useCallback(() => configuredFilters.slice(-1)[0], [
    configuredFilters
  ]);

  const getFilterParamsForAPI = useCallback(() => {
    const params = {
      route_id: routeID
    };
    map(filterState, (fl, key) => {
      params[key] = fl.value.value;
    });
    return params;
  }, [filterState, routeID]);

  useEffect(() => {
    if (filterState[getLastFilter().value]) {
      dispatchGetPageComponents({
        params: getFilterParamsForAPI()
      });
    }
  }, [
    dispatchGetPageComponents,
    filterState,
    getFilterParamsForAPI,
    getLastFilter
  ]);

  const fetchTabAndTableData = useCallback(
    defaultTab => {
      const payload = {
        ...getFilterParamsForAPI(),
        metric_key: defaultTab.key
      };
      if (defaultTab.has_chart) {
        dispatchGetTabs({
          params: payload
        });
      }
      if (defaultTab.has_table) {
        const { granularities } = defaultTab.performance_table;
        const grValues = {};
        granularities.forEach(gr => {
          grValues[gr.key] = getFilterValue({ options: gr.options })?.value;
        });
        dispatchGetPerformanceTable({
          params: {
            ...payload,
            ...grValues
          }
        });
      }
    },
    [getFilterParamsForAPI, dispatchGetPerformanceTable, dispatchGetTabs]
  );

  useEffect(() => {
    if (pageComponents.cumulative_cards?.enable) {
      dispatchGetCumulativeCards({
        params: getFilterParamsForAPI()
      });
    }
    if (pageComponents.metric_tabs?.enable) {
      const defaultTab = pageComponents.metric_tabs.tabs[0];
      fetchTabAndTableData(defaultTab);
    }
  }, [
    pageComponents,
    dispatchGetCumulativeCards,
    fetchTabAndTableData,
    getFilterParamsForAPI
  ]);

  useEffect(() => {
    if (downloadReport && downloadReport.status === 200) {
      setSnackBarState({
        isOpen: true,
        message: downloadReport.message,
        severity: 'success',
        duration: 4000
      });
    }
  }, [downloadReport]);

  useEffect(() => {
    if (
      apiRequestStatus.downloadReport.status === enums.apiRequest.FAILURE &&
      apiRequestStatus.downloadReport.error
    ) {
      setSnackBarState({
        isOpen: true,
        message: apiRequestStatus.downloadReport.error.error_message,
        severity: 'error',
        duration: 4000
      });
    }
  }, [apiRequestStatus.downloadReport]);

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleTabChange = ({ index }) => {
    const targetTab = pageComponents.metric_tabs.tabs[index];
    fetchTabAndTableData(targetTab);
  };

  const handleGranularityChange = ({ activeTabKey, grValues }) => {
    dispatchGetPerformanceTable({
      params: {
        ...getFilterParamsForAPI(),
        ...grValues,
        metric_key: activeTabKey
      }
    });
  };

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <MerchEvaluationPage
        apiRequestStatus={apiRequestStatus}
        cumulativeCards={cumulativeCards}
        filtersToRender={filterState}
        handleDownloadReport={handleDownloadReport}
        handleFilterChange={handleFilterChange}
        handleGranularityChange={handleGranularityChange}
        handleTabChange={handleTabChange}
        metricTabs={metricTabs}
        pageComponents={pageComponents}
        performanceTable={performanceTable}
        scheduledReports={scheduledReports}
        scheduledReportsMetaData={scheduledReportsMetaData}
        postScheduledReport={postScheduledReport}
        updateScheduledReport={updateScheduledReport}
        deleteScheduledReport={deleteScheduledReport}
        dispatchPostScheduledReport={dispatchPostScheduledReport}
        dispatchUpdateScheduledReport={dispatchUpdateScheduledReport}
        dispatchDeleteScheduledReport={dispatchDeleteScheduledReport}
      />
    </>
  );
};

MerchEvaluationContainer.propTypes = {
  apiRequestStatus: object.isRequired,
  cumulativeCards: object.isRequired,
  configuredFilters: array.isRequired,
  dispatchFilterChange: func.isRequired,
  dispatchGetFilterList: func.isRequired,
  dispatchGetPageComponents: func.isRequired,
  dispatchGetPerformanceTable: func.isRequired,
  dispatchGetTabs: func.isRequired,
  dispatchGetCumulativeCards: func.isRequired,
  dispatchDownloadReport: func.isRequired,
  downloadReport: object,
  filterState: object.isRequired,
  performanceTable: object.isRequired,
  pageComponents: object.isRequired,
  metricTabs: object.isRequired,
  dispatchGetScheduledReports: func.isRequired,
  dispatchGetScheduledReportsMetaData: func.isRequired,
  scheduledReports: array.isRequired,
  scheduledReportsMetaData: object.isRequired,
  postScheduledReport: object.isRequired,
  updateScheduledReport: object.isRequired,
  deleteScheduledReport: object.isRequired,
  dispatchPostScheduledReport: func.isRequired,
  dispatchUpdateScheduledReport: func.isRequired,
  dispatchDeleteScheduledReport: func.isRequired
};

MerchEvaluationContainer.defaultProps = {
  downloadReport: {}
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getConfiguredFilters = appSelector.makeGetDefaultFilters();

  const mapStateToProps = state => ({
    activeSession: merchSelector.selectActiveSession(state),
    apiRequestStatus: merchSelector.selectApiRequestStatus(state),
    cumulativeCards: merchSelector.selectCumulativeCards(state),
    configuredFilters: getConfiguredFilters(state, { routeID }),
    downloadReport: merchSelector.selectDownloadReport(state),
    filterState: merchSelector.selectFilterState(state),
    pageComponents: merchSelector.selectPageComponents(state),
    metricTabs: merchSelector.selectMetricTabs(state),
    performanceTable: merchSelector.selectPerformanceTable(state),
    scheduledReports: merchSelector.selectScheduledReports(state),
    scheduledReportsMetaData: merchSelector.selectScheduledReportsMetaData(
      state
    ),
    postScheduledReport: merchSelector.selectPostScheduledReport(state),
    updateScheduledReport: merchSelector.selectUpdateScheduledReport(state),
    deleteScheduledReport: merchSelector.selectDeleteScheduledReport(state)
  });
  return mapStateToProps;
};

/*
    Connect dispatch methods to props so that you can call the methods
    from the scope of the component's props
  */
const mapDispatchToProps = {
  dispatchFilterChange: merchActions.filterChange,
  dispatchGetFilterList: merchActions.getFilters,
  dispatchGetPageComponents: merchActions.getPageComponents,
  dispatchGetCumulativeCards: merchActions.getCumulativeCards,
  dispatchGetTabs: merchActions.getMetricTabs,
  dispatchGetPerformanceTable: merchActions.getPerformanceTable,
  dispatchGetScheduledReports: merchActions.getScheduledReports,
  dispatchGetScheduledReportsMetaData: merchActions.getScheduledReportsMetaData,
  dispatchDownloadReport: merchActions.downloadReport,
  dispatchSessionChange: merchActions.sessionChange,
  dispatchPostScheduledReport: payload =>
    merchActions.postScheduledReport(payload),
  dispatchUpdateScheduledReport: payload =>
    merchActions.updateScheduledReport(payload),
  dispatchDeleteScheduledReport: payload =>
    merchActions.deleteScheduledReport(payload)
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MerchEvaluationContainer);

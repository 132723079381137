/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { pick, map } from 'lodash';
// action types
import { appTypes, chartTypes, cacheTypes } from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  filters: {},
  slicers: {}
};

const reducer = createReducer(initialState, {
  [chartTypes.slicerChange]: (state, action) => {
    const {
      payload: { routeID, updates, chartName }
    } = action;
    map(updates, (value, key) => {
      state.slicers[routeID][chartName][key].value = value;
    });
  },
  [appTypes.getFilterList.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.filters = { ...state.filters, ...response.data };
  },
  [chartTypes.getChartList.success]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID },
        response: { data: chartList }
      }
    } = action;
    const slicerList = {};
    chartList.forEach(chart => {
      const chartObject = pick(chart, ['name', 'slicers']);
      const slicerType = {};
      if (chartObject.slicers) {
        chartObject.slicers.forEach(e => {
          slicerType[e.filter_id] = {
            ...state.filters[e.filter_id],
            value: state.filters[e.filter_id].options[0]
          };
        });
      }
      slicerList[chartObject.name] = slicerType;
    });
    state.slicers[routeID] = slicerList;
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

/* eslint-disable valid-jsdoc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// redux utils
import { appImageActions } from 'redux-utils/actions';
import { connect } from 'react-redux';
// javascript utility for conditionally joining classNames together
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
// core components
import Typography from 'components/Typography/Typography';
import Button from 'components/Button/Button';
import Menu from 'components/Menu/Menu';
// assets
import headerStyleNew from 'assets/jss/material-ui/components/headerStyleNew';
import { MenuIcon, PersonIcon, Home } from 'assets/icons/appIcons';
// storage
import ls from 'lib/core/storageFactory';

const getPathName = props => {
  let name;
  props.routes.map(prop => {
    if (prop.path === props.location.pathname) {
      name = prop.navbarName;
    }
    return null;
  });
  return name;
};

const generateRightMenuItems = ({
  routes,
  handleRouteClick,
  handleLogout,
  handleProfileClose
}) => {
  const client = ls.get('client') || '';
  const clientResponse = ls.get('clientResponse');
  const showResetPasswordLs = ls.get('showResetPassword');
  const menuItems = [];
  routes.map(route => {
    if (!route.redirect) {
      menuItems.push(
        <MenuItem key={route.path} onClick={() => handleRouteClick(route)}>
          {route.navbarName}
        </MenuItem>
      );
    }
    return route;
  });
  menuItems.push(
    <MenuItem key={1} onClick={() => handleProfileClose('logout')}>
      {client.includes('kcc-ru') ? 'Выйти из системы' : 'Logout'}
    </MenuItem>
  );
  try {
    // updated this condition to show reset password for all users logging with username and password
    if (showResetPasswordLs) {
      menuItems.push(
        <MenuItem key={2} onClick={() => handleProfileClose('reset')}>
          {client.includes('kcc-ru') ? 'Reset Password' : 'Reset Password'}
        </MenuItem>
      );
    }
  } catch (error) {
    handleLogout();
  }

  return menuItems;
};

const Brand = ({ classes, logo }) => (
  <div>
    <a href="/app">
      <div className={classes.logoImage}>
        <img src={logo} alt="logo" />
      </div>
    </a>
  </div>
);

const StyledAppBar = styled(AppBar)`
  background-color: ${props =>
    props.bg ? `${props.bg} !important` : '#0f0d36 !important'};
`;

const StyledDiv = styled.div`
  && path {
    fill: ${props => props.color};
  }
`;

/**
 * @class
 * @hideconstructor
 * @description Page AppHeader class component
 */
class AppHeader extends Component {
  state = {
    profEl: null
  };

  handleProfileClick = event => {
    this.setState({ profEl: event.currentTarget });
  };

  handleRouteClick = route => {
    this.props.history.push(route.path);
    this.setState({ profEl: null });
  };

  handleHomeClick = () => {
    const { initializeAppImages } = this.props;
    this.props.history.push('/app');
    initializeAppImages();
    this.setState({ profEl: null });
  };

  handleProfileClose = option => {
    if (option === 'logout') {
      this.props.handleLogout();
    }
    if (option === 'reset') {
      this.props.history.push('/reset_password');
    }
    this.setState({ profEl: null });
  };

  render() {
    const {
      classes,
      color,
      handleLogout,
      handleDrawerToggle,
      rightMenuRoutes,
      useHomeIcon,
      handleDrawerExpand,
      icon: ViewIcon,
      clientPreferences,
      headerStyles
    } = this.props;

    const { profEl } = this.state;
    const appBarClasses = classNames({
      [` ${classes[color]}`]: color
    });
    const profileOpen = Boolean(profEl);

    return (
      <StyledAppBar
        className={classes.appBar + appBarClasses}
        bg={headerStyles?.backgroundColor}
      >
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <Brand {...this.props} />
            <div className={classes.navTitleHeaderContainer}>
              <Typography
                className={classes.navTitleHeader}
                size="md"
                color={
                  headerStyles?.textColor
                    ? headerStyles?.textColor
                    : 'rgba(255, 255, 255, 0.8)'
                }
                fontSize={headerStyles?.fontSize}
                textColor={headerStyles?.textColor}
              >
                {getPathName(this.props) || ''}
              </Typography>
            </div>
            <Hidden
              className={classes.rightFlexContainer}
              smDown
              implementation="css"
            >
              <div style={{ display: 'flex' }}>
                {useHomeIcon && (
                  <Button
                    className={classes.menuButtonRight}
                    color="dark"
                    round
                    justIcon
                    onClick={this.handleHomeClick}
                  >
                    <Home />
                  </Button>
                )}
                {clientPreferences.client_logo &&
                clientPreferences.client_logo.show ? (
                  <div className={classes.logoContainer}>
                    <img
                      alt="client logo"
                      src={
                        clientPreferences.client_logo
                          ? clientPreferences.client_logo.logo
                          : ''
                      }
                    />
                  </div>
                ) : null}

                <Button
                  className={useHomeIcon ? '' : classes.menuButtonRightPerson}
                  aria-haspopup="true"
                  aria-owns={profileOpen ? 'profile-menu' : undefined}
                  aria-label="open profile"
                  color="dark"
                  round
                  justIcon
                  onClick={this.handleProfileClick}
                >
                  <PersonIcon />
                </Button>
              </div>
            </Hidden>
            <Hidden mdUp implementation="css">
              <Button
                className={classes.menuButton}
                aria-label="open drawer"
                color="dark"
                round
                justIcon
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </Button>
            </Hidden>
            <Menu
              id="menu-profile"
              anchorEl={profEl}
              handleClose={this.handleProfileClose}
            >
              {generateRightMenuItems({
                routes: rightMenuRoutes,
                handleProfileClose: this.handleProfileClose,
                handleRouteClick: this.handleRouteClick,
                handleLogout
              }).map(RightMenu => RightMenu)}
            </Menu>
          </div>
        </Toolbar>
      </StyledAppBar>
    );
  }
}

// component properties
AppHeader.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description browser history object
   */
  history: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  rightMenuRoutes: PropTypes.array.isRequired,

  useHomeIcon: PropTypes.bool,
  /**
   * @type {string}
   * @description App bar color
   */
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    ''
  ]),
  /**
   * @type {function}
   * @description Handler function to handle the drawer toggle on md devices
   */
  handleDrawerToggle: PropTypes.func,
  /**
   * @type {function}
   * @description Handler function to handle the user logout
   */
  handleLogout: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Redux function to initialize the app images state
   */
  initializeAppImages: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Drawer open icon
   */
  icon: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description Handler function to handle the drawer open on sm devices
   */
  handleDrawerExpand: PropTypes.func.isRequired,
  clientPreferences: PropTypes.object.isRequired,
  headerStyles: PropTypes.object.isRequired
};

AppHeader.defaultProps = {
  color: '',
  handleDrawerToggle: () => {},
  useHomeIcon: false
};

Brand.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    initializeAppImages: payload =>
      dispatch(appImageActions.initializeAppImages(payload))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(headerStyleNew)(AppHeader));

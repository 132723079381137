import React, { useCallback, useEffect, useState } from 'react';
import { array, func, object } from 'prop-types';
import { isEmpty } from 'lodash';
// redux-utils
import { connect } from 'react-redux';
import { accuracyActions } from 'redux-utils/actions/index';
import { accuracySelector, appSelector } from 'redux-utils/selectors/index';
// material ui core components
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// utils
import { getRouteID } from 'lib/utils/getActiveRoute';
import enums from 'models/enums';
// import { computeFilters, computeRequestParams } from './helpers';
import AccuracyPage from './AccuracyPage';

const AccuracyContainer = ({
  activeSession,
  chartData,
  chartList,
  configuredFilters,
  dispatchFilterChange,
  dispatchGetChartData,
  dispatchGetChartList,
  dispatchGetFilterList,
  dispatchGetSessionDetails,
  dispatchGetSessionList,
  dispatchGetScheduledReports,
  dispatchGetScheduledReportsMetaData,
  dispatchDownloadReport,
  dispatchPostScheduledReport,
  dispatchUpdateScheduledReport,
  dispatchDeleteScheduledReport,
  dispatchSessionChange,
  filterState,
  scheduledReports,
  scheduledReportsMetaData,
  sessionDetails,
  sessionList,
  apiRequestStatus,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport,
  downloadReport,
  dispatchResetSessionDetails
}) => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const [hasDownloadedReport, setHasDownloadedReport] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleFilterChange = ({ selectedOption, filter, filterID }) => {
    dispatchFilterChange({
      [filterID]: {
        ...filter,
        value: selectedOption
      }
    });
  };

  if (!hasDownloadedReport && !isEmpty(downloadReport)) {
    const { url } = downloadReport;
    if (url && url !== '') {
      const a = document.createElement('a');
      a.href = url;
      a.click();
      setHasDownloadedReport(true);
    }
  }

  const handleDownloadReport = payload => {
    dispatchDownloadReport(payload);
    setHasDownloadedReport(false);
  };

  useEffect(() => {
    dispatchGetFilterList({});
  }, [dispatchGetFilterList]);

  useEffect(() => {
    dispatchGetScheduledReports({});
  }, [dispatchGetScheduledReports]);

  useEffect(() => {
    dispatchGetScheduledReportsMetaData({});
  }, [dispatchGetScheduledReportsMetaData]);

  useEffect(() => {
    dispatchGetChartList({ params: { route_id: routeID } });
  }, [dispatchGetChartList, routeID]);

  const getPenultimateFilter = useCallback(
    () => configuredFilters.slice(-2)[0],
    [configuredFilters]
  );

  useEffect(() => {
    if (
      isEmpty(sessionList) &&
      apiRequestStatus.getSessionList.status === enums.apiRequest.SUCCESS
    ) {
      dispatchResetSessionDetails();
    }
  }, [sessionList]);

  useEffect(() => {
    if (filterState[getPenultimateFilter().value]) {
      dispatchGetSessionList({
        params: {
          channel: filterState.fl_ai_channel?.value.value,
          category: filterState.fl_category?.value.value
        }
      });
    }
  }, [filterState, dispatchGetSessionList, getPenultimateFilter]);

  const getChartData = useCallback(() => {
    chartList.forEach(chart => {
      const requestObject = {};
      const { params, name } = chart;
      params.forEach(({ param_id: paramID }) => {
        if (paramID.includes('fl_session')) {
          requestObject[paramID] = activeSession.value;
        } else if (paramID.includes('fl_')) {
          requestObject[paramID] = filterState[paramID].value.value;
        } else if (paramID.includes('chart_name')) {
          requestObject[paramID] = name;
        }
      });
      dispatchGetChartData({
        params: requestObject
      });
    });
  }, [activeSession]);

  useEffect(() => {
    if (sessionList.length > 0) {
      dispatchGetSessionDetails({
        params: {
          session_id: activeSession.value
        }
      });
      getChartData();
    }
  }, [dispatchGetSessionDetails, getChartData, activeSession, sessionList]);

  useEffect(() => {
    if (downloadReport && downloadReport.status === 200) {
      setSnackBarState({
        isOpen: true,
        message: downloadReport.message,
        severity: 'success',
        duration: 4000
      });
    }
  }, [downloadReport]);

  useEffect(() => {
    if (
      apiRequestStatus.downloadReport.status === enums.apiRequest.FAILURE &&
      apiRequestStatus.downloadReport.error
    ) {
      setSnackBarState({
        isOpen: true,
        message: apiRequestStatus.downloadReport.error.error_message,
        severity: 'error',
        duration: 4000
      });
    }
  }, [apiRequestStatus.downloadReport]);

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <AccuracyPage
        activeSession={activeSession}
        chartData={chartData}
        chartList={chartList}
        filtersToRender={filterState}
        handleFilterChange={handleFilterChange}
        handleSessionChange={dispatchSessionChange}
        sessionDetails={sessionDetails}
        sessionList={sessionList}
        apiRequestStatus={apiRequestStatus}
        postScheduledReport={postScheduledReport}
        updateScheduledReport={updateScheduledReport}
        deleteScheduledReport={deleteScheduledReport}
        scheduledReports={scheduledReports}
        scheduledReportsMetaData={scheduledReportsMetaData}
        dispatchPostScheduledReport={dispatchPostScheduledReport}
        dispatchUpdateScheduledReport={dispatchUpdateScheduledReport}
        dispatchDeleteScheduledReport={dispatchDeleteScheduledReport}
        dispatchDownloadReport={payload => handleDownloadReport(payload)}
      />
    </>
  );
};

AccuracyContainer.propTypes = {
  activeSession: object.isRequired,
  chartData: object.isRequired,
  chartList: array.isRequired,
  configuredFilters: array.isRequired,
  dispatchFilterChange: func.isRequired,
  dispatchGetChartData: func.isRequired,
  dispatchGetChartList: func.isRequired,
  dispatchGetFilterList: func.isRequired,
  dispatchGetSessionDetails: func.isRequired,
  dispatchGetSessionList: func.isRequired,
  dispatchGetScheduledReports: func.isRequired,
  dispatchGetScheduledReportsMetaData: func.isRequired,
  dispatchDownloadReport: func.isRequired,
  dispatchPostScheduledReport: func.isRequired,
  dispatchUpdateScheduledReport: func.isRequired,
  dispatchDeleteScheduledReport: func.isRequired,
  dispatchSessionChange: func.isRequired,
  filterState: object.isRequired,
  sessionDetails: object.isRequired,
  sessionList: array.isRequired,
  apiRequestStatus: object.isRequired,
  postScheduledReport: object.isRequired,
  updateScheduledReport: object.isRequired,
  deleteScheduledReport: object.isRequired,
  scheduledReports: array.isRequired,
  scheduledReportsMetaData: object.isRequired,
  downloadReport: object.isRequired,
  dispatchResetSessionDetails: func.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getConfiguredFilters = appSelector.makeGetDefaultFilters();

  const mapStateToProps = state => ({
    activeSession: accuracySelector.selectActiveSession(state),
    chartData: accuracySelector.selectChartData(state),
    chartList: accuracySelector.selectChartList(state),
    configuredFilters: getConfiguredFilters(state, { routeID }),
    filterState: accuracySelector.selectFilterState(state),
    sessionDetails: accuracySelector.selectSessionDetails(state),
    sessionList: accuracySelector.selectSessionList(state),
    scheduledReports: accuracySelector.selectScheduledReports(state),
    scheduledReportsMetaData: accuracySelector.selectScheduledReportsMetaData(
      state
    ),
    downloadReport: accuracySelector.selectDownloadReport(state),
    apiRequestStatus: accuracySelector.selectApiRequestStatus(state),
    postScheduledReport: accuracySelector.selectPostScheduledReport(state),
    updateScheduledReport: accuracySelector.selectUpdateScheduledReport(state),
    deleteScheduledReport: accuracySelector.selectDeleteScheduledReport(state)
  });
  return mapStateToProps;
};

/*
    Connect dispatch methods to props so that you can call the methods
    from the scope of the component's props
  */
const mapDispatchToProps = {
  dispatchFilterChange: accuracyActions.filterChange,
  dispatchResetSessionDetails: accuracyActions.resetSessionDetails,
  dispatchGetChartData: accuracyActions.getChartData,
  dispatchGetChartList: accuracyActions.getChartList,
  dispatchGetFilterList: accuracyActions.getFilters,
  dispatchGetSessionDetails: accuracyActions.getSessionDetails,
  dispatchGetSessionList: accuracyActions.getSessionList,
  dispatchGetScheduledReports: accuracyActions.getScheduledReports,
  dispatchGetScheduledReportsMetaData:
    accuracyActions.getScheduledReportsMetaData,
  dispatchDownloadReport: payload => accuracyActions.downloadReport(payload),
  dispatchSessionChange: accuracyActions.sessionChange,
  dispatchSaveExcel: accuracyActions.saveExcelAction,
  dispatchEditExcel: accuracyActions.editExcelAction,
  dispatchDeleteExcel: accuracyActions.deleteExcelAction,
  dispatchPostScheduledReport: payload =>
    accuracyActions.postScheduledReport(payload),
  dispatchUpdateScheduledReport: payload =>
    accuracyActions.updateScheduledReport(payload),
  dispatchDeleteScheduledReport: payload =>
    accuracyActions.deleteScheduledReport(payload)
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AccuracyContainer);

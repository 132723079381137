/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
/* Action Types */
import { appImagesTypes } from 'redux-utils/types';
import ls from 'lib/core/storageFactory';

/* Initial State */
const initialState = {
  isFetching: false,
  message: null,
  appImageDownloadOptions: [],
  appImageFilters: {},
  preferences: {
    stores: [],
    sessions: [],
    photos: [],
    photoAttributes: {},
    bulkStores: [],
    downloadPhotos: []
  },
  errors: {
    getStores: {},
    getStoreSessions: {},
    getPhotos: {},
    downloadPhotos: {},
    bulkStores: {}
  },
  success: {
    downloadPhotos: {}
  },
  messages: {
    bulkStores: null
  },
  appImageDownloadOptionsState: {
    request: false,
    success: false,
    failure: false,
    message: ''
  }
};

const reducer = createReducer(initialState, {
  [appImagesTypes.setAppImageFilters]: (state, action) => {
    state.appImageFilters = action.payload;
  },
  [appImagesTypes.getStores.request]: state => {
    state.isFetching = true;
    state.message = ' ';
  },
  [appImagesTypes.getStores.success]: (state, action) => {
    state.isFetching = false;
    const client = ls.get('client');
    if (action.payload.response.length === 0) {
      state.message = client.includes('kcc-ru')
        ? 'На указанную дату магазины не найдены'
        : 'No stores found for this date';
    }
    state.preferences.stores = action.payload.response;
    state.preferences.sessions = [];
    state.preferences.photos = [];
  },
  [appImagesTypes.getStores.failure]: state => {
    const client = ls.get('client');
    state.isFetching = false;
    state.message = client.includes('kcc-ru')
      ? 'Ошибка при получении информации о магазинах'
      : 'There was an Error getting the stores';
  },
  [appImagesTypes.getStoreSessions.request]: state => {
    state.isFetching = true;
    state.preferences.photos = [];
  },
  [appImagesTypes.getStoreSessions.success]: (state, action) => {
    state.isFetching = false;
    const client = ls.get('client');
    if (state.preferences.sessions.length === 0) {
      state.message = client.includes('kcc-ru')
        ? 'Сессии для данного магазина отсутствуют'
        : 'No Sessions available for this store';
    }
    state.preferences.sessions = action.payload.response;
  },
  [appImagesTypes.getStoreSessions.failure]: state => {
    state.isFetching = false;
    const client = ls.get('client');
    state.message = client.includes('kcc-ru')
      ? 'Ошибка при получении информации о магазинных сессиях'
      : 'There was an error getting the store sessions';
  },
  [appImagesTypes.getPhotos.request]: state => {
    state.isFetching = true;
    state.preferences.photos = [];
    state.preferences.photoAttributes = {};
  },
  [appImagesTypes.getPhotos.success]: (state, action) => {
    state.isFetching = false;
    if (state.preferences.photos.length === 0) {
      const client = ls.get('client');
      state.message = client.includes('kcc-ru')
        ? 'Фотографии для указанной сессии отсутствуют'
        : 'No photos available for this session';
    }
    state.preferences.photos = action.payload.response;
    state.preferences.photoAttributes = action.payload.photoAttributes;
  },
  [appImagesTypes.getPhotos.failure]: state => {
    state.isFetching = false;
    const client = ls.get('client');
    state.message = client.includes('kcc-ru')
      ? 'Ошибка при получении информации о фотографиях'
      : 'There was an error getting the photos';
  },
  [appImagesTypes.getPhotos.clear]: state => {
    state.preferences.photos = [];
    state.preferences.photoAttributes = {};
  },
  [appImagesTypes.downloadPhotos.request]: state => {
    state.isFetching = true;
    state.appImageDownloadOptionsState.request = true;
    state.errors.downloadPhotos = initialState.errors.downloadPhotos;
    state.success.downloadPhotos = initialState.success.downloadPhotos;
  },
  [appImagesTypes.downloadPhotos.success]: (state, action) => {
    state.isFetching = false;
    state.appImageDownloadOptionsState.request = false;
    state.appImageDownloadOptionsState.success = true;
    state.preferences.downloadPhotos = action.payload.response;
    const client = ls.get('client');
    state.success.downloadPhotos = {
      message: client.includes('kcc-ru')
        ? 'Действие успешно завершено'
        : 'Success',
      toast: action.payload.response.message
    };
  },
  [appImagesTypes.downloadPhotos.failure]: (state, action) => {
    state.isFetching = false;
    state.appImageDownloadOptionsState.request = false;
    state.appImageDownloadOptionsState.success = false;
    state.appImageDownloadOptionsState.failure = true;
    const client = ls.get('client');
    state.appImageDownloadOptionsState.message =
      action.payload.response.data.message;
    state.errors.downloadPhotos = {
      message: client.includes('kcc-ru')
        ? 'Ошибка'
        : action.payload.response.data.message,
      toast: action.payload.response.data?.error_message
    };
  },
  [appImagesTypes.getStoresRange.request]: state => {
    state.messages.bulkStores = null;
    state.isFetching = true;
  },
  [appImagesTypes.getStoresRange.success]: (state, action) => {
    state.isFetching = false;
    const stores = action.payload.response;
    if (stores.length === 0) {
      const client = ls.get('client');
      state.messages.bulkStores = client.includes('kcc-ru')
        ? 'Для выбранного диапазона дат магазины отсутствуют'
        : 'No Stores available for the selected date range';
    }
    state.preferences.bulkStores = action.payload.response;
  },
  [appImagesTypes.getStoresRange.failure]: state => {
    state.isFetching = false;
    const client = ls.get('client');
    state.messages.bulkStores = client.includes('kcc-ru')
      ? 'Ошибка при получении информации о магазинах'
      : 'There was an error getting the stores for this range';
    state.preferences.bulkStores = [];
  },
  [appImagesTypes.clearBulkStores.request]: state => {
    state.preferences.bulkStores = initialState.preferences.bulkStores;
  },
  [appImagesTypes.initializeAppImages.request]: state => {
    state.preferences = initialState.preferences;
  },
  [appImagesTypes.initializeSuccessAPI.request]: state => {
    state.success = initialState.success;
  },
  [appImagesTypes.initializeSuccessAPI.request]: state => {
    state.errors = initialState.errors;
    state.success = initialState.success;
  },
  [appImagesTypes.getImageActionTypes.success]: (state, action) => {
    state.appImageDownloadOptions = action.payload;
    state.appImageDownloadOptionsState = {
      ...state.appImageDownloadOptionsState,
      success: true
    };
  },
  [appImagesTypes.getImageActionTypes.request]: (state, action) => {
    state.appImageDownloadOptionsState = {
      ...state.appImageDownloadOptionsState,
      request: true
    };
  },
  [appImagesTypes.getImageActionTypes.failure]: (state, action) => {
    state.appImageDownloadOptionsState = {
      request: false,
      success: false,
      failure: true,
      message: 'There is some error'
    };
  },
  [appImagesTypes.downloadPhotos.reset]: (state, action) => {
    state.appImageDownloadOptionsState = {
      request: false,
      success: false,
      failure: false,
      message: ''
    };
    state.appImageDownloadOptionsState = {
      request: false,
      success: false,
      failure: false,
      message: ''
    };
  }
});

export default reducer;

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { authTypes } from 'redux-utils/types/index';
import Cookies from 'universal-cookie';
import ls from 'lib/core/storageFactory';
import { deleteDB } from 'db';
import { setBaseUrl } from 'api';

const cookies = new Cookies();
const initialState = {
  isLoggedIn: false,
  client: {},
  profile: {
    groupID: '',
    username: ''
  },
  logoutRedirection: {},
  apiStatus: {
    login: { request: false, success: false, error: false },
    logout: { request: false, success: false, error: false },
    client: { request: false, success: false, error: false },
    forgotPassword: { request: false, success: false, error: false },
    resetPassword: { request: false, success: false, error: false },
    confirmForgotPassword: { request: false, success: false, error: false }
  }
};

const reducer = createReducer(initialState, {
  [authTypes.resetPassword.request]: state => {
    state.apiStatus = {
      resetPassword: { request: true, success: false, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.resetPassword = {};
  },
  [authTypes.resetPassword.success]: (state, action) => {
    const { response } = action.payload;
    state.apiStatus = {
      resetPassword: { request: true, success: true, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.resetPassword = {
      ...response
    };
  },
  [authTypes.resetPassword.failure]: (state, action) => {
    state.apiStatus = {
      resetPassword: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message.message
      },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      login: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.resetPassword = {};
  },
  [authTypes.forgotPassword.request]: state => {
    state.apiStatus = {
      forgotPassword: { request: true, success: false, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.confirmForgotPassword = {};
  },
  [authTypes.forgotPassword.success]: (state, action) => {
    const { response } = action.payload;
    state.apiStatus = {
      forgotPassword: { request: true, success: true, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.forgotPassword = {
      ...response
    };
    state.confirmForgotPassword = {};
  },
  [authTypes.forgotPassword.failure]: (state, action) => {
    state.apiStatus = {
      forgotPassword: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message.message
      },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      login: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.confirmForgotPassword = {};
  },
  [authTypes.confirmForgotPassword.request]: state => {
    state.apiStatus = {
      confirmForgotPassword: { request: true, success: false, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false }
    };
  },
  [authTypes.confirmForgotPassword.success]: (state, action) => {
    const { response } = action.payload;
    state.apiStatus = {
      confirmForgotPassword: { request: true, success: true, error: false },
      login: { request: true, success: true, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false }
    };
    state.confirmForgotPassword = {
      ...response
    };
  },
  [authTypes.confirmForgotPassword.failure]: (state, action) => {
    state.apiStatus = {
      confirmForgotPassword: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message.status
      },
      login: {
        request: false,
        success: false,
        error: false
      },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false }
    };
  },
  [authTypes.login.request]: state => {
    state.apiStatus = {
      login: { request: true, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
  },
  [authTypes.login.success]: (state, action) => {
    const { request, response } = action.payload;
    state.apiStatus = {
      login: { request: true, success: true, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.isLoggedIn = true;
    state.profile = {
      ...state.profile,
      username: request.username,
      groupID: response.group_id,
      ...response
    };
  },
  [authTypes.login.failure]: (state, action) => {
    state.apiStatus = {
      login: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message.error_message
      },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.isLoggedIn = false;
  },
  [authTypes.analyticsRedirect]: (state, action) => {
    const { request, response } = action.payload;
    state.apiStatus = {
      login: { request: true, success: true, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      analyticsRedirect: { request: false, success: true, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.isLoggedIn = true;
    state.analyticsRedirect = {
      ...response.analytics_redirect,
      ...response.analytics
    };
    state.profile = {
      ...state.profile,
      username: request.username,
      groupID: response.group_id,
      ...response
    };
  },
  [authTypes.clientValidation.request]: state => {
    state.apiStatus = {
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: true, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.client = {};
  },
  [authTypes.clientValidation.success]: (state, action) => {
    state.apiStatus = {
      client: { request: true, success: true, error: false },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.client = action.payload.response;
  },
  [authTypes.clientValidation.failure]: (state, action) => {
    state.apiStatus = {
      client: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message
      },
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
  },
  [authTypes.logout.request]: state => {
    state.apiStatus = {
      logout: { request: true, success: false, error: false },
      login: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
  },
  [authTypes.logout.success]: (state, action) => {
    state.logoutRedirection = action.payload.response;

    const {
      is_redirect: isRedirect,
      redirect_url: redirectURL
    } = action.payload.response;

    clearUserDetails();
    window.location.href = isRedirect && redirectURL ? redirectURL : '';
    state.apiStatus = {
      logout: {
        request: true,
        success: true,
        error: false
      },
      login: {
        request: false,
        success: false,
        error: false
      },
      client: {
        request: false,
        success: false,
        error: false
      },
      forgotPassword: {
        request: false,
        success: false,
        error: false
      },
      resetPassword: {
        request: false,
        success: false,
        error: false
      },
      confirmForgotPassword: {
        request: false,
        success: false,
        error: false
      }
    };
    state.isLoggedIn = false;
  },
  [authTypes.logout.failure]: (state, action) => {
    state.apiStatus = {
      logout: {
        request: true,
        success: false,
        error: true,
        message: action.payload.message
      },
      login: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.isLoggedIn = false;
  },
  [authTypes.inValidToken]: state => {
    state.apiStatus = {
      login: { request: false, success: false, error: false },
      logout: { request: false, success: false, error: false },
      client: { request: false, success: false, error: false },
      forgotPassword: { request: false, success: false, error: false },
      resetPassword: { request: false, success: false, error: false },
      confirmForgotPassword: { request: false, success: false, error: false }
    };
    state.client_exist = false;
    state.isLoggedIn = false;
  }
});

export default reducer;

// function to clear user details
const clearUserDetails = () => {
  // cookies.remove('trafficType', {
  //   path: '/',
  //   domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
    // sameSite: 'none',
    // secure: 'true'
  // });
  // cookies.remove('showResetPassword', {
  //   path: '/',
  //   domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`
  // });
  // cookies.remove('userId', {
  //   path: '/',
  //   domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`
  // });
  
  // delete all cookies
  cookies.remove();
  
  ls.clear();
  ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
  setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
  try {
    deleteDB();
  } catch (err) {
    console.error('db delete error', err);
  }
};

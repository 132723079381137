import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { DropzoneAreaBase } from 'material-ui-dropzone';

import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
import Button from 'components/Button/Button';

// import PackshotUpload from './PackshotUpload';

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiDropzoneArea: {
      root: {
        outline: 'none',
        backgroundColor: '#1e1e2b',
        minHeight: 130,
        border: '1px dashed',
        borderColor: 'rgb(189 174 174 / 34%)',
        padding: 40,
        width: 400
      },
      text: {
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDropzonePreviewList: {
      root: {
        marginTop: 10,
        width: 'inherit'
      }
    },
    MuiChip: {
      root: {
        backgroundColor: '#52596b !important',
        color: 'rgba(255,255,255,0.8)'
      }
    }
  }
});

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center'
  },
  button: {
    padding: '8px 20px !important',
    textTransform: 'capitalize !important',
    marginLeft: '10px'
  }
};

const FirstUpload = ({
  classes,
  menuProps,
  apiRequestStatus,
  handleExcelUpload,
  updateSidePanel,
  excelUploadData,
  taskPollData
}) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [disableInteraction, setDisableInteraction] = useState(false);
  const [messageState, setMessageState] = useState({
    isOpen: false,
    message: ''
  });

  //   const [isPackshotModalOpen, setPackshotModalOpen] = useState(false);

  //   const onPackshotUploadSubmit = data => {
  //     if (data.length > 0) {
  //       handlePackshotUpload({
  //         params: { upload_file: data[0].file, subpanel: menuProps.name },
  //         other: { type: 'packshot' }
  //       });
  //     }
  //   };

  const onExcelSubmit = () => {
    if (fileObjects.length > 0) {
      handleExcelUpload({
        params: { upload_file: fileObjects[0].file, subpanel: menuProps.name },
        other: { type: 'excel' }
      });
    }
  };

  useEffect(() => {
    if (
      apiRequestStatus.uploadExcel.request &&
      !apiRequestStatus.pollTask.start &&
      !apiRequestStatus.pollTask.stop
    ) {
      if (!excelUploadData.task_id) {
        if (apiRequestStatus.uploadExcel.error) {
          return setMessageState({
            isOpen: true,
            message: `Failed to upload excel file. Please try again`,
            severity: 'error',
            duration: 4000
          });
        }
        if (apiRequestStatus.uploadExcel.progress >= 0) {
          return setMessageState({
            isOpen: true,
            message: `Excel upload in progress. ${apiRequestStatus.uploadExcel.progress}% / 100% uploaded`,
            severity: 'info'
          });
        }
      }
      if (excelUploadData.task_id) {
        if (apiRequestStatus.uploadExcel.success) {
          return setMessageState({
            isOpen: true,
            message: `Excel file uploaded successfully. Please wait until it is processed`,
            severity: 'success',
            duration: 4000
          });
        }
      }
    }
    return () => {};
  }, [
    excelUploadData,
    apiRequestStatus.uploadExcel,
    apiRequestStatus.pollTask
  ]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      taskPollData.task.type === 'excelUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setMessageState({
          isOpen: true,
          message: `Error happened while processing the data. Please upload the file again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && taskPollData.data.result) {
        if (taskPollData.data.result.status.toLowerCase() === 'success') {
          setMessageState({
            isOpen: true,
            message: `Processed successfully`,
            severity: 'success',
            duration: 3000
          });
          updateSidePanel({
            params: { status: 'success' },
            other: { subpanel: menuProps.name }
          });
          return setDisableInteraction(false);
        }
        if (taskPollData.data.result.status.toLowerCase() === 'failed') {
          setMessageState({
            isOpen: true,
            message: taskPollData.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (taskPollData.data.result.status.toLowerCase() === 'pending') {
          setMessageState({
            isOpen: true,
            message: taskPollData.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, taskPollData]);

  return (
    <>
      <Typography>{menuProps.title}</Typography>
      <Typography size={13} weight={400} align="center">
        (Please upload your Merchandizer-Supervisor mapping excel file)
      </Typography>
      <VSpacer amount={20} />
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <DropzoneAreaBase
            filesLimit={1}
            previewText=""
            // acceptedFiles={[
            //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            //   'application/vnd.ms-excel'
            // ]}
            fileObjects={fileObjects}
            showAlerts={['error']}
            cancelButtonText="cancel"
            submitButtonText="submit"
            maxFileSize={50000000000}
            onAdd={newFile => {
              setFileObjects(newFile);
            }}
            onDelete={() => {
              setFileObjects([]);
            }}
            onSave={() => {}}
            useChipsForPreview
            showPreviews
            showPreviewsInDropzone={false}
            showFileNamesInPreview
          />
        </MuiThemeProvider>
        <VSpacer amount={20} />
        {fileObjects.length > 0 && (
          <Button
            disabled={disableInteraction}
            className={classes.button}
            color="success"
            onClick={() => onExcelSubmit()}
          >
            Submit
          </Button>
        )}
        <VSpacer amount={20} />
        {messageState.message.length > 0 && (
          <Typography
            size={14}
            weight={400}
            align="center"
            color={
              messageState.severity === 'success'
                ? '#AFA'
                : messageState.severity === 'error'
                ? '#f36'
                : 'rgba(255,255, 255, 0.8)'
            }
          >
            {messageState.message}
          </Typography>
        )}
        {/* {fileObjects.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPackshotModalOpen(true)}
          >
            Upload packshots
          </Button>
        )}
        <PackshotUpload
          isOpen={isPackshotModalOpen}
          setOpen={setPackshotModalOpen}
          handleSubmit={onPackshotUploadSubmit}
        /> */}
      </div>
    </>
  );
};

FirstUpload.propTypes = {
  classes: object.isRequired,
  excelUploadData: object,
  handleExcelUpload: func.isRequired,
  updateSidePanel: func.isRequired,
  menuProps: object.isRequired,
  apiRequestStatus: object.isRequired,
  taskPollData: object
};

FirstUpload.defaultProps = {
  taskPollData: {},
  excelUploadData: {}
};

export default withStyles(styles)(FirstUpload);

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
// core components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Typography from 'components/Typography/Typography';

import { NavigateBeforeIcon, NavigateNextIcon } from 'assets/icons/appIcons';
import { getFilterValue } from 'utils/filters';
// chart
import VerticalBar from './VerticalBar';

const styles = {
  container: {
    paddingBottom: 50
  },
  chart: {
    height: 450,
    width: 'auto'
  },
  title: {
    marginLeft: 79,
    marginBottom: 30
  },
  toolbar: {
    display: 'flex',
    alignItems: 'baseline'
  },
  controlsWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '& div[class*="control"]': {
      borderColor: '#214aa0',
      borderWidth: '2px',
      paddingLeft: '6px',
      paddingRight: '6px',
      '& span[class*="indicatorSeparator"]': {
        margin: '0px'
      }
    },
    '& div[class*="menu"]': {
      border: '2px solid #214aa0',
      backgroundColor: 'transparent',
      '& div[class*="MenuList"]': {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  },
  arrowButton: {
    border: '1px solid #214aa0',
    zIndex: 1,
    width: 30,
    minWidth: 30,
    height: 30,
    '&>svg': {
      width: 20,
      height: 20
    }
  }
};

const BARS_PER_PAGE = 8;

const VerticalBarComponent = ({
  classes,
  componentData: { chart_data: chartData }
}) => {
  const [dataToRender, setDataToRender] = useState({
    data: [],
    index: 0
  });

  const [activeSort, setActiveSort] = useState(
    getFilterValue({ options: chartData.sort_options })
  );

  const computeDataToRender = useCallback(dataset => {
    const subset = [];
    for (let i = 0; i < dataset.length - 1; i += BARS_PER_PAGE) {
      subset.push(dataset.slice(i, i + BARS_PER_PAGE));
    }
    setDataToRender({ data: subset, index: 0 });
  }, []);

  // TODO can be configured from backend
  const sortHelper = {
    alphabetically: () =>
      orderBy(chartData.datasets, [chartData.xAxisDataKey], ['asc']),
    low_to_high: () =>
      orderBy(chartData.datasets, [chartData.yAxisDataKey[0]], ['asc']),
    high_to_low: () =>
      orderBy(chartData.datasets, [chartData.yAxisDataKey[0]], ['desc'])
  };

  useEffect(() => {
    const defaultSort = getFilterValue({ options: chartData.sort_options });
    handleSortChange(defaultSort);
  }, [handleSortChange, chartData.sort_options]);

  const handleSortChange = useCallback(
    option => {
      const derivedDataset = sortHelper[option.value]
        ? sortHelper[option.value]()
        : chartData.datasets;
      computeDataToRender(derivedDataset);
      setActiveSort(option);
    },
    [chartData.datasets, computeDataToRender, sortHelper]
  );

  const onPageChange = direction => {
    const subsetLength = dataToRender.data.length;
    const resultIndex = dataToRender.index + direction;
    if (resultIndex < 0 || resultIndex === subsetLength) {
      return;
    }
    setDataToRender(prev => ({
      ...prev,
      index: prev.index + direction
    }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.toolbar}>
        <Typography
          color="rgba(255, 255, 255, 0.9)"
          className={classes.title}
          cardTitle
        >
          {chartData.title}
        </Typography>
        <div className={classes.controlsWrapper}>
          {chartData.allow_sorting ? (
            <>
              <Typography color="rgba(255, 255, 255, 0.7)" size="xs">
                Sort
              </Typography>
              <Dropdown
                width={140}
                title=""
                handleValueChange={handleSortChange}
                options={chartData.sort_options}
                value={activeSort}
                useMenuPortalTarget={false}
              />
            </>
          ) : null}
          <Typography color="rgba(255, 255, 255, 0.7)" size="xs">
            {`${dataToRender.data.length ? dataToRender.index + 1 : 0} of ${
              dataToRender.data.length
            }`}
          </Typography>
          <Button
            className={classes.arrowButton}
            color="dark"
            round
            justIcon
            onClick={() => onPageChange(-1)}
          >
            <NavigateBeforeIcon />
          </Button>
          <Button
            className={classes.arrowButton}
            color="dark"
            round
            justIcon
            onClick={() => onPageChange(1)}
          >
            <NavigateNextIcon />
          </Button>
        </div>
      </div>
      {!dataToRender.data[dataToRender.index] ? (
        <Typography center color="light25" size="xs">
          No data to display
        </Typography>
      ) : (
        <div className={classes.chart}>
          <VerticalBar
            data={{
              ...chartData,
              datasets: dataToRender.data[dataToRender.index]
            }}
          />
        </div>
      )}
    </div>
  );
};

// component properties
VerticalBarComponent.propTypes = {
  classes: PropTypes.object,
  componentData: PropTypes.object,
  title: PropTypes.string.isRequired
};

VerticalBarComponent.defaultProps = {
  classes: {},
  componentData: {}
};

export default withStyles(styles)(VerticalBarComponent);

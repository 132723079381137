import React, { useEffect, useState } from 'react';
import { array, func, object } from 'prop-types';
import { isEmpty } from 'lodash';

import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Typography from 'components/Typography/Typography';
import TableWithTooltipWrapper from 'table/TableWithTooltipWrapper';
import VerticalBarComponent from 'charts/verticalBar/VerticalBarComponent';

import enums from 'models/enums';
import { getFilterValue } from 'utils/filters';
import { chartData } from './mockData';

const styles = {
  container: {
    height: 'auto',
    marginTop: '30px'
  },
  content: {
    padding: '50px 20px 20px 20px',
    background: '#26293c'
  },
  appBar: {
    backgroundColor: '#1e1e2b',
    width: 'fit-content',
    borderRight: '1px solid #444a62',
    borderLeft: '1px solid #444a62',
    '& span': {
      textTransform: 'none'
    },
    '& span[class*="MuiTabs-indicator"]': {
      backgroundColor: '#214aa0',
      top: 0,
      height: '5px'
    },
    '& button[class*="Mui-selected"]': {
      backgroundColor: '#26293c'
    }
  },
  tabHorizontalLine: {
    position: 'absolute',
    width: '160px',
    backgroundColor: '#444a62',
    height: '5px',
    top: 0
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px'
  },
  noDataMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#eaeaea',
    marginTop: '20px',
    marginBottom: '20px',
    width: '300px',
    borderRadius: '10px',
    padding: '20px',
    '& button': {
      width: '22px',
      height: '22px',
      minWidth: '22px',
      marginRight: '12px'
    },
    '& h4': {
      display: 'flex',
      alignItems: 'center'
    }
  }
};

const TabContentWrapper = ({
  apiRequestStatus,
  classes,
  handleTabChange,
  handleGranularityChange,
  metricTabs,
  performanceTable,
  tabsLayout,
  setSelectedTab
}) => {
  // this state is used to maintain only one tooltip open at a time if there are multiple tables of the same type
  const [tableTooltipStatus, setTableTooltipStatus] = useState({
    status: false,
    key: ''
  });
  const [activeTab, setActiveTab] = useState({
    key: tabsLayout[0].key,
    data: tabsLayout[0]
  });
  const [granularitiesToRender, setFiltersToRender] = useState([]);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const onTabChange = value => {
    setSelectedTab(tabsLayout.filter(tab => tab.key === value)[0]);
    setShowNoDataMessage(false);
    const tabIndex = tabsLayout.findIndex(tab => tab.key === value);
    setActiveTab({
      key: value,
      data: tabsLayout[tabIndex]
    });
    handleTabChange({ key: value, index: tabIndex });
  };

  useEffect(() => {
    const { granularities } = activeTab.data.performance_table;
    setFiltersToRender(
      granularities.map(gr => {
        return { ...gr, value: getFilterValue({ options: gr.options }) };
      })
    );
  }, [activeTab]);

  useEffect(() => {
    if (
      apiRequestStatus.getMetricTabs.status === enums.apiRequest.SUCCESS &&
      apiRequestStatus.getTableData.status === enums.apiRequest.SUCCESS &&
      isEmpty(metricTabs[activeTab.key]) &&
      isEmpty(performanceTable)
    ) {
      setShowNoDataMessage(true);
    }
  }, [apiRequestStatus]);

  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={activeTab.key}
          onChange={(_e, value) => onTabChange(value)}
          aria-label="wrapped label tabs"
        >
          {tabsLayout.map(tab => (
            <Tab
              key={tab.key}
              value={tab.key}
              label={
                <>
                  <span className={classes.tabHorizontalLine} />
                  <span>{tab.title}</span>
                </>
              }
              wrapped
            />
          ))}
        </Tabs>
      </AppBar>
      <div className={classes.content}>
        {apiRequestStatus.getMetricTabs.status === enums.apiRequest.REQUEST ? (
          <div className={classes.loaderContainer}>
            <Loader color="primary" simple circular />
          </div>
        ) : activeTab.data.has_chart && metricTabs[activeTab.key] ? (
          <VerticalBarComponent
            componentData={metricTabs[activeTab.key]}
            title="Merchandisers"
            data={chartData}
          />
        ) : null}
        {showNoDataMessage ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.noDataMessageContainer}>
              <Button
                color="dark"
                round
                justIcon
                onClick={() => setShowNoDataMessage(false)}
              >
                <CloseIcon />
              </Button>
              <Typography color="black">Data not available</Typography>
            </div>
          </div>
        ) : null}
        {activeTab.data.has_table ? (
          <TableWithTooltipWrapper
            apiRequestStatus={apiRequestStatus.getTableData}
            granularitiesToRender={granularitiesToRender}
            handleGranularityChange={data =>
              handleGranularityChange({ ...data, activeTabKey: activeTab.key })
            }
            height={400}
            setTableTooltipStatus={setTableTooltipStatus}
            tableData={performanceTable}
            tableName={activeTab.key} // this prop should be unique for every table
            tableTooltipStatus={tableTooltipStatus}
            title={activeTab.data.performance_table.title}
            width="100%"
          />
        ) : null}
      </div>
    </div>
  );
};

TabContentWrapper.propTypes = {
  apiRequestStatus: object.isRequired,
  classes: object.isRequired,
  handleTabChange: func.isRequired,
  handleGranularityChange: func.isRequired,
  metricTabs: object.isRequired,
  performanceTable: object.isRequired,
  tabsLayout: array.isRequired,
  setSelectedTab: func.isRequired
};

export default withStyles(styles)(TabContentWrapper);

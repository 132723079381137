import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// utils
import { appSelector } from 'redux-utils/selectors/index';
import { authActions } from 'redux-utils/actions/index';
import ls from 'lib/core/storageFactory';

// components
import StaticsContent from './StaticsContent';
import Notification from 'components/Notification/Notification';
import Loader from 'components/Loader/Loader';
import LoginVectorBackground from './LoginVectorBackground';

// assets
import infivizLogo from 'assets/svg/infiviz_logo.svg';
import mailIcon from 'assets/svg/mail_icon.svg';
import backIcon from 'assets/svg/login_back_icon.svg';

// stylesheet
import './index.css';
import { LoginHeader } from './constants';

const ForgotPassword = ({ dispatchForgotPasswordRequest, authState }) => {
  const [inputValues, setInputValues] = useState({
    forgotPasswordEmail: ''
  });
  const [notification, setNotification] = useState({
    display: false,
    message: ''
  });

  const handleFormSubmit = ({ e, type }) => {
    e.preventDefault();
    if (type === 'forgotPassword') handleForgotPasswordSubmit();
  };
  const handleInputChange = ({ value, field }) => {
    setInputValues(p => ({ ...p, [field]: value }));
  };
  const handleForgotPasswordSubmit = () => {
    // validated the email once again
    const regExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (regExp.test(inputValues.forgotPasswordEmail)) {
      dispatchForgotPasswordRequest({
        email: inputValues.forgotPasswordEmail.trim()
      });
    }
  };

  useEffect(() => {
    if (authState.apiStatus.forgotPassword.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.forgotPassword.message
      });
    }
  }, [authState.apiStatus]);
  const handleNotificationClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      display: false,
      message: ''
    });
  };
  useEffect(() => {
    let timeout;
    if (authState.forgotPassword && authState.forgotPassword.message) {
      setNotification({
        display: true,
        message: authState.forgotPassword.message
      });
      timeout = setTimeout(() => {
        window.location = '/login';
      }, [3000]);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [authState.forgotPassword]);
  const goPrevStep = () => {
    // clear the local storage to remove client's endpoint
    ls.clear();
    window.location = '/login';
  };
  return (
    <div className="loginContainer ">
      <div className="staticContentWrapper">
        <StaticsContent />
      </div>
      <Notification
        duration={3000}
        message={notification.message}
        isOpen={notification.display}
        handleClose={handleNotificationClose}
      />
      <div className="loginWrapper ">
        <div className="mobileViewHeader">{LoginHeader}</div>
        <LoginVectorBackground />
        <div className="loginContainerWrapper ">
          <div className="loginContainerHeader">
            <img src={infivizLogo} alt="infiviz_logo"></img>
          </div>

          <div className="loginContainerBody forgotPassowrdWrapper">
            <div role="button" className="loginBackButton">
              <img src={backIcon} alt="backIcon" onClick={goPrevStep} />
            </div>
            <div className="forgotPassowrdHeadings">
              <h2>Forgot Password</h2>
              <p>
                <em>Dont worry, happens to the best of us.</em>
              </p>
              <h3>Please enter your registered email address</h3>
            </div>

            <form
              onSubmit={e => {
                handleFormSubmit({ e, type: 'forgotPassword' });
              }}
            >
              <div className="inputRow forgotPasswordInputRow">
                <label className="inputRowLable" htmlFor="emailAddress">
                  Email
                  <img src={mailIcon} alt="mail_icon"></img>
                </label>
                <div className="clientInputWrapper">
                  <input
                    type="text"
                    className="inputRowComponent"
                    id="emailAddress"
                    placeholder="Name@email.com"
                    onChange={e =>
                      handleInputChange({
                        field: 'forgotPasswordEmail',
                        value: e.target.value
                      })
                    }
                    value={inputValues.forgotPasswordEmail}
                  ></input>
                </div>
              </div>
              {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                inputValues.forgotPasswordEmail
              )
                ? null
                : inputValues.forgotPasswordEmail !== '' && (
                    <div className="inputRow forgotPasswordInputRow">
                      <label
                        className="inputRowLable errorLable"
                        htmlFor="emailAddress"
                      >
                        Please enter a valid email address
                      </label>
                    </div>
                  )}
              <div className="loginContainerActions">
                <button
                  className="actionButton"
                  disabled={inputValues.forgotPasswordEmail.trim() === ''}
                  type="submit"
                >
                  Email me recovery link
                  {authState.apiStatus.forgotPassword.request &&
                    !authState.apiStatus.forgotPassword.success &&
                    !authState.apiStatus.forgotPassword.error && (
                      <Loader
                        plain
                        simple
                        inline
                        circular
                        secondary
                        className="login-loader"
                      />
                    )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  authState: appSelector.getAuthState(state)
});

/*
    Connect dispatch methods to props so that you can call the methods
    from the scope of the component's props
  */
const mapDispatchToProps = dispatch => ({
  dispatchLoginRequest: payload =>
    dispatch(authActions.userLoginRequest(payload)),
  dispatchClientRequest: payload =>
    dispatch(authActions.clientValidationRequest(payload)),
  dispatchForgotPasswordRequest: payload =>
    dispatch(authActions.forgotPasswordRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

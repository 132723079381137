import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, isEqual, capitalize } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getRouteID } from 'lib/utils/getActiveRoute';
// assets
import { ExpandLessIcon, ExpandMoreIcon } from 'assets/icons/appIcons';

import Typography from 'components/Typography/Typography';
import Notification from 'components/Notification/Notification';
import Sidebar from 'pages/Admin/SideBar';
import AppImagesFilters from 'pages/AppImages/AppImagesFilters';
import GenericSEFilters from 'pages/GenericSE/GenericSEFilters';
import SEFilterContainer from 'pages/StoreExplorer/SEFilterContainer';
import FilterContainer from 'layout/Filter/FilterContainer';

import metadata from 'metadata';

import {
  genericSEActions,
  storeExplorerActions,
  appImageActions,
  storeNewActions,
  appActions
} from 'redux-utils/actions/index';
import {
  appImageSelector,
  appSelector,
  genericSESelector
} from 'redux-utils/selectors/index';
import ls from 'lib/core/storageFactory';

const commonRoutes = [
  'share-of-shelf',
  'display',
  'product-position',
  'on-shelf-availability',
  'sku-assortment'
];

// function to check if the sidebar route is currently active
const checkActiveRoute = (path, routeName) => path.indexOf(routeName) > -1;

// stateless component for non-nested menu item
const NavItem = ({
  classes,
  handleItemClick,
  item,
  listItemClasses,
  listIcon: ListIcon
}) => (
  <NavLink to={item.path} className={classes.item} activeClassName="active">
    <ListItem
      onClick={handleItemClick}
      button
      className={classes.itemLink + listItemClasses}
    >
      <ListItemIcon className={classes.itemIcon}>{ListIcon}</ListItemIcon>
      <ListItemText
        // primary={t(`sideMenu.${item.sidebarName}`)}
        primary={item.sidebarName}
        className={classes.itemText}
        disableTypography
      />
    </ListItem>
  </NavLink>
);

// stateless component for nested menu item
const ExpandList = ({
  classes,
  handleItemClick,
  handleItemExpand,
  isExpanded,
  item,
  listIcon: ListIcon,
  listItemClasses,
  location,
  t,
  isNested
}) => (
  <>
    <ListItem
      className={`${listItemClasses} ${classes.itemLink}`}
      button
      onClick={() => handleItemExpand(item.path)}
    >
      <ListItemIcon className={classes.itemIcon}>
        <ListIcon />
      </ListItemIcon>
      <ListItemText
        primary={item.sidebarName}
        className={classes.itemText}
        disableTypography
      />
      {isExpanded[item.path] ? (
        <ExpandLessIcon className={classes.iconRight} />
      ) : (
        <ExpandMoreIcon className={classes.iconRight} />
      )}
    </ListItem>
    <Collapse in={isExpanded[item.path]} timeout="auto" unmountOnExit>
      {item.subRoutes.map((sub, i) => {
        const activeClass = classNames({
          [` ${classes.indicator}`]: checkActiveRoute(
            location.pathname,
            sub.path
          )
        });
        const SubIcon = sub.icon;
        return sub.subRoutes ? (
          <div
            key={i}
            className={`${classes.collapseItem} ${classes.nestedRoute}`}
          >
            <ExpandList
              isNested
              location={location}
              t={t}
              item={sub}
              classes={classes}
              handleItemClick={handleItemClick}
              listItemClasses={activeClass}
              listIcon={SubIcon}
              isExpanded={isExpanded}
              handleItemExpand={handleItemExpand}
            />
          </div>
        ) : (
          <NavLink
            key={i}
            to={sub.path}
            className={`${classes.item} ${classes.collapseItem}`}
          >
            <ListItem
              button
              onClick={handleItemClick}
              className={classes.itemLink + activeClass}
            >
              <ListItemText
                inset={!isNested}
                primary={sub.sidebarName}
                className={`${classes.itemText} ${classes.fullText}`}
                disableTypography
              />
              <ListItemText
                inset={!isNested}
                primary={sub.acronym}
                className={`${classes.itemText} ${classes.acronym}`}
                disableTypography
              />
            </ListItem>
          </NavLink>
        );
      })}
    </Collapse>
  </>
);

const SidebarMenu = React.memo(
  ({
    classes,
    handleDateChange,
    handleStoreChange,
    handleSessionChange,
    stores,
    store,
    sessions,
    session,
    value,
    filtersToRender,
    handleFilterChange,
    isFiltersReadyToRender,
    handleFilterChangeTestStoreExplorer,
    // routeIDParam,
    // handleFilterChangeForAllPages,
    dashboardType,
    subPanel,
    activeSubRoute,
    dropdownStyles,
    dropdownTitleStyles,
    layout,
    currentFilter,
    dispatchCurrentFilterRequest,
    dispatchSessionDataRequest,
    handleDrawerToggle,
    drawerRight,
    isFetchingFilter,
    dispatchGetImages,
    clearSessionData,
    clearTSEData,
    layoutValue,
    sessionData,
    sessionFilterValues,
    sessionFilterValuesStatus,
    dispatchChartListRequest,
    chartList,
    dispatchChartDataRequest,
    dispatchGetAppImages,
    fetchedChart,
    isFetchingChartList,
    isFetchingSessionData,
    dispatchProductFilter,
    setSidebarFilterAction,
    clearSidebarFilterAction,
    clearAppImages,
    clearChartList,
    isFetchingSessionDataSuccessful,
    sidebarFilters,
    dispatchBottomUpFilterRequest,
    bottomUpFilters,
    isFetchingFilters,
    setAreFiltersSetToStore,
    areFiltersSet,
    photoAttributes,
    photos
  }) => {
    const { pathname, search } = useLocation();
    const [notificationSnack, setNotificationSnack] = useState({});
    const [routeIDParam, setRouteIDParam] = useState(null);
    useEffect(() => {
      setRouteIDParam(getRouteID(window.location.pathname));
    }, [window.location.pathname]);

    const handleItemClick = () => {
      if (drawerRight) handleDrawerToggle();
    };

    const [path, setPath] = useState(null);

    const handleItemExpand = path => {
      this.setState(state => ({ [path]: !state[path] }));
      setPath(!path);
    };

    const [filterOrder, setFilterOrder] = useState([]);
    const [filterState, setFilterState] = useState({});
    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {
      if (!isEmpty(filterState) || !isEmpty(bottomUpFilters)) {
        const areAllFiltersSet = filterOrder.every(
          i => bottomUpFilters[i]?.options.length && filterState[i].value
        );
        setAreFiltersSetToStore(areAllFiltersSet);
      }
    }, [filterState, bottomUpFilters]);

    const clearAppData = () => {
      clearSessionData();
      clearTSEData();
      clearAppImages();
      clearChartList();
    };

    const getFilterApiVersionFromLayout = (filterType, layout) => {
      const filterDefaults = layout.defaults;
      return (
        filterDefaults.find(filter => filter.value === filterType)?.version ||
        'v2'
      );
    };

    const handleCurrentFilterChange = (filter, isSynthetic) => {
      // console.log('filterHere', filter, isSynthetic);
      window.history.replaceState(null, null, window.location.pathname);
      if (!isEmpty(sidebarFilters)) {
        clearSidebarFilterAction();
      }
      if (
        routeIDParam === 'test-store-explorer' &&
        filter.key === 'fl_category' &&
        filter?.val?.value
      ) {
        dispatchProductFilter({
          params: {
            fl_category: filter.val.value
          }
        });
      }
      ls.set('sessionId', null);
      clearAppData();
      const indexOfCurrent = filterOrder.indexOf(filter.key);
      const filtersToClear = filterOrder.slice(
        indexOfCurrent + 1,
        filterOrder.length
      );

      const filterValues = { ...filterState };
      filterValues[filter.key] = {
        ...filterValues[filter.key],
        value: filter.val
      };

      // clear next filters
      filtersToClear.map(item => {
        filterValues[item] = {
          ...filterValues[item],
          value: {},
          options: []
        };
        ls.set(item, [], true);
      });

      // make API call
      let payload = {};
      payload.filter_type = filterOrder[indexOfCurrent + 1];
      const selectedFiltersKeys = filterOrder.slice(0, indexOfCurrent + 1);
      const selectedFilters = selectedFiltersKeys.reduce((accu, key) => {
        // console.log('isSynthetic', key, sidebarFilters, bottomUpFilters);
        const obj = {
          field: key,
          value: isSynthetic
            ? bottomUpFilters[key].hashedOptions[sidebarFilters[key]]
              ? sidebarFilters[key]
              : bottomUpFilters[key].options[0]?.value
            : filterValues[key].value.value
        };

        accu.push(obj);
        return accu;
      }, []);

      payload.filters = selectedFilters;
      // console.log('isSynthetic', filter, isSynthetic, payload);
      payload['route-id'] = routeIDParam;

      if (indexOfCurrent + 1 !== filterOrder.length) {
        ls.set('sessionId', null);
        const filterVersion = layoutValue.find(
          item => item.routeID === 'store-explorer-v2'
        ).defaults[indexOfCurrent + 1].version;
        payload.api_version = filterVersion;
        dispatchCurrentFilterRequest({
          params: payload
        });
      }
      setFilterState(filterValues);
    };

    useEffect(() => {
      clearAppData();
    }, []);

    const sidebarFilterRef = useRef('');
    useEffect(() => {
      sidebarFilterRef.current = sidebarFilters;
    }, [sidebarFilters]);

    useEffect(() => {
      const areAllFiltersAvailable = filterOrder.every(i =>
        bottomUpFilters.hasOwnProperty(i)
      );
      let newState = { ...filterState };
      if (bottomUpFilters.incomingFilter && !isEmpty(newState)) {
        // if (!bottomUpFilters[bottomUpFilters.incomingFilter].options.length) {
        //   let title = '';
        //   const allKeys = bottomUpFilters.incomingFilter.split('_');
        //   title = allKeys[allKeys.length - 1];
        //   toast.warn(
        //     `No ${capitalize(title)} available for the selected filters.`,
        //     {
        //       position: 'bottom-right',
        //       autoClose: 3000,
        //       hideProgressBar: true,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: 'light'
        //     }
        //   );
        // }
        newState[bottomUpFilters.incomingFilter].options =
          bottomUpFilters[bottomUpFilters.incomingFilter].options;
      }
      if (areAllFiltersAvailable) {
        if (!isEmpty(bottomUpFilters) && !isEmpty(sidebarFilters)) {
          filterOrder.forEach(item => {
            if (bottomUpFilters[item]) {
              if (newState[item]) {
                newState[item].options = bottomUpFilters[item].options;
                newState[item].value =
                  bottomUpFilters[item].hashedOptions[sidebarFilters[item]] ||
                  bottomUpFilters[item].options[0];
              }
              if (
                // bottomUpFilters[item].options.length &&
                !bottomUpFilters[item].options[0] ||
                !bottomUpFilters[item].hashedOptions[sidebarFilters[item]]
              ) {
                let title = '';
                const allKeys = item.split('_');
                title = allKeys[allKeys.length - 1];
                toast.warn(
                  `${capitalize(
                    title
                  )} has been changed to the next available option.`,
                  {
                    position: 'top-right',
                    autoClose: 8000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                  }
                );
              }
              setFilterState(newState);
              if (!bottomUpFilters[item].hashedOptions[sidebarFilters[item]]) {
                const nextFilterValueForType = bottomUpFilters[item].options[0];
                let newState = { ...filterState };
                if (newState[bottomUpFilters.incomingFilter]) {
                  newState[
                    bottomUpFilters.incomingFilter
                  ] = nextFilterValueForType;
                }
                handleCurrentFilterChange(
                  {
                    val: nextFilterValueForType,
                    key: item
                  },
                  true
                );
              }
              // console.log(
              //   'bottomUpFiltersD',
              //   bottomUpFilters[item].hashedOptions[sidebarFilters[item]],
              //   item
              // );
            }
          });
        }

        // console.log('unavailableFilters', unavailableFilters);
      }
    }, [bottomUpFilters]);

    useEffect(() => {
      // const payload = { filter_type: filterOrder[0], filters: [] };
      if (
        layoutValue.length &&
        routeIDParam &&
        filterOrder.length &&
        isEmpty(currentFilter) &&
        !isFetchingFilter
      ) {
        const currentLayoutValue = layoutValue.find(
          item => {
            return ((window.location.href.includes('survey') && item.routeID === 'survey') || (!window.location.href.includes('survey') && item.routeID === 'store-explorer-v2'))
          }
        );
        // dispatch API call with first filter
        const firstDefault = currentLayoutValue.defaults[0].value;
        if (
          filterOrder &&
          filterOrder[0] &&
          !sessionId
          // &&
          // !ls.get('sessionId')
        ) {
          dispatchCurrentFilterRequest({
            params: {
              filter_type: firstDefault,
              api_version: currentLayoutValue.defaults[0].version,
              filters: [],
              'route-id': routeIDParam
            }
          });
        }
      }
    }, [filterOrder, routeIDParam]);

    useEffect(() => {
      const currentLayoutValue = layoutValue.find(
        item => item.routeID === 'store-explorer-v2'
      );
      // display notification if no options available
      const errorTitle = currentLayoutValue?.defaults.find(
        item => item.label === currentFilter.filter_typepnotification
      );

      if (
        currentFilter &&
        currentFilter.filter_type &&
        !currentFilter.options.length
      ) {
        setNotificationSnack({
          isOpen: true,
          message: `No ${
            errorTitle
              ? errorTitle
              : capitalize(currentFilter.filter_type.substring(3))
          } available for the selected filters`
        });
      }
      if (currentFilter && currentFilter.filter_type) {
        const currentFilterKey = currentFilter.filter_type;
        const tempFilterState = { ...filterState };
        const hashedOptions = currentFilter.options.reduce((obj, item) => {
          return {
            ...obj,
            [item.value]: item
          };
        }, {});
        tempFilterState[currentFilterKey] = {
          ...tempFilterState[currentFilterKey],
          options: currentFilter.options,
          hashedOptions
        };

        // clear subsequent filters
        const indexOfCurrent = filterOrder.indexOf(currentFilterKey);
        const filtersToClear = filterOrder.slice(
          indexOfCurrent + 1,
          filterOrder.length
        );

        if (!ls.get('sessionId')) {
          filtersToClear.map(item => {
            tempFilterState[item] = {
              ...tempFilterState[item],
              value: {},
              options: []
            };
            ls.set(item, [], true);
          });
        }

        ls.set(currentFilterKey, currentFilter.options, true);
        setFilterState(tempFilterState);
      }
    }, [currentFilter]);

    useEffect(() => {
      const prevPath = window.sessionStorage.getItem('prevPath') ?? '';
      window.sessionStorage.setItem('prevPath', pathname);
      if (
        layoutValue.length &&
        routeIDParam !== 'redirect'
      ) {
        if (!pathname.includes('survey') && !isEmpty(filterState) && !prevPath.includes('survey')) {
          return;
        }
        const currentLayoutValue = layoutValue.find(
          item => item.routeID === (pathname.includes('survey') ?  'survey' : 'store-explorer-v2')
        );
        if (!currentLayoutValue) return;
        const filterOrderFromAPI = currentLayoutValue.defaults.map(
          item => item.value
        );
        let tempFilterState = {};
        currentLayoutValue.defaults.map(
          item =>
            (tempFilterState[item.value] = {
              key: item.value,
              label: item.label,
              value: {},
              options: []
            })
        );
        setFilterState(tempFilterState);
        setFilterOrder(filterOrderFromAPI);
      }
    }, [layout, pathname]);

    const checkForEmptyFilter = () => {
      let payload = {};
      let filterValues = {};
      let isFilterEmpty = false;
      //
      filterOrder.map(item => (payload[item] = filterState[item].options));
      filterOrder.map(item => (filterValues[item] = filterState[item].value));
      Object.keys(payload).forEach(key => {
        if (!payload[key]?.length) isFilterEmpty = true;
      });
      return !isFilterEmpty;
    };

    const setSidebarFilterInStore = payload => {
      if (!isEqual(payload, sidebarFilters)) {
        setSidebarFilterAction({ ...payload });
      }
    };

    useEffect(() => {
      const canFetchSessionData = checkForEmptyFilter();
      const lastFilterKey = filterOrder[filterOrder.length - 1];
      if (
        filterState[lastFilterKey] &&
        !isEmpty(filterState[lastFilterKey].value)
        // && canFetchSessionData
      ) {
        let payload = {};
        filterOrder.map(
          item => (payload[item] = filterState[item].value.value)
        );
        if (routeIDParam === 'test-store-explorer') {
          payload.test = true;

          setSidebarFilterInStore(payload);
          dispatchGetImages({ params: payload });
        } else if (routeIDParam === 'store-explorer-v2') {
          if (!isFetchingSessionData) {
            setSidebarFilterInStore(payload);
            dispatchSessionDataRequest({
              params: payload
            });
          }
        } else {
          setSidebarFilterInStore(payload);
          if (isEmpty(photoAttributes) && !photos.length && routeIDParam !== 'survey') {
            dispatchGetAppImages({ params: payload });
          }
        }

        if (
          routeIDParam === 'store-explorer-v2' &&
          !isFetchingChartList &&
          !chartList.length
        ) {
          dispatchChartListRequest({
            params: {
              route_id: routeIDParam,
              ...payload
            }
          });
        }
      }
    }, [filterOrder, filterState, routeIDParam]);

    // useEffect(() => {
    //   if (sessionFilterValuesStatus.error) {
    //     setNotification({
    //       display: true,
    //       message: sessionFilterValuesStatus.message,
    //       callback: handleNotificationCloseAndRefresh
    //     });
    //   }
    // }, [sessionFilterValuesStatus]);

    useEffect(() => {
      const session = new URLSearchParams(search).get('q');
      setSessionId(session);
      if (!ls.get('sessionId')) {
        ls.set('sessionId', session);
      }
    }, [search]);

    useEffect(() => {
      if (
        filterState.fl_category &&
        filterState.fl_category.value &&
        filterState.fl_category.value.value
      ) {
        dispatchProductFilter({
          params: {
            fl_category: filterState.fl_category.value.value
          }
        });
      }
    }, [routeIDParam]);

    useEffect(() => {
      const bottomUpFilterOrder = [...filterOrder].reverse();
      bottomUpFilterOrder.map((item, index) => {
        const filterForPayload = bottomUpFilterOrder.slice(
          index + 1,
          bottomUpFilterOrder.length
        );
        let payload = { filter_type: item, filters: [] };
        let shouldFetch = true;
        filterForPayload.map(filterItem => {
          if (!sidebarFilterRef.current[filterItem]) {
            shouldFetch = false;
          }
          if (item !== filterItem) {
            payload.filters.push({
              field: filterItem,
              value: sidebarFilterRef.current[filterItem]
            });
          }
        });
        payload['route-id'] = routeIDParam;
        payload['api_version'] = getFilterApiVersionFromLayout(
          payload.filter_type,
          layout
        );
        // const payload = {};
        // if(!isEmpty(sidebarFilters)){}
        if (shouldFetch && !search) {
          dispatchBottomUpFilterRequest({
            params: payload
          });
        }
      });
    }, [routeIDParam]);

    // useEffect(() => {
    //   console.log('rerender', routeIDParam);
    // }, [routeIDParam]);
    // useEffect(() => {
    //   console.log('rerender');
    // }, [sidebarFilters]);

    useEffect(() => {
      if (Object.keys(sessionFilterValues).length && sessionId) {
        const newState = {
          ...filterState
        };
        Object.keys(newState).reduce((accu, item) => {
          const filterKey = newState[item].key;
          newState[item].value = sessionFilterValues[filterKey];
        }, {});
        setFilterState(newState);
        let payload = {};
        filterOrder.map(
          item => (payload[item] = filterState[item].value.value)
        );
        if (routeIDParam === 'test-store-explorer') {
          payload.test = true;

          setSidebarFilterInStore(payload);
          dispatchGetImages({ params: payload });
        } else if (routeIDParam === 'store-explorer-v2') {
          if (!isFetchingSessionData) {
            setSidebarFilterInStore(payload);
            dispatchSessionDataRequest({
              params: payload
            });
          }
          // make calls here
          filterOrder.map(async (item, index) => {
            const filterForPayload = filterOrder.slice(0, index + 1);
            let payload = { filter_type: item, filters: [] };
            filterForPayload.map(filterItem => {
              if (item !== filterItem) {
                payload.filters.push({
                  field: filterItem,
                  value: sessionFilterValues[filterItem].value
                });
              }
            });
            payload['route-id'] = routeIDParam;
            const initialFilterDefaults = layoutValue.find(
              item => item.routeID === 'store-explorer-v2'
            ).defaults;
            const filterApiEndpointVersion = initialFilterDefaults.find(
              filterDefault => filterDefault.value === item
            ).version;
            payload.api_version = filterApiEndpointVersion;
            await dispatchCurrentFilterRequest({
              params: payload
            });
          });
        } else {
          setSidebarFilterInStore(payload);
        }
        if (routeIDParam === 'store-explorer-v2' && !isFetchingChartList) {
          dispatchChartListRequest({
            params: {
              route_id: routeIDParam,
              ...payload
            }
          });
        }
      }
    }, [sessionFilterValues]);

    const prepareChartDataAPICall = ({ chart }) => {
      let payload = {};
      filterOrder.forEach(item => {
        payload[item] = filterState[item]?.value?.value;
      });

      // check for empty filter values
      let isEmpty = false;
      Object.keys(payload).forEach(key => {
        if (payload[key] == undefined) isEmpty = true;
      });

      if (!isEmpty) {
        if (!fetchedChart?.includes(chart.name)) {
          dispatchChartDataRequest({
            params: {
              chart_name: chart.name,
              fl_chart_type: chart.type,
              ...payload
            }
          });
        }
      }
      // }
    };

    useEffect(() => {
      // condition check for filter availability
      if (chartList.length > 0 && routeIDParam === 'store-explorer-v2') {
        chartList.forEach(chart => {
          if (!fetchedChart?.includes(chart.name)) {
            prepareChartDataAPICall({ chart });
          }
        });
      }
    }, [chartList]);

    const isValueEmpty = value => {
      const objType = typeof value;
      if ((objType === 'object' && isEmpty(value)) || objType === 'undefined')
        return true;
      return false;
    };

    useEffect(() => {
      let payload = {};
      let isFilterEmpty = false;
      filterOrder.map(item => (payload[item] = filterState[item].value));
      Object.keys(payload).forEach(key => {
        if (isValueEmpty(payload[key]?.value)) {
          isFilterEmpty = true;
        }
      });
      if (isFilterEmpty) {
        clearAppData();
      }
    }, [filterState]);

    return (
      <>
        <List className={`${classes.list} ${classes.activeItem}`}>
          {(routeIDParam === 'store-explorer-v2' ||
            routeIDParam === 'test-store-explorer' ||
            routeIDParam === 'app-images' || routeIDParam === 'survey') && (
            <GenericSEFilters
              key={filtersToRender.key}
              filterState={filterState}
              handleFilterChange={handleFilterChange}
              handleCurrentFilterChange={handleCurrentFilterChange}
              filtersToRender={filtersToRender || []}
              dropdownTitleStyles={dropdownTitleStyles}
              dropdownStyles={dropdownStyles}
              dashboardType={dashboardType}
              isFetchingFilter={isFetchingFilter}
            />
          )}
          {/* {commonRoutes.includes(routeIDParam) ? (
            <FilterContainer
              routeID={routeIDParam}
              handleFilterChange={handleFilterChangeForAllPages}
              dashboardType={dashboardType}
            />
          ) : null} */}
          {routeIDParam === 'admin' ? (
            <Sidebar
              subPanel={subPanel}
              activeSubRoute={activeSubRoute}
              handleItemClick={handleItemClick}
            />
          ) : null}
          <Typography
            className={classes.versionLabel}
            align="center"
            weight="400"
            size={12}
          >{`v${metadata.version}`}</Typography>
        </List>
        <ToastContainer />
        <Notification
          message={notificationSnack.message}
          isOpen={notificationSnack.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          handleClose={() =>
            setNotificationSnack({ isOpen: false, message: '' })
          }
        />
      </>
    );
  }
);

// component properties
NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  listIcon: PropTypes.any.isRequired,
  listItemClasses: PropTypes.string.isRequired
};

ExpandList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  handleItemExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  listIcon: PropTypes.any.isRequired,
  listItemClasses: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isNested: PropTypes.bool
};

ExpandList.defaultProps = {
  isNested: false
};

SidebarMenu.propTypes = {
  drawerRight: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  sidebarColor: PropTypes.string.isRequired,
  stores: PropTypes.array,
  store: PropTypes.object,
  session: PropTypes.object,
  sessions: PropTypes.array,
  value: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleStoreChange: PropTypes.func.isRequired,
  handleSessionChange: PropTypes.func.isRequired,
  filtersToRender: PropTypes.array.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isFiltersReadyToRender: PropTypes.bool.isRequired,
  handleFilterChangeTestStoreExplorer: PropTypes.func.isRequired,
  routeIDParam: PropTypes.any.isRequired,
  handleFilterChangeForAllPages: PropTypes.func.isRequired,
  dashboardType: PropTypes.string.isRequired,
  subPanel: PropTypes.array.isRequired,
  activeSubRoute: PropTypes.any.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  dropdownStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object
};

SidebarMenu.defaultProps = {
  stores: [],
  store: {},
  session: {},
  sessions: [],
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

const mapStateToProps = state => ({
  currentFilter: genericSESelector.getCurrentFilter(state),
  layout: genericSESelector.selectLayout(state),
  isFetchingFilter: genericSESelector.getIsFetchingFilter(state),
  layoutValue: genericSESelector.getLayout(state),
  sessionData: genericSESelector.selectSessionData(state),
  sessionFilterValues: genericSESelector.selectFilterValues(state),
  sessionFilterValuesStatus: genericSESelector.sessionFilterValuesStatus(state),
  chartList: genericSESelector.selectChartList(state),
  fetchedChart: genericSESelector.selectChartFetched(state),
  isFetchingChartList: genericSESelector.selectChartListFetching(state),
  isFetchingSessionData: genericSESelector.isFetchingSessionData(state),
  sidebarFilters: appSelector.getSidebaFilters(state),
  isFetchingSessionDataSuccessful: genericSESelector.isFetchingSessionDataSuccessful(
    state
  ),
  bottomUpFilters: genericSESelector.getBottomUpFilter(state),
  isFetchingFilters: genericSESelector.isFetchingFilter(state),
  areFiltersSet: appSelector.getAreFiltersSet(state),
  photoAttributes: appImageSelector.getPhotoAttributes(state),
  photos: appImageSelector.getPhotos(state)
});

const mapDispatchToProps = {
  dispatchBottomUpFilterRequest: genericSEActions.getBottomUpFilter,
  dispatchCurrentFilterRequest: genericSEActions.getCurrentFilter,
  dispatchSessionDataRequest: genericSEActions.getSessionData,
  dispatchGetImages: storeExplorerActions.getShelfImageAction,
  clearSessionData: genericSEActions.clearSessionData,
  dispatchChartListRequest: genericSEActions.getChartList,
  dispatchChartDataRequest: payload => genericSEActions.getChartData(payload),
  dispatchGetAppImages: payload => appImageActions.getPhotos(payload),
  clearTSEData: storeExplorerActions.clearSessionData,
  clearChartList: genericSEActions.clearChartList,
  clearAppImages: appImageActions.clearAppImages,
  dispatchProductFilter: payload =>
    storeNewActions.productFilterAction(payload),
  setSidebarFilterAction: payload => appActions.setSidebarFilterAction(payload), // to set filterValues in the store
  clearSidebarFilterAction: payload =>
    appActions.clearSidebarFilterAction(payload),
  setAreFiltersSetToStore: payload =>
    appActions.setAreFiltersSetToStore(payload)
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);

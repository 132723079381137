const types = {
  routeChange: 'ADMIN_ROUTE_CHANGE',
  updateSidePanel: 'UPDATE_SIDE_PANEL',
  getSubPanel: {
    request: 'GET_SUB_PANEL_REQUEST',
    success: 'GET_SUB_PANEL_SUCCESS',
    failure: 'GET_SUB_PANEL_FAILURE'
  },
  getDashboardUsersData: {
    request: 'GET_DASHBOARD_USERS_DATA_REQUEST',
    success: 'GET_DASHBOARD_USERS_DATA_SUCCESS',
    failure: 'GET_DASHBOARD_USERS_DATA_FAILURE'
  },
  postSubPanelPageData: {
    request: 'POST_SUB_PANEL_PAGE_DATA_REQUEST',
    success: 'POST_SUB_PANEL_PAGE_DATA_SUCCESS',
    failure: 'POST_SUB_PANEL_PAGE_DATA_FAILURE'
  },
  getSubPanelPageData: {
    request: 'GET_SUB_PANEL_PAGE_DATA_REQUEST',
    success: 'GET_SUB_PANEL_PAGE_DATA_SUCCESS',
    failure: 'GET_SUB_PANEL_PAGE_DATA_FAILURE'
  },
  downloadAllTemplates: {
    request: 'DOWNLOAD_ALL_TEMPLATES_REQUEST',
    success: 'DOWNLOAD_ALL_TEMPLATES_SUCCESS',
    failure: 'DOWNLOAD_ALL_TEMPLATES_FAILURE'
  },
  downloadTemplate: {
    request: 'DOWNLOAD_TEMPLATE_REQUEST',
    success: 'DOWNLOAD_TEMPLATE_SUCCESS',
    failure: 'DOWNLOAD_TEMPLATE_FAILURE'
  },
  downloadExcel: {
    request: 'DOWNLOAD_EXCEL_REQUEST',
    success: 'DOWNLOAD_EXCEL_SUCCESS',
    failure: 'DOWNLOAD_EXCEL_FAILURE'
  },
  downloadPackshots: {
    request: 'DOWNLOAD_PACKSHOTS_REQUEST',
    success: 'DOWNLOAD_PACKSHOTS_SUCCESS',
    failure: 'DOWNLOAD_PACKSHOTS_FAILURE'
  },
  uploadPackshot: {
    request: 'UPLOAD_PACKSHOT_REQUEST',
    success: 'UPLOAD_PACKSHOT_SUCCESS',
    progress: 'UPLOAD_PACKSHOT_PROGRESS',
    failure: 'UPLOAD_PACKSHOT_FAILURE'
  },
  uploadExcel: {
    request: 'UPLOAD_EXCEL_REQUEST',
    success: 'UPLOAD_EXCEL_SUCCESS',
    progress: 'UPLOAD_EXCEL_PROGRESS',
    failure: 'UPLOAD_EXCEL_FAILURE'
  },
  uploadReferenceImages: {
    request: 'UPLOAD_REFERENCE_IMAGES_REQUEST',
    success: 'UPLOAD_REFERENCE_IMAGES_SUCCESS',
    progress: 'UPLOAD_REFERENCE_IMAGES_PROGRESS',
    failure: 'UPLOAD_REFERENCE_IMAGES_FAILURE'
  },
  getStickerValues: {
    request: 'GET_STICKER_VALUES_REQUEST',
    success: 'GET_STICKER_VALUES_SUCCESS',
    failure: 'GET_STICKER_VALUES_FAILURE'
  },
  getDisplayValues: {
    request: 'GET_DISPLAY_VALUES_REQUEST',
    success: 'GET_DISPLAY_VALUES_SUCCESS',
    failure: 'GET_DISPLAY_VALUES_FAILURE'
  },
  pollTask: {
    start: 'POLL_MDM_TASK_START',
    stop: 'POLL_MDM_TASK_STOP',
    success: 'POLL_MDM_TASK_SUCCESS',
    failure: 'POLL_MDM_TASK_FAILURE'
  }
};

export default types;

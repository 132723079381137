/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// library to assign component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// react library to dynamically set the document’s head section
import Helmet from 'react-helmet';
import { map } from 'lodash';
// @material-ui/core components
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
// redux utils
import {
  appActions,
  authActions,
  userActions
} from 'redux-utils/actions/index';
import { appSelector } from 'redux-utils/selectors/index';
// route handler
import { SwitchRoutes } from 'routes/index';
// core components
import Loader from 'components/Loader/Loader';
// core functions
import ls from 'lib/core/storageFactory';
import { getRouteID, getRoutePath } from 'lib/utils/getActiveRoute';
// views
import Header from 'layout/Header/Header';
import HeaderNew from 'layout/HeaderNew/HeaderNew';
import SideBar from 'layout/Sidebar/Sidebar';
import SideBarNew from 'layout/SidebarNew/SidebarNew';
import SubHeader from 'layout/SubHeader/SubHeader';
// assets
import dashboardStyle from 'assets/jss/material-ui/layout/dashboardLayoutStyle';
// import infilectLogo from 'assets/images/infilectLogo.png';
import infilectLogo from 'assets/svg/infiviz_logo.svg';
import logo from 'assets/images/logo.png';
import { FormatAlignCenter, ListIcon } from 'assets/icons/appIcons';
// utils
import routes from 'routes/appRoutes';

import themeProvider from 'themes/ThemeProvider';

const helmet = (
  <Helmet>
    <style key="dashboard-view">
      {/* {
        'body { background-color: #1e1e2b; overflow: unset; overflow-x: hidden; }'
      } */}
      {'body { overflow: unset; overflow-x: hidden; }'}
    </style>
  </Helmet>
);

/**
 * @class
 * @hideconstructor
 * @description DashboardPanel class component to render the DashboardPanel view layout
 */
class DashboardPanel extends Component {
  pathname = '';

  appRoutes = [];

  state = {
    isDrawerOpen: false,
    isDrawerExpanded: true
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    this.pathname = pathname;
    this.appRoutes = map(routes, el => el);
    const {
      dispatchGetLayoutRequest,
      dispatchGetPreferenceRequest,
      dispatchGetClientPreferenceRequest
    } = this.props;
    if (ls.get('isLoggedIn')) {
      dispatchGetPreferenceRequest();
      dispatchGetClientPreferenceRequest();
      dispatchGetLayoutRequest();
    }
  }

  componentWillReceiveProps() {
    const { dispatchRouteChange, location, clientPreferences } = this.props;
    const {
      location: { pathname }
    } = window;
    this.pathname = pathname;

    try {
      const firstLogin = ls.get('firstLogin')
      if ((window.location.pathname.includes('/store-explorer') || window.location.pathname.includes('/app-images')) && JSON.parse(firstLogin) === true && clientPreferences?.default_session_id) {
        ls.remove('firstLogin');
        const search = `?q=${clientPreferences?.default_session_id}`;
        window.location.replace(`${pathname}${search}`);
      }
    } catch (error) {
      console.error('Error in parsing firstLogin', error);
    }
    if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
      if (ls.get('isLoggedIn')) {
        const domain = ls.get('domain');
        const parsedURL = window.location.origin
          .split('//')
          .join('')
          .split('.');
        const hasSubDomain = parsedURL.length > 2;
        if (
          (hasSubDomain &&
            parsedURL[0].split(':')[1].toLowerCase() !==
              domain.toLowerCase()) ||
          !hasSubDomain
        ) {
          window.location.replace(
            `https://${domain.toLowerCase()}.${
              process.env.REACT_APP_SHELF_REDIRECT_URI
            }/app`
          );
        }
      }
    }
    if (pathname !== location.pathname) {
      dispatchRouteChange({
        current: getRouteID(pathname),
        previous: getRouteID(location.pathname)
      });
    }
    window.FreshworksWidget('show');
    if (window.location.pathname.includes('test-store-explorer')) {
      window.FreshworksWidget('hide');
    } else if (
      window.location.pathname.includes('app-images') ||
      window.location.pathname.includes('survey') ||
      window.location.pathname.includes('analytics')
    ) {
      window.FreshworksWidget('prefill', 'ticketForm', {
        custom_fields: {
          cf_session_id: ''
        }
      });
    }
  }

  /**
   * @private
   * @method
   * @description handler function to handle the drawer expand
   * @returns {undefined}
   */
  handleDrawerExpand = () => {
    this.setState(state => ({
      ...state,
      isDrawerExpanded: !state.isDrawerExpanded
    }));
  };

  /**
   * @private
   * @method
   * @description handler function to handle the drawer toggle
   * @returns {undefined}
   */
  handleDrawerToggle = () => {
    this.setState(state => ({ ...state, isDrawerOpen: !state.isDrawerOpen }));
  };

  /**
   * @private
   * @method
   * @description handler function to handle the user preferences
   * @returns {undefined}
   */
  handlePreferenceClick = () => {
    const { history } = this.props;
    history.push(getRoutePath('userProfile', routes));
  };

  /**
   * @method
   * @private
   * @description A function to clear the localStorage and redirect
   * the user to Login Page
   * @returns {undefined}
   */

  render() {
    const {
      classes,
      dispatchLogoutRequest,
      menuRoutes,
      layoutCSS,
      dashboardType,
      layout,
      layoutNetworkState,
      ...rest
    } = this.props;
    const { isDrawerExpanded, isDrawerOpen } = this.state;

    return (
      <MuiThemeProvider
        theme={
          dashboardType === 'v1' ? themeProvider.dark : themeProvider.light
        }
      >
        <div
          className={`${classes.root} ${
            getRouteID(window.location.pathname) === 'admin' &&
            dashboardType === 'v2'
              ? classes.adminSideBar
              : ''
          } dashboardPanel`}
          style={{
            backgroundColor:
              dashboardType === 'v1'
                ? '#1e1e2b'
                : layoutCSS?.theme?.dashboardPanel?.backgroundColor ||
                  '#f5f5f5',
            fontFamily:
              dashboardType === 'v1'
                ? 'inherit'
                : layoutCSS?.theme?.dashboardPanel?.fontFamily ||
                  'loginPageFont'
          }}
        >
          {helmet}

          <CssBaseline />

          {layoutNetworkState ? (
            <Loader
              circular
              color="secondary"
              inline
              withTheme={dashboardType === 'v1' ? 'dark' : ''}
            />
          ) : null}

          {dashboardType === 'v1' && (
            <>
              <Header
                handleLogout={dispatchLogoutRequest}
                handlePreferenceClick={this.handlePreferenceClick}
                routes={[...this.appRoutes, ...menuRoutes]}
                {...rest}
              />
              <SideBar
                icon={isDrawerExpanded ? ListIcon : FormatAlignCenter}
                routes={menuRoutes}
                color="orange"
                drawerState={{ isDrawerExpanded, isDrawerOpen }}
                handleDrawerToggle={this.handleDrawerToggle}
                handleDrawerExpand={this.handleDrawerExpand}
                logo={logo}
                logoText="Infiviz"
                {...rest}
              />
            </>
          )}

          {dashboardType === 'v2' && (
            <>
              <HeaderNew
                handleLogout={dispatchLogoutRequest}
                handlePreferenceClick={this.handlePreferenceClick}
                handleDrawerExpand={this.handleDrawerExpand}
                icon={isDrawerExpanded ? ListIcon : FormatAlignCenter}
                routes={[...this.appRoutes, ...menuRoutes]}
                logo={infilectLogo}
                headerStyles={layoutCSS?.theme?.header}
                {...rest}
              />
              {getRouteID(window.location.pathname) !==
                'analytics-redirect' && (
                <SideBarNew
                  routes={menuRoutes}
                  color="orange"
                  drawerState={{ isDrawerExpanded, isDrawerOpen }}
                  handleDrawerToggle={this.handleDrawerToggle}
                  backgroundColor={layoutCSS?.theme?.sidebar.backgroundColor}
                  dropdownTitleStyles={layoutCSS?.theme?.dropdownTitle}
                  dropdownStyles={layoutCSS?.theme?.dropdown}
                  {...rest}
                />
              )}
            </>
          )}

          <div
            className={`${
              getRouteID(window.location.pathname) === 'analytics-redirect'
                ? classes.analyticsPanel
                : classes.mainPanel
            } ${
              getRouteID(window.location.pathname) === 'admin' &&
              dashboardType === 'v2'
                ? classes.adminPanel
                : ''
            }`}
          >
            {dashboardType === 'v2' ? (
              <SubHeader
                routes={menuRoutes}
                color="orange"
                {...rest}
                navBarStyles={layoutCSS?.theme?.navBar}
              />
            ) : null}

            <main
              className={
                dashboardType === 'v1'
                  ? classes.content
                  : classes.lightThemeContent
              }
            >
              <div
                className={`${classes.toolbar} ${
                  dashboardType === 'v1' ? '' : classes.toolbarContainer
                }`}
              />
              <div
                className={
                  dashboardType === 'v2' &&
                  getRouteID(window.location.pathname) === 'app-images'
                    ? classes.lightThemeContainer
                    : getRouteID(window.location.pathname) ===
                      'analytics-redirect'
                    ? classes.analyticsContainer
                    : classes.container
                }
              >
                {!layoutNetworkState && !layout.length && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <p
                      style={{
                        width: 295,
                        textAlign: 'center'
                      }}
                    >
                      No dashboard available. Please contact InfiViz Support{' '}
                      <a href="mailto:customersupport@infilect.com">
                        (customersupport@infilect.com)
                      </a>{' '}
                      to set up your dashboard
                      <br></br>
                      <br></br>
                      <>---- or ----</>
                      <br></br>
                      <br></br>
                      <button 
                        style={{
                          padding: '7.5px 20px',
                          fontSize: '16px',
                          border: '0px',
                          color: 'white',
                          fontWeight: 'bold',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          background: '#A52A2A'
                        }}
                        onClick={() => dispatchLogoutRequest()}
                      >Logout</button> and try again?
                    </p>
                    
                  </div>
                )}

                {layout.length ? (
                  <SwitchRoutes routes={[...this.appRoutes, ...menuRoutes]} />
                ) : null}
              </div>
            </main>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

// component properties
DashboardPanel.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description  Logout request action dispatcher
   */
  dispatchLogoutRequest: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Menu structure request action dispatcher
   */
  dispatchGetLayoutRequest: PropTypes.func.isRequired,
  /**
   * @type {object}
   * @description Menu structure routes
   */
  menuRoutes: PropTypes.array.isRequired,
  /**
   * @type {object}
   * @description Right Menu structure routes
   */
  rightMenuRoutes: PropTypes.array.isRequired,
  /**
   * @type {function}
   * @description Get User preferences request action dispatcher
   */
  dispatchGetPreferenceRequest: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Get User preferences request action dispatcher
   */
  dispatchGetClientPreferenceRequest: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Route change action dispatcher
   */
  dispatchRouteChange: PropTypes.func.isRequired,
  /**
   * @type {object}
   * @description Browser location object
   */
  location: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description Browser location object
   */
  history: PropTypes.object.isRequired,
  dashboardType: PropTypes.string.isRequired,
  layoutCSS: PropTypes.object.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const mapStateToProps = state => ({
  menuRoutes: appSelector.getMenuStructure(state),
  clientPreferences: appSelector.getClientPreferences(state),
  rightMenuRoutes: appSelector.getRightMenu(state),
  layoutCSS: appSelector.getLayoutCSS(state),
  layout: appSelector.getLayout(state),
  layoutNetworkState: appSelector.getLayoutNetworkState(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version,
  logoutRedirectionData: appSelector.getLogoutRedirectionData(state)
});

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = {
  dispatchGetPreferenceRequest: () => userActions.getUserPreferenceAction(),
  dispatchGetClientPreferenceRequest: () =>
    appActions.getClientPreferencesAction(),
  dispatchGetLayoutRequest: () => appActions.layoutRequestAction({}),
  dispatchLogoutRequest: () => authActions.userLogoutRequest(),
  dispatchRouteChange: payload => appActions.routeChangeAction(payload)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle, { withTheme: true })(DashboardPanel));

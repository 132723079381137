import { createAction } from '@reduxjs/toolkit';
import { SETypes } from 'redux-utils/types/index';
import apiModel from 'models/apiModel';
import reducerModel from 'models/reducerModel';

const getSEFilterListAction = (
  payload,
  meta = { reducerID: [reducerModel.SE.id] }
) => {
  const action = createAction(SETypes.getFilters.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getSEFilter,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: SETypes.getFilters.success,
    requestAction: SETypes.getFilters.fromAPI
  });
  action.meta = meta;
  return action;
};

// eslint-disable-next-line import/prefer-default-export
export { getSEFilterListAction };

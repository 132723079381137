import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// styles
import gridItemStyle from 'assets/jss/material-ui/components/gridItemStyles';

/**
 * @class GridItem
 * @hideconstructor
 * @description Core GridItem component
 */
const GridItem = ({ classes, children, ...rest }) => (
  <Grid item {...rest} className={classes.grid}>
    {children}
  </Grid>
);

// component properties
GridItem.propTypes = {
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {function | element}
   * @description - Child elements
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

export default withStyles(gridItemStyle)(GridItem);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// @material-ui/core
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
// core components
import CustomTableCell from 'table/CustomCell';
import Typography from 'components/Typography/Typography';
import ls from 'lib/core/storageFactory';
// styles
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  TickIcon as tickIcon
} from 'assets/icons/appIcons';
import tableStyles from 'assets/jss/material-ui/components/tableStyle';
import Preview from './Preview';
import { connect } from 'react-redux';

const client = ls.get('client');

const StyledTable = styled(Table)`
  && tbody tr:nth-of-type(odd) {
    background-color: ${props => `${props.bg} !important`};
  }
  && tbody tr:nth-of-type(even) {
    background-color: ${props => `${props.alternateRowColor} !important`};
  }
  && thead tr th {
    background-color: ${props => `${props.bg} !important`};
    white-space: nowrap;
  }
  && tbody tr td {
    color: ${props => `${props.color} !important`};
    font-family: ${props => props.dashboardType === 'v2' && 'loginPageFont'};
  }
  && thead tr th {
    color: ${props => `${props.color} !important`};
    font-family: ${props => props.dashboardType === 'v2' && 'loginPageFont'};
    font-weight: ${props => props.dashboardType === 'v2' && 'bold'};
  }
`;

const StyledPaper = styled(Paper)`
  && {
    scrollbar-width: thin;
    scrollbar-color: ${props =>
      `${props.scrollbarColor} ${props.scrollbarTrackColor}`};
    &::-webkit-scrollbar: {
      display: block;
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.scrollbarColor};
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${props => props.scrollbarTrackColor};
      border-radius: 8px;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${props => `${props.checkboxColor} !important`};
  }
`;

const CustomTable = ({
  classes,
  data,
  onRowClick,
  highlightRow,
  useCheckBox,
  onCheckBoxClick,
  cardStyles,
  dashboardPanelStyles,
  dashboardType
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [isAllSelected, setAllSelected] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState({});
  const [preview, setPreview] = useState({ img: [], display: false });
  const handleRowClick = (row, index) => {
    if (highlightRow) {
      setActiveRow(index);
    }
    onRowClick(row);
  };

  const handleSelectAllCheckboxChange = e => {
    e.persist();
    setAllSelected(e.target.checked);
    setCheckBoxState(() => {
      const values = {};
      data.datasets.map((el, i) => {
        values[`checkbox-${i}`] = e.target.checked;
        return el;
      });
      return values;
    });
    onCheckBoxClick({
      row: data.datasets,
      columns: data.columns,
      isChecked: e.target.checked,
      selectAll: true
    });
  };

  const handleCheckBoxChange = ({ e, row, i }) => {
    e.persist();
    setAllSelected(false);
    setCheckBoxState(prev => ({
      ...prev,
      [`checkbox-${i}`]: e.target.checked
    }));
    onCheckBoxClick({
      row,
      i,
      isChecked: e.target.checked,
      columns: data.columns
    });
  };

  const handlePackshotClick = ({ src }) => {
    setPreview({
      img: [{ src }],
      isOpen: true
    });
  };

  return (
    <StyledPaper
      className={classes.root}
      scrollbarColor={dashboardPanelStyles?.scrollbarColor}
      scrollbarTrackColor={dashboardPanelStyles?.scrollbarTrackColor}
    >
      <Preview images={preview.img} modalState={preview} cb={setPreview} />
      <StyledTable
        className={classes.table}
        bg={cardStyles?.backgroundColor}
        alternateRowColor={cardStyles?.alternateRowColor}
        color={cardStyles?.textColor}
        dashboardType={dashboardType}
      >
        <TableHead>
          <TableRow>
            {useCheckBox && (
              <>
                <CustomTableCell style={{ zIndex: 10 }}>
                  <StyledCheckbox
                    classes={{
                      root: classes.checkBox
                    }}
                    checkboxColor={dashboardPanelStyles?.checkboxColor}
                    color="secondary"
                    checked={isAllSelected}
                    onChange={handleSelectAllCheckboxChange}
                    className={classes.checkbox}
                  />
                </CustomTableCell>
              </>
            )}
            {data.columns.map(col => (
              <CustomTableCell key={col.dataKey}>{col.label}</CustomTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.datasets.map(
            (row, i) =>
              !row.hide && (
                <TableRow
                  onClick={() => handleRowClick(row, i)}
                  className={`${classes.row} ${
                    activeRow === i ? classes.active : ''
                  } ${row.highlight ? classes.highlight : ''}`}
                  key={i}
                >
                  {useCheckBox && (
                    <CustomTableCell>
                      <StyledCheckbox
                        color="secondary"
                        checkboxColor={dashboardPanelStyles?.checkboxColor}
                        classes={{
                          root: classes.checkBox
                        }}
                        value={`checkbox-${i}`}
                        checked={checkBoxState[`checkbox-${i}`] || false}
                        onChange={e => handleCheckBoxChange({ e, row, i })}
                        className={classes.checkbox}
                      />
                    </CustomTableCell>
                  )}
                  {data.columns.map(col => {
                    if (
                      col.dataKey ===
                      (client === 'kcc-ru' || client === 'infilect-kcc-ru'
                        ? 'упаковать фото'
                        : 'packshot')
                    ) {
                      return (
                        <CustomTableCell key={col.dataKey}>
                          {row[
                            client === 'kcc-ru' || client === 'infilect-kcc-ru'
                              ? 'упаковать фото'
                              : 'packshot'
                          ] ? (
                            row[
                              client === 'kcc-ru' ||
                              client === 'infilect-kcc-ru'
                                ? 'упаковать фото'
                                : 'packshot'
                            ].length > 0 ? (
                              <img
                                onClick={() =>
                                  handlePackshotClick({
                                    src:
                                      row[
                                        client === 'kcc-ru' ||
                                        client === 'infilect-kcc-ru'
                                          ? 'упаковать фото'
                                          : 'packshot'
                                      ]
                                  })
                                }
                                className={classes.packshot}
                                src={
                                  row[
                                    client === 'kcc-ru' ||
                                    client === 'infilect-kcc-ru'
                                      ? 'упаковать фото'
                                      : 'packshot'
                                  ]
                                }
                                alt="packshot"
                              />
                            ) : (
                              <>
                                {client === 'kcc-ru' ||
                                client === 'infilect-kcc-ru'
                                  ? 'Н/Д'
                                  : 'NA'}
                              </>
                            )
                          ) : (
                            <>
                              {client === 'kcc-ru' ||
                              client === 'infilect-kcc-ru'
                                ? 'Н/Д'
                                : 'NA'}
                            </>
                          )}
                        </CustomTableCell>
                      );
                    }
                    if (
                      col.dataKey ===
                      (client === 'kcc-ru' || client === 'infilect-kcc-ru'
                        ? 'Соответствие цены'
                        : 'price_compliance')
                    ) {
                      return (
                        <CustomTableCell key={col.dataKey}>
                          {row[
                            client === 'kcc-ru' || client === 'infilect-kcc-ru'
                              ? 'Соответствие цены'
                              : 'price_compliance'
                          ][
                            client === 'kcc-ru' || client === 'infilect-kcc-ru'
                              ? 'Соответствие цены'
                              : 'price_compliance'
                          ] === 'true' ? (
                            <div className={classes.priceCompliance}>
                              {tickIcon({ className: classes.compliant })}
                            </div>
                          ) : row[
                              client === 'kcc-ru' ||
                              client === 'infilect-kcc-ru'
                                ? 'Соответствие цены'
                                : 'price_compliance'
                            ][
                              client === 'kcc-ru' ||
                              client === 'infilect-kcc-ru'
                                ? 'Соответствие цены'
                                : 'price_compliance'
                            ] ? (
                            <Tooltip
                              classes={{ tooltip: classes.tooltipWrapper }}
                              title={
                                <div className={classes.tooltip}>
                                  <p>
                                    <span>
                                      {client === 'kcc-ru' ||
                                      client === 'infilect-kcc-ru'
                                        ? 'Мин. цена'
                                        : 'Min'}
                                    </span>
                                    <span>
                                      {
                                        row[
                                          client === 'kcc-ru' ||
                                          client === 'infilect-kcc-ru'
                                            ? 'Соответствие цены'
                                            : 'price_compliance'
                                        ][
                                          client === 'kcc-ru' ||
                                          client === 'infilect-kcc-ru'
                                            ? 'Мин. цена'
                                            : 'min'
                                        ]
                                      }
                                    </span>
                                  </p>
                                  <p>
                                    <span>
                                      {client === 'kcc-ru' ||
                                      client === 'infilect-kcc-ru'
                                        ? 'Макс. цена'
                                        : 'Max'}
                                    </span>
                                    <span>
                                      {
                                        row[
                                          client === 'kcc-ru' ||
                                          client === 'infilect-kcc-ru'
                                            ? 'Соответствие цены'
                                            : 'price_compliance'
                                        ][
                                          client === 'kcc-ru' ||
                                          client === 'infilect-kcc-ru'
                                            ? 'Макс. цена'
                                            : 'max'
                                        ]
                                      }
                                    </span>
                                  </p>
                                </div>
                              }
                              className={classes.tooltip}
                            >
                              <div className={classes.priceCompliance}>
                                {row[
                                  client === 'kcc-ru' ||
                                  client === 'infilect-kcc-ru'
                                    ? 'Соответствие цены'
                                    : 'price_compliance'
                                ][
                                  client === 'kcc-ru' ||
                                  client === 'infilect-kcc-ru'
                                    ? 'Соответствие цены'
                                    : 'price_compliance'
                                ] === 'down' ? (
                                  <ExpandMoreIcon
                                    className={`${classes.notCompliant}`}
                                  />
                                ) : row[
                                    client === 'kcc-ru' ||
                                    client === 'infilect-kcc-ru'
                                      ? 'Соответствие цены'
                                      : 'price_compliance'
                                  ][
                                    client === 'kcc-ru' ||
                                    client === 'infilect-kcc-ru'
                                      ? 'Соответствие цены'
                                      : 'price_compliance'
                                  ] === 'up' ? (
                                  <ExpandLessIcon
                                    className={`${classes.notCompliant}`}
                                  />
                                ) : null}
                                <Typography
                                  color="rgba(255,255,255,0.8)"
                                  weight={400}
                                >
                                  {
                                    row[
                                      client === 'kcc-ru' ||
                                      client === 'infilect-kcc-ru'
                                        ? 'Соответствие цены'
                                        : 'price_compliance'
                                    ][
                                      client === 'kcc-ru' ||
                                      client === 'infilect-kcc-ru'
                                        ? 'Отличие цены'
                                        : 'delta'
                                    ]
                                  }
                                </Typography>
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              {client === 'kcc-ru' ||
                              client === 'infilect-kcc-ru'
                                ? 'Н/Д'
                                : 'NA'}
                            </>
                          )}
                        </CustomTableCell>
                      );
                    }
                    if (
                      col.dataKey === 'упаковать фото' ||
                      col.dataKey === 'packshot'
                    ) {
                      return (
                        <CustomTableCell key={col.dataKey}>
                          {row['упаковать фото'] || row.packshot ? (
                            (row['упаковать фото'] || row.packshot).length >
                            0 ? (
                              <img
                                onClick={() =>
                                  handlePackshotClick({
                                    src: row['упаковать фото'] || row.packshot
                                  })
                                }
                                className={classes.packshot}
                                src={row['упаковать фото'] || row.packshot}
                                alt="packshot"
                              />
                            ) : (
                              <>{client.includes('kcc-ru') ? 'Н/Д' : 'NA'}</>
                            )
                          ) : (
                            <>{client.includes('kcc-ru') ? 'Н/Д' : 'NA'}</>
                          )}
                        </CustomTableCell>
                      );
                    }
                    if (
                      col.dataKey ===
                      (client.includes('kcc-ru')
                        ? 'Соответствие цены'
                        : 'price_compliance')
                    ) {
                      return (
                        <CustomTableCell key={col.dataKey}>
                          {row[
                            client.includes('kcc-ru')
                              ? 'Соответствие цены'
                              : 'price_compliance'
                          ][
                            client.includes('kcc-ru')
                              ? 'Соответствие цены'
                              : 'price_compliance'
                          ] === 'true' ? (
                            <div className={classes.priceCompliance}>
                              {tickIcon({ className: classes.compliant })}
                            </div>
                          ) : row[
                              client.includes('kcc-ru')
                                ? 'Соответствие цены'
                                : 'price_compliance'
                            ][
                              client.includes('kcc-ru')
                                ? 'Соответствие цены'
                                : 'price_compliance'
                            ] ? (
                            <Tooltip
                              classes={{ tooltip: classes.tooltipWrapper }}
                              title={
                                <div className={classes.tooltip}>
                                  <p>
                                    <span>
                                      {client.includes('kcc-ru')
                                        ? 'Мин. цена'
                                        : 'Min'}
                                    </span>
                                    <span>
                                      {
                                        row[
                                          client.includes('kcc-ru')
                                            ? 'Соответствие цены'
                                            : 'price_compliance'
                                        ][
                                          client.includes('kcc-ru')
                                            ? 'Мин. цена'
                                            : 'min'
                                        ]
                                      }
                                    </span>
                                  </p>
                                  <p>
                                    <span>
                                      {client.includes('kcc-ru')
                                        ? 'Макс. цена'
                                        : 'Max'}
                                    </span>
                                    <span>
                                      {
                                        row[
                                          client.includes('kcc-ru')
                                            ? 'Соответствие цены'
                                            : 'price_compliance'
                                        ][
                                          client.includes('kcc-ru')
                                            ? 'Макс. цена'
                                            : 'max'
                                        ]
                                      }
                                    </span>
                                  </p>
                                </div>
                              }
                              className={classes.tooltip}
                            >
                              <div className={classes.priceCompliance}>
                                {row[
                                  client.includes('kcc-ru')
                                    ? 'Соответствие цены'
                                    : 'price_compliance'
                                ][
                                  client.includes('kcc-ru')
                                    ? 'Соответствие цены'
                                    : 'price_compliance'
                                ] === 'down' ? (
                                  <ExpandMoreIcon
                                    className={`${classes.notCompliant}`}
                                  />
                                ) : row[
                                    client.includes('kcc-ru')
                                      ? 'Соответствие цены'
                                      : 'price_compliance'
                                  ][
                                    client.includes('kcc-ru')
                                      ? 'Соответствие цены'
                                      : 'price_compliance'
                                  ] === 'up' ? (
                                  <ExpandLessIcon
                                    className={`${classes.notCompliant}`}
                                  />
                                ) : null}
                                <Typography
                                  color="rgba(255,255,255,0.8)"
                                  weight={400}
                                >
                                  {
                                    row[
                                      client.includes('kcc-ru')
                                        ? 'Соответствие цены'
                                        : 'price_compliance'
                                    ][
                                      client.includes('kcc-ru')
                                        ? 'Отличие цены'
                                        : 'delta'
                                    ]
                                  }
                                </Typography>
                              </div>
                            </Tooltip>
                          ) : (
                            <>{client.includes('kcc-ru') ? 'Н/Д' : 'NA'}</>
                          )}
                        </CustomTableCell>
                      );
                    }
                    if (col.dataKey === 'Promotional Crop') {
                      return (
                        <CustomTableCell key={col.dataKey}>
                          {row['Promotional Crop'] ? (
                            row['Promotional Crop'].length > 0 ? (
                              <img
                                onClick={() =>
                                  handlePackshotClick({
                                    src: row['Promotional Crop']
                                  })
                                }
                                className={classes.packshot}
                                src={row['Promotional Crop']}
                                alt="Promotional Crop"
                              />
                            ) : (
                              'NA'
                            )
                          ) : (
                            'NA'
                          )}
                        </CustomTableCell>
                      );
                    }
                    return (
                      <CustomTableCell key={col.dataKey}>
                        {row[col.dataKey]}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              )
          )}
        </TableBody>
      </StyledTable>
    </StyledPaper>
  );
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  highlightRow: PropTypes.bool.isRequired,
  useCheckBox: PropTypes.bool.isRequired,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object
};

CustomTable.defaultProps = {
  cardStyles: {},
  dashboardPanelStyles: {}
};

const TableWrapper = withStyles(tableStyles)(props =>
  props.data.datasets.length === 0 ? (
    <Typography center color="light25" size="xs">
      {this.client.includes('kcc-ru')
        ? 'Нет данных для отображения'
        : 'No data to display'}
    </Typography>
  ) : (
    CustomTable({ ...props })
  )
);

TableWrapper.propTypes = {
  /**
   * @type {string}
   * @description - Width of the table
   */
  width: PropTypes.string,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object
};

TableWrapper.defaultProps = {
  width: '90%',
  onRowClick: () => {},
  onCheckBoxClick: () => {},
  highlightRow: false,
  useCheckBox: false,
  cardStyles: {},
  dashboardPanelStyles: {}
};
const mapStateToProps = state => ({
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version
});
export default connect(mapStateToProps, null)(TableWrapper);

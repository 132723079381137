/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { array, bool, func, object, string, number } from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
// ag-grid
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import { scrollbar } from 'assets/jss/material-ui/material-ui';
import { DeleteIcon, EditIcon } from 'assets/icons/appIcons';
// components
import Button from 'components/Button/Button';
import Toggle from '../Toggle/ToggleComponent';
// utils
import { hasPermission } from '../utils';
import { PermissionsEnum } from '../enums';

const customImageModalStyles = {
  view: base => ({
    ...base,
    maxHeight: '90vh',
    overflow: 'auto',
    '& img': {
      maxHeight: '70vh'
      // maxWidth: '70vw'
    }
  })
};

const defaultStyles = { width: '100%', height: 400 };

const style = theme => ({
  cell: {
    // backgroundColor: theme.palette.type === 'light' && '#ffffff',
    color: theme.palette.type === 'light' && '#767676',
    borderColor: theme.palette.type === 'light' && '#c7c7c7'
  },
  header: {
    textDecoration: 'underline',
    backgroundColor: theme.palette.type === 'light' && '#ffffff',
    color: theme.palette.type === 'light' && '#767676',
    borderColor: theme.palette.type === 'light' && '#c7c7c7'
  },
  table: {
    height: 'calc(100vh - 240px) !important',
    '& div[class*="ag-root-wrapper"]': {
      border: theme.palette.type === 'light' && 'none !important'
    },
    '& div[class*="ag-row"]': {
      borderBottom: theme.palette.type === 'light' && 'none !important'
    },
    '& div[class*="ag-header"]': {
      borderBottom: theme.palette.type === 'light' && 'none !important'
    },
    '& div[class*="ag-center-cols-viewport"]': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff',
      color: theme.palette.type === 'light' && '#767676',
      borderColor: theme.palette.type === 'light' && '#c7c7c7'
    },
    '& div[class*="ag-header-cell"]': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff',
      color: theme.palette.type === 'light' && '#767676',
      borderColor: theme.palette.type === 'light' && '#c7c7c7'
    },
    '& div[class*="ag-header-viewport"]': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff',
      color: theme.palette.type === 'light' && '#767676',
      borderColor: theme.palette.type === 'light' && '#c7c7c7'
    },
    '& div.ag-body-horizontal-scroll': {
      height: '0.1px !important',
      minHeight: '0.1px !important',
      maxHeight: '0.1px !important'
    },
    '& div.ag-body-horizontal-scroll-viewport': {
      height: '0.1px !important',
      minHeight: '0.1px !important',
      maxHeight: '0.1px !important'
    },
    '& div.ag-body-horizontal-scroll-container': {
      height: '0.1px !important',
      minHeight: '0.1px !important',
      maxHeight: '0.1px !important'
    },
    '&:hover': {
      '& div.ag-body-viewport.ag-layout-normal': {
        ...scrollbar,
        scrollbarColor:
          theme.palette.type === 'light'
            ? '#f5f5f5 transparent'
            : 'rgb(65, 65, 66) transparent',
        '&::-webkit-scrollbar-thumb': {
          backgroundColor:
            theme.palette.type === 'light' ? '#d4d4d4' : 'rgb(65, 65, 66)',
          borderRadius: 8
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor:
            theme.palette.type === 'light' ? '#ffffff' : 'transparent',
          borderRadius: 8
        },
        paddingBottom: 0
      },
      '& div.ag-body-horizontal-scroll': {
        height: '10px !important',
        minHeight: '10px !important',
        maxHeight: '10px !important'
      },
      '& div.ag-body-horizontal-scroll-viewport': {
        ...scrollbar,
        scrollbarColor:
          theme.palette.type === 'light'
            ? '#f5f5f5 transparent'
            : 'rgb(65, 65, 66) transparent',
        '&::-webkit-scrollbar-thumb': {
          backgroundColor:
            theme.palette.type === 'light' ? '#d4d4d4' : 'rgb(65, 65, 66)',
          borderRadius: 8
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor:
            theme.palette.type === 'light' ? '#ffffff' : 'transparent',
          borderRadius: 8
        },
        paddingBottom: 0,
        height: '10px !important',
        minHeight: '10px !important',
        maxHeight: '10px !important'
      },
      '& div.ag-body-horizontal-scroll-container': {
        height: '10px !important',
        minHeight: '10px !important',
        maxHeight: '10px !important'
      }
    }
  }
});

const EmptyCell = <span />;

const ButtonRenderer = props => {
  const { colDef, value, data } = props;
  if (value) {
    if (colDef.otherProps && colDef.otherProps.value_type === 'string') {
      return (
        <span>
          <Button style={{ height: 30 }}>{value}</Button>
        </span>
      );
    }
    if (colDef.otherProps && value === 'delete') {
      return (
        <Button
          onClick={() => data.clickHandler({ props })}
          style={{ height: 30 }}
          color="danger"
          justIcon
        >
          <DeleteIcon />
        </Button>
      );
    }
    if (colDef.otherProps && value === 'upload') {
      return (
        <Button
          onClick={() => data.clickHandler({ props })}
          style={{ height: 30 }}
          color="info"
          justIcon
        >
          <EditIcon />
        </Button>
      );
    }
    return (
      <span>
        <Button style={{ height: 30 }}>{value}</Button>
      </span>
    );
  }
  return EmptyCell;
};

const TextRenderer = props => {
  const { colDef, value } = props;
  if (value) {
    if (colDef.otherProps && colDef.otherProps.value_type === 'array') {
      return (
        <span>
          {value.map(item => `${item}${value.length > 1 ? ',' : ''} `)}
        </span>
      );
    }
    if (colDef.otherProps && colDef.otherProps.value_type === 'string') {
      return <span>{`${value}`}</span>;
    }
    return <span>{`${value}`}</span>;
  }
  return EmptyCell;
};

const ImageRenderer = props => {
  const { colDef, value } = props;
  if (value) {
    if (colDef.otherProps && colDef.otherProps.value_type === 'array') {
      return (
        <span>
          {value.map(item => (
            <img
              key={item.url + item.id}
              style={{ height: 30, marginRight: 5, maxWidth: 50, minWidth: 30 }}
              src={item.url}
              alt={item.label}
            />
          ))}
        </span>
      );
    }
    return (
      <span>
        <img
          style={{ height: 30, marginRight: 5, maxWidth: 50 }}
          src={value.url}
          alt={value.label}
        />
      </span>
    );
  }
  return EmptyCell;
};

const cellRenderer = {
  text: 'textRenderer',
  image: 'imageRenderer',
  action_button: 'buttonRenderer',
  toggle: 'toggleRenderer'
};

const TableComponent = ({
  classes,
  createdRows,
  customColumn,
  data,
  styleProps,
  enableEdit,
  resizeState,
  setResizeState,
  overlay,
  disableInteraction,
  handleRowEdit,
  uploadReferenceImages,
  handleRowDelete,
  permissions,
  hideDelete,
  loaderStatus,
  limit,
  dashboardType
}) => {
  const [gridApi, setGridApi] = useState(undefined);
  const [columns, setColumns] = useState(data.columns);
  const [imageModalState, setImageModalState] = useState({
    isOpen: false,
    images: []
  });

  useEffect(() => {
    if (gridApi && resizeState) {
      resizeColumns();
      setResizeState(false);
    }
  }, [resizeState]);

  const ToggleRenderer = props => {
    const {
      value,
      colDef: { otherProps }
    } = props;
    return (
      <>
        <Toggle
          disabled={otherProps.isToggleDisabled}
          isChecked={value}
          handleChange={checked =>
            handleToggleChange({ checked, props, otherProps })
          }
        />
      </>
    );
  };

  const resizeColumns = () => {
    if (gridApi && gridApi.columnController && dashboardType !== 'v2') {
      gridApi.columnController.autoSizeAllColumns();
    }
  };

  // to enable/disable toggle
  const onColumnValueChanged = change => {
    if (change.api) {
      change.api.refreshCells({ force: true });
    }
  };

  const onRowDataChanged = () => {
    if (gridApi) {
      resizeColumns();
    }
  };

  const onCellDoubleClicked = ({ value, colDef }) => {
    if (colDef.otherProps.column_type === 'image') {
      setImageModalState({
        isOpen: true,
        images: value.map(el => ({ ...el, source: el.url }))
      });
    }
  };

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      suppressMovable: true,
      ...(dashboardType === 'v2' && { flex: 1 })
    },
    onNewColumnsLoaded: onColumnValueChanged,
    onRowDataChanged,
    onCellDoubleClicked,
    animateRows: true,
    pagination: true,
    paginationPageSize: limit,
    suppressPaginationPanel: true,
    frameworkComponents: {
      toggleRenderer: ToggleRenderer,
      textRenderer: TextRenderer,
      imageRenderer: ImageRenderer,
      buttonRenderer: ButtonRenderer
    }
  };

  const cleanCellObject = cell => {
    const cellClone = { ...cell };
    delete cellClone.delete;
    delete cellClone.upload;
    delete cellClone.clickHandler;
    return cellClone;
  };

  const handleUploadClick = useCallback(
    ({
      props: {
        node: { data: row }
      }
    }) => {
      const draft = { ...row };
      uploadReferenceImages(cleanCellObject({ ...draft }));
    },
    [gridApi]
  );

  const deleteRow = useCallback(
    ({
      props: {
        node: { data: row }
      }
    }) => {
      const draft = { ...row };
      gridApi.applyTransaction({ remove: [row] });
      handleRowDelete(cleanCellObject({ ...draft }));
    },
    [gridApi]
  );

  // add delete option to all rows from api data
  useEffect(() => {
    if (gridApi && data.data && data.data.length > 0) {
      const rowData = addCustomColumns(data.data);
      gridApi.setRowData(rowData);
    }
    // logic for handling search api response data when there is no records found
    if (gridApi && data.data && !data.data.length) {
      gridApi.setRowData(data.data);
    }
  }, [data.data, gridApi]);

  // add delete option to created rows and scroll to the row
  useEffect(() => {
    if (gridApi && createdRows.length > 0) {
      const createdRowData = addCustomColumns(createdRows);
      const addedRow = gridApi.applyTransaction({ add: createdRowData });
      gridApi.ensureIndexVisible(addedRow.add[0].rowIndex, 'bottom');
      gridApi.flashCells({
        rowNodes: addedRow.add
      });
    }
  }, [createdRows]);

  const addCustomColumns = availableRows => {
    let rowData = availableRows;
    rowData = availableRows.map(row => {
      return {
        ...row,
        ...(!hideDelete &&
          (hasPermission(permissions, PermissionsEnum.delete) ||
            hasPermission(permissions, PermissionsEnum.all)) &&
          customColumn === 'delete' && { delete: 'delete' }),
        ...(customColumn === 'upload' && { upload: 'upload' }),
        clickHandler: e =>
          customColumn === 'upload'
            ? handleUploadClick(e)
            : !hideDelete &&
              (hasPermission(permissions, PermissionsEnum.delete) ||
                hasPermission(permissions, PermissionsEnum.all)) &&
              customColumn === 'delete'
            ? deleteRow(e)
            : () => {}
      };
    });
    return rowData;
  };

  useEffect(() => {
    if (data.columns) {
      const columnsWithToggleDisabled = data.columns.map(col => {
        if (col.column_type === 'toggle') {
          return { ...col, isToggleDisabled: true };
        }
        return col;
      });
      setColumns(columnsWithToggleDisabled);
    }
  }, [data.columns]);

  useEffect(() => {
    if (enableEdit) {
      const columnsWithToggleEnabled = data.columns.map(col => {
        if (col.column_type === 'toggle') {
          return { ...col, isToggleDisabled: false };
        }
        return col;
      });
      const columnsWithCustomButton = [
        {
          width: 100,
          column_type: 'action_button',
          key: customColumn,
          value_type: 'icon'
        },
        ...columnsWithToggleEnabled
      ];
      setColumns(columnsWithCustomButton);
      setColumns(
        !hideDelete &&
          (hasPermission(permissions, PermissionsEnum.delete) ||
            hasPermission(permissions, PermissionsEnum.all))
          ? columnsWithCustomButton
          : columnsWithToggleEnabled
      );
    } else {
      const columnsWithToggleDisabled = data.columns.map(col => {
        if (col.column_type === 'toggle') {
          return { ...col, isToggleDisabled: true };
        }
        return col;
      });
      setColumns(columnsWithToggleDisabled);
    }
  }, [enableEdit]);

  useEffect(() => {
    if (gridApi && (disableInteraction || loaderStatus)) {
      gridApi.showLoadingOverlay();
      return;
    }
    if (gridApi && !loaderStatus && data.data && data.data.length === 0) {
      gridApi.showNoRowsOverlay();
      return;
    }
    if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [disableInteraction, loaderStatus, data]);

  // useEffect(() => {
  //   if (gridApi) {
  //     if (data.data && data.data.length <= 0 && createdRows.length <= 0) {
  //       gridApi.showNoRowsOverlay();
  //     }
  //   }
  // }, [data.data, gridApi, createdRows, disableInteraction]);

  const onGridReady = params => {
    setGridApi(params.api);
    window.gridApi = params.api;
  };

  const onFirstDataRendered = () => {
    setTimeout(() => {
      resizeColumns();
    }, 1000);
  };

  const onCellValueChanged = changeEvt => {
    const {
      newValue,
      oldValue,
      data: updatedData,
      node,
      colDef: { isMandatory, field }
    } = changeEvt;
    const newData = { ...updatedData };
    if (isMandatory) {
      if (newValue.trim().length < 1) {
        node.updateData({ ...newData, [field]: oldValue });
        // node.setDataValue({ [field]: oldValue });
      } else if (!newValue) {
        node.updateData({ ...newData, [field]: '' });
        // node.setDataValue({ [field]: '' });
      } else {
        return handleRowEdit({
          ...cleanCellObject(newData),
          is_password_changed: field === 'password',
          [field]: newValue
        });
      }
    }
    return handleRowEdit({
      ...cleanCellObject(newData),
      is_password_changed: field === 'password',
      [field]: newValue
    });
  };

  const handleToggleChange = ({ checked, props, otherProps }) => {
    const newData = { ...props.data };
    props.setValue(checked);
    // props.node.updateData({ ...newData, [otherProps.key]: checked });
    handleRowEdit({
      ...cleanCellObject(newData),
      [otherProps.key]: checked,
      ...(otherProps.key === 'active' && { has_active_changed: true })
    });
  };

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: 'white !important' };
    }
    return { background: '#f7f7f7 !important' };
  };

  return (
    <>
      <ModalGateway showThumbnails>
        {imageModalState.images.length > 0 && imageModalState.isOpen ? (
          <Modal
            showThumbnails
            onClose={() =>
              setImageModalState({
                isOpen: false,
                images: []
              })
            }
          >
            <Carousel
              showThumbnails
              styles={customImageModalStyles}
              views={imageModalState.images}
              showImageCount
              isOpen={imageModalState.isOpen}
              backdropClosesModal
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <div
        className={`ag-theme-alpine-dark ${classes.table}`}
        style={{ ...defaultStyles, ...styleProps }}
      >
        <AgGridReact
          gridOptions={gridOptions}
          applyColumnDefOrder
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellValueChanged}
          stopEditingWhenGridLosesFocus
          // overlayLoadingTemplate={`<span>loading...</span>`}
          overlayLoadingTemplate={`<span>${overlay.message}</span>`}
          overlayNoRowsTemplate={`<span class="ag-overlay-loading-center">No records found</span>`}
          suppressNoRowsOverlay={overlay.message !== ''}
          getRowStyle={dashboardType === 'v2' && getRowStyle}
        >
          {columns.map(column => {
            return (
              <AgGridColumn
                {...column}
                key={column.key}
                headerClass={
                  column.is_primary && column.is_primary === true
                    ? classes.header
                    : ''
                }
                minWidth={300}
                cellClass={classes.cell}
                field={column.key}
                headerName={column.label}
                editable={
                  column.is_editable &&
                  enableEdit &&
                  column.column_type !== 'toggle'
                }
                sortable={column.is_sortable}
                filter={column.is_searchable}
                autoHeight
                cellRenderer={cellRenderer[column.column_type]}
                otherProps={column}
              />
            );
          })}
        </AgGridReact>
      </div>
    </>
  );
};

TableComponent.propTypes = {
  data: object,
  overlay: object,
  customColumn: string,
  enableEdit: bool,
  disableInteraction: bool,
  resizeState: bool,
  setResizeState: func,
  handleRowEdit: func,
  handleRowDelete: func,
  uploadReferenceImages: func,
  styleProps: object,
  createdRows: array,
  permissions: array.isRequired,
  hideDelete: bool,
  loaderStatus: bool,
  limit: number,
  dashboardType: string.isRequired
};

TableComponent.defaultProps = {
  customColumn: 'delete',
  resizeState: false,
  data: { data: [], columns: [] },
  overlay: { message: '' },
  createdRows: [],
  styleProps: defaultStyles,
  enableEdit: false,
  disableInteraction: false,
  setResizeState: () => {},
  handleRowEdit: () => {},
  handleRowDelete: () => {},
  uploadReferenceImages: () => {},
  hideDelete: false,
  loaderStatus: false,
  limit: 100
};

export default withStyles(style)(TableComponent);

/* eslint-disable consistent-return */
import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// lodash wrapper for immutable.js
import { map, isEmpty } from 'lodash';
// @material-ui/core
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Dropdown from 'components/Dropdown/Dropdown';
import { HSpacer } from 'components/Spacer/Spacer';

/**
 * @ignore
 * @name FilterToolbarStyles
 * @description toolbarStyles for Filter component
 */
const toolbarStyles = {
  root: {
    marginBottom: '13px',
    marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0
  }
};

/**
 * @class SEFilter
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
const SEFilter = ({ classes, filters, defaultFilters, handleFilterChange }) => (
  <Toolbar className={classes.root}>
    {!isEmpty(filters)
      ? map(defaultFilters, def => {
          const { value: type, label: title } = def;
          if (filters[type]) {
            const { options, value } = filters[type];
            return (
              <React.Fragment key={type}>
                <Dropdown
                  title={title}
                  handleValueChange={val =>
                    handleFilterChange({ val, key: type, filters })
                  }
                  options={options}
                  value={value}
                />
                <HSpacer amount={20} />
              </React.Fragment>
            );
          }
        })
      : null}
  </Toolbar>
);

// component properties
SEFilter.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {array}
   * @description filter list
   */
  defaultFilters: PropTypes.array.isRequired,
  /**
   * @type {callback}
   * @description callback function
   */
  handleFilterChange: PropTypes.func.isRequired
};

export default withStyles(toolbarStyles)(SEFilter);

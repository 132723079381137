import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer
} from 'axios-extensions';
// redux utils
import store from 'redux-utils/store';
import { authTypes } from 'redux-utils/types';
// core library
import ls from 'lib/core/storageFactory';
import { setBaseUrl } from 'api/index';
import { deleteDB } from 'db';

const axiosCacheInstance = axios.create({
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter),
    {
      threshold: 0 // make new api call after 2 minutes
    }
  )
});

export const mockInstance = axios.create();

// catch and log all the http errors and return back
axiosCacheInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // eslint-disable-next-line
      console.log('response error', error.response);
      if (error.response.data.detail === 'Invalid token.') {
        store.dispatch({
          type: authTypes.inValidToken,
          error: true,
          payload: error.response.data
        });
        ls.clear();
        ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
        setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
        deleteDB();
        window.location = '/app';
      }
      return Promise.reject(error.response);
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // eslint-disable-next-line
      console.log('request error', error.request);
      return Promise.reject(error.request);
    }
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line
    console.log('general error', error.message);
    return Promise.reject(error);
  }
);
const axiosInstance = axios.create({});

// catch and log all the http errors and return back
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // eslint-disable-next-line
      console.log('response error', error.response);
      if (error.response.data.detail === 'Invalid token.') {
        store.dispatch({
          type: authTypes.inValidToken,
          error: true,
          payload: error.response.data
        });
        ls.clear();
        ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
        setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
        deleteDB();
        window.location = '/app';
      }
      return Promise.reject(error.response);
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // eslint-disable-next-line
      console.log('request error', error.request);
      return Promise.reject(error.request);
    }
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line
    console.log('general error', error.message);
    return Promise.reject(error);
  }
);

export { axiosInstance };
export default axiosCacheInstance;

import React from 'react';
import { VSpacer } from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const NoDataMessage = ({ shared, message, otherMessage }) => (
  <div style={{ minHeight: '250px' }}>
    <VSpacer amount={30} />
    <Typography weight="400" color="light25" align="center">
      {shared
        ? message
        : client.includes('kcc-ru')
        ? 'Данные для выбранной даты отсутствуют'
        : `No data available for the selected date. ${otherMessage}`}
    </Typography>
  </div>
);

export default NoDataMessage;

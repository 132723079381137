/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { Rect, Text, Label, Tag } from 'react-konva';

const getImageObject = (box, list) => {
  const index = list.findIndex(el => el.id === box.photoID);

  const imageObject = list[index];
  if (imageObject) {
    return {
      ...imageObject,
      index,
      scaled_height: Math.floor(imageObject.height * imageObject.height_ratio),
      scaled_width: Math.floor(imageObject.width * imageObject.width_ratio)
    };
  }
  return null;
};

const Crops = ({
  activeBoxes,
  selectedCrops,
  scaleDownFactor,
  imageList,
  checkBoxState,
  handleCropClick
}) => {
  return activeBoxes.map((box, i) => {
    const image = getImageObject(box, imageList);
    if (!image) return null;
    const imageRange =
      image.index === 0 ? imageList[0] : imageList.slice(0, image.index);
    const offsetX =
      image.index === 0 ? 0 : sumBy(imageRange, x => x.canvasWidth);
    const relativePoints = {
      left: (box.left / image.width) * image.canvasWidth + offsetX,
      right: (box.right / image.width) * image.canvasWidth + offsetX,
      top: (box.top / image.height) * image.canvasHeight,
      bottom: (box.bottom / image.height) * image.canvasHeight
    };

    const Box = () => (
      <Rect
        key={`${i + box.photoID}box${scaleDownFactor}`}
        x={relativePoints.left / scaleDownFactor}
        y={relativePoints.top / scaleDownFactor}
        width={(relativePoints.right - relativePoints.left) / scaleDownFactor}
        height={(relativePoints.bottom - relativePoints.top) / scaleDownFactor}
        stroke={
          selectedCrops.includes(box.id)
            ? '#fff'
            : box.color?.length > 0
            ? box.color
            : '#44ec09'
        }
        strokeWidth={3}
        onClick={() => handleCropClick({ crop: box, image: image.path })}
      />
    );

    return (
      <>
        {scaleDownFactor === 1 && box.text && (
          <Label
            key={`${i + box.photoID}label${scaleDownFactor}`}
            x={relativePoints.left / scaleDownFactor}
            y={relativePoints.top / scaleDownFactor}
          >
            <Tag fill="yellow" />
            <Text
              text={box.text}
              fontSize={10}
              stroke="#000"
              strokeWidth={1}
              padding={4}
            />
          </Label>
        )}
        {box.active !== undefined ? (
          box.active === true ? (
            <Box />
          ) : (
            checkBoxState.displayExclusions && <Box />
          )
        ) : (
          <Box />
        )}
      </>
    );
  });
};

Crops.propTypes = {
  checkBoxState: PropTypes.object,
  activeBoxes: PropTypes.array.isRequired,
  selectedCrops: PropTypes.array,
  scaleDownFactor: PropTypes.number,
  imageList: PropTypes.array.isRequired,
  handleCropClick: PropTypes.func
};

Crops.defaultProps = {
  checkBoxState: {},
  scaleDownFactor: 1,
  selectedCrops: [],
  handleCropClick: () => {}
};

export default Crops;
export { getImageObject };

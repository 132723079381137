import { call, put, take, race } from 'redux-saga/effects';
import { adminTypes } from 'redux-utils/types/index';
import { get, endpoints } from 'api/index';

const TIMEOUT_DELAY = 3000;

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

function* processQueueWorker(action) {
  while (true) {
    try {
      const { response, error } = yield call(get, {
        uriPath: endpoints.pollTask,
        payload: action.payload.params,
        headers: ['auth'],
        useCachedInstance: false
      });
      if (response) {
        const { data } = response;
        yield put({
          type: adminTypes.pollTask.success,
          payload: { response: data, ...action.payload }
        });
        yield call(delay, TIMEOUT_DELAY);
      } else {
        yield put({
          type: adminTypes.pollTask.failure,
          payload: { ...action.payload, message: error.data },
          error: true
        });
      }
    } catch (error) {
      yield put({
        type: adminTypes.pollTask.failure,
        payload: { ...action.payload, message: error.data },
        error: true
      });
    }
  }
}

function* injectedSaga() {
  while (true) {
    const action = yield take(adminTypes.pollTask.start);
    yield race([
      call(processQueueWorker, action),
      take(adminTypes.pollTask.stop)
    ]);
  }
}

export default injectedSaga;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// @material-ui/core
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
// core components
import CustomTableCell from 'table/CustomCell';
import Typography from 'components/Typography/Typography';
import Button from 'components/Button/Button';
// styles
import tableStyles from 'assets/jss/material-ui/components/tableWithTooltipStyles';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#eaeaea',
    color: 'black',
    borderRadius: '10px',
    '& h4': {
      fontSize: '12px'
    },
    padding: '0px',
    maxWidth: 800,
    width: 'auto',
    height: 'auto',
    '& div[class*="tooltipBodyContainer"]': {
      maxHeight: '300px !important',
      overflow: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgb(65, 65, 66) transparent',
      '&::-webkit-scrollbar': {
        display: 'block',
        height: '10px',
        width: '10px'
      },
      '&::-webkit-scrollbar-thumb': {
        // backgroundColor: 'rgb(65, 65, 66)',
        backgroundColor: 'rgb(117, 117, 117)',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '10px'
      }
    },
    '& div[class*="closeButtonContainer"]': {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: '#eaeaea',
      padding: '0px 15px 0px 0px',
      '& button': {
        width: '22px',
        height: '22px',
        minWidth: '22px',
        marginTop: '0px',
        marginBottom: '0px'
      }
    },
    '& div[class*="tooltipRow"]': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#eaeaea',
      padding: '0px 15px'
    },
    '& div[class*="tooltipCol"]': {
      width: '80px',
      textAlign: 'center',
      padding: '10px 0px',
      '& h4': {
        whiteSpace: 'break-spaces'
      }
    }
  }
}))(Tooltip);

const CustomTable = ({
  classes,
  tableData,
  height,
  setTableTooltipStatus,
  tableTooltipStatus,
  tableName
}) => {
  const [open, setOpen] = useState(false);
  const [tooltipKey, setTooltipKey] = useState({
    rowNo: '',
    colName: ''
  });

  const handleTooltipClose = event => {
    if (event.target.id.includes('tooltipCol')) {
      return;
    }
    setOpen(false);
    setTooltipKey({
      rowNo: '',
      colName: ''
    });
  };

  const handleTooltipOpen = (rowNo, colName) => {
    setOpen(true);
    setTooltipKey({
      rowNo,
      colName
    });
  };

  useEffect(() => {
    if (
      tableTooltipStatus.status === true &&
      tableTooltipStatus.key !== tableName
    ) {
      setOpen(false);
      setTooltipKey({
        rowNo: '',
        colName: ''
      });
    }
  }, [tableTooltipStatus]);

  return (
    <Paper className={classes.root} style={{ height }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            {tableData.performance_table.columns.map(col => {
              if (col.type === 'performance_number') {
                return (
                  <CustomTableCell
                    key={col.dataKey}
                    style={{ color: '#d2d5dc' }}
                  >
                    <div style={{ width: '150px', textAlign: 'center' }}>
                      {col.label}
                      <div style={{ textAlign: 'center' }}>{col.subheader}</div>
                    </div>
                  </CustomTableCell>
                );
              }
              return (
                <CustomTableCell key={col.dataKey} style={{ color: '#d2d5dc' }}>
                  {col.label}
                </CustomTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.performance_table.datasets.map((row, i) => {
            return (
              <TableRow className={`${classes.row}`} key={i}>
                {tableData.performance_table.columns.map(col => {
                  if (col.type === 'performance_number') {
                    return (
                      <CustomTableCell key={col.dataKey}>
                        <div style={{ display: 'inline-block' }}>
                          <HtmlTooltip
                            PopperProps={{
                              disablePortal: false
                            }}
                            onClose={handleTooltipClose}
                            onOpen={handleTooltipOpen}
                            open={
                              i === tooltipKey.rowNo &&
                              col.dataKey === tooltipKey.colName &&
                              open
                            }
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            interactive
                            title={
                              <ClickAwayListener
                                onClickAway={handleTooltipClose}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      padding: '16px 0px'
                                    }}
                                  >
                                    {isEmpty(
                                      row[col.dataKey].tooltip_data.columns
                                    ) ||
                                    isEmpty(
                                      row[col.dataKey].tooltip_data.datasets
                                    ) ? (
                                      <div
                                        style={{
                                          minWidth: '150px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography color="rgba(0, 0, 0, 0.8)">
                                          No data available
                                        </Typography>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="tooltipRow">
                                          {row[
                                            col.dataKey
                                          ].tooltip_data.columns?.map(
                                            colDetail => (
                                              <div
                                                className="tooltipCol"
                                                key={colDetail.label}
                                              >
                                                <Typography
                                                  color="#505050"
                                                  align="center"
                                                  weight={700}
                                                >
                                                  {colDetail.label}
                                                </Typography>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div className="tooltipBodyContainer">
                                          {row[
                                            col.dataKey
                                          ].tooltip_data.datasets?.map(
                                            (rowDetail, index) => (
                                              <div
                                                className="tooltipRow"
                                                key={index}
                                              >
                                                {row[
                                                  col.dataKey
                                                ].tooltip_data.columns?.map(
                                                  colDetail => (
                                                    <div
                                                      className="tooltipCol"
                                                      key={colDetail.dataKey}
                                                    >
                                                      <Typography
                                                        color="#6c6c6c"
                                                        align="center"
                                                      >
                                                        {
                                                          rowDetail[
                                                            colDetail.dataKey
                                                          ]
                                                        }
                                                      </Typography>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    <div className="closeButtonContainer">
                                      <Button
                                        color="dark"
                                        round
                                        justIcon
                                        onClick={handleTooltipClose}
                                      >
                                        <CloseIcon />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </ClickAwayListener>
                            }
                          >
                            <div
                              style={{ width: '150px', textAlign: 'center' }}
                            >
                              <div
                                style={{
                                  color: row[col.dataKey].color,
                                  border: '2px solid #52535d',
                                  padding: '8px',
                                  width: 'fit-content',
                                  display: 'inline-block'
                                }}
                                onClick={() => {
                                  setTableTooltipStatus({
                                    status: true,
                                    key: tableName
                                  });
                                  handleTooltipOpen(i, col.dataKey);
                                }}
                                id="tooltipCol"
                              >
                                {row[col.dataKey].value}
                              </div>
                            </div>
                          </HtmlTooltip>
                        </div>
                      </CustomTableCell>
                    );
                  }
                  return (
                    <CustomTableCell key={col.dataKey}>
                      {row[col.dataKey]}
                    </CustomTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setTableTooltipStatus: PropTypes.func.isRequired,
  tableTooltipStatus: PropTypes.object.isRequired,
  tableName: PropTypes.string.isRequired
};

const TableWrapper = withStyles(tableStyles)(props =>
  props.tableData.performance_table.datasets.length === 0 ? (
    <Typography center color="light25" size="xs">
      No data to display
    </Typography>
  ) : (
    CustomTable({
      ...props,
      height: props.height,
      setTableTooltipStatus: props.setTableTooltipStatus,
      tableTooltipStatus: props.tableTooltipStatus,
      tableName: props.tableName
    })
  )
);

TableWrapper.propTypes = {
  /**
   * @type {string}
   * @description - Width of the table
   */
  width: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tableData: PropTypes.object.isRequired,
  setTableTooltipStatus: PropTypes.func.isRequired,
  tableTooltipStatus: PropTypes.object.isRequired,
  tableName: PropTypes.string.isRequired
};

TableWrapper.defaultProps = {
  width: '90%',
  height: '300px'
};

export default TableWrapper;

const types = {
  granularityChange: 'CHART_GRANULARITY_CHANGE_NOTIFICATION',
  slicerChange: 'CHART_SLICER_CHANGE_NOTIFICATION',
  getChartList: {
    request: 'GET_CHART_LIST_REQUEST',
    success: 'GET_CHART_LIST_SUCCESS',
    failure: 'GET_CHART_LIST_FAILURE',
    fromAPI: 'GET_CHART_LIST_FROM_API'
  },
  getChartData: {
    request: 'GET_CHART_DATA_REQUEST',
    success: 'GET_CHART_DATA_SUCCESS',
    failure: 'GET_CHART_DATA_FAILURE',
    fromAPI: 'GET_CHART_DATA_FROM_API'
  },
  getStoreCount: {
    request: 'GET_STORE_COUNT_REQUEST',
    success: 'GET_STORE_COUNT_SUCCESS',
    failure: 'GET_STORE_COUNT_FAILURE',
    fromAPI: 'GET_STORE_COUNT_FROM_API'
  },
  downloadChartData: {
    request: 'DOWNLOAD_CHART_DATA_REQUEST',
    success: 'DOWNLOAD_CHART_DATA_SUCCESS',
    failure: 'DOWNLOAD_CHART_DATA_FAILURE',
    fromAPI: 'DOWNLOAD_CHART_DATA_FROM_API'
  }
};

export default types;

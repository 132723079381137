/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from 'components/Typography/Typography';

const styles = {
  root: {
    padding: 10,
    display: 'flex'
  },
  data: {
    marginLeft: 'auto',
    marginRight: 10,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
};

const Count = ({ classes, icon, data: { header, value } }) => (
  <div className={classes.root}>
    <Icon info color="orange">
      {icon}
    </Icon>
    <div className={classes.data}>
      <Typography color="light25" weight={400} size="s">
        {header}
      </Typography>
      <Typography color="#fff" weight={400} size="l">
        {value}
      </Typography>
    </div>
  </div>
);

Count.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(Count);

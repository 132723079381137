import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// assets
import { ExpandLessIcon, ExpandMoreIcon } from 'assets/icons/appIcons';

// function to check if the sidebar route is currently active
const checkActiveRoute = (path, routeName) => path.indexOf(routeName) > -1;

// stateless component for non-nested menu item
const NavItem = ({
  classes,
  handleItemClick,
  item,
  listItemClasses,
  listIcon: ListIcon
}) => (
  <NavLink to={item.path} className={classes.item} activeClassName="active">
    <ListItem
      onClick={handleItemClick}
      button
      className={classes.itemLink + listItemClasses}
    >
      <ListItemIcon className={classes.itemIcon}>{ListIcon}</ListItemIcon>
      <ListItemText
        primary={item.sidebarName}
        className={classes.itemText}
        disableTypography
      />
    </ListItem>
  </NavLink>
);

// stateless component for nested menu item
const ExpandList = ({
  classes,
  handleItemClick,
  handleItemExpand,
  isExpanded,
  item,
  listIcon: ListIcon,
  listItemClasses,
  location,
  t,
  isNested
}) => (
  <>
    <ListItem
      className={`${listItemClasses} ${classes.itemLink}`}
      button
      onClick={() => handleItemExpand(item.path)}
    >
      <ListItemIcon className={classes.itemIcon}>
        <ListIcon />
      </ListItemIcon>
      <ListItemText
        primary={item.sidebarName}
        className={classes.itemText}
        disableTypography
      />
      {isExpanded[item.path] ? (
        <ExpandLessIcon className={classes.iconRight} />
      ) : (
        <ExpandMoreIcon className={classes.iconRight} />
      )}
    </ListItem>
    <Collapse in={isExpanded[item.path]} timeout="auto" unmountOnExit>
      {item.subRoutes.map((sub, i) => {
        const activeClass = classNames({
          [` ${classes.indicator}`]: checkActiveRoute(
            location.pathname,
            sub.path
          )
        });
        const SubIcon = sub.icon;
        return sub.subRoutes ? (
          <div
            key={i}
            className={`${classes.collapseItem} ${classes.nestedRoute}`}
          >
            <ExpandList
              isNested
              location={location}
              t={t}
              item={sub}
              classes={classes}
              handleItemClick={handleItemClick}
              listItemClasses={activeClass}
              listIcon={SubIcon}
              isExpanded={isExpanded}
              handleItemExpand={handleItemExpand}
            />
          </div>
        ) : (
          <NavLink
            key={i}
            to={sub.path}
            className={`${classes.item} ${classes.collapseItem}`}
          >
            <ListItem
              button
              onClick={handleItemClick}
              className={classes.itemLink + activeClass}
            >
              <ListItemText
                inset={!isNested}
                primary={sub.sidebarName}
                className={`${classes.itemText} ${classes.fullText}`}
                disableTypography
              />
              <ListItemText
                inset={!isNested}
                primary={sub.acronym}
                className={`${classes.itemText} ${classes.acronym}`}
                disableTypography
              />
            </ListItem>
          </NavLink>
        );
      })}
    </Collapse>
  </>
);

class SidebarMenu extends Component {
  state = {};

  handleItemClick = () => {
    const { handleDrawerToggle, drawerRight } = this.props;
    if (drawerRight) handleDrawerToggle();
  };

  handleItemExpand = path => {
    this.setState(state => ({ [path]: !state[path] }));
  };

  render() {
    const { classes, routes, location, t, sidebarColor } = this.props;
    return (
      <List className={classes.list}>
        {routes.map((item, key) => {
          const listItemClasses = classNames({
            [` ${classes.indicator}`]: checkActiveRoute(
              location.pathname,
              item.path
            )
          });
          const ListIcon = item.icon;

          if (item.redirect !== undefined) return null;
          return item.subRoutes !== undefined && item.subRoutes.length !== 0 ? (
            <ExpandList
              location={location}
              sidebarColor={sidebarColor}
              key={key}
              t={t}
              item={item}
              classes={classes}
              handleItemClick={this.handleItemClick}
              listItemClasses={listItemClasses}
              listIcon={ListIcon}
              isExpanded={this.state}
              handleItemExpand={this.handleItemExpand}
            />
          ) : (
            <NavItem
              key={key}
              t={t}
              item={item}
              classes={classes}
              handleItemClick={this.handleItemClick}
              listItemClasses={listItemClasses}
              listIcon={ListIcon}
            />
          );
        })}
      </List>
    );
  }
}

// component properties
NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  listIcon: PropTypes.any.isRequired,
  listItemClasses: PropTypes.string.isRequired
};

ExpandList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  handleItemExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  listIcon: PropTypes.any.isRequired,
  listItemClasses: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isNested: PropTypes.bool
};

ExpandList.defaultProps = {
  isNested: false
};

SidebarMenu.propTypes = {
  drawerRight: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  sidebarColor: PropTypes.string.isRequired
};

export default SidebarMenu;

import { createMuiTheme } from '@material-ui/core/styles';
import colors from 'styles/__variables';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    background: { default: colors.dark.default, paper: colors.dark.dark25 }
  },
  props: {
    sideBarMini: 75,
    sideBarExpanded: 210
  },
  overrides: {
    drawer: { background: colors.dark.drawer }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Helvetica Neue",sans-serif'
  }
});

const lightTheme = createMuiTheme({
  palette: {
    type: 'light'
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'loginPageFont'
  }
});

const ThemeProvider = {
  light: lightTheme,
  dark: darkTheme
};

export default ThemeProvider;

import { combineReducers } from 'redux';
// js util library
import { includes } from 'lodash';
// utils
import detectDevice from 'utils/device/Device';
// models
import reducerModel from 'models/reducerModel';
// reducers
import adminReducer from 'redux-utils/reducers/adminReducer';
import appReducer from 'redux-utils/reducers/appReducer';
import authReducer from 'redux-utils/reducers/authReducer';
import chartReducer from 'redux-utils/reducers/chartReducer';
import filterReducer from 'redux-utils/reducers/filterReducer';
import layoutReducer from 'redux-utils/reducers/layoutReducer';
import networkReducer from 'redux-utils/reducers/networkReducer';
import storeExplorerReducer from 'redux-utils/reducers/storeExplorerReducer';
import SEReducer from 'redux-utils/reducers/SEReducer';
import genericSEReducer from 'redux-utils/reducers/genericSEReducer';
import storeNewReducer from 'redux-utils/reducers/storeNewReducer';
import userProfileReducer from 'redux-utils/reducers/userProfileReducer';
import appImagesReducer from 'redux-utils/reducers/appImagesReducer';
import accuracyReducer from 'redux-utils/reducers/accuracyReducer';
import merchReducer from 'redux-utils/reducers/merchReducer';
import slicerReducer from 'redux-utils/reducers/slicerReducer';

const deviceMode = (
  state = { device: detectDevice(), size: window.innerWidth },
  action
) => {
  switch (action.type) {
    case 'DEVICE_CHANGE':
      return { ...state, device: action.payload, size: action.size };
    default:
      return state;
  }
};

// higher-order reducer, to limit a reducer to work
// on actions matching a predicate only
const limited = (reducer, predicate) => (state, action) => {
  if (predicate(action)) {
    return reducer(state, action);
  }
  return state;
};

const mainReducer = combineReducers({
  appImages: appImagesReducer,
  adminStore: limited(adminReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.admin.id)
  ),
  accuracy: limited(accuracyReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.accuracy.id)
  ),
  appStore: limited(appReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.app.id)
  ),
  slicerStore: limited(slicerReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.slicer.id)
  ),
  layoutStore: limited(layoutReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.layout.id)
  ),
  filterStore: limited(filterReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.filter.id)
  ),
  chartStore: limited(chartReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.chart.id)
  ),
  merchEvaluation: limited(merchReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.merchEvaluation.id)
  ),
  storeExplorer: limited(storeExplorerReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.storeExplorer.id)
  ),
  SE: limited(SEReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.SE.id)
  ),
  genericSE: limited(genericSEReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.genericSE.id)
  ),
  storeExplorerNew: limited(storeNewReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.storeExplorerNew.id)
  ),
  userStore: limited(userProfileReducer, action =>
    action.meta === undefined
      ? true
      : includes(action.meta.reducerID, reducerModel.userProfile.id)
  ),
  authStore: authReducer,
  deviceMode,
  network: networkReducer
});

export default mainReducer;

const loaderStyles = theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: theme.palette.type === 'light' && '#0f0d36 !important'
  },
  plain: {
    height: '30px !important',
    width: '30px !important'
  },
  plainWrapper: {
    display: 'inherit',
    padding: 10,
    '& svg': {
      color: theme.palette.type === 'light' && '#0f0d36 !important'
    }
  },
  loader: {
    '& svg': {
      color: theme.palette.type === 'light' && '#0f0d36 !important'
    }
  },
  wrapper: {
    background: '#fff',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '100'
  },
  centered: {
    width: '1127px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    verticalAlign: 'middle',
    margin: 0,
    left: 0,
    top: 0,
    textAlign: 'center',
    zIndex: 1000
  },
  roundLoader: {
    height: '100% !important'
  }
});
export default loaderStyles;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// @material-ui/core components
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// routers
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from 'routes';
// redux utils
import { appActions } from 'redux-utils/actions';
import { appSelector } from 'redux-utils/selectors';
// core components
import NoInternetSnackBar from 'components/SnackBar/NoInternetSnackBar';
// utils
import ConnectedDeviceMonitor from 'utils/device/DeviceMonitor';
// styles
import themeProvider from 'themes/ThemeProvider';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
// containers
import LandingPage from 'pages/Landing/LandingPage';
import LoginPage from 'pages/LoginPage';
import ForgotPassword from 'pages/LoginPage/ForgotPassword';
import VerifyPassword from 'pages/LoginPage/VerifyPassword';
import ResetPassword from 'pages/LoginPage/ResetPassword';
import SSOLoginSuccess from 'pages/Auth/SSOLoginSuccess';
// import ResetPassword from 'pages/Auth/ResetPassword';
import TestContainer from 'pages/TestContainer';
// views
import Dashboard from 'layout/DashboardPanel/DashboardPanel';
import AdminPanel from 'layout/Admin/AdminPanel';
import ErrorBoundary from 'pages/ErrorBoundary/ErrorBoundary';
import UserProfileContainer from 'pages/UserProfile/UserProfileContainer';

/**
 * @description Stateless App component to render the views.
 * Entry file to the application
 * @class
 * @returns {reactnode} React Node
 */

class App extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-unused-vars
    const { dispatchStartWatchNetwork } = this.props;
    // dispatchStartWatchNetwork();
  }

  componentDidMount() {
    if (window.location.pathname === '/reset_password') {
      const { dispatchGetLayoutRequest } = this.props;
      dispatchGetLayoutRequest();
    }

    if (!window.location.pathname.includes('app')) {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('hide', 'launcher');
      }
    }
  }

  componentWillUnmount() {
    const { dispatchStopWatchNetwork } = this.props;
    dispatchStopWatchNetwork();
  }

  render() {
    const { network, dashboardType } = this.props;
    return (
      <ErrorBoundary>
        <MuiThemeProvider
          theme={
            dashboardType === 'v1' ? themeProvider.dark : themeProvider.light
          }
        >
          <CssBaseline />
          <NoInternetSnackBar network={network} />
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <LandingPage {...props} />}
              />
              <Route
                exact
                path="/login"
                render={props => <LoginPage {...props} />}
              />
              <Route exact path="/test" render={() => <TestContainer />} />
              <Route
                path="/login/forgotPassword"
                render={props => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/password_reset_confirm/"
                render={props => <VerifyPassword {...props} />}
              />
              <Route
                exact
                path="/loginValidationSuccess/:token/:client/:data"
                render={props => <SSOLoginSuccess {...props} />}
              />
              <Route
                exact
                path="/reset_password/"
                render={props => <ResetPassword {...props} />}
              />
              <PrivateRoute path="/app" component={Dashboard} />
              <PrivateRoute path="/feature" component={AdminPanel} />
              <Route
                path="/user-profile"
                render={() => <UserProfileContainer />}
              />
            </Switch>
          </Router>
          <ConnectedDeviceMonitor />
        </MuiThemeProvider>
      </ErrorBoundary>
    );
  }
}

// component properties
App.propTypes = {
  network: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description Redux network watcher start action dispatcher
   */
  dispatchStartWatchNetwork: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Redux network watcher stop action dispatcher
   */
  dispatchStopWatchNetwork: PropTypes.func.isRequired,
  dashboardType: PropTypes.string
};

App.defaultProps = {
  dashboardType: ''
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const mapStateToProps = state => ({
  network: appSelector.getNetworkState(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version
});

const mapDispatchToProps = {
  dispatchStartWatchNetwork: appActions.startWatchNetworkAction,
  dispatchStopWatchNetwork: appActions.stopWatchNetworkAction,
  dispatchGetLayoutRequest: () => appActions.layoutRequestAction({})
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const types = {
  getUserProfile: {
    request: 'GET_USER_PROFILE_REQUEST',
    success: 'GET_USER_PROFILE_SUCCESS',
    failure: 'GET_USER_PROFILE_FAILURE',
    fromAPI: 'GET_USER_PROFILE_FROM_API'
  },
  getUserPreference: {
    request: 'GET_USER_PREFERENCE_REQUEST',
    success: 'GET_USER_PREFERENCE_SUCCESS',
    failure: 'GET_USER_PREFERENCE_FAILURE',
    fromAPI: 'GET_USER_PREFERENCE_FROM_API'
  },
  saveUserPreference: {
    request: 'SAVE_USER_PREFERENCE_REQUEST',
    success: 'SAVE_USER_PREFERENCE_SUCCESS',
    failure: 'SAVE_USER_PREFERENCE_FAILURE'
  }
};

export default types;

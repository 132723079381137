/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, each, map } from 'lodash';
import { connect } from 'react-redux';
// redux-utils
import {
  appActions,
  chartActions,
  storeExplorerActions
} from 'redux-utils/actions/index';
import {
  appSelector,
  chartSelector,
  storeExplorerSelector,
  userSelector,
  SESelector
} from 'redux-utils/selectors/index';
// core functions
import { getRouteID } from 'lib/utils/getActiveRoute';
import constants from 'models/constants';
// components
import Loader from 'components/Loader/Loader';
// views
import ChartRenderer from 'pages/Common/ChartRenderer';
import NoDataMessage from './NoDataMessage';
import SEFilterContainer from './SEFilterContainer';
import PhotosContainer from './Photos/PhotosContainer';

class StoreExplorerContainer extends Component {
  routeID = '';

  exportChartName = null;

  state = {
    tableData: null
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    const routeID = getRouteID(pathname);
    this.routeID = routeID;
  }

  componentWillReceiveProps = nextProps => {
    const {
      chartList,
      chartParamsValue,
      shelfData: { imageList, variantList }
    } = nextProps;
    const { dispatchGetImages } = this.props;
    if (variantList && imageList) {
      this.computeTableData(variantList);
    }
    if (
      !this.props.chartList &&
      chartList &&
      !isEmpty(chartParamsValue.fl_dropdown_date)
    ) {
      this.getChartData(chartList, chartParamsValue);
      dispatchGetImages({
        params: {
          fl_placement: chartParamsValue.fl_placement.value.value,
          fl_category: chartParamsValue.fl_category.value.value,
          fl_supervisor: chartParamsValue.fl_supervisor?.value?.value,
          fl_merchandiser: chartParamsValue.fl_merchandiser?.value?.value,
          fl_store_brand: chartParamsValue.fl_store_brand?.value?.value,
          fl_store: chartParamsValue.fl_store?.value?.value,
          fl_dropdown_date: chartParamsValue.fl_dropdown_date?.value?.value
        }
      });
    }
  };

  fetchChartList = category => {
    const {
      location: { pathname }
    } = window;
    const { dispatchGetChartList } = this.props;
    const routeID = getRouteID(pathname);
    const params = {
      route_id: routeID,
      fl_category: category
    };
    dispatchGetChartList({
      params,
      cacheRequest: false
    });
  };

  getChartData = (chartList, chartParamsValue) => {
    const { dispatchGetChartData } = this.props;
    each(chartList, chart => {
      const requestObject = this.computeRequestParams(chart, chartParamsValue);
      dispatchGetChartData({
        params: requestObject,
        routeID: this.routeID
      });
    });
  };

  computeRequestParams = (chart, chartParamsValue) => {
    const requestObject = {};
    requestObject.chart_name = chart.name;
    requestObject.fl_chart_type = chart.type;
    requestObject.fl_placement = chartParamsValue.fl_placement.value.value;
    requestObject.fl_category = chartParamsValue.fl_category.value.value;
    requestObject.fl_supervisor = chartParamsValue.fl_supervisor.value.value;
    requestObject.fl_merchandiser =
      chartParamsValue.fl_merchandiser.value.value;
    requestObject.fl_store_brand = chartParamsValue.fl_store_brand.value.value;
    requestObject.fl_store = chartParamsValue.fl_store?.value?.value;
    requestObject.fl_dropdown_date =
      chartParamsValue.fl_dropdown_date?.value?.value;
    return requestObject;
  };

  computeTableData = data => {
    const columns = [];
    const ignoreKeys = ['color', 'min', 'max', 'delta'];
    const tableData = {
      datasets: [],
      columns: []
    };
    each(data[0].details, item => {
      map(item, (_v, key) => {
        if (!ignoreKeys.includes(key)) {
          columns.push(key);
          tableData.columns.push({
            label: key.split('_').join(' '),
            dataKey: key
          });
        }
      });
    });
    each(data, el => {
      const { bounding_boxes: bnBox, details } = el;
      const tempItem = { bnBox };
      map(details, item => {
        const itemKeys = Object.keys(item);
        const arrays = [itemKeys, columns];
        const result = arrays
          .sort((a, b) => {
            return a.length - b.length;
          })
          .shift()
          .filter(v => {
            return arrays.every(a => {
              return a.indexOf(v) !== -1;
            });
          });
        if (itemKeys.includes('color')) {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = (
            <p style={{ color: item.color, margin: 0 }}>{item[result[0]]}</p>
          );
        } else if (itemKeys.includes('price_compliance')) {
          tempItem[result[0]] = item;
        } else {
          if (result[0] === 'count') {
            if (item.count !== 0) {
              tempItem[result[0]] = item[result[0]];
            }
          } else {
            tempItem[result[0]] = item[result[0]];
          }
          // eslint-disable-next-line prefer-destructuring
        }
      });
      tableData.datasets.push({ ...tempItem, hide: !tempItem.count });
    });
    // concat other brand to last of array
    const position = tableData.datasets.map(e => e.brand).indexOf('Others');
    tableData.datasets = tableData.datasets.concat(
      tableData.datasets.splice(position, 1)
    );
    this.setState({ tableData });
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} value - Changed value
   * @param {string} key - value key
   * @return {undefined}
   */
  handleFilterChange = (value, key) => {
    if (!isEmpty(value)) {
      const { routeID } = this;
      const { chartList, chartParamsValue, dispatchFilterChange } = this.props;
      dispatchFilterChange({
        routeID,
        value
      });
      if (value.fl_category?.value) {
        this.fetchChartList(value.fl_category.value.value);
      }
      if (key === 'fl_dropdown_date') {
        this.getChartData(chartList, { ...chartParamsValue, ...value });
      }
    }
  };

  render() {
    const { tableData } = this.state;
    const {
      chartList,
      granularities,
      chartData: chartDataList,
      loadingState,
      useDisplayTag,
      shelfData: { imageList, excelReport }
    } = this.props;

    return (
      <>
        <SEFilterContainer
          routeID={this.routeID}
          handleFilterChange={this.handleFilterChange}
        />
        {loadingState ? (
          <Loader plain circular centered color="secondary" inline />
        ) : imageList && tableData ? (
          <div
            style={{
              height: '83vh',
              overflow: 'auto',
              overflowX: 'hidden',
              maxWidth: constants.mainWindowWidth
            }}
          >
            <PhotosContainer
              useExport={excelReport}
              useDisplayTag={useDisplayTag}
              photosList={imageList}
              tableData={tableData}
              handleDataDownload={this.handleDataDownload}
            />
            <ChartRenderer
              routeID={this.routeID}
              usePin={false}
              chartList={chartList}
              slicers={{}}
              granularities={granularities}
              chartDataList={chartDataList}
              handleGranularityClick={this.handleGranularityClick}
              handlePinClick={this.handlePinClick}
              handleDataDownload={this.handleDataDownload}
            />
          </div>
        ) : (
          <NoDataMessage />
        )}
      </>
    );
  }
}

StoreExplorerContainer.propTypes = {
  dispatchGetImages: PropTypes.func.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getChartData = chartSelector.makeGetChartData();
  const getChartList = chartSelector.makeGetChartList();
  const getGranularitiesList = chartSelector.makeGetGranularitiesList();
  const getFilterList = appSelector.makeGetFilterList();

  const mapStateToProps = state => ({
    chartData: getChartData(state, { routeID }),
    chartList: getChartList(state, { routeID }),
    chartParamsValue: getParamsValueList(state, { routeID }),
    granularities: getGranularitiesList(state, { routeID }),
    userPrefs: userSelector.getUserPrefs(state),
    loadingState: SESelector.selectLoadingStatus(state),
    device: state.deviceMode,
    useDisplayTag: appSelector.getClientPreferences(state)
      .store_explorer_display_tag,
    filters: getFilterList(state),
    shelfData: storeExplorerSelector.getShelfImages(state)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetImages: payload =>
    dispatch(storeExplorerActions.getShelfImageAction(payload)),
  dispatchGetCurationStores: payload =>
    dispatch(
      storeExplorerActions.getCurationStoresAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchGetCurationSessions: payload =>
    dispatch(
      storeExplorerActions.getCurationSessionsAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchDownloadChartData: payload =>
    dispatch(chartActions.downloadChartDataAction(payload)),
  dispatchGetChartList: payload =>
    dispatch(chartActions.getChartListAction(payload)),
  dispatchGetChartData: payload =>
    dispatch(chartActions.getChartDataAction(payload)),
  dispatchGranularityChange: payload =>
    dispatch(chartActions.granularityChangeAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(StoreExplorerContainer);

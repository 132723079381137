import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import detectDevice from './Device';

export class DeviceMonitor extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch({
      type: 'DEVICE_CHANGE',
      payload: detectDevice(),
      size: window.innerWidth
    });
  }

  // Listens for window resize event.If a device change is detected,
  // dispatches an action to update the redux state
  componentDidMount() {
    window.addEventListener('resize', () => {
      if (detectDevice() !== this.props.device) {
        this.props.dispatch({
          type: 'DEVICE_CHANGE',
          payload: detectDevice(),
          size: window.innerWidth
        });
      }
    });
  }

  render() {
    return null;
  }
}

// prop-types validation
DeviceMonitor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  device: PropTypes.string
};

// defautlProps
DeviceMonitor.defaultProps = {
  device: detectDevice()
};

// subscribe to redux store changes
const mapStateToProps = state => ({ device: state.deviceMode.device });

export default withTranslation('page1')(
  connect(mapStateToProps)(DeviceMonitor)
);

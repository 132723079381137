import React, { useEffect, useState } from 'react';
import { object, func, number, bool, string } from 'prop-types';
import { map } from 'lodash';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MuiButton from '@material-ui/core/Button';
import Button from 'components/Button/Button';
// assets
import {
  EditIcon,
  DownloadAltIcon,
  RefreshIcon,
  ResizeIcon
} from 'assets/icons/appIcons';
import { scrollbar } from 'assets/jss/material-ui/material-ui';

import AddRow from '../AddRow';
import PackshotUpload from './PackshotUpload';
import ExcelUpload from '../ExcelUpload';
import PaginationComponent from '../Table/PaginationComponent';
import TableComponent from '../Table/TableComponent';
import SearchComponent from '../SearchComponent';

// utils
import { hasPermission } from '../utils';
import { idPrefix, PermissionsEnum } from '../enums';

const ITEM_HEIGHT = 48;

const styles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonGroup: {
    '& button': {
      marginLeft: '10px'
    }
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pagination: {
    marginTop: 10
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button': {
      padding: '8px 20px !important',
      textTransform: 'capitalize !important',
      marginLeft: '10px'
    }
  },
  grayButton: {
    // background: '#27293d',
    background: theme.palette.type === 'light' ? '#18c7fe' : '#27293d',
    color: theme.palette.type === 'light' && '#0f0d36',
    boxShadow: 'none !important',
    padding: '8px 20px !important',
    textTransform: 'capitalize !important',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && '#0f0d36'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && '#0f0d36'
    }
  },
  modalContent: {
    color: '#94949a',
    backgroundColor: '#1e1e2b',
    margin: 80,

    outline: 'none',
    overflow: 'auto',
    ...scrollbar,
    scrollbarColor:
      theme.palette.type === 'light'
        ? '#f5f5f5 transparent'
        : 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5' : 'rgb(65, 65, 66)',
      borderRadius: 8
    },
    padding: '12px 50px !important'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  resizeButton: {
    '& svg:nth-of-type(1)': {
      transform: 'rotate(90deg)'
    }
  },
  inlineBlock: {
    display: 'inline-block',
    '& button': {
      background: theme.palette.type === 'light' && '#18c7fe',
      '&:hover': {
        background: theme.palette.type === 'light' && '#18c7fe'
      },
      '&:focus': {
        background: theme.palette.type === 'light' && '#18c7fe'
      }
    }
  }
});

const useDebounce = true;

const SKUPage = ({
  pageData,
  pageTotal,
  getPageData,
  getSearchData,
  menuProps,
  classes,
  handlePackshotUpload,
  handleExcelUpload,
  packshotUpload,
  excelUploadData,
  pollTask,
  saveTransaction,
  apiRequestStatus,
  downloadAllTemplates,
  downloadTemplate,
  downloadExcel,
  downloadPackshots,
  loaderStatus,
  dashboardType
}) => {
  const [refreshButtonStatus, setRefreshButtonStatus] = useState(false);
  const [resizeState, setResizeState] = useState(false);
  const [transactionData, setTransactionData] = useState({
    add: {},
    delete: {},
    update: {}
  });
  const [tableData, setTableData] = useState(pageData);
  const [createdRows, setCreatedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [editState, setEditState] = useState({});
  const [disableInteraction, setDisableInteraction] = useState(false);
  const [isAddRowModalOpen, setAddRowModalState] = useState(false);
  const [isPackshotModalOpen, setPackshotModalState] = useState(false);
  const [isExcelModalOpen, setExcelModalState] = useState(false);
  const [searchValue, setSearchValue] = useState({ col: '', value: '' });
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const hasUpdatePermission =
    hasPermission(menuProps.permissions, PermissionsEnum.update) ||
    hasPermission(menuProps.permissions, PermissionsEnum.all);

  useEffect(() => {
    if (loaderStatus) {
      setDisableInteraction(true);
    } else {
      setDisableInteraction(false);
    }
  }, [loaderStatus]);

  useEffect(() => {
    if (pageData && menuProps.mandatory_cols) {
      const data = [];
      pageData.columns.forEach(dCol => {
        let isMandatory = false;
        menuProps.mandatory_cols.forEach(mCol => {
          isMandatory = isMandatory || dCol.key === mCol.key;
        });
        data.push({ ...dCol, is_mandatory: isMandatory });
      });
      setTableData({ ...pageData, columns: data });
    }
    setEditState({});
    setTransactionData({
      add: {},
      delete: {},
      update: {}
    });
    setCreatedRows([]);
  }, [pageData, menuProps.mandatory_cols]);

  const handleEditIconClick = event => {
    setAnchorEl(event.currentTarget);
    setDisableInteraction(true);
  };

  const handleDownloadIconClick = event => {
    setDownloadAnchorEl(event.currentTarget);
    setDisableInteraction(true);
  };

  const handleEditMenuClose = () => {
    setAnchorEl(null);
    setEditState({});
    setDisableInteraction(false);
  };

  const handleDownloadMenuClose = () => {
    setDownloadAnchorEl(null);
    setDisableInteraction(false);
  };

  const handleEditData = () => {
    handleEditMenuClose();
    setEditState({ editExisting: true });
  };

  const handleToggleValueChange = row => {
    const payload = {};
    const data = {
      ...transactionData,
      update: {
        ...transactionData.update,
        [row.id]: transactionData.update[row.id]
          ? { ...transactionData.update[row.id], ...row }
          : { ...row }
      }
    };
    setTransactionData(data);
    map(data, (fields, crudType) => {
      payload[crudType.toUpperCase()] = map(fields, meta => {
        return {
          meta: { ...meta, id: crudType === 'add' ? '' : meta.id },
          id: crudType === 'add' ? '' : meta.id
        };
      });
    });
    saveTransaction(payload);
  };

  const handleSubmitClick = () => {
    setShowConfirmationPopup(true);
    setDisableInteraction(true);
    setSnackBarState({
      isOpen: true,
      message: `This will modify the existing data. Are you sure?`,
      severity: 'info'
    });
  };

  const onPageChangeHandler = params => {
    if (searchValue.value !== '') {
      getSearchData(
        searchValue.col,
        searchValue.value,
        params.offset,
        params.limit
      );
    } else {
      getPageData({ offset: params.offset, limit: params.limit });
    }
  };

  const handleDownloadAllTemplates = () => {
    handleDownloadMenuClose();
    downloadAllTemplates();
  };

  const handleDownloadTemplate = () => {
    handleDownloadMenuClose();
    downloadTemplate();
  };

  const handleDownloadExcel = () => {
    handleDownloadMenuClose();
    downloadExcel();
  };

  const handleDownloadPackshots = () => {
    handleDownloadMenuClose();
    downloadPackshots();
  };

  const discardEdits = () => {
    setEditState({});
    if (pageData && menuProps.mandatory_cols) {
      const data = [];
      pageData.columns.forEach(dCol => {
        let isMandatory = false;
        menuProps.mandatory_cols.forEach(mCol => {
          isMandatory = isMandatory || dCol.key === mCol.key;
        });
        data.push({ ...dCol, is_mandatory: isMandatory });
      });
      setTableData({ ...pageData, columns: data });
    }
    setCreatedRows([]);
  };

  const openPackshotUploadModal = () => {
    handleEditMenuClose();
    setPackshotModalState(true);
    setEditState({ uploadPackshots: true });
  };

  const onPackshotUploadSubmit = data => {
    if (data.length > 0) {
      handlePackshotUpload({
        params: { upload_file: data[0].file, subpanel: menuProps.name },
        other: { type: 'packshot' }
      });
    }
  };

  const openExcelUploadModal = () => {
    handleEditMenuClose();
    setExcelModalState(true);
    setEditState({ uploadNew: true });
  };

  const onExcelUploadSubmit = (data, replace) => {
    if (data.length > 0) {
      handleExcelUpload({
        params: {
          upload_file: data[0].file ? data[0].file : data[0],
          subpanel: menuProps.name,
          replace
        },
        other: { type: 'excel' }
      });
    }
  };

  const handleAddRow = () => {
    // handleEditMenuClose();
    setAddRowModalState(true);
  };

  const handleRowEdit = row => {
    const isAddedRow = String(row.id).includes(idPrefix); // check if id starts with prefix
    if (!isAddedRow) {
      setTransactionData(previous => ({
        ...previous,
        update: {
          ...previous.update,
          [row.id]: previous.update[row.id]
            ? { ...previous.update[row.id], ...row }
            : { ...row }
        }
      }));
    } else {
      setTransactionData(previous => ({
        ...previous,
        add: {
          ...previous.add,
          [row.id]: previous.add[row.id]
            ? { ...previous.add[row.id], ...row }
            : { ...row }
        }
      }));
    }
  };

  const handleRowDelete = row => {
    const isAddedRow = String(row.id).includes(idPrefix); // check if id starts with prefix
    if (!isAddedRow) {
      setTransactionData(previous => {
        const draft = {
          ...previous,
          delete: { ...previous.delete, [row.id]: { ...row } },
          add: {
            ...previous.add,
            [row.id]: previous.add[row.id]
              ? { ...previous.add[row.id], ...row }
              : { ...row }
          }
        };
        delete draft.add[row.id];
        delete draft.update[row.id];
        return draft;
      });
    } else {
      setTransactionData(previous => {
        const draft = {
          ...previous
        };
        delete draft.add[row.id];
        return draft;
      });
    }
  };

  const handleAddRowSubmit = data => {
    const row = {
      ...data,
      id: `${idPrefix}${new Date().getTime()}` // to distinguish the temp created id
    };
    setTransactionData(previous => ({
      ...previous,
      add: {
        ...previous.add,
        [row.id]: { ...row }
      }
    }));
    setCreatedRows([row]);
  };

  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleCancelButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    setDisableInteraction(false);
    setShowConfirmationPopup(false);
  };

  const handleOKButton = (_e, reason) => {
    const payload = {};
    map(transactionData, (fields, crudType) => {
      payload[crudType.toUpperCase()] = map(fields, meta => {
        return {
          meta: { ...meta, id: crudType === 'add' ? '' : meta.id },
          id: crudType === 'add' ? '' : meta.id
        };
      });
    });
    saveTransaction(payload);
    handleSnackBarClose(_e, reason);
    setDisableInteraction(false);
    setShowConfirmationPopup(false);
    // while adding row or deleting in table, after submitting search should be cleared
    setRefreshButtonStatus(true);
    setSearchValue({ col: '', value: '' });
  };

  useEffect(() => {
    if (
      apiRequestStatus.uploadPackshot.request &&
      !apiRequestStatus.pollTask.start &&
      !apiRequestStatus.pollTask.stop
    ) {
      if (!packshotUpload.task_id) {
        if (apiRequestStatus.uploadPackshot.error) {
          return setSnackBarState({
            isOpen: true,
            message: apiRequestStatus.uploadPackshot.message,
            severity: 'error',
            duration: 4000
          });
        }
        if (apiRequestStatus.uploadPackshot.progress >= 0) {
          return setSnackBarState({
            isOpen: true,
            message: `Packshot upload in progress. ${apiRequestStatus.uploadPackshot.progress}% / 100% uploaded`,
            severity: 'info'
          });
        }
      }
      if (packshotUpload.task_id) {
        if (apiRequestStatus.uploadPackshot.success) {
          return setSnackBarState({
            isOpen: true,
            message: `Packshot uploaded successfully! Please wait until it is processed`,
            severity: 'success',
            duration: 4000
          });
        }
      }
    }
    return () => {};
  }, [
    packshotUpload,
    apiRequestStatus.uploadPackshot,
    apiRequestStatus.pollTask
  ]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'packshotUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please upload the file again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully!`,
            severity: 'success',
            duration: 3000
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    if (
      apiRequestStatus.uploadExcel.request &&
      !apiRequestStatus.pollTask.start &&
      !apiRequestStatus.pollTask.stop
    ) {
      if (!excelUploadData.task_id) {
        if (apiRequestStatus.uploadExcel.error) {
          return setSnackBarState({
            isOpen: true,
            message: apiRequestStatus.uploadExcel.message,
            severity: 'error',
            duration: 4000
          });
        }
        if (apiRequestStatus.uploadExcel.progress >= 0) {
          return setSnackBarState({
            isOpen: true,
            message: `Excel upload in progress. ${apiRequestStatus.uploadExcel.progress}% / 100% uploaded`,
            severity: 'info'
          });
        }
      }
      if (excelUploadData.task_id) {
        if (apiRequestStatus.uploadExcel.success) {
          return setSnackBarState({
            isOpen: true,
            message: `Excel file uploaded successfully! Please wait until it is processed`,
            severity: 'success',
            duration: 4000
          });
        }
      }
    }
    return () => {};
  }, [
    excelUploadData,
    apiRequestStatus.uploadExcel,
    apiRequestStatus.pollTask
  ]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'excelUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please upload the file again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully!`,
            severity: 'success',
            duration: 3000
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'transaction'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please try again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully!`,
            severity: 'success',
            duration: 3000
          });
          setTransactionData({
            add: {},
            delete: {},
            update: {}
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    setDisableInteraction(isPackshotModalOpen || isAddRowModalOpen);
  }, [isPackshotModalOpen, isAddRowModalOpen]);

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          action={
            showConfirmationPopup ? (
              <>
                <MuiButton
                  color="inherit"
                  size="small"
                  onClick={handleOKButton}
                >
                  OK
                </MuiButton>
                <MuiButton
                  color="inherit"
                  size="small"
                  onClick={handleCancelButton}
                >
                  Cancel
                </MuiButton>
              </>
            ) : null
          }
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <PackshotUpload
        isOpen={isPackshotModalOpen}
        setOpen={setPackshotModalState}
        handleSubmit={onPackshotUploadSubmit}
      />
      <ExcelUpload
        isOpen={isExcelModalOpen}
        setOpen={setExcelModalState}
        handleSubmit={onExcelUploadSubmit}
      />
      <div className={classes.header}>
        <div className={classes.searchContainer}>
          <SearchComponent
            data={tableData ? tableData.columns : []}
            useDebounce={useDebounce}
            getPageData={getPageData}
            getSearchData={getSearchData}
            setSearchValue={setSearchValue}
            refreshButtonStatus={refreshButtonStatus}
            setRefreshButtonStatus={setRefreshButtonStatus}
            activeSideMenuItem={menuProps ? menuProps.name : ''}
          />
        </div>
        <div className={classes.buttonGroup}>
          <Tooltip title="Reset Column Width">
            <div className={classes.inlineBlock}>
              <Button
                className={classes.resizeButton}
                disabled={disableInteraction}
                color="dark"
                round
                justIcon
                onClick={() => setResizeState(true)}
              >
                <ResizeIcon />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Reset Data">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                color="dark"
                round
                justIcon
                onClick={() => {
                  getPageData({});
                  setRefreshButtonStatus(true);
                  setSearchValue({ col: '', value: '' });
                }}
              >
                <RefreshIcon />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Download">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                aria-haspopup="true"
                aria-controls="download-menu"
                color="dark"
                round
                justIcon
                onClick={handleDownloadIconClick}
              >
                <DownloadAltIcon />
              </Button>
            </div>
          </Tooltip>
          <Menu
            id="download-menu"
            anchorEl={downloadAnchorEl}
            open={Boolean(downloadAnchorEl)}
            onClose={handleDownloadMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem onClick={handleDownloadAllTemplates}>
              Download All templates
            </MenuItem>
            <MenuItem onClick={handleDownloadTemplate}>
              Download template
            </MenuItem>
            <MenuItem onClick={handleDownloadExcel}>Download excel</MenuItem>
            <MenuItem onClick={handleDownloadPackshots}>
              Download packshots
            </MenuItem>
          </Menu>
          <Tooltip title="Edit">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                aria-haspopup="true"
                aria-controls="edit-menu"
                color="dark"
                round
                justIcon
                onClick={handleEditIconClick}
              >
                <EditIcon />
              </Button>
            </div>
          </Tooltip>
          <Menu
            id="edit-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleEditMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            {hasPermission(menuProps.permissions, PermissionsEnum.create) ||
            hasPermission(menuProps.permissions, PermissionsEnum.all) ? (
              <MenuItem onClick={openExcelUploadModal}>
                Upload Excel file
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={handleEditData}
              disabled={
                pageData
                  ? pageData.columns
                    ? pageData.columns < 1
                    : true
                  : true
              }
            >
              Edit existing data
            </MenuItem>
            {hasPermission(menuProps.permissions, PermissionsEnum.create) ||
            hasPermission(menuProps.permissions, PermissionsEnum.all) ? (
              <MenuItem onClick={openPackshotUploadModal}>
                Upload packshots
              </MenuItem>
            ) : null}
          </Menu>
        </div>
      </div>
      <TableComponent
        resizeState={resizeState}
        setResizeState={setResizeState}
        disableInteraction={disableInteraction}
        permissions={menuProps.permissions}
        createdRows={createdRows}
        handleRowEdit={handleRowEdit}
        handleToggleValueChange={handleToggleValueChange}
        handleRowDelete={handleRowDelete}
        enableEdit={editState.editExisting && hasUpdatePermission}
        styleProps={{ height: window.innerHeight - 260 }}
        data={tableData}
        canEdit={hasUpdatePermission}
        overlay={{ message: loaderStatus ? 'loading data...' : '' }}
        loaderStatus={loaderStatus}
        limit={menuProps.row_limit}
        dashboardType={dashboardType}
      />
      <div className={classes.paginationContainer}>
        <PaginationComponent
          classNames={classes.pagination}
          offset={0}
          limit={menuProps.row_limit}
          total={pageTotal}
          onPageChange={params => onPageChangeHandler(params)}
        />
      </div>
      <Modal
        open={isAddRowModalOpen}
        onClose={() => setAddRowModalState(false)}
        aria-labelledby="add-row-modal"
        aria-describedby="modal to add row"
      >
        <div className={classes.modalContent}>
          <div className={classes.closeIconContainer}>
            <IconButton onClick={() => setAddRowModalState(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <AddRow
            handleFormSubmit={handleAddRowSubmit}
            data={tableData ? tableData.columns : []}
            setModelVisibility={visibility => setAddRowModalState(visibility)}
          />
        </div>
      </Modal>
      <div className={classes.footer}>
        {(Object.keys(transactionData.add).length > 0 ||
          Object.keys(transactionData.update).length > 0 ||
          Object.keys(transactionData.delete).length > 0) && (
          <Button
            disabled={disableInteraction}
            color="success"
            onClick={handleSubmitClick}
          >
            Submit
          </Button>
        )}
        {editState.editExisting && (
          <>
            <Button
              disabled={disableInteraction}
              className={classes.grayButton}
              onClick={discardEdits}
            >
              Close Editor
            </Button>
            {hasPermission(menuProps.permissions, PermissionsEnum.create) ||
            hasPermission(menuProps.permissions, PermissionsEnum.all) ? (
              <Button
                disabled={disableInteraction}
                className={classes.grayButton}
                onClick={handleAddRow}
              >
                Add New Row
              </Button>
            ) : null}
            {/* <Button
              disabled={disableInteraction}
              className={classes.grayButton}
              onClick={handleAddRow}
            >
              Add New Row
            </Button> */}
          </>
        )}
      </div>
    </>
  );
};

SKUPage.propTypes = {
  classes: object.isRequired,
  pageTotal: number,
  packshotUpload: object,
  excelUploadData: object,
  apiRequestStatus: object,
  pollTask: object,
  pageData: object,
  menuProps: object,
  getPageData: func.isRequired,
  getSearchData: func.isRequired,
  saveTransaction: func.isRequired,
  handlePackshotUpload: func.isRequired,
  handleExcelUpload: func.isRequired,
  downloadAllTemplates: func.isRequired,
  downloadTemplate: func.isRequired,
  downloadExcel: func.isRequired,
  downloadPackshots: func.isRequired,
  loaderStatus: bool.isRequired,
  dashboardType: string.isRequired
};

SKUPage.defaultProps = {
  pageData: undefined,
  pageTotal: 0,
  pollTask: {},
  packshotUpload: {},
  excelUploadData: {},
  apiRequestStatus: {},
  menuProps: {}
};

export default withStyles(styles)(SKUPage);

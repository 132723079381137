import React from 'react';
import PropTypes from 'prop-types';

import Carousel, { Modal, ModalGateway } from 'react-images';

import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';

import AppImagesPage from 'pages/AppImages/AppImagesPage';
import Typography from 'components/Typography/Typography';
/* Redux */
import { appImageActions } from 'redux-utils/actions/index';
import { connect } from 'react-redux';
import { appSelector, appImageSelector } from 'redux-utils/selectors';
/* Utils */
import ls from 'lib/core/storageFactory';
import getDataLayer from 'utils/gtagManager';
import { isEqual } from 'lodash';

const client = ls.get('client');

const initialState = {
  downloadBulkOptions: {
    fileType: 'valid',
    includeDuplicates: false,
    stores: [],
    dateRange: {},
    selectAllStores: false,
    bulkStores: []
  },
  notificationSnack: {
    message: '',
    isOpen: false
  },
  date: {},
  store: {},
  session: {},
  modalOpen: false,
  imagesModal: {
    isOpen: false,
    currentIndex: 0
  },
  errors: {
    chooseDateRange: false
  },
  downloadMeta: {},
  allDownloadOptionsSelected: false,
  downloadBulkCheckBoxOptions: []
};

const customStyles = {
  view: base => ({
    ...base,
    maxHeight: '90vh',
    overflow: 'auto',
    '& img': {
      maxHeight: '90vh'
    }
  })
};

class AppImagesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.success.downloadPhotos.message,
        this.props.success.downloadPhotos.message
      )
    ) {
      const successStatus = client.includes('kcc-ru')
        ? 'Действие успешно завершено'
        : 'Success';
      const errorStatus = client.includes('kcc-ru') ? 'Ошибка' : 'Error';
      if (this.props.success.downloadPhotos.message === successStatus) {
        if (!this.state.notificationSnack.isOpen) {
          this.handleNotification(this.props.success.downloadPhotos.toast);
        }
      } else if (this.props.errors.downloadPhotos.message === errorStatus) {
        if (!this.state.notificationSnack.isOpen) {
          this.handleNotification(this.props.errors.downloadPhotos.toast);
        }
      }
    }

    if (
      prevProps.appImageDownloadOptionsState.success !==
      this.props.appImageDownloadOptionsState.success
    ) {
      this.setAppImagesOptions();
    }
  }

  setAppImagesOptions = () => {
    let optionTypes = [];
    let defaultSelected = {};
    this.props.appImageOptions.map(el => {
      if (!optionTypes.includes(el.type)) {
        optionTypes = [...optionTypes, el.type];
        defaultSelected[el.type] = [
          this.props.appImageOptions.filter(lable => lable.is_default)[0].title
        ];
      }
    });
    this.setState({
      downloadBulkCheckBoxOptions: optionTypes,
      downloadMeta: defaultSelected,
      allDownloadOptionsSelected: false
    });
  };
  handleNotification = message => {
    const { initializeSuccessApis } = this.props;
    this.setState(
      state => {
        return { ...state, notificationSnack: { isOpen: true, message } };
      },
      () => {
        setTimeout(() => {
          this.setState(
            state => {
              return {
                ...state,
                notificationSnack: { message: '', isOpen: false }
              };
            },
            () => {
              initializeSuccessApis();
            }
          );
        }, 1500);
      }
    );
  };

  handleDateRangeChange = dateRange => {
    const { getStoresDateRange } = this.props;
    const dataLayer = getDataLayer();
    getStoresDateRange(dateRange);
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        dateRange
      });
    }
    this.setState(state => {
      return {
        ...state,
        downloadBulkOptions: {
          ...state.downloadBulkOptions,
          dateRange,
          stores: []
        }
      };
    });
  };

  handleDateChange = date => {
    const { getStores } = this.props;
    const dataLayer = getDataLayer();
    this.setState({ date, store: {}, session: {} });
    getStores(date);
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        date
      });
    }
  };

  handleStoreChange = store => {
    const { date } = this.state;
    const dataLayer = getDataLayer();
    const { getStoreSessions } = this.props;
    this.setState({ store, session: {} });
    getStoreSessions({ store, date });
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        store,
        date
      });
    }
  };

  handleSessionChange = session => {
    const { date, store } = this.state;
    const { getPhotos } = this.props;
    const dataLayer = getDataLayer();
    this.setState({ session });
    getPhotos({ session, date, store });
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        session,
        date,
        store
      });
    }
  };

  handleImageDownload = (action, optionTypes, appImageOptions) => {
    const { downloadPhotos, stores, sidebarFilters } = this.props;
    const {
      fl_category,
      fl_placement,
      fl_dropdown_date,
      fl_store
    } = sidebarFilters;

    if (action !== 'bulk') {
      let payLoad = {};
      if (this.props.dashboardType !== 'v2') {
        var { date, store, session } = this.state;
        payLoad = {
          stores: [],
          date: {
            from: fl_dropdown_date,
            to: fl_dropdown_date
          },
          store: fl_store,
          session: `${fl_category}_${fl_placement}`,
          downloadSingle: true
        };
      } else {
        var { date, store, session } = this.props.appImagesFilters;
        payLoad = {
          stores: [],
          date: {
            from: fl_dropdown_date,
            to: fl_dropdown_date
          },
          store: fl_store,
          session: `${fl_category}_${fl_placement}`,
          downloadSingle: true
        };
      }

      if (
        fl_dropdown_date !== '' &&
        fl_store !== '' &&
        fl_category !== '' &&
        fl_placement !== ''
      ) {
        const { email } = ls.get('authProfile');
        downloadPhotos(payLoad);
      } else {
        this.handleNotification(
          client.includes('kcc-ru')
            ? 'Пожалуйста, выберите фильтры'
            : 'Please select the Filters'
        );
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      const {
        store,
        session,
        dateRange,
        downloadBulkOptions,
        downloadMeta
      } = this.state;
      if (
        dateRange !== '' &&
        store !== '' &&
        session !== '' &&
        downloadBulkOptions.stores.length !== 0
      ) {
        const { email } = ls.get('authProfile');

        let newOptionObj = {};
        optionTypes.forEach(type => {
          if (downloadMeta[type].length === 0) {
            newOptionObj = {
              ...downloadMeta,
              [type]: [
                appImageOptions.filter(
                  imageType => imageType.type === type && imageType.is_default
                )[0].title
              ]
            };
          } else {
            newOptionObj = downloadMeta;
          }
        });
        downloadPhotos({
          stores: downloadBulkOptions.stores,
          date: {
            from: downloadBulkOptions.dateRange.fl_start_date,
            to: downloadBulkOptions.dateRange.fl_end_date
          },
          session: session.value,
          downloadMultiple: true,
          downloadMeta: newOptionObj
        });
        this.setState({
          modalOpen: false,
          downloadBulkOptions: {
            fileType: 'valid',
            includeDuplicates: false,
            stores: [],
            dateRange: {},
            selectAllStores: false,
            downloadMeta: {}
          }
        });
      } else {
        if (downloadBulkOptions.stores.length === 0) {
          this.handleNotification(
            client.includes('kcc-ru')
              ? 'Пожалуйста, выберите как минимум один магазин'
              : 'Please select at least one store'
          );
          return;
        }

        this.handleNotification(
          client.includes('kcc-ru')
            ? 'Пожалуйста, выберите фильтры'
            : 'Please select the Filters'
        );

        this.setState({
          modalOpen: false,
          downloadBulkOptions: {
            fileType: 'valid',
            includeDuplicates: false,
            stores: [],
            dateRange: {},
            selectAllStores: false
          }
        });
      }
    }
  };
  // selected option type
  handleOptionSelected = (meta, lable, allOptions) => {
    const { downloadMeta } = this.state;
    if (downloadMeta[meta]) {
      if (
        downloadMeta[meta].includes(lable) &&
        allOptions.filter(el => el.type === meta).length ===
          downloadMeta[meta].length
      ) {
        this.setState({ allDownloadOptionsSelected: false });
      }

      this.setState({
        downloadMeta: {
          ...downloadMeta,
          [meta]: downloadMeta[meta].includes(lable)
            ? downloadMeta[meta].filter(el => el !== lable)
            : [...downloadMeta[meta], lable]
        }
      });
    } else {
      this.setState({
        downloadMeta: { ...downloadMeta, [meta]: [lable] }
      });
    }
  };
  setDefaultDownloadMeta = value => {
    this.setState({ downloadMeta: value });
  };
  // select all image types
  handleAllClicked = (metasArray, options, third) => {
    const { downloadMeta } = this.state;
    let obj = {};
    if (!third) {
      return this.setState({ allDownloadOptionsSelected: false });
    }
    if (this.state.allDownloadOptionsSelected) {
      metasArray.map(meta => {
        obj = {
          [meta]: [
            options.filter(el => el.type === meta && el.is_default)[0].title
          ]
        };
      });
      this.setState({
        downloadMeta: obj,
        allDownloadOptionsSelected: false
      });
    } else {
      metasArray.map(meta => {
        obj = {
          ...downloadMeta,
          [meta]: options.filter(el => el.type === meta).map(el => el.title)
        };
      });
      this.setState({
        downloadMeta: obj,
        allDownloadOptionsSelected: true
      });
    }
  };
  // eslint-disable-next-line consistent-return
  handleBulkStoresChange = e => {
    const newStore = e.target.value[1];
    const { downloadBulkOptions } = this.state;
    if (downloadBulkOptions.stores.includes(newStore)) {
      return this.setState(state => {
        return {
          ...state,
          downloadBulkOptions: {
            ...state.downloadBulkOptions,
            selectAllStores: false,
            stores: state.downloadBulkOptions.stores.filter(
              item => item !== newStore
            )
          }
        };
      });
    }
    this.setState(state => {
      return {
        ...state,
        downloadBulkOptions: {
          ...state.downloadBulkOptions,
          selectAllStores: false,
          stores: [...state.downloadBulkOptions.stores, newStore]
        }
      };
    });
  };

  handleModalAction = value => {
    const { clearBulkStores } = this.props;
    clearBulkStores();
    this.setState({
      modalOpen: value,
      downloadBulkOptions: initialState.downloadBulkOptions
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'fileType':
        this.setState(state => {
          return {
            ...state,
            downloadBulkOptions: {
              ...state.downloadBulkOptions,
              [name]: value
            }
          };
        });

        return;
      case 'includeDuplicates':
        this.setState(state => {
          return {
            ...state,
            downloadBulkOptions: {
              ...state.downloadBulkOptions,
              [name]: !state.downloadBulkOptions.includeDuplicates
            }
          };
        });
        break;
      default:
    }
  };

  selectAllStores = data => {
    const { bulkStores } = this.props;
    this.setState(state => {
      return {
        ...state,
        downloadBulkOptions: {
          ...state.downloadBulkOptions,
          selectAllStores: data,
          stores: data ? bulkStores.map(item => item.value) : []
        }
      };
    });
  };

  toggleModal = modalState => {
    this.setState(state => {
      return {
        ...state,
        imagesModal: { ...state.imagesModal, isOpen: modalState }
      };
    });
  };

  handleImageModalAction = data => {
    this.setState(state => {
      return { ...state, imagesModal: { isOpen: true, currentIndex: data } };
    });
  };

  componentDidMount() {
    this.props.dispatchGetAppImageDownloadImages();
    if (this.props.appImageOptions.length > 0) {
      this.setAppImagesOptions();
    }
  }

  render() {
    const {
      date,
      store,
      session,
      modalOpen,
      downloadBulkOptions,
      notificationSnack,
      imagesModal
    } = this.state;
    const {
      stores,
      sessions,
      photos,
      message,
      bulkStores,
      messages,
      isLoading,
      dashboardType,
      layoutCSS,
      photoAttributes
    } = this.props;

    const modalPhotos = photos.map(item => {
      return { src: item.url };
    });

    const CustomFooter = ({ innerProps, isModal, currentIndex }) =>
      isModal &&
      photos[currentIndex].flags &&
      photos[currentIndex].flags.length > 0 && (
        <div
          {...innerProps}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px'
          }}
        >
          <div
            style={{
              marginRight: '10px',
              padding: '12px 30px',
              borderRadius: '50px',
              backgroundColor: 'white',
              display: 'flex',
              border: `2px solid ${photos[currentIndex].border_color}`
            }}
          >
            {photos[currentIndex].flags.includes('Good Quality') ||
            photos[currentIndex].flags.includes('Valid') ? (
              <DoneIcon style={{ fill: '#5cb85c', marginRight: '4px' }} />
            ) : (
              <WarningIcon style={{ fill: '#ff0000', marginRight: '4px' }} />
            )}
            {photos[currentIndex].flags.map((flag, index) => (
              <>
                <div
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  <Typography color="black" weight={400} size={18}>
                    {flag}
                  </Typography>
                </div>
                {index !== photos[currentIndex].flags.length - 1 && (
                  <div
                    style={{
                      marginRight: '10px',
                      marginLeft: '10px',
                      border: '1px solid black'
                    }}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      );

    return (
      <div>
        <ModalGateway>
          {modalPhotos.length > 0 && imagesModal.isOpen ? (
            <Modal onClose={() => this.toggleModal(false)}>
              <Carousel
                styles={customStyles}
                views={modalPhotos}
                showImageCount
                isOpen={imagesModal.isOpen}
                currentIndex={imagesModal.currentIndex}
                backdropClosesModal
                components={{ Footer: CustomFooter }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <AppImagesPage
          value={date}
          message={message}
          bulkStores={bulkStores}
          selectedBulkStores={downloadBulkOptions.stores}
          dateRange={downloadBulkOptions.dateRange}
          notificationSnack={notificationSnack}
          store={store}
          isLoading={isLoading}
          photos={photos}
          photoAttributes={photoAttributes}
          session={session}
          sessions={sessions}
          modalOpen={modalOpen}
          downloadBulkOptions={downloadBulkOptions}
          handleImageModalAction={this.handleImageModalAction}
          selectAllStores={this.selectAllStores}
          isSelectAllStore={downloadBulkOptions.selectAllStores}
          handleBulkStoresChange={this.handleBulkStoresChange}
          handleModalAction={this.handleModalAction}
          handleChange={this.handleChange}
          handleBulkImageDownload={this.handleBulkImageDownload}
          handleImageDownload={this.handleImageDownload}
          handleOptionSelected={this.handleOptionSelected}
          handleSessionChange={this.handleSessionChange}
          handleDateRangeChange={this.handleDateRangeChange}
          handleDateChange={this.handleDateChange}
          handleStoreChange={this.handleStoreChange}
          stores={stores}
          bulkStoreMessage={messages.bulkStores}
          dashboardType={dashboardType}
          primaryButtonStyles={layoutCSS?.theme?.primaryButton}
          dashboardPanelStyles={layoutCSS?.theme?.dashboardPanel}
          dropdownTitleStyles={layoutCSS?.theme?.dropdownTitle}
          dropdownStyles={layoutCSS?.theme?.dropdown}
          handleAllClicked={this.handleAllClicked}
          selectedDownloadMeta={this.state.downloadMeta}
          setDefaultDownloadMeta={this.setDefaultDownloadMeta}
          allDownloadOptionsSelected={this.state.allDownloadOptionsSelected}
          downloadBulkCheckBoxOptions={this.state.downloadBulkCheckBoxOptions}
          setNotificationSnack={notificationSnack =>
            this.setState({ notificationSnack })
          }
          unClickAll={() =>
            this.setState({ allDownloadOptionsSelected: false })
          }
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  appImageDownloadOptionsState: appImageSelector.appImageDownloadOptionsState(
    state
  ),
  message: appImageSelector.getMessage(state),
  stores: appImageSelector.getStores(state),
  sessions: appImageSelector.getSessions(state),
  photos: appImageSelector.getPhotos(state),
  photoAttributes: appImageSelector.getPhotoAttributes(state),
  email: appSelector.getAuthState(state),
  bulkStores: appImageSelector.getBulkStores(state),
  messages: appImageSelector.getMessages(state),
  success: appImageSelector.getSuccess(state),
  errors: appImageSelector.getErrors(state),
  isLoading: appImageSelector.getLoading(state),
  layoutCSS: appSelector.getLayoutCSS(state),
  appImagesFilters: appImageSelector.getAppImagesFilters(state),
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version,
  sidebarFilters: appSelector.getSidebaFilters(state)
});

const mapDispatchToProps = dispatch => ({
  getStores: payload => dispatch(appImageActions.getStores(payload)),
  getStoreSessions: payload =>
    dispatch(appImageActions.getStoreSessions(payload)),
  getPhotos: payload => dispatch(appImageActions.getPhotos(payload)),
  downloadPhotos: payload => dispatch(appImageActions.downloadPhotos(payload)),
  getStoresDateRange: payload =>
    dispatch(appImageActions.getStoresRange(payload)),
  clearBulkStores: payload => {
    dispatch(appImageActions.clearBulkStores(payload));
  },
  initializeSuccessApis: payload => {
    dispatch(appImageActions.initializeSuccessApis(payload));
  },
  initializeFailureApis: payload => {
    dispatch(appImageActions.initializeFailureApis(payload));
  },
  dispatchGetAppImageDownloadImages: payload =>
    dispatch(appImageActions.getImageActionTypes(payload))
});

AppImagesContainer.propTypes = {
  getStores: PropTypes.func.isRequired,
  getStoreSessions: PropTypes.func.isRequired,
  downloadPhotos: PropTypes.func.isRequired,
  getPhotos: PropTypes.func.isRequired,
  photos: PropTypes.array,
  stores: PropTypes.array,
  sessions: PropTypes.array,
  message: PropTypes.string,
  bulkStores: PropTypes.array,
  getStoresDateRange: PropTypes.func.isRequired,
  clearBulkStores: PropTypes.func.isRequired,
  messages: PropTypes.object,
  success: PropTypes.object,
  errors: PropTypes.object,
  initializeSuccessApis: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  dashboardType: PropTypes.string.isRequired,
  layoutCSS: PropTypes.object,
  photoAttributes: PropTypes.object.isRequired
};
AppImagesContainer.defaultProps = {
  stores: [],
  sessions: [],
  photos: [],
  message: null,
  bulkStores: [],
  messages: {},
  success: {},
  errors: {},
  isLoading: false,
  layoutCSS: {}
};

export default connect(mapStateToProp, mapDispatchToProps)(AppImagesContainer);

const types = {
  analyticsRedirect: 'ANALYTICS_REDIRECT',
  login: {
    request: 'USER_LOGIN_REQUEST',
    success: 'USER_LOGIN_SUCCESS',
    failure: 'USER_LOGIN_FAILURE'
  },
  logout: {
    request: 'USER_LOGOUT_REQUEST',
    success: 'USER_LOGOUT_SUCCESS',
    failure: 'USER_LOGOUT_FAILURE'
  },
  clientValidation: {
    request: 'CLIENT_VALIDATION_REQUEST',
    success: 'CLIENT_VALIDATION_SUCCESS',
    failure: 'CLIENT_VALIDATION_FAILURE'
  },
  resetPassword: {
    request: 'RESET_PASSWORD_REQUEST',
    success: 'RESET_PASSWORD_SUCCESS',
    failure: 'RESET_PASSWORD_FAILURE'
  },
  confirmForgotPassword: {
    request: 'CONFIRM_FORGOT_PASSWORD_REQUEST',
    success: 'CONFIRM_FORGOT_PASSWORD_SUCCESS',
    failure: 'CONFIRM_FORGOT_PASSWORD_FAILURE'
  },
  forgotPassword: {
    request: 'FORGOT_PASSWORD_REQUEST',
    success: 'FORGOT_PASSWORD_SUCCESS',
    failure: 'FORGOT_PASSWORD_FAILURE'
  },
  inValidToken: 'INVALID_USER_TOKEN_ERROR'
};

export default types;

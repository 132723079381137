const style = theme => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  radio: {
    color: theme.palette.type === 'light' && '#0f0d36 !important'
  },
  checkbox: {
    color: theme.palette.type === 'light' && '#0f0d36 !important',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit'
  },
  timeFilterDropDown: {
    marginLeft: '15px!important'
  },
  dropDown: {
    marginLeft: '5px!important',
    marginBottom: theme.palette.type === 'light' && '20px'
  },
  footerText: {
    color: theme.palette.type === 'light' && '#767676'
  },
  newAppImagesBanner: {
    display: 'flex',
    width: '100%',
    margin: '0 !important',
    padding: ' 0 10px 0 10px',
    alignItems: 'center'
  },
  centeredCss: { display: 'flex', justifyContent: 'center' },
  downloadBulkImagesButton: {
    display: 'flex',
    justifyContent: theme.palette.type === 'light' ? 'flex-start' : 'center'
    // width: '190px'
  },
  downloadButton: {
    marginTop:
      theme.palette.type === 'light' ? '0px!important' : '30px!important',
    marginLeft: '20px!important',
    background: theme.palette.type === 'light' ? '#18c7fe' : '#27293d',
    color: theme.palette.type === 'light' && 'white',
    height: '36px',
    boxShadow: 'none !important',
    padding: '8px !important',
    border: theme.palette.type === 'light' ? 'none' : '1px solid #2b3553',
    borderRadius: '4px',
    textTransform: 'capitalize !important',
    marginBottom: '0px',
    marginTop: '10px',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white'
    }
  },

  viewInSeamButton: {
    marginTop:
      theme.palette.type === 'light' ? '0px!important' : '30px!important',
    marginLeft: '20px!important',
    background: 'rgb(253, 245, 227)',
    color: 'rgb(30, 41, 59)',
    height: '36px',
    boxShadow: 'none !important',
    padding: '8px !important',
    border: '1px solid rgb(253, 189, 50)',
    borderRadius: '4px',
    textTransform: 'capitalize !important',
    marginBottom: '0px',
    marginTop: '10px',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit',
    '&:hover': {
      background: 'rgb(253, 245, 227)',
      color: 'rgb(30, 41, 59)',
    },
    '&:focus': {
      background: 'rgb(253, 245, 227)',
      color: 'rgb(30, 41, 59)',
    }
  },
  downloadImagesButton: {
    marginTop:
      theme.palette.type === 'light' ? '0px!important' : '30px!important',
    marginLeft: '20px!important',
    background: theme.palette.type === 'light' ? '#f7f7f7' : '#27293d',
    color: theme.palette.type === 'light' && '#18c7fe',
    border: theme.palette.type === 'light' && '1px solid #18c7fe',
    boxShadow: 'none!important',
    padding: '8px!important',
    textTransform: 'capitalize!important',
    marginBottom: '0px',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#f7f7f7' : '#ffffff33',
      color: theme.palette.type === 'light' && '#18c7fe'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#f7f7f7' : '#ffffff33',
      color: theme.palette.type === 'light' && '#18c7fe'
    }
  },
  pillBox: {
    backgroundColor: theme.palette.type === 'light' ? '#f5f5f5' : '#2d2e3e',
    color: theme.palette.type === 'light' && '#424242',
    padding: '5px',
    borderRadius: '20px',
    marginLeft: '5px',
    marginTop: '5px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? '#18c7fe' : '#f50057',
      color: 'white',
      transition: '0.3s'
    }
  },
  pillBoxText: {
    display: 'inline-block',
    marginTop: '5px',
    marginBottom: '5px'
  },
  modalContainer: {
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center'
  },

  customModal: {
    position: 'relative',
    minWidth: '50%',
    width: 'fit-content',
    maxWidth: '50%',
    minHeight: '20%',
    height: 'fit-content',
    maxHeight: '600px',
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#1e1e2b',
    marginTop: '5%',
    color: '#94949a',
    borderRadius: '20px',
    outline: 'none',
    padding: '20px'
  },
  modalTitle: {
    color: theme.palette.type === 'light' && '#3f3f3f !important'
  },

  modalHeader: {
    display: 'flex',
    height: '42px',
    alignItems: 'center'
  },
  modalCloseButton: {
    background: '#27293d',
    boxShadow: 'none !important',
    padding: '8px !important',
    marginLeft: 'auto',
    '&:hover': {
      background: '#ffffff33'
    },
    '&:focus': {
      background: '#ffffff33'
    }
  },
  modalBody: {
    width: '100%'
  },
  selectedStoresText: {
    fontSize: '1.2rem',
    fontWeight: '700',
    marginBottom: '0px',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit'
  },
  selectedStores: {
    height: '100px',
    width: '100%',
    overflowY: 'scroll',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '15px'
  },
  photoCard: {
    overflowY: 'scroll',
    width: theme.palette.type !== 'light' && '200px',
    display: 'inline-block',
    justifyContent: 'center',
    height: theme.palette.type !== 'light' && '200px',
    marginRight: '10px',
    marginTop: '10px',
    overflow: 'hidden'
  },
  photoCardDescription: {
    backgroundColor: '#27293d',
    borderRadius: '10px',
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  appImagesFormControl: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    color: '#94949a'
  },
  spaceBetweenCss: {
    display: 'flex !important',
    justifyContent: 'space-between !important'
  },
  checkboxContainer: {
    marginTop: '-19px',
    justifyContent: 'space-between!important'
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    color: 'rgba(255, 255, 255, 0.6) !important',
    cursor: 'pointer',
    top: '20px'
  },
  contentText: {
    marginBottom: '0px',
    marginTop: '0px'
  },
  timeFilter: {
    marginLeft: '10px'
  },
  timeFilterWidth: {
    width: '250px',
    '& .css-ogbvz8-control': {
      width: '100%',
      '&:focus': {
        width: '100%!important'
      }
    }
  },
  downloadBox: {
    position: 'absolute',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    top: theme.palette.type !== 'light' && '10px',
    left: theme.palette.type !== 'light' && '10px',
    backgroundColor: '#35b935',
    borderRadius: '10px',
    bottom: theme.palette.type === 'light' && '10px',
    right: theme.palette.type === 'light' && '10px',
    '&:hover': {
      backgroundColor: '#6bb76b'
    }
  },
  absolute: {
    position: 'absolute'
  },
  card: {
    color: 'rgba(0, 0, 0, 0.87)',
    zIndex: 1,
    position: 'relative',
    background: '#363848',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    right: 18,
    top: 15
  },
  contentHolder: {
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    backgroundColor: '#353855',
    fontSize: '14px',
    padding: '8px',
    minHeight: '30px',
    width: '100%',
    bottom: '0px',
    left: '0px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center'
  },
  checkboxWidth: {
    width: '100px'
  },
  selectBar: {
    padding: '10px',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: theme.palette.type === 'light' ? '1px' : '2px',
    borderColor: theme.palette.type === 'light' ? '#c7c7c7' : '#252b43',
    '& .MuiInput-underline': {
      borderBottom: 'none!important'
    },
    fontSize: '14px',
    height: '38px',
    borderRadius: '4px'
  },
  arrowBox: {
    position: 'absolute',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    top: '10px',
    right: '10px',
    backgroundColor: '#D65353',
    borderRadius: '10px'
  },
  duplicateBox: {
    position: 'absolute',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    backgroundColor: '#D65353',
    borderRadius: '10px',
    marginBottom: '5px'
  },
  tile: {
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#27293d',
    borderRadius: '10px',
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  validTile: {
    backgroundColor:
      theme.palette.type === 'light' ? 'rgb(245, 245, 245)' : '#27293d',
    borderRadius: '10px',
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: theme.palette.type === 'light' ? '3px' : '1px',
    borderColor: '#53dc53'
  },
  invalidTile: {
    backgroundColor:
      theme.palette.type === 'light' ? 'rgb(245, 245, 245)' : '#27293d',
    borderRadius: '10px',
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: theme.palette.type === 'light' ? '3px' : '1px',
    borderColor: 'red'
  },
  photosInfo: {
    backgroundColor: '#353855',
    marginTop: '10px',
    borderRadius: theme.palette.type !== 'light' && '5px',
    marginLeft: theme.palette.type === 'light' && '1px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  photosContainer: {
    paddingLeft: theme.palette.type === 'light' && '20px'
  },
  marginLeft20: {
    marginLeft: '15px',
    display: theme.palette.type === 'light' && 'block',
    width: theme.palette.type === 'light' && '100px'
  },
  fontWeight600: {
    fontWeight: 600
  },
  appImageItem: {
    height: '197px',
    maxWidth: '180px'
  },
  appImagesFiltersContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.palette.type === 'light' && '20px'
  },
  inputLable: {
    minHeight: '20px',
    marginBottom: theme.palette.type === 'light' ? '10px' : '8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.palette.type === 'light' ? '16px !important' : 'inherit',
    fontWeight: theme.palette.type === 'light' ? 'bold !important' : 'inherit',
    fontFamily: theme.palette.type === 'light' ? 'loginPageFont' : 'inherit',
    color:
      theme.palette.type === 'light' ? '#3f3f3f' : 'rgba(255, 255, 255, 0.6)'
  },

  checkBoxRow: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap'
  }
});

export default style;

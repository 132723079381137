import colors from 'styles/__variables';

const palette = {
  dark: {
    axisStroke: 'rgb(113 113 121)',
    green: {
      primary: colors.dark.successColor,
      secondary: '#42b88342',
      opaque: '#38946600'
    },
    blue: {
      primary: colors.dark.infoColor,
      secondary: '#17cdf9',
      opaque: '#38946600'
    },
    pink: {
      primary: colors.dark.roseColor,
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    gray: {
      primary: colors.dark.grayColor
    },
    orange: {
      primary: colors.dark.dangerColor,
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    yellow: {
      primary: colors.dark.warningColor,
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    purple: {
      primary: '#E040FB',
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    red: {
      primary: '#E91E63',
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    lime: {
      primary: '#CDDC39',
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    },
    color1: {
      primary: '#e1eb34'
    },
    color2: {
      primary: '#959c16'
    },
    color3: {
      primary: '#b8bf37'
    },
    color4: {
      primary: '#494d08'
    },
    color5: {
      primary: '#b3e63e'
    },
    color6: {
      primary: '#bd7c62'
    },
    color7: {
      primary: '#a64e2b'
    },
    color8: {
      primary: '#66c4b3'
    },
    color9: {
      primary: '#5a6e6a'
    },
    color10: {
      primary: '#30c4cf'
    },
    color11: {
      primary: '#17878f'
    },
    color12: {
      primary: '#0b565c'
    },
    color13: {
      primary: '#6179c9'
    },
    color14: {
      primary: '#b9c5ed'
    },
    color15: {
      primary: '#bb38c9'
    },
    color16: {
      primary: '#89548f'
    },
    color17: {
      primary: '#48224d'
    },
    color18: {
      primary: '#b999bd'
    },
    color19: {
      primary: '#d472ab'
    },
    color20: {
      primary: '#e0b1bc'
    },
    color21: {
      primary: '#a17f87'
    },
    color22: {
      primary: '#8aa17f'
    },

    orangePink: {
      primary: '#ff7683',
      secondary: '#f2387b1a',
      opaque: '#f2387b00'
    }
  }
};

export default palette;

import { createAction } from '@reduxjs/toolkit';
import { appTypes, networkTypes } from 'redux-utils/types/index';
import reducerModel from 'models/reducerModel';
import apiModel from 'models/apiModel';

const pinToDashboardAction = (
  payload,
  meta = { reducerID: [reducerModel.chart.id] }
) => {
  const action = createAction(appTypes.pinToDashboard.request)({
    ...payload,
    cacheRequest: false,
    dbKey: {
      apiID: apiModel.pinToDashboard,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.pinToDashboard.success,
    requestAction: appTypes.pinToDashboard.fromAPI
  });
  action.meta = meta;
  return action;
};

const unPinFromDashboardAction = (
  payload,
  meta = { reducerID: [reducerModel.chart.id] }
) => {
  const action = createAction(appTypes.unPinFromDashboard.request)({
    ...payload,
    cacheRequest: false,
    dbKey: {
      apiID: apiModel.unPinFromDashboard,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.unPinFromDashboard.success,
    requestAction: appTypes.unPinFromDashboard.fromAPI
  });
  action.meta = meta;
  return action;
};

const layoutRequestAction = (
  payload,
  meta = {
    reducerID: [reducerModel.layout.id]
  }
) => {
  const action = createAction(appTypes.getLayout.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getLayoutList,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.getLayout.success,
    requestAction: appTypes.getLayout.fromAPI
  });
  action.meta = meta;
  return action;
};

const analyticsRedirectAction = (
  payload,
  meta = {
    reducerID: [reducerModel.app.id]
  }
) => {
  const action = createAction(appTypes.getAnalyticsRedirect.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getAnalyticsRedirect,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.getAnalyticsRedirect.success,
    requestAction: appTypes.getAnalyticsRedirect.fromAPI
  });
  action.meta = meta;
  return action;
};

const setAreFiltersSetToStore = payload => {
  const action = createAction(appTypes.filterOrder.set)(payload);
  return action;
};

const getClientPreferencesAction = (
  payload,
  meta = {
    reducerID: [reducerModel.app.id]
  }
) => {
  const action = createAction(appTypes.getClientPreferences.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getClientPreferences,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.getClientPreferences.success,
    requestAction: appTypes.getClientPreferences.fromAPI
  });
  action.meta = meta;
  return action;
};

const getFilterListAction = (
  payload,
  meta = { reducerID: [reducerModel.filter.id, reducerModel.slicer.id] }
) => {
  const action = createAction(appTypes.getFilterList.request)({
    ...payload,
    cacheRequest: false,
    dbKey: {
      apiID: apiModel.getFilterList,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: appTypes.getFilterList.success,
    requestAction: appTypes.getFilterList.fromAPI
  });
  action.meta = meta;
  return action;
};

const filterChangeAction = (
  payload,
  meta = {
    reducerID: [
      reducerModel.app.id,
      reducerModel.storeExplorer.id,
      reducerModel.storeExplorerNew.id
    ]
  }
) => {
  const action = createAction(appTypes.filterChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const setSidebarFilterAction = payload => {
  const action = createAction(appTypes.sidebarFilters.set)({
    ...payload
  });
  return action;
};

const clearSidebarFilterAction = payload => {
  const action = createAction(appTypes.sidebarFilters.clear)({
    ...payload
  });
  return action;
};

const clearFilterList = (
  payload,
  meta = {
    reducerID: [
      reducerModel.app.id,
      reducerModel.storeExplorer.id,
      reducerModel.storeExplorerNew.id
    ]
  }
) => {
  const action = createAction(appTypes.clearFilter)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const routeChangeAction = payload =>
  createAction(appTypes.routeChange)(payload);

const startWatchNetworkAction = createAction(
  networkTypes.START_WATCH_NETWORK_STATUS
);

const stopWatchNetworkAction = createAction(
  networkTypes.STOP_WATCH_NETWORK_STATUS
);


const fetchSurveyDetails = payload =>
  createAction(appTypes.surveyDetails.request)(payload);
  

export {
  analyticsRedirectAction,
  clearFilterList,
  filterChangeAction,
  getClientPreferencesAction,
  getFilterListAction,
  layoutRequestAction,
  pinToDashboardAction,
  routeChangeAction,
  startWatchNetworkAction,
  stopWatchNetworkAction,
  unPinFromDashboardAction,
  setSidebarFilterAction,
  clearSidebarFilterAction,
  setAreFiltersSetToStore,
  fetchSurveyDetails,
};

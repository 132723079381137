// global colors
import colors from 'styles/__variables';

/**
 * @name CustomReactSelectStyles
 * @method
 * @description A custom style object generator for the `react-select` dropdown
 * @param {bool} isButton - Whether to apply button styles
 * @param {number} width - Width of the dropdown in number
 * @return {object} - A custom style object
 */
const customStyles = (isButton, width, dashboardType) => {
  return {
    control: provided => ({
      ...provided,
      minWidth: 140,
      width,
      background: !isButton ? 'transparent' : colors.dark.dark15,
      borderColor: !isButton ? colors.dark.dark15 : 'transparent !important',
      boxShadow: !isButton ? 'inherit' : 'none !important'
    }),
    menuList: provided => ({
      ...provided,
      minWidth: 150,
      width,
      background: colors.dark.buttonDark,
      color: colors.dark.light25,
      scrollbarWidth: '5px !important'
    }),
    menu: provided => ({
      ...provided,
      minWidth: 150,
      width,
      zIndex: 11
    }),
    input: provided => ({
      ...provided,
      color: colors.dark.light25
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: colors.dark.light25
    }),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: !isButton ? 'transparent' : '#e6e6e836',
      margin: !isButton ? '8px' : '5px'
    }),
    groupHeading: provided => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold'
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight:
        dashboardType === 'v2'
          ? state.isSelected
            ? 'bold'
            : 'normal'
          : 'normal',
      backgroundColor:
        dashboardType === 'v2'
          ? state.isFocused
            ? '#ffffff'
            : '#f5f5f5'
          : state.isFocused
          ? 'rgb(85, 85, 107)'
          : colors.dark.buttonDark,

      color:
        dashboardType === 'v2'
          ? state.isFocused
            ? '#000'
            : state.isSelected
            ? '#000'
            : '#767676'
          : state.isFocused
          ? '#fff'
          : state.isSelected
          ? '#fff'
          : 'inherit'
    }),
    singleValue: provided => ({
      ...provided,
      color: colors.dark.light24
    }),
    multiValue: provided => ({
      ...provided,
      color: colors.dark.light24,
      backgroundColor: colors.dark.buttonDark
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: colors.dark.light24
    })
  };
};

const dropDownStyles = theme => ({
  label: {
    minHeight: '18px',
    // marginBottom: '10px',
    marginBottom: '8px',

    textTransform: 'capitalize'
  },
  dropdownMenu: {
    color: theme.palette.type === 'light' && '#767676 !important',
    // backgroundColor:
    //   theme.palette.type === 'light' ? '#f5f5f5 !important' : 'inherit',
    '& div[class*="singleValue"]': {
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& div[class*="control"]': {
      borderColor: theme.palette.type === 'light' && '#c7c7c7 !important',
      '&:hover': {
        borderColor: theme.palette.type === 'light' && '#767676 !important'
      },

      backgroundColor: theme.palette.type === 'light' && 'white !important',
      '& input': {
        color: theme.palette.type === 'light' && '#767676 !important'
      }
    },
    '& div[class*="indicatorContainer"]': {
      color: theme.palette.type === 'light' && 'hsl(0,0%,60%) !important'
    },
    '& div[class*="menu"] > div': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important'
    }
  },
  tooltip: {
    fontSize: 14
  }
});

export { dropDownStyles, customStyles };

import React, { Component } from 'react';
import * as pbi from 'powerbi-client';
import PropTypes from 'prop-types';
import axios from 'axios';

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

var COMPONENT = pbi.Embed;
var ROOTELEMENT = HTMLElement;

class PowerBiWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedRender: false,
      invalidConfig: false
    };
  }

  componentDidMount() {
    this.updateState(this.props);
    this.embedConfig();
  }

  componentDidUpdate() {
    if (this.state.id !== this.props.id) {
      this.updateState(this.props);
    }
    if (true) {
      this.fetchReportFromPowerBI();
    } else if (!this.state.failedRender) {
      this.setState({
        failedRender: true,
        invalidConfig: true
      });
    }
  }

  componentWillUnmount() {
    powerbi.reset(ROOTELEMENT);
    COMPONENT = null;
  }

  fetchReportFromPowerBI() {
    if (this.state.failedRender) {
      return;
    }
  }

  handleErrors() {
    COMPONENT.off('error');
    COMPONENT.on('error', () => {
      this.logRenderFailureInState();
      return COMPONENT;
    });
  }

  logRenderFailureInState() {
    this.setState({
      failedRender: true
    });
  }

  embedConfig() {
    const newEmbedUrl = this.props.embedURL.split('&autoAuth')[0];
    var config = {
      //   embedTokenType: 'reports',
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: this.props.accessToken,
      embedUrl: newEmbedUrl,
      //   id: this.state.id,
      permissions: pbi.models.Permissions.All,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
      }
    };
    if (true) {
      COMPONENT = powerbi.embed(ROOTELEMENT, config);
      const { iframe } = COMPONENT || {};
      if (iframe?.style) {
        const { width } = iframe?.parentElement?.getBoundingClientRect() || {};
        if (width) {
          iframe.style.height = `${width / 2.4}px`;
          iframe.style.marginLeft = '-2px';
          iframe.style.marginTop = '-2px';
        }
      }
      this.handleErrors();
    }
    if (this.state.retry == 1) {
      COMPONENT = powerbi.embed(ROOTELEMENT, config);
      this.handleErrors();
    }
    return COMPONENT;
  }

  updateState(props) {
    powerbi.reset(ROOTELEMENT);
    const nextState = Object.assign({}, this.state, props, {
      type: this.props.embedType ? this.props.embedType : 'report'
    });
    this.setState(nextState);
  }

  validateConfig() {
    var config = this.state;
    const errors = pbi.models.validateReportLoad(config);
    if (!config.accessToken) {
      return false;
    }
    return errors === undefined;
  }

  renderErrorMessage() {
    var invalidConfigErrorMessage =
      this.props.invalidConfigErrorMessage ||
      'Oops! Looks like you passed an invalid config for generating the report!';
    var PbiFailErrorMessage =
      this.props.PbiFailErrorMessage ||
      'Oops! An error was encountererd while fetching the report!';
    return this.state.invalidConfig
      ? invalidConfigErrorMessage
      : PbiFailErrorMessage;
  }

  render() {
    const inlineStyles = {
      width: this.props.width,
      height: '91vh',
      overflow: 'hidden',
      display:
        this.state.failedRender && this.state.hideDefaultError
          ? 'none'
          : 'block',
      border: 'none'
    };

    return (
      <React.Fragment>
        {this.state.failedRender &&
          this.state.hideDefaultError &&
          this.renderErrorMessage()}
        <div
          ref={el => {
            ROOTELEMENT = el;
          }}
          style={inlineStyles}
        />
      </React.Fragment>
    );
  }
}

PowerBiWrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
  embedType: PropTypes.string.isRequired,
  AuthenticationType: PropTypes.string.isRequired,
  hideDefaultError: PropTypes.bool.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  filterPaneEnabled: PropTypes.bool,
  navContentPaneEnabled: PropTypes.bool
};

export default PowerBiWrapper;

import React, { useState, useEffect } from 'react';
import { object, array, bool, func, string } from 'prop-types';
import { debounce } from 'lodash';

import withStyles from '@material-ui/core/styles/withStyles';

import getDataLayer from 'utils/gtagManager';

import { SearchIcon } from 'assets/icons/appIcons';

import InputField from 'components/TextField/TextField';
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& input': {
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& label': {
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& label.MuiFormLabel-root': {
      fontSize: '1.1rem',
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& div[class$="container"]': {
      marginLeft: '10px',
      marginRight: '10px'
    },
    color: theme.palette.type === 'light' && '#767676 !important',
    // backgroundColor:
    //   theme.palette.type === 'light' ? '#f5f5f5 !important' : 'inherit',
    '& div[class*="singleValue"]': {
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& div[class*="control"]': {
      borderColor: theme.palette.type === 'light' && '#c7c7c7 !important',
      backgroundColor: theme.palette.type === 'light' && 'white !important'
    },
    '& div[class*="indicatorContainer"]': {
      color: theme.palette.type === 'light' && 'hsl(0,0%,60%) !important'
    },
    '& div[class*="MenuList"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important'
    },
    '& div[class*="option"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important',
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& div[class*="option"]:hover': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
      color: theme.palette.type === 'light' && '#767676 !important'
    }
  }
});

const SearchComponent = ({
  classes,
  data,
  useDebounce,
  getSearchData,
  setSearchValue,
  refreshButtonStatus,
  setRefreshButtonStatus,
  activeSideMenuItem
}) => {
  const [dropdownValue, setDropdownValue] = useState({
    label: 'Select',
    value: 'select'
  });
  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    if (refreshButtonStatus) {
      setDropdownValue({
        label: 'Select',
        value: 'select'
      });
      setSearchInputValue('');
      setRefreshButtonStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshButtonStatus]);

  const debounceSearchHandler = React.useCallback(
    debounce((col, value) => {
      getSearchData(col, value);
      setSearchValue({ col, value });
      const dataLayer = getDataLayer();
      if (dataLayer !== null) {
        dataLayer.push({
          event: 'filter_change',
          inputValue: value,
          dropdownValue: col,
          path: activeSideMenuItem
        });
      }
    }, 2000),
    []
  );

  const onChangeHandler = value => {
    setSearchInputValue(value);
    if (useDebounce && dropdownValue.value !== 'select') {
      debounceSearchHandler(dropdownValue.value, value);
    }
  };

  const dropdownOnChangeHandler = value => {
    setDropdownValue(value);
    if (useDebounce && searchInputValue !== '') {
      getSearchData(value.value, searchInputValue);
      setSearchValue({ col: value.value, value: searchInputValue });
      const dataLayer = getDataLayer();
      if (dataLayer !== null) {
        dataLayer.push({
          event: 'filter_change',
          dropdownValue: value.value,
          inputValue: searchInputValue,
          path: activeSideMenuItem
        });
      }
    }
  };

  const searchButtonHandler = () => {
    if (searchInputValue !== '' && dropdownValue.value !== 'select') {
      getSearchData(dropdownValue.value, searchInputValue);
      setSearchValue({ col: dropdownValue.value, value: searchInputValue });
      // const dataLayer = getDataLayer();
      // if (dataLayer !== null) {
      //   dataLayer.push({
      //     event: 'filter_change',
      //     col: dropdownValue.value
      //   });
      // }
    }
  };

  return (
    <div className={classes.container}>
      <InputField
        onChange={e => onChangeHandler(e.target.value)}
        id="search"
        type="text"
        label="Search"
        value={searchInputValue}
        colorTheme="dark"
        fullWidth
        variant="outlined"
      />
      <Dropdown
        key=""
        title=""
        handleValueChange={value => dropdownOnChangeHandler(value)}
        options={data
          .filter(option => option.is_searchable)
          .map(col => ({ label: col.label, value: col.key }))}
        value={dropdownValue}
      />
      {!useDebounce ? (
        <Button color="dark" round justIcon onClick={searchButtonHandler}>
          <SearchIcon />
        </Button>
      ) : null}
    </div>
  );
};

SearchComponent.propTypes = {
  classes: object.isRequired,
  data: array.isRequired,
  useDebounce: bool.isRequired,
  getSearchData: func.isRequired,
  setSearchValue: func.isRequired,
  refreshButtonStatus: bool.isRequired,
  setRefreshButtonStatus: func.isRequired,
  activeSideMenuItem: string
};

SearchComponent.defaultProps = {
  activeSideMenuItem: ''
};

export default withStyles(styles)(SearchComponent);

import { createAction } from '@reduxjs/toolkit';
import { storeExplorerTypes } from 'redux-utils/types';
import apiModel from 'models/apiModel';
import reducerModel from 'models/reducerModel';

const getShelfImageAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorer.id, reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.getShelfImage.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getShelfImage,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getShelfImage.success,
    requestAction: storeExplorerTypes.getShelfImage.fromAPI
  });
  action.meta = meta;
  return action;
};

const clearSessionData = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorer.id]
  }
) => {
  const action = createAction(storeExplorerTypes.getShelfImage.clear)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getCurationStoresAction = (
  payload,
  meta = { reducerID: [reducerModel.app.id, reducerModel.filter.id] }
) => {
  const action = createAction(storeExplorerTypes.getCurationStores.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getCurationStores,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getCurationStores.success,
    requestAction: storeExplorerTypes.getCurationStores.fromAPI
  });
  action.meta = meta;
  return action;
};

const getCurationSessionsAction = (
  payload,
  meta = { reducerID: [reducerModel.app.id, reducerModel.filter.id] }
) => {
  const action = createAction(storeExplorerTypes.getCurationSessions.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getCurationSessions,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getCurationSessions.success,
    requestAction: storeExplorerTypes.getCurationSessions.fromAPI
  });
  action.meta = meta;
  return action;
};

const getStoreExplorerFilterAction = (
  payload,
  meta = { reducerID: [reducerModel.storeExplorerNew.id] }
) => {
  const action = createAction(
    storeExplorerTypes.getStoreExplorerFilter.request
  )({
    ...payload,
    dbKey: {
      apiID: apiModel.getStoreExplorerFilter,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getStoreExplorerFilter.success,
    requestAction: storeExplorerTypes.getStoreExplorerFilter.fromAPI
  });
  action.meta = meta;
  return action;
};

export {
  getShelfImageAction,
  getCurationStoresAction,
  getCurationSessionsAction,
  getStoreExplorerFilterAction,
  clearSessionData
};

import { createAction } from '@reduxjs/toolkit';
import { authTypes } from 'redux-utils/types/index';

const userLoginRequest = payload =>
  createAction(authTypes.login.request)(payload);

const clientValidationRequest = payload =>
  createAction(authTypes.clientValidation.request)(payload);

const forgotPasswordRequest = payload =>
  createAction(authTypes.forgotPassword.request)(payload);

const confirmForgotPassword = payload =>
  createAction(authTypes.confirmForgotPassword.request)(payload);

const resetPasswordRequest = payload =>
  createAction(authTypes.resetPassword.request)(payload);

const userLogoutRequest = payload => {
  return createAction(authTypes.logout.request)(payload);
};
export {
  confirmForgotPassword,
  clientValidationRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  userLoginRequest,
  userLogoutRequest
};

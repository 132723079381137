import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { isEmpty } from 'lodash';

import CloseIcon from '@material-ui/icons/Close';

import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import Loader from 'components/Loader/Loader';
import LineChartComponent from 'charts/line/LineChartComponent';

import { VSpacer } from 'components/Spacer/Spacer';
import { NavigateNextIcon } from 'assets/icons/appIcons';
import enums from 'models/enums';

const COLORS = [
  'linear-gradient(90deg, rgb(249 112 146) 0%, rgb(247 102 139) 50%)',
  'linear-gradient(90deg, rgb(102 206 218) 0%, rgb(0, 189, 211) 50%)',
  'linear-gradient(90deg, rgb(149 115 251) 0%, rgb(130, 89, 255) 50%)',
  '#2B9DFA',
  '#DC9E64',
  '#FAA3A3'
];

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: 250
  },
  container: {
    maxWidth: '100%',
    padding: '0 40px',
    background: '#37374a',
    borderRadius: 20,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    position: 'relative'
  },
  header: {
    display: 'flex',
    gap: '20px',
    '& h4:nth-of-type(2)': {
      marginLeft: 'auto'
    },
    '& span': {
      position: 'relative',
      '&::before': {
        top: 10,
        left: '-20px',
        width: 20,
        height: 2,
        content: '""',
        position: 'absolute',
        transform: 'rotate(90deg)',
        background: 'rgba(255,255,255,0.5)'
      }
    }
  },
  cardList: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    gap: '10px',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  card: {
    width: 'auto',
    padding: 15,
    minWidth: 'auto',
    maxHeight: 180,
    minHeight: 170,
    borderRadius: 10,
    display: 'flex',
    flexFlow: 'column',
    position: 'relative'
  },
  arrowButton: {
    position: 'absolute',
    background: 'rgba(76, 60, 60, 0.34)',
    zIndex: 1,
    right: 15,
    top: 5,
    width: 30,
    minWidth: 30,
    height: 30,
    '&>svg': {
      width: 20,
      height: 20
    }
  },
  valueWrapper: {
    display: 'flex',
    gap: '20px',
    alignItems: 'baseline',
    // height: 60,
    marginTop: 15
  },
  value: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'relative',
    '& p': {
      fontSize: 20,
      color: '#fff'
    }
  },
  previousValue: {
    '& p': {
      color: 'rgba(0,0,0,0.8)',
      fontSize: 20
    },
    '&::before': {
      top: 34,
      left: -17,
      width: 17,
      height: 2,
      content: '""',
      position: 'absolute',
      transform: 'rotate(90deg)',
      background: 'rgba(0,0,0,0.8)'
    }
  },
  label: {
    width: 160,
    position: 'relative',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    breakAfter: 'left',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    display: 'inline',
    '&::after': {
      left: 0,
      bottom: '-12px',
      width: 20,
      height: 2,
      content: '""',
      position: 'absolute',
      background: 'rgba(255, 255, 255, 0.5)'
    }
  }
};

const getColor = index => {
  const { length } = COLORS;
  const position = index - length * Math.floor(index / length);
  return COLORS[index] || COLORS[position];
};

const HtmlTooltip = withStyles(() => ({
  popper: {
    zIndex: '2 !important',
    top: '100px !important'
  },
  tooltip: {
    backgroundColor: '#eaeaea',
    color: 'black',
    borderRadius: '10px',
    '& h4': {
      fontSize: '12px'
    },
    padding: '0px',
    minWidth: 700,
    minHeight: 330,
    width: 'auto',
    height: 'auto',
    '& div[class*="chartWrapper"]': {
      height: 300,
      width: 600,
      '& > div': {
        height: 'calc(100% - 55px)',
        background: '#eaeaea !important',
        '& text': {
          fill: '#959595 !important'
        }
      }
    },
    '& div[class*="tooltipContainer"]': {
      padding: '20px 20px 5px',
      minWidth: 700,
      width: 700,
      height: 300
    },
    '& div[class*="closeButtonContainer"]': {
      display: 'flex',
      justifyContent: 'space-between',
      '& button': {
        width: 22,
        height: 22,
        minWidth: 22
      }
    }
  }
}))(Tooltip);

const CumulativeCards = ({
  cumulativeCards,
  classes,
  compareVersions,
  apiRequestStatus
}) => {
  const [open, setOpen] = useState(false);
  const [tooltipKey, setTooltipKey] = useState(null);

  const handleTooltipOpen = tKey => {
    setOpen(true);
    setTooltipKey(tKey);
  };

  const handleTooltipClose = event => {
    if (event.target.id.includes('tooltipCol')) {
      return;
    }
    setOpen(false);
    setTooltipKey(null);
  };

  return !isEmpty(cumulativeCards) ? (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography>{cumulativeCards.title}</Typography>
          <Typography>{cumulativeCards.currentText}</Typography>
          {compareVersions && (
            <span>
              <Typography>{cumulativeCards.previousText}</Typography>
            </span>
          )}
        </div>
        {apiRequestStatus.getCumulativeCards.status !==
          enums.apiRequest.REQUEST &&
        (!cumulativeCards || !cumulativeCards.cards.length) ? (
          <Typography align="center">Data not available</Typography>
        ) : null}
        {apiRequestStatus.getCumulativeCards.status ===
        enums.apiRequest.REQUEST ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader color="primary" simple circular />
          </div>
        ) : null}
        <div className={classes.cardList}>
          {cumulativeCards.cards.map((card, index) => {
            return (
              <HtmlTooltip
                key={card.title}
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                open={index === tooltipKey && open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                title={
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div className="tooltipContainer">
                      <div className="closeButtonContainer">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography size={40} color="#5d5d5d" weight={700}>
                            {card.chart?.title}
                          </Typography>
                        </div>
                        <Button
                          color="dark"
                          round
                          justIcon
                          onClick={handleTooltipClose}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                      <VSpacer amount={20} />
                      {card.chart ? (
                        <div className="chartWrapper">
                          <LineChartComponent
                            granularities={{}}
                            chartName=""
                            title=""
                            isPinned={false}
                            useExport={false}
                            handlePinClick={() => {}}
                            handleDataDownload={() => {}}
                            handleGranularityClick={() => {}}
                            data={card.chart}
                          />
                        </div>
                      ) : (
                        <Typography center color="rgba(0, 0, 0, 0.8)">
                          No data available
                        </Typography>
                      )}
                    </div>
                  </ClickAwayListener>
                }
              >
                <div
                  style={{ background: getColor(index) }}
                  key={card.title}
                  className={classes.card}
                >
                  <Button
                    id="tooltipCol"
                    className={classes.arrowButton}
                    color="dark"
                    round
                    justIcon
                    onClick={() => handleTooltipOpen(index)}
                  >
                    <NavigateNextIcon id="tooltipCol" />
                  </Button>
                  <div className={classes.valueWrapper}>
                    <div className={classes.value}>
                      <Typography size={40} color="#fff">
                        {card.current}
                      </Typography>
                      <span>
                        <p>%</p>
                      </span>
                    </div>
                    {compareVersions && (card.previous || card.previous === 0) && (
                      <>
                        <div
                          className={`${classes.value} ${classes.previousValue}`}
                        >
                          <Typography size={20} color="rgba(0,0,0,0.8)">
                            {card.previous}
                          </Typography>
                          <span>
                            <p className={classes.previousPercentage}>%</p>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <Typography
                    className={classes.label}
                    color="rgba(255,255,255,0.8)"
                  >
                    {card.title}
                  </Typography>
                </div>
              </HtmlTooltip>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

CumulativeCards.propTypes = {
  cumulativeCards: object,
  classes: object.isRequired,
  compareVersions: bool,
  apiRequestStatus: object.isRequired
};

CumulativeCards.defaultProps = {
  cumulativeCards: {},
  compareVersions: false
};

export default withStyles(styles)(CumulativeCards);

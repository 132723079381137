import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core
import Menu from '@material-ui/core/Menu';

const ITEM_HEIGHT = 48;

/**
 * @class
 * @hideconstructor
 * @description Core Menu component
 */
const CustomMenu = ({ anchorEl, id, children, handleClose }) => {
  const open = Boolean(anchorEl);
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200
        }
      }}
    >
      {children}
    </Menu>
  );
};

// component properties
CustomMenu.propTypes = {
  /**
   * @type {string}
   * @description - ID of the menu component
   */
  id: PropTypes.string.isRequired,
  /**
   * @type {element}
   * @description - Target element
   */
  anchorEl: PropTypes.any,
  /**
   * @type {function | element}
   * @description Child elements
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  /**
   * @type {function}
   * @description - Callback function to handle the menu close
   */
  handleClose: PropTypes.func.isRequired
};

CustomMenu.defaultProps = {
  anchorEl: null
};

export default CustomMenu;

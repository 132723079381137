/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

/* global google */

import React from 'react';
// javascript library to compute gradient color
import tinyGradient from 'tinygradient';
// library to set properties for components
import PropTypes from 'prop-types';
import { withProps, compose } from 'recompose';
// google maps library for react
import {
  Circle,
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
// map options
import options from 'maps/options';
import { RGBToHex } from 'utils/colorConverter';
import ls from 'lib/core/storageFactory';

// gradient color generator
const gradient = tinyGradient(['red', 'yellow', 'green', 'cyan', 'blue']);
const colorsRgb = gradient.rgb(100);

const DEFAULT_POINT_COLOR = '#FFEB3B';

class GoogleMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      infoWindow: {
        isVisible: false,
        position: new window.google.maps.LatLng(0, 0)
      }
    };
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.computeBounds(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.computeBounds(nextProps);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data.datasets) {
      if (prevProps.data.datasets.length !== data.datasets.length) {
        this.setState(prev => ({
          ...prev,
          infoWindow: { ...prev.infoWindow, isVisible: false }
        }));
      }
    }
  }

  computeColor = (weight = 0) => {
    const stop = weight >= 100 ? 99 : weight <= 0 ? 1 : weight;
    const { _r, _g, _b } = colorsRgb[Math.floor(stop)];
    return RGBToHex(`rgb(${_r},${_g},${_b})`);
  };

  computeBounds = props => {
    const {
      data: { datasets }
    } = props;
    const bounds = new window.google.maps.LatLngBounds();
    datasets.map(model => {
      const { lat, lng } = model.location;
      const latLng = new window.google.maps.LatLng(lat, lng);
      if (datasets.length < 2) {
        // Earth’s radius, sphere
        const R = 6378137;
        // offsets in meters
        const dn = 500;
        const de = 500;
        // Coordinate offsets in radians
        const dLat = dn / R;
        const dLon = de / (R * Math.cos((Math.PI * lat) / 180));
        // OffsetPosition, decimal degrees
        const latO = lat + (dLat * 180) / Math.PI;
        const lonO = lng + (dLon * 180) / Math.PI;
        const offsetLatLng = new window.google.maps.LatLng(latO, lonO);
        bounds.extend(offsetLatLng);
      }
      bounds.extend(latLng);
      return latLng;
    });
    this.mapRef.current.fitBounds(bounds);
  };

  handleMouseOver = ({ location, weight, storeName }) => {
    const { lat, lng } = location;
    this.setState(prev => {
      return {
        ...prev,
        infoWindow: {
          isVisible: true,
          position: new window.google.maps.LatLng(lat, lng),
          storeName,
          weight
        }
      };
    });
  };

  render() {
    const {
      displayMarkers,
      data: { datasets },
      chartType
    } = this.props;

    const { infoWindow } = this.state;

    const coordinates = datasets.map(point => {
      const {
        location: { lat, lng, store_name: storeName, color },
        weight,
        ...rest
      } = point;
      return {
        location: new window.google.maps.LatLng(lat, lng),
        color,
        weight,
        storeName,
        ...rest
      };
    });

    return (
      <GoogleMap ref={this.mapRef} defaultOptions={options.default}>
        {coordinates.map(({ location, weight, color, ...rest }, i) => {
          const getColor = () => {
            return chartType !== 'map_without_gradient'
              ? this.computeColor(weight)
              : color || DEFAULT_POINT_COLOR;
          };
          return (
            <React.Fragment key={i}>
              <Circle
                radius={coordinates.length > 1 ? 500 : 100}
                visible
                options={{
                  ...options.circle,
                  fillColor: getColor(),
                  strokeColor: getColor()
                }}
                onClick={() =>
                  this.handleMouseOver({ location, weight, ...rest })
                }
                center={location}
              />
              {displayMarkers && (
                <Marker
                  position={location}
                  onClick={() =>
                    this.handleMouseOver({ location, weight, ...rest })
                  }
                />
              )}
            </React.Fragment>
          );
        })}
        {infoWindow.isVisible ? (
          <InfoBox
            options={{ enableEventPropagation: true }}
            defaultPosition={new google.maps.LatLng(0, 0)}
            visible
            position={infoWindow.position}
            onCloseClick={() => {
              this.setState(prev => ({
                ...prev,
                infoWindow: { ...prev.infoWindow, isVisible: false }
              }));
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                opacity: 1,
                padding: 12,
                borderRadius: 5,
                fontSize: 16,
                '& p': {
                  margin: 0
                }
              }}
            >
              {infoWindow.storeName
                ? infoWindow.storeName.length > 0 &&
                  infoWindow.storeName
                    .split('\\n')
                    .map(sentence => (
                      <p style={{ color: `#08233B` }}>{`${sentence}`}</p>
                    ))
                : null}
            </div>
          </InfoBox>
        ) : null}
      </GoogleMap>
    );
  }
}

// component properties
GoogleMaps.propTypes = {
  /**
   * @type {boolean}
   * @description - Whether to display markers on the map
   */
  displayMarkers: PropTypes.bool,
  chartType: PropTypes.string.isRequired
};

GoogleMaps.defaultProps = {
  displayMarkers: false
};

export default compose(
  withProps(options.maps),
  withScriptjs,
  withGoogleMap
)(GoogleMaps);

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { keys, map, each, pick, isEmpty } from 'lodash';
// action types
import {
  appTypes,
  chartTypes,
  cacheTypes,
  storeExplorerTypes
} from 'redux-utils/types/index';
import grModel from 'models/granularityModel';

// initial reducer state
const initialState = {
  isFetching: false,
  activeRouteID: '',
  previousRouteID: '',
  clientPreferences: {},
  sidebarFilters: {},
  filterOrder: null,
  analyticsRedirect: { request: true, data: {} },
  preferences: {
    filters: {},
    granularities: {},
    filterGranularities: {}
  },
  error: {
    state: false,
    type: ''
  },
  surveyDetailsData: {
    isFetching: false,
    data: null,
    error: null
  }
};

const reducer = createReducer(initialState, {
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  },
  [appTypes.getClientPreferences.success]: (state, action) => {
    state.clientPreferences = action.payload.response;
  },
  [appTypes.routeChange]: (state, action) => {
    const { payload } = action;
    state.activeRouteID = payload.current;
    state.previousRouteID = payload.previous;
  },
  [appTypes.getAnalyticsRedirect.request]: state => {
    state.analyticsRedirect = {
      request: true,
      error: false,
      data: {}
    };
  },
  [appTypes.getAnalyticsRedirect.success]: (state, action) => {
    state.analyticsRedirect = {
      request: false,
      error: false,
      data: action.payload.response
    };
  },
  [appTypes.getAnalyticsRedirect.failure]: (state, action) => {
    state.analyticsRedirect = {
      request: false,
      error: true,
      message: action.payload.error
    };
    state.error = {
      state: true,
      type: action.type
    };
  },
  // eslint-disable-next-line consistent-return
  [chartTypes.getChartList.success]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID },
        response: { data: chartList }
      }
    } = action;
    state.isPageLoading = false;
    if (!isEmpty(state.preferences.granularities[routeID])) {
      return state;
    }
    const grList = {};
    each(chartList, chart => {
      const chartObject = pick(chart, [
        'granularities',
        'name',
        'params',
        'filters'
      ]);
      const grType = {};
      each(chartObject.granularities, e => {
        const options = map(e.options, obj => ({ ...obj, isHidden: false }));
        grType[e.type] = { ...e, options, value: options[0].value };
      });
      grList[chartObject.name] = grType;
      each(chartObject.filters, e => {
        const pickFrom = e.pick_from
          ? e.pick_from
          : e.filter_id.includes('brand')
          ? 'fl_category'
          : e.filter_id.includes('fl_posm')
          ? 'fl_channel'
          : 'fl_brand';
        if (pickFrom === 'fl_channel') {
          grType[e.filter_id] = {
            ...e,
            value: state.preferences.filters[routeID][pickFrom][e.filter_id][0]
          };
        } else {
          grType[e.filter_id] = {
            ...e,
            value: state.preferences.filters[routeID][pickFrom][e.filter_id][0]
          };
        }
      });
      grList[chartObject.name] = grType;
    });
    state.error.state = false;
    state.error.type = '';
    state.preferences.granularities[routeID] = {
      ...grList
    };
  },
  [storeExplorerTypes.getCurationStores.success]: (state, action) => {
    const {
      payload: { response, menuModel }
    } = action;
    state.isPageLoading = false;
    let value = {};
    if (response.data[0]) {
      value = {
        label: response.data[0].store_title,
        value: response.data[0].store_name,
        is_default: true
      };
    }
    const explorerRoutes = {};
    each(menuModel, route => {
      explorerRoutes[route] = {
        ...state.preferences.filters[route],
        fl_store: value
      };
    });
    state.preferences = {
      ...state.preferences,
      filters: {
        ...state.preferences.filters,
        ...explorerRoutes
      }
    };
  },
  [storeExplorerTypes.getCurationSessions.success]: (state, action) => {
    const {
      payload: { response, menuModel }
    } = action;
    state.isPageLoading = false;
    let value = {};
    if (response.data[0]) {
      value = {
        label: response.data[0].label,
        value: response.data[0].session_id,
        is_default: true
      };
    }
    const explorerRoutes = {};
    each(menuModel, route => {
      explorerRoutes[route] = {
        ...state.preferences.filters[route],
        fl_session: value
      };
    });
    state.preferences = {
      ...state.preferences,
      filters: {
        ...state.preferences.filters,
        ...explorerRoutes
      }
    };
  },
  [appTypes.clearFilter]: state => {
    state.preferences = {
      ...state.preferences,
      filters: {}
    };
  },
  [appTypes.filterOrder.set]: (state, action) => {
    state.filterOrder = action.payload;
  },
  [appTypes.sidebarFilters.set]: (state, action) => {
    state.sidebarFilters = action.payload;
  },
  [appTypes.sidebarFilters.clear]: state => {
    state.sidebarFilters = {};
  },
  [appTypes.filterChange]: (state, action) => {
    const {
      payload: { value: filterList }
    } = action;
    const routeID = action.payload.routeID || action.payload.routeIDParam;
    const { filterType, chartName, grType, valueKey } = grModel;
    const filterId = keys(filterList);
    if (
      filterId.includes(filterType) &&
      state.preferences.granularities[routeID] !== undefined
    ) {
      map(chartName, name => {
        map(
          state.preferences.granularities[routeID][name][grType].options,
          el => {
            el.isHidden = false;
            if (el.value === valueKey) {
              el.isHidden = filterList.fl_type.value !== 'shelf';
            }
            return { ...el };
          }
        );
        state.preferences.granularities[routeID][name] = {
          ...state.preferences.granularities[routeID][name],
          [grType]: {
            ...state.preferences.granularities[routeID][name][grType],
            value: filterList.fl_type.value === 'shelf' ? 'brand' : 'brand-form'
          }
        };
      });
    }
    state.activeRouteID = routeID;
    const filters = {
      ...state.preferences.filters
    };
    filters[routeID] = {
      ...filters[routeID],
      ...filterList
    };
    // if (filterList.fl_time) {
    //   map(filters, (fl, route) => {
    //     filters[route] = {
    //       ...filters[route],
    //       fl_time: { ...filterList.fl_time }
    //     };
    //   });
    // }

    state.preferences.filters = { ...filters };
  },
  [chartTypes.granularityChange]: (state, action) => {
    const {
      payload: { routeID, value: chart }
    } = action;
    const [chartName] = keys(chart);
    const grList = chart[chartName];
    const [grType] = keys(grList);
    state.activeRouteID = routeID;
    state.preferences.granularities[routeID][chartName] = {
      ...state.preferences.granularities[routeID][chartName],
      [grType]: {
        ...state.preferences.granularities[routeID][chartName][grType],
        value: grList[grType]
      }
    };
  },
  [appTypes.surveyDetails.request]: state => {
    state.surveyDetailsData = {
      ...state.surveyDetailsData,
      isFetching: true
    };
  },
  [appTypes.surveyDetails.success]: (state, action) => {
    state.surveyDetailsData = {
      ...state.surveyDetailsData,
      data: action.payload.response,
      error: null,
      isFetching: false,
    };
  },

  [appTypes.surveyDetails.failure]: (state, action) => {
    state.surveyDetailsData = {
      ...state.surveyDetailsData,
      data: null,
      error: action.payload.message,

      isFetching: false
    };
  },
});

export default reducer;

import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
import { isEmpty, isObject, isArray } from 'lodash';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// custom select component for react
import CreatableSelect from 'react-select/creatable';
// core components
import Typography from 'components/Typography/Typography';
// styles
import { customStyles, dropDownStyles } from './styles';
import styled from 'styled-components';

const StyledSelect = styled(CreatableSelect)`
  && {
    font-size: ${props => `${props.fontSize} !important`};
    color: ${props => `${props.color} !important`};
    border-color: ${props => `${props.borderColor} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='singleValue'] {
    color: ${props => `${props.color} !important`};
  }
  && div[class*='control'] {
    border-color: ${props => `${props.borderColor} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='MenuList'] {
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='option'] {
    color: ${props => `${props.color} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
`;

/**
 * @class Dropdown
 * @hideconstructor
 * @description Customized stateless select dropdown component
 */
const Dropdown = ({
  options,
  button: isButton,
  handleValueChange,
  value: currentValue,
  width,
  title,
  isMulti,
  titleColor,
  classes,
  dropdownStyles
}) => (
  <div className={classes.dropDown}>
    {title ? (
      <Typography
        className={classes.label}
        color={titleColor}
        weight={400}
        size={14}
      >
        {title}
      </Typography>
    ) : null}

    <StyledSelect
      color={dropdownStyles?.textColor}
      bgColor={dropdownStyles?.backgroundColor}
      fontSize={dropdownStyles?.fontSize}
      borderColor={dropdownStyles?.borderColor}
      isMulti={isMulti}
      styles={customStyles(isButton, width)}
      className={classes.dropdownMenu}
      onChange={updatedValue => {
        if (isMulti) {
          let refinedValue = updatedValue ? [...updatedValue] : [];
          if (updatedValue !== null) {
            if (updatedValue.length > 1) {
              let isAllInCurrentValue = 0;
              let isAllInUpdatedValue = 0;
              if (isObject(currentValue)) {
                isAllInCurrentValue = currentValue.value === 'all';
              }
              if (isArray(currentValue)) {
                isAllInCurrentValue =
                  currentValue.filter(el => el.value === 'all').length > 0;
              }
              if (isObject(updatedValue)) {
                isAllInUpdatedValue = updatedValue.value === 'all';
              }
              if (isArray(updatedValue)) {
                isAllInUpdatedValue =
                  updatedValue.filter(el => el.value === 'all').length > 0;
              }
              if (isAllInCurrentValue && isAllInUpdatedValue) {
                const index = refinedValue.findIndex(el => el.value === 'all');
                if (index > -1) {
                  refinedValue.splice(index, 1);
                }
              }
              if (!isAllInCurrentValue && isAllInUpdatedValue) {
                [refinedValue] = refinedValue.filter(el => el.value === 'all');
              }
            }
          }
          handleValueChange(refinedValue);
        } else if (
          updatedValue.value !== currentValue.value ||
          updatedValue.value === 'custom'
        ) {
          handleValueChange(updatedValue);
        }
      }}
      value={isEmpty(currentValue) ? null : currentValue}
      options={options}
    />
  </div>
);

// component properties
Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * @type {string}
   * @description - Title of the dropdown
   */
  title: PropTypes.string,
  /**
   * @type {string}
   * @description - Dropdown title color
   */
  titleColor: PropTypes.string,
  /**
   * @type {array}
   * @description - Whether to apply button styles
   */
  isMulti: PropTypes.bool,
  /**
   * @type {array}
   * @description - Whether to apply button styles
   */
  button: PropTypes.bool,
  /**
   * @callback
   * @description - Callback function to handle the dropdown change
   */
  handleValueChange: PropTypes.func.isRequired,
  /**
   * @type {array}
   * @description - Dropdown options
   */
  options: PropTypes.array.isRequired,
  /**
   * @type {object}
   * @description - Dropdown value
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * @type {number}
   * @description - Width of the dropdown
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Dropdown.defaultProps = {
  button: false,
  isMulti: false,
  width: 150,
  value: {},
  title: undefined,
  titleColor: 'light25'
};

export default withStyles(dropDownStyles)(Dropdown);

import React from 'react';

// assets
import bubbleTop from 'assets/svg/bubble_design_top.svg';

// components
import Animation from './Animation';
import { ListItems, LoginHeader } from './constants';

const StaticsContent = () => {
  return (
    <>
      <div className="bubbles bubblesTop">
        <img alt="bubbles_top" src={bubbleTop}></img>
      </div>
      <div className="bubbles bubblesBottom">
        <img alt="bubbles_top" src={bubbleTop}></img>
      </div>
      <div className="staticContentHeader">{LoginHeader}</div>
      <div className="staticContentBody">
        <ul className="listItemWrapper">
          {ListItems.map((el, idx) => (
            <li className="listItem" key={idx}>
              {el}
            </li>
          ))}
        </ul>
      </div>
      <Animation />
    </>
  );
};

export default StaticsContent;

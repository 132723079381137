/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { Rect, Text, Label, Tag } from 'react-konva';

const getImageObject = (box, list) => {
  const index = list.findIndex(el => el.id === box.photoID);

  const imageObject = list[index];
  if (imageObject) {
    return {
      ...imageObject,
      index,
      scaled_height: Math.floor(imageObject.height * imageObject.height_ratio),
      scaled_width: Math.floor(imageObject.width * imageObject.width_ratio)
    };
  }
  return null;
};

const Crops = ({
  activeBoxes,
  selectedCrops,
  showStickers,
  stickerBoxes,
  scaleDownFactor,
  imageList,
  handleCropHover,
  handleCropClick,
  isMiniMap
}) => {
  const allBoxes = [...activeBoxes, ...stickerBoxes];
  return allBoxes.map((box, i) => {
    const image = getImageObject(box, imageList);
    if (!image) return null;
    const imageRange =
      image.index === 0 ? imageList[0] : imageList.slice(0, image.index);
    const offsetX =
      image.index === 0 ? 0 : sumBy(imageRange, x => x.canvasWidth);
    const relativePoints = {
      left: (box.left / image.width) * image.canvasWidth + offsetX,
      right: (box.right / image.width) * image.canvasWidth + offsetX,
      top: (box.top / image.height) * image.canvasHeight,
      bottom: (box.bottom / image.height) * image.canvasHeight
    };

    return (
      <>
        {scaleDownFactor === 1 && box.text && (
          <Label
            key={`${i + box.id}label${scaleDownFactor}`}
            x={relativePoints.left / scaleDownFactor}
            y={relativePoints.top / scaleDownFactor}
          >
            <Tag fill="yellow" />
            <Text
              text={box.text}
              fontSize={10}
              stroke="#000"
              strokeWidth={1}
              padding={4}
            />
          </Label>
        )}

        {box.is_sku === undefined || box.is_sku === null ? ( // crops
          <Rect
            key={`${i + box.id}box${scaleDownFactor}`}
            x={relativePoints.left / scaleDownFactor}
            y={
              isMiniMap
                ? relativePoints.top / scaleDownFactor + 20
                : relativePoints.top / scaleDownFactor
            }
            width={
              (relativePoints.right - relativePoints.left) / scaleDownFactor
            }
            height={
              (relativePoints.bottom - relativePoints.top) / scaleDownFactor
            }
            dashEnabled={selectedCrops[box.id] ? true : false}
            dash={[2, 2]}
            stroke={
              selectedCrops[box.id]
                ? '#fff'
                : box.color?.length > 0
                ? box.color
                : '#44ec09'
            }
            strokeWidth={3}
            onClick={() => handleCropClick({ crop: { ...box, is_sku: true } })}
            onMouseOver={() =>
              handleCropHover({
                crop: { ...box, is_sku: true },
                image: image.path
              })
            }
          />
        ) : box.is_sku === false ? ( // stickers
          showStickers && (
            <Rect
              key={`${i + box.id}box${scaleDownFactor}`}
              x={relativePoints.left / scaleDownFactor}
              y={
                isMiniMap
                  ? relativePoints.top / scaleDownFactor + 10
                  : relativePoints.top / scaleDownFactor
              }
              width={
                (relativePoints.right - relativePoints.left) / scaleDownFactor
              }
              height={
                (relativePoints.bottom - relativePoints.top) / scaleDownFactor
              }
              dashEnabled={selectedCrops[box.id] ? true : false}
              dash={[2, 2]}
              stroke={
                selectedCrops[box.id]
                  ? '#fff'
                  : box.color?.length > 0
                  ? box.color
                  : '#44ec09'
              }
              strokeWidth={1.5}
              onClick={() =>
                handleCropClick({ crop: { ...box, is_sticker: true } })
              }
              onMouseOver={() =>
                handleCropHover({
                  crop: { ...box, is_sticker: true },
                  image: image.path
                })
              }
            />
          )
        ) : null}
      </>
    );
  });
};

Crops.propTypes = {
  activeBoxes: PropTypes.array,
  stickerBoxes: PropTypes.array,
  showStickers: PropTypes.bool,
  selectedCrops: PropTypes.object,
  scaleDownFactor: PropTypes.number,
  imageList: PropTypes.array.isRequired,
  handleCropClick: PropTypes.func,
  handleCropHover: PropTypes.func
};

Crops.defaultProps = {
  scaleDownFactor: 1,
  activeBoxes: [],
  selectedCrops: {},
  stickerBoxes: [],
  showStickers: false,
  handleCropClick: () => {},
  handleCropHover: () => {}
};

export default Crops;
export { getImageObject };

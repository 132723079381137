const endpoints = {
  getSubPanel: '/api/v1/mdm/subpanal-layout/',
  getSubPanelPageData: '/api/v1/mdm/data/',
  downloadAllTemplates: '/api/v1/mdm/template-data/',
  downloadTemplate: '/api/v1/mdm/template-data/',
  downloadExcel: '/api/v1/mdm/download-excel/',
  downloadPackshots: '/api/v1/mdm/download-excel/',
  packshotUpload: '/api/v1/mdm/packshot_upload/',
  excelUpload: '/api/v1/mdm/excel_upload/',
  pollTask: '/api/v1/mdm/poll/',
  getStickerValues: '/api/v1/mdm/get_values_promotional_sicker/',
  filters: '/api/v1/filters/',
  chartList: '/api/v1/chart/list/',
  chartData: '/api/v1/chart/data/',
  analyticsRedirect: '/api/v1/analytics-redirect/',
  analytics: { embed: '/api/v4/analytics/embed/' },
  storeExplorer: {
    filters: '/api/v1/store_explorer_filters/',
    filter: '/api/v1/store-explorer/filter/',
    // remove filter endpoint only when session_id is supported by v4 filter.
    v4filter: '/api/v4/dashboard/filter/',
    sessionData: '/api/v1/get_store_photos/',
    sessionFilterValues: '/api/v1/get-filter-values/'
  },
  accuracy: {
    sessionDetails: '/api/v1/accuracy/session-detail/',
    sessionList: '/api/v1/accuracy/session-list/',
    getScheduledReports: '/api/v1/accuracy/reports/',
    getScheduledReportsMetaData: '/api/v1/accuracy/reports-meta/',
    postScheduledReport: '/api/v1/accuracy/reports/',
    updateScheduledReport: '/api/v1/accuracy/reports/',
    deleteScheduledReport: '/api/v1/accuracy/reports/',
    downloadReport: '/api/v1/accuracy/reports/download/'
  },
  merchEvaluation: {
    pageComponents: '/api/v1/page-components/',
    cumulativeCards: '/api/v1/cumulative-card/',
    getMetricTabs: '/api/v1/metrics/',
    getPerformanceTable: '/api/v1/performance-table/',
    getScheduledReports: '/api/v1/merchandiser-performance/reports/',
    getScheduledReportsMetaData:
      '/api/v1/merchandiser-performance/reports-meta/',
    postScheduledReport: '/api/v1/merchandiser-performance/reports/',
    updateScheduledReport: '/api/v1/merchandiser-performance/reports/',
    deleteScheduledReport: '/api/v1/merchandiser-performance/reports/',
    downloadReport: '/api/v1/merchandiser-performance/reports/download/'
  }
};

export default endpoints;

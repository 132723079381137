/* eslint-disable valid-jsdoc */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map, isEmpty, isEqual } from 'lodash';
// library to set component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// core library
import getStartEndDate from 'lib/utils/getStartEndDate';
import { getPreviousDate } from 'lib/core/dateUtils';
import { removeUndefined } from 'lib/core/object';
// redux-utils
import { appActions } from 'redux-utils/actions';
import { appSelector, storeNewSelector } from 'redux-utils/selectors/index';
// core components
import SEFilter from 'pages/StoreExplorerNew/SENewFilter';
import NoDataMessage from './NoDataMessage';

const initialSelectedDate = {
  start: getPreviousDate(1, 'days', false),
  end: getPreviousDate(0, 'days', false)
};

/**
 * @class SEFilterContainer
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
class SEFilterContainer extends Component {
  state = {};

  // componentDidMount = () => {
  //   this.computeFilterValues();
  // };

  componentWillMount() {
    const { dispatchGetFilterRequest, filterPreferences } = this.props;
    if (isEmpty(filterPreferences)) {
      dispatchGetFilterRequest();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.computeFilterValues(nextProps, 'next');
    }
  }

  // fl_date: 30/10/2019
  // fl_category: foot-wear
  // fl_type: shelf
  // fl_store: 10005300_1554878467506

  computeFilterValues = (nextProps = this.props) => {
    const {
      filterPreferences,
      filters,
      defaultFilters,
      handleFilterChange
    } = nextProps;
    if (!isEmpty(filters) && !isEmpty(defaultFilters)) {
      const defaultItems = {};
      map(defaultFilters, def => {
        const { value: key } = def;
        if (filters[key] && !filterPreferences[key]) {
          // if (filters[key].options[0]) {
          const { options, use_value_dropdown: useValueDropDown } = filters[
            key
          ];
          let [value] =
            options.filter(e => e.is_default === true).length > 0
              ? options.filter(e => e.is_default === true)
              : options;
          let valueDropDownKey;
          let valueDropDownOptions = [];
          let valueDropDownValue;
          if (useValueDropDown) {
            const [defaultItem] =
              options.filter(e => e.is_default === true).length > 0
                ? options.filter(e => e.is_default === true)
                : options;
            valueDropDownKey = defaultItem.value;
            valueDropDownOptions = filters[defaultItem.value].options;
            [valueDropDownValue] =
              valueDropDownOptions.filter(e => e.is_default === true).length > 0
                ? valueDropDownOptions.filter(e => e.is_default === true)
                : valueDropDownOptions;
          }
          if (key === 'fl_date') {
            const dateRange = getStartEndDate(value.value);
            value = { ...dateRange, ...value };
          }
          defaultItems[key] = value;
          defaultItems[valueDropDownKey] = valueDropDownValue;
        }
      });
      handleFilterChange(removeUndefined(defaultItems));
    }
  };

  /**
   * @method
   * @description Handles the date change event
   * @param {object} options - Changed object
   * @param {bool} val - is selected
   * @param {bool} key - Changed object key
   * @return {undefined}
   */
  handleToggleChange = ({ options, val, type: key }) => {
    const { handleFilterChange } = this.props;
    handleFilterChange({
      [key]: val ? options[1] : options[0]
    });
  };

  render() {
    const {
      filters,
      defaultFilters,
      filterPreferences,
      handleFilterChange
    } = this.props;
    return (
      <>
        <SEFilter
          handleFilterChange={handleFilterChange}
          handleToggleChange={this.handleToggleChange}
          initialSelectedDate={initialSelectedDate}
          filters={filters}
          defaultFilters={defaultFilters}
          state={{ ...filterPreferences }}
        />
        {filters.fl_store ? (
          filters.fl_store.options.length === 0 ? (
            <NoDataMessage />
          ) : null
        ) : null}
      </>
    );
  }
}

// component properties
SEFilterContainer.propTypes = {
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description user preferences of the filter
   */
  filterPreferences: PropTypes.object,
  /**
   * @type {array}
   * @description dropdown filter data
   */
  defaultFilters: PropTypes.array,
  /**
   * @type {function}
   * @description Callback function to handle the filter change event
   */
  handleFilterChange: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Callback function to handle the filter change event
   */
  dispatchGetFilterRequest: PropTypes.func.isRequired
};

SEFilterContainer.defaultProps = {
  defaultFilters: [],
  filterPreferences: {}
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const getDefaultFilters = appSelector.makeGetDefaultFilters();
  const getFilterPreferences = storeNewSelector.makeGetFilterPreferences();
  const getFilterList = storeNewSelector.makeGetFilterList();

  const mapStateToProps = (state, props) => ({
    defaultFilters: getDefaultFilters(state, props),
    filterPreferences: getFilterPreferences(state, props),
    filters: getFilterList(state)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetFilterRequest: () => dispatch(appActions.getFilterListAction())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SEFilterContainer);

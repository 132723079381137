import React, { useEffect, useState } from 'react';
import { func, object, array } from 'prop-types';
import { isEmpty } from 'lodash';

import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Loader from 'components/Loader/Loader';
import Icon from 'components/Icon/Icon';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';

import { DownloadIcon } from 'assets/icons/appIcons';
import { drawerWidth } from 'assets/jss/material-ui/material-ui';

import enums from 'models/enums';

import CumulativeCards from './CumulativeCards';
import Filter from './Filter';
import TabContentWrapper from './TabContentWrapper';
import SaveExcelModal from './SaveExcelModal';

const ITEM_HEIGHT = 48;

const styles = {
  root: {
    height: '87vh',
    width: `calc(100vw - ${drawerWidth + 30}px)`,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  toolbar: {
    position: 'fixed',
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    width: 'inherit',
    minHeight: 50,
    padding: 0,
    paddingBottom: 10,
    marginBottom: 13,
    background: '#1e1e2b',
    zIndex: 3,
    '&>label': {
      marginTop: 25,
      color: 'rgba(255,255,255,0.6)'
    }
  },
  title: {
    margin: '15px 0'
  },
  excelIcon: {
    marginTop: 25,
    marginLeft: 'auto'
  },
  content: {
    marginTop: 80
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px'
  }
};

const MerchEvaluationPage = ({
  activeSession,
  apiRequestStatus,
  classes,
  cumulativeCards,
  handleDownloadReport,
  dispatchPostScheduledReport,
  dispatchUpdateScheduledReport,
  dispatchDeleteScheduledReport,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport,
  filtersToRender,
  handleFilterChange,
  handleGranularityChange,
  handleTabChange,
  metricTabs,
  pageComponents,
  performanceTable,
  scheduledReports,
  scheduledReportsMetaData
}) => {
  const [selectedTab, setSelectedTab] = useState(
    pageComponents?.metric_tabs?.tabs[0]
  );
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [excelModalState, setExcelModalState] = useState(false);
  const handleExcelClick = event => {
    setDownloadAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setSelectedTab(pageComponents?.metric_tabs?.tabs[0] || {});
  }, [pageComponents.metric_tabs]);

  const handleDownloadMenuClose = () => {
    setDownloadAnchorEl(null);
  };

  const handleScheduleClick = () => {
    setDownloadAnchorEl(null);
    setExcelModalState(prev => !prev);
  };

  const handleDownloadNowClick = () => {
    setDownloadAnchorEl(null);
    handleDownloadReport({
      fl_session: activeSession.value,
      metric_key: selectedTab.key
    });
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Filter
          filtersToRender={filtersToRender}
          handleFilterChange={handleFilterChange}
        />
        {/* {pageComponents.allow_excel_download && ( */}
        {true && (
          <span className={classes.excelIcon}>
            <Icon color="light">
              <DownloadIcon
                onClick={handleExcelClick}
                aria-haspopup="true"
                aria-controls="download-menu"
              />
            </Icon>
            <Menu
              id="download-menu"
              anchorEl={downloadAnchorEl}
              open={Boolean(downloadAnchorEl)}
              onClose={handleDownloadMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200
                }
              }}
            >
              <MenuItem onClick={() => handleDownloadNowClick()}>
                Download now
              </MenuItem>
              <MenuItem onClick={() => handleScheduleClick()}>
                Schedule
              </MenuItem>
            </Menu>
            <SaveExcelModal
              apiRequestStatus={apiRequestStatus}
              postScheduledReport={postScheduledReport}
              updateScheduledReport={updateScheduledReport}
              deleteScheduledReport={deleteScheduledReport}
              filtersToRender={filtersToRender}
              isModalOpen={excelModalState}
              setIsModalOpen={setExcelModalState}
              scheduledReports={scheduledReports}
              scheduledReportsMetaData={scheduledReportsMetaData}
              dispatchUpdateScheduledReport={dispatchUpdateScheduledReport}
              dispatchPostScheduledReport={dispatchPostScheduledReport}
              dispatchDeleteScheduledReport={dispatchDeleteScheduledReport}
              metricOptions={pageComponents?.metric_tabs?.tabs}
              enableMetricTabs={pageComponents?.metric_tabs?.enable}
              defaultMetricValue={
                isEmpty(selectedTab)
                  ? pageComponents?.metric_tabs?.tabs[0]
                  : selectedTab
              }
            />
          </span>
        )}
      </Toolbar>
      <div className={classes.content}>
        {false ? (
          <>
            <VSpacer amount={20} />
            <Typography align="center">
              Data not available for the selected filters
            </Typography>
          </>
        ) : (
          <>
            {cumulativeCards.enable ? (
              <CumulativeCards
                compareVersions
                cumulativeCards={cumulativeCards}
                apiRequestStatus={apiRequestStatus}
              />
            ) : null}
            {apiRequestStatus.getPageComponents.status ===
            enums.apiRequest.REQUEST ? (
              <div className={classes.loaderContainer}>
                <Loader color="primary" simple circular />
              </div>
            ) : pageComponents.metric_tabs &&
              pageComponents.metric_tabs.enable ? (
              <>
                <TabContentWrapper
                  apiRequestStatus={apiRequestStatus}
                  performanceTable={performanceTable}
                  metricTabs={metricTabs}
                  tabsLayout={pageComponents.metric_tabs.tabs}
                  handleGranularityChange={handleGranularityChange}
                  handleTabChange={handleTabChange}
                  setSelectedTab={setSelectedTab}
                />
              </>
            ) : null}
            {apiRequestStatus.getPageComponents.status ===
              enums.apiRequest.SUCCESS &&
            isEmpty(pageComponents.metric_tabs) ? (
              <div className={classes.loaderContainer}>
                Data not available for the selected filters
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

MerchEvaluationPage.propTypes = {
  activeSession: object,
  apiRequestStatus: object.isRequired,
  classes: object.isRequired,
  cumulativeCards: object.isRequired,
  filtersToRender: object.isRequired,
  handleFilterChange: func,
  handleGranularityChange: func,
  handleTabChange: func,
  metricTabs: object,
  pageComponents: object.isRequired,
  performanceTable: object,
  postScheduledReport: object.isRequired,
  updateScheduledReport: object.isRequired,
  deleteScheduledReport: object.isRequired,
  handleDownloadReport: func.isRequired,
  dispatchPostScheduledReport: func.isRequired,
  dispatchUpdateScheduledReport: func.isRequired,
  dispatchDeleteScheduledReport: func.isRequired,
  scheduledReports: array,
  scheduledReportsMetaData: object
};

MerchEvaluationPage.defaultProps = {
  activeSession: {},
  handleFilterChange: () => {},
  handleGranularityChange: () => {},
  handleTabChange: () => {},
  performanceTable: {},
  metricTabs: {},
  scheduledReports: [],
  scheduledReportsMetaData: {}
};

export default withStyles(styles)(MerchEvaluationPage);

// TODO chart distribution logic

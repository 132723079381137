import React from 'react';
import PropTypes from 'prop-types';
// chart library for react
import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label
} from 'recharts';
// custom chart components
import { YAxisTickFormatter } from 'charts/AxisTick';
import ChartTooltip from 'components/Tooltip/ChartTooltip';
// styles
import colors from 'charts/__variables';
import './__line-styles.scss';

const useColors = [
  colors.dark.orangePink,
  colors.dark.green,
  colors.dark.purple,
  colors.dark.orange,
  colors.dark.lime,
  colors.dark.yellow,
  colors.dark.pink,
  colors.dark.blue,
  colors.dark.red,
  colors.dark.gray
];

// Customized Label component to show label values by default on Lines, pass this as a label prop to Line component of Chart
/*
const CustomizedLabel = ({ x, y, value }) => {
  return (
    <text
      x={x}
      y={y - 5}
      dy={-4}
      fill="white"
      fontSize={14}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
*/

const SimpleLineChart = ({ data }) => (
  <ResponsiveContainer className="line-wrapper" height="97%">
    <LineChart
      data={data.datasets}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      {data.xAxisLabel
        ? data.xAxisLabel.display_axis && (
            <XAxis
              axisLine={false}
              stroke={colors.dark.axisStroke}
              dataKey={data.xAxisDataKey}
              tickLine={false}
              // interval={0}
            >
              {data.xAxisLabel
                ? data.xAxisLabel.show && (
                    <Label
                      value={data.xAxisLabel.label}
                      offset={-10}
                      position="insideBottom"
                    />
                  )
                : null}
            </XAxis>
          )
        : null}
      {data.yAxisLabel
        ? data.yAxisLabel.display_axis && (
            <YAxis
              axisLine={false}
              tick={<YAxisTickFormatter />}
              stroke={colors.dark.axisStroke}
            >
              {data.yAxisLabel
                ? data.yAxisLabel.show && (
                    <Label
                      value={data.yAxisLabel.label}
                      angle={-90}
                      position="center"
                    />
                  )
                : null}
            </YAxis>
          )
        : null}
      <CartesianGrid
        stroke={useColors[0].secondary}
        horizontal={false}
        strokeDasharray="3 3"
      />
      <Tooltip cursor={false} content={<ChartTooltip />} />
      <Legend wrapperStyle={{ bottom: -20 }} iconType="circle" />
      {data.yAxisDataKey.map((yAxis, i) => (
        <Line
          key={yAxis}
          type="monotone"
          dataKey={yAxis}
          strokeWidth={2}
          stroke={useColors[i].primary}
          dot={{ fill: useColors[i].primary, r: 3.5 }}
          // label={CustomizedLabel}
        />
      ))}
    </LineChart>
  </ResponsiveContainer>
);

SimpleLineChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default SimpleLineChart;

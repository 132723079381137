import React, { useState, useEffect } from 'react';
import { object, array, func, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { getPreviousDate, formatDate } from 'lib/core/dateUtils';
import withStyles from '@material-ui/core/styles/withStyles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import MuiButton from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// core components
import Dropdown from 'components/Dropdown/Dropdown';
import InputField from 'components/TextField/TextField';
import Typography from 'components/Typography/Typography';
import TimeFilter from 'layout/Filter/TimeFilter';
import Button from 'components/Button/Button';
import { VSpacer, HSpacer } from 'components/Spacer/Spacer';

import {
  scrollbar,
  successColor,
  dangerColor
} from 'assets/jss/material-ui/material-ui';
import { EditIcon, DeleteIcon } from 'assets/icons/appIcons';

import Loader from 'components/Loader/Loader';
import enums from 'models/enums';

const options = {
  options: [
    {
      label: 'Open Calendar',
      value: 'custom'
    }
  ]
};

const styles = theme => ({
  closeIconContainer: {
    marginLeft: 'auto',
    marginBottom: 'auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#27293d',
    padding: '15px 20px',
    width: 'calc(100vw - 50vw - 100px)',
    borderRadius: '5px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '100',
    color: 'white',
    '& svg': {
      fontSize: '1.2rem',
      marginLeft: '10px',
      borderRadius: '50%',
      border: '1px solid white'
    },
    '& svg:hover': {
      cursor: 'pointer'
    },
    marginBottom: '20px'
  },
  messageIconContainer: {
    position: 'absolute',
    right: '20px'
  },
  successMessage: {
    backgroundColor: successColor,
    '& svg': {
      backgroundColor: successColor
    }
  },
  errorMessage: {
    backgroundColor: dangerColor,
    '& svg': {
      backgroundColor: dangerColor
    }
  },
  modalContent: {
    width: '1100px',
    color: '#94949a',
    backgroundColor: '#1e1e2b',
    outline: 'none',
    padding: '12px 50px !important',
    borderRadius: '10px'
  },
  row: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    '& div[class*="TimeFilter"]': {
      marginLeft: '90px !important'
    },
    '& span[class*="indicatorSeparator"]': {
      margin: '0px !important'
    }
  },
  footer: {
    maxHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    // width: '500px',
    padding: '5px 0px',
    '& button': {
      width: '30px',
      height: '30px',
      minWidth: '30px'
    }
  },
  reportCol: {
    width: '20%',
    paddingLeft: '20px',
    paddingRight: '20px',
    '& h4': {
      whiteSpace: 'break-spaces'
    }
  },
  footerHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    // width: '500px',
    width: '100%',
    paddingRight: '6.5%',
    padding: '0px 0px 10px 0px'
  },
  footerHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    background: '#1e1e2b !important',
    alignItems: 'center',
    width: 'fill-available'
  },
  footerContent: {
    '& div[class*="reportRow"]:nth-of-type(odd)': {
      background: '#ffffff33'
    },
    maxHeight: '210px',
    overflow: 'auto',
    width: '100%',
    paddingRight: '6.5%',
    ...scrollbar,
    scrollbarColor:
      theme.palette.type === 'light'
        ? '#f5f5f5 transparent'
        : 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5' : 'rgb(65, 65, 66)',
      borderRadius: 8
    }
  },
  inputLabel: {
    display: 'block',
    minHeight: '18px',
    marginBottom: '8px',
    textTransform: 'capitalize'
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    background: 'rgba(255, 51, 102, 0.8)',
    boxShadow: 'none !important',
    textTransform: 'capitalize !important',
    padding: '9px 30px !important',
    border: '1px solid #f36 !important',
    '&:hover': {
      background: 'rgba(255, 51, 102, 1)'
    },
    '&:focus': {
      background: 'rgba(255, 51, 102, 1)'
    }
  },
  resetButton: {
    background: 'transparent',
    boxShadow: 'none !important',
    border: '1px solid #f36 !important',
    padding: '9px 30px !important',
    textTransform: 'capitalize !important',
    '&:hover': {
      background: '#ffffff33'
    },
    '&:focus': {
      background: '#ffffff33'
    }
  },
  checkboxesContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      marginRight: '60px !important'
    },
    '& span[class*="MuiFormControlLabel-label"]': {
      fontSize: '0.8rem'
    },
    '& label:first-child': {
      marginLeft: '0px !important'
    }
  },
  outerCircle: {
    border: '2px solid #ffffff33',
    borderRadius: '50%',
    height: '1em',
    width: '1em',
    position: 'relative'
  },
  innerCircle: {
    position: 'absolute',
    background: '#ffffff33',
    borderRadius: '50%',
    height: '0.3em',
    width: '0.3em',
    top: '50%',
    left: '50%',
    margin: '-0.15em 0em 0em -0.15em'
  },
  innerCircleWithColor: {
    background: '#f36'
  }
});

const SaveExcelModal = ({
  apiRequestStatus,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport,
  classes,
  scheduledReports,
  scheduledReportsMetaData,
  dispatchUpdateScheduledReport,
  dispatchPostScheduledReport,
  dispatchDeleteScheduledReport,
  isModalOpen,
  setIsModalOpen,
  filtersToRender
}) => {
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });
  const [currentApiCall, setCurrentApiCall] = useState(undefined);
  const [deleteReportDetail, setDeleteReportDetail] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [dropdowns, setDropdowns] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [emails, setEmails] = useState([]);
  const [checked, setChecked] = useState('');
  const [isEditing, setIsEditing] = useState({
    status: false,
    reportId: undefined,
    report: undefined
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const initialSelectedDate = {
    start: getPreviousDate(1, 'days', false),
    end: getPreviousDate(0, 'days', false)
  };

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleOKButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    dispatchDeleteScheduledReport({
      report_id: [deleteReportDetail.id]
    });
    setCurrentApiCall('delete');
    setDeleteReportDetail(null);
  };

  const handleCancelButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    setDeleteReportDetail(null);
  };

  const onChangeHandler = value => {
    setSearchInputValue(value);
  };

  useEffect(() => {
    if (
      apiRequestStatus.postScheduledReport.status ===
        enums.apiRequest.FAILURE ||
      apiRequestStatus.updateScheduledReport.status ===
        enums.apiRequest.FAILURE ||
      apiRequestStatus.deleteScheduledReport.status === enums.apiRequest.FAILURE
    ) {
      setShowErrorMessage(true);
    }
    if (
      apiRequestStatus.postScheduledReport.status ===
        enums.apiRequest.SUCCESS ||
      apiRequestStatus.updateScheduledReport.status === enums.apiRequest.SUCCESS
    ) {
      handleReset();
    }
    if (
      apiRequestStatus.postScheduledReport.status ===
        enums.apiRequest.SUCCESS ||
      apiRequestStatus.updateScheduledReport.status ===
        enums.apiRequest.SUCCESS ||
      apiRequestStatus.deleteScheduledReport.status === enums.apiRequest.SUCCESS
    ) {
      setShowErrorMessage(true);
    }
  }, [apiRequestStatus]);

  useEffect(() => {
    if (!isEmpty(filtersToRender)) {
      let fl = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filtersToRender)) {
        fl = { ...fl, [key]: value.value };
      }
      setDropdowns(fl);
    }
  }, [filtersToRender]);

  const handleSave = () => {
    let fl = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(dropdowns)) {
      fl = { ...fl, [key]: value.value };
    }
    const selectedDate = dateRange.fl_start_date.split('/');
    const newDate = new Date(
      selectedDate[2],
      selectedDate[1] - 1,
      selectedDate[0]
    );
    const result = {
      filters: fl,
      name: searchInputValue.trim(),
      subscribed_users: emails.map(email => email.label),
      frequency: checked,
      first_report_date: newDate.getTime() / 1000
    };
    if (isEditing.status) {
      const editResult = {
        filters: {
          ...isEditing.report.filters,
          ...fl
        },
        name: searchInputValue.trim(),
        subscribed_users: emails.map(email => email.label),
        frequency: checked,
        first_report_date: newDate.getTime() / 1000
      };
      dispatchUpdateScheduledReport({
        report_id: isEditing.reportId,
        ...editResult
      });
      setCurrentApiCall('update');
    } else {
      dispatchPostScheduledReport(result);
      setCurrentApiCall('post');
    }
  };

  const handleReset = () => {
    if (!isEmpty(filtersToRender)) {
      let fl = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(filtersToRender)) {
        fl = { ...fl, [key]: value.value };
      }
      setDropdowns(fl);
    }
    setSearchInputValue('');
    setDateRange({});
    setEmails([]);
    setChecked({});
    setIsEditing({
      status: false,
      reportId: undefined,
      report: undefined
    });
  };

  const handleDateChange = date => {
    setDateRange(date);
  };

  const handleScheduledReportEdit = report => {
    setIsEditing({
      status: true,
      reportId: report.id,
      report
    });
    const reportFiltersKeys = Object.keys(report.filters);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of reportFiltersKeys) {
      if (Object.prototype.hasOwnProperty.call(dropdowns, key)) {
        setDropdowns(prevDropdowns => {
          return {
            ...prevDropdowns,
            // [key]: { value: report.filters[key], label: report.filters[key] }
            [key]: {
              value: report.filters[key],
              label: filtersToRender[key].options.filter(
                option => option.value === report.filters[key]
              )[0].label
            }
          };
        });
      }
    }
    setSearchInputValue(report.name);
    setDateRange({
      fl_start_date: convertTimestampToDate(report.first_report_date),
      label: formatDate(new Date(+report.first_report_date * 1000), true)
    });
    setEmails(
      report.subscribed_users.map(user => ({ label: user, value: user }))
    );
    setChecked(report.frequency);
  };

  const handleScheduledReportDelete = reportDetail => {
    setDeleteReportDetail(reportDetail);
    setSnackBarState({
      isOpen: true,
      message: `Are you sure, you want to delete this?`,
      severity: 'error'
    });
  };

  const handleCheckboxChange = event => {
    setChecked(event.target.value);
  };

  const convertTimestampToDate = timestamp => {
    const d = new Date(+timestamp * 1000);
    const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    return date;
  };

  const closeExcelModal = () => {
    setIsModalOpen(false);
    handleReset();
    setShowErrorMessage(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={snackBarState.isOpen}
        onClose={handleSnackBarClose}
        aria-labelledby="confirm-delete-report-modal"
        aria-describedby="modal to confirm delete report"
      >
        <Alert
          elevation={6}
          variant="filled"
          action={
            <>
              <MuiButton color="inherit" size="small" onClick={handleOKButton}>
                OK
              </MuiButton>
              <MuiButton
                color="inherit"
                size="small"
                onClick={handleCancelButton}
              >
                Cancel
              </MuiButton>
            </>
          }
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Modal>
      <Modal
        open={isModalOpen}
        onClose={() => closeExcelModal()}
        className={classes.modal}
        aria-labelledby="save-excel-modal"
        aria-describedby="modal to save excel"
      >
        <div className={`${classes.modalContent} saveExcelModal`}>
          <Modal
            open={
              apiRequestStatus.postScheduledReport.status ===
                enums.apiRequest.REQUEST ||
              apiRequestStatus.updateScheduledReport.status ===
                enums.apiRequest.REQUEST ||
              apiRequestStatus.deleteScheduledReport.status ===
                enums.apiRequest.REQUEST
            }
            className={classes.modal}
          >
            <Loader color="primary" simple circular />
          </Modal>
          <VSpacer amount={20} />
          {showErrorMessage ? (
            <>
              {currentApiCall === 'post' &&
              apiRequestStatus.postScheduledReport.status ===
                enums.apiRequest.FAILURE ? (
                <div
                  className={`${classes.messageContainer} ${classes.errorMessage}`}
                >
                  {apiRequestStatus.postScheduledReport.error.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}

              {currentApiCall === 'update' &&
              apiRequestStatus.updateScheduledReport.status ===
                enums.apiRequest.FAILURE ? (
                <div
                  className={`${classes.messageContainer} ${classes.errorMessage}`}
                >
                  {apiRequestStatus.updateScheduledReport.error.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}

              {currentApiCall === 'delete' &&
              apiRequestStatus.deleteScheduledReport.status ===
                enums.apiRequest.FAILURE ? (
                <div
                  className={`${classes.messageContainer} ${classes.errorMessage}`}
                >
                  {apiRequestStatus.deleteScheduledReport.error.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}

              {currentApiCall === 'post' &&
              apiRequestStatus.postScheduledReport.status ===
                enums.apiRequest.SUCCESS ? (
                <div
                  className={`${classes.messageContainer} ${classes.successMessage}`}
                >
                  {postScheduledReport.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}
              {currentApiCall === 'update' &&
              apiRequestStatus.updateScheduledReport.status ===
                enums.apiRequest.SUCCESS ? (
                <div
                  className={`${classes.messageContainer} ${classes.successMessage}`}
                >
                  {updateScheduledReport.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}
              {currentApiCall === 'delete' &&
              apiRequestStatus.deleteScheduledReport.status ===
                enums.apiRequest.SUCCESS ? (
                <div
                  className={`${classes.messageContainer} ${classes.successMessage}`}
                >
                  {deleteScheduledReport.data.message}
                  <div className={classes.messageIconContainer}>
                    <CloseIcon onClick={() => setShowErrorMessage(false)} />
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          <div className={classes.row}>
            {Object.keys(filtersToRender).map(optionKey => (
              <div style={{ marginRight: '20px' }}>
                <Dropdown
                  key={optionKey}
                  width={200}
                  title={filtersToRender[optionKey].name}
                  handleValueChange={data => {
                    setDropdowns(prevState => ({
                      ...prevState,
                      [optionKey]: data
                    }));
                  }}
                  options={filtersToRender[optionKey].options}
                  value={
                    dropdowns[optionKey] || filtersToRender[optionKey].value
                  }
                  useMenuPortalTarget={false}
                />
              </div>
            ))}
            <div className={classes.closeIconContainer}>
              <Button
                color="dark"
                round
                justIcon
                onClick={() => closeExcelModal()}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
          <VSpacer amount={20} />
          <div className={classes.row}>
            <div
              className={classes.col}
              style={{
                marginTop: scheduledReportsMetaData.frequency ? '-25px' : '0px'
              }}
            >
              <div className={classes.inputLabel}>
                <Typography weight={400} size={14}>
                  Report Name
                </Typography>
              </div>
              <InputField
                style={{ width: '300px' }}
                onChange={e => onChangeHandler(e.target.value)}
                id="search"
                type="text"
                value={searchInputValue}
                colorTheme="dark"
                fullWidth
                variant="outlined"
                placeholder="Type a name"
                helperText={
                  isEditing.status &&
                  scheduledReports.some(
                    report =>
                      report.name === searchInputValue.trim() &&
                      isEditing.reportId !== report.id
                  )
                    ? 'name already exists'
                    : !isEditing.status &&
                      scheduledReports.some(
                        report => report.name === searchInputValue.trim()
                      )
                    ? 'name already exists'
                    : ''
                }
                error={
                  isEditing.status
                    ? scheduledReports.some(
                        report =>
                          report.name === searchInputValue.trim() &&
                          isEditing.reportId !== report.id
                      )
                    : scheduledReports.some(
                        report => report.name === searchInputValue.trim()
                      )
                }
              />
            </div>
            {scheduledReportsMetaData.frequency ? (
              <div style={{ marginLeft: '90px' }}>
                <div className={classes.inputLabel}>
                  <Typography weight={400} size={14}>
                    Frequency
                  </Typography>
                </div>
                <div className={classes.checkboxesContainer}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                    >
                      {scheduledReportsMetaData.frequency &&
                        scheduledReportsMetaData.frequency.map(frequency => (
                          <FormControlLabel
                            onChange={handleCheckboxChange}
                            value={frequency.name}
                            control={
                              <Radio
                                name={frequency.name}
                                checked={checked === frequency.name}
                                checkedIcon={
                                  <div className={classes.outerCircle}>
                                    <div
                                      className={`${classes.innerCircle} ${classes.innerCircleWithColor}`}
                                    />
                                  </div>
                                }
                                icon={
                                  <div className={classes.outerCircle}>
                                    <div className={classes.innerCircle} />
                                  </div>
                                }
                              />
                            }
                            label={frequency.label}
                            labelPlacement="bottom"
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ) : null}
          </div>
          <VSpacer amount={20} />
          <div className={classes.row}>
            <Dropdown
              width={300}
              title="Enter multiple email to send excel"
              handleValueChange={data => setEmails(data)}
              options={
                scheduledReportsMetaData.users
                  ? scheduledReportsMetaData.users.map(option => ({
                      label: option.email,
                      value: option.username
                    }))
                  : null
              }
              placeholder="Name@email.com"
              isMulti
              value={emails}
              useMenuPortalTarget={false}
            />
            <TimeFilter
              useRange={false}
              title="First Report Date"
              options={options.options}
              value={dateRange}
              useMenuPortalTarget={false}
              parentComponentClass=".saveExcelModal"
              defaultDateRange={initialSelectedDate}
              handleDateChange={date => handleDateChange(date)}
              minSelectableDate={new Date()}
              maxSelectableDate={new Date(2050, 11, 31)}
            />
            <HSpacer amount={170} />
            <div>
              <VSpacer amount={30} />
              <Button
                className={classes.button}
                onClick={handleSave}
                disabled={
                  (isEditing.status
                    ? scheduledReports.some(
                        report =>
                          report.name === searchInputValue.trim() &&
                          isEditing.reportId !== report.id
                      )
                    : scheduledReports.some(
                        report => report.name === searchInputValue.trim()
                      )) ||
                  searchInputValue.trim() === '' ||
                  isEmpty(checked) ||
                  isEmpty(emails) ||
                  isEmpty(dateRange)
                }
              >
                Save
              </Button>
            </div>
            <HSpacer amount={20} />
            <div>
              <VSpacer amount={30} />
              <Button className={classes.resetButton} onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
          <VSpacer amount={20} />
          <div className={classes.footer}>
            <div className={classes.footerHeading}>
              <Typography weight={400} size={16}>
                Scheduled Reports
              </Typography>
            </div>
            <VSpacer amount={20} />
            {isEmpty(scheduledReports) ? (
              <>
                <Typography size={14} align="center">
                  No reports available
                </Typography>
              </>
            ) : (
              <>
                <div className={classes.footerHeader}>
                  <div className={classes.reportCol}>
                    <Typography size={14}>Next Due Date</Typography>
                  </div>
                  <div className={classes.reportCol}>
                    <Typography size={14}>Report Name</Typography>
                  </div>
                  <div className={classes.reportCol}>
                    <Typography size={14}>Frequency</Typography>
                  </div>
                </div>
                <div className={classes.footerContent}>
                  {scheduledReports.map(report => {
                    return (
                      <div className={`${classes.footerRow} reportRow`}>
                        <div className={classes.reportCol}>
                          <Typography size={14}>
                            {convertTimestampToDate(report.first_report_date)}
                          </Typography>
                        </div>
                        <div className={classes.reportCol}>
                          <Typography size={14}>{report.name}</Typography>
                        </div>
                        <div className={classes.reportCol}>
                          <Typography size={14}>
                            {scheduledReportsMetaData &&
                              scheduledReportsMetaData.frequency &&
                              scheduledReportsMetaData.frequency.filter(
                                fr => fr.name === report.frequency
                              )[0].label}
                          </Typography>
                        </div>
                        <div className={classes.reportCol}>
                          <Button
                            color="dark"
                            round
                            justIcon
                            onClick={() => handleScheduledReportEdit(report)}
                          >
                            <EditIcon />
                          </Button>
                        </div>
                        <div className={classes.reportCol}>
                          <Button
                            color="dark"
                            round
                            justIcon
                            onClick={() => handleScheduledReportDelete(report)}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <VSpacer amount={20} />
        </div>
      </Modal>
    </>
  );
};

SaveExcelModal.propTypes = {
  classes: object.isRequired,
  scheduledReports: array.isRequired,
  scheduledReportsMetaData: object.isRequired,
  dispatchDeleteScheduledReport: func.isRequired,
  dispatchPostScheduledReport: func.isRequired,
  dispatchUpdateScheduledReport: func.isRequired,
  isModalOpen: bool.isRequired,
  setIsModalOpen: func.isRequired,
  filtersToRender: object.isRequired,
  apiRequestStatus: object.isRequired,
  postScheduledReport: object.isRequired,
  updateScheduledReport: object.isRequired,
  deleteScheduledReport: object.isRequired
};

export default withStyles(styles)(SaveExcelModal);

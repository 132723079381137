import React, { useEffect, useState } from 'react';
import { array, object, func, number, bool, string } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';

import Button from 'components/Button/Button'; // assets
import {
  EditIcon,
  DownloadAltIcon,
  RefreshIcon,
  ResizeIcon
} from 'assets/icons/appIcons';
import { scrollbar } from 'assets/jss/material-ui/material-ui';
// utils
import { PermissionsEnum } from 'pages/Admin/enums';
import { hasPermission } from 'pages/Admin/utils';
// views
import ReferenceImageUpload from './ReferenceImageUpload';
import PaginationComponent from '../Table/PaginationComponent';
import TableComponent from '../Table/TableComponent';
import SearchComponent from '../SearchComponent';

const ITEM_HEIGHT = 48;

const styles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonGroup: {
    '& button': {
      marginLeft: '10px'
    }
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pagination: {
    marginTop: 10
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button': {
      padding: '8px 20px !important',
      textTransform: 'capitalize !important',
      marginLeft: '10px'
    }
  },
  grayButton: {
    // background: '#27293d',
    background: theme.palette.type === 'light' ? '#18c7fe' : '#27293d',
    color: theme.palette.type === 'light' && '#0f0d36',
    boxShadow: 'none !important',
    padding: '8px 20px !important',
    textTransform: 'capitalize !important',
    '&:hover': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && '#0f0d36'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && '#0f0d36'
    }
  },
  modalContent: {
    color: '#94949a',
    backgroundColor: '#1e1e2b',
    margin: 80,
    outline: 'none',
    overflow: 'auto',
    ...scrollbar,
    scrollbarColor:
      theme.palette.type === 'light'
        ? '#f5f5f5 transparent'
        : 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5' : 'rgb(65, 65, 66)',
      borderRadius: 8
    },
    padding: '12px 50px !important'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  resizeButton: {
    '& svg:nth-of-type(1)': {
      transform: 'rotate(90deg)'
    }
  },
  inlineBlock: {
    display: 'inline-block',
    '& button': {
      background: theme.palette.type === 'light' && '#18c7fe',
      '&:hover': {
        background: theme.palette.type === 'light' && '#18c7fe'
      },
      '&:focus': {
        background: theme.palette.type === 'light' && '#18c7fe'
      }
    }
  }
});

const useDebounce = true;

const DisplayPage = ({
  pageData,
  stickerValues,
  pageTotal,
  getPageData,
  getStickerValues,
  getSearchData,
  menuProps,
  classes,
  handleReferenceImageUpload,
  referenceImageUploadData,
  pollTask,
  apiRequestStatus,
  downloadAllTemplates,
  loaderStatus,
  dashboardType
}) => {
  const [tableData, setTableData] = useState(pageData);
  const [refreshButtonStatus, setRefreshButtonStatus] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [editState, setEditState] = useState({});
  const [disableInteraction, setDisableInteraction] = useState(false);
  const [referenceModalState, setReferenceModalState] = useState({
    isOpen: false,
    stickerRow: {}
  });
  const [searchValue, setSearchValue] = useState({ col: '', value: '' });

  const [resizeState, setResizeState] = useState(false);

  const hasUpdatePermission =
    hasPermission(menuProps.permissions, PermissionsEnum.update) ||
    hasPermission(menuProps.permissions, PermissionsEnum.all);

  useEffect(() => {
    if (loaderStatus) {
      setDisableInteraction(true);
    } else {
      setDisableInteraction(false);
    }
  }, [loaderStatus]);

  useEffect(() => {
    if (pageData && menuProps.mandatory_cols) {
      const data = [];
      pageData.columns.forEach(dCol => {
        let isMandatory = false;
        menuProps.mandatory_cols.forEach(mCol => {
          isMandatory = isMandatory || dCol.key === mCol.key;
        });
        data.push({ ...dCol, is_mandatory: isMandatory });
      });
      setTableData({ ...pageData, columns: data });
    }
    setEditState({});
  }, [pageData, menuProps.mandatory_cols]);

  const handleEditIconClick = event => {
    setAnchorEl(event.currentTarget);
    setDisableInteraction(true);
  };

  const handleDownloadIconClick = event => {
    setDownloadAnchorEl(event.currentTarget);
    setDisableInteraction(true);
  };

  const handleEditMenuClose = () => {
    setAnchorEl(null);
    setEditState({});
    setDisableInteraction(false);
  };

  const handleDownloadMenuClose = () => {
    setDownloadAnchorEl(null);
    setDisableInteraction(false);
  };

  const handleEditData = () => {
    handleEditMenuClose();
    setEditState({ editExisting: true });
  };

  const onPageChangeHandler = params => {
    if (searchValue.value !== '') {
      getSearchData(
        searchValue.col,
        searchValue.value,
        params.offset,
        params.limit
      );
    } else {
      getPageData({ offset: params.offset, limit: params.limit });
    }
  };

  const handleDownloadAllTemplates = () => {
    handleDownloadMenuClose();
    downloadAllTemplates();
  };

  const discardEdits = () => {
    setEditState({});
    if (pageData && menuProps.mandatory_cols) {
      const data = [];
      pageData.columns.forEach(dCol => {
        let isMandatory = false;
        menuProps.mandatory_cols.forEach(mCol => {
          isMandatory = isMandatory || dCol.key === mCol.key;
        });
        data.push({ ...dCol, is_mandatory: isMandatory });
      });
      setTableData({ ...pageData, columns: data });
    }
  };

  const openReferenceUploadModal = rowData => {
    getStickerValues({ params: { sticker_id: rowData.id } });
    // handleEditMenuClose();
    setReferenceModalState({ isOpen: true, stickerRow: rowData });
    setEditState({ editExisting: true });
  };

  const onReferenceUploadSubmit = uploadData => {
    const {
      deletedImages = [],
      fileObjects = [],
      checkBoxState = {},
      stickerRow = {}
    } = uploadData;
    const params = {
      ...(fileObjects.length > 0 && { upload_file: fileObjects[0] }),
      ...checkBoxState,
      sticker: stickerRow.id,
      edit_data: JSON.stringify(
        deletedImages.length > 0 ? { DELETE_IMAGES: deletedImages } : {}
      )
    };
    handleReferenceImageUpload(params);
  };

  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  useEffect(() => {
    if (
      apiRequestStatus.uploadReferenceImages.request &&
      !apiRequestStatus.pollTask.start &&
      !apiRequestStatus.pollTask.stop
    ) {
      if (!referenceImageUploadData.task_id) {
        if (apiRequestStatus.uploadReferenceImages.error) {
          return setSnackBarState({
            isOpen: true,
            message: `Failed to upload. Please try again`,
            severity: 'error',
            duration: 4000
          });
        }
        if (apiRequestStatus.uploadReferenceImages.progress >= 0) {
          return setSnackBarState({
            isOpen: true,
            message: `Upload in progress. ${apiRequestStatus.uploadReferenceImages.progress}% / 100% uploaded`,
            severity: 'info'
          });
        }
      }
      if (referenceImageUploadData.task_id) {
        if (apiRequestStatus.uploadReferenceImages.success) {
          return setSnackBarState({
            isOpen: true,
            message: `Uploaded successfully. Please wait until it is processed`,
            severity: 'success',
            duration: 4000
          });
        }
      }
    }
    return () => {};
  }, [
    referenceImageUploadData,
    apiRequestStatus.uploadReferenceImages,
    apiRequestStatus.pollTask
  ]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'referenceImageUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please upload the file again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully`,
            severity: 'success',
            duration: 3000
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'excelUpload'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please upload the file again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully`,
            severity: 'success',
            duration: 3000
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    if (
      apiRequestStatus.pollTask.start &&
      pollTask.task.type === 'transaction'
    ) {
      if (apiRequestStatus.pollTask.error) {
        setSnackBarState({
          isOpen: true,
          message:
            apiRequestStatus.pollTask.message ||
            `Error happened while processing the data. Please try again`,
          severity: 'error'
        });
        return setDisableInteraction(false);
      }
      if (apiRequestStatus.pollTask.success && pollTask.data.result) {
        if (pollTask.data.result.status.toLowerCase() === 'success') {
          setSnackBarState({
            isOpen: true,
            message: `Processed successfully`,
            severity: 'success',
            duration: 3000
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'failed') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'error'
          });
          return setDisableInteraction(false);
        }
        if (pollTask.data.result.status.toLowerCase() === 'pending') {
          setSnackBarState({
            isOpen: true,
            message: pollTask.data.result.detail,
            severity: 'info'
          });
          return setDisableInteraction(true);
        }
      }
      return setDisableInteraction(true);
    }
    return () => {};
  }, [apiRequestStatus, pollTask]);

  useEffect(() => {
    setDisableInteraction(referenceModalState.isOpen);
  }, [referenceModalState.isOpen]);

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <ReferenceImageUpload
        isOpen={referenceModalState.isOpen && stickerValues.length > 0}
        stickerRow={referenceModalState.stickerRow}
        stickerValues={stickerValues}
        setOpen={setReferenceModalState}
        handleSubmit={onReferenceUploadSubmit}
      />
      <div className={classes.header}>
        <div className={classes.searchContainer}>
          <SearchComponent
            data={tableData ? tableData.columns : []}
            useDebounce={useDebounce}
            getPageData={getPageData}
            getSearchData={getSearchData}
            setSearchValue={setSearchValue}
            refreshButtonStatus={refreshButtonStatus}
            setRefreshButtonStatus={setRefreshButtonStatus}
            activeSideMenuItem={menuProps ? menuProps.name : ''}
          />
        </div>
        <div className={classes.buttonGroup}>
          <Tooltip title="Reset Column Width">
            <div className={classes.inlineBlock}>
              <Button
                className={classes.resizeButton}
                disabled={disableInteraction}
                color="dark"
                round
                justIcon
                onClick={() => setResizeState(true)}
              >
                <ResizeIcon />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Reset Data">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                color="dark"
                round
                justIcon
                onClick={() => {
                  getPageData({});
                  setRefreshButtonStatus(true);
                  setSearchValue({ col: '', value: '' });
                }}
              >
                <RefreshIcon />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Download">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                color="dark"
                round
                justIcon
                onClick={handleDownloadIconClick}
              >
                <DownloadAltIcon />
              </Button>
            </div>
          </Tooltip>
          <Menu
            id="download-menu"
            anchorEl={downloadAnchorEl}
            open={Boolean(downloadAnchorEl)}
            onClose={handleDownloadMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem onClick={handleDownloadAllTemplates}>
              Download All templates
            </MenuItem>
          </Menu>
          <Tooltip title="Edit">
            <div className={classes.inlineBlock}>
              <Button
                disabled={disableInteraction}
                aria-haspopup="true"
                aria-controls="edit-menu"
                color="dark"
                round
                justIcon
                onClick={handleEditIconClick}
              >
                <EditIcon />
              </Button>
            </div>
          </Tooltip>
          <Menu
            id="edit-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleEditMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem
              onClick={handleEditData}
              disabled={
                pageData
                  ? pageData.columns
                    ? pageData.columns < 1
                    : true
                  : true
              }
            >
              Edit existing data
            </MenuItem>
          </Menu>
        </div>
      </div>
      <TableComponent
        customColumn="upload"
        resizeState={resizeState}
        setResizeState={setResizeState}
        disableInteraction={disableInteraction}
        permissions={menuProps.permissions}
        uploadReferenceImages={openReferenceUploadModal}
        enableEdit={editState.editExisting && hasUpdatePermission}
        styleProps={{ height: window.innerHeight - 260 }}
        data={tableData}
        canEdit={hasUpdatePermission}
        overlay={{ message: loaderStatus ? 'loading data...' : '' }}
        loaderStatus={loaderStatus}
        limit={menuProps.row_limit}
        dashboardType={dashboardType}
      />
      <div className={classes.paginationContainer}>
        <PaginationComponent
          classNames={classes.pagination}
          offset={0}
          limit={menuProps.row_limit}
          total={pageTotal}
          onPageChange={params => onPageChangeHandler(params)}
        />
      </div>
      <div className={classes.footer}>
        {editState.editExisting && (
          <>
            <Button
              disabled={disableInteraction}
              className={classes.grayButton}
              onClick={discardEdits}
            >
              Close Editor
            </Button>
          </>
        )}
      </div>
    </>
  );
};

DisplayPage.propTypes = {
  classes: object.isRequired,
  pageTotal: number,
  referenceImageUploadData: object,
  apiRequestStatus: object,
  pollTask: object,
  pageData: object,
  stickerValues: array,
  menuProps: object,
  getPageData: func.isRequired,
  getStickerValues: func.isRequired,
  getSearchData: func.isRequired,
  handleReferenceImageUpload: func.isRequired,
  downloadAllTemplates: func.isRequired,
  loaderStatus: bool.isRequired,
  dashboardType: string.isRequired
};

DisplayPage.defaultProps = {
  pageData: undefined,
  pageTotal: 0,
  pollTask: {},
  stickerValues: [],
  referenceImageUploadData: {},
  apiRequestStatus: {},
  menuProps: {}
};

export default withStyles(styles)(DisplayPage);

import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem';
// google maps night theme
import nightMapStyles from 'assets/styles/googleMaps';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const language = client ? (client.includes('kcc-ru') ? 'ru' : 'en') : 'en';

// google maps api key
const GMAPS_KEY = process.env.REACT_APP_SHELF_GMAPS_KEY;

const options = {
  default: { styles: nightMapStyles },
  heatmap: {
    gradient: [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 255, 255, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(0, 127, 255, 1)',
      'rgba(0, 63, 255, 1)',
      'rgba(0, 0, 255, 1)',
      'rgba(0, 0, 223, 1)',
      'rgba(0, 0, 191, 1)',
      'rgba(0, 0, 159, 1)',
      'rgba(0, 0, 127, 1)',
      'rgba(63, 0, 91, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(255, 0, 0, 1)'
    ],
    radius: 10,
    opacity: 1
  },
  circle: {
    fillColor: '#FFEB3B',
    strokeColor: '#FFEB3B',
    strokeOpacity: 0.4,
    fillOpacity: 0.4
  },
  maps: {
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GMAPS_KEY}&v=3.exp&libraries=geometry,drawing,places,visualization&language=${language}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: (
      <GridItem xs={11} sm={11} md={11} style={{ height: 300 }} />
    ),
    mapElement: <div style={{ height: '100%' }} />
  }
};

export default options;

import { object } from 'prop-types';
// library to encrypt user id
import SHA512 from 'crypto-js/sha512';
import HMACSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
// core functions
import Cookies from 'universal-cookie';
import ls from 'lib/core/storageFactory';
import { setBaseUrl } from 'api';

const cookies = new Cookies();

const SSOLoginSuccess = ({ match: { params } }) => {
  const redirectLogin = () => {
    const profile = JSON.parse(decodeURIComponent(params.data));

    ls.set('isLoggedIn', true);
    ls.set('firstLogin', true);
    ls.set('authToken', params.token);
    ls.set('domain', params.client);
    ls.set('authProfile', profile);
    ls.set('client', params.client);
    ls.set(
      'baseURL',
      `https://${params.client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
    );
    setBaseUrl(
      `https://${params.client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
    );
    cookies.set(
      'trafficType',
      profile.username.includes('@infilect') ? 'internal' : 'external',
      {
        path: '/',
        domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
        sameSite: 'none',
        secure: 'true'
      }
    );
    const hashDigest = SHA512(profile.username);
    const encryptedUserId = Base64.stringify(
      HMACSHA512(hashDigest, process.env.REACT_APP_SHELF_GTAG_ENCKEY)
    );
    cookies.set('userId', encryptedUserId, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });
    if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
      const domain = ls.get('domain');
      // redirecting user to the tabaleu UI
      if (
        profile.analytics_redirect &&
        profile.analytics.redirect_url &&
        profile.analytics.has_access &&
        profile.dashboardType === 'tableau'
      ) {
        window.location.replace(profile.analytics.redirect_url);
      } else {
        window.location.replace(
          `https://${domain}.${process.env.REACT_APP_SHELF_REDIRECT_URI}/app`
        );
      }

      return null;
    }
    window.location.href = '/app';
    return null;
  };

  return redirectLogin();
};

SSOLoginSuccess.propTypes = {
  match: object.isRequired
};

export default SSOLoginSuccess;

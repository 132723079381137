/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
// lodash
import { isEqual, map } from 'lodash';
// core components
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Dropdown from 'components/Dropdown/Dropdown';
import GoogleMaps from 'maps/GoogleMaps';
import Icon from 'components/Icon/Icon';
import CardHeader from 'components/Card/CardHeader';
import Typography from 'components/Typography/Typography';
import { HSpacer } from 'components/Spacer/Spacer';

// assets
import { Pin, DownloadIcon } from 'assets/icons/appIcons';
// helpers
import GradientScale from 'utils/GradientScale';
import ls from 'lib/core/storageFactory';

const mapStyles = {
  wrapper: {
    display: 'flex'
  },
  actionButtons: {
    marginLeft: '20px'
  },
  granularities: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'start',
    paddingTop: 8
  }
};

class RegionMapComponent extends Component {
  state = {};

  client = ls.get('client');

  componentWillMount() {
    this.setActiveGranularity(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.granularities, nextProps.granularities)) {
      this.setActiveGranularity(nextProps);
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  setActiveGranularity = props => {
    const { granularities } = props;
    map(granularities, gr => {
      this.setState(() => ({ [gr.type]: gr.value }));
    });
  };

  handleGranularityClick = ({
    chartName,
    grType,
    selectedValue,
    option: grObject,
    value,
    type
  }) => {
    this.setState(() => ({ [grType]: selectedValue }));
    if (type === 'filter') {
      return this.props.handleGranularityClick({ value }, grType, {
        [chartName]: { [grType]: value }
      });
    }
    return this.props.handleGranularityClick(grObject, grType, {
      [chartName]: { [grType]: selectedValue }
    });
  };

  handleSlicerChange = ({ chartName, slicerType, value: selectedValue }) =>
    this.props.handleSlicerChange({
      selectedValue,
      slicerType,
      chartName
    });

  render() {
    const {
      classesList,
      classes,
      data,
      chartName,
      chartType,
      granularities,
      slicers,
      title,
      isPinned,
      usePin,
      useExport,
      handlePinClick,
      handleDataDownload,
      cardStyles
    } = this.props;

    const deDupedData = {
      ...data,
      datasets: data.datasets.filter(
        ({ location: { lat, lng } }) => lat !== 0 && lng !== 0
      )
    };
    return (
      <Card className={classesList.card} chart cardStyles={cardStyles}>
        <CardHeader map chart>
          <Typography className={classesList.title} cardTitle>
            {title}
          </Typography>
          <div className={classes.granularities}>
            {map(slicers, (slicer, key) => (
              <>
                <Dropdown
                  key={key}
                  title=""
                  handleValueChange={value => {
                    this.handleSlicerChange({
                      chartName,
                      slicerType: key,
                      value
                    });
                  }}
                  options={slicer.options}
                  value={slicer.value}
                />
                <HSpacer amount={20} />
              </>
            ))}

            {map(granularities, gr =>
              gr.filter_id ? (
                <Dropdown
                  key={gr.filter_id}
                  title=""
                  handleValueChange={value => {
                    this.handleGranularityClick({
                      chartName,
                      grType: gr.filter_id,
                      value,
                      type: 'filter'
                    });
                  }}
                  options={gr.options}
                  value={gr.value}
                />
              ) : (
                <React.Fragment key={gr.type}>
                  {map(gr.options, option => {
                    const active = this.state[gr.type] === option.value;
                    return !option.isHidden ? (
                      <Button
                        key={option.value}
                        onClick={() =>
                          this.handleGranularityClick({
                            chartName,
                            grType: gr.type,
                            selectedValue: option.value,
                            option
                          })
                        }
                        active={active}
                        outline
                        size="sm"
                        color="primary"
                      >
                        {option.label}
                      </Button>
                    ) : null;
                  })}
                </React.Fragment>
              )
            )}
          </div>
          <div className={classes.actionButtons}>
            {useExport ? (
              <Tooltip title="Download data" aria-label="Download data">
                <Icon color="light" id={`${chartName}-excel-download-button`}>
                  <DownloadIcon onClick={handleDataDownload} />
                </Icon>
              </Tooltip>
            ) : null}
            {usePin ? (
              <Tooltip
                title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
                aria-label={
                  isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
                }
              >
                <Icon color={isPinned ? 'primary' : 'light'}>
                  <Pin
                    onClick={() => handlePinClick({ isPinned, chartName })}
                  />
                </Icon>
              </Tooltip>
            ) : null}
          </div>
        </CardHeader>
        <CardBody map>
          {deDupedData.datasets.length === 0 ? (
            <Typography center color="light25" size="xs">
              {this.client.includes('kcc-ru')
                ? 'Нет данных для отображения'
                : 'No data to display'}
            </Typography>
          ) : (
            <div className={classes.wrapper}>
              <GoogleMaps
                displayMarkers={data.use_marker}
                chartType={chartType}
                data={deDupedData}
              />
              {chartType !== 'map_without_gradient' && (
                <GradientScale direction="vertical" />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

RegionMapComponent.propTypes = {
  data: PropTypes.object.isRequired,
  handleDataDownload: PropTypes.func.isRequired,
  usePin: PropTypes.bool,
  useExport: PropTypes.bool,
  cardStyles: PropTypes.object
};

RegionMapComponent.defaultProps = {
  usePin: false,
  useExport: false,
  cardStyles: {}
};

export default withStyles(mapStyles)(RegionMapComponent);

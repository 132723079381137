/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import {
  appTypes,
  chartTypes,
  storeExplorerTypes,
  cacheTypes
} from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  action: '',
  isPageLoading: false,
  excelReport: false,
  reportData: {},
  apiRequestStatus: {
    sessionData: {
      request: false,
      success: false,
      error: false,
      isRequestMade: false
    }
  },
  chartList: {
    isFetching: false,
    list: []
  },
  chartData: {
    isFetching: false,
    data: {}
  },
  error: {
    state: false,
    type: ''
  },
  images: {
    isFetching: false,
    list: []
  },
  variants: {
    list: []
  },
  stickerBoxes: {
    list: []
  },
  sessions: {
    list: []
  },
  store_explorer_settings: []
};

const reducer = createReducer(initialState, {
  [storeExplorerTypes.getShelfImage.clear]: state => {
    state.isPageLoading = false;
    state.images = { list: [], isFetching: false };
    state.variants = { list: [] };
    state.stickerBoxes = { list: [] };
  },
  [storeExplorerTypes.getShelfImage.request]: (state, action) => {
    const { type } = action;
    state.isPageLoading = true;
    state.images = { ...state.images, isFetching: true };
    state.action = type;
    state.apiRequestStatus.sessionData = {
      request: true,
      success: false,
      error: false,
      isRequestMade: true
    };
  },
  [storeExplorerTypes.getShelfImage.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    state.images = { list: response.data.images, isFetching: false };
    state.variants = { list: response.data.variants };
    state.stickerBoxes = { list: response.data.sticker_boxes };
    state.excelReport = response.data.excel_report;
    state.sessionId = response.data.session_id;
    state.error.state = false;
    state.store_explorer_settings = response.data.store_explorer_settings;
    state.apiRequestStatus.sessionData = {
      request: false,
      success: true,
      error: false,
      isRequestMade: true
    };
  },
  [storeExplorerTypes.getShelfImage.failure]: (state, action) => {
    state.isPageLoading = false;
    state.images = { list: [], isFetching: false };
    state.variants = { list: [] };
    state.stickerBoxes = { list: [] };
    state.error = {
      state: true,
      type: action.type
    };
    state.apiRequestStatus.sessionData = {
      request: false,
      success: false,
      error: true,
      isRequestMade: true
    };
  },
  [appTypes.filterChange]: (state, action) => {
    if (action.payload.routeID === 'store-explorer') {
      state.isPageLoading = false;
      state.images = { list: [], isFetching: false };
      state.variants = { list: [] };
      state.stickerBoxes = { list: [] };
    }
  },
  [chartTypes.downloadChartData.request]: state => {
    state.isFetching = true;
    state.error = {
      state: false,
      type: ''
    };
    state.reportData = {};
  },
  [chartTypes.downloadChartData.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isFetching = false;
    state.reportData = { ...response.data };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.downloadChartData.failure]: (state, action) => {
    const { type } = action;
    state.isFetching = false;
    state.error = {
      state: true,
      type
    };
    state.isFetching = false;
    state.reportData = {};
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

/* eslint-disable react/prop-types */
/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { PropTypes } from 'prop-types';
import colors from 'charts/__variables';

const XAxisTickFormatter = ({
  x,
  y,
  payload,
  width,
  maxChars,
  lineHeight,
  fontSize,
  fill
}) => {
  const rx = new RegExp(`.{1,${maxChars}}`, 'g');
  const chunks = payload.value
    .replace(/-/g, ' ')
    .split(' ')
    .map(s => s.match(rx))
    .flat();
  const tspans = chunks.map((s, i) => (
    <tspan key={i + i * lineHeight} x={0} y={lineHeight} dy={i * lineHeight}>
      {s}
    </tspan>
  ));

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={x - 20}
        y={y + 20}
        width={width}
        height="auto"
        textAnchor="middle"
        fontSize={fontSize}
        fill={fill}
      >
        {tspans}
      </text>
    </g>
  );
};

const YAxisTickFormatter = ({ x, y, payload: { value }, fill }) => (
  <text x={x - 50} y={y} fill={fill}>
    {value}
  </text>
);

XAxisTickFormatter.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.number,
  maxChars: PropTypes.number,
  lineHeight: PropTypes.number,
  fontSize: PropTypes.number
};

XAxisTickFormatter.defaultProps = {
  x: 0,
  width: 50,
  maxChars: 10,
  lineHeight: 14,
  fontSize: 9,
  y: 0,
  payload: {},
  fill: colors.dark.axisStroke
};

YAxisTickFormatter.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  fill: PropTypes.string
};

YAxisTickFormatter.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
  fill: colors.dark.axisStroke
};

export { XAxisTickFormatter, YAxisTickFormatter };

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import {
  appTypes,
  cacheTypes,
  storeExplorerTypes
} from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  isPageLoading: false,
  error: {
    state: false,
    type: ''
  },
  filters: {
    isFetching: false,
    list: {}
  }
};

const reducer = createReducer(initialState, {
  [appTypes.getFilterList.request]: state => {
    state.isPageLoading = true;
    state.filters = {
      ...state.filters,
      isFetching: true,
      list: {}
    };
  },
  [appTypes.getFilterList.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    state.filters = {
      ...state.filters,
      isFetching: false,
      list: { ...state.filters.list, ...response.data }
    };
    state.error.state = false;
  },
  [appTypes.getFilterList.failure]: (state, action) => {
    state.isPageLoading = false;
    state.filters = { isFetching: false, list: {} };
    state.error = {
      state: true,
      type: action.type
    };
  },
  [appTypes.AIAccuracySessionFilter.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.filters = {
      ...state.filters,
      list: {
        ...state.filters.list,
        fl_session: { name: 'Session', options: [...response.data] }
      }
    };
    state.error.state = false;
  },
  [appTypes.AIAccuracySessionFilter.failure]: (state, action) => {
    state.isPageLoading = false;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [appTypes.routeChange]: state => {
    state.isPageLoading = false;
    const list = { ...state.filters.list };
    delete list.fl_store;
    state.filters = {
      ...state.filters,
      isFetching: true,
      list: { ...list }
    };
    state.error.state = false;
  },
  [storeExplorerTypes.getCurationStores.request]: state => {
    state.isPageLoading = false;
    const { list } = { ...state.filters };
    delete list.fl_store;
    state.filters = {
      ...state.filters,
      isFetching: true,
      list: { ...list }
    };
    state.error.state = false;
  },
  [storeExplorerTypes.getCurationStores.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    const storeList = { options: response.data, name: 'store' };
    storeList.options = storeList.options.map((el, i) => ({
      label: el.store_title,
      value: el.store_name,
      is_default: i === 0
    }));
    const { list } = { ...state.filters };
    state.filters = {
      ...state.filters,
      isFetching: false,
      list: {
        ...list,
        fl_store: storeList
      }
    };
    state.error.state = false;
  },
  [storeExplorerTypes.getCurationStores.failure]: (state, action) => {
    state.isPageLoading = false;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.getCurationSessions.request]: state => {
    state.isPageLoading = false;
    const { list } = { ...state.filters };
    delete list.fl_session;
    state.filters = {
      ...state.filters,
      isFetching: true,
      list: { ...list }
    };
    state.error.state = false;
  },
  [storeExplorerTypes.getCurationSessions.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    const sessionList = { options: response.data, name: 'session' };
    sessionList.options = sessionList.options.map((el, i) => ({
      label: el.label,
      value: el.session_id,
      is_default: i === 0
    }));
    state.filters = {
      ...state.filters,
      isFetching: false,
      list: { ...state.filters.list, fl_session: sessionList }
    };
    state.error.state = false;
  },
  [storeExplorerTypes.getCurationSessions.failure]: (state, action) => {
    state.isPageLoading = false;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

import React, { useState } from 'react';
import { bool, func } from 'prop-types';
// @material-ui/core
import { DropzoneDialogBase } from 'material-ui-dropzone';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiDropzoneArea: {
      root: {
        outline: 'none',
        backgroundColor: '#1e1e2b',
        minHeight: 130,
        border: '1px dashed',
        borderColor: 'rgb(189 174 174 / 34%)'
      },
      text: {
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 8,
        paddingTop: 0,
        '&>button': {
          textTransform: 'capitalize'
        },
        '&>button:nth-of-type(1)': {
          color: '#f36'
        },
        '&>button:nth-of-type(2)': {
          color: '#afa'
        },
        '&>button:nth-of-type(2):disabled': {
          color: 'gray'
        }
      }
    },
    MuiDropzonePreviewList: {
      root: {
        marginTop: 10
      }
    },
    MuiChip: {
      root: {
        backgroundColor: '#52596b !important',
        color: 'rgba(255,255,255,0.8)'
      }
    }
  }
});

const PackshotUpload = ({ setOpen, handleSubmit, isOpen }) => {
  const [fileObjects, setFileObjects] = useState([]);

  const closeDialog = () => {
    setFileObjects([]);
    setOpen(false);
  };

  const dialogTitle = () => (
    <>
      <span
        style={{
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: 14,
          fontWeight: 500
        }}
      >
        Upload packshots zip file
      </span>
      <IconButton
        style={{ right: '12px', top: '8px', position: 'absolute' }}
        onClick={() => closeDialog()}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <DropzoneDialogBase
          filesLimit={1}
          dialogTitle={dialogTitle()}
          previewText=""
          acceptedFiles={['.zip']}
          fileObjects={fileObjects}
          cancelButtonText="cancel"
          submitButtonText="submit"
          maxFileSize={50000000000}
          open={isOpen}
          onAdd={newFile => {
            setFileObjects(newFile);
          }}
          onDelete={() => {
            setFileObjects([]);
          }}
          onClose={() => closeDialog()}
          onSave={() => {
            handleSubmit(fileObjects);
            closeDialog();
          }}
          useChipsForPreview
          showPreviews
          showFileNamesInPreview
        />
      </MuiThemeProvider>
    </>
  );
};

PackshotUpload.propTypes = {
  isOpen: bool,
  setOpen: func,
  handleSubmit: func
};

PackshotUpload.defaultProps = {
  isOpen: false,
  setOpen: () => {},
  handleSubmit: () => {}
};

export default PackshotUpload;

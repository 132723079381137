import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Icon from 'components/Icon/Icon';
import Table from 'table/Table';
// assets
import { DownloadIcon } from 'assets/icons/appIcons';

const styles = theme => ({
  root: {
    maxWidth: 'calc(100vw - 18rem)',
    overflowX: 'scroll',
    display: 'flex'
  },
  toolbar: {
    marginBottom: '13px',
    marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0
  },
  imgWrapper: {},
  canvas: {
    maxHeight: 'calc(100vh - 100px)'
  },
  actionButtons: {
    marginLeft: 'auto'
  },
  card: {
    backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
    '& tr,th,td': {
      // backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
      color: theme.palette.type === 'light' && '#424242 !important'
    },
    '& th': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff !important'
    },
    '& p': {
      color: theme.palette.type === 'light' && '#767676 !important'
    }
  }
});

const SKUTable = ({
  classes,
  tableData,
  handleRowClick,
  handleDataDownload,
  onCheckBoxClick,
  useExport,
  cardStyles,
  dashboardPanelStyles
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card} chart cardStyles={cardStyles}>
          <CardHeader chart float="right">
            <div className={classes.actionButtons}>
              {useExport ? (
                <Tooltip title="Download data" aria-label="Download data">
                  <Icon color="light">
                    <DownloadIcon onClick={handleDataDownload} />
                  </Icon>
                </Tooltip>
              ) : null}
            </div>
          </CardHeader>
          <CardBody chart maxWidth="98%">
            <Table
              width="100%"
              useCheckBox
              onRowClick={handleRowClick}
              onCheckBoxClick={onCheckBoxClick}
              data={tableData}
              cardStyles={cardStyles}
              dashboardPanelStyles={dashboardPanelStyles}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

SKUTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleDataDownload: PropTypes.func,
  onCheckBoxClick: PropTypes.func.isRequired,
  useExport: PropTypes.bool,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object
};

SKUTable.defaultProps = {
  useExport: false,
  handleDataDownload: () => {},
  cardStyles: {},
  dashboardPanelStyles: {}
};

export default withStyles(styles)(SKUTable);

import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
// chart library for react
import { LineChart, Line, XAxis } from 'recharts';
// styles
import colors from 'charts/__variables';

const useColors = [
  colors.dark.green,
  colors.dark.purple,
  colors.dark.orange,
  colors.dark.lime,
  colors.dark.yellow,
  colors.dark.pink,
  colors.dark.blue,
  colors.dark.red,
  colors.dark.gray
];

const TinyLineChart = ({ data }) => (
  <LineChart width={100} height={80} data={data.dataSet}>
    {data.yAxisDataKey.map(item => (
      <Line
        key={item}
        dataKey={item}
        type="monotone"
        strokeWidth={2}
        stroke={useColors[1].primary}
        dot={false}
      />
    ))}
    <XAxis axisLine={false} dataKey={data.xAxisDataKey} tick={false} />
  </LineChart>
);

// component properties
TinyLineChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default TinyLineChart;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useImage from 'use-image';
import { Image, Rect, Label, Tag, Text } from 'react-konva';

import arrowRight from 'assets/svg/arrow_right.svg';
import arrowLeft from 'assets/svg/arrow_left.svg';
import arrowUp from 'assets/svg/arrow_up.svg';
import arrowDown from 'assets/svg/arrow_down.svg';

const closingBracketsRegEx = /(\))/gim;

const directionArrow = {
  top: arrowUp,
  up: arrowUp,
  down: arrowDown,
  bottom: arrowDown,
  left: arrowLeft,
  right: arrowRight
};

const Arrow = ({ arrow, ...rest }) => {
  const [image] = useImage(arrow);
  return image ? <Image {...rest} image={image} /> : null;
};

Arrow.propTypes = {
  arrow: PropTypes.any.isRequired
};

const ImageNode = ({ url, colors, type, scaleDownFactor, ...rest }) => {
  const [image] = useImage(url);
  return image ? (
    <>
      <Image {...rest} image={image} />
      <Rect
        stroke={type === 'shelf' ? colors.shelf : colors.display}
        strokeWidth={scaleDownFactor === 1 ? 3 : 1}
        {...rest}
      />
    </>
  ) : null;
};

ImageNode.propTypes = {
  colors: PropTypes.object.isRequired,
  scaleDownFactor: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

const Crops = ({
  boxes,
  activeCrop,
  image,
  handleBoxClick,
  offsetX,
  scaleDownFactor
}) => {
  return boxes.map((box, index) => {
    const relativePoints = {
      left:
        (box.left / image.width) * (image.canvasWidth / scaleDownFactor) +
        offsetX,
      right:
        (box.right / image.width) * (image.canvasWidth / scaleDownFactor) +
        offsetX,
      top: (box.top / image.height) * (image.canvasHeight / scaleDownFactor),
      bottom:
        (box.bottom / image.height) * (image.canvasHeight / scaleDownFactor)
    };
    const color =
      box.crop_id === activeCrop
        ? 'rgba(0, 189, 211)'
        : box.color?.length > 0
        ? box.color
        : 'rgba(68, 236, 9)';
    const opacity = ',0.3';
    const position = color.search(closingBracketsRegEx);
    const opaqueColor = [
      color.slice(0, position),
      opacity,
      color.slice(position)
    ].join('');

    return (
      <>
        {scaleDownFactor === 1 && box.text && (
          <Label
            key={`${index + box.photoID}label${scaleDownFactor}`}
            x={relativePoints.left}
            y={relativePoints.top}
          >
            <Tag fill="yellow" />
            <Text
              text={box.text}
              fontSize={10}
              stroke="#000"
              strokeWidth={1}
              padding={4}
            />
          </Label>
        )}
        <Rect
          key={`${index}${box.crop_id}box${scaleDownFactor}`}
          x={relativePoints.left}
          y={relativePoints.top}
          width={relativePoints.right - relativePoints.left}
          height={relativePoints.bottom - relativePoints.top}
          fill={opaqueColor}
          stroke={color}
          strokeWidth={scaleDownFactor === 1 ? 3 : 1}
          onClick={() => handleBoxClick({ box })}
        />
      </>
    );
  });
};

const StitchedImage = ({
  photoList,
  handleBoxClick,
  scaleDownFactor,
  colors,
  nativeProps
}) => {
  const [activeCrop, setActiveCrop] = useState(null);

  const onBoxClick = ({ box }) => {
    setActiveCrop(box.crop_id);
    handleBoxClick({ box });
  };

  let nextXPosition = 0;
  return (
    photoList &&
    photoList.map((image, i) => {
      const item = i > 0 ? photoList[i] : image;
      const photoType = image.type || 'shelf';
      const direction = item.direction ? item.direction.toLowerCase() : '';
      let displays = [];
      if (image.displays) {
        displays = [...image.displays];
      }

      const Node = (
        <>
          <ImageNode
            {...nativeProps}
            colors={colors}
            key={image.id * i}
            url={image.path}
            type={photoType}
            x={nextXPosition}
            y={0}
            scaleDownFactor={scaleDownFactor}
            width={item.canvasWidth / scaleDownFactor}
            height={item.canvasHeight / scaleDownFactor}
          />
          <Crops
            activeCrop={activeCrop}
            boxes={item.boxes}
            image={image}
            handleBoxClick={onBoxClick}
            offsetX={nextXPosition}
            scaleDownFactor={scaleDownFactor}
          />
          {scaleDownFactor === 1 &&
            displays &&
            displays.map((disp, index) => {
              return (
                <>
                  <Label
                    x={nextXPosition + 50}
                    y={
                      item.canvasHeight / scaleDownFactor +
                      10 +
                      (index === 0 ? 0 : index * 20 * 2)
                    }
                  >
                    <Tag fill="#ffffff" />
                    <Text
                      padding={4}
                      text={`${index + 1}. Display: ${disp.type},
    Brand: ${disp.brand}`}
                      fontSize={13}
                      strokeWidth={1}
                      stroke="#000"
                      fill="#000"
                    />
                  </Label>
                </>
              );
            })}
          {scaleDownFactor === 1 && displays && (
            <>
              <Rect
                x={nextXPosition}
                y={item.canvasHeight / scaleDownFactor + 10}
                width={30}
                height={30}
                fill="#5b773c"
              />
              {directionArrow[direction] && (
                <Arrow
                  arrow={directionArrow[direction]}
                  x={nextXPosition + 5}
                  y={item.canvasHeight / scaleDownFactor + 15}
                  width={20}
                  height={20}
                />
              )}
            </>
          )}
        </>
      );
      nextXPosition += item.canvasWidth / scaleDownFactor;
      return Node;
    })
  );
};

StitchedImage.propTypes = {
  photoList: PropTypes.array.isRequired,
  scaleDownFactor: PropTypes.number,
  useDisplayTag: PropTypes.bool,
  handleBoxClick: PropTypes.func,
  nativeProps: PropTypes.object
};

StitchedImage.defaultProps = {
  scaleDownFactor: 1,
  nativeProps: {},
  handleBoxClick: () => {},
  useDisplayTag: false
};

export default StitchedImage;

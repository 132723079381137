import { createSelector } from '@reduxjs/toolkit';
// accuracy store selector
const accuracyStoreSelector = state => state.accuracy;

const selectActiveSession = createSelector(
  [accuracyStoreSelector],
  ({ activeSession }) => activeSession
);

const selectApiRequestStatus = createSelector(
  [accuracyStoreSelector],
  ({ apiRequestStatus }) => apiRequestStatus
);

const selectPostScheduledReport = createSelector(
  [accuracyStoreSelector],
  ({ postScheduledReport }) => postScheduledReport
);

const selectUpdateScheduledReport = createSelector(
  [accuracyStoreSelector],
  ({ updateScheduledReport }) => updateScheduledReport
);

const selectDeleteScheduledReport = createSelector(
  [accuracyStoreSelector],
  ({ deleteScheduledReport }) => deleteScheduledReport
);

const selectSessionDetails = createSelector(
  [accuracyStoreSelector],
  ({ sessionDetails }) => sessionDetails
);

const selectScheduledReports = createSelector(
  [accuracyStoreSelector],
  ({ scheduledReports }) => scheduledReports
);

const selectScheduledReportsMetaData = createSelector(
  [accuracyStoreSelector],
  ({ scheduledReportsMetaData }) => scheduledReportsMetaData
);

const selectDownloadReport = createSelector(
  [accuracyStoreSelector],
  ({ downloadReport }) => downloadReport
);

const selectSessionList = createSelector(
  [accuracyStoreSelector],
  ({ sessionList }) => sessionList
);

const selectChartData = createSelector(
  [accuracyStoreSelector],
  ({ chartData }) => chartData
);

const selectChartList = createSelector(
  [accuracyStoreSelector],
  ({ chartList }) => chartList
);

const filterListSelector = state => state.accuracy.filters;

const selectFilterOptions = createSelector(
  [filterListSelector],
  ({ list }) => list
);

const filterStateSelector = state => state.accuracy.filters.currentState;

const layoutSelector = state => state.layoutStore.routes.layout;

const selectFilterState = createSelector(
  [filterStateSelector, layoutSelector],
  (filterState, layout) => {
    const configuredFilters =
      layout.filter(e => e.routeID === 'accuracy')?.[0].defaults || [];
    // remove filters which are not configure for the accuracy page
    const initialFilterState = {};
    configuredFilters.forEach(({ value: filterID }) => {
      if (filterState[filterID]) {
        initialFilterState[filterID] = filterState[filterID];
      }
    });
    return initialFilterState;
  }
);

export {
  selectActiveSession,
  selectSessionDetails,
  selectSessionList,
  selectFilterOptions,
  selectFilterState,
  selectChartList,
  selectChartData,
  selectApiRequestStatus,
  selectPostScheduledReport,
  selectUpdateScheduledReport,
  selectDeleteScheduledReport,
  selectScheduledReports,
  selectScheduledReportsMetaData,
  selectDownloadReport
};

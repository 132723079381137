const types = {
  clear: {},
  getStores: {
    request: 'GET_STORES',
    success: 'GET_STORES_SUCCESS',
    failure: 'GET_STORES_FAILURE'
  },
  getStoreSessions: {
    request: 'GET_STORE_SESSIONS',
    success: 'GET_STORE_SESSIONS_SUCCESS',
    failure: 'GET_STORE_SESSIONS_FAILURE'
  },
  getPhotos: {
    request: 'GET_PHOTOS',
    success: 'GET_PHOTOS_SUCCESS',
    failure: 'GET_PHOTOS_FAILURE',
    clear: 'APP_IMAGES_CLEAR'
  },
  downloadPhotos: {
    request: 'DOWNLOAD_PHOTOS',
    success: 'DOWNLOAD_PHOTOS_SUCCESS',
    failure: 'DOWNLOAD_PHOTOS_FAILURE',
    reset: 'DONWLOAD_PHOTOS_RESET'
  },
  getStoresRange: {
    request: 'GET_STORES_RANGE',
    success: 'GET_STORES_RANGE_SUCCESS',
    failure: 'GET_STORES_RANGE_FAILURE'
  },
  clearBulkStores: {
    request: 'CLEAR_BULK_STORES'
  },
  initializeAppImages: {
    request: 'INITIALIZE_APP_IMAGES'
  },
  initializeSuccessAPI: {
    request: 'INITiALIZE_SUCCESS_API'
  },
  getImageActionTypes: {
    request: 'GET_IMAGES_ACTION_TYPES_REQUEST',
    success: 'GET_IMAGES_ACTION_TYPES_SUCCESS',
    failure: 'GET_IMAGES_ACTION_TYPES_FAILURE'
  },
  setAppImageFilters: 'SET_APP_IMAGES_FILTERS'
};

export default types;

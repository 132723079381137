import React from 'react';
import PropTypes from 'prop-types';
import Konva from './Konva';

const IMAGE_HEIGHT = 500;

const Photos = ({
  list,
  dashboardType,
  tooltipStyles,
  dropdownStyles,
  dropdownTitleStyles,
  ...rest
}) => {
  return (
    <>
      <Konva
        {...rest}
        list={{
          images: list.map(el => ({
            ...el,
            canvasWidth: el.width * (IMAGE_HEIGHT / el.height),
            canvasHeight: IMAGE_HEIGHT
          }))
        }}
        dashboardType={dashboardType}
        tooltipStyles={tooltipStyles}
        dropdownStyles={dropdownStyles}
        dropdownTitleStyles={dropdownTitleStyles}
      />
    </>
  );
};

// component proptypes
Photos.propTypes = {
  list: PropTypes.array.isRequired,
  dashboardType: PropTypes.string,
  tooltipStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object
};

Photos.defaultProps = {
  dashboardType: '',
  tooltipStyles: {},
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

export default Photos;

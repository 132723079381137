import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { object, func, bool } from 'prop-types';

// components
import Loader from 'components/Loader/Loader';

// assets
import profileIcon from 'assets/svg/new_profile_icon.svg';
import mailIcon from 'assets/svg/mail_icon.svg';
import lockIcon from 'assets/svg/lock_icon.svg';
import ls from 'lib/core/storageFactory';
import { HmacSHA512, SHA512 } from 'crypto-js';
import Cookies from 'universal-cookie';
import Base64 from 'crypto-js/enc-base64';
import googleIcon from 'assets/svg/google_login_icon.svg';
import pingId from 'assets/images/pingId.png';
import Notification from 'components/Notification/Notification';

const cookies = new Cookies();

let hideNotification = true;

var authUrl = '';

window.authcallback = {};
window.authcallback.call = function(authenticated, data) {
  const profile = JSON.parse(data.replaceAll('&quot;', '"'));

  if (profile.status == 200) {
    ls.set('showResetPassword', false);
    ls.set('gauth', true);
    ls.set('authToken', profile.token);
    ls.set('isLoggedIn', true);
    ls.set('firstLogin', true);
    ls.set('authProfile', {
      email: profile.email,
      token: profile.token,
      fresh_support: profile.fresh_support,
      username: profile.username
    });

    cookies.set('showResetPassword', true, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });
    const hashDigest = SHA512(profile.username);
    const encryptedUserId = Base64.stringify(
      HmacSHA512(hashDigest, process.env.REACT_APP_SHELF_GTAG_ENCKEY)
    );
    cookies.set('userId', encryptedUserId, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });

    let pathname = '/app';

    if (cookies.get('prevPath')) {
      pathname = cookies.get('prevPath');

      if (cookies.get('searchPath')) {
        pathname = pathname + cookies.get('searchPath');
      }

      cookies.remove('prevPath', {
        path: '/',
        domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
        sameSite: 'none',
        secure: 'true'
      });
      cookies.remove('searchPath', {
        path: '/',
        domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
        sameSite: 'none',
        secure: 'true'
      });
    }

    if (pathname !== '/app') {
      const domain = ls.get('domain');
      window.location.replace(
        `https://${domain.toLowerCase()}.${
          process.env.REACT_APP_SHELF_REDIRECT_URI
        }${pathname}`
      );
    } else {
      window.location.replace('/app');
    }
    // if (authState.analyticsRedirect?.analyticsRedirect) {
    //   if (authState.analyticsRedirect.has_access) {
    //     window.location.replace(authState.analyticsRedirect.redirect_url);
    //   } else if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
    //     const domain = ls.get('domain');
    //     window.location.replace(
    //       `https://${domain.toLowerCase()}.${
    //         process.env.REACT_APP_SHELF_REDIRECT_URI
    //       }${pathname}`
    //     );
    //   } else {
    //     window.location.replace(pathname);
    //   }
    // } else if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
    //   const domain = ls.get('domain');
    //   window.location.replace(
    //     `https://${domain.toLowerCase()}.${
    //       process.env.REACT_APP_SHELF_REDIRECT_URI
    //     }${pathname}`
    //   );
    // } else {
    //   window.location.replace('/app');
    // }

    // window.location.replace('/app');
  } else {
    hideNotification = false;
  }
};

const InputRow = ({
  authState,
  handleClientValidation,
  handleLoginValidation,
  ssoDetails,
  isClientFieldActive,
  hasSSOLogin,
  validationError
}) => {
  const [inputValues, setInputValues] = useState({
    clientID: '',
    username: '',
    password: '',
    forgotPasswordEmail: ''
  });

  const validateClient = () => {
    if (!inputValues.clientID) {
      validationError('Enter client ID');
    } else {
      handleClientValidation({
        client: inputValues.clientID.trim().toLowerCase()
      });
    }
  };

  const validateLogin = () => {
    const regExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // email valdation logic added

    if (!inputValues.username || !inputValues.password) {
      validationError(
        !inputValues.username ? 'Enter username ' : 'Enter Password'
      );
    } else {
      handleLoginValidation({
        username: inputValues.username.trim(),
        password: inputValues.password.trim()
      });
    }
  };

  const handleInputChange = ({ value, field }) => {
    setInputValues(p => ({ ...p, [field]: value }));
  };

  const handleFormSubmit = ({ e, type }) => {
    e.preventDefault();

    if (type === 'login') validateLogin();
    if (type === 'client') validateClient();
  };
  // Taking user to client's login screen
  const hybridButtonClicked = () => {
    window.location = ssoDetails.link;
  };
  useEffect(() => {
    if (authState.client.auth_url) {
      authUrl = authState.client.auth_url;
    }
  }, [authState]);

  return (
    <>
      {!hideNotification ? (
        <Notification
          duration={3000}
          message={'Unbale to login.'}
          isOpen={!hideNotification}
          handleClose={() => (hideNotification = true)}
        />
      ) : null}
      {isClientFieldActive && (
        <form
          onSubmit={e => {
            handleFormSubmit({ e, type: 'client' });
          }}
        >
          <div className="inputRow clientInputRow">
            <label className="inputRowLable" for="client">
              Enter your Client ID
              <img src={profileIcon} alt="profile_icon"></img>
            </label>
            <div className="clientInputWrapper">
              <div
                className="inputOverlay"
                data-overlay-suffix={
                  '.' + process.env.REACT_APP_SHELF_API_REDIRECT_URI
                }
              >
                {inputValues.clientID ? inputValues.clientID : 'client'}
              </div>
              <input
                type="text"
                className="inputRowComponent"
                id="client-input"
                placeholder="client"
                onChange={e =>
                  handleInputChange({
                    field: 'clientID',
                    value: e.target.value
                  })
                }
                value={inputValues.clientID}
                autocomplete="on"
              ></input>
            </div>
          </div>
          <div className="loginContainerActions">
            <button
              className="actionButton"
              type="submit"
              disabled={inputValues.clientID.trim() === ''}
            >
              Continue
              {authState.apiStatus.client.request &&
                !authState.apiStatus.client.success &&
                !authState.apiStatus.client.error && (
                  <Loader
                    plain
                    simple
                    inline
                    circular
                    secondary
                    className="login-loader"
                  />
                )}
            </button>
          </div>
        </form>
      )}
      {!isClientFieldActive && (!hasSSOLogin || ssoDetails.showHybrid) ? (
        <>
          {' '}
          <form
            onSubmit={e => {
              handleFormSubmit({ e, type: 'login' });
            }}
          >
            <div className="inputRow">
              <label className="inputRowLable" for="username">
                Username/Email
                <img src={mailIcon} alt="mail_icon"></img>
              </label>
              <div className="clientInputWrapper">
                <input
                  type="text"
                  className="inputRowComponent"
                  id="username"
                  placeholder="Name@email.com"
                  onChange={e =>
                    handleInputChange({
                      field: 'username',
                      value: e.target.value
                    })
                  }
                  value={inputValues.username}
                ></input>
              </div>
            </div>
            <div className="inputRow">
              <label className="inputRowLable" for="username">
                Password
                <img src={lockIcon} alt="lock_icon"></img>
              </label>
              <div className="clientInputWrapper">
                <input
                  type="password"
                  className="inputRowComponent"
                  id="password"
                  placeholder="Type your password"
                  onChange={e =>
                    handleInputChange({
                      field: 'password',
                      value: e.target.value
                    })
                  }
                  value={inputValues.password}
                ></input>
              </div>
            </div>
            <div className="inputRow forgotPasswordRow">
              <Link to="/login/forgotPassword">Forgot Password?</Link>
            </div>
            <div className="loginContainerActions">
              <button
                className="actionButton"
                disabled={
                  inputValues.username.trim() === '' ||
                  inputValues.password.trim() === ''
                }
                type="submit"
              >
                Login
                {authState.apiStatus.login.request &&
                  !authState.apiStatus.login.success &&
                  !authState.apiStatus.login.error && (
                    <Loader
                      plain
                      simple
                      inline
                      circular
                      secondary
                      className="login-loader"
                    />
                  )}
              </button>
            </div>
          </form>
          {/* <button>
            {/* <a href={authState.client.auth_url}> */}
          {/* </a> */}
          <div className="ssoContainer">
            <div className="ssoWrapper">
              <div className="ssoDeviderWrapper">
                <div className="ssoDevider"></div>
                <div className="ssoDeviderText">OR</div>
              </div>
            </div>
            <b>Do you want to login via SSO?</b>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {ssoDetails.showHybrid && (
                <div
                  style={{
                    backgroundColor: '#e5e5e5',
                    height: '2rem',
                    width: '2rem',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    margin: '5px'
                  }}
                  onClick={hybridButtonClicked}
                >
                  <img
                    height="100%"
                    style={{ borderRadius: '4px' }}
                    src={ssoDetails.sso_logo ? ssoDetails.sso_logo : pingId}
                  ></img>
                </div>
              )}
              <div
                style={{
                  backgroundColor: '#e5e5e5',
                  height: '2rem',
                  width: '2rem',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  margin: '5px'
                }}
                onClick={() =>
                  window.open(
                    authUrl,
                    '',
                    'location=no,toolbar=no,width=500,height=600'
                  )
                }
              >
                <img src={googleIcon}></img>
              </div>
            </div>
            {/* {ssoDetails.lable && <b>{ssoDetails.lable} </b>} */}
            {/* <button onClick={hybridButtonClicked} className="actionButton">
              {ssoDetails.text}
            </button> */}
          </div>
        </>
      ) : null}
    </>
  );
};

InputRow.propTypes = {
  authState: object.isRequired,
  classes: object.isRequired,
  handleForgotPassword: func.isRequired,
  handleClientValidation: func.isRequired,
  submitForgotPasswordEmail: func.isRequired,
  handleLoginValidation: func.isRequired,
  forgotPassword: bool.isRequired,
  isClientFieldActive: bool.isRequired,
  ssoDetails: object.isRequired
};

export default InputRow;

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import { accuracyTypes } from 'redux-utils/types/index';
import { map, isEmpty } from 'lodash';

import enums from 'models/enums';

// initial reducer state
const initialState = {
  filters: {
    list: {},
    currentState: {}
  },
  chartList: [],
  chartData: {},
  sessionList: [],
  sessionDetails: {},
  activeSession: {},
  scheduledReports: [],
  scheduledReportsMetaData: {},
  downloadReport: undefined,
  apiRequestStatus: {
    getPageFilters: { status: enums.apiRequest.IDLE },
    getSessionList: { status: enums.apiRequest.IDLE },
    getSessionDetails: { status: enums.apiRequest.IDLE },
    getChartList: { status: enums.apiRequest.IDLE },
    getChartData: { status: enums.apiRequest.IDLE },
    getScheduledReports: { status: enums.apiRequest.IDLE },
    getScheduledReportsMetaData: { status: enums.apiRequest.IDLE },
    postScheduledReport: { status: enums.apiRequest.IDLE },
    updateScheduledReport: { status: enums.apiRequest.IDLE },
    deleteScheduledReport: { status: enums.apiRequest.IDLE },
    downloadReport: { status: enums.apiRequest.IDLE }
  }
};

const reducer = createReducer(initialState, {
  [accuracyTypes.filterChange]: (state, action) => {
    const { payload } = action;
    state.chartData = {};
    state.sessionList = [];
    state.sessionDetails = {};
    state.filters.currentState = { ...state.filters.currentState, ...payload };
  },
  [accuracyTypes.sessionChange]: (state, action) => {
    const { payload } = action;
    state.activeSession = payload;
  },
  [accuracyTypes.resetSessionDetails]: state => {
    state.sessionDetails = {};
  },
  [accuracyTypes.getFilters.request]: state => {
    state.filters.list = {};
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getFilters.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.filters.list = response.data;
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.SUCCESS
    };
    // logic to set the default value for filters on page load
    const initialFilterState = {};
    const getDefaultFilterOption = ({ options = [] }) => {
      const [valueFromApi] = options.filter(el => el.is_default === true);
      return valueFromApi || options[0];
    };
    map(response.data, (data, filterID) => {
      if (data && data.options) {
        initialFilterState[filterID] = {
          ...data,
          value: getDefaultFilterOption({
            options: data.options || []
          })
        };
      }
    });
    state.filters.currentState = initialFilterState;
  },
  [accuracyTypes.getFilters.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.filters.list = {};
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.getSessionList.request]: state => {
    state.sessionList = [];
    state.chartData = {};
    state.sessionDetails = {};
    state.apiRequestStatus.getSessionList = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getSessionList.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    [state.activeSession] = response.data;
    state.sessionList = response.data;
    state.apiRequestStatus.getSessionList = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getSessionList.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.sessionList = [];
    state.apiRequestStatus.getSessionList = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.getChartList.request]: state => {
    state.chartList = [];
    state.apiRequestStatus.getChartList = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getChartList.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.chartList = response.data;
    state.apiRequestStatus.getChartList = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getChartList.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.chartList = [];
    state.apiRequestStatus.getChartList = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.getChartData.request]: state => {
    state.chartData = {};
    state.apiRequestStatus.getChartData = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getChartData.success]: (state, action) => {
    const {
      payload: { response, params }
    } = action;
    state.chartData = {
      ...state.chartData,
      [params.chart_name]: { ...response.data[params.chart_name] }
    };
    state.apiRequestStatus.getChartData = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getChartData.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.chartData = {};
    state.apiRequestStatus.getChartData = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.getSessionDetails.request]: state => {
    state.sessionDetails = {};
    state.apiRequestStatus.getSessionDetails = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getSessionDetails.success]: (state, action) => {
    const { response } = action.payload;
    if (!isEmpty(state.sessionList)) {
      state.sessionDetails = response.data;
    }
    if (isEmpty(state.sessionList)) {
      state.chartData = {};
    }
    state.apiRequestStatus.getSessionDetails = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getSessionDetails.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.getSessionDetails = {
      request: false,
      success: false,
      error: true,
      message: error.message
    };
    state.sessionDetails = {};
  },
  [accuracyTypes.getScheduledReports.request]: state => {
    state.scheduledReports = [];
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getScheduledReports.success]: (state, action) => {
    const { response } = action.payload;
    state.scheduledReports = response.data;
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getScheduledReports.failure]: (state, action) => {
    const { error } = action.payload;
    state.scheduledReports = [];
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.getScheduledReportsMetaData.request]: state => {
    state.scheduledReportsMetaData = {};
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.getScheduledReportsMetaData.success]: (state, action) => {
    const { response } = action.payload;
    state.scheduledReportsMetaData = response.data;
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.getScheduledReportsMetaData.failure]: (state, action) => {
    const { error } = action.payload;
    state.scheduledReportsMetaData = {};
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [accuracyTypes.postScheduledReport.request]: state => {
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.postScheduledReport = undefined;
  },
  [accuracyTypes.postScheduledReport.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    if (response.data.is_success) {
      state.scheduledReports.push({
        filters: action.payload.filters,
        subscribed_users: action.payload.subscribed_users,
        frequency: action.payload.frequency,
        name: action.payload.name,
        first_report_date: action.payload.first_report_date,
        due_date: response.data.due_date,
        id: response.data.report_id
      });
    }
    state.postScheduledReport = response;
  },
  [accuracyTypes.postScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.postScheduledReport = undefined;
  },
  [accuracyTypes.updateScheduledReport.request]: state => {
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.updateScheduledReport = undefined;
  },
  [accuracyTypes.updateScheduledReport.success]: (state, action) => {
    const { response } = action.payload;
    if (response.data.is_success) {
      state.scheduledReports = state.scheduledReports.map(report => {
        if (report.id === action.payload.report_id) {
          return {
            filters: action.payload.filters,
            subscribed_users: action.payload.subscribed_users,
            frequency: action.payload.frequency,
            name: action.payload.name,
            first_report_date: action.payload.first_report_date,
            due_date: response.data.due_date,
            id: action.payload.report_id
          };
        }
        return report;
      });
    }
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    state.updateScheduledReport = response;
  },
  [accuracyTypes.updateScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.updateScheduledReport = undefined;
  },
  [accuracyTypes.deleteScheduledReport.request]: state => {
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.deleteScheduledReport = undefined;
  },
  [accuracyTypes.deleteScheduledReport.success]: (state, action) => {
    const { response, report_id: reportId } = action.payload;
    let updatedScheduledReports = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const id of reportId) {
      updatedScheduledReports = [
        ...updatedScheduledReports,
        ...state.scheduledReports.filter(report => report.id !== id)
      ];
    }
    state.scheduledReports = updatedScheduledReports;
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    state.deleteScheduledReport = response;
  },
  [accuracyTypes.deleteScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.deleteScheduledReport = undefined;
  },
  [accuracyTypes.downloadReport.request]: state => {
    state.downloadReport = undefined;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.REQUEST
    };
  },
  [accuracyTypes.downloadReport.success]: (state, action) => {
    const { response } = action.payload;
    state.downloadReport = response;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [accuracyTypes.downloadReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.downloadReport = undefined;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
  }
});

export default reducer;

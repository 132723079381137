// views
import UserProfileContainer from 'pages/UserProfile/UserProfileContainer';
import AppImagesContainer from 'pages/AppImages/AppImagesContainer';

const routes = {
  userProfile: {
    path: '/app/user-profile',
    component: UserProfileContainer,
    navbarName: 'User Profile'
  },
  'app-images': {
    path: '/feature/app-images',
    component: AppImagesContainer,
    navbarName: 'App Images'
  }
};

export default routes;

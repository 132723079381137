/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { map, omit } from 'lodash';
// action types
import { appTypes, chartTypes, cacheTypes } from 'redux-utils/types';
import ls from 'lib/core/storageFactory';

// initial reducer state
const initialState = {
  isFetching: false,
  error: {
    state: false,
    type: ''
  },
  charts: {
    isFetching: false,
    list: {}
  },
  reports: {},
  chartData: {}
};

const reducer = createReducer(initialState, {
  [appTypes.pinToDashboard.success]: (state, action) => {
    const {
      payload: {
        params: { chart_name: chartName },
        routeID
      }
    } = action;
    const index = state.charts.list[routeID].findIndex(
      ch => ch.name === chartName
    );
    if (index !== -1) {
      const pinnedChart = { ...state.charts.list[routeID][index] };
      pinnedChart.is_pinned = true;
      state.charts.list[routeID][index] = pinnedChart;
    }
  },
  [appTypes.unPinFromDashboard.success]: (state, action) => {
    const {
      payload: {
        params: { chart_name: chartName },
        routeID
      }
    } = action;
    const index = state.charts.list[routeID].findIndex(
      ch => ch.name === chartName
    );
    if (index !== -1) {
      const unPinnedChart = { ...state.charts.list[routeID][index] };
      unPinnedChart.is_pinned = false;
      state.charts.list[routeID][index] = unPinnedChart;
    }
  },
  [chartTypes.getChartList.request]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID }
      }
    } = action;
    state.isFetching = true;
    state.charts = {
      ...state.charts,
      isFetching: true,
      list: {
        ...state.charts.list,
        [routeID]: undefined
      }
    };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.getChartList.success]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID },
        response
      }
    } = action;
    state.isFetching = false;
    const data = map(response.data, chart => omit(chart, ['granularities']));
    state.charts = {
      ...state.charts,
      isFetching: false,
      list: {
        ...state.charts.list,
        [routeID]: [...data]
      }
    };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.getChartList.failure]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID }
      }
    } = action;
    state.isFetching = false;
    state.charts = {
      isFetching: false,
      list: {
        ...state.charts.list,
        [routeID]: []
      }
    };
    state.error = {
      state: true,
      type: action.type
    };
  },
  [chartTypes.getChartData.request]: state => {
    state.isFetching = true;
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.getChartData.success]: (state, action) => {
    const {
      payload: { routeID, response }
    } = action;
    state.isFetching = false;
    state.chartData = {
      ...state.chartData,
      [routeID]: {
        ...state.chartData[routeID],
        ...response.data
      }
    };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.getChartData.failure]: (state, action) => {
    const {
      payload: {
        request: {
          params: { chart_name: chartName },
          routeID
        }
      }
    } = action;
    state.isFetching = false;
    state.error = {
      state: true,
      type: action.type
    };
    state.isFetching = false;
    if (state.chartData[routeID]) {
      if (state.chartData[routeID][chartName]) {
        state.chartData = {
          ...state.chartData,
          [routeID]: {
            ...state.chartData[routeID],
            [state.chartData[routeID][chartName]]: {
              ...state.chartData[routeID][chartName],
              datasets: []
            }
          }
        };
      }
    }
  },
  [chartTypes.getStoreCount.success]: (state, action) => {
    const {
      payload: {
        params: { chart_name: chartName },
        routeID,
        response: {
          data: { count, title }
        }
      }
    } = action;
    state.isFetching = false;
    state.error = {
      state: true,
      type: action.type
    };
    state.isFetching = false;
    const client = ls.get('client');
    const countWord = client.includes('kcc-ru')
      ? 'Счетчик магазинов'
      : 'Store Count';
    const splitWord = client.includes('kcc-ru') ? '(Сче' : '(Sto';
    if (state.chartData[routeID]) {
      if (state.chartData[routeID][chartName]) {
        state.chartData = {
          ...state.chartData,
          [routeID]: {
            ...state.chartData[routeID],
            [chartName]: {
              ...state.chartData[routeID][chartName],
              title:
                count !== undefined && count !== null
                  ? `${state.chartData[routeID][chartName].title
                      .split(splitWord)[0]
                      .trim()} (${countWord}: ${count})`
                  : title || state.chartData[routeID][chartName].title
            }
          }
        };
      }
    }
  },
  [chartTypes.downloadChartData.request]: (state, action) => {
    const {
      payload: { routeID, params }
    } = action;
    state.isFetching = true;
    state.error = {
      state: false,
      type: ''
    };
    const reportState = { ...state.reports };
    if (reportState) {
      const routeReports = reportState[routeID];
      if (routeReports) {
        delete routeReports[params.chart_name];
      }
    }
    state.reports = { ...reportState };
  },
  [chartTypes.downloadChartData.success]: (state, action) => {
    const {
      payload: { routeID, response, params }
    } = action;
    state.isFetching = false;
    state.reports = {
      ...state.reports,
      [routeID]: {
        ...state.reports[routeID],
        [params.chart_name]: { ...response }
      }
    };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.downloadChartData.failure]: (state, action) => {
    const {
      payload: {
        request: {
          params: { chart_name: chartName },
          routeID
        }
      }
    } = action;
    state.isFetching = false;
    state.error = {
      state: true,
      type: action.type
    };
    state.isFetching = false;
    if (state.reports[routeID]) {
      if (state.reports[routeID][chartName]) {
        state.reports = {
          ...state.reports,
          [routeID]: {
            ...state.reports[routeID],
            [state.reports[routeID][chartName]]: {}
          }
        };
      }
    }
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

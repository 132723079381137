import React from 'react';
// library to concatenates classes
import classNames from 'classnames';
// library to set properties for components
import PropTypes from 'prop-types';
// material-ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import iconStyle from 'assets/jss/material-ui/views/iconsStyle';

/**
 * @class
 * @hideconstructor
 * @description Core Icon Component
 */
const RegularIcon = ({
  classes,
  children: Icon,
  disabled,
  size,
  color,
  info,
  className,
  id
}) => {
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.iconInfo]: info,
    [classes.disabled]: disabled,
    [classes.iconWrapper]: true,
    [className]: className
  });
  return (
    <div className={iconClasses} id={id}>
      {Icon}
    </div>
  );
};

RegularIcon.propTypes = {
  info: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
  color: PropTypes.oneOf(['orange', 'light', 'primary', '']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.string
};

RegularIcon.defaultProps = {
  info: false,
  color: '',
  size: 'sm',
  disabled: false,
  className: '',
  id: ''
};
export default withStyles(iconStyle)(RegularIcon);

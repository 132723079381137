import React from 'react';
import PropTypes from 'prop-types';

/**
 * @class
 * @hideconstructor
 * @description Horizontal divider component
 */
const HSpacer = ({ amount }) => (
  <div style={{ width: `${amount}px`, display: 'inline-flex' }} />
);

// component properties
HSpacer.propTypes = {
  /**
   * @type {number}
   * @description Horiontal space in pixels
   */
  amount: PropTypes.number
};

HSpacer.defaultProps = {
  amount: 10
};

/**
 * @class
 * @hideconstructor
 * @description - Vertical divider component
 */
const VSpacer = ({ amount }) => (
  <div style={{ height: `${amount}px`, width: '100%' }} />
);

// component properties
VSpacer.propTypes = {
  /**
   * @type {number}
   * @description - Vertical space in pixels
   */
  amount: PropTypes.number
};

VSpacer.defaultProps = {
  amount: 10
};

export { HSpacer, VSpacer };

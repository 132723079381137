import { createSelector } from '@reduxjs/toolkit';
import { getRouteID as getRouteIDKey } from 'lib/utils/getActiveRoute';
// store selectors
const getStoreExplorerStore = state => state.genericSE;
const getLayoutStore = state => state.layoutStore;

const getRouteID = () => {
  const {
    location: { pathname }
  } = window;
  return getRouteIDKey(pathname);
};

const selectLayout = createSelector([getLayoutStore], ({ routes: list }) => {
  const routeName =
    getRouteID() === 'redirect' ? 'store-explorer-v2' : getRouteID();
  return list.layout.length > 0
    ? list.layout.filter(l => l.routeID === routeName)[0]
    : [];
});

const getLayout = createSelector([getLayoutStore], ({ routes: list }) => {
  return list.layout;
});

const selectApiRequestStatus = createSelector(
  [getStoreExplorerStore],
  ({ apiRequestStatus }) => apiRequestStatus
);

const getIsRequestMade = createSelector(
  [getStoreExplorerStore],
  ({ apiRequestStatus }) => apiRequestStatus.sessionData.isRequestMade
);

const getIsFetchingFilter = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) => apiRequestStatus.filter.request
);

const selectFilters = createSelector(
  getStoreExplorerStore,
  ({ filters }) => filters
);

const isFetchingSessionData = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) => apiRequestStatus.sessionData.request
);

const isFetchingSessionDataSuccessful = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) => apiRequestStatus.sessionData.success
);

const getCurrentFilter = createSelector(
  getStoreExplorerStore,
  ({ filter }) => filter
);

const getBottomUpFilter = createSelector(
  getStoreExplorerStore,
  ({ bottomUpFilter }) => bottomUpFilter
);

const isFetchingFilter = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) =>
    apiRequestStatus.bottomUpFilter.request || apiRequestStatus.filter.request
);

const selectSessionData = createSelector(
  getStoreExplorerStore,
  ({ sessionData }) => sessionData
);

const selectChartData = createSelector(
  getStoreExplorerStore,
  ({ chartData }) => chartData
);

const selectChartList = createSelector(
  getStoreExplorerStore,
  ({ chartList }) => chartList
);

const selectReportData = createSelector(
  [getStoreExplorerStore],
  ({ reportData }) => reportData
);
// to fetch the filters
const selectSessionFilters = createSelector(
  [getStoreExplorerStore],
  ({ sessionFilters }) => sessionFilters
);

const selectChartFetched = createSelector(
  [getStoreExplorerStore],
  ({ apiRequestStatus }) => apiRequestStatus.chartData.fetchedChart
);

const selectChartListFetching = createSelector(
  [getStoreExplorerStore],
  ({ apiRequestStatus }) => apiRequestStatus.chartList.request
);

const getGranularitiesList = (state, props) =>
  state.genericSE.preferences.granularities[props.routeID];

const getFilterPreferences = (state, props) =>
  state.genericSE.preferences.filters[props.routeID];

const makeGetParamsValueList = () =>
  createSelector(
    [getFilterPreferences, getGranularitiesList],
    (filterPrefs, grPrefs) => {
      return {
        ...filterPrefs,
        ...grPrefs
      };
    }
  );

const selectFilterValues = createSelector(
  getStoreExplorerStore,
  ({ sessionFilterValues }) => sessionFilterValues
);

const sessionFilterValuesStatus = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) => apiRequestStatus.sessionFilterValuesStatus
);

export {
  getRouteID,
  selectApiRequestStatus,
  selectChartData,
  selectChartList,
  selectFilters,
  selectLayout,
  selectSessionData,
  selectReportData,
  selectSessionFilters,
  selectFilterValues,
  selectChartFetched,
  makeGetParamsValueList,
  sessionFilterValuesStatus,
  getCurrentFilter,
  getIsRequestMade,
  getIsFetchingFilter,
  isFetchingSessionData,
  isFetchingSessionDataSuccessful,
  getLayout,
  selectChartListFetching,
  getBottomUpFilter,
  isFetchingFilter
};

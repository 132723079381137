/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { array, func, object } from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from 'components/Typography/Typography';

const styles = theme => ({
  sideBarRoot: {
    paddingRight: theme.palette.type === 'dark' && 10,
    borderRight: theme.palette.type === 'dark' && '1px solid #373844',
    paddingTop: theme.palette.type === 'light' && 74
  },
  navItem: {
    paddingLeft: 10,
    display: 'flex',
    justifyContent: theme.palette.type === 'dark' ? 'start' : 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: theme.palette.type === 'dark' && '5px 0',
    height: theme.palette.type === 'dark' ? 40 : 60,
    width: '100%',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
    color: theme.palette.type === 'light' && '#2e2e2e',
    fontWeight: theme.palette.type === 'light' && 800,
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#27293d'
    },
    borderBottom: theme.palette.type === 'light' && '1px solid #75828308'
  },
  activeItem: {
    fontWeight: 800,
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#27293d',
    borderRight: theme.palette.type === 'light' && '2px solid #3475b6',
    color:
      theme.palette.type === 'light'
        ? '#2e2e2e'
        : 'rgba(255,255,255,0.8) !important'
  }
});

const SideBar = ({ classes, subPanel, activeSubRoute, handleItemClick }) => {
  return (
    <div className={classes.sideBarRoot}>
      {subPanel.map(item => (
        <Tooltip title={item.full_name}>
          <div
            onClick={() => handleItemClick(item)}
            className={`${classes.navItem} ${
              activeSubRoute.name === item.name ? classes.activeItem : ''
            }`}
            key={item.name}
          >
            <Typography
              size={13}
              className={`${classes.navItem} ${
                activeSubRoute.name === item.name ? classes.activeItem : ''
              }`}
              weight={activeSubRoute.name === item.name ? 800 : 400}
              color={
                activeSubRoute.name === item.name
                  ? 'rgba(255,255,255,0.8)'
                  : 'rgba(255,255,255,0.5)'
              }
            >
              {item.title}
            </Typography>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

SideBar.propTypes = {
  classes: object.isRequired,
  activeSubRoute: object,
  handleItemClick: func.isRequired,
  subPanel: array.isRequired
};

SideBar.defaultProps = {
  activeSubRoute: {}
};

export default withStyles(styles)(SideBar);

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import { genericSETypes } from 'redux-utils/types/index';
import { map, keys, each, pick, isEmpty } from 'lodash';

import grModel from 'models/granularityModel';

// initial reducer state
const initialState = {
  preferences: {
    filters: {},
    granularities: {}
  },
  filters: {},
  filter: {},
  bottomUpFilter: {},
  chartList: [],
  chartData: {},
  sessionData: {},
  reportData: {},
  apiRequestStatus: {
    chartData: {
      request: false,
      success: false,
      error: false,
      fetchedChart: []
    },
    chartList: { request: false, success: false, error: false },
    filters: { request: false, success: false, error: false },
    filter: { request: false, success: false, error: false },
    bottomUpFilter: { request: false, success: false, error: false },
    sessionData: {
      request: false,
      success: false,
      error: false,
      isRequestMade: false
    },
    reportData: { request: false, success: false, error: false },
    sessionFilterValuesStatus: {
      request: false,
      success: false,
      error: false,
      message: ''
    }
  },
  error: {
    state: false,
    type: ''
  },
  sessionFilters: null,
  sessionFilterValues: {}
};

const reducer = createReducer(initialState, {
  [genericSETypes.setSessionFilters]: (state, action) => {
    state.sessionFilters = action.payload;
  },
  [genericSETypes.getChartData.request]: (state, action) => {
    state.chartData = {};
    state.apiRequestStatus.chartData = {
      request: true,
      success: false,
      error: false,
      fetchedChart: [
        ...(state.apiRequestStatus.chartData?.fetchedChart || []),
        action.payload.params.chart_name
      ]
    };
  },
  [genericSETypes.sessionFilterValues.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.sessionFilterValues = response.data;
    state.apiRequestStatus.sessionFilterValuesStatus = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.sessionFilterValues.request]: state => {
    state.apiRequestStatus.sessionFilterValuesStatus = {
      request: true,
      success: false,
      error: false
    };
  },
  [genericSETypes.sessionFilterValues.error]: (state, action) => {
    state.apiRequestStatus.sessionFilterValuesStatus = {
      request: false,
      success: false,
      error: true,
      message: action.payload.error.error_message
        ? action.payload.error.error_message
        : 'Something went wrong'
    };
  },
  [genericSETypes.getChartData.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isFetching = false;
    state.chartData = {
      ...state.chartData,
      ...response.data
    };
    state.apiRequestStatus.chartData = {
      ...state.apiRequestStatus.chartData,
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.getBottomUpFilter.set]: (state, action) => {
    state.bottomUpFilter = action.payload;
  },
  [genericSETypes.getBottomUpFilter.request]: state => {
    state.bottomUpFilter = {};
    state.apiRequestStatus.bottomUpFilter = {
      request: true,
      success: false,
      error: false
    };
    // state.bottomUpFilter.incomingFilter = '';
  },
  [genericSETypes.getBottomUpFilter.success]: (state, action) => {
    const hashedOptions = action.payload.response.options.reduce(
      (obj, item) => {
        return {
          ...obj,
          [item.value]: item
        };
      },
      {}
    );
    state.bottomUpFilter[action.payload.response.filter_type] = {
      ...action.payload.response,
      hashedOptions
    };
    state.bottomUpFilter.incomingFilter = action.payload.response.filter_type;
    state.apiRequestStatus.bottomUpFilter = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.getBottomUpFilter.failure]: state => {
    state.bottomUpFilter = {};
    state.apiRequestStatus.bottomUpFilter = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.getChartData.failure]: (state, action) => {
    const {
      payload: {
        params: { chart_name: chartName }
      }
    } = action;
    if (state.chartData[chartName]) {
      state.chartData = {
        ...state.chartData,
        [state.chartData[chartName]]: {
          ...state.chartData[chartName],
          datasets: []
        }
      };
    }
    state.apiRequestStatus.chartData = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.getChartList.clear]: state => {
    state.chartList = [];
    state.apiRequestStatus.chartList = {
      request: false,
      success: false,
      error: false
    };
    state.apiRequestStatus.chartData.fetchedChart = [];
  },
  [genericSETypes.getChartList.request]: state => {
    state.chartList = [];
    state.apiRequestStatus.chartList = {
      request: true,
      success: false,
      error: false
    };
  },

  [genericSETypes.getChartList.success]: (state, action) => {
    const {
      payload: {
        params: { route_id: routeID },
        response: { data: chartList }
      }
    } = action;
    state.chartList = chartList;
    if (!isEmpty(state.preferences.granularities[routeID])) {
      return state;
    }
    const grList = {};
    each(chartList, chart => {
      const chartObject = pick(chart, [
        'granularities',
        'name',
        'params',
        'filters'
      ]);
      const grType = {};
      each(chartObject.granularities, e => {
        const options = map(e.options, obj => ({ ...obj, isHidden: false }));
        grType[e.type] = { ...e, options, value: options[0].value };
      });
      grList[chartObject.name] = grType;
      each(chartObject.filters, e => {
        const pickFrom = e.pick_from
          ? e.pick_from
          : e.filter_id.includes('brand')
          ? 'fl_category'
          : e.filter_id.includes('fl_posm')
          ? 'fl_channel'
          : 'fl_brand';
        if (pickFrom === 'fl_channel') {
          grType[e.filter_id] = {
            ...e,
            value: state.preferences.filters[routeID][pickFrom][e.filter_id][0]
          };
        } else {
          grType[e.filter_id] = {
            ...e,
            value: state.preferences.filters[routeID][pickFrom][e.filter_id][0]
          };
        }
      });
      grList[chartObject.name] = grType;
    });
    state.preferences.granularities[routeID] = {
      ...grList
    };

    state.apiRequestStatus.chartList = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.getChartList.failure]: state => {
    state.chartList = [];
    state.apiRequestStatus.chartList = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.getChartList.clear]: state => {
    state.chartList = [];
    state.apiRequestStatus.chartList = {
      request: false,
      success: false,
      error: false
    };
    state.apiRequestStatus.chartData.fetchedChart = [];
  },
  [genericSETypes.getFilter.request]: state => {
    state.filter = {};
    state.apiRequestStatus.filter = {
      request: true,
      success: false,
      error: false
    };
    // state.bottomUpFilter.incomingFilter = '';
  },
  [genericSETypes.getFilter.success]: (state, action) => {
    const hashedOptions = action.payload.response.options.reduce(
      (obj, item) => {
        return {
          ...obj,
          [item.value]: item
        };
      },
      {}
    );
    state.bottomUpFilter[action.payload.response.filter_type] = {
      ...action.payload.response,
      hashedOptions
    };
    state.bottomUpFilter.incomingFilter = action.payload.response.filter_type;
    state.filter = action.payload.response;
    state.apiRequestStatus.filter = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.getFilter.failure]: state => {
    state.filter = {};
    state.apiRequestStatus.filter = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.getFilters.request]: state => {
    state.filters = {};
    state.apiRequestStatus.filters = {
      request: true,
      success: false,
      error: false
    };
  },
  [genericSETypes.getFilters.success]: (state, action) => {
    state.filters = action.payload.response.data;
    state.apiRequestStatus.filters = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.getFilters.failure]: state => {
    state.filters = {};
    state.apiRequestStatus.filters = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.getSessionData.clear]: state => {
    state.sessionData = {};
    // state.appImagesFilters = {};
    state.apiRequestStatus.sessionData.isRequestMade = false;
  },
  [genericSETypes.getSessionData.request]: state => {
    state.sessionData = {};
    // state.appImagesFilters = {};
    state.apiRequestStatus.sessionData = {
      request: true,
      success: false,
      error: false,
      isRequestMade: true
    };
  },
  [genericSETypes.getSessionData.success]: (state, action) => {
    state.sessionData = action.payload.response.data;
    // state.appImagesFilters = action.payload.response.data.app_images_filters;
    state.apiRequestStatus.sessionData = {
      request: false,
      success: true,
      error: false,
      isRequestMade: true
    };
  },
  [genericSETypes.getSessionData.failure]: state => {
    state.sessionData = {};
    state.apiRequestStatus.sessionData = {
      request: false,
      success: false,
      error: true,
      isRequestMade: true
    };
  },
  [genericSETypes.downloadChartData.request]: state => {
    state.reportData = {};
    state.apiRequestStatus.reportData = {
      request: true,
      success: false,
      error: false
    };
  },
  [genericSETypes.downloadChartData.success]: (state, action) => {
    state.reportData = typeof action.payload.response === 'string'? action.payload.response :action.payload.response.data;
    state.apiRequestStatus.reportData = {
      request: false,
      success: true,
      error: false
    };
  },
  [genericSETypes.downloadChartData.failure]: state => {
    state.reportData = {};
    state.apiRequestStatus.reportData = {
      request: false,
      success: false,
      error: true
    };
  },
  [genericSETypes.genericSEfilterChange]: (state, action) => {
    const {
      payload: { value: filterList, chartList }
    } = action;
    const routeID = action.payload.routeID || action.payload.routeIDParam;
    const { filterType, grType, valueKey } = grModel;
    const filterId = keys(filterList);
    if (
      filterId.includes(filterType) &&
      state.preferences.granularities[routeID] !== undefined
    ) {
      map(chartList, name => {
        map(
          state.preferences.granularities[routeID][name][grType].options,
          el => {
            el.isHidden = false;
            if (el.value === valueKey) {
              el.isHidden = filterList.fl_type.value !== 'shelf';
            }
            return { ...el };
          }
        );
        state.preferences.granularities[routeID][name] = {
          ...state.preferences.granularities[routeID][name],
          [grType]: {
            ...state.preferences.granularities[routeID][name][grType],
            value: filterList.fl_type.value === 'shelf' ? 'brand' : 'brand-form'
          }
        };
      });
    }
    state.activeRouteID = routeID;
    const filters = {
      ...state.preferences.filters
    };
    filters[routeID] = {
      ...filters[routeID],
      ...filterList
    };
    // if (filterList.fl_time) {
    //   map(filters, (fl, route) => {
    //     filters[route] = {
    //       ...filters[route],
    //       fl_time: { ...filterList.fl_time }
    //     };
    //   });
    // }

    state.preferences.filters = { ...filters };
  }
});

export default reducer;

import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { computeFilters } from './helpers';

// store selectors
const getUserStore = state => state.userStore;
const getFilterStore = state => state.filterStore;

const getFilterList = createSelector([getFilterStore], data =>
  computeFilters(data)
);

const getUserPrefs = createSelector(
  [getUserStore],
  ({ preference: { prefs } }) => prefs
);

const getLoadingState = createSelector(
  [getUserStore],
  ({ preference: { isLoading } }) => isLoading
);

const getUserProfile = createSelector(
  [getUserStore, getUserPrefs, getFilterList],
  ({ profile }, userPrefs, filters) => {
    return !isEmpty(profile) && !isEmpty(userPrefs) && !isEmpty(filters)
      ? { profile, userPrefs, filters }
      : { profile: null, userPrefs: null, filters: null };
  }
);

export { getUserPrefs, getLoadingState, getUserProfile };

import React from 'react';
import { VSpacer } from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const NoDataMessage = () => (
  <>
    <VSpacer amount={30} />
    <Typography weight="400" color="light25" align="center">
      {client.includes('kcc-ru')
        ? 'Данные для выбранной даты отсутствуют'
        : 'No data available for the selected date'}
    </Typography>
  </>
);

export default NoDataMessage;

import React from 'react';
// library to set component properties
import { func, object } from 'prop-types';
// js utils library
import { map } from 'lodash';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Dropdown from 'components/Dropdown/Dropdown';

/**
 * @ignore
 * @name style object
 * @description Styles for Accuracy page filter component
 */
const style = {
  root: {
    display: 'flex',
    columnGap: 10
  }
};

/**
 * @class AccuracyFilter
 * @hideconstructor
 * @description Accuracy page filter component
 */
const AccuracyFilter = ({ classes, filtersToRender, handleFilterChange }) => (
  <div className={classes.root}>
    {map(filtersToRender, (filter, filterID) => {
      const { options, value, name: title } = filter;
      return (
        <Dropdown
          key={filterID}
          title={title}
          handleValueChange={selectedOption =>
            handleFilterChange({
              selectedOption,
              filterID,
              filter
            })
          }
          options={options}
          value={value}
        />
      );
    })}
  </div>
);

// component properties
AccuracyFilter.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: object.isRequired,
  /**
   * @type {array}
   * @description filter list
   */
  filtersToRender: object.isRequired,
  /**
   * @type {callback}
   * @description callback function to handle filter change
   */
  handleFilterChange: func.isRequired
};

export default withStyles(style)(AccuracyFilter);

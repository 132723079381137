import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

// assets
import infivizLogo from 'assets/svg/infiviz_logo.svg';
import backIcon from 'assets/svg/login_back_icon.svg';
import lockIcon from 'assets/svg/lock_icon.svg';
import eyeClose from 'assets/images/hide.png';
import eyeOpen from 'assets/images/show.png';

// utils
import { appSelector } from 'redux-utils/selectors/index';
import { authActions } from 'redux-utils/actions/index';
import ls from 'lib/core/storageFactory';
import { setBaseUrl } from 'api/index';

// components
import Notification from 'components/Notification/Notification';
import Loader from 'components/Loader/Loader';
import LoginVectorBackground from './LoginVectorBackground';

// stylesheet
import './index.css';
import { LoginHeader } from './constants';

const ResetPassword = ({
  authState,
  location,
  dispatchConfirmForgotPassword
}) => {
  const [inputValues, setInputValues] = useState({
    password: '',
    confirmPassword: ''
  });
  const [hasUpdated, setUpdatedStatus] = useState(false);
  const [notification, setNotification] = useState({
    display: false,
    message: ''
  });

  useEffect(() => {
    if (authState.confirmForgotPassword) {
      if (authState.confirmForgotPassword.status === 200) {
        setUpdatedStatus(true);
      }
    }
  }, [authState.confirmForgotPassword]);

  useEffect(() => {
    if (authState.apiStatus.confirmForgotPassword) {
      if (authState.apiStatus.confirmForgotPassword.message === 400) {
        setNotification({
          display: true,
          message: 'Link expired. Please request forgot password link again'
        });
      }
    }
  }, [authState.apiStatus.confirmForgotPassword]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const client = params.get('client');
    ls.set('client', client);
    ls.set(
      'baseURL',
      `https://${client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
    );
    setBaseUrl(
      `https://${client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
    );
  }, [location]);

  const handleInputChange = ({ value, field }) => {
    setInputValues(p => ({ ...p, [field]: value }));
  };

  const handleSubmit = ({ e, type }) => {
    e.preventDefault();
    if (type === 'verify_password') {
      const params = new URLSearchParams(location.search);
      dispatchConfirmForgotPassword({
        new_password: inputValues.password.trim(),
        token: params.get('token'),
        uid: params.get('uid')
      });
    }
  };

  const handleNotificationClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      display: false,
      message: ''
    });
  };

  const navigateToLogin = () => {
    window.location.replace('/login');
  };
  const goPrevStep = () => {
    ls.clear();
    window.location = '/login';
  };

  return (
    <div className="loginContainer ">
      <Notification
        duration={3000}
        message={notification.message}
        isOpen={notification.display}
        handleClose={handleNotificationClose}
      />
      <div className="loginWrapper ">
        <div className="mobileViewHeader">{LoginHeader}</div>
        <LoginVectorBackground />
        <div className="loginContainerWrapper ">
          <div className="loginContainerHeader">
            <img src={infivizLogo} alt="infiviz_logo"></img>
          </div>

          <div className="loginContainerBody forgotPassowrdWrapper resetPasswordWrapper">
            <div role="button" className="loginBackButton">
              <img src={backIcon} alt="backIcon" onClick={goPrevStep} />
            </div>
            <div className="forgotPassowrdHeadings">
              {!hasUpdated ? (
                <>
                  <h2>Password Reset</h2>
                  <h3>Please enter a new password</h3>
                </>
              ) : (
                <>
                  <h2>Password reset successful</h2>
                  <p>
                    Please click on <b>‘Login’</b> to continue using Infiviz
                  </p>
                </>
              )}
            </div>

            {!hasUpdated ? (
              <form
                onSubmit={e => {
                  handleSubmit({ e, type: 'verify_password' });
                }}
              >
                <VerifyPasswordInput
                  lable="Enter New Password"
                  id="oldPassword"
                  value={inputValues.password}
                  handleChange={e =>
                    handleInputChange({
                      field: 'password',
                      value: e.target.value
                    })
                  }
                  placeholder={'Enter New Password'}
                />
                <VerifyPasswordInput
                  lable="Confirm New Password"
                  id="newPassword"
                  value={inputValues.confirmPassword}
                  handleChange={e =>
                    handleInputChange({
                      field: 'confirmPassword',
                      value: e.target.value
                    })
                  }
                  placeholder={'Confirm New Password'}
                />
                {inputValues.password.trim() !== '' &&
                  inputValues.confirmPassword.trim() !== '' &&
                  inputValues.confirmPassword !== inputValues.password && (
                    <div className="inputRow forgotPasswordInputRow">
                      <label
                        className="inputRowLable errorLable"
                        htmlFor="oldPassword"
                      >
                        Please make sure your passwords match
                      </label>
                    </div>
                  )}
                <div className="loginContainerActions">
                  <button
                    className="actionButton"
                    disabled={
                      inputValues.confirmPassword.trim() === '' ||
                      inputValues.password.trim() === '' ||
                      inputValues.confirmPassword !== inputValues.password
                    }
                    type="submit"
                  >
                    Reset
                    {authState.apiStatus.confirmForgotPassword.request &&
                      !authState.apiStatus.confirmForgotPassword.success &&
                      !authState.apiStatus.confirmForgotPassword.error && (
                        <Loader
                          plain
                          simple
                          inline
                          circular
                          secondary
                          className="login-loader"
                        />
                      )}
                  </button>
                </div>
              </form>
            ) : (
              <div className="loginContainerActions">
                <button className="actionButton" onClick={goPrevStep}>
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const VerifyPasswordInput = ({
  lable,
  id,
  value,
  placeholder,
  handleChange
}) => {
  const inputRef = useRef();
  const [hidden, setHidden] = useState(true);
  const handleEyeClick = () => {
    if (hidden) {
      setHidden(false);
      inputRef.current.type = 'text';
    } else {
      setHidden(true);
      inputRef.current.type = 'password';
    }
  };
  return (
    <div className="inputRow forgotPasswordInputRow">
      <label className="inputRowLable" htmlFor={id}>
        {lable}
        <img src={lockIcon} alt="lock_icon"></img>
      </label>
      <div
        className="clientInputWrapper confirmPasswordInput "
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '10px'
        }}
      >
        <input
          ref={inputRef}
          type="password"
          className="inputRowComponent"
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        ></input>
        <img
          src={hidden ? eyeClose : eyeOpen}
          alt="lock_icon"
          width="20"
          style={{ cursor: 'pointer' }}
          onClick={handleEyeClick}
        ></img>

        {}
      </div>
    </div>
  );
};
/*
    Connect redux store state to props so that you can access the state
    from the scope of the component's props
  */
const mapStateToProps = state => ({
  authState: appSelector.getAuthState(state)
});

/*
    Connect dispatch methods to props so that you can call the methods
    from the scope of the component's props
  */
const mapDispatchToProps = dispatch => ({
  dispatchConfirmForgotPassword: payload =>
    dispatch(authActions.confirmForgotPassword(payload))
});

ResetPassword.propTypes = {
  authState: object.isRequired,
  location: object.isRequired,
  classes: object.isRequired,
  dispatchConfirmForgotPassword: func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

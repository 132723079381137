import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

const customStyles = {
  view: () => ({
    '& img': {
      maxHeight: '80vh',
      maxWidth: '90vw'
    }
  })
};

const Preview = ({ images, cb, modalState }) => {
  return (
    <ModalGateway>
      {modalState.isOpen ? (
        <Modal onClose={() => cb({ img: [], isOpen: false })}>
          <Carousel styles={customStyles} views={images} />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};

Preview.propTypes = {
  images: PropTypes.array.isRequired,
  modalState: PropTypes.object.isRequired,
  cb: PropTypes.func.isRequired
};

export default Preview;

import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// styles
import gridStyle from 'assets/jss/material-ui/components/gridStyles';

/**
 * @class GridContainer
 * @hideconstructor
 * @description Core GridContainer component
 */
const GridContainer = ({ classes, classNames, children, ...rest }) => (
  <Grid container {...rest} className={`${classes.grid} ${classNames}`}>
    {children}
  </Grid>
);

// component properties
GridContainer.propTypes = {
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description - Additional classNames
   */
  classNames: PropTypes.string,
  /**
   * @type {function | element}
   * @description - Child elements
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

GridContainer.defaultProps = {
  classNames: ''
};

export default withStyles(gridStyle)(GridContainer);

/* eslint-disable valid-jsdoc */
import React, { Component } from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// lodash wrapper for immutable.js
import { isEmpty, isEqual, map } from 'lodash';
// core library
import { removeUndefined } from 'lib/core/object';
// redux-utils
import { SEActions } from 'redux-utils/actions/index';
import { appSelector, SESelector } from 'redux-utils/selectors/index';
// core components
import SEFilter from 'pages/SE/SEFilter';

import NoDataMessage from './NoDataMessage';

/**
 * @class SEFilterContainer
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
class SEFilterContainer extends Component {
  state = {};

  componentWillMount() {
    const { dispatchGetSEFilterRequest, filters } = this.props;
    if (isEmpty(filters.fl_channel)) {
      dispatchGetSEFilterRequest();
    } else {
      this.computeFilterValues(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = this.props;
    if (!isEqual(filters.fl_channel, nextProps.filters.fl_channel)) {
      this.computeFilterValues(nextProps);
    }
  }

  computeFilterValues = (nextProps = this.props) => {
    const { filters, defaultFilters, handleFilterChange } = nextProps;
    if (!isEmpty(filters.fl_channel) && !isEmpty(defaultFilters)) {
      const defaultItems = {};
      map(filters, (fl, key) => {
        if (key === 'fl_channel') {
          defaultItems.fl_channel = {
            options: fl,
            value: fl.filter(e => e.is_default === true)[0]
          };
          defaultItems.fl_store = {
            options: defaultItems.fl_channel.value.fl_store,
            value: defaultItems.fl_channel.value.fl_store.filter(
              e => e.is_default === true
            )[0]
          };
          defaultItems.fl_date = {
            options: defaultItems.fl_store.value.fl_date,
            value: defaultItems.fl_store.value.fl_date.filter(
              e => e.is_default === true
            )[0]
          };
        }
      });
      this.setState(() => ({
        SEFilters: defaultItems
      }));
      handleFilterChange(removeUndefined(defaultItems));
    }
  };

  /**
   * @method
   * @description Handles the date change event
   * @param {object} options - Changed object
   * @param {bool} val - is selected
   * @param {bool} key - Changed object key
   * @return {undefined}
   */
  handleToggleChange = ({ options, val, type: key }) => {
    const { handleFilterChange } = this.props;
    handleFilterChange({
      [key]: val ? options[1] : options[0]
    });
  };

  handleFilterChange = ({ val, key, filters }) => {
    const defaultItems = { ...filters };
    if (key === 'fl_channel') {
      defaultItems.fl_channel = {
        options: filters.fl_channel.options,
        value: filters.fl_channel.options.filter(e => e.value === val.value)[0]
      };
      defaultItems.fl_store = {
        options: defaultItems.fl_channel.value.fl_store,
        value: defaultItems.fl_channel.value.fl_store.filter(
          e => e.is_default === true
        )[0]
      };
      defaultItems.fl_date = {
        options: defaultItems.fl_store.value.fl_date,
        value: defaultItems.fl_store.value.fl_date.filter(
          e => e.is_default === true
        )[0]
      };
    }
    if (key === 'fl_store') {
      defaultItems.fl_store = {
        options: filters.fl_store.options,
        value: filters.fl_store.options.filter(e => e.value === val.value)[0]
      };
      defaultItems.fl_date = {
        options: defaultItems.fl_store.value.fl_date,
        value: defaultItems.fl_store.value.fl_date.filter(
          e => e.is_default === true
        )[0]
      };
    }
    if (key === 'fl_date') {
      defaultItems.fl_date = {
        options: filters.fl_date.options,
        value: filters.fl_date.options.filter(e => e.value === val.value)[0]
      };
    }
    this.setState(() => ({
      SEFilters: defaultItems
    }));
    this.props.handleFilterChange(removeUndefined(defaultItems));
  };

  render() {
    const { filters, defaultFilters, filterPreferences } = this.props;
    const { SEFilters } = this.state;
    return (
      <>
        <SEFilter
          handleFilterChange={this.handleFilterChange}
          handleToggleChange={this.handleToggleChange}
          filters={SEFilters}
          defaultFilters={defaultFilters}
          state={{ ...filterPreferences }}
        />
        {filters && filters.fl_date && filters.fl_date.options.length === 0 && (
          <NoDataMessage />
        )}
      </>
    );
  }
}

// component properties
SEFilterContainer.propTypes = {
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description user preferences of the filter
   */
  filterPreferences: PropTypes.object,
  /**
   * @type {array}
   * @description dropdown filter data
   */
  defaultFilters: PropTypes.array,
  /**
   * @type {function}
   * @description Callback function to handle the filter change event
   */
  handleFilterChange: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Callback function to handle the filter change event
   */
  dispatchGetSEFilterRequest: PropTypes.func.isRequired
};

SEFilterContainer.defaultProps = {
  defaultFilters: [],
  filterPreferences: {}
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const getDefaultFilters = appSelector.makeGetDefaultFilters();
  const getFilterPreferences = appSelector.makeGetFilterPreferences();

  const mapStateToProps = (state, props) => ({
    defaultFilters: getDefaultFilters(state, props),
    filterPreferences: getFilterPreferences(state, props),
    filters: SESelector.selectFilters(state)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetSEFilterRequest: () => dispatch(SEActions.getSEFilterListAction())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SEFilterContainer);

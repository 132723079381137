import { takeEvery, call, put } from 'redux-saga/effects';
// redux-utils
import { accuracyTypes } from 'redux-utils/types/index';
import { actionTypeFormatter } from 'redux-utils/sagas/index';
// api helpers
import { deleteRequest, get, post, putRequest, endpoints } from 'api/index';

/**
 * @name appWatcherSaga
 * @description Watches for the action dispatched of certain type.
 */
const watcherSaga = [
  takeEvery(accuracyTypes.getFilters.request, getWorkerSaga),
  takeEvery(accuracyTypes.getSessionDetails.request, getWorkerSaga),
  takeEvery(accuracyTypes.getSessionList.request, getWorkerSaga),
  takeEvery(accuracyTypes.getChartList.request, getWorkerSaga),
  takeEvery(accuracyTypes.getChartData.request, getWorkerSaga),
  takeEvery(accuracyTypes.getScheduledReports.request, getWorkerSaga),
  takeEvery(accuracyTypes.getScheduledReportsMetaData.request, getWorkerSaga),
  takeEvery(accuracyTypes.postScheduledReport.request, getWorkerSaga),
  takeEvery(accuracyTypes.updateScheduledReport.request, getWorkerSaga),
  takeEvery(accuracyTypes.deleteScheduledReport.request, getWorkerSaga),
  takeEvery(accuracyTypes.downloadReport.request, getWorkerSaga)
];

function getWorkerSaga(action) {
  switch (action.type) {
    case accuracyTypes.getFilters.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.filters,
        method: get,
        headers: ['auth']
      });
    }
    case accuracyTypes.getSessionDetails.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.sessionDetails,
        method: get,
        headers: ['auth']
      });
    }
    case accuracyTypes.getSessionList.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.sessionList,
        method: get,
        headers: ['auth']
      });
    }
    case accuracyTypes.getChartList.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.chartList,
        method: get,
        headers: ['auth']
      });
    }
    case accuracyTypes.getChartData.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.chartData,
        method: get,
        headers: ['auth']
      });
    }
    case accuracyTypes.getScheduledReports.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.getScheduledReports,
        method: get,
        headers: ['auth'],
        useBaseURL: true
      });
    }
    case accuracyTypes.getScheduledReportsMetaData.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.getScheduledReportsMetaData,
        method: get,
        headers: ['auth'],
        useBaseURL: true
      });
    }
    case accuracyTypes.postScheduledReport.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.postScheduledReport,
        method: post,
        headers: ['auth'],
        useBaseURL: true,
        payload: action.payload
      });
    }
    case accuracyTypes.updateScheduledReport.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.updateScheduledReport,
        method: putRequest,
        headers: ['auth'],
        useBaseURL: true,
        payload: action.payload
      });
    }
    case accuracyTypes.deleteScheduledReport.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.deleteScheduledReport,
        method: deleteRequest,
        headers: ['auth'],
        useBaseURL: true,
        payload: action.payload
      });
    }
    case accuracyTypes.downloadReport.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.accuracy.downloadReport,
        method: get,
        headers: ['auth'],
        payload: action.payload
      });
    }
    default:
      return null;
  }
}

/**
 * @description Get layout worker saga
 * @param {object} action - dispatched action
 * @param {object} params - api params
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* apiWorkerSaga(action, params) {
  try {
    const { response, error } = yield call(params.method, {
      payload: action.payload.params,
      useCachedInstance: false,
      ...params
    });
    if (response) {
      yield put({
        meta: action.meta,
        type: actionTypeFormatter(action.type, 'SUCCESS'),
        payload: {
          ...action.payload,
          error: null,
          response: response.data
        }
      });
    } else {
      yield put({
        meta: action.meta,
        type: actionTypeFormatter(action.type, 'FAILURE'),
        payload: {
          ...action.payload,
          error: error.data,
          response: null
        }
      });
    }
  } catch (error) {
    yield put({
      meta: action.meta,
      type: 'SAGA_ERROR',
      payload: {
        type: action.type,
        ...action.payload,
        error
      }
    });
  }
}

export default watcherSaga;

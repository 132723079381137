/* eslint-disable import/prefer-default-export */
export const uniq = a => [...new Set(a)];

export const uniqBy = (a, key) =>
  a.filter(
    (thing, index, self) => index === self.findIndex(t => t[key] === thing[key])
  );

export const searchByKey = ({ key, value, array: myArray }) => {
  for (let i = 0; i < myArray.length; i += 1) {
    if (myArray[i][key] === value) {
      return myArray[i];
    }
  }
  return undefined;
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useImage from 'use-image';
import { Image, Rect, Label, Tag, Text } from 'react-konva';

import arrowRight from 'assets/svg/arrow_right.svg';
import arrowLeft from 'assets/svg/arrow_left.svg';
import arrowUp from 'assets/svg/arrow_up.svg';
import arrowDown from 'assets/svg/arrow_down.svg';
import separator from 'assets/svg/separator.svg';

const directionArrow = {
  top: arrowUp,
  up: arrowUp,
  down: arrowDown,
  bottom: arrowDown,
  left: arrowLeft,
  right: arrowRight
};

const Arrow = ({ arrow, type, scaleDownFactor, dashboardType, ...rest }) => {
  const [image] = useImage(arrow);
  return (
    (image && (
      <>
        <Image {...rest} image={image} fill={'#5b773c'} />
      </>
    )) ||
    null
  );
};

const ImageNode = ({
  url,
  colors,
  type,
  scaleDownFactor,
  showDivider,
  isMiniMap,
  dashboardType,
  ...rest
}) => {
  const [image] = useImage(url);
  return (
    (image && (
      <>
        <Image {...rest} image={image} y={isMiniMap ? rest.y + 20 : rest.y} />
        <Rect
          stroke={type === 'shelf' ? colors.shelf : colors.display}
          strokeWidth={scaleDownFactor === 1 ? 3 : 1}
          {...rest}
          y={isMiniMap ? rest.y + 20 : rest.y}
        />
        {showDivider && (
          <Arrow
            arrow={separator}
            y={0}
            x={rest.x - 8}
            width={15}
            height={15}
          />
        )}
      </>
    )) ||
    null
  );
};

ImageNode.propTypes = {
  url: PropTypes.string.isRequired
};

const StitchedImage = ({
  photoList,
  scaleDownFactor,
  colors,
  nativeProps,
  shelfCanvasWidth,
  index,
  isMiniMap,
  dashboardType
}) => {
  let nextXPosition = 0;
  return (
    photoList &&
    photoList.map((image, i) => {
      const item = i > 0 ? photoList[i] : image;
      const photoType = image.type || 'shelf';
      const direction = item.direction ? item.direction.toLowerCase() : '';
      const showDivider = isMiniMap && !(i % 10) && !!i;
      let displays = [];
      if (image.displays) {
        displays = [...image.displays];
      }

      const Node = (
        <>
          <ImageNode
            {...nativeProps}
            colors={colors}
            key={image.id * i}
            url={image.scaled_img}
            type={photoType}
            x={nextXPosition}
            y={0}
            scaleDownFactor={scaleDownFactor}
            width={item.canvasWidth / scaleDownFactor}
            height={item.canvasHeight / scaleDownFactor}
            showDivider={showDivider}
            isMiniMap={isMiniMap}
          />

          {scaleDownFactor === 1 &&
            displays &&
            displays.map((disp, index) => {
              return (
                <>
                  <Label
                    x={nextXPosition + 50}
                    y={
                      item.canvasHeight / scaleDownFactor +
                      10 +
                      (index === 0 ? 0 : index * 20 * 2)
                    }
                  >
                    <Tag fill="#ffffff" />
                    <Text
                      padding={4}
                      text={`${index + 1}. Display: ${disp.type},
    Brand: ${disp.brand}`}
                      fontSize={13}
                      strokeWidth={1}
                      stroke="#000"
                      fill="#000"
                    />
                  </Label>
                </>
              );
            })}
          {scaleDownFactor === 1 && displays && (
            <>
              <Rect
                x={nextXPosition}
                y={item.canvasHeight / scaleDownFactor + 10}
                width={30}
                height={30}
                fill="#5b773c"
              />
              <Arrow
                arrow={directionArrow[direction]}
                x={nextXPosition + 5}
                y={item.canvasHeight / scaleDownFactor + 15}
                width={20}
                height={20}
              />
              <Label
                x={shelfCanvasWidth - 110}
                y={item.canvasHeight / scaleDownFactor + 25}
              >
                <Text
                  text={`End of Image set ${index + 1}`}
                  fill={dashboardType === 'v2' ? '#767676' : 'white'}
                />
              </Label>
            </>
          )}
        </>
      );
      nextXPosition += item.canvasWidth / scaleDownFactor;
      return Node;
    })
  );
};

StitchedImage.propTypes = {
  photoList: PropTypes.array.isRequired,
  scaleDownFactor: PropTypes.number,
  useDisplayTag: PropTypes.bool,
  nativeProps: PropTypes.object,
  dashboardType: PropTypes.string
};

StitchedImage.defaultProps = {
  scaleDownFactor: 1,
  nativeProps: {},
  useDisplayTag: false,
  dashboardType: ''
};

export default StitchedImage;

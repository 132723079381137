const reducerModel = {
  layout: {
    id: 'rx_layout'
  },
  app: {
    id: 'rx_app_state'
  },
  filter: {
    id: 'rx_filter'
  },
  chart: {
    id: 'rx_chart'
  },
  storeExplorer: {
    id: 'rx_store_explorer'
  },
  storeExplorerNew: {
    id: 'rx_store_explorer_new'
  },
  SE: {
    id: 'rx_SE'
  },
  genericSE: {
    id: 'rx_genericSE'
  },
  slicer: {
    id: 'rx_slicer'
  },
  userProfile: {
    id: 'rx_user_profile'
  },
  admin: {
    id: 'rx_admin'
  },
  accuracy: {
    id: 'rx_accuracy'
  },
  merchEvaluation: {
    id: 'rx_merch_evaluation'
  }
};

export default reducerModel;

import React, { Component } from 'react';
// library to assign component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// react library to dynamically set the document’s head section
import Helmet from 'react-helmet';
import { map } from 'lodash';
// @material-ui/core components
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
// redux utils
import { appActions, authActions, userActions } from 'redux-utils/actions';
import { appSelector } from 'redux-utils/selectors';
// route handler
import { SwitchRoutes } from 'routes';
// db helpers
import { deleteDB } from 'db';
// core components
import Loader from 'components/Loader/Loader';
// core functions
import ls from 'lib/core/storageFactory';
import { getRouteID, getRoutePath } from 'lib/utils/getActiveRoute';
// views
import Header from 'layout/Header/Header';
// assets
import dashboardStyle from 'assets/jss/material-ui/layout/dashboardLayoutStyle';
// utils
import routes from 'routes/appRoutes';
import { setBaseUrl } from 'api';

const helmet = (
  <Helmet>
    <style key="dashboard-view">
      {
        'body { background-color: #1e1e2b; overflow: unset; overflow-x: hidden; }'
      }
    </style>
  </Helmet>
);

/**
 * @class
 * @hideconstructor
 * @description AdminPanel class component to render the AdminPanel view layout
 */
class AdminPanel extends Component {
  pathname = '';

  appRoutes = [];

  state = {
    isDrawerOpen: false,
    isDrawerExpanded: true
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    this.pathname = pathname;
    this.appRoutes = map(routes, el => el);
    const {
      dispatchGetLayoutRequest,
      dispatchGetPreferenceRequest
    } = this.props;
    dispatchGetPreferenceRequest();
    dispatchGetLayoutRequest();
  }

  componentWillReceiveProps() {
    const { dispatchRouteChange, location } = this.props;
    const {
      location: { pathname }
    } = window;
    const routeID = getRouteID(pathname);
    this.pathname = pathname;
    if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
      if (ls.get('isLoggedIn')) {
        const domain = ls.get('domain');
        const parsedURL = window.location.origin
          .split('//')
          .join('')
          .split('.');
        const hasSubDomain = parsedURL.length !== 2;
        if (
          (hasSubDomain && parsedURL[0].split(':')[1] !== domain) ||
          !hasSubDomain
        ) {
          window.location.replace(
            `https://${domain}.${process.env.REACT_APP_SHELF_REDIRECT_URI}/app`
          );
        }
      }
    }
    if (
      (routeID.length === 0 &&
        !pathname.includes('/user-profile') &&
        !pathname.includes('/feature')) ||
      ls.get('isLoggedIn') !== true
    ) {
      this.logoutUser();
    }
    if (pathname !== location.pathname) {
      // dispatchGetFilterRequest()
      dispatchRouteChange(getRouteID(pathname));
    }
  }

  /**
   * @private
   * @method
   * @description handler function to handle the drawer expand
   * @returns {undefined}
   */
  handleDrawerExpand = () => {
    this.setState(state => ({
      ...state,
      isDrawerExpanded: !state.isDrawerExpanded
    }));
  };

  /**
   * @private
   * @method
   * @description handler function to handle the drawer toggle
   * @returns {undefined}
   */
  handleDrawerToggle = () => {
    this.setState(state => ({ ...state, isDrawerOpen: !state.isDrawerOpen }));
  };

  /**
   * @private
   * @method
   * @description handler function to handle the user preferences
   * @returns {undefined}
   */
  handlePreferenceClick = () => {
    const { history } = this.props;
    history.push(getRoutePath('userProfile', routes));
  };

  /**
   * @method
   * @private
   * @description A function to clear the localStorage and redirect
   * the user to Login Page
   * @returns {undefined}
   */
  logoutUser = () => {
    const { history } = this.props;
    ls.clear();
    ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
    setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
    deleteDB();
    history.push('/app');
  };

  render() {
    const {
      classes,
      dispatchLogoutRequest,
      rightMenuRoutes,
      ...rest
    } = this.props;
    return (
      <div className={classes.root}>
        {helmet}
        <CssBaseline />
        {rightMenuRoutes.length <= 1 ? (
          <Loader circular color="secondary" inline />
        ) : null}
        <Header
          handleLogout={dispatchLogoutRequest}
          handlePreferenceClick={this.handlePreferenceClick}
          rightMenuRoutes={rightMenuRoutes}
          useHomeIcon
          {...rest}
        />
        <div className={classes.mainPanel}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.container}>
              {rightMenuRoutes.length > 1 ? (
                <SwitchRoutes routes={[...rightMenuRoutes]} />
              ) : null}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

// component properties
AdminPanel.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description  Logout request action dispatcher
   */
  dispatchLogoutRequest: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Menu structure request action dispatcher
   */
  dispatchGetLayoutRequest: PropTypes.func.isRequired,
  /**
   * @type {object}
   * @description Right Menu structure routes
   */
  rightMenuRoutes: PropTypes.array.isRequired,
  /**
   * @type {function}
   * @description Get User preferences request action dispatcher
   */
  dispatchGetPreferenceRequest: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Route change action dispatcher
   */
  dispatchRouteChange: PropTypes.func.isRequired,
  /**
   * @type {object}
   * @description Browser location object
   */
  location: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description Browser location object
   */
  history: PropTypes.object.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const mapStateToProps = state => ({
  rightMenuRoutes: appSelector.getRightMenu(state)
});

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetPreferenceRequest: () =>
    dispatch(userActions.getUserPreferenceAction()),
  dispatchGetLayoutRequest: () => dispatch(appActions.layoutRequestAction({})),
  dispatchLogoutRequest: () => dispatch(authActions.userLogoutRequest()),
  dispatchRouteChange: payload =>
    dispatch(appActions.routeChangeAction(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle, { withTheme: true })(AdminPanel));

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { any, array, bool, func, number, object } from 'prop-types';
import { debounce, throttle, map } from 'lodash';
import { Stage, Layer } from 'react-konva';
import Draggable from 'react-draggable'; // The default
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import constants from 'models/constants';

// components
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
// views
import StitchedImage from './StitchedImage';
import Crops from './Crops';

const styles = {
  wrapper: {
    overflow: 'auto'
    // maxWidth: '75vw'
  },
  canvas: {
    display: 'flex'
  },
  draggable: {
    zIndex: 999,
    position: 'fixed',
    overflow: 'scroll',
    whiteSpace: 'pre-wrap',
    maxHeight: '80vh',
    background: '#2e2e31',
    border: '1px'
  },
  dropdown: {},
  selectedCrops: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  cropDetails: {
    width: 200,
    height: 'fit-content',
    padding: 10,
    overflow: 'auto'
  },
  item: {
    width: 260,
    display: 'grid',
    gridTemplateColumns: '130px 130px',
    '& h4': {
      whiteSpace: 'pre-line',
      wordBreak: 'break-all'
    },
    '& a': {
      color: 'aqua'
    },
    '& img': {
      maxHeight: 160,
      maxWidth: '100%',
      paddingTop: 10
    }
  }
};

const ShelfCanvas = ({
  colors,
  classes,
  activeBoxes,
  useExclusionsCheckBox,
  checkBoxState,
  canvasHeight,
  canvasWidth,
  dispatch,
  list,
  useDisplayTag,
  reducerState,
  wrapperRef,
  isCropInfoHandlerSelected,
  handleCropClick
}) => {
  const [userScroll, setUserScroll] = useState(false);
  const [selectedCrops, setSelectedCrops] = useState([]);
  const [cropDetails, setCropDetails] = useState({
    display: false,
    data: {}
  });

  useEffect(() => {
    setCropDetails({ display: false, data: {} });
    setSelectedCrops([]);
  }, [isCropInfoHandlerSelected, activeBoxes]);

  const throttledMouseEvents = useRef(throttle(q => handleMouseEvents(q), 800))
    .current;

  const handleMouseEvents = e => {
    setUserScroll(e);
  };

  useEffect(() => {
    if (!userScroll) {
      const { current: canvasRef } = wrapperRef;
      canvasRef.scrollTo({
        top: 0,
        left: reducerState.shelfCanvas.activeArea[0],
        behavior: 'smooth'
      });
    }
  }, [reducerState.shelfCanvas, wrapperRef, userScroll]);

  const handleCanvasScroll = ({ event, toDispatch }) => {
    if (event && toDispatch) {
      const { target } = event;
      dispatch({
        payload: { event: target },
        type: 'SHELF_CANVAS_SCROLL_EVENT'
      });
    }
  };

  const throttledScroll = useCallback(
    useRef(debounce(q => handleCanvasScroll(q), 0)).current
  );

  const [mouseEvent, setMouseEvent] = useState(null);

  useEffect(() => {
    throttledScroll({
      event: mouseEvent,
      toDispatch: userScroll
    });
  }, [mouseEvent, userScroll, throttledScroll]);

  useEffect(() => {
    const { current: canvasRef } = wrapperRef;
    const scrollListener = canvasRef.addEventListener('scroll', e => {
      setMouseEvent(e);
    });
    return () => {
      canvasRef.removeEventListener('scroll', scrollListener);
    };
  }, [wrapperRef]);

  const handleBoundaryClickEvent = boundary => {
    const values = [];
    map(boundary, (value, key) => {
      if (key !== 'coords' && value.length > 0) {
        values.push({ key: key.replace(/^\w/, c => c.toUpperCase()), value });
      }
    });
    setCropDetails({
      display: true,
      data: { new_tooltip: values }
    });
  };

  const handleCropClickEvent = ({ crop, image }) => {
    if (!crop.id) {
      return 0;
    }
    const arr = [];
    arr.push({ key: 'CropId', value: crop.id });
    arr.push({ key: 'image_url', value: image });
    if (!isCropInfoHandlerSelected) {
      return handleCropClick({ crop });
    }
    let displayInfo = true;
    let targetCrop = [crop.id];
    if (selectedCrops.includes(crop.id)) {
      displayInfo = false;
      targetCrop = [];
    }
    setCropDetails({
      display: displayInfo,
      data: {
        ...crop,
        columns: [...crop.columns],
        rowDetails: { ...crop.rowDetails, cropId: crop.id },
        new_tooltip: arr
      }
    });
    return setSelectedCrops(targetCrop);
  };

  return (
    <>
      <div ref={wrapperRef} style={styles.wrapper}>
        <Stage width={canvasWidth} height={canvasHeight}>
          <Layer
            onMouseOver={() => throttledMouseEvents(true)}
            onMouseLeave={() => throttledMouseEvents(false)}
          >
            <StitchedImage
              colors={colors}
              photoList={list.images}
              useDisplayTag={useDisplayTag}
              checkBoxState={checkBoxState}
              handleBoundaryClick={handleBoundaryClickEvent}
            />
            {activeBoxes.length > 0 && (
              <Crops
                useExclusionsCheckBox={useExclusionsCheckBox}
                checkBoxState={checkBoxState}
                selectedCrops={selectedCrops}
                handleCropClick={handleCropClickEvent}
                activeBoxes={activeBoxes}
                imageList={list.images}
              />
            )}
          </Layer>
        </Stage>
      </div>
      <Draggable
        defaultPosition={{ x: constants.mainWindowWidth - 300, y: -544 }}
      >
        <div className={classes.draggable}>
          {cropDetails.display && isCropInfoHandlerSelected && (
            <div style={{ width: 280 }} className={classes.cropDetails}>
              {cropDetails.data.new_tooltip &&
                cropDetails.data.new_tooltip.map(({ key, value }) =>
                  key.toLowerCase() === 'pack shot' &&
                  value &&
                  value.length > 0 ? (
                    <div key={key} className={classes.item}>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${key}:`}</Typography>
                      <img src={value} alt="packshot" />
                    </div>
                  ) : key.toLowerCase() === 'image_url' ? (
                    <div key={key} className={classes.item}>
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        image_url
                      </a>
                    </div>
                  ) : (
                    <div key={key} className={classes.item}>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${key}:`}</Typography>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${value}`}</Typography>
                    </div>
                  )
                )}
              <VSpacer amount={20} />
              {cropDetails.data.columns && (
                <>
                  {cropDetails.data.columns.map(col => {
                    if (
                      (col.dataKey === 'упаковать фото' ||
                        col.dataKey === 'packshot' ||
                        col.dataKey === 'Promotional Crop') &&
                      typeof cropDetails.data.rowDetails[col.dataKey] !==
                        'object'
                        ? cropDetails.data.rowDetails[col.dataKey] || ''
                        : '' &&
                          typeof cropDetails.data.rowDetails[col.dataKey] !==
                            'object'
                        ? cropDetails.data.rowDetails[col.dataKey] || ''
                        : ''.length > 0
                    ) {
                      return (
                        <div key={col.dataKey} className={classes.item}>
                          <Typography
                            size={14}
                            weight={400}
                          >{`${col.label}:`}</Typography>
                          <img
                            src={
                              typeof cropDetails.data.rowDetails[
                                col.dataKey
                              ] !== 'object'
                                ? cropDetails.data.rowDetails[col.dataKey] || ''
                                : ''
                            }
                            alt={col.dataKey}
                          />
                        </div>
                      );
                    }

                    return (
                      <div key={col.dataKey} className={classes.item}>
                        <Typography
                          size={14}
                          weight={400}
                        >{`${col.label}:`}</Typography>
                        <Typography size={14} weight={400}>
                          {React.isValidElement(
                            cropDetails.data.rowDetails[col.dataKey]
                          )
                            ? cropDetails.data.rowDetails[col.dataKey].props
                                .children
                            : typeof cropDetails.data.rowDetails[
                                col.dataKey
                              ] !== 'object'
                            ? cropDetails.data.rowDetails[col.dataKey] || 'NA'
                            : 'NA'}
                        </Typography>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
};

ShelfCanvas.propTypes = {
  classes: object.isRequired,
  useExclusionsCheckBox: bool.isRequired,
  checkBoxState: object.isRequired,
  colors: object.isRequired,
  activeBoxes: array.isRequired,
  canvasHeight: number.isRequired,
  useDisplayTag: bool.isRequired,
  isCropInfoHandlerSelected: bool,
  canvasWidth: number.isRequired,
  dispatch: func.isRequired,
  handleCropClick: func.isRequired,
  list: object.isRequired,
  reducerState: object.isRequired,
  wrapperRef: any.isRequired
};

ShelfCanvas.defaultProps = {
  isCropInfoHandlerSelected: false
};

export default withStyles(styles)(ShelfCanvas);

/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// lodash wrapper for immutable.js
import { isEmpty, map, filter } from 'lodash';
// @material-ui/core
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Dropdown from 'components/Dropdown/Dropdown';
import TimeFilter from 'layout/Filter/TimeFilter';
import { HSpacer } from 'components/Spacer/Spacer';
import { scrollbar } from 'assets/jss/material-ui/material-ui';
import constants from 'models/constants';

/**
 * @ignore
 * @name FilterToolbarStyles
 * @description toolbarStyles for Filter component
 */
const toolbarStyles = theme => ({
  root: {
    ...scrollbar,
    scrollbarColor:
      theme.palette.type === 'light'
        ? '#f5f5f5 transparent'
        : 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5' : 'rgb(65, 65, 66)',
      borderRadius: 8
    },
    marginBottom: '13px',
    marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0,
    maxWidth: constants.mainWindowWidth,
    overflowX: 'auto'
  }
});

const handleChange = (val, key, filters, callback) => {
  if (filters[key].use_value_dropdown) {
    const [valueObject] = filter(
      filters[val.value].options,
      el => el.is_default === true
    );
    const value = {
      [key]: val,
      [val.value]: valueObject
    };
    callback(value);
  } else callback(val, key);
};

/**
 * @class SEFilter
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
const SEFilter = ({
  classes,
  filters,
  defaultFilters,
  state,
  handleFilterChange,
  initialSelectedDate
}) => (
  <Toolbar className={classes.root}>
    {!isEmpty(filters)
      ? map(defaultFilters, def => {
          const { value: type, label: title } = def;
          if (filters[type]) {
            const {
              options,
              use_toggle: useToggle,
              use_value_dropdown: useValueDropDown
            } = filters[type];
            const value =
              state[type] !== undefined && state[type] !== null
                ? state[type]
                : options.filter(e => e.is_default === true)[0];
            let valueDropDownKey = '';
            let valueDropDownOptions = [];
            let valueDropDownValue = {};
            if (useValueDropDown) {
              const defaultItem =
                state[type] !== undefined && state[type] !== null
                  ? options.filter(e => e === state[type])[0]
                  : options.filter(e => e.is_default === true)[0];
              valueDropDownKey = defaultItem.value;
              valueDropDownOptions = filters[defaultItem.value].options;
              valueDropDownValue =
                state[valueDropDownKey] !== undefined &&
                state[valueDropDownKey] !== null
                  ? state[valueDropDownKey]
                  : valueDropDownOptions.filter(e => e.is_default === true)[0];
            }
            return type === 'fl_date' ? (
              <React.Fragment key={type}>
                <TimeFilter
                  title={title}
                  float="left"
                  key={type}
                  useRange={false}
                  options={options}
                  value={value}
                  defaultDateRange={initialSelectedDate}
                  handleDateChange={date =>
                    handleChange(date, 'fl_date', filters, handleFilterChange)
                  }
                />
                <HSpacer amount={20} />
              </React.Fragment>
            ) : useToggle ? null : (
              <React.Fragment key={type}>
                <Dropdown
                  title={title}
                  handleValueChange={val =>
                    handleChange(val, type, filters, handleFilterChange)
                  }
                  options={options}
                  value={value}
                />
                {useValueDropDown ? (
                  <React.Fragment key={type}>
                    <HSpacer amount={20} />
                    <Dropdown
                      handleValueChange={val =>
                        handleChange(
                          val,
                          valueDropDownKey,
                          filters,
                          handleFilterChange
                        )
                      }
                      options={valueDropDownOptions}
                      value={valueDropDownValue}
                    />
                  </React.Fragment>
                ) : null}
                <HSpacer amount={20} />
              </React.Fragment>
            );
          }
        })
      : null}
  </Toolbar>
);

// component properties
SEFilter.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {array}
   * @description filter list
   */
  defaultFilters: PropTypes.array.isRequired
};

export default withStyles(toolbarStyles)(SEFilter);

/* eslint-disable camelcase */
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { appImagesTypes } from 'redux-utils/types';
import { get, post } from 'api/index';
import { formatDate } from 'components/DateRangePicker/utils';

/* Store Selectors */
import { getStores, getSessions } from '../selectors/appImagesSelector';

const getDate = date => {
  return date
    .split('/')
    .reverse()
    .reduce((a, c) => `${a}-${c}`);
};

function* getStoresWorker(action) {
  const { fl_start_date } = action.payload;
  const date = fl_start_date
    .split('/')
    .reverse()
    .reduce((a, c) => `${a}-${c}`);
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_APP_IMAGES_GET_STORES,
    payload: { date },
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    const stores = data.data.map(item => {
      return { label: item.store_title, value: item.store_id };
    });
    yield put({
      type: appImagesTypes.getStores.success,
      payload: { response: stores, ...action.payload }
    });
    if (stores.length > 0) {
      const dateSession = action.payload;
      const storeSession = stores[0];
      const payload = { date: dateSession, store: storeSession };
      yield getStoreSessionsWorker({ payload });
    }
  } else {
    yield put({
      type: appImagesTypes.getStores.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

function* getStoreSessionsWorker(action) {
  const date = action.payload.date.fl_start_date
    .split('/')
    .reverse()
    .reduce((a, c) => `${a}-${c}`);
  const store = action.payload.store.value;
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_APP_IMAGES_GET_STORE_SESSIONS,
    payload: { date, store },
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    const sessions = data.data;
    if (sessions.length > 0) {
      const photosDate = action.payload.date;
      const photosStore = action.payload.store;
      const photosSession = sessions[0];
      const photosData = {
        payload: {
          date: photosDate,
          store: photosStore,
          session: photosSession
        }
      };
      yield getPhotosWorker(photosData);
    }
    yield put({
      type: appImagesTypes.getStoreSessions.success,
      payload: { response: sessions, ...action.payload }
    });
  } else {
    yield put({
      type: appImagesTypes.getStoreSessions.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

function* getPhotosWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_APP_IMAGES_GET_PHOTOS,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    const photos = data.data.photos.map(item => ({
      ...item,
      url: item.url,
      direction: item.direction,
      flags: item.flags,
      border_color: item.border_color,
      session_id: item.session_id
    }));

    yield put({
      type: appImagesTypes.setAppImageFilters,
      payload: action.payload.params
    });
    yield put({
      type: appImagesTypes.getPhotos.success,
      payload: {
        response: photos,
        photoAttributes: data.data.photoAttributes,
        ...action.payload.params
      }
    });
  } else {
    yield put({
      type: appImagesTypes.getPhotos.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

function* downloadPhotosWorker(action) {
  const {
    date,
    downloadSingle,
    stores,
    session,
    downloadMeta
  } = action.payload;
  let { store } = action.payload;
  const allStores = yield select(getStores);
  const allSessions = yield select(getSessions);
  let params = !downloadSingle
    ? {
        download_meta: downloadMeta,
        is_bulk: true,
        date: {
          from: formatDate(date.from),
          to: formatDate(date.to)
        },
        session: []
      }
    : {
        session: [{ id: session }],
        is_bulk: false,
        date: {
          from: formatDate(date.from),
          to: formatDate(date.to)
        }
      };

  if (!store && !session && downloadSingle) {
    // eslint-disable-next-line prefer-destructuring
    store = [{ id: allStores[0].value }];
    // eslint-disable-next-line prefer-destructuring
    params = {
      ...params,
      session: [{ id: allSessions[0].value }],
      is_bulk: false
    };
  } else if (store && !session && downloadSingle) {
    params = {
      ...params,
      session: [{ id: allSessions[0].value }],
      is_bulk: false,
      store: [{ id: store }]
    };
  }
  if (store) {
    params = {
      ...params,
      store: [{ id: store }]
    };
  }
  if (!downloadSingle)
    store = stores.map(el => {
      return { id: el };
    });

  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_APP_IMAGES_DOWNLOAD,
    payload: {
      is_bulk: !downloadSingle,
      store,
      ...params
    },
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      type: appImagesTypes.downloadPhotos.success,
      payload: { response: data, ...action.payload }
    });
  } else {
    yield put({
      type: appImagesTypes.downloadPhotos.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

function* getImageDownloadOptions(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_APP_IMAGES_GET_DOWNLOAD_OPTIONS,
    payload: {},
    headers: ['auth'],
    useCachedInstance: true
  });
  if (response) {
    yield put({
      type: appImagesTypes.getImageActionTypes.success,
      payload: response.data.data.modalBox.choices
    });
  } else {
    yield put({
      type: appImagesTypes.getImageActionTypes.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

function* getStoresRange(action) {
  const { fl_start_date, fl_end_date } = action.payload;
  const startDate = getDate(fl_start_date);
  const endDate = getDate(fl_end_date);

  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_APP_IMAGES_GET_STORES,
    payload: { date: startDate, end_date: endDate },
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const stores = response.data.data.map(item => {
      return { label: item.store_title, value: item.store_id };
    });
    yield put({
      type: appImagesTypes.getStoresRange.success,
      payload: { response: stores }
    });
  } else {
    yield put({
      type: appImagesTypes.getStoresRange.failure,
      payload: { response: error, ...action.payload }
    });
  }
}

const watcherSaga = [
  takeLatest(appImagesTypes.getStores.request, getStoresWorker),
  takeLatest(appImagesTypes.getStoreSessions.request, getStoreSessionsWorker),
  takeLatest(appImagesTypes.getPhotos.request, getPhotosWorker),
  takeLatest(appImagesTypes.downloadPhotos.request, downloadPhotosWorker),
  takeLatest(appImagesTypes.getStoresRange.request, getStoresRange),
  takeLatest(
    appImagesTypes.getImageActionTypes.request,
    getImageDownloadOptions
  )
];

export default watcherSaga;

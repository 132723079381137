import React from 'react';
import { PropTypes } from 'prop-types';
// charting library for react
import {
  BarChart,
  Bar,
  ErrorBar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from 'recharts';
// core components
import { XAxisTickFormatter, YAxisTickFormatter } from 'charts/AxisTick';
// styles
import colors from 'charts/__variables';
import './_styles.scss';

const useColors = [
  { primary: '#F36' },
  { primary: '#FFFF00' },
  { primary: '#00EAFF' },
  { primary: '#AA00FF' },
  { primary: '#FF7F00' },
  { primary: '#BFFF00' },
  { primary: '#0095FF' },
  { primary: '#FF00AA' },
  { primary: '#FFD400' },
  { primary: '#0040FF' },
  { primary: '#EDB9B9' },
  { primary: '#B9D7ED' },
  { primary: '#E7E9B9' },
  { primary: '#DCB9ED' },
  { primary: '#B9EDE0' },
  { primary: '#8F2323' },
  { primary: '#23628F' },
  { primary: '#8F6A23' },
  { primary: '#6B238F' },
  { primary: '#4F8F23' },
  { primary: '#000000' },
  { primary: '#737373' },
  { primary: '#CCCCCC' }
];

// Customized Label component to show label values by default on Bar, pass this as a label prop to Bar component of Chart
/*
const CustomizedLabel = ({ x, y, value }) => {
  return (
    <text
      x={x + 25}
      y={y}
      dy={-4}
      fill="white"
      fontSize={14}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
*/

/**
 * @class
 * @hideconstructor
 * @description Bar chart component
 */
const SimpleBarChart = ({ color: useColor, data }) => (
  <ResponsiveContainer className="bar-wrapper" height="97%">
    <BarChart
      data={data.datasets}
      margin={{
        top: 5,
        right: 20,
        left: 20,
        bottom: 5
      }}
    >
      <defs>
        <linearGradient id="bargrad1" x1="50%" y1="0%" x2="50%" y2="100%">
          <stop offset="0%" stopColor={colors.dark[useColor].secondary} />
          <stop offset="100%" stopColor={colors.dark[useColor].opaque} />
        </linearGradient>
      </defs>
      <XAxis
        axisLine={false}
        stroke={colors.dark.axisStroke}
        dataKey={data.xAxisDataKey}
        tickLine={false}
        tick={<XAxisTickFormatter />}
      />
      <YAxis
        axisLine={false}
        tick={<YAxisTickFormatter />}
        stroke={colors.dark.axisStroke}
      >
        {data.yAxisLabel
          ? data.yAxisLabel.show && (
              <Label
                value={data.yAxisLabel.label}
                angle={-90}
                position="center"
              />
            )
          : null}
      </YAxis>
      <Legend wrapperStyle={{ bottom: -20 }} iconType="circle" />
      <Tooltip cursor={false} />
      {data.useError ? (
        <Bar
          type="monotone"
          maxBarSize={50}
          dataKey={data.yAxisDataKey}
          stroke={colors.dark[useColor].primary}
          fill="url(#bargrad1)"
        >
          <ErrorBar
            dataKey="errorX"
            width={4}
            strokeWidth={1}
            stroke={colors.dark[useColor].primary}
          />
        </Bar>
      ) : (
        data.yAxisDataKey.map((key, i) =>
          data.isStacked ? (
            <Bar
              key={key}
              stackId="1"
              type="monotone"
              maxBarSize={50}
              dataKey={key}
              stroke={useColors[i].primary}
              fill={useColors[i].primary}
              // label={CustomizedLabel}
            />
          ) : (
            <Bar
              key={key}
              type="monotone"
              maxBarSize={50}
              dataKey={key}
              stroke={useColors[i].primary}
              fill={useColors[i].primary}
              // label={CustomizedLabel}
            />
          )
        )
      )}
    </BarChart>
  </ResponsiveContainer>
);

// component properties
SimpleBarChart.propTypes = {
  /**
   * @type {string}
   * @description - Color of the bars and background - 'green' | 'blue' | 'pink'|
   * 'gray' | 'orange' | 'yellow'
   */
  color: PropTypes.string,
  /**
   * @type {object}
   * @description - data to build the chart
   */
  data: PropTypes.object.isRequired
};

SimpleBarChart.defaultProps = {
  color: 'green'
};

export default SimpleBarChart;

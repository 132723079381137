import React, { useState } from 'react';
import { number, func, string } from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';

const PaginationComponent = ({
  classNames,
  offset,
  limit,
  total,
  onPageChange
}) => {
  const [activePage, setActivePage] = useState(offset / limit + 1);
  const handlePageChange = (_e, page) => {
    setActivePage(page);
    onPageChange({ offset: limit * (page - 1), page, total, limit });
  };

  return (
    <div className={classNames}>
      <Pagination
        count={Math.ceil(total / limit)}
        variant="outlined"
        color="primary"
        page={activePage}
        onChange={(_e, page) => handlePageChange(_e, page)}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  classNames: string,
  offset: number.isRequired,
  limit: number.isRequired,
  total: number.isRequired,
  onPageChange: func.isRequired
};

PaginationComponent.defaultProps = {
  classNames: ''
};

export default PaginationComponent;

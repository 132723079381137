/* eslint-disable react/no-unused-prop-types */
import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
import styled from 'styled-components';
// library to concatenate classes
import classNames from 'classnames';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// assets
import typographyStyles from 'assets/jss/material-ui/components/typographyStyles';

const Styledh4 = styled.h4`
  && {
    color: ${props => `${props.color} !important`};
    font-size: ${props => `${props.fontSize} !important`};
  }
`;

/**
 * @class
 * @hideconstructor
 * @description Core Typography component
 */
const Typo = ({
  classes,
  weight,
  styles,
  font,
  children,
  className,
  cardTitle,
  filterTitle,
  center,
  fontSize,
  textColor
}) => {
  const fontClasses = classNames({
    [classes.default]: true,
    [classes[font]]: font !== 'Helvetica Neue' ? font : false,
    [classes[weight]]: weight,
    [classes.cardTitle]: cardTitle,
    [classes.filterTitle]: filterTitle,
    [classes.center]: center,
    [className]: className
  });
  return (
    <Styledh4
      style={styles}
      className={fontClasses}
      color={textColor}
      fontSize={fontSize}
    >
      {children}
    </Styledh4>
  );
};

// component properties
Typo.propTypes = {
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  styles: PropTypes.object.isRequired,
  /**
   * @type {string}
   * @description - Font color
   */
  color: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @description - Font size - 'xs' | 'sm' | 'md'| 'l' | 'xl' | 'xxl'
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * @type {string}
   * @description - Font weight - 'light' | 'medium' | 'bold'| 'bolder'
   */
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * @type {string}
   * @description - Font family
   */
  font: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @description - Classname to apply to the root element
   */
  className: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @description - Text alignment
   */
  align: PropTypes.string.isRequired,
  /**
   * @type {boolean}
   * @description - Whether the text is card's title
   */
  cardTitle: PropTypes.bool.isRequired,
  /**
   * @type {boolean}
   * @description - Align the text centered horizontally and vertically
   */
  center: PropTypes.bool.isRequired,
  /**
   * @type {function | element}
   * @description - Child elements
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  fontSize: PropTypes.string,
  textColor: PropTypes.string
};

Typo.defaultProps = {
  fontSize: '',
  textColor: ''
};

const Typography = withStyles(typographyStyles, { withTheme: true })(props => (
  <Typo {...props} />
));

Typography.defaultProps = {
  color: 'light25',
  size: 'sm',
  weight: 500,
  styles: {},
  font: 'Helvetica Neue',
  transform: '',
  className: '',
  cardTitle: false,
  filterTitle: false,
  center: false,
  align: 'left'
};

export default Typography;

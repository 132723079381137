import { createAction } from '@reduxjs/toolkit';
import { accuracyTypes } from 'redux-utils/types/index';
import reducerModel from 'models/reducerModel';

const getFilters = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getFilters.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getSessionList = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getSessionList.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getSessionDetails = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getSessionDetails.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const filterChange = (
  payload,
  meta = {
    reducerID: [reducerModel.accuracy.id]
  }
) => {
  const action = createAction(accuracyTypes.filterChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getChartList = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getChartList.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getChartData = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getChartData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const sessionChange = (
  payload,
  meta = {
    reducerID: [reducerModel.accuracy.id]
  }
) => {
  const action = createAction(accuracyTypes.sessionChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getScheduledReports = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.getScheduledReports.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getScheduledReportsMetaData = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(
    accuracyTypes.getScheduledReportsMetaData.request
  )({
    ...payload
  });
  action.meta = meta;
  return action;
};

const postScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.postScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const updateScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.updateScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const deleteScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.deleteScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadReport = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.downloadReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const resetSessionDetails = (
  payload,
  meta = { reducerID: [reducerModel.accuracy.id] }
) => {
  const action = createAction(accuracyTypes.resetSessionDetails)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  filterChange,
  getFilters,
  getSessionDetails,
  getSessionList,
  getChartData,
  getChartList,
  sessionChange,
  getScheduledReports,
  getScheduledReportsMetaData,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport,
  downloadReport,
  resetSessionDetails
};

// icons
import {
  DashboardIcon,
  ShelfIcon,
  DisplayIcon,
  OSAIcon,
  PositionIcon,
  ExplorerIcon
} from 'assets/icons/appIcons';
// views
import ChartRenderContainer from 'pages/Common/ChartRenderContainer';
import StoreExplorerContainer from 'pages/StoreExplorer/StoreExplorerContainer';
import StoreExplorerContainerNew from 'pages/StoreExplorerNew/StoreExplorerContainerNew';
import SEContainer from 'pages/SE/SEContainer';
import SEPGContainer from 'pages/SE-PG/SEContainer';
import TestStoreExplorerContainer from 'pages/TestStoreExplorer/TestStoreExplorerContainer';
import AppImagesContainer from 'pages/AppImages/AppImagesContainer';
import AdminContainer from 'pages/Admin/AdminContainer';
import AccuracyContainer from 'pages/Accuracy/AccuracyContainer';
import MerchEvaluationContainer from 'pages/MerchEvaluation/MerchEvaluationContainer';
import AnalyticsRedirect from 'pages/AnalyticsRedirect/AnalyticsRedirect';
import SurveyContainer from 'pages/Survey/SurveyContainer';

const menuManifest = {
  dashboard: {
    path: '/app/dashboard',
    icon: DashboardIcon,
    component: ChartRenderContainer,
    navbarName: 'Dashboard'
  },
  admin: {
    path: '/app/admin',
    icon: DashboardIcon,
    component: AdminContainer,
    navbarName: 'Admin'
  },
  'visibility-score': {
    path: '/app/visibility-score',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'SKU Assortment'
  },
  opportunity: {
    path: '/app/opportunity',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Opportunity'
  },
  availability: {
    path: '/app/availability',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Availability'
  },
  'vgp-score': {
    path: '/app/vgp-score',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'SKU Assortment'
  },
  execution: {
    path: '/app/execution',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'SKU Assortment'
  },
  'sku-assortment': {
    path: '/app/sku-assortment',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'SKU Assortment'
  },
  'brand-penetration': {
    path: '/app/brand-penetration',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Brand Penetration'
  },
  distribution: {
    path: '/app/distribution',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Distribution'
  },
  'category-view': {
    path: '/app/category-view',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Category View'
  },
  'variant-assortment': {
    path: '/app/variant-assortment',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'Variant Assortment'
  },
  overview: {
    path: '/app/overview',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Overview'
  },
  'source-of-purchase': {
    path: '/app/source-of-purchase',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Source Of Purchase'
  },
  profile: {
    path: '/app/profile',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Profile'
  },
  'store-branding': {
    path: '/app/store-branding',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Store Branding'
  },
  'visi-cooler-share-of-shelf': {
    path: '/app/visi-cooler-share-of-shelf',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Visi Cooler Share Of Shelf'
  },
  'posm-share': {
    path: '/app/posm-share',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Posm Share'
  },
  'linear-share-of-shelf': {
    path: '/app/linear-share-of-shelf',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Linear SOS'
  },
  'draught-branding': {
    path: '/app/draught-branding',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Draught Branding'
  },
  'share-of-shelf': {
    path: '/app/share-of-shelf',
    icon: ShelfIcon,
    component: ChartRenderContainer,
    navbarName: 'Share Of Shelf'
  },
  'on-shelf-availability': {
    path: '/app/on-shelf-availability',
    icon: OSAIcon,
    component: ChartRenderContainer,
    navbarName: 'On Shelf Availability'
  },
  'product-position': {
    path: '/app/product-position',
    icon: PositionIcon,
    component: ChartRenderContainer,
    navbarName: 'Product Position'
  },
  'fsu-planogram-compliance': {
    path: '/app/fsu-planogram-compliance',
    icon: PositionIcon,
    component: ChartRenderContainer,
    navbarName: 'FSU Planogram Compliance'
  },
  'new-store-explorer': {
    path: '/app/new-store-explorer',
    icon: ExplorerIcon,
    component: StoreExplorerContainerNew,
    navbarName: 'Store Explorer (SOS)'
  },
  'test-store-explorer': {
    path: '/app/test-store-explorer',
    icon: ExplorerIcon,
    component: TestStoreExplorerContainer,
    navbarName: 'Test Store Explorer'
  },
  'store-explorer-sos': {
    path: '/app/store-explorer-sos',
    icon: ExplorerIcon,
    component: StoreExplorerContainer,
    navbarName: 'Store Explorer (SOS)'
  },
  'store-explorer-fsu': {
    path: '/app/store-explorer-fsu',
    icon: ExplorerIcon,
    component: StoreExplorerContainer,
    navbarName: 'Store Explorer (FSU)'
  },
  display: {
    path: '/app/display',
    icon: DisplayIcon,
    component: ChartRenderContainer,
    navbarName: 'Display'
  },
  'app-images': {
    path: '/app/app-images',
    icon: DisplayIcon,
    component: AppImagesContainer,
    navbarName: 'App Images'
  },
  'survey': {
    path: '/app/survey',
    icon: DisplayIcon,
    component: SurveyContainer,
    navbarName: 'Survey'
  },
  'store-explorer': {
    path: '/app/store-explorer',
    icon: ExplorerIcon,
    component: StoreExplorerContainer,
    navbarName: 'Store Explorer'
  },
  'analytics-redirect': {
    path: '/app/analytics-redirect',
    icon: ExplorerIcon,
    component: AnalyticsRedirect,
    navbarName: 'Analytics'
  },
  accuracy: {
    path: '/app/accuracy',
    icon: ExplorerIcon,
    component: AccuracyContainer,
    navbarName: 'Accuracy page'
  },
  'merchandiser-performance': {
    path: '/app/merchandiser-performance',
    icon: ExplorerIcon,
    component: MerchEvaluationContainer,
    navbarName: 'Merchandizer page'
  },
  'visibility-store-explorer': {
    path: '/app/visibility-store-explorer',
    icon: ExplorerIcon,
    component: SEContainer,
    navbarName: 'Store Explorer'
  },
  'pgindo-store-explorer': {
    path: '/app/pgindo-store-explorer',
    icon: ExplorerIcon,
    component: SEPGContainer,
    navbarName: 'Store Explorer'
  },
  'store-explorer-v2': {
    path: '/app/store-explorer-v2',
    icon: ExplorerIcon,
    component: SEPGContainer,
    navbarName: 'Store Explorer'
  },
  redirect: {
    redirect: true,
    path: '/app',
    to: '/app/share-of-shelf'
  }
};

export default menuManifest;

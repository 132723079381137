import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Created to build a landing page for Infiviz.
 */

const LandingPage = () => <Redirect to="/app" />;

export default LandingPage;

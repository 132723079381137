import { createAction } from '@reduxjs/toolkit';
import { appImagesTypes } from 'redux-utils/types';

const getStores = createAction(appImagesTypes.getStores.request);
const getStoresSuccess = createAction(appImagesTypes.getStores.success);
const getStoresFailure = createAction(appImagesTypes.getStores.failure);

const getStoreSessions = createAction(appImagesTypes.getStoreSessions.request);
const getStoresSessionsSuccess = createAction(
  appImagesTypes.getStoreSessions.success
);
const getStoresSessionsFailure = createAction(
  appImagesTypes.getStoreSessions.failure
);

const getPhotos = createAction(appImagesTypes.getPhotos.request);
const getPhotosSuccess = createAction(appImagesTypes.getPhotos.success);
const getPhotosFailure = createAction(appImagesTypes.getPhotos.failure);

const downloadPhotos = createAction(appImagesTypes.downloadPhotos.request);
const downloadPhotosSuccess = createAction(
  appImagesTypes.downloadPhotos.success
);
const downloadPhotosFailure = createAction(
  appImagesTypes.downloadPhotos.failure
);

const getStoresRange = createAction(appImagesTypes.getStoresRange.request);
const getStoresRangeSuccess = createAction(
  appImagesTypes.getStoresRange.success
);
const getStoresRangeFailure = createAction(
  appImagesTypes.getStoresRange.failure
);

const clearBulkStores = createAction(appImagesTypes.clearBulkStores.request);
const initializeAppImages = createAction(
  appImagesTypes.initializeAppImages.request
);
const initializeSuccessApis = createAction(
  appImagesTypes.initializeSuccessAPI.request
);
const getImageActionTypes = createAction(
  appImagesTypes.getImageActionTypes.request
);
const resetAppIagesDownloadState = createAction(
  appImagesTypes.downloadPhotos.reset
);
const clearAppImages = createAction(appImagesTypes.getPhotos.clear);

export {
  getStores,
  getStoresSuccess,
  getStoresFailure,
  getStoreSessions,
  getStoresSessionsSuccess,
  getStoresSessionsFailure,
  getPhotos,
  getPhotosSuccess,
  getPhotosFailure,
  downloadPhotos,
  downloadPhotosSuccess,
  downloadPhotosFailure,
  getStoresRange,
  getStoresRangeSuccess,
  getStoresRangeFailure,
  clearBulkStores,
  clearAppImages,
  initializeAppImages,
  initializeSuccessApis,
  getImageActionTypes,
  resetAppIagesDownloadState
};

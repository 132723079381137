import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

// utils
import ls from 'lib/core/storageFactory';
import { authActions } from 'redux-utils/actions/index';
import { appSelector } from 'redux-utils/selectors/index';

// assets
import backIcon from 'assets/svg/login_back_icon.svg';
import infivizLogo from 'assets/svg/infiviz_logo.svg';

// components
import StaticsContent from './StaticsContent';
import LoginVectorBackground from './LoginVectorBackground';
import Notification from 'components/Notification/Notification';
import LoginContainer from './LoginContainer';
import Cookies from 'universal-cookie';

// stylesheet
import './index.css';
import { LoginHeader } from './constants';
import { useLocation } from 'react-router-dom';

const cookies = new Cookies();

const Index = ({
  dispatchClientRequest,
  dispatchLoginRequest,
  dispatchForgotPasswordRequest,
  authState,
  location,
  history
}) => {
  const [isClientFieldActive, setClientFieldActive] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hasSSOLogin, setHasSSOLogin] = useState(false);
  const [ssoDetails, setSsoDetails] = useState({
    showHybrid: false,
    text: '',
    link: '',
    lable: '',
    sso_logo: ''
  });
  const [notification, setNotification] = useState({
    display: false,
    message: ''
  });

  useEffect(() => {
    if (location.pathname.includes('forgotPassword')) {
      setForgotPassword(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    let timeout;
    if (authState.forgotPassword && authState.forgotPassword.message) {
      setNotification({
        display: true,
        message: authState.forgotPassword.message
      });
      timeout = setTimeout(() => {
        window.location = '/login';
      }, [3000]);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [authState.forgotPassword]);

  useEffect(() => {
    setClientFieldActive(!authState.client.client_exist);

    // check for these conditions -
    //     {
    //       SSO true, hybrid true - show SSO button
    //       SSO true, hybrid false - redirect user
    //       SSO false - username and pass
    //     }

    if (authState.client.sso && !authState.client.show_hybrid_sso) {
      if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
        setHasSSOLogin(authState.client.sso);
        window.location = authState.client.sso_link;
      } else {
        setHasSSOLogin(false);
      }
    } else if (authState.client.sso && authState.client.show_hybrid_sso) {
      setHasSSOLogin(authState.client.sso);

      setSsoDetails({
        showHybrid: authState.client.show_hybrid_sso,
        text: authState.client.text_on_sso_button,
        link: authState.client.sso_link,
        lable: authState.client.sso_description,
        sso_logo: authState.client.sso_logo
      });
    } else {
      setHasSSOLogin(false);
      setSsoDetails({
        showHybrid: false,
        text: '',
        link: '',
        lable: '',
        sso_logo: ''
      });
    }
  }, [authState.client]);

  const historyConst = useLocation();
  useEffect(() => {
    if (authState.apiStatus.client.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.client.message
      });
    }
    if (authState.apiStatus.login.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.login.message
      });
    }
    if (authState.apiStatus.login.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.login.message
      });
    }
    if (authState.apiStatus.forgotPassword.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.forgotPassword.message
      });
    }
  }, [authState.apiStatus]);

  useEffect(() => {
    if (authState.isLoggedIn || ls.get('isLoggedIn')) {
      ls.set('isLoggedIn', true);
      let pathname = '/app';

      if (cookies.get('prevPath')) {
        pathname = cookies.get('prevPath');

        if (cookies.get('searchPath')) {
          pathname = pathname + cookies.get('searchPath');
        }

        cookies.remove('prevPath', {
          path: '/',
          domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
          sameSite: 'none',
          secure: 'true'
        });
        cookies.remove('searchPath', {
          path: '/',
          domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
          sameSite: 'none',
          secure: 'true'
        });
      }
      if (historyConst.state?.from.pathname) {
        pathname = historyConst.state?.from.pathname;

        if (historyConst.state?.from.search)
          pathname = pathname + historyConst.state?.from.search;
      }
      if (authState.analyticsRedirect) {
        if (
          authState.analyticsRedirect.has_access &&
          authState.analyticsRedirect.dashboardType === 'tableau'
        ) {
          window.location.replace(authState.analyticsRedirect.redirect_url);
        } else if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
          const domain = ls.get('domain');
          window.location.replace(
            `https://${domain.toLowerCase()}.${
              process.env.REACT_APP_SHELF_REDIRECT_URI
            }${pathname}`
          );
        } else {
          window.location.replace(pathname);
        }
      } else if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
        const domain = ls.get('domain');
        window.location.replace(
          `https://${domain.toLowerCase()}.${
            process.env.REACT_APP_SHELF_REDIRECT_URI
          }${pathname}`
        );
      } else {
        window.location.replace('/app');
      }
    }
  }, [authState.isLoggedIn, authState.analyticsRedirect]);

  const handleNotificationClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      display: false,
      message: ''
    });
  };

  const handleForgotPassword = () => {
    history.push('/login/forgotPassword');
  };
  const goPrevStep = () => {
    setHasSSOLogin(false);
    setSsoDetails({
      showHybrid: false,
      text: '',
      link: '',
      lable: ''
    });
    ls.clear();
    setClientFieldActive(true);
  };

  return (
    <div className="loginContainer">
      <Notification
        duration={3000}
        message={notification.message}
        isOpen={notification.display}
        handleClose={handleNotificationClose}
      />
      <div className="staticContentWrapper">
        <StaticsContent />
      </div>
      <div className="loginWrapper">
        <LoginVectorBackground />
        <div className="mobileViewHeader">{LoginHeader}</div>
        <div className="loginContainerWrapper">
          <div className="loginContainerHeader">
            <img src={infivizLogo} alt="infiviz_logo"></img>
          </div>
          <div className="loginContainerBody">
            <div role="button" className="loginBackButton">
              {!isClientFieldActive && (
                <img src={backIcon} alt="backIcon" onClick={goPrevStep} />
              )}
            </div>
            <div className="loginContainerBodyCentered">
              {/* Only show thi lable while user is being redirected */}
              {authState.client.sso &&
                !authState.client.show_hybrid_sso &&
                !isClientFieldActive &&
                hasSSOLogin && (
                  <p className="loginContainerLable">
                    <b>Please wait, you are being redirected. </b>
                  </p>
                )}

              <LoginContainer
                authState={authState}
                forgotPassword={forgotPassword}
                isClientFieldActive={isClientFieldActive}
                ssoDetails={ssoDetails}
                submitForgotPasswordEmail={dispatchForgotPasswordRequest}
                handleForgotPassword={handleForgotPassword}
                handleClientValidation={dispatchClientRequest}
                handleLoginValidation={dispatchLoginRequest}
                hasSSOLogin={hasSSOLogin}
                validationError={message => {
                  setNotification({
                    display: true,
                    message: message
                  });
                }}
              ></LoginContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// component properties
Index.propTypes = {
  location: object.isRequired,
  history: object.isRequired,
  authState: object.isRequired,
  dispatchClientRequest: func.isRequired,
  dispatchLoginRequest: func.isRequired,
  dispatchForgotPasswordRequest: func.isRequired
};

/*
    Connect redux store state to props so that you can access the state
    from the scope of the component's props
  */
const mapStateToProps = state => ({
  authState: appSelector.getAuthState(state)
});

/*
    Connect dispatch methods to props so that you can call the methods
    from the scope of the component's props
  */
const mapDispatchToProps = dispatch => ({
  dispatchLoginRequest: payload =>
    dispatch(authActions.userLoginRequest(payload)),
  dispatchClientRequest: payload =>
    dispatch(authActions.clientValidationRequest(payload)),
  dispatchForgotPasswordRequest: payload =>
    dispatch(authActions.forgotPasswordRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);

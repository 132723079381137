import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
// core components
import TableWithLine from 'table/TableWithLine';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Typography from 'components/Typography/Typography';
import Icon from 'components/Icon/Icon';
import {
  Pin,
  DownloadIcon,
  ArrowUpward,
  ArrowDownward
} from 'assets/icons/appIcons';
import withStyles from '@material-ui/core/styles/withStyles';
import colors from 'styles/__variables';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const previousWrapperStyles = {
  red: {
    color: colors.dark.roseColor,
    fontWeight: 'normal',
    '& $icon': {
      '& svg': {
        fill: colors.dark.roseColor
      }
    }
  },
  value: {
    color: '#fff !important',
    fontWeight: 'normal'
  },
  green: {
    color: 'green',
    fontWeight: 'normal',
    '& $icon': {
      '& svg': {
        fill: 'green'
      }
    }
  },
  root: {
    display: 'flex'
  },
  icon: {
    '& div': {
      fontSize: '10px !important'
    },
    '& svg': {
      width: '24px !important',
      height: '32px !important'
    }
  }
};

const computeDifference = (current, previous, classes) => {
  const difference = Math.round(Math.abs(current - previous) * 100) / 100;
  let differenceIcon;
  if (current > previous) {
    differenceIcon = (
      <Icon>
        <ArrowUpward />
      </Icon>
    );
  } else if (current < previous) {
    differenceIcon = (
      <Icon>
        <ArrowDownward />
      </Icon>
    );
  }
  const toolTipValue = (
    <div
      className={`${classes.root} ${
        current < previous ? classes.red : classes.green
      }`}
    >
      <p className={classes.value}>{current}</p>
      {difference !== 0 ? (
        <>
          <span className={classes.icon}>{differenceIcon}</span>
          <p>{difference}</p>
        </>
      ) : (
        <p>-</p>
      )}
    </div>
  );
  return toolTipValue;
};

const TableWithLineWrapper = ({
  data,
  classes,
  classesList,
  height,
  maxHeight,
  isPinned,
  usePin,
  useExport,
  chartName,
  title,
  handlePinClick
}) => {
  let useDifference = false;
  const datasets = data.datasets.map(el => {
    const obj = { ...el };
    let { current: value } = obj;
    if (el.previous) {
      useDifference = true;
    }
    if (useDifference) {
      value = computeDifference(obj.current, obj.previous, classes);
    }
    return { ...el, current: value, previous: null };
  });
  return (
    <div>
      <Card className={classesList.card} chart>
        <CardHeader chart float="right">
          <Typography className={classesList.title} cardTitle>
            {title}
          </Typography>
          <div className={classesList.actionButtons}>
            {useExport ? (
              <Tooltip title="Download data" aria-label="Download data">
                <Icon color="light" id={`${chartName}-excel-download-button`}>
                  <DownloadIcon />
                </Icon>
              </Tooltip>
            ) : null}
            {usePin ? (
              <Tooltip
                title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
                aria-label={
                  isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
                }
              >
                <Icon color={isPinned ? 'primary' : 'light'}>
                  <Pin
                    onClick={() => handlePinClick({ isPinned, chartName })}
                  />
                </Icon>
              </Tooltip>
            ) : null}
          </div>
        </CardHeader>
        <CardBody height={height} maxheight={maxHeight} chart table>
          {data.datasets.length === 0 ? (
            <Typography center color="light25" size="xs">
              {client.includes('kcc-ru')
                ? 'Нет данных для отображения'
                : 'No data to display'}
            </Typography>
          ) : (
            <TableWithLine data={{ ...data, datasets }} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

TableWithLineWrapper.propTypes = {
  chartName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  classesList: PropTypes.object.isRequired,
  height: PropTypes.string,
  isPinned: PropTypes.bool,
  useExport: PropTypes.bool,
  usePin: PropTypes.bool,
  handlePinClick: PropTypes.func.isRequired,
  maxHeight: PropTypes.string
};

TableWithLineWrapper.defaultProps = {
  height: '300px',
  maxHeight: '300px',
  isPinned: false,
  useExport: false,
  usePin: true
};

export default withStyles(previousWrapperStyles)(TableWithLineWrapper);

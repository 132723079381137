export const chartData = {
  allow_sorting: true,
  sort_options: [
    {
      label: 'Alphabetical',
      value: 'alphabetical'
    },
    {
      label: 'Low to High',
      value: 'low_to_high'
    },
    {
      label: 'High to Low',
      value: 'high_to_low'
    }
  ],
  type: 'horizontal-bar',
  xAxisLabel: { label: 'in(%)', display_axis: true, show: true },
  yAxisLabel: {
    label: 'Merchandisers',
    show: true,
    display_axis: true
  },
  xAxisDataKey: 'name',
  yAxisDataKey: ['uv'],
  title: 'Supervisors',
  datasets: [
    {
      name: 'Page 1',
      uv: 100,
      color: '#f36'
    },
    {
      name: 'Page 2',
      uv: 200,
      color: '#f36'
    },
    {
      name: 'Page 3',
      uv: 400,
      color: '#00AF64'
    },
    {
      name: 'Page 4',
      uv: 30,
      color: '#f36'
    },
    {
      name: 'Page 5',
      uv: 500,
      color: '#00AF64'
    },
    {
      name: 'Page 6',
      uv: 10,
      color: '#f36'
    },
    {
      name: 'Page 7',
      uv: 90,
      color: '#f36'
    },
    {
      name: 'Page 8',
      uv: 60,
      color: '#f36'
    },
    {
      name: 'Page 9',
      uv: 123,
      color: '#f9e64f'
    },
    {
      name: 'Page 10',
      uv: 320,
      color: '#f9e64f'
    },
    {
      name: 'Page 11',
      uv: 490,
      color: '#00AF64'
    },
    {
      name: 'Page 12',
      uv: 231,
      color: '#f9e64f'
    },
    {
      name: 'Page 13',
      uv: 178,
      color: '#f9e64f'
    },
    {
      name: 'Page 14',
      uv: 456,
      color: '#00AF64'
    },
    {
      name: 'Page 15',
      uv: 356,
      color: '#f9e64f'
    },
    {
      name: 'Page 16',
      uv: 179,

      color: '#f9e64f'
    },
    {
      name: 'Page 17',
      uv: 44,

      color: '#f36'
    },
    {
      name: 'Page 18',
      uv: 354,
      color: '#f9e64f'
    },
    {
      name: 'Page 19',
      uv: 410,
      color: '#00AF64'
    },
    {
      name: 'Page F',
      uv: 45,
      color: '#f36'
    }
  ]
};

export const tableData = {
  status: 200,
  data: {
    'photo-quality': {
      performance_table: {
        columns: [
          {
            dataKey: 'merchandizers',
            type: 'text',
            label: 'Merchandizers'
          },
          {
            dataKey: 'invalid',
            type: 'performance_number',
            label: 'Invalid',
            subheader: '(0)'
          },
          {
            dataKey: 'blur',
            type: 'performance_number',
            label: 'Blur',
            subheader: '(0)'
          },
          {
            dataKey: 'slant',
            type: 'performance_number',
            label: 'Slant',
            subheader: '(0)'
          },
          {
            dataKey: 'wrong_direction',
            type: 'performance_number',
            label: 'Wrong Direction',
            subheader: '(0)'
          },
          {
            dataKey: 'wrong_task',
            type: 'performance_number',
            label: 'Wrong Task',
            subheader: '(0)'
          }
        ],
        datasets: [
          {
            merchandizers: 'Max',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          },
          {
            merchandizers: 'John',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          },
          {
            merchandizers: 'Susan',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          }
        ]
      }
    }
  }
};

import { createSelector } from '@reduxjs/toolkit';

const getChartData = (state, props) => {
  const { chartData } = state.chartStore;
  if (chartData !== undefined) {
    return chartData[props.routeID];
  }
  return {};
};

const makeGetChartData = () => createSelector([getChartData], data => data);

const downloadChartData = (state, props) => {
  const { reports } = state.chartStore;
  if (reports !== undefined) {
    return reports[props.routeID];
  }
  return {};
};

const makeDownloadChartData = () =>
  createSelector([downloadChartData], data => data);

const getChartList = (state, props) => {
  const { charts } = state.chartStore;
  if (charts !== undefined) {
    return charts.list[props.routeID];
  }
  return [];
};

const makeGetChartList = () => createSelector([getChartList], charts => charts);

const getGranularitiesList = (state, props) =>
  state.appStore.preferences.granularities[props.routeID];

const getChartListLoadingState = state => {
  const {
    charts: { isFetching }
  } = state.chartStore;
  return { isFetching };
};

const makeGetGranularitiesList = () =>
  createSelector([getGranularitiesList], grList => grList);

const getFilterPreferences = (state, props) =>
  state.appStore.preferences.filters[props.routeID];

const makeGetParamsValueList = () =>
  createSelector(
    [getFilterPreferences, getGranularitiesList],
    (filterPrefs, grPrefs) => {
      return {
        ...filterPrefs,
        ...grPrefs
      };
    }
  );

export {
  getChartListLoadingState,
  makeGetChartList,
  makeGetChartData,
  makeDownloadChartData,
  makeGetGranularitiesList,
  getFilterPreferences,
  makeGetParamsValueList
};

import { takeLatest, call, put } from 'redux-saga/effects';
// action types
import { userTypes, cacheTypes } from 'redux-utils/types';
import { get, post } from 'api';
import ls from 'lib/core/storageFactory';

/**
 * @name storeExplorerWatcherSaga
 * @description Catches the action dispatched of certain type.
 */
const watcherSaga = [
  takeLatest(userTypes.getUserProfile.fromAPI, userProfileSaga),
  takeLatest(userTypes.saveUserPreference.request, userPreferenceSaga),
  takeLatest(userTypes.getUserPreference.fromAPI, userPreferenceWorker)
];

/**
 * @description Worker saga to get the user profile information
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* userProfileSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_USER_PROFILE,
    payload: action.payload.params,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
    ls.set('userProfile', data);
  } else {
    yield put({
      type: userTypes.getUserProfile.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* userPreferenceWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_USER_PREFS,
    headers: ['auth'],
    payload: action.payload.params
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: userTypes.getUserPreference.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the user profile information
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* userPreferenceSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_USER_PREFS,
    payload: action.payload.params,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: userTypes.saveUserPreference.success,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      type: userTypes.saveUserPreference.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

export default watcherSaga;

/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isEmpty } from 'lodash';
import { getPreviousDate } from 'lib/core/dateUtils';

// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';

/* Components */
import GridContainer from 'components/Grid/GridContainer';
import DropDown from 'components/Dropdown/Dropdown';
import TimeFilter from 'layout/Filter/TimeFilter';
import Button from 'components/Button/Button';
import Notification from 'components/Notification/Notification';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from 'components/Typography/Typography';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from 'components/Loader/Loader';

import withStyles from '@material-ui/core/styles/withStyles';
import style from 'pages/AppImages/AppImageStyles';
import Arrow from 'assets/svg/arrow.svg';
import Download from 'assets/svg/download.svg';
import Duplicate from 'assets/svg/copy.svg';
import ls from 'lib/core/storageFactory';

import './style.css';
import { Close } from 'assets/icons/appIcons';
import { appImageActions, genericSEActions } from 'redux-utils/actions/index';
import { appImageSelector, appSelector } from 'redux-utils/selectors/index';
import { connect } from 'react-redux';

import ShareIcon from './../../assets/svg/share_icon.svg';

const ai_flags = ['Photos Quality', 'Contrast'];

const client = ls.get('client');

const options = {
  options: [
    {
      label: client
        ? client.includes('kcc-ru')
          ? 'Открыть календарь'
          : 'Open Calendar'
        : 'Open Calendar',
      value: 'custom'
    }
  ]
};

const downloadImage = imageUrl => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.target = '__blank';
  document.body.appendChild(link);
  link.click();
};

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${props => `${props.checkboxColor} !important`};
  }
`;

const StyledRadio = styled(Radio)`
  && {
    color: ${props => `${props.radioColor} !important`};
  }
`;

const AppImagesPage = ({
  selectAllStores,
  handleImageDownload,
  handleImageModalAction,
  downloadBulkOptions,
  bulkStoreMessage,
  isSelectAllStore,
  handleChange,
  bulkStores,
  selectedBulkStores,
  handleBulkStoresChange,
  handleDateRangeChange,
  dateRange,
  handleDateChange,
  handleStoreChange,
  handleSessionChange,
  handleModalAction,
  value,
  notificationSnack,
  stores = [],
  photos = [],
  store,
  session,
  sessions = [],
  modalOpen,
  message,
  classes,
  isLoading,
  dashboardType,
  primaryButtonStyles,
  dashboardPanelStyles,
  dropdownTitleStyles,
  dropdownStyles,
  photoAttributes,
  appImageOptions,
  appImageDownloadOptionsState,
  handleOptionSelected,
  handleAllClicked,
  selectedDownloadMeta,
  allDownloadOptionsSelected,
  setNotificationSnack,
  dispatchResetAppImagesDownloadState,
  downloadBulkCheckBoxOptions,
  dispatchSessionFiltersValuesRequest,
  layout,
}) => {
  const { search, pathname } = useLocation();
  const [photosMeta, setPhotosMeta] = useState({});
  const [sessionId, setSessionId] = useState('');
  const [showModalContent, setShowModalContent] = useState(false);
  const initialSelectedDate = {
    start: getPreviousDate(1, 'days', false),
    end: getPreviousDate(0, 'days', false)
  };
  const handleBulkImageDownloadClick = () => {
    handleModalAction(true);
  };

  useEffect(() => {
    const sessionId = new URLSearchParams(search).get('q');
    console.log('sessionId is', sessionId);
    setSessionId(sessionId);
  }, [search]);

  useEffect(() => {
    if (sessionId) {
      dispatchSessionFiltersValuesRequest({
        params: { q: sessionId, route_id: 'app-images' }
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (photos?.length > 0) {
      const sessionId = photos[0].session_id;
      setSessionId(sessionId);
      window.history.replaceState(null, null, `${pathname}?q=${sessionId}`);
    }
  }, [photos]);

  useEffect(() => {
    if (photos.length > 0) {
      const pCount = photos.reduce(
        (a, c) => {
          if (c.isDuplicate) {
            if (c.isValid) {
              return {
                [client.includes('kcc-ru') ? 'Счетчик' : 'Count']:
                  a[client.includes('kcc-ru') ? 'Счетчик' : 'Count'] + 1,
                [client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate']:
                  a[client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate'] +
                  1,
                [client.includes('kcc-ru') ? 'Активный' : 'Valid']:
                  a[client.includes('kcc-ru') ? 'Активный' : 'Valid'] + 1,
                [client.includes('kcc-ru') ? 'Неактивный' : 'Invalid']: a[
                  client.includes('kcc-ru') ? 'Неактивный' : 'Invalid'
                ]
              };
            }
            return {
              [client.includes('kcc-ru') ? 'Счетчик' : 'Count']:
                a[client.includes('kcc-ru') ? 'Счетчик' : 'Count'] + 1,
              [client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate']:
                a[client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate'] + 1,
              [client.includes('kcc-ru') ? 'Активный' : 'Valid']: a[
                client.includes('kcc-ru') ? 'Активный' : 'Valid'
              ],
              [client.includes('kcc-ru') ? 'Неактивный' : 'Invalid']:
                a[client.includes('kcc-ru') ? 'Неактивный' : 'Invalid'] + 1
            };
          }
          if (c.isValid) {
            return {
              [client.includes('kcc-ru') ? 'Счетчик' : 'Count']:
                a[client.includes('kcc-ru') ? 'Счетчик' : 'Count'] + 1,
              [client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate']: a[
                client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate'
              ],
              [client.includes('kcc-ru') ? 'Активный' : 'Valid']:
                a[client.includes('kcc-ru') ? 'Активный' : 'Valid'] + 1,
              [client.includes('kcc-ru') ? 'Неактивный' : 'Invalid']: a[
                client.includes('kcc-ru') ? 'Неактивный' : 'Invalid'
              ]
            };
          }
          return {
            [client.includes('kcc-ru') ? 'Счетчик' : 'Count']:
              a[client.includes('kcc-ru') ? 'Счетчик' : 'Count'] + 1,

            [client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate']: a[
              client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate'
            ],
            [client.includes('kcc-ru') ? 'Активный' : 'Valid']: a[
              client.includes('kcc-ru') ? 'Активный' : 'Valid'
            ],
            [client.includes('kcc-ru') ? 'Неактивный' : 'Invalid']:
              a[client.includes('kcc-ru') ? 'Неактивный' : 'Invalid'] + 1
          };
        },
        {
          [client.includes('kcc-ru') ? 'Счетчик' : 'Count']: 0,
          [client.includes('kcc-ru') ? 'Дублировать' : 'Duplicate']: 0,
          [client.includes('kcc-ru') ? 'Активный' : 'Valid']: 0,
          [client.includes('kcc-ru') ? 'Неактивный' : 'Invalid']: 0
        }
      );
      setPhotosMeta(pCount);
    }
  }, [photos]);
  useEffect(() => {
    if (
      appImageDownloadOptionsState.request &&
      appImageDownloadOptionsState.success
    ) {
      setShowModalContent(true);
    }
  }, [appImageDownloadOptionsState]);

  useEffect(() => {
    if (appImageDownloadOptionsState.failure) {
      setNotificationSnack({
        isOpen: true,
        message: appImageDownloadOptionsState.message
      });
    }
  }, [appImageDownloadOptionsState.failure]);

  return (
    <div className={classes.appImagesHomeContainer}>
      <Modal
        open={modalOpen}
        className={classes.modalContainer}
        onClose={() => {
          handleModalAction(false);
          dispatchResetAppImagesDownloadState();
        }}
      >
        <div className={`${classes.customModal} appImagesModal`}>
          <div className={classes.modalHeader}>
            <Typography size={20}>
              {client.includes('kcc-ru')
                ? 'Загрузить массивом'
                : 'Download Bulk'}
            </Typography>
            <Button
              color="gray"
              className={classes.modalCloseButton}
              onClick={() => {
                handleModalAction(false);
                dispatchResetAppImagesDownloadState();
              }}
              round
              margin="0"
            >
              <Close />
            </Button>
          </div>
          {appImageDownloadOptionsState.failure &&
          downloadBulkCheckBoxOptions.length === 0 ? (
            <div className={classes.centeredCss}>
              Error while fetching the dowloading options
            </div>
          ) : showModalContent ? (
            <>
              <div className={classes.modalBody}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    color: '#94949a',
                    justifyContent: 'center'
                  }}
                >
                  <div style={{ width: '90%', display: 'flex' }}>
                    <div style={{ marginRight: '10px' }}>
                      <TimeFilter
                        float="right"
                        useRange
                        title={
                          client !== 'kcc-ru'
                            ? 'Select Date Range'
                            : 'Выбрать диапазон дат'
                        }
                        options={options.options}
                        value={dateRange}
                        useMenuPortalTarget={false}
                        defaultDateRange={initialSelectedDate}
                        handleDateChange={handleDateRangeChange}
                        routeID="app_images"
                        type="fl_date"
                      />
                    </div>
                    <div>
                      <InputLabel className={classes.inputLable}>
                        {client !== 'kcc-ru'
                          ? 'Select Stores'
                          : 'Выбрать магазины'}
                      </InputLabel>
                      <div>
                        <Select
                          multiple
                          className={classes.selectBar}
                          value={[selectedBulkStores]}
                          onChange={handleBulkStoresChange}
                          input={<Input />}
                          renderValue={() => []}
                        >
                          {bulkStores.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              <StyledCheckbox
                                checked={selectedBulkStores.includes(
                                  item.value
                                )}
                                checkboxColor={
                                  dashboardPanelStyles?.checkboxColor
                                }
                                className={classes.checkbox}
                              />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {bulkStores.length && !bulkStoreMessage > 0 ? (
                    <div
                      style={{
                        width: '90%',
                        marginTop: `${dashboardType === 'v2' ? 0 : '50px'}`
                      }}
                    >
                      <div className={classes.spaceBetweenCss}>
                        <span className={classes.selectedStoresText}>
                          {client.includes('kcc-ru')
                            ? 'Выбранные магазины'
                            : 'Selected Stores'}
                        </span>
                        <span>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                checked={isSelectAllStore}
                                onChange={() =>
                                  selectAllStores(!isSelectAllStore)
                                }
                                className={classes.checkbox}
                              />
                            }
                            label={
                              client.includes('kcc-ru')
                                ? 'Выбрать все магазины'
                                : 'Select All Stores'
                            }
                            className={classes.checkbox}
                          />
                        </span>
                      </div>
                      <div className={classes.selectedStores}>
                        {bulkStores
                          .filter(item =>
                            selectedBulkStores.includes(item.value)
                          )
                          .reverse()
                          .map(item => (
                            <span key={item.value} className={classes.pillBox}>
                              <p className={classes.pillBoxText}>
                                {item.label}
                              </p>
                            </span>
                          ))}
                      </div>
                      <div className={classes.checkBoxRow}>
                        <>
                          {downloadBulkCheckBoxOptions.map(el =>
                            appImageOptions
                              .filter(opt => opt.type === el)
                              .map(lable => (
                                <FormControlLabel
                                  className={classes.checkbox}
                                  control={
                                    <StyledCheckbox
                                      onChange={() =>
                                        handleOptionSelected(
                                          el,
                                          lable.title,
                                          appImageOptions
                                        )
                                      }
                                      checked={
                                        selectedDownloadMeta[el]
                                          ? selectedDownloadMeta[el].includes(
                                              lable.title
                                            )
                                          : false
                                      }
                                      className={classes.checkbox}
                                    />
                                  }
                                  label={lable.title}
                                />
                              ))
                          )}

                          {downloadBulkCheckBoxOptions.map(el =>
                            appImageOptions.filter(opt => opt.type === el)
                          )[0].length > 1 && (
                            <FormControlLabel
                              className={classes.checkbox}
                              control={
                                <StyledCheckbox
                                  onChange={() =>
                                    handleAllClicked(
                                      downloadBulkCheckBoxOptions,
                                      appImageOptions,
                                      true
                                    )
                                  }
                                  checked={allDownloadOptionsSelected}
                                  className={classes.checkbox}
                                />
                              }
                              label="All"
                            />
                          )}
                        </>
                      </div>
                    </div>
                  ) : (
                    <p>{bulkStoreMessage}</p>
                  )}
                </div>
              </div>

              <div className={classes.modalFooter}>
                <Button
                  onClick={() =>
                    handleImageDownload(
                      'bulk',
                      downloadBulkCheckBoxOptions,
                      appImageOptions
                    )
                  }
                  className={classes.downloadButton}
                  id="app-images-download-bulk-button"
                >
                  {client.includes('kcc-ru') ? 'Загрузить' : 'Download'}
                </Button>
                <Button
                  onClick={() => handleModalAction(false)}
                  className={classes.downloadButton}
                  id="app-images-download-bulk-button"
                >
                  Close
                </Button>
              </div>
            </>
          ) : (
            <div>
              <div className={classes.centeredCss}>
                <Loader circular />
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Notification
        message={notificationSnack.message}
        isOpen={notificationSnack.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        handleClose={() =>
          setNotificationSnack(
            { isOpen: false, message: '' },
            dispatchResetAppImagesDownloadState()
          )
        }
      />
      <GridContainer classNames={classes.newAppImagesBanner}>
        {dashboardType !== 'v2' ? (
          <div
            style={{
              overflowY: 'scroll',
              marginRight: 'auto',
              maxWidth: '65%',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <TimeFilter
              useRange={false}
              title={client.includes('kcc-ru') ? 'Выбрать дату' : 'Select Date'}
              options={options.options}
              defaultDateRange={initialSelectedDate}
              handleDateChange={handleDateChange}
              value={value}
              routeID="app_images"
              type="fl_date"
              dropdownStyles={dropdownStyles}
              dropdownTitleStyles={dropdownTitleStyles}
            />
            <div className={classes.dropDown}>
              <DropDown
                title={
                  client.includes('kcc-ru') ? 'Выбрать магазин' : 'Select Store'
                }
                options={stores}
                handleValueChange={handleStoreChange}
                value={isEmpty(store) ? stores[0] : store}
                routeID="app_images"
                type="fl_store"
                dropdownStyles={dropdownStyles}
                dropdownTitleStyles={dropdownTitleStyles}
              />
            </div>
            <div className={classes.dropDown}>
              <DropDown
                title={
                  client.includes('kcc-ru')
                    ? 'Выбрать сессию'
                    : 'Select Session'
                }
                options={sessions}
                handleValueChange={handleSessionChange}
                value={isEmpty(session) ? sessions[0] : session}
                routeID="app_images"
                type="fl_session"
                dropdownStyles={dropdownStyles}
                dropdownTitleStyles={dropdownTitleStyles}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              overflowY: 'scroll',
              marginRight: 'auto',
              maxWidth: '65%',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {photoAttributes && Object.keys(photoAttributes).length > 0
              ? Object.keys(photoAttributes).map(item => {
                  return (
                    <>
                      <div
                        style={{
                          backgroundColor:
                            dashboardType === 'v2' ? '#f5f5f5' : '#353855',
                          height: '36px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                          marginTop: '10px'
                        }}
                      >
                        <span
                          style={{
                            color: dashboardPanelStyles?.textColor,
                            backgroundColor:
                              dashboardType === 'v2' ? '#f5f5f5' : '#353855'
                          }}
                        >
                          <span className={classes.fontWeight600}>{item}</span>:
                          {photoAttributes[item]}
                        </span>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        )}
        <div className={classes.downloadBulkImagesButton}>
          {/* Photos length is greater than 0 and user has TSE access */}
          {(photos.length > 0) && ((Array.isArray(layout)? layout: []).map(res => res.routeID === 'test-store-explorer').indexOf(true) > -1) ? (
            <Button
              onClick={() => {
                [...new Set(photos.map(res => res.session_id))].forEach(item => {
                  if (item) {
                    window.open(`/seam/app/sessions/${item}`, '_blank');
                  }
                })
              }}
              id="app-images-download-single-image-button"
              className={classes.viewInSeamButton}
              primaryButtonStyles={primaryButtonStyles}
            >
              <img src={ShareIcon} style={{ width: '16px', marginRight: '5px' }} />
              <div>View In SEAM</div>
            </Button>
          ) : null}
          {photos.length > 0 ? (
            <Button
              onClick={() => handleImageDownload('single')}
              id="app-images-download-single-image-button"
              className={classes.downloadButton}
              primaryButtonStyles={primaryButtonStyles}
            >
              {client.includes('kcc-ru')
                ? 'Загрузить изображения'
                : 'Download Images'}
            </Button>
          ) : null}
          <Button
            onClick={handleBulkImageDownloadClick}
            className={classes.downloadButton}
            primaryButtonStyles={primaryButtonStyles}
          >
            {client.includes('kcc-ru')
              ? 'Загрузить массив изображений'
              : 'Download Bulk Images'}
          </Button>
        </div>
        {/* </div> */}
      </GridContainer>
      {photos.length || message > 0 ? (
        <div
          style={{
            overflowY: 'scroll'
          }}
        >
          {dashboardType !== 'v2' && (
            <div
              style={{
                overflowY: 'scroll'
              }}
            >
              {photoAttributes && Object.keys(photoAttributes).length > 0 ? (
                <div className={classes.photosInfo}>
                  {Object.keys(photoAttributes).map(item => {
                    return (
                      <div
                        style={{
                          color: dashboardPanelStyles?.textColor,
                          backgroundColor:
                            dashboardType === 'v2' ? '#f5f5f5' : '#353855',
                          height: '36px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px'
                        }}
                      >
                        <span className={classes.fontWeight600}>{item}</span>:
                        {photoAttributes[item]}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          )}
          <div
            style={{
              height: '70vh',
              overflow: 'scroll',
              paddingBottom: '50px'
            }}
          >
            {photos.map((item, index) => {
              let direction = '';
              switch (item.direction.toUpperCase()) {
                case '':
                  direction = '';
                  break;
                case 'UP':
                  direction = '90deg';
                  break;
                case 'TOP':
                  direction = '90deg';
                  break;
                case 'RIGHT':
                  direction = '180deg';
                  break;
                case 'LEFT':
                  direction = '0deg';
                  break;
                case 'BOTTOM':
                  direction = '270deg';
                  break;
                case 'DOWN':
                  direction = '270deg';
                  break;
                default:
                  break;
              }
              // eslint-disable-next-line consistent-return
              return (
                <>
                  <div
                    key={index}
                    className={classes.photoCard}
                    style={{ overflow: 'hidden' }}
                  >
                    <div
                      className={
                        item.isValid ? classes.validTile : classes.invalidTile
                      }
                      style={{ border: `1px solid ${item.border_color}` }}
                    >
                      <Tooltip
                        title={item.flags.length > 0 && item.flags.join(',')}
                        aria-label={item.url}
                      >
                        <img
                          src={item.url}
                          alt={item.url}
                          className={classes.appImageItem}
                          onClick={() => handleImageModalAction(index)}
                        />
                      </Tooltip>

                      <div
                        className={classes.downloadBox}
                        onClick={() => downloadImage(item.url)}
                      >
                        <img src={Download} style={{ width: '12px' }} />
                      </div>
                      {direction !== '' ? (
                        <div className={classes.arrowBox}>
                          <img
                            src={Arrow}
                            style={{
                              width: '12px',
                              transform: `rotate(${direction})`
                            }}
                            alt={JSON.stringify(item.isDuplicate)}
                          />
                        </div>
                      ) : null}
                      <div>
                        {item.isDuplicate ? (
                          <div className={classes.contentHolder}>
                            <div className={classes.duplicateBox}>
                              <img src={Duplicate} style={{ width: '12px' }} />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          {isLoading ? (
            <Loader circular />
          ) : (
            <p
              style={{
                textAlign: 'center',
                color: dashboardPanelStyles.textColor
                  ? dashboardPanelStyles.textColor
                  : '#B3B3B3'
              }}
              className={classes.footerText}
            >
              {message ||
                (client.includes('kcc-ru')
                  ? 'Пожалуйста, выберите фильтры'
                  : 'Please select the filters')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

AppImagesPage.propTypes = {
  handleImageModalAction: PropTypes.func.isRequired,
  handleImageDownload: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleStoreChange: PropTypes.func.isRequired,
  handleSessionChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  notificationSnack: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  stores: PropTypes.array,
  photos: PropTypes.array,
  store: PropTypes.object,
  session: PropTypes.object,
  sessions: PropTypes.array,
  downloadBulkOptions: PropTypes.object.isRequired,
  handleModalAction: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  bulkStores: PropTypes.array,
  selectedBulkStores: PropTypes.array,
  handleBulkStoresChange: PropTypes.func.isRequired,
  selectAllStores: PropTypes.func.isRequired,
  isSelectAllStore: PropTypes.bool.isRequired,
  bulkStoreMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  dashboardType: PropTypes.string.isRequired,
  primaryButtonStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  photoAttributes: PropTypes.object.isRequired,
  dispatchGetAppImageDownloadImages: PropTypes.func,
  appImageOptions: PropTypes.object,
  appImageDownloadOptionsState: PropTypes.object,
  handleOptionSelected: PropTypes.func,
  handleAllClicked: PropTypes.func,
  selectedDownloadMeta: PropTypes.func,
  setDefaultDownloadMeta: PropTypes.func,
  allDownloadOptionsSelected: PropTypes.bool,
  setNotificationSnack: PropTypes.func,
  appImagesDownloadOptionState: PropTypes.object,
  dispatchResetAppImagesDownloadState: PropTypes.func
};
AppImagesPage.defaultProps = {
  stores: [],
  store: {},
  session: {},
  sessions: [],
  photos: [],
  message: null,
  bulkStores: [],
  selectedBulkStores: [],
  bulkStoreMessage: null,
  isLoading: false,
  primaryButtonStyles: {},
  dashboardPanelStyles: {},
  dropdownTitleStyles: {},
  dropdownStyles: {}
};
const mapStateToProps = state => ({
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  getErrors: appImageSelector.getErrors(state),
  // appImagesDownloadOptionState:appImageSelector.getAppImagesDownloadOptionState(state),
  appImageDownloadOptionsState: appImageSelector.appImageDownloadOptionsState(
    state
  ),
  layout: appSelector.getLayout(state),
});

/*
      Connect dispatch methods to props so that you can call the methods
      from the scope of the component's props
    */
const mapDispatchToProps = dispatch => ({
  dispatchGetAppImageDownloadImages: payload =>
    dispatch(appImageActions.getImageActionTypes(payload)),
  dispatchResetAppImagesDownloadState: payload =>
    dispatch(appImageActions.resetAppIagesDownloadState(payload)),
  dispatchSessionFiltersValuesRequest: payload =>
    dispatch(genericSEActions.getSessionFilterValues(payload))
});

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(AppImagesPage)
);

import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
// calendar library for react
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
// @material-ui/core
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from 'components/Card/CardHeader';
// core functions
import { dateRangeString, dateString } from 'lib/core/dateUtils';
import ls from 'lib/core/storageFactory';
// theme and styles
import calendarStyles from 'assets/jss/material-ui/components/calendarStyles';
import './__styles.scss';
import { options, calendarTheme } from './utils';

const CalendarWithRange = withRange(Calendar);

const client = ls.get('client');

/*
  A card like container for the calendar
 */
const Card = ({ classes, children, ...rest }) => (
  <div className={classes.card} {...rest}>
    {children}
  </div>
);

/**
 * @class
 * @hideconstructor
 * @description Core Calendar component
 */
class ReactInfiniteCalendar extends Component {
  state = {
    range: this.props.selected
  };

  /**
   * @method
   * @description - sets the selected range to the component state and pss the values to the callback prop
   * @param {object} ev - date range select event object
   * @returns {undefined}
   */
  handleDateRangeSelect = ev => {
    if (ev.eventType === 3) {
      // eslint-disable-next-line no-param-reassign
      delete ev.eventType;
      this.setState({ range: ev });
    }
    this.props.handleDateRangeSelect(ev);
  };

  renderCalendar() {
    const {
      classes,
      selected,
      handleApply,
      handleCancel,
      handleDateSelect,
      useRange,
      minDate,
      minSelectableDate,
      maxSelectableDate,
      routeID
    } = this.props;
    const { range } = this.state;

    return (
      <div className={classes.absolute}>
        <Card classes={classes} routeID={routeID}>
          <CardHeader plain>
            <h4 className={classes.heading}>
              {useRange
                ? dateRangeString(range.start, range.end)
                : dateString(range.start)}
            </h4>
          </CardHeader>
          <CardContent style={{ padding: '5px !important' }}>
            {useRange ? (
              <InfiniteCalendar
                theme={calendarTheme}
                height={300}
                width={300}
                rowHeight={56}
                Component={CalendarWithRange}
                selected={selected}
                minDate={minDate}
                max={new Date()}
                maxDate={new Date()}
                displayOptions={options.displayOptions}
                locale={options.locale}
                onSelect={this.handleDateRangeSelect}
              />
            ) : (
              <InfiniteCalendar
                theme={calendarTheme}
                height={300}
                width={300}
                rowHeight={56}
                selected={selected}
                minDate={minSelectableDate}
                max={maxSelectableDate || new Date()}
                maxDate={maxSelectableDate || new Date()}
                displayOptions={options.displayOptions}
                locale={options.locale}
                onSelect={handleDateSelect}
              />
            )}
          </CardContent>
          <CardActions>
            <Button className={classes.right} onClick={handleCancel}>
              {client.includes('kcc-ru') ? 'Отменить' : 'Cancel'}
            </Button>
            <Button onClick={handleApply}>
              {' '}
              {client.includes('kcc-ru') ? 'Применить' : 'Apply'}
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  render() {
    const { usePortal, parentComponentClass } = this.props;
    return createPortal(
      this.renderCalendar(),
      document.querySelector(
        !usePortal
          ? parentComponentClass !== ''
            ? parentComponentClass
            : '.appImagesModal'
          : 'body'
      )
    );
  }
}

ReactInfiniteCalendar.propTypes = {
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description - Default selected date range
   */
  selected: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description - Callback function to handle date range select
   */
  handleDateRangeSelect: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description - Callback function to handle date select
   */
  handleDateSelect: PropTypes.func.isRequired,
  /**
   * @type {bool}
   * @description - Callback function to handle date range apply
   */
  handleApply: PropTypes.func.isRequired,
  /**
   * @type {bool}
   * @description - Callback function to handle calendar close
   */
  handleCancel: PropTypes.func.isRequired,
  /**
   * @type {bool}
   * @description - Whether to use range picker
   */
  useRange: PropTypes.bool,
  /**
   * @type {bool}
   * @description - Whether to use menuPortalTarget prop
   */
  usePortal: PropTypes.bool,
  parentComponentClass: PropTypes.string,
  minDate: PropTypes.any,
  minSelectableDate: PropTypes.any,
  maxSelectableDate: PropTypes.any,
  routeID: PropTypes.string
};

ReactInfiniteCalendar.defaultProps = {
  useRange: true,
  minDate: null,
  usePortal: true,
  parentComponentClass: '',
  minSelectableDate: null,
  maxSelectableDate: null,
  routeID: ''
};

Card.propTypes = {
  /**
   * @description - Children
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(calendarStyles)(ReactInfiniteCalendar);

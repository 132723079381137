import React from 'react';
import { PropTypes } from 'prop-types';
// charting library for react
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  Tooltip,
  LabelList,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
// styles
import colors from 'charts/__variables';
import './_styles.scss';

/**
 * @class
 * @hideconstructor
 * @description Vertical bar chart component
 */
const VerticalBar = ({ data }) => {
  return (
    <>
      <ResponsiveContainer height="97%">
        <BarChart
          layout="vertical"
          data={data.datasets}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid stroke="rgba(113, 113, 121,0.5)" horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            stroke="rgba(168, 168, 169, 1)"
          />
          <YAxis
            type="category"
            dataKey={data.xAxisDataKey}
            axisLine={false}
            tickLine={false}
            tick={false}
            stroke="rgba(168, 168, 169, 1)"
          >
            {data.yAxisLabel
              ? data.yAxisLabel.show && (
                  <Label
                    value={data.yAxisLabel.label}
                    angle={-90}
                    position="center"
                    fill={colors.dark.axisStroke}
                  />
                )
              : null}
          </YAxis>
          <Tooltip cursor={false} />
          <Bar
            barSize={10}
            dataKey={data.yAxisDataKey[0]}
            radius={[0, 4, 4, 0]}
            type="monotone"
          >
            <LabelList
              dataKey={data.xAxisDataKey}
              position="left"
              content={config => (
                <text
                  x={config.viewBox.width + 30}
                  y={config.viewBox.y - 8}
                  fill="rgba(255, 255, 255, 0.9)"
                >
                  {config.value}
                </text>
              )}
            />
            {data.datasets.map(entry => (
              <Cell
                key={`cell-${entry[data.xAxisDataKey]}`}
                stroke={entry.color}
                fill={entry.color}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

// component properties
VerticalBar.propTypes = {
  /**
   * @type {object}
   * @description - data to build the chart
   */
  data: PropTypes.object.isRequired
};

export default VerticalBar;

import React from 'react';
// library to concatenates classes
import classNames from 'classnames';
// library to set properties for components
import PropTypes from 'prop-types';
import styled from 'styled-components';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import cardStyle from 'assets/jss/material-ui/components/cardStyle';

const StyledDiv = styled.div`
  && {
    background-color: ${props => `${props.bg} !important`};
    color: ${props => `${props.color} !important`};
  }
`;

/**
 * @class
 * @hideconstructor
 * @description Core Card component
 */
const Card = ({
  classes,
  className,
  children,
  plain,
  map,
  profile,
  chart,
  stats,
  color,
  cardStyles,
  ...rest
}) => {
  const cardClasses = classNames({
    [classes.card]: true,
    [classes[`${color}Card`]]: color,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [classes.cardMap]: map,
    [classes.cardBodyStats]: stats,
    [className]: className !== undefined
  });
  return (
    <StyledDiv
      className={cardClasses}
      {...rest}
      bg={cardStyles?.backgroundColor}
      color={cardStyles?.textColor}
    >
      {children}
    </StyledDiv>
  );
};

// component properties
Card.propTypes = {
  /**
   * @type {object}
   * @description - Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @description - Children
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  /**
   * @type {string}
   * @description - Background color of the card - 'warning' | 'success' | 'danger'|
   * 'info' | 'primary' | 'dark'
   */
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'dark'
  ]),
  /**
   * @type {string}
   * @description - Classname to apply for the root element
   */
  className: PropTypes.string,
  /**
   * @type {boolean}
   * @description - Plain card without any background and shadow
   */
  plain: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a chart
   */
  chart: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a map
   */
  map: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains statistics
   */
  stats: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a profile
   */
  profile: PropTypes.bool,
  cardStyles: PropTypes.object
};

Card.defaultProps = {
  color: 'dark',
  className: '',
  plain: false,
  chart: false,
  stats: false,
  map: false,
  profile: false,
  cardStyles: {}
};

export default withStyles(cardStyle)(Card);

/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
function RGBToHex(rgb) {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb
    .substr(4)
    .split(')')[0]
    .split(sep);

  let r = (+rgb[0]).toString(16).split('.')[0];
  let g = (+rgb[1]).toString(16).split('.')[0];
  let b = (+rgb[2]).toString(16).split('.')[0];

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

export { RGBToHex };

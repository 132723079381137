import { withStyles } from '@material-ui/core/styles';
// @material-ui/core
import TableCell from '@material-ui/core/TableCell';
// global colors
import colors from 'styles/__variables';

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: colors.dark.buttonDark,
    borderBottom: 'none !important',
    color: colors.dark.light26,
    position: 'sticky',
    top: 0,
    textTransform: 'uppercase'
  },
  body: {
    cursor: 'pointer',
    fontSize: 14,
    color: colors.dark.light25,
    borderBottom: 'none !important'
  }
}))(TableCell);

export default CustomTableCell;

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Table from 'table/Table';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Dropdown from 'components/Dropdown/Dropdown';
import Typography from 'components/Typography/Typography';
import Icon from 'components/Icon/Icon';
import { HSpacer } from 'components/Spacer/Spacer';

import {
  Pin,
  DownloadIcon,
  ArrowUpward,
  ArrowDownward
} from 'assets/icons/appIcons';
import colors from 'styles/__variables';

import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const styles = theme => ({
  red: {
    color: colors.dark.roseColor,
    '& $icon': {
      '& svg': {
        fill: colors.dark.roseColor
      }
    }
  },
  value: {
    color: `${colors.dark.light25} !important`
  },
  green: {
    color: 'green',
    '& $icon': {
      '& svg': {
        fill: 'green'
      }
    }
  },
  root: {
    display: 'flex'
  },
  icon: {
    '& div': {
      fontSize: '10px !important'
    },
    '& svg': {
      width: '15px !important',
      height: '14px !important'
    }
  },
  granularities: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'start',
    paddingTop: 8
  },
  card: {
    backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
    '& tr,th,td': {
      // backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
      // color: theme.palette.type === 'light' && '#767676 !important'
      color: theme.palette.type === 'light' && '#424242 !important'
    },
    '& th': {
      backgroundColor: theme.palette.type === 'light' && '#ffffff !important'
    },
    '& p': {
      // color: theme.palette.type === 'light' && '#767676 !important'
      color: theme.palette.type === 'light' && '#424242 !important'
    }
  }
});

const computeDifference = (current, previous, classes) => {
  const difference = Math.round(Math.abs(current - previous) * 100) / 100;
  let differenceIcon;
  if (current > previous) {
    differenceIcon = (
      <Icon>
        <ArrowUpward />
      </Icon>
    );
  } else if (current < previous) {
    differenceIcon = (
      <Icon>
        <ArrowDownward />
      </Icon>
    );
  }
  const toolTipValue = (
    <div
      className={`${classes.root} ${
        current < previous ? classes.red : classes.green
      }`}
    >
      <p className={classes.value}>{`${current}%`}</p>
      {difference !== 0 ? (
        <>
          <span className={classes.icon}>{differenceIcon}</span>
          <p>{`${difference}%`}</p>
        </>
      ) : (
        <p>-</p>
      )}
    </div>
  );
  return toolTipValue;
};

const TableWrapper = ({
  data,
  classes,
  classesList,
  granularities,
  slicers,
  isPinned,
  usePin,
  useExport,
  chartName,
  type,
  title,
  height,
  handleSlicerChange,
  handlePinClick,
  handleDataDownload,
  cardStyles,
  dashboardPanelStyles
}) => {
  const handleTableExport = () => {
    const keyMap = {};
    data.columns.forEach(column => {
      keyMap[column.dataKey] = column.label;
    });
    data.datasets = data.datasets.map(dataset => {
      const newDataset = {};
      Object.keys(dataset).forEach(key => {
        const newKey = keyMap[key] || key;
        newDataset[newKey] = dataset[key];
      });

      return newDataset;
    });

    const tableCSV = convertJSONToCSV(data.datasets);
    if (!(tableCSV === '')) {
      // Create CSV file and initiate download
      const blob = new Blob([tableCSV], {
        type: 'text/csv;charset=utf-8;'
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${title}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const convertJSONToCSV = jsonData => {
    if (jsonData.length === 0) {
      return '';
    }
    const columnHeaders = Object.keys(jsonData[0]);
    const headers = columnHeaders.join(',') + '\n';

    const rows = jsonData
      .map(row => {
        return columnHeaders.map(field => row[field] || '').join(',');
      })
      .join('\n');

    return headers + rows;
  };

  const onSlicerChange = ({ slicerType, value: selectedValue }) =>
    handleSlicerChange({
      selectedValue,
      slicerType,
      chartName
    });

  let useDifference = false;
  const datasets = data.datasets.map(el => {
    const obj = { ...el };
    let value = '';
    let keyName;
    map(el, (_val, key) => {
      if (key.includes('previous')) {
        useDifference = true;
        [, keyName] = key.split('previous_');
      }
    });
    if (useDifference) {
      const [filteredKey] = data.columns.filter(e => e.dataKey === keyName);
      if (filteredKey) {
        if (filteredKey.dataKey === keyName) {
          value = computeDifference(
            obj[keyName],
            obj[`previous_${keyName}`],
            classes
          );
        }
      }
    }
    return { ...el, [keyName]: value };
  });

  return (
    <>
      <Card
        className={`${classesList.card} ${classes.card}`}
        chart
        cardStyles={cardStyles}
      >
        <CardHeader chart float="right">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography
              className={classesList.title}
              cardTitle
              textColor={cardStyles?.textColor}
            >
              {title}
            </Typography>
            <Icon color="light" id="sku-excel-download-button" float="left">
              <DownloadIcon onClick={handleTableExport} />
            </Icon>
          </div>
          <div className={classes.granularities}>
            {map(slicers, (slicer, key) => (
              <>
                <Dropdown
                  key={key}
                  title=""
                  handleValueChange={value => {
                    onSlicerChange({
                      chartName,
                      slicerType: key,
                      value
                    });
                  }}
                  options={slicer.options}
                  value={slicer.value}
                />
                <HSpacer amount={20} />
              </>
            ))}
          </div>
          {(useExport || usePin) && (
            <div className={classes.actionButtons}>
              {useExport ? (
                <Tooltip title="Download data" aria-label="Download data">
                  <Icon color="light" id={`${chartName}-excel-download-button`}>
                    <DownloadIcon onClick={handleDataDownload} />
                  </Icon>
                </Tooltip>
              ) : null}
              {usePin ? (
                <Tooltip
                  title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
                  aria-label={
                    isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
                  }
                >
                  <Icon color={isPinned ? 'primary' : 'light'}>
                    <Pin
                      onClick={() => handlePinClick({ isPinned, chartName })}
                    />
                  </Icon>
                </Tooltip>
              ) : null}
            </div>
          )}
        </CardHeader>
        <CardBody
          height={height}
          chart
          table
          // maxWidth={type === 'tablefull' ? '98%' : null}
          maxWidth={type === 'tablefull' ? '100%' : null}
        >
          {datasets.length === 0 ? (
            <Typography center color="light25" size="xs">
              {client.includes('kcc-ru')
                ? 'Нет данных для отображения'
                : 'No data to display'}
            </Typography>
          ) : (
            <Table
              width="100%"
              data={{ ...data, datasets }}
              granularities={granularities}
              cardStyles={cardStyles}
              dashboardPanelStyles={dashboardPanelStyles}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

TableWrapper.propTypes = {
  chartName: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  classesList: PropTypes.object.isRequired,
  granularities: PropTypes.object,
  slicers: PropTypes.object,
  isPinned: PropTypes.bool,
  usePin: PropTypes.bool,
  useExport: PropTypes.bool,
  handleSlicerChange: PropTypes.func,
  handlePinClick: PropTypes.func.isRequired,
  handleDataDownload: PropTypes.func.isRequired,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object
};

TableWrapper.defaultProps = {
  height: '300px',
  handleSlicerChange: () => {},
  granularities: {},
  slicers: {},
  isPinned: false,
  usePin: false,
  useExport: false,
  cardStyles: {},
  dashboardPanelStyles: {}
};

export default withStyles(styles)(TableWrapper);

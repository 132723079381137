import React, {
  createRef,
  useReducer,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { array, bool, object } from 'prop-types';
import { sumBy } from 'lodash';

import ShelfCanvas from './ShelfCanvas';
import Minimap from './Minimap';

const scaleDownValues = (value, factor) => {
  return value / factor;
};

const scaleUpValues = (value, factor) => {
  return value * factor;
};

const SHELF_COLOR = '#673ab7';
const DISPLAY_COLOR = '#F57C00';

const initialState = {
  minimap: {
    minimapDragging: false,
    activeArea: [0, 0],
    scaleDownFactor: 10
  },
  shelfCanvas: {
    canvasScroll: false,
    width: 0,
    activeArea: [0, 0]
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'REGISTER_SHELF_CANVAS':
      return {
        ...state,
        shelfCanvas: { ...state.shelfCanvas, ...action.payload },
        minimap: {
          ...state.minimap,
          scaleDownFactor: action.payload.scaleDownFactor,
          activeArea: [
            scaleDownValues(
              action.payload.activeArea[0],
              action.payload.scaleDownFactor
            ),
            scaleDownValues(
              action.payload.activeArea[1],
              action.payload.scaleDownFactor
            )
          ]
        }
      };
    case 'MINIMAP_WINDOW_DRAG': {
      return {
        ...state,
        shelfCanvas: {
          ...state.shelfCanvas,
          canvasScroll: false,
          activeArea: [
            scaleUpValues(
              action.payload.activeArea[0],
              action.payload.scaleDownFactor
            ),
            scaleUpValues(
              action.payload.activeArea[1],
              action.payload.scaleDownFactor
            )
          ]
        },
        minimap: {
          ...state.minimap,
          minimapDragging: true,
          activeArea: [...action.payload.activeArea]
        }
      };
    }
    case 'SHELF_CANVAS_SCROLL_EVENT': {
      return {
        ...state,
        shelfCanvas: {
          ...state.shelfCanvas,
          canvasScroll: true,
          activeArea: [
            action.payload.event.scrollLeft,
            action.payload.event.scrollLeft + state.shelfCanvas.width
          ]
        },
        minimap: {
          ...state.minimap,
          minimapDragging: false,
          activeArea: [
            scaleDownValues(
              action.payload.event.scrollLeft,
              state.minimap.scaleDownFactor
            ),
            scaleDownValues(
              action.payload.event.scrollLeft + state.shelfCanvas.width,
              state.minimap.scaleDownFactor
            )
          ]
        }
      };
    }
    default:
      return state;
  }
};

const ImageRecognition = ({ activeControls, compareVersions, IRData }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const minimapScaleDownFactor = 10;

  const shelfCanvasRef = createRef();
  const shelfCanvasHeight = IRData[0].canvasHeight + 50;
  const shelfCanvasWidth = sumBy(IRData, x => x.canvasWidth);

  const minimapCanvasRef = createRef();
  const minimapCanvasWidth = scaleDownValues(
    shelfCanvasWidth,
    minimapScaleDownFactor
  );
  const minimapCanvasHeight = scaleDownValues(
    shelfCanvasHeight,
    minimapScaleDownFactor
  );

  let updateState = useCallback(() => {
    const { current } = shelfCanvasRef;
    const { width } = current.getBoundingClientRect();
    dispatch({
      type: 'REGISTER_SHELF_CANVAS',
      payload: {
        width,
        activeArea: [current.scrollLeft, current.scrollLeft + width],
        scaleDownFactor: minimapScaleDownFactor
      }
    });
  }, [shelfCanvasRef]);
  updateState = useRef(updateState).current;

  useEffect(() => {
    updateState();
  }, [updateState]);

  return (
    <div style={{ position: 'relative' }}>
      <ShelfCanvas
        activeControls={activeControls}
        colors={{ shelf: SHELF_COLOR, display: DISPLAY_COLOR }}
        compareVersions={compareVersions}
        canvasHeight={shelfCanvasHeight}
        canvasWidth={shelfCanvasWidth}
        dispatch={dispatch}
        list={IRData}
        reducerState={state}
        wrapperRef={shelfCanvasRef}
      />
      <div style={{ height: 10 }} />
      <Minimap
        colors={{ shelf: SHELF_COLOR, display: DISPLAY_COLOR }}
        canvasHeight={minimapCanvasHeight}
        canvasWidth={minimapCanvasWidth}
        dispatch={dispatch}
        list={IRData}
        reducerState={state}
        scaleDownFactor={minimapScaleDownFactor}
        wrapperRef={minimapCanvasRef}
      />
      <div style={{ height: 10 }} />
    </div>
  );
};

ImageRecognition.propTypes = {
  activeControls: object,
  compareVersions: bool,
  IRData: array.isRequired
};

ImageRecognition.defaultProps = {
  activeControls: {},
  compareVersions: false
};

export default ImageRecognition;

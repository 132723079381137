/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isEmpty, set } from 'lodash';
import { getPreviousDate } from 'lib/core/dateUtils';

// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';

/* Components */
import GridContainer from 'components/Grid/GridContainer';
import DropDown from 'components/Dropdown/Dropdown';
import TimeFilter from 'layout/Filter/TimeFilter';
import Button from 'components/Button/Button';
import Notification from 'components/Notification/Notification';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from 'components/Typography/Typography';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from 'components/Loader/Loader';
import FormControl from '@material-ui/core/FormControl';

import withStyles from '@material-ui/core/styles/withStyles';
import style from 'pages/Survey/SurveyStyles';
import Arrow from 'assets/svg/arrow.svg';
import Download from 'assets/svg/download.svg';
import Duplicate from 'assets/svg/copy.svg';
import ls from 'lib/core/storageFactory';

import './style.css';
import { Close } from 'assets/icons/appIcons';
import { appImageActions, genericSEActions, appActions } from 'redux-utils/actions/index';
import { appImageSelector, appSelector } from 'redux-utils/selectors/index';
import { connect } from 'react-redux';

import ShareIcon from './../../assets/svg/share_icon.svg';
import { unstable_batchedUpdates } from 'react-dom';
import { CircularProgress } from '../../../node_modules/@material-ui/core/index';

const ai_flags = ['Photos Quality', 'Contrast'];

const client = ls.get('client');

const options = {
  options: [
    {
      label: client
        ? client.includes('kcc-ru')
          ? 'Открыть календарь'
          : 'Open Calendar'
        : 'Open Calendar',
      value: 'custom'
    }
  ]
};

const downloadImage = imageUrl => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.target = '__blank';
  document.body.appendChild(link);
  link.click();
};

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${props => `${props.checkboxColor} !important`};
  }
`;

const StyledRadio = styled(Radio)`
  && {
    color: ${props => `${props.radioColor} !important`};
  }
`;

const SurveyPage = ({
  selectAllStores,
  handleImageDownload,
  handleImageModalAction,
  downloadBulkOptions,
  bulkStoreMessage,
  isSelectAllStore,
  handleChange,
  bulkStores,
  selectedBulkStores,
  handleBulkStoresChange,
  handleDateRangeChange,
  dateRange,
  handleDateChange,
  handleStoreChange,
  handleSessionChange,
  handleModalAction,
  value,
  notificationSnack,
  stores = [],
  photos = [],
  store,
  session,
  sessions = [],
  modalOpen,
  message,
  classes,
  isLoading,
  dashboardType,
  primaryButtonStyles,
  dashboardPanelStyles,
  dropdownTitleStyles,
  dropdownStyles,
  photoAttributes,
  appImageOptions,
  appImageDownloadOptionsState,
  handleOptionSelected,
  handleAllClicked,
  selectedDownloadMeta,
  allDownloadOptionsSelected,
  setNotificationSnack,
  dispatchResetAppImagesDownloadState,
  downloadBulkCheckBoxOptions,
  dispatchSessionFiltersValuesRequest,
  layout,
  filters,
  dispatchFetchSurveyDetails,
  surveyDetailsData
}) => {
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(surveyOptions[0]);
  // const [answerStats, setAnswerStats] = useState({
  //   total: 0,
  //   answered: 0,
  // });

  function handleSurveyChange(value) {
    setSelectedSurvey(value);
  }

  useEffect(() => {
    if (Object.keys(filters ?? {}).length > 0) {
      dispatchFetchSurveyDetails({
        params: filters
      });
    }
  }, [filters]);

  useEffect(() => {
    let data = [];
    const surveyRes = JSON.parse(JSON.stringify(surveyDetailsData?.data?.data ?? {}));

    // if (Array.isArray(surveyRes)){
      Object.keys(surveyRes ?? {}).map((item, index) => {
        if (Array.isArray(surveyRes[item])) {
          console.log(item,'filters');
          //flatten the array values of the surveyRes
          surveyRes[item] = surveyRes[item].flatMap((r, index) => {
            return r
          });
        }
      })
    // }

    // console.log(surveyRes, 'filters');

    // console.log(surveyRes, 'filters');
    if (Array.isArray(surveyRes?.tasks)){
      data = (surveyRes?.tasks ?? []).map((item, index) => {
        const question_mapping = {};
  
        (surveyRes?.questions || []).map((question) => {
          if (question.task_id === item.id) {
            question_mapping[question.id] = question;
          }
          return null;
        });
        const answers = surveyRes?.answers?.filter((answer) => answer.question__task_id === item.id).map((answer) => ({
          ...answer,
          question: question_mapping[answer.question_id],
        }));
        const answerSessions = answers.map((answer) => answer.session__session_id);
        const photos = surveyRes?.photos?.images?.filter((photo) => answerSessions.includes(photo.session_id));
  
        if (answers.length !== Object.values(question_mapping).length) {
          const missingQuestions = Object.values(question_mapping).filter((question) => !answers.find((answer) => answer.question_id === question.id));
          answers.push(...missingQuestions.map((question) => ({
            question: question,
            question__question_text: question.question_text,
            question__task_id: question.task_id,
            text: '',
          })));
        }
  
        return {
          label: item.title,
          value: item.id,
          answers,
          photos,
        };
      });
    }
    data.unshift({
      label: 'All Surveys',
      value: 'all'
    });
    // console.log(data,'transformed data');
    // console.log(testData, 'transformed data');

    unstable_batchedUpdates(() => {
      setSurveyOptions(data);
      setSelectedSurvey(data[0]);
    });
  }, [surveyDetailsData]);
  


  return (
    <div className={classes.appImagesHomeContainer} style={{
      marginRight:'90px'
    }}>
      <div className={classes.dropDown} style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <DropDown
          title={'Survey Questionaire'}
          options={surveyOptions}
          width={300}
          handleValueChange={handleSurveyChange}
          value={isEmpty(selectedSurvey) ? surveyOptions?.[0] : selectedSurvey}
          useMenuPortalTarget={false}
          routeID="survey"
          type="Store Filter"
          dropdownStyles={dropdownStyles}
          dropdownTitleStyles={dropdownTitleStyles}
        />
      </div>
      {
        surveyDetailsData?.isFetching ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap:'20px',
            marginTop: '150px',
            fontSize: '20px',
          }}>
            <CircularProgress /> Loading...
          </div>
        ): (
          <div className='survey-list'>
            {
              React.Children.toArray(
                ( surveyOptions.length > 1? surveyOptions : [
                  {noData: surveyDetailsData.error && !surveyDetailsData?.isFetching}
                ])?.map((item, index) => {
                  const answerStats = {
                    total: item.answers?.length,
                    answered: item.answers?.filter((answer) => answer.text).length,
                  }
                  return (
                    item.noData ? (
                      <div style={{
                        textAlign: 'center',
                        padding: '40px 10px',
                        fontSize: '20px',
                      }}>No survey found for selected filters...</div>
                    ) : (
                      <>
                        {
                          (selectedSurvey?.value === 'all' || selectedSurvey?.value === item.value) && (item.value !== 'all') ? (
                            <>
                              <div className='survey-title'>
                                <div>{item.label}</div>                
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                  justifyContent: 'end',
                                }}>
                                  {
                                    answerStats.total > 0 ? (
                                      <>
                                        Answered: {answerStats.answered}/{answerStats.total}
                                        <div style={{
                                          width: '250px',
                                          height: '6px',
                                          backgroundColor: '#CBD5E1',
                                          borderRadius: '5px',
                                        }}>
                                          <div style={{
                                            width: `${(answerStats.answered / answerStats.total) * 100}%`,
                                            height: '100%',
                                            backgroundColor: '#3f51b5',
                                            borderRadius: '5px',
                                          }}></div>
                                        </div>
                                      </>
                                    ): <></>
                                  }
                                </div>
                              </div>
                              <div className='survey-item'>
                                <div className='survey-answers'>
                                  {
                                    !item.answers?.length ? (
                                      <>No questions and answers found for this survey...</>
                                    ):(
                                      <>
                                        {
                                          React.Children.toArray(
                                            item.answers?.map((answer, index) => {
                                              return (
                                                <div className='survey-answer'>
                                                  <div className='answer-title'>
                                                    <Typography variant='h6'>{answer?.question?.question_text ?? ''} {!answer.question?.is_optional ? <></>: <span style={{ color: 'red' }}>*</span>}</Typography>
                                                    <div className='answer-value'>
                                                      {answer.text? <Typography variant='body1'>{answer.text}</Typography>: <span style={{ color:'transparent' }}></span>}
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            })
                                          )
                                        }
                                        <div className='survey-photos'>
                                          {
                                            React.Children.toArray(
                                              item.photos?.map((photo, index) => {
                                                return (
                                                  <div className='photo-item'>
                                                    <a href={photo.path} target='_blank'><img src={photo.scaled_img} alt={photo.name} /></a>
                                                  </div>
                                                )
                                              })
                                            )
                                          }
                                        </div>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </>
                          ): <></>
                        }
                      </>
                    )
                  )
                })
              )
            }
          </div>
        )
      }
    </div>
  );
};

SurveyPage.propTypes = {
  handleImageModalAction: PropTypes.func.isRequired,
  handleImageDownload: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleStoreChange: PropTypes.func.isRequired,
  handleSessionChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  notificationSnack: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  stores: PropTypes.array,
  photos: PropTypes.array,
  store: PropTypes.object,
  session: PropTypes.object,
  sessions: PropTypes.array,
  downloadBulkOptions: PropTypes.object.isRequired,
  handleModalAction: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  bulkStores: PropTypes.array,
  selectedBulkStores: PropTypes.array,
  handleBulkStoresChange: PropTypes.func.isRequired,
  selectAllStores: PropTypes.func.isRequired,
  isSelectAllStore: PropTypes.bool.isRequired,
  bulkStoreMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  dashboardType: PropTypes.string.isRequired,
  primaryButtonStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  photoAttributes: PropTypes.object.isRequired,
  dispatchGetAppImageDownloadImages: PropTypes.func,
  appImageOptions: PropTypes.object,
  appImageDownloadOptionsState: PropTypes.object,
  handleOptionSelected: PropTypes.func,
  handleAllClicked: PropTypes.func,
  selectedDownloadMeta: PropTypes.func,
  setDefaultDownloadMeta: PropTypes.func,
  allDownloadOptionsSelected: PropTypes.bool,
  setNotificationSnack: PropTypes.func,
  appImagesDownloadOptionState: PropTypes.object,
  dispatchResetAppImagesDownloadState: PropTypes.func,
  dispatchFetchSurveyDetails: PropTypes.func,
  surveyDetailsData: PropTypes.object,
};
SurveyPage.defaultProps = {
  stores: [],
  store: {},
  session: {},
  sessions: [],
  photos: [],
  message: null,
  bulkStores: [],
  selectedBulkStores: [],
  bulkStoreMessage: null,
  isLoading: false,
  primaryButtonStyles: {},
  dashboardPanelStyles: {},
  dropdownTitleStyles: {},
  dropdownStyles: {},
  surveyDetailsData: {},
};
const mapStateToProps = state => ({
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  getErrors: appImageSelector.getErrors(state),
  // appImagesDownloadOptionState:appImageSelector.getAppImagesDownloadOptionState(state),
  appImageDownloadOptionsState: appImageSelector.appImageDownloadOptionsState(
    state
  ),
  layout: appSelector.getLayout(state),
  surveyDetailsData: appSelector.getSurveyDetailsSelector(state),
});

/*
      Connect dispatch methods to props so that you can call the methods
      from the scope of the component's props
    */
const mapDispatchToProps = dispatch => ({
  dispatchGetAppImageDownloadImages: payload =>
    dispatch(appImageActions.getImageActionTypes(payload)),
  dispatchResetAppImagesDownloadState: payload =>
    dispatch(appImageActions.resetAppIagesDownloadState(payload)),
  dispatchSessionFiltersValuesRequest: payload =>
    dispatch(genericSEActions.getSessionFilterValues(payload)),
    dispatchFetchSurveyDetails: payload => dispatch(appActions.fetchSurveyDetails(payload)),
    
});

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(SurveyPage)
);

import React from 'react';
import PropTypes from 'prop-types';

import Draggable from 'react-draggable';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from 'components/Button/Button';

const styles = {
  tooltipRow: {
    display: 'flex'
  },
  tooltipHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(215, 215, 215)'
  },
  draggable: {
    zIndex: 999,
    position: 'fixed',
    overflow: 'scroll',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#eaeaea',
    color: 'black',
    borderRadius: '10px',
    '& h4': {
      fontSize: '12px'
    },
    maxWidth: 800,
    width: 'auto',
    height: 'auto'
  },
  col: {
    width: '170px',
    textAlign: 'center',
    padding: '20px 15px',
    color: '#757575',
    backgroundColor: '#eaeaea',
    border: '1px solid #d7d7d7'
  },
  tableHeader: {
    backgroundColor: '#d7d7d7',
    border: 'none',
    padding: '10px 0px',
    width: '175px'
  },
  firstCol: {
    width: '100px',
    textAlign: 'center',
    padding: '20px 0px',
    color: '#757575',
    backgroundColor: 'rgb(215, 215, 215)'
  },
  tooltipContainer: {
    maxHeight: '300px !important',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      // backgroundColor: 'rgb(65, 65, 66)',
      backgroundColor: 'rgb(117, 117, 117)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  tooltip: {
    opacity: '1 !important',
    paddingLeft: '6px !important'
  },
  bold: {
    fontWeight: '600'
  },
  closeIconContainer: {
    '& button': {
      width: '30px',
      height: '30px',
      minWidth: '30px'
    }
  }
};

const CropTooltip = ({
  children,
  activeCropData,
  classes,
  setImageClickedStatus,
  imageClickedStatus,
  enablePreviousVersionCol
}) => {
  const handleTooltipClose = () => {
    setImageClickedStatus(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        {Array.isArray(activeCropData) &&
        activeCropData.length > 0 &&
        imageClickedStatus ? (
          <Draggable handle=".handle" defaultPosition={{ x: 100, y: -100 }}>
            <div className={`${classes.draggable} handle`}>
              <div style={styles.tooltipHeaderRow}>
                <div
                  style={{ ...styles.firstCol, padding: '0px' }}
                  className={classes.closeIconContainer}
                >
                  <Button
                    color="dark"
                    round
                    justIcon
                    onClick={handleTooltipClose}
                  >
                    <CloseIcon />
                  </Button>
                </div>
                {activeCropData &&
                  activeCropData[0].data.map(colValue => {
                    if (
                      colValue.label.includes('Previous') &&
                      !enablePreviousVersionCol
                    ) {
                      return null;
                    }
                    console.log('colValue.label', colValue.label);
                    return (
                      <div style={{ ...styles.col, ...styles.tableHeader }}>
                        <Typography noWrap className={classes.bold}>
                          {colValue.label}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
              <div className={classes.tooltipContainer}>
                {activeCropData &&
                  activeCropData.map(row => {
                    return (
                      <div>
                        <div style={styles.tooltipRow}>
                          <div style={styles.firstCol}>
                            <Typography noWrap className={classes.bold}>
                              {row.title}
                            </Typography>
                          </div>
                          {row.data.map(col => {
                            if (col.label.includes('Current')) {
                              return (
                                <div style={styles.col}>
                                  <Typography style={{ color: col.color }}>
                                    {col.value}
                                  </Typography>
                                </div>
                              );
                            }
                            if (col.label.includes('Previous')) {
                              if (!enablePreviousVersionCol) {
                                return null;
                              }
                              return (
                                <div style={styles.col}>
                                  <Typography style={{ color: col.color }}>
                                    {col.value}
                                  </Typography>
                                </div>
                              );
                            }
                            return (
                              <div style={styles.col}>
                                <Typography style={{ color: col.color }}>
                                  {col.value}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Draggable>
        ) : null}
        {children}
      </div>
    </ClickAwayListener>
  );
};

CropTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  activeCropData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  setImageClickedStatus: PropTypes.func.isRequired,
  imageClickedStatus: PropTypes.bool.isRequired,
  enablePreviousVersionCol: PropTypes.bool.isRequired
};

export default withStyles(styles)(CropTooltip);

/* eslint-disable no-param-reassign */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// util library
import { sumBy, map, isEqual } from 'lodash';
// chart library for react
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Tooltip
} from 'recharts';
import CustomPieTooltip from 'components/Tooltip/CustomPieTooltip';
// colors and styles
import palette from 'charts/__variables';
import './_pie-styles.scss';

const COLORS = [
  palette.dark.green.primary,
  palette.dark.blue.primary,
  palette.dark.pink.primary,
  palette.dark.yellow.primary,
  palette.dark.gray.primary,
  palette.dark.lime.primary,
  palette.dark.purple.primary,
  palette.dark.orangePink.primary,
  palette.dark.red.primary,
  palette.dark.color1.primary,
  palette.dark.color2.primary,
  palette.dark.color3.primary,
  palette.dark.color4.primary,
  palette.dark.color5.primary,
  palette.dark.color6.primary,
  palette.dark.color7.primary,
  palette.dark.color8.primary,
  palette.dark.color9.primary,
  palette.dark.color10.primary,
  palette.dark.color11.primary,
  palette.dark.color12.primary,
  palette.dark.color13.primary,
  palette.dark.color14.primary,
  palette.dark.color15.primary,
  palette.dark.color16.primary,
  palette.dark.color17.primary,
  palette.dark.color18.primary,
  palette.dark.color19.primary,
  palette.dark.color20.primary,
  palette.dark.color21.primary,
  palette.dark.color22.primary
];

class DoughnutChart extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const { data } = this.props;
    const propsClone = { ...data };
    const totalSum = sumBy(propsClone.datasets, propsClone.yAxisDataKey[0]);
    propsClone.datasets = map(propsClone.datasets, (set, i) => {
      const colorFromAPI = set.color
        ? set.color.length > 0
          ? set.color
          : null
        : null;
      const color =
        colorFromAPI ||
        (COLORS[i] !== undefined ? COLORS[i] : COLORS[i - COLORS.length]);
      const el = { ...set, color };
      el.name = el[propsClone.xAxisDataKey];
      el[propsClone.yAxisDataKey] = Number(
        ((el[propsClone.yAxisDataKey] / totalSum) * 100).toFixed(2)
      );
      return el;
    });

    return (
      <ResponsiveContainer className="doughnut-wrapper">
        <PieChart>
          {map(propsClone.yAxisDataKey, key => (
            <Pie
              startAngle={90}
              endAngle={-270}
              paddingAngle={2}
              margin={{ bottom: 5 }}
              innerRadius={70}
              outerRadius={90}
              key={`${key} ${Date.now()}`}
              data={propsClone.datasets}
              dataKey={key}
              labelLine
              label
            >
              {propsClone.datasets.map(entry => (
                <Cell stroke={entry.color} key={entry} fill={entry.color} />
              ))}
            </Pie>
          ))}
          <Tooltip cursor={false} content={<CustomPieTooltip />} />
          <Legend wrapperStyle={{ bottom: '0px' }} iconType="circle" />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

// component properties
DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default DoughnutChart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, isEqual } from 'lodash';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/Button/Button';
import CardHeader from 'components/Card/CardHeader';
import Typography from 'components/Typography/Typography';
import Icon from 'components/Icon/Icon';
import { Pin, DownloadIcon } from 'assets/icons/appIcons';

// chart
import RadarChart from 'charts/radar/RadarChart';
import ls from 'lib/core/storageFactory';

class RadarChartComponent extends Component {
  state = {};

  client = ls.get('client');

  componentWillMount() {
    this.setActiveGranularity(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.granularities, nextProps.granularities)) {
      this.setActiveGranularity(nextProps);
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  setActiveGranularity = props => {
    const { granularities } = props;
    map(granularities, gr => {
      this.setState(() => ({ [gr.type]: gr.value }));
    });
  };

  handleGranularityClick = ({
    chartName,
    grType,
    selectedValue,
    option: grObject
  }) => {
    this.setState(() => ({ [grType]: selectedValue }));
    this.props.handleGranularityClick(grObject, grType, {
      [chartName]: { [grType]: selectedValue }
    });
  };

  render() {
    const {
      classes,
      data,
      chartName,
      title,
      granularities,
      isPinned,
      usePin,
      handlePinClick,
      cardStyles
    } = this.props;
    return (
      <Card className={classes.card} chart cardStyles={cardStyles}>
        <CardHeader chart float="right">
          <Typography className={classes.title} cardTitle>
            {title}
          </Typography>
          <div className={classes.granularities}>
            {map(granularities, gr =>
              map(gr.options, option => {
                const active = this.state[gr.type] === option.value;
                return !option.isHidden ? (
                  <Button
                    key={option.value}
                    onClick={() =>
                      this.handleGranularityClick({
                        chartName,
                        grType: gr.type,
                        selectedValue: option.value,
                        option
                      })
                    }
                    active={active}
                    outline
                    size="sm"
                    color="primary"
                  >
                    {option.label}
                  </Button>
                ) : null;
              })
            )}
          </div>
          <div className={classes.actionButtons}>
            <Tooltip title="Download data" aria-label="Download data">
              <Icon color="light" id={`${chartName}-excel-download-button`}>
                <DownloadIcon />
              </Icon>
            </Tooltip>
            {usePin ? (
              <Tooltip
                title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
                aria-label={
                  isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
                }
              >
                <Icon color={isPinned ? 'primary' : 'light'}>
                  <Pin
                    onClick={() => handlePinClick({ isPinned, chartName })}
                  />
                </Icon>
              </Tooltip>
            ) : null}
          </div>
        </CardHeader>
        <CardBody chart>
          {data.datasets.length === 0 ? (
            <Typography center color="light25" size="xs">
              {this.client.includes('kcc-ru')
                ? 'Нет данных для отображения'
                : 'No data to display'}
            </Typography>
          ) : (
            <RadarChart data={data} />
          )}
        </CardBody>
      </Card>
    );
  }
}

// component properties
RadarChartComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  chartName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  granularities: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isPinned: PropTypes.bool,
  usePin: PropTypes.bool,
  handlePinClick: PropTypes.func.isRequired,
  handleGranularityClick: PropTypes.func.isRequired,
  cardStyles: PropTypes.object
};

RadarChartComponent.defaultProps = {
  isPinned: false,
  usePin: true,
  cardStyles: {}
};

export default RadarChartComponent;

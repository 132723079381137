/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { map } from 'lodash';
// action types
import {
  appTypes,
  chartTypes,
  storeExplorerTypes,
  cacheTypes
} from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  action: '',
  isPageLoading: false,
  excelReport: false,
  reportData: {},
  preferences: {
    filters: {},
    granularities: {}
  },
  debugProblems: [],
  productFacing: {
    isLoading: false,
    error: false,
    success: false,
    data: {}
  },
  productFilter: {
    isLoading: false,
    error: false,
    data: {}
  },
  fixProductFacing: {
    isLoading: false,
    error: false,
    success: false,
    data: {}
  },
  saveDebug: {
    isLoading: false,
    error: false,
    data: {}
  },
  filters: {
    next: {},
    isFetching: false,
    list: {}
  },
  chartList: {
    isFetching: false,
    list: []
  },
  chartData: {
    isFetching: false,
    data: {}
  },
  error: {
    state: false,
    type: ''
  },
  images: {
    isFetching: false,
    list: []
  },
  variants: {
    list: []
  },
  stickerBoxes: {
    list: []
  },
  sessions: {
    list: []
  }
};

const reducer = createReducer(initialState, {
  [storeExplorerTypes.productFilter.request]: state => {
    state.productFilter.isLoading = true;
    state.productFilter.error = false;
    state.productFilter.data = {};
  },
  [storeExplorerTypes.productFilter.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.productFilter.isLoading = false;
    state.productFilter.error = false;
    state.productFilter.data = response.data;
  },
  [storeExplorerTypes.productFilter.failure]: (state, action) => {
    state.productFilter.isLoading = false;
    state.productFilter.error = true;
    state.productFilter.data = {};
    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.fixProductFacing.request]: state => {
    state.fixProductFacing.isLoading = true;
    state.fixProductFacing.error = false;
    state.fixProductFacing.success = false;
    state.fixProductFacing.data = {};
  },
  [storeExplorerTypes.fixProductFacing.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.fixProductFacing.isLoading = false;
    state.fixProductFacing.success = true;
    state.fixProductFacing.error = false;
    state.fixProductFacing.data = response.data;
    state.productFacing.data = { message: response.message };
  },
  [storeExplorerTypes.fixProductFacing.failure]: (state, action) => {
    const {
      payload: { message }
    } = action;
    state.fixProductFacing.isLoading = false;
    state.fixProductFacing.success = false;
    state.fixProductFacing.error = true;
    state.productFacing.error = true;
    state.fixProductFacing.data = {};
    state.productFacing.data = { message: message.message };
    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.getDebugProblems.request]: state => {
    state.isPageLoading = true;
    state.debugProblems = [];
  },
  [storeExplorerTypes.getDebugProblems.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    state.debugProblems = response.data;
    state.error.state = false;
  },
  [storeExplorerTypes.getDebugProblems.failure]: (state, action) => {
    state.isPageLoading = false;
    state.debugProblems = [];
    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.updateProductFacing.request]: state => {
    state.productFacing.isLoading = true;
    state.productFacing.error = false;
    state.productFacing.success = false;
  },
  [storeExplorerTypes.updateProductFacing.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.productFacing.isLoading = false;
    state.productFacing.success = true;
    state.productFacing.error = false;
    state.productFacing.data = { ...response };
  },
  [storeExplorerTypes.updateProductFacing.failure]: (state, action) => {
    const {
      payload: { message }
    } = action;
    state.productFacing.isLoading = false;
    state.productFacing.error = true;
    state.productFacing.success = false;
    state.productFacing.data = { ...message };

    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.updateProductFacing.reset]: (state, action) => {
    state.productFacing.isLoading = false;
    state.productFacing.error = false;
    state.productFacing.success = false;
    state.productFacing.data.message = '';
  },
  [storeExplorerTypes.fixProductFacing.reset]: (state, action) => {
    state.fixProductFacing.isLoading = false;
    state.fixProductFacing.error = false;
    state.fixProductFacing.success = false;
  },
  [storeExplorerTypes.saveDashboardDebug.request]: state => {
    state.saveDebug.isLoading = true;
    state.saveDebug.error = false;
  },
  [storeExplorerTypes.saveDashboardDebug.success]: (state, action) => {
    const {
      payload: { params, response }
    } = action;
    state.saveDebug.isLoading = false;
    state.saveDebug.error = false;
    state.saveDebug.data = { ...response };
    if (response.status === 200) {
      const problems = [...state.debugProblems, ...JSON.parse(params.problem)];
      state.debugProblems = [...new Set(problems)];
    }
  },
  [storeExplorerTypes.saveDashboardDebug.failure]: (state, action) => {
    state.saveDebug.isLoading = false;
    state.saveDebug.error = true;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [storeExplorerTypes.getDashboardDebug.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.saveDebug.data = { ...response.data };
  },
  [storeExplorerTypes.getShelfImage.request]: (state, action) => {
    const { type } = action;
    state.isPageLoading = true;
    state.images = { ...state.images, isFetching: true };
    state.action = type;
  },
  [storeExplorerTypes.getShelfImage.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;
    state.images = { list: response.data.images, isFetching: false };
    state.variants = { list: response.data.variants };
    state.stickerBoxes = { list: response.data.sticker_boxes };
    state.excelReport = response.data.excel_report;
    state.error.state = false;
  },
  [storeExplorerTypes.getShelfImage.failure]: (state, action) => {
    state.isPageLoading = false;
    state.images = { list: [], isFetching: false };
    state.variants = { list: [] };
    state.stickerBoxes = { list: [] };
    state.error = {
      state: true,
      type: action.type
    };
  },
  [chartTypes.downloadChartData.request]: state => {
    state.isFetching = true;
    state.error = {
      state: false,
      type: ''
    };
    state.reportData = {};
  },
  [chartTypes.downloadChartData.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isFetching = false;
    state.reportData = { ...response.data };
    state.error = {
      state: false,
      type: ''
    };
  },
  [chartTypes.downloadChartData.failure]: (state, action) => {
    const { type } = action;
    state.isFetching = false;
    state.error = {
      state: true,
      type
    };
    state.isFetching = false;
    state.reportData = {};
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  },
  [storeExplorerTypes.getStoreExplorerFilter.request]: (state, action) => {
    state.filters.isFetching = true;
    if (action.payload.params.next === 'fl_store') {
      state.images = {
        isFetching: false,
        list: []
      };
      state.variants = {
        list: []
      };
      state.stickerBoxes = {
        list: []
      };
    }
  },
  [storeExplorerTypes.getStoreExplorerFilter.success]: (state, action) => {
    state.filters.isFetching = false;
    state.filters.next = action.payload.response.data.next;
    state.filters.list = {
      ...state.filters.list,
      [action.payload.params.next]: {
        ...action.payload.response.data,
        options: action.payload.response.data.values,
        name: action.payload.params.label
      }
    };
    if (action.payload.response.data.next) {
      if (action.payload.response.data.next.date === 'fl_date') {
        state.filters.list = {
          ...state.filters.list,
          fl_date: {
            options: [],
            name: 'Date'
          }
        };
      }
    }
  },
  [appTypes.filterChange]: (state, action) => {
    const {
      payload: { routeID, value: filterList, next, deleteValues = [] }
    } = action;
    const filterPreferences = { ...state.preferences.filters[routeID] };

    map(state.filters.list, (_filter, key) => {
      if (deleteValues.includes(key)) {
        delete state.filters.list[key];
        state.filters.next = next;
      }
      if (key === 'fl_date') {
        state.filters.list.fl_date = {
          name: 'Date',
          options: []
        };
      }
    });
    map(filterPreferences, (_filter, key) => {
      if (deleteValues.includes(key)) {
        if (key !== 'fl_date') {
          delete filterPreferences[key];
        }
      }
    });
    const filters = {
      ...filterPreferences,
      ...filterList
    };
    state.activeRouteID = routeID;
    state.preferences.filters[routeID] = { ...filters };
  }
});

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import AIAccuracyTable from 'pages/Accuracy/AccuracyTable';
import Typography from 'components/Typography/Typography';

const styles = {
  root: {
    maxWidth: 'calc(100vw - 18rem )',
    overflowX: 'scroll',
    display: 'flex'
  },
  toolbar: {
    marginBottom: '13px',
    marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0
  },
  imgWrapper: {},
  canvas: {
    maxHeight: 'calc(100vh - 100px)'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    '& h4': {
      fontSize: 14
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  currentAIVersionButton: {
    border: '2px solid #797983',
    padding: '8px',
    color: '#797983',
    '& h4': {
      fontSize: '12px'
    }
  },
  previousAIVersionButton: {
    border: '2px solid #488b97',
    padding: '8px',
    color: '#488b97',
    '& h4': {
      fontSize: '12px'
    }
  }
};

const AccuracyTable = ({
  classes,
  tableData,
  enablePreviousVersion,
  setTableTooltipStatus,
  tableTooltipStatus,
  chartName
}) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card} chart>
          <CardHeader chart float="right">
            <div className={classes.cardHeader}>
              <div className={classes.title}>
                <Typography>{tableData.title}</Typography>
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.currentAIVersionButton}>
                  <Typography>Current AI Version</Typography>
                </div>
                {enablePreviousVersion ? (
                  <div className={classes.previousAIVersionButton}>
                    <Typography color="#488b97">Previous AI Version</Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </CardHeader>
          <CardBody chart maxWidth="98%" height="auto">
            <AIAccuracyTable
              width="100%"
              data={tableData}
              enablePreviousVersion={enablePreviousVersion}
              setTableTooltipStatus={setTableTooltipStatus}
              tableTooltipStatus={tableTooltipStatus}
              chartName={chartName}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

AccuracyTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.object.isRequired,
  enablePreviousVersion: PropTypes.bool,
  setTableTooltipStatus: PropTypes.func.isRequired,
  tableTooltipStatus: PropTypes.object.isRequired,
  chartName: PropTypes.string.isRequired
};

AccuracyTable.defaultProps = {
  enablePreviousVersion: false
};

export default withStyles(styles)(AccuracyTable);

/* eslint-disable import/prefer-default-export */
import { createAction } from '@reduxjs/toolkit';
import { userTypes } from 'redux-utils/types';
import apiModel from 'models/apiModel';
import reducerModel from 'models/reducerModel';

const getUserProfileAction = (
  payload,
  meta = { reducerID: [reducerModel.userProfile.id] }
) => {
  const action = createAction(userTypes.getUserProfile.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getUserProfile,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: userTypes.getUserProfile.success,
    requestAction: userTypes.getUserProfile.fromAPI
  });
  action.meta = meta;
  return action;
};

const getUserPreferenceAction = (
  payload,
  meta = { reducerID: [reducerModel.userProfile.id] }
) => {
  const action = createAction(userTypes.getUserPreference.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getUserPrefs,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: userTypes.getUserPreference.success,
    requestAction: userTypes.getUserPreference.fromAPI
  });
  action.meta = meta;
  return action;
};

const saveUserPreferenceAction = (
  payload,
  meta = { reducerID: [reducerModel.userProfile.id] }
) => {
  const action = createAction(userTypes.saveUserPreference.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  getUserProfileAction,
  getUserPreferenceAction,
  saveUserPreferenceAction
};

const types = {
  filterChange: 'PAGE_FILTER_CHANGE_NOTIFICATION',
  clearFilter: 'CLEAR_FILTER_LIST',
  routeChange: 'APP_ROUTE_CHANGE',
  updateLastUsedState: {
    request: 'UPDATE_LAST_USED_STATE'
  },
  filterOrder: {
    set: 'SET_FILTER_ORDER'
  },
  getAnalyticsRedirect: {
    request: 'GET_ANALYTICS_REDIRECT_REQUEST',
    success: 'GET_ANALYTICS_REDIRECT_SUCCESS',
    failure: 'GET_ANALYTICS_REDIRECT_FAILURE',
    fromAPI: 'GET_ANALYTICS_REDIRECT_FROM_API'
  },
  getClientPreferences: {
    request: 'GET_CLIENT_PREFERENCES_REQUEST',
    success: 'GET_CLIENT_PREFERENCES_SUCCESS',
    failure: 'GET_CLIENT_PREFERENCES_FAILURE',
    fromAPI: 'GET_CLIENT_PREFERENCES_FROM_API'
  },
  getFilterList: {
    request: 'GET_FILTER_LIST_REQUEST',
    success: 'GET_FILTER_LIST_SUCCESS',
    failure: 'GET_FILTER_LIST_FAILURE',
    fromAPI: 'GET_FILTER_LIST_FROM_API'
  },
  AIAccuracySessionFilter: {
    request: 'GET_AI_SESSION_FILTER_LIST_REQUEST',
    success: 'GET_AI_SESSION_FILTER_LIST_SUCCESS',
    failure: 'GET_AI_SESSION_FILTER_LIST_FAILURE',
    fromAPI: 'GET_AI_SESSION_FILTER_LIST_FROM_API'
  },
  getLayout: {
    request: 'GET_LAYOUT_REQUEST',
    success: 'GET_LAYOUT_SUCCESS',
    failure: 'GET_LAYOUT_FAILURE',
    fromAPI: 'GET_LAYOUT_FROM_API'
  },
  updatePreferences: {
    request: 'UPDATE_USER_PREFERENCE_REQUEST',
    success: 'UPDATE_USER_PREFERENCE_SUCCESS',
    failure: 'UPDATE_USER_PREFERENCE_FAILURE'
  },
  pinToDashboard: {
    request: 'PIN_TO_DASHBOARD_REQUEST',
    success: 'PIN_TO_DASHBOARD_SUCCESS',
    failure: 'PIN_TO_DASHBOARD_FAILURE',
    fromAPI: 'PIN_TO_DASHBOARD_FROM_API'
  },
  unPinFromDashboard: {
    request: 'UNPIN_FROM_DASHBOARD_REQUEST',
    success: 'UNPIN_FROM_DASHBOARD_SUCCESS',
    failure: 'UNPIN_FROM_DASHBOARD_FAILURE',
    fromAPI: 'UNPIN_FROM_DASHBOARD_FROM_API'
  },
  sidebarFilters: {
    set: 'SET_SIDEBAR_FILTERS',
    clear: 'CLEAR_SIDEBAR_FILTERS'
  },
  surveyDetails: {
    request: 'SURVEY_DETAILS_REQUEST',
    success: 'SURVEY_DETAILS_SUCCESS',
    failure: 'SURVEY_DETAILS_FAILURE',
  },
};

export default types;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getPreviousDate } from 'lib/core/dateUtils';

/* Components */
import DropDown from 'components/Dropdown/Dropdown';
import TimeFilter from 'layout/Filter/TimeFilter';

import withStyles from '@material-ui/core/styles/withStyles';
import style from 'pages/AppImages/AppImageStyles';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const options = {
  options: [
    {
      label: client
        ? client.includes('kcc-ru')
          ? 'Открыть календарь'
          : 'Open Calendar'
        : 'Open Calendar',
      value: 'custom'
    }
  ]
};

const AppImagesFilters = ({
  classes,
  handleDateChange,
  handleStoreChange,
  handleSessionChange,
  stores,
  store,
  sessions,
  session,
  value,
  dropdownTitleStyles,
  dropdownStyles: dpStyles
}) => {
  const initialSelectedDate = {
    start: getPreviousDate(1, 'days', false),
    end: getPreviousDate(0, 'days', false)
  };

  return (
    <div className={classes.appImagesFiltersContainer}>
      <TimeFilter
        useRange={false}
        title={client.includes('kcc-ru') ? 'Выбрать дату' : 'Select Date'}
        options={options.options}
        defaultDateRange={initialSelectedDate}
        handleDateChange={handleDateChange}
        value={value}
        routeID="app_images"
        type="fl_date"
        useMenuPortalTarget={false}
        parentComponentClass=".dashboardPanel"
        dropdownStyles={dpStyles}
        dropdownTitleStyles={dropdownTitleStyles}
      />
      <div className={classes.dropDown}>
        <DropDown
          title={client.includes('kcc-ru') ? 'Выбрать магазин' : 'Select Store'}
          options={stores}
          width={160}
          handleValueChange={handleStoreChange}
          value={isEmpty(store) ? stores[0] : store}
          useMenuPortalTarget={false}
          routeID="app_images"
          type="fl_store"
          dropdownStyles={dpStyles}
          dropdownTitleStyles={dropdownTitleStyles}
        />
      </div>
      <div className={classes.dropDown}>
        <DropDown
          title={
            client.includes('kcc-ru') ? 'Выбрать сессию' : 'Select Session'
          }
          options={sessions}
          width={160}
          handleValueChange={handleSessionChange}
          value={isEmpty(session) ? sessions[0] : session}
          useMenuPortalTarget={false}
          routeID="app_images"
          type="fl_session"
          dropdownStyles={dpStyles}
          dropdownTitleStyles={dropdownTitleStyles}
        />
      </div>
    </div>
  );
};

AppImagesFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleStoreChange: PropTypes.func.isRequired,
  handleSessionChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  stores: PropTypes.array,
  store: PropTypes.object,
  session: PropTypes.object,
  sessions: PropTypes.array,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object
};

AppImagesFilters.defaultProps = {
  stores: [],
  store: {},
  session: {},
  sessions: [],
  dropdownTitleStyles: {},
  dropdownStyles: {}
};

export default withStyles(style)(AppImagesFilters);

import { isEmpty, forEach } from 'lodash';
// core functions
import { formatDate, getPreviousDate } from 'lib/core/dateUtils';
import { uniqBy } from 'lib/core/array';
import ls from 'lib/core/storageFactory';
// routes
import {
  DashboardIcon,
  ShelfIcon,
  DisplayIcon,
  OSAIcon,
  PositionIcon,
  ExplorerIcon
} from 'assets/icons/appIcons';
// pages
import ChartRenderContainer from 'pages/Common/ChartRenderContainer';
import StoreExplorerContainer from 'pages/StoreExplorer/StoreExplorerContainer';
import StoreExplorerContainerNew from 'pages/StoreExplorerNew/StoreExplorerContainerNew';
import TestStoreExplorerContainer from 'pages/TestStoreExplorer/TestStoreExplorerContainer';
import AppImagesContainer from 'pages/AppImages/AppImagesContainer';
import AdminContainer from 'pages/Admin/AdminContainer';
import AccuracyContainer from 'pages/Accuracy/AccuracyContainer';
import MerchEvaluationContainer from 'pages/MerchEvaluation/MerchEvaluationContainer';
import SEContainer from 'pages/SE/SEContainer';
import GenericSEContainer from 'pages/GenericSE/GenericSE';
import SEPGContainer from 'pages/SE-PG/SEContainer';
import AnalyticsRedirect from 'pages/AnalyticsRedirect/AnalyticsRedirect';
import SurveyContainer from 'pages/Survey/SurveyContainer';

const client = ls.get('client');

const icons = [
  DashboardIcon,
  ShelfIcon,
  DisplayIcon,
  OSAIcon,
  PositionIcon,
  ExplorerIcon
];

const randomIntFromInterval = (input, max) => {
  if (input <= max) {
    return input;
  }
  const int = (input % max) - 1 === -1 ? 0 : (input % max) - 1;
  return int;
};

const generateRoute = (source = [], dest = []) => {
  let defaultRoute = '';

  const isDefaultRouteAvailable = source.some(item => !item.is_default);

  if (isDefaultRouteAvailable) {
    const firstRoute = source[0];
    defaultRoute = `/app/${firstRoute.routeID}`;
  } else {
    source.forEach(route => {
      if (route.is_default) {
        defaultRoute = `/app/${route.routeID}`;
      }
    });
  }

  let redirectRoute = defaultRoute;
  source.forEach((e, i) => {
    const {
      title,
      routeID,
      'right-menu': isRightMenu,
      is_default: isDefault
    } = e;
    if (!isRightMenu) {
      const rootPath = {};
      rootPath.component = ChartRenderContainer;
      rootPath.navbarName = title;
      rootPath.icon = icons[randomIntFromInterval(i, 5)];
      rootPath.path = `/app/${routeID}`;
      rootPath.sidebarName = title;
      rootPath.navbarName = title;
      rootPath.routeID = routeID;
      if (routeID.includes('store-explorer')) {
        rootPath.component = StoreExplorerContainer;
      }
      if (routeID.includes('pgindo-store-explorer')) {
        rootPath.component = SEPGContainer;
      }
      if (routeID.includes('store-explorer-v2')) {
        rootPath.component = GenericSEContainer;
      }
      if (routeID.includes('visibility-store-explorer')) {
        rootPath.component = SEContainer;
      }
      if (routeID.includes('new-store-explorer')) {
        rootPath.component = StoreExplorerContainerNew;
      }
      if (routeID.includes('store-explorer-new')) {
        rootPath.component = StoreExplorerContainerNew;
      }
      if (routeID.includes('test-store-explorer')) {
        rootPath.component = TestStoreExplorerContainer;
      }
      if (routeID.includes('app-images')) {
        rootPath.component = AppImagesContainer;
      }
      if (routeID.includes('admin')) {
        rootPath.component = AdminContainer;
      }
      if (routeID.includes('accuracy')) {
        rootPath.component = AccuracyContainer;
      }
      if (routeID.includes('merchandiser-performance')) {
        rootPath.component = MerchEvaluationContainer;
      }
      if (routeID.includes('analytics-redirect')) {
        rootPath.component = AnalyticsRedirect;
      }
      // survey route start
      if (routeID.includes('survey')) {
        rootPath.component = SurveyContainer;
      }
      // survey route end
      if (isDefault) {
        redirectRoute = rootPath.path;
      }
      dest.push(rootPath);
    }
  });
  dest.push({
    redirect: true,
    path: '/app',
    to: redirectRoute
  });
  return dest;
};

const generateRightMenu = (source, dest = []) => {
  forEach(source, e => {
    const { title, routeID, 'right-menu': isRightMenu } = e;
    if (isRightMenu) {
      const rootPath = {};
      rootPath.component = ChartRenderContainer;
      rootPath.navbarName = title;
      rootPath.icon = icons[randomIntFromInterval(0, 5)];
      rootPath.path = `/app/${routeID}`;
      rootPath.sidebarName = title;
      rootPath.navbarName = title;
      rootPath.routeID = routeID;
      if (routeID.includes('visibility-store-explorer')) {
        rootPath.component = SEContainer;
      }
      if (routeID.includes('pgindo-store-explorer')) {
        rootPath.component = SEPGContainer;
      }
      if (routeID.includes('store-explorer')) {
        rootPath.component = StoreExplorerContainer;
      }
      if (routeID.includes('store-explorer-new')) {
        rootPath.component = StoreExplorerContainerNew;
      }
      if (routeID.includes('new-store-explorer')) {
        rootPath.component = StoreExplorerContainerNew;
      }
      if (routeID.includes('app-images')) {
        rootPath.component = AppImagesContainer;
      }
      if (routeID.includes('survey')) {
        rootPath.component = SurveyContainer;
      }
      if (routeID.includes('accuracy')) {
        rootPath.component = AccuracyContainer;
      }
      dest.push(rootPath);
    }
  });
  return dest;
};

const computeFilters = data => {
  const customDateObj = {
    label: client.includes('kcc-ru') ? 'Настроить' : 'Custom',
    value: 'custom',
    args: 'cus'
  };

  let { list } = data.filters;
  list = { ...list };

  if (!isEmpty(list)) {
    list.fl_date = {
      name: 'Date',
      options: [
        {
          label: formatDate(getPreviousDate(0, 'days', false), true),
          value: 'today',
          is_default: true
        },
        {
          label: client.includes('kcc-ru') ? 'Настроить' : 'Custom',
          value: 'custom',
          args: 'cus'
        }
      ]
    };
  }
  if (list.fl_time !== undefined) {
    const flTime = { ...list.fl_time };
    flTime.options = flTime.options.map(el => {
      switch (el.label) {
        case 'Last Week':
          return { ...el, value: 'lw' };
        case 'Last Month':
          return { ...el, value: 'lm' };
        case 'Last Quarter':
          return { ...el, value: 'lq' };
        case 'Last 3 Months':
          return { ...el, value: 'lq' };
        case 'Last 7 Days':
          return { ...el, value: 'l7' };
        case 'Last 30 Days':
          return { ...el, value: 'l30' };
        case 'Last 90 Days':
          return { ...el, value: 'l90' };
        case 'Предыдущая неделя':
          return { ...el, value: 'lw' };
        case 'Предыдущий месяц':
          return { ...el, value: 'lm' };
        case 'Предыдущие 3 месяца':
          return { ...el, value: 'lq' };
        case 'Предыдущие 7 дней':
          return { ...el, value: 'l7' };
        case 'Предыдущие 30 дней':
          return { ...el, value: 'l30' };
        case 'Предыдущие 90 дней':
          return { ...el, value: 'l90' };
        default:
          return el;
      }
    });
    flTime.options.push(customDateObj);
    flTime.options = uniqBy(flTime.options, 'value');
    list.fl_time = flTime;
  }
  return list;
};

export { computeFilters, generateRoute, generateRightMenu };

import React from 'react';
import PropTypes from 'prop-types';
import { VSpacer } from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const NoDataMessage = ({ dashboardPanelStyles }) => (
  <>
    <VSpacer amount={30} />
    <Typography
      weight="400"
      color="light25"
      align="center"
      textColor={dashboardPanelStyles?.textColor}
    >
      {client.includes('kcc-ru')
        ? 'Данные для выбранной даты отсутствуют'
        : 'No data available for the selected date'}
    </Typography>
  </>
);

NoDataMessage.propTypes = {
  dashboardPanelStyles: PropTypes.object
};

NoDataMessage.defaultProps = {
  dashboardPanelStyles: {}
};

export default NoDataMessage;

import React from 'react';
import { array, bool, object } from 'prop-types';
import { isEmpty } from 'lodash';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from 'components/Typography/Typography';
import Loader from 'components/Loader/Loader';
import enums from 'models/enums';

const COLORS = ['#00AF64', '#00BDD3', '#2B9DFA', '#DC9E64', '#FAA3A3'];

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: 240
  },
  container: {
    maxWidth: '100%',
    padding: 15,
    background: '#37374a',
    borderRadius: 20,
    display: 'flex',
    flexFlow: 'column',
    gap: '10px',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    gap: '20px',
    '& h4:nth-of-type(2)': {
      marginLeft: 'auto'
    },
    '& span': {
      position: 'relative',
      '&::before': {
        top: 10,
        left: '-20px',
        width: 20,
        height: 2,
        content: '""',
        position: 'absolute',
        transform: 'rotate(90deg)',
        background: 'rgba(255,255,255,0.5)'
      }
    }
  },
  cardList: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    gap: '10px',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  card: {
    width: 'auto',
    padding: 15,
    minWidth: 'auto',
    maxHeight: 180,
    minHeight: 170,
    borderRadius: 10,
    display: 'flex',
    flexFlow: 'column'
  },
  valueWrapper: {
    display: 'flex',
    gap: '20px'
  },
  value: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'relative',
    '& p': {
      fontSize: 20,
      color: '#fff'
    }
  },
  previousValue: {
    '& p': {
      color: 'rgba(0,0,0,0.8)'
    },
    '&::before': {
      top: 35,
      left: -27,
      width: 35,
      height: 2,
      content: '""',
      position: 'absolute',
      transform: 'rotate(90deg)',
      background: 'rgba(0,0,0,0.8)'
    }
  },
  label: {
    width: 190,
    position: 'relative',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    breakAfter: 'left',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    display: 'inline',
    '&::after': {
      left: 0,
      bottom: '-12px',
      width: 30,
      height: 2,
      content: '""',
      position: 'absolute',
      background: 'rgba(255, 255, 255, 0.5)'
    }
  }
};

const getColor = index => {
  const { length } = COLORS;
  const position = index - length * Math.floor(index / length);
  return COLORS[index] || COLORS[position];
};

const AccuracyCards = ({
  accuracyNumbers,
  classes,
  compareVersions,
  apiRequestStatus
}) => {
  const [currentVersion] = accuracyNumbers.filter(el => el.is_current);
  const [previousVersion] = accuracyNumbers.filter(el => !el.is_current);
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography>Cumulative Accuracy</Typography>
          <Typography>Current AI version</Typography>
          {compareVersions && (
            <span>
              <Typography>Previous AI version</Typography>
            </span>
          )}
        </div>
        {apiRequestStatus.getSessionDetails.status !==
          enums.apiRequest.REQUEST &&
        (!currentVersion || isEmpty(currentVersion.data)) ? (
          <Typography align="center">Data not available</Typography>
        ) : null}
        {apiRequestStatus.getSessionDetails.status ===
        enums.apiRequest.REQUEST ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader color="primary" simple circular />
          </div>
        ) : null}
        <div className={classes.cardList}>
          {currentVersion &&
            currentVersion.data.map((el, index) => {
              return (
                <div
                  style={{ background: getColor(index) }}
                  key={el.title}
                  className={classes.card}
                >
                  <div className={classes.valueWrapper}>
                    <div className={classes.value}>
                      <Typography size={50} color="#fff">
                        {el.value}
                      </Typography>
                      <span>
                        <p>%</p>
                      </span>
                    </div>
                    {compareVersions && previousVersion && (
                      <>
                        <div
                          className={`${classes.value} ${classes.previousValue}`}
                        >
                          <Typography size={50} color="rgba(0,0,0,0.8)">
                            {previousVersion.data[index].value}
                          </Typography>
                          <span>
                            <p>%</p>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <Typography
                    className={classes.label}
                    color="rgba(255,255,255,0.8)"
                  >
                    {el.title}
                  </Typography>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

AccuracyCards.propTypes = {
  accuracyNumbers: array,
  classes: object.isRequired,
  compareVersions: bool,
  apiRequestStatus: object.isRequired
};

AccuracyCards.defaultProps = {
  accuracyNumbers: [],
  compareVersions: false
};

export default withStyles(styles)(AccuracyCards);

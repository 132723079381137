import { buttonDark, defaultColor, scrollbar } from '../material-ui';

const styledBy = (property, mapping) => props => {
  if (property === 'width') {
    return props.width;
  }
  return mapping[props[property]];
};

const tableStyles = theme => ({
  root: {
    width: styledBy('width', {}),
    // paddingRight: 0,
    paddingRight: '20px',
    overflow: 'auto',
    height: 'inherit',
    // marginLeft: 20,
    paddingLeft: '20px',
    boxShadow: 'none',
    background: 'transparent',
    ...scrollbar,
    scrollbarColor:
      theme.palette.type === 'light'
        ? '#f5f5f5 transparent'
        : 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5' : 'rgb(65, 65, 66)',
      borderRadius: 8
    },
    '& th:first-child': {
      width: '500px'
    },
    '& td:first-child': {
      width: '500px'
    }
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: buttonDark
    },
    '&:nth-of-type(even)': {
      backgroundColor: defaultColor
    }
  },
  active: {
    border: '1px solid #466ecc'
  },
  highlight: {
    border: '1px solid #f36'
  },
  tooltipWrapper: {
    background: 'rgb(236, 236, 236)'
  },
  tooltip: {
    '& p': {
      margin: 2,
      display: 'flex',
      justifyContent: 'space-between',
      '& span:nth-of-type(1)': {
        color: '#000',
        fontWeight: 600,
        fontSize: 15,
        marginRight: 5
      },
      '& span:nth-of-type(2)': {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 15
      }
    }
  },
  priceCompliance: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    '& h4': {
      color: 'hsla(0,0%,100%,.6)'
    }
  },
  notCompliant: {
    fill: 'red',
    width: 60,
    height: 60
  },
  compliant: {
    fill: '#43A047',
    width: 30,
    height: 30
  },
  checkBox: {
    padding: '12px 0 !important'
  },
  packshot: {
    height: 80,
    width: 'auto',
    maxWidth: '100%'
  }
});

export default tableStyles;

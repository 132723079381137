import React, { useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
// @material-ui/core
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
// icons
import { DeleteAltIcon } from 'assets/icons/appIcons';

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1e1e2b',
        maxWidth: 'calc(100vw - 500px)',
        width: 'calc(100vw - 500px)'
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiDropzoneArea: {
      root: {
        outline: 'none',
        backgroundColor: '#1e1e2b',
        minHeight: 130,
        border: '1px dashed',
        borderColor: 'rgb(189 174 174 / 34%)'
      },
      text: {
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 8,
        paddingTop: 0,
        '&>button': {
          textTransform: 'capitalize'
        },
        '&>button:nth-of-type(1)': {
          color: '#f36'
        },
        '&>button:nth-of-type(2)': {
          color: '#afa'
        },
        '&>button:nth-of-type(2):disabled': {
          color: 'gray'
        }
      }
    },
    MuiDropzonePreviewList: {
      root: {
        marginTop: 10
      }
    },
    MuiChip: {
      root: {
        backgroundColor: '#52596b !important',
        color: 'rgba(255,255,255,0.8)'
      }
    }
  }
});

const styles = {
  font: {
    color: 'rgba(255, 255, 255, 0.8) !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
  },
  closeButton: { right: '12px', top: '8px', position: 'absolute' },
  referencesContainer: {
    display: 'flex',
    width: 'inherit',
    overflow: 'auto',
    margin: '10px 0',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      // backgroundColor: 'white',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  imageWrapper: {
    position: 'relative',
    display: 'inline-block',
    fontSize: 0,
    '&:hover': {
      '&$close': {
        opacity: 1
      }
    }
  },
  dialogContent: {
    '& div[aria-describedby="client-snackbar"][class*="MuiDropzoneSnackbar"]': {
      width: 'auto'
    }
  },
  deleteButton: {
    position: 'absolute',
    color: '#f36',
    cursor: 'pointer'
  },
  image: {
    maxHeight: 150,
    maxWidth: 200,
    padding: 3
  }
};

const ReferenceImageUpload = ({
  classes,
  setOpen,
  displayRow,
  handleSubmit,
  isOpen
}) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const [deletedImages, setDeletedImages] = useState([]);
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleOKButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    handleSubmit({
      fileObjects: fileObjects || [],
      deletedImages,
      displayRow
    });
    closeDialog();
  };

  const handleCancelButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
  };

  const resetToInitialState = () => {
    setDisplayData({});
    setFileObjects([]);
    setDeletedImages([]);
  };

  useEffect(() => {
    setDisplayData(displayRow);
    return () => {
      resetToInitialState();
    };
  }, [displayRow]);

  const closeDialog = () => {
    resetToInitialState();
    setOpen(false);
    if (snackBarState.isOpen) {
      handleSnackBarClose();
    }
  };

  const handleSave = () => {
    setSnackBarState({
      isOpen: true,
      message: `This will modify the existing data. Are you sure?`,
      severity: 'info'
    });
  };

  const dialogTitle = (
    <>
      <span className={classes.font}>Upload reference images zip file</span>
      <IconButton className={classes.closeButton} onClick={() => closeDialog()}>
        <CloseIcon />
      </IconButton>
    </>
  );

  const handleDeleteImage = image => {
    setDisplayData(prev => {
      if (prev.references) {
        return {
          ...prev,
          references: prev.references.filter(img => img.id !== image.id)
        };
      }
      return prev;
    });
    setDeletedImages(prev => [...prev, image]);
  };

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          action={
            <>
              <Button color="inherit" size="small" onClick={handleOKButton}>
                OK
              </Button>
              <Button color="inherit" size="small" onClick={handleCancelButton}>
                Cancel
              </Button>
            </>
          }
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <MuiThemeProvider theme={theme}>
        <div>
          <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {dialogTitle}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DropzoneArea
                filesLimit={1}
                acceptedFiles={['.zip']}
                fileObjects={fileObjects}
                maxFileSize={50000000000}
                useChipsForPreview
                previewText=""
                showPreviews
                showFileNamesInPreview
                showPreviewsInDropzone={false}
                onAdd={newFile => {
                  setFileObjects(newFile);
                }}
                onDrop={newFile => {
                  setFileObjects(newFile);
                }}
                onDelete={() => {
                  setFileObjects([]);
                }}
              />
              <div className={classes.referencesContainer}>
                {displayData.references
                  ? displayData.references.map(image => {
                      return (
                        <div key={image.id} className={classes.imageWrapper}>
                          <span
                            className={classes.deleteButton}
                            onClick={() => handleDeleteImage(image)}
                          >
                            <DeleteAltIcon />
                          </span>
                          <img
                            alt={image.label}
                            src={image.url}
                            className={classes.image}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                autoFocus
                disabled={!fileObjects.length > 0 && !deletedImages.length > 0}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
    </>
  );
};

ReferenceImageUpload.propTypes = {
  classes: object.isRequired,
  displayRow: object,
  isOpen: bool,
  setOpen: func,
  handleSubmit: func
};

ReferenceImageUpload.defaultProps = {
  isOpen: false,
  displayRow: {},
  setOpen: () => {},
  handleSubmit: () => {}
};

export default withStyles(styles)(ReferenceImageUpload);

import React, { useState } from 'react';
import { array, object, func } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import InputField from 'components/TextField/TextField';
import Button from 'components/Button/Button';

const styles = {
  gridContainer: {
    height: 'auto',
    overflow: 'auto',
    padding: '10px',
    '& div.MuiGrid-item': {
      marginBottom: '20px',
      paddingLeft: '5px !important'
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '14px',
    '& button': {
      padding: '8px 20px !important',
      textTransform: 'capitalize !important'
    }
  },
  grayButton: {
    background: '#27293d',
    marginLeft: '10px',
    boxShadow: 'none !important',
    '&:hover': {
      background: '#ffffff33'
    },
    '&:focus': {
      background: '#ffffff33'
    }
  }
};

const AddRow = ({ classes, data, handleFormSubmit, setModelVisibility }) => {
  const mandatoryFields = [];
  const [formData, setFormData] = useState(
    data.reduce((obj, item) => Object.assign(obj, { [item.key]: '' }), {})
  );
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].is_mandatory) {
      mandatoryFields.push(data[i].key);
    }
  }

  const onChangeHandler = (value, key) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value.trim()
    }));
  };

  const handleSubmitClick = () => {
    if (!mandatoryFields.every(currentValue => formData[currentValue] !== '')) {
      return;
    }
    const regExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (formData.email && !regExp.test(formData.email)) {
      setIsEmailInvalid(true);
      return;
    }
    handleFormSubmit(formData);
    setModelVisibility(false);
  };

  const handleCancel = () => {
    setModelVisibility(false);
  };

  return (
    <>
      <GridContainer classNames={classes.gridContainer}>
        {data.map(rowData => {
          if (
            (rowData.column_type === 'text' ||
              rowData.column_type === 'email' ||
              rowData.column_type === 'password') &&
            (rowData.is_creatable === undefined ? true : rowData.is_creatable)
          ) {
            return (
              <GridItem key={rowData.key} xs={12} sm={12} md={6}>
                <InputField
                  onChange={e => onChangeHandler(e.target.value, rowData.key)}
                  id={rowData.key}
                  type={rowData.column_type}
                  label={rowData.label}
                  colorTheme="dark"
                  fullWidth
                  // required={rowData.is_mandatory}
                  variant="outlined"
                  helperText={
                    rowData.column_type === 'email' && isEmailInvalid
                      ? 'enter valid email'
                      : ''
                  }
                  error={rowData.column_type === 'email' && isEmailInvalid}
                />
              </GridItem>
            );
          }
          return null;
        })}
      </GridContainer>
      <div className={classes.buttonGroup}>
        <Button
          color="success"
          onClick={handleSubmitClick}
          disabled={
            !mandatoryFields.every(
              currentValue => formData[currentValue] !== ''
            )
          }
        >
          Add
        </Button>
        <Button className={classes.grayButton} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

AddRow.propTypes = {
  classes: object.isRequired,
  data: array.isRequired,
  handleFormSubmit: func.isRequired,
  setModelVisibility: func.isRequired
};

export default withStyles(styles)(AddRow);

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React, {
  createRef,
  useReducer,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { array, bool, func, object } from 'prop-types';
import { sumBy } from 'lodash';

// components
import MiniMap from './MiniMap';
import ShelfCanvas from './ShelfCanvas';

const scaleDownValues = (value, factor) => {
  return value / factor;
};

const scaleUpValues = (value, factor) => {
  return value * factor;
};

const SHELF_COLOR = '#673ab7';
const DISPLAY_COLOR = '#F57C00';

const initialState = {
  miniMap: {
    miniMapDragging: false,
    activeArea: [0, 0],
    scaleDownFactor: 10
  },
  shelfCanvas: {
    canvasScroll: false,
    width: 0,
    activeArea: [0, 0]
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'REGISTER_SHELF_CANVAS':
      return {
        ...state,
        shelfCanvas: { ...state.shelfCanvas, ...action.payload },
        miniMap: {
          ...state.miniMap,
          scaleDownFactor: action.payload.scaleDownFactor,
          activeArea: [
            scaleDownValues(
              action.payload.activeArea[0],
              action.payload.scaleDownFactor
            ),
            scaleDownValues(
              action.payload.activeArea[1],
              action.payload.scaleDownFactor
            )
          ]
        }
      };
    case 'MINIMAP_WINDOW_DRAG': {
      return {
        ...state,
        shelfCanvas: {
          ...state.shelfCanvas,
          canvasScroll: false,
          activeArea: [
            scaleUpValues(
              action.payload.activeArea[0],
              action.payload.scaleDownFactor
            ),
            scaleUpValues(
              action.payload.activeArea[1],
              action.payload.scaleDownFactor
            )
          ]
        },
        miniMap: {
          ...state.miniMap,
          miniMapDragging: true,
          activeArea: [...action.payload.activeArea]
        }
      };
    }
    case 'SHELF_CANVAS_SCROLL_EVENT': {
      return {
        ...state,
        shelfCanvas: {
          ...state.shelfCanvas,
          canvasScroll: true,
          activeArea: [
            action.payload.event.scrollLeft,
            action.payload.event.scrollLeft + state.shelfCanvas.width
          ]
        },
        miniMap: {
          ...state.miniMap,
          miniMapDragging: false,
          activeArea: [
            scaleDownValues(
              action.payload.event.scrollLeft,
              state.miniMap.scaleDownFactor
            ),
            scaleDownValues(
              action.payload.event.scrollLeft + state.shelfCanvas.width,
              state.miniMap.scaleDownFactor
            )
          ]
        }
      };
    }
    default:
      return state;
  }
};

const Konva = ({
  list,
  activeBoxes,
  useExclusionsCheckBox,
  checkBoxState,
  useDisplayTag,
  isCropInfoHandlerSelected,
  handleCropClick
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchLogger = params => {
    dispatch(params);
  };

  // function scope
  const miniMapScaleDownFactor = 10;
  let maxFactor = 0;
  list.images.forEach(el => {
    if (el.displays && maxFactor < el.displays.length) {
      maxFactor = el.displays.length * 20 * 2;
    } else {
      maxFactor = 1 * 20 * 2;
    }
  });
  // maxFactor = 8 * 20 * 2;
  const shelfCanvasRef = createRef();
  const shelfCanvasHeight = list.images[0].canvasHeight + maxFactor;
  const shelfCanvasWidth = sumBy(list.images, x => x.canvasWidth);

  const miniMapCanvasRef = createRef();
  const miniMapCanvasWidth = scaleDownValues(
    shelfCanvasWidth,
    miniMapScaleDownFactor
  );
  const miniMapCanvasHeight = scaleDownValues(
    shelfCanvasHeight - maxFactor,
    miniMapScaleDownFactor
  );

  let updateState = useCallback(() => {
    const { current } = shelfCanvasRef;
    const { width } = current.getBoundingClientRect();
    dispatchLogger({
      type: 'REGISTER_SHELF_CANVAS',
      payload: {
        width,
        activeArea: [current.scrollLeft, current.scrollLeft + width],
        scaleDownFactor: miniMapScaleDownFactor
      }
    });
  }, [shelfCanvasRef]);
  updateState = useRef(updateState).current;

  useEffect(() => {
    updateState();
  }, [updateState]);

  return (
    <>
      <ShelfCanvas
        useDisplayTag={useDisplayTag}
        useExclusionsCheckBox={useExclusionsCheckBox}
        checkBoxState={checkBoxState}
        colors={{ shelf: SHELF_COLOR, display: DISPLAY_COLOR }}
        isCropInfoHandlerSelected={isCropInfoHandlerSelected}
        activeBoxes={activeBoxes}
        canvasHeight={shelfCanvasHeight}
        canvasWidth={shelfCanvasWidth}
        dispatch={dispatchLogger}
        list={list}
        reducerState={state}
        wrapperRef={shelfCanvasRef}
        handleCropClick={handleCropClick}
      />
      <div style={{ height: 10 }} />
      <MiniMap
        useDisplayTag={useDisplayTag}
        useExclusionsCheckBox={useExclusionsCheckBox}
        checkBoxState={checkBoxState}
        maxFactor={maxFactor}
        colors={{ shelf: SHELF_COLOR, display: DISPLAY_COLOR }}
        activeBoxes={activeBoxes}
        canvasHeight={miniMapCanvasHeight}
        canvasWidth={miniMapCanvasWidth}
        dispatch={dispatchLogger}
        list={list}
        reducerState={state}
        scaleDownFactor={miniMapScaleDownFactor}
        wrapperRef={miniMapCanvasRef}
      />
      <div style={{ height: 10 }} />
    </>
  );
};

Konva.propTypes = {
  activeBoxes: array.isRequired,
  useDisplayTag: bool.isRequired,
  list: object.isRequired,
  checkBoxState: object,
  isCropInfoHandlerSelected: bool,
  useExclusionsCheckBox: bool,
  handleCropClick: func
};

Konva.defaultProps = {
  useExclusionsCheckBox: false,
  checkBoxState: {},
  isCropInfoHandlerSelected: true,
  handleCropClick: () => {}
};

export default Konva;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, each, map } from 'lodash';
import { connect } from 'react-redux';
// redux-utils
import {
  appActions,
  chartActions,
  storeNewActions
} from 'redux-utils/actions/index';
import {
  appSelector,
  chartSelector,
  storeNewSelector
} from 'redux-utils/selectors/index';
// core functions
import {
  addDays,
  formatDate,
  getPreviousDate,
  dateString
} from 'lib/core/dateUtils';
import { getRouteID } from 'lib/utils/getActiveRoute';
import constants from 'models/constants';
// components
import Loader from 'components/Loader/Loader';
// views
import ChartRenderer from 'pages/Common/ChartRenderer';
import NoDataMessage from 'pages/StoreExplorer/NoDataMessage';
import SENewFilterContainer from 'pages/StoreExplorerNew/SENewFilterContainer';
import PhotosContainer from 'pages/StoreExplorer/Photos/PhotosContainer';

class StoreExplorerContainerNew extends Component {
  routeID = '';

  lastFilter = {};

  count = 0;

  state = {
    tableData: null,
    hasDownloadedFile: true
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    this.routeID = getRouteID(pathname);
    const { dispatchGetFilterList, defaultFilters } = this.props;
    if (defaultFilters[0]) {
      dispatchGetFilterList({
        params: {
          next: defaultFilters[0].value,
          label: defaultFilters[0].label
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      filterData,
      dispatchGetFilterList,
      dispatchGetChartList,
      chartList,
      shelfData,
      dispatchGetImages,
      chartParamsValue,
      shelfData: { imageList, variantList },
      defaultFilters
    } = nextProps;
    this.lastFilter = defaultFilters[defaultFilters.length - 1];
    if (variantList && imageList) {
      this.computeTableData(variantList);
    }
    if (this.props.chartList === undefined && chartList === undefined) {
      if (
        !this.props.chartParamsValue.fl_category &&
        chartParamsValue.fl_category
      ) {
        const params = {
          route_id: this.routeID,
          fl_category: chartParamsValue.fl_category.value
        };
        if (this.count === 0) {
          dispatchGetChartList({ params, cacheRequest: false });
        }
      }
    }
    if (!filterData.next) {
      if (!shelfData.imageList) {
        if (this.count === 0) {
          if (chartParamsValue[this.lastFilter.value]) {
            let params = {};
            map(chartParamsValue, (value, key) => {
              params[key] = value.value;
            });
            params = {
              ...params,
              ...(chartParamsValue.fl_date && {
                fl_date: chartParamsValue.fl_date.fl_start_date
              })
            };

            dispatchGetImages({
              params,
              cacheRequest: false
            });
            if (chartList) {
              this.getChartData(chartList, chartParamsValue);
            }
            this.count = 1;
          }
        }
      }
    }
    if (!filterData.isFetching && filterData.next) {
      if (filterData.next.value || filterData.next.date) {
        if (filterData.next.date === 'fl_date') {
          if (
            chartParamsValue[
              this.getPreviousFilter(this.lastFilter.value, nextProps)
            ]
          ) {
            dispatchGetFilterList({
              params: {
                next: this.lastFilter.value,
                label: this.lastFilter.label,
                ...this.getAllFilterValues(filterData.list, nextProps, '-')
              }
            });
            this.count = 0;
          }
        } else if (
          Object.keys(filterData.list).includes(filterData.next.value)
        ) {
          return 1;
        } else if (
          chartParamsValue[
            this.getPreviousFilter(filterData.next.value, nextProps)
          ]
        ) {
          dispatchGetFilterList({
            params: {
              next: filterData.next.value,
              label: filterData.next.label,
              ...this.getAllFilterValues(filterData.list, nextProps, '-')
            }
          });
        }
      }
    }
    return 1;
  }

  getPreviousFilter = (filter, props = this.props) => {
    const index = props.defaultFilters.findIndex(el => el.value === filter);
    if (index > 0) {
      return props.defaultFilters[index - 1].value;
    }
    return props.defaultFilters[0];
  };

  getAllFilterValues = (filterData, props = this.props, timeFormat) => {
    const values = {};
    map(filterData, (_v, key) => {
      if (key.includes('fl_')) {
        if (key === 'fl_date') {
          const date = props.chartParamsValue.fl_date.fl_start_date;
          values[key] = date.split('/').join(timeFormat);
          return;
        }
        if (props.chartParamsValue[key]) {
          values[key] = props.chartParamsValue[key].value;
        }
      }
    });
    return values;
  };

  computeTableData = data => {
    const columns = [];
    const tableData = {
      datasets: [],
      columns: []
    };
    each(data[0].details, item => {
      map(item, (_v, key) => {
        if (key !== 'color') {
          columns.push(key);
          tableData.columns.push({
            label: key.split('_').join(' '),
            dataKey: key
          });
        }
      });
    });
    each(data, el => {
      const { bounding_boxes: bnBox, details } = el;
      const tempItem = { bnBox };
      map(details, item => {
        const itemKeys = Object.keys(item);
        const arrays = [itemKeys, columns];
        const result = arrays
          .sort((a, b) => {
            return a.length - b.length;
          })
          .shift()
          .filter(v => {
            return arrays.every(a => {
              return a.indexOf(v) !== -1;
            });
          });
        if (itemKeys.includes('color')) {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = (
            <p style={{ color: item.color, margin: 0 }}>{item[result[0]]}</p>
          );
        } else {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = item[result[0]];
        }
      });
      tableData.datasets.push(tempItem);
    });
    // concat other brand to last of array
    const position =
      tableData.datasets.map(e => e.brand).indexOf('Others') ||
      tableData.datasets.map(e => e.brand).indexOf('Другое');
    tableData.datasets = tableData.datasets.concat(
      tableData.datasets.splice(position, 1)
    );
    this.setState({ tableData });
  };

  computeRequestParams = (chart, chartParamsValue) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValue[chart.name]) {
          if (chartParamsValue[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValue[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        if (chartParamsValue.fl_date) {
          requestObject.fl_end_date = chartParamsValue.fl_date.fl_end_date;
          requestObject.fl_start_date = chartParamsValue.fl_date.fl_start_date;
        }
      } else if (paramID === 'fl_type') {
        if (window.location.pathname === '/display') {
          requestObject[paramID] = 'display';
        } else if (window.location.pathname === '/on-shelf-availability') {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else {
        requestObject[paramID] =
          chartParamsValue[paramID] === undefined
            ? ''
            : chartParamsValue[paramID].value;
      }
    });
    requestObject.fl_space_type = 'fl_store';
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  getChartData = (chartList, chartParamsValue) => {
    const { dispatchGetChartData } = this.props;
    each(chartList, chart => {
      const requestObject = this.computeRequestParams(chart, chartParamsValue);
      dispatchGetChartData({
        params: requestObject,
        routeID: this.routeID
      });
    });
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} val - Changed value
   * @param {string} key - value key
   * @return {undefined}
   */
  handleFilterChange = (val, key) => {
    if (!isEmpty(val)) {
      const { routeID } = this;
      const {
        chartList,
        chartParamsValue,
        dispatchFilterChange,
        dispatchGetFilterList,
        defaultFilters,
        dispatchGetImages,
        dispatchGetChartList,
        filterData
      } = this.props;
      const value = key !== undefined ? { [key]: val } : val;
      let nextValue = {};
      const paramsClone = {
        chartParamsValue: { ...chartParamsValue, ...value }
      };
      const deleteValues = [];
      if (key) {
        if (key !== this.lastFilter.value) {
          if (key === 'fl_category') {
            const params = {
              route_id: this.routeID,
              fl_category: paramsClone.chartParamsValue.fl_category.value
            };
            dispatchGetChartList({ params, cacheRequest: false });
          }
          if (key === 'fl_date') {
            nextValue = { ...this.lastFilter };
            this.count = 0;
            deleteValues.push(this.lastFilter.value);
            dispatchGetFilterList({
              params: {
                next: this.lastFilter.value,
                label: this.lastFilter.label,
                ...this.getAllFilterValues(filterData.list, paramsClone, '-')
              }
            });
          } else {
            nextValue = filterData.list[key].next;
            const index = defaultFilters.findIndex(
              el => el.value === nextValue.value || el.value === nextValue.date
            );
            if (nextValue.value === 'fl_date' || nextValue.date === 'fl_date') {
              value.fl_date = {
                fl_end_date: addDays(1, 'days'),
                fl_start_date: formatDate(getPreviousDate(0, 'days', false)),
                is_default: true,
                label: dateString(getPreviousDate(0, 'days', false)),
                value: 'today'
              };
            }
            if (index > -1) {
              for (let i = index; i < defaultFilters.length - 1; i += 1) {
                deleteValues.push(defaultFilters[i].value);
              }
            }
          }
        } else {
          let params = {};
          map(chartParamsValue, (v, k) => {
            params[k] = v.value;
          });
          params = {
            ...params,
            ...(chartParamsValue.fl_date && {
              fl_date: chartParamsValue.fl_date.fl_start_date
            }),
            [key]: value[key].value
          };
          dispatchGetImages({
            params,
            cacheRequest: false
          });
          if (chartList) {
            this.getChartData(chartList, paramsClone.chartParamsValue);
          }
        }
        if (deleteValues.includes('fl_date')) {
          deleteValues.push('fl_store');
        }
      }
      dispatchFilterChange({
        routeID,
        value,
        next: nextValue,
        deleteValues
      });
    }
  };

  render() {
    const { tableData, hasDownloadedFile } = this.state;
    const {
      chartList,
      granularities = {},
      chartData: chartDataList,
      loadingState,
      filterData,
      shelfData: { imageList, excelReport },
      reportData
    } = this.props;

    if (!hasDownloadedFile && !isEmpty(reportData)) {
      const url = reportData.report_file;
      const a = document.createElement('a');
      a.href = url;
      a.click();
      this.setState(() => ({ hasDownloadedFile: true }));
    }

    return (
      <div>
        <SENewFilterContainer
          routeID={this.routeID}
          handleFilterChange={this.handleFilterChange}
        />
        {loadingState ? (
          <>
            <Loader circular centered color="secondary" inline />
          </>
        ) : filterData.list[this.lastFilter.value] ? (
          filterData.list[this.lastFilter.value].options.length ===
          0 ? null : imageList && tableData ? (
            <div
              style={{
                height: '83vh',
                overflow: 'auto',
                overflowX: 'hidden',
                maxWidth: constants.mainWindowWidth
              }}
            >
              <PhotosContainer
                useExport={excelReport}
                photosList={imageList}
                tableData={tableData}
                handleDataDownload={this.handleDataDownload}
              />
              <ChartRenderer
                routeID={this.routeID}
                usePin={false}
                chartList={chartList}
                slicers={{}}
                granularities={granularities}
                chartDataList={chartDataList}
                handleGranularityClick={this.handleGranularityClick}
                handlePinClick={this.handlePinClick}
                handleDataDownload={this.handleDataDownload}
              />
            </div>
          ) : (
            <NoDataMessage />
          )
        ) : null}
      </div>
    );
  }
}

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getParamsValueList = storeNewSelector.makeGetParamsValueList();
  const getChartData = chartSelector.makeGetChartData();
  const getChartList = chartSelector.makeGetChartList();
  const getDefaultFilters = appSelector.makeGetDefaultFilters();

  const mapStateToProps = (state, props) => ({
    chartData: getChartData(state, { routeID }),
    chartList: getChartList(state, { routeID }),
    chartParamsValue: getParamsValueList(state, { routeID }),
    loadingState: storeNewSelector.getImageFetchingStatus(state),
    filterData: storeNewSelector.getFilterData(state),
    device: state.deviceMode,
    shelfData: storeNewSelector.getShelfImages(state),
    reportData: storeNewSelector.getReportData(state),
    defaultFilters: getDefaultFilters(state, props)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetImages: payload =>
    dispatch(storeNewActions.getShelfImageAction(payload)),
  dispatchGetFilterList: payload =>
    dispatch(
      storeNewActions.getStoreExplorerFilterAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchDownloadChartData: payload =>
    dispatch(chartActions.downloadChartDataAction(payload)),
  dispatchGetChartList: payload =>
    dispatch(chartActions.getChartListAction(payload)),
  dispatchGetChartData: payload =>
    dispatch(chartActions.getChartDataAction(payload))
});

StoreExplorerContainerNew.propTypes = {
  dispatchGetFilterList: PropTypes.func.isRequired,
  defaultFilters: PropTypes.array.isRequired,
  filterData: PropTypes.array.isRequired,
  chartParamsValue: PropTypes.object.isRequired
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(StoreExplorerContainerNew);

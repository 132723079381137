import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
// styles
import colors from 'charts/__variables';
import './__styles.scss';

const SimpleRadarChart = ({ color: useColor, data }) => {
  return (
    <ResponsiveContainer className="radar-wrapper">
      <RadarChart data={data.datasets}>
        <PolarAngleAxis dataKey={data.label} />
        <PolarGrid />
        <defs>
          <linearGradient id="bargrad1" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stopColor={colors.dark[useColor].secondary} />
            <stop offset="100%" stopColor={colors.dark[useColor].opaque} />
          </linearGradient>
        </defs>
        <Radar
          name="Mike"
          dataKey={data.dataKey[0]}
          stroke={colors.dark[useColor].primary}
          fill="url(#bargrad1)"
          fillOpacity={0.6}
        />
        <Tooltip cursor={false} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

// component properties
SimpleRadarChart.propTypes = {
  /**
   * @type {string}
   * @description - Color of the bars and background - 'green' | 'blue' | 'pink'|
   * 'gray' | 'orange' | 'yellow'
   */
  color: PropTypes.string,
  /**
   * @type {array}
   * @description - data to build the chart
   */
  data: PropTypes.object.isRequired
};

SimpleRadarChart.defaultProps = {
  color: 'green'
};

export default SimpleRadarChart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// function to check if the sidebar route is currently active
const checkActiveRoute = (path, routeName) => path.indexOf(routeName) > -1;

const StyledListItemText = styled(ListItemText)`
  && {
    color: ${props => props.color};
  }
`;

// stateless component for non-nested menu item
const NavItem = ({
  classes,
  handleItemClick,
  item,
  listItemClasses,
  color
}) => {
  const ele = (
    <ListItem
      onClick={handleItemClick}
      button
      className={classes.itemLink + listItemClasses}
    >
      <StyledListItemText
        primary={item.sidebarName}
        className={classes.itemText}
        disableTypography
        color={color}
      />
    </ListItem>
  )
  return (
    <>
      {
        ((item?.path ?? '').includes('survey') || window.location.href.includes('/survey'))
          ? <a href={item.path} className={`${classes.item} ${window.location.pathname.includes('survey') && (item?.path ?? '').includes('survey') ? ' active ': ''}`}>{ele}</a>
          : (
            <NavLink to={item.path} className={classes.item} activeClassName="active">
              {ele}
            </NavLink>
          )
      }
    </>
  )
};

const StyledList = styled(List)`
  && a {
    background-color: ${props => props.bg};
  }
  && a[class*='active'] > div:before {
    background: ${props => props.gradientColors};
  }
  && a[class*='active'] div > div {
    color: ${props => `${props.activeItemTextColor} !important`};
  }
`;

class SidebarMenu extends Component {
  state = {};

  handleItemExpand = path => {
    this.setState(state => ({ [path]: !state[path] }));
  };

  render() {
    const {
      classes,
      routes,
      location,
      t,
      sidebarColor,
      navBarStyles
    } = this.props;
    return (
      <StyledList
        className={`${classes.list} ${classes.activeItem}`}
        bg={navBarStyles?.backgroundColor}
        gradientColors={navBarStyles?.gradientColors}
        activeItemTextColor={navBarStyles?.activeItemTextColor}
      >
        {routes.map((item, key) => {
          const listItemClasses = classNames({
            [` ${classes.indicator}`]: checkActiveRoute(
              location.pathname,
              item.path
            )
          });
          const ListIcon = item.icon;

          if (item.redirect !== undefined) return null;
          return (
            <NavItem
              key={key}
              t={t}
              item={item}
              classes={classes}
              handleItemClick={this.handleItemClick}
              listItemClasses={listItemClasses}
              listIcon={ListIcon}
              color={navBarStyles?.textColor}
            />
          );
        })}
      </StyledList>
    );
  }
}

// component properties
NavItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  listItemClasses: PropTypes.string.isRequired,
  color: PropTypes.string,
  gradientColors: PropTypes.array
};

NavItem.defaultProps = {
  color: '',
  gradientColors: []
};

SidebarMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  sidebarColor: PropTypes.string.isRequired,
  navBarStyles: PropTypes.object
};

SidebarMenu.defaultProps = {
  navBarStyles: {}
};

export default SidebarMenu;

const enums = {
  apiRequest: {
    IDLE: 'idle',
    REQUEST: 'fetching',
    SUCCESS: 'success',
    FAILURE: 'failed'
  }
};

export default enums;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { array, number, bool, object, func } from 'prop-types';
import useImage from 'use-image';
import { Image, Rect, Line } from 'react-konva';

import ls from 'lib/core/storageFactory';

const DEFAULT_COLOR = '#44ec09';

let email = '';
if (ls.get('authProfile')) {
  email = ls.get('authProfile').email;
}
const scaledImageUsers = ['roma@infilect.com'];

const getRelativePoints = ({ image, boundary, offsetX, type = 'box' }) => {
  if (type === 'box') {
    return {
      left: (image.eye_level.left / image.width) * image.canvasWidth + offsetX,
      right:
        (image.eye_level.right / image.width) * image.canvasWidth + offsetX,
      top: (image.eye_level.top / image.height) * image.canvasHeight,
      bottom: (image.eye_level.bottom / image.height) * image.canvasHeight
    };
  }
  if (boundary) {
    const polygon = boundary.coords.split(',').map((point, i) => {
      if (i === 0 || i % 2 === 0) {
        return (point / image.width) * image.canvasWidth + offsetX;
      }
      return (point / image.height) * image.canvasHeight;
    });
    const start = { x: polygon[0], y: polygon[1] };
    return { polygon, ...start };
  }
  return null;
};

const ImageNode = ({
  nkey,
  image,
  displayEyeLevel,
  showDisplays,
  colors,
  onClick,
  scaleDownFactor,
  type,
  x,
  ...rest
}) => {
  const [imageObject] = useImage(image.path);

  return (
    (image && (
      <>
        <Image {...rest} x={x} image={imageObject} />
        <Rect
          stroke={type === 'shelf' ? colors.shelf : colors.display}
          strokeWidth={scaleDownFactor === 1 ? 3 : 1}
          x={x}
          {...rest}
        />
        {showDisplays &&
          image.displays.map(boundary => {
            const relativePoints = getRelativePoints({
              image,
              boundary,
              offsetX: x,
              type: 'polygon'
            });
            return (
              <Line
                onClick={() => onClick(boundary)}
                closed
                points={[...relativePoints.polygon]}
                strokeWidth={3}
                stroke={DEFAULT_COLOR}
              />
            );
          })}
      </>
    )) ||
    null
  );
};

ImageNode.propTypes = {
  image: object.isRequired,
  showDisplays: bool,
  displayEyeLevel: bool,
  x: number.isRequired
};

ImageNode.defaultProps = {
  displayEyeLevel: false,
  showDisplays: false
};

const StitchedImage = ({
  checkBoxState,
  photoList,
  scaleDownFactor,
  handleBoundaryClick,
  colors,
  nativeProps
}) => {
  let nextXPosition = 0;
  return (
    photoList &&
    photoList.map((image, i) => {
      const item = i > 0 ? photoList[i] : image;
      const photoType = image.type || 'shelf';
      const Node = (
        <>
          <ImageNode
            {...nativeProps}
            key={item.id * i - scaleDownFactor}
            colors={colors}
            url={
              scaledImageUsers.includes(email) ? image.scaled_img : image.path
            }
            onClick={handleBoundaryClick}
            image={item}
            type={photoType}
            x={nextXPosition}
            y={0}
            scaleDownFactor={scaleDownFactor}
            width={item.canvasWidth / scaleDownFactor}
            height={item.canvasHeight / scaleDownFactor}
            showDisplays={checkBoxState.displayPOSM}
          />
        </>
      );
      nextXPosition += item.canvasWidth / scaleDownFactor;
      return Node;
    })
  );
};

StitchedImage.propTypes = {
  photoList: array.isRequired,
  scaleDownFactor: number,
  useDisplayTag: bool,
  nativeProps: object,
  checkBoxState: object,
  handleBoundaryClick: func
};

StitchedImage.defaultProps = {
  scaleDownFactor: 1,
  nativeProps: {},
  useDisplayTag: false,
  checkBoxState: { showDisplays: false },
  handleBoundaryClick: () => {}
};

export default StitchedImage;

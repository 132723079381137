/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames'; // nodejs library that concatenates classes
import PropTypes from 'prop-types'; // nodejs library to set properties for components

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import cardHeaderStyle from 'assets/jss/material-ui/components/cardHeaderStyle';

function CardHeader({ ...props }) {
  const {
    classes,
    className,
    children,
    color,
    plain,
    stats,
    icon,
    chart,
    map,
    ...rest
  } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[`${color}CardHeader`]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderChart]: chart,
    [classes.cardHeaderMap]: map,
    [classes.title]: chart,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * @description - Children
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'dark',
    'primary',
    'rose'
  ]),
  chart: PropTypes.bool,
  plain: PropTypes.bool,
  map: PropTypes.bool,
  stats: PropTypes.bool,
  icon: PropTypes.bool
};

export default withStyles(cardHeaderStyle)(CardHeader);

import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';

import { ArrowLeft, ArrowRight } from 'assets/icons/appIcons';

import ImageRecognition from './ImageRecognition';
import ImageControls from './ImageControls';

const styles = {
  root: {},
  wrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 130px',
    margin: '20px 0'
  },
  sessionList: {
    display: 'flex',
    columnGap: 20,
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const IMAGE_HEIGHT = 500;

const controls = ['zoomIn', 'reset'];

const CanvasWrapper = ({
  activeSession,
  classes,
  compareVersions,
  handleSessionChange,
  IRData,
  sessionList
}) => {
  const [activeControls, setActiveControls] = useState({});
  const [activeSessionIndex, setActiveSessionIndex] = useState(null);

  const handleImageControls = ({ e, isActive }) => {
    setActiveControls(() => ({ [e]: !isActive }));
  };

  useEffect(() => {
    const index = sessionList.findIndex(el => el.value === activeSession.value);
    setActiveSessionIndex(index);
  }, [sessionList, activeSession.value]);

  const fetchSession = direction => {
    let targetSession = null;
    if (direction === 'previous') {
      if (activeSessionIndex > -1) {
        [targetSession] = sessionList.slice(
          activeSessionIndex - 1,
          activeSessionIndex
        );
      }
    } else if (direction === 'next') {
      if (activeSessionIndex > -1) {
        [targetSession] = sessionList.slice(
          activeSessionIndex + 1,
          activeSessionIndex + 2
        );
      }
    }
    if (targetSession) {
      handleSessionChange(targetSession);
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.sessionList}>
          <Button
            disabled={activeSessionIndex < 1}
            color="dark"
            round
            justIcon
            onClick={() => fetchSession('previous')}
          >
            <ArrowLeft />
          </Button>
          <Dropdown
            width={300}
            title=""
            handleValueChange={handleSessionChange}
            options={sessionList}
            value={activeSession}
            useMenuPortalTarget={false}
          />
          <Button
            disabled={activeSessionIndex === sessionList.length - 1}
            color="dark"
            round
            justIcon
            onClick={() => fetchSession('next')}
          >
            <ArrowRight />
          </Button>
        </div>
        <ImageControls
          controls={controls}
          className={classes.imageControls}
          handleControls={handleImageControls}
          activeControls={activeControls}
        />
      </div>
      <div>
        {IRData.length > 0 && (
          <ImageRecognition
            activeControls={activeControls}
            compareVersions={compareVersions}
            IRData={IRData.map(el => ({
              ...el,
              canvasWidth: el.width * (IMAGE_HEIGHT / el.height),
              canvasHeight: IMAGE_HEIGHT
            }))}
          />
        )}
      </div>
    </>
  );
};

CanvasWrapper.propTypes = {
  activeSession: object,
  compareVersions: bool.isRequired,
  classes: object.isRequired,
  handleSessionChange: func.isRequired,
  IRData: array,
  sessionList: array.isRequired
};

CanvasWrapper.defaultProps = {
  activeSession: {},
  IRData: []
};

export default withStyles(styles)(CanvasWrapper);

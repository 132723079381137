const types = {
  filterChange: 'MERCH_EVALUATION_FILTER_CHANGE',
  sessionChange: 'MERCH_EVALUATION_SESSION_CHANGE',
  getPageComponents: {
    request: 'GET_PAGE_COMPONENTS_REQUEST',
    success: 'GET_PAGE_COMPONENTS_SUCCESS',
    failure: 'GET_PAGE_COMPONENTS_FAILURE'
  },
  getCumulativeCards: {
    request: 'GET_CUMULATIVE_CARDS_REQUEST',
    success: 'GET_CUMULATIVE_CARDS_SUCCESS',
    failure: 'GET_CUMULATIVE_CARDS_FAILURE'
  },
  getFilters: {
    request: 'GET_MERCH_EVALUATION_PAGE_FILTERS_REQUEST',
    success: 'GET_MERCH_EVALUATION_PAGE_FILTERS_SUCCESS',
    failure: 'GET_MERCH_EVALUATION_PAGE_FILTERS_FAILURE'
  },
  getMetricTabs: {
    request: 'GET_MERCH_EVALUATION_TABS_DATA_REQUEST',
    success: 'GET_MERCH_EVALUATION_TABS_DATA_SUCCESS',
    failure: 'GET_MERCH_EVALUATION_TABS_DATA_FAILURE'
  },
  getPerformanceTable: {
    request: 'GET_MERCH_PERFORMANCE_TABLE_DATA_REQUEST',
    success: 'GET_MERCH_PERFORMANCE_TABLE_DATA_SUCCESS',
    failure: 'GET_MERCH_PERFORMANCE_TABLE_DATA_FAILURE'
  },
  downloadReport: {
    request: 'DOWNLOAD_MERCH_REPORT_REQUEST',
    success: 'DOWNLOAD_MERCH_REPORT_SUCCESS',
    failure: 'DOWNLOAD_MERCH_REPORT_FAILURE'
  },
  getScheduledReports: {
    request: 'GET_MERCH_SCHEDULED_REPORTS_REQUEST',
    success: 'GET_MERCH_SCHEDULED_REPORTS_SUCCESS',
    failure: 'GET_MERCH_SCHEDULED_REPORTS_FAILURE'
  },
  getScheduledReportsMetaData: {
    request: 'GET_MERCH_SCHEDULED_REPORTS_META_DATA_REQUEST',
    success: 'GET_MERCH_SCHEDULED_REPORTS_META_DATA_SUCCESS',
    failure: 'GET_MERCH_SCHEDULED_REPORTS_META_DATA_FAILURE'
  },
  postScheduledReport: {
    request: 'POST_MERCH_SCHEDULED_REPORT_REQUEST',
    success: 'POST_MERCH_SCHEDULED_REPORT_SUCCESS',
    failure: 'POST_MERCH_SCHEDULED_REPORT_FAILURE'
  },
  updateScheduledReport: {
    request: 'UPDATE_MERCH_SCHEDULED_REPORT_REQUEST',
    success: 'UPDATE_MERCH_SCHEDULED_REPORT_SUCCESS',
    failure: 'UPDATE_MERCH_SCHEDULED_REPORT_FAILURE'
  },
  deleteScheduledReport: {
    request: 'DELETE_MERCH_SCHEDULED_REPORT_REQUEST',
    success: 'DELETE_MERCH_SCHEDULED_REPORT_SUCCESS',
    failure: 'DELETE_MERCH_SCHEDULED_REPORT_FAILURE'
  }
};

export default types;

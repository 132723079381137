import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from 'components/Typography/Typography';
import { ExpandMoreIcon, ExpandLessIcon } from 'assets/icons/appIcons';

const styles = {
  flex: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit'
  },
  data: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    height: 100,
    width: 100,
    position: 'absolute',
    right: '-80px'
  },
  green: {
    fill: '#388E3C'
  },
  red: {
    fill: '#f36'
  }
};

const Stats = ({
  classes,
  data: { header, value, prev_val: preVal, comparison }
}) => (
  <div className={classes.root}>
    <div className={classes.data}>
      <Typography color="light25" weight={400} size="s">
        {header}
      </Typography>
      <div className={classes.flex}>
        <Typography color="#fff" weight={400} size={70}>
          {value}
        </Typography>
        {comparison === 'UP' ? (
          <ExpandLessIcon className={`${classes.icon} ${classes.green}`} />
        ) : comparison === 'DOWN' ? (
          <ExpandMoreIcon className={`${classes.icon} ${classes.red}`} />
        ) : null}
      </div>
      {preVal && (
        <Typography color="#fff" weight={300} size={13}>
          {`Previous value : ${preVal}`}
        </Typography>
      )}
    </div>
  </div>
);

Stats.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(Stats);

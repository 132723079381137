import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from '../material-ui';

const headerStyle = theme => ({
  appBar: {
    // backgroundColor: '#0f0d36',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    // position: 'absolute',
    position: 'fixed',
    width: '100%',

    zIndex: '1',

    border: '0',

    padding: '5px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '62px',
    display: 'block'
    // left: '20rem'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '40px',
      height: '40px',
      marginRight: '10px'
    }
  },
  navTitle: {
    paddingLeft: '10px',
    fontVariant: 'all-petite-caps'
  },
  navTitleHeaderContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  navTitleHeader: {
    fontVariant: 'all-petite-caps',
    fontSize: '30px',
    fontWeight: 'bold',
    color: theme.palette.type === 'light' && 'white'
  },
  menuButtonPage: {
    cursor: 'pointer'
  },
  container: {
    ...container,
    minHeight: '50px'
  },
  flex: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  rightFlexContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  menuButton: {
    margin: theme.spacing(1)
  },
  menuButtonRightPerson: {
    marginLeft: 'auto',
    backgroundColor: '#e6ecff',
    color: '#4294ff',
    '& svg': {
      width: '30px',
      height: '30px'
    },
    '&:hover': {
      backgroundColor: '#e6ecff',
      color: '#4294ff'
    }
    // marginRight: 20
  },
  menuButtonRight: {
    marginLeft: 'auto',
    marginRight: 20
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    margin: '0',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  },
  logoImage: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '38%'
    }
  },
  appResponsive: {
    top: '8px'
  },
  dark: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  }
});

export default headerStyle;

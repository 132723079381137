import { createAction } from '@reduxjs/toolkit';
import { merchTypes as types } from 'redux-utils/types/index';
import reducerModel from 'models/reducerModel';

const getFilters = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getFilters.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getCumulativeCards = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getCumulativeCards.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getPageComponents = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getPageComponents.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const filterChange = (
  payload,
  meta = {
    reducerID: [reducerModel.merchEvaluation.id]
  }
) => {
  const action = createAction(types.filterChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getMetricTabs = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getMetricTabs.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getPerformanceTable = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getPerformanceTable.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadReport = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.downloadReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getScheduledReports = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getScheduledReports.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getScheduledReportsMetaData = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.getScheduledReportsMetaData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const postScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.postScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const updateScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.updateScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const deleteScheduledReport = (
  payload,
  meta = { reducerID: [reducerModel.merchEvaluation.id] }
) => {
  const action = createAction(types.deleteScheduledReport.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  filterChange,
  getFilters,
  getPageComponents,
  getCumulativeCards,
  getPerformanceTable,
  getMetricTabs,
  downloadReport,
  getScheduledReports,
  getScheduledReportsMetaData,
  postScheduledReport,
  updateScheduledReport,
  deleteScheduledReport
};

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { map, omit } from 'lodash';
// action types
import { appTypes, cacheTypes } from 'redux-utils/types';

// initial reducer state
const initialState = {
  isPageLoading: false,
  error: {
    state: false,
    type: ''
  },
  routes: {
    isFetching: false,
    layout: [],
    dashboardConfig: {}
  },
  dashboardType: ''
};

const reducer = createReducer(initialState, {
  [appTypes.getLayout.request]: state => {
    state.isPageLoading = true;
    state.routes.isFetching = true;
  },
  [appTypes.getLayout.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.isPageLoading = false;

    const getLayoutData = data =>
      map(data, e => {
        e = omit(e, ['charts']);
        return e;
      });
    state.routes = {
      isFetching: false,
      layout: getLayoutData(response.data.layout),
      dashboardConfig: response.data.dashboard_config
    };
    state.error = {
      state: false,
      type: ''
    };
  },
  [appTypes.getLayout.failure]: (state, action) => {
    state.isPageLoading = false;
    state.routes = { isFetching: false, layout: [] };
    state.error = {
      state: true,
      type: action.type
    };
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

import React, { useState, useEffect } from 'react';
import { array, object, func, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// redux-utils
import { connect } from 'react-redux';
import { adminActions } from 'redux-utils/actions/index';
import { adminSelector } from 'redux-utils/selectors/index';
// gtag manager utils
import getDataLayer from 'utils/gtagManager';
// views
import Sidebar from './SideBar';
import DashboardUsersContainer from './DashboardUsers/DashboardUsersContainer';
import MerchandizersContainer from './Merchandizers/MerchandizersContainer';
import SKUContainer from './SKU/SKUContainer';
import StoresContainer from './Stores/StoresContainer';
import SupervisorsContainer from './Supervisors/SupervisorsContainer';
import DisplaysContainer from './Display/DisplayContainer';
import PromotionalStickersContainer from './PromotionalStickers/PromotionalStickersContainer';
import StoreSKUMappingContainer from './StoreSKUMapping/StoreSKUMappingContainer';
import SupervisorMerchMappingContainer from './SupervisorMerchMapping/SupervisorMerchMappingContainer';
import StoreMerchMappingContainer from './StoreMerchMapping/StoreMerchMappingContainer';

const styles = theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: theme.palette.type !== 'light' && '170px 1fr',
    backgroundColor: theme.palette.type === 'light' && 'white',
    paddingLeft: theme.palette.type === 'light' && '0.5rem'
  },
  content: {
    paddingLeft: 10,
    height: 'calc(100vh - 100px)',
    overflow: 'auto'
  }
});

const switchRoute = ({ activeSubRoute: active }) => {
  if (active) {
    const pages = {
      'dashboard-users': <DashboardUsersContainer menuProps={active} />,
      skus: <SKUContainer menuProps={active} />,
      merchandizers: <MerchandizersContainer menuProps={active} />,
      stores: <StoresContainer menuProps={active} />,
      supervisors: <SupervisorsContainer menuProps={active} />,
      display: <DisplaysContainer menuProps={active} />,
      'promotional-stickers': (
        <PromotionalStickersContainer menuProps={active} />
      ),
      'store-sku-mapping': <StoreSKUMappingContainer menuProps={active} />,
      'supervisor-merchandizer-map': (
        <SupervisorMerchMappingContainer menuProps={active} />
      ),
      'store-merchandizer-mapping': (
        <StoreMerchMappingContainer menuProps={active} />
      )
    };
    return pages[
      active.name
        .toLowerCase()
        .split(' ')
        .join('-')
    ];
  }
  return null;
};

const AdminContainer = ({
  classes,
  subPanel,
  getSubPanelAction,
  stopTaskPoll,
  routeChange,
  dashboardType
}) => {
  const [activeSubRoute, setActiveSubRoute] = useState();

  useEffect(() => {
    getSubPanelAction({
      params: {},
      cacheRequest: false
    });
  }, [getSubPanelAction]);

  useEffect(() => {
    if (sessionStorage.getItem('activeAdminSidePanel')) {
      const panelLastUsed = JSON.parse(
        sessionStorage.getItem('activeAdminSidePanel')
      );
      const [menuProps] = subPanel.filter(
        panel => panel.name === panelLastUsed.name
      );
      setActiveSubRoute(menuProps);
    } else {
      setActiveSubRoute(subPanel[0]);
    }
  }, [subPanel]);

  const handleItemClick = item => {
    const dataLayer = getDataLayer();
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'mdm_history_change',
        path: item.name
      });
    }
    setActiveSubRoute(item);
    sessionStorage.setItem('activeAdminSidePanel', JSON.stringify(item));
    stopTaskPoll({});
    routeChange();
  };

  return (
    <div className={classes.root}>
      {dashboardType !== 'v2' ? (
        <Sidebar
          subPanel={subPanel}
          activeSubRoute={activeSubRoute}
          handleItemClick={handleItemClick}
        />
      ) : null}
      <main className={classes.content}>{switchRoute({ activeSubRoute })}</main>
    </div>
  );
};

AdminContainer.propTypes = {
  classes: object.isRequired,
  subPanel: array.isRequired,
  getSubPanelAction: func.isRequired,
  stopTaskPoll: func.isRequired,
  routeChange: func.isRequired,
  dashboardType: string.isRequired
};

const mapStateToProps = state => ({
  subPanel: adminSelector.selectSubPanel(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version
});

const mapDispatchToProps = dispatch => ({
  getSubPanelAction: payload =>
    dispatch(adminActions.getSubPanelAction(payload)),
  stopTaskPoll: payload => dispatch(adminActions.stopPollTaskAction(payload)),
  routeChange: payload => dispatch(adminActions.routeChangeAction(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminContainer));

// library to encrypt user id
import SHA512 from 'crypto-js/sha512';
import HMACSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
// library to set cookies universally
import Cookies from 'universal-cookie';

import { takeLatest, call, put } from 'redux-saga/effects';
// action types
import { authTypes } from 'redux-utils/types/index';
// api helpers
import { get, post, setBaseUrl } from 'api';
// core functions
import ls from 'lib/core/storageFactory';
import { deleteDB } from 'db';

const cookies = new Cookies();

const watcherSaga = [
  takeLatest(authTypes.clientValidation.request, clientValidationSaga),
  takeLatest(authTypes.logout.request, userLogoutSaga),
  takeLatest(authTypes.login.request, userLoginSaga),
  takeLatest(authTypes.resetPassword.request, resetPasswordSaga),
  takeLatest(authTypes.forgotPassword.request, forgotPasswordSaga),
  takeLatest(authTypes.confirmForgotPassword.request, confirmForgotPassword)
];

function* clientValidationSaga(action) {
  // set baseurl to public if the user is not logged, it is to avoid making calls to client's url in subsequent calls
  if (!ls.get('client')) setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CLIENT,
    payload: action.payload
  });
  if (response) {
    const { data } = response;
    if (data.client_exist) {
      ls.set('client', action.payload.client.toLowerCase());
      ls.set('clientResponse', data);
      ls.set('domain', action.payload.client.toLowerCase());
      ls.set(
        'baseURL',
        `https://${action.payload.client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
      );
      setBaseUrl(
        `https://${action.payload.client}.${process.env.REACT_APP_SHELF_API_REDIRECT_URI}`
      );
      yield put({
        type: authTypes.clientValidation.success,
        payload: { response: data, request: { ...action.payload } }
      });
    } else {
      ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
      setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
      yield put({
        type: authTypes.clientValidation.failure,
        payload: { message: data.message, client_exist: false },
        error: true
      });
    }
  } else {
    ls.clear();
    ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
    setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
    yield put({
      type: authTypes.clientValidation.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

const TIMEOUT_DELAY = 2000;

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

function* userLoginSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_LOGIN,
    payload: action.payload
  });
  if (response) {
    const { data: profile } = response;
    ls.set('authToken', profile.token);
    ls.set('firstLogin', true);
    ls.set('authProfile', {
      email: profile.email,
      token: profile.token,
      status: profile.status,
      fresh_support: profile.fresh_support,
      username: profile.username
    });

    cookies.set('showResetPassword', true, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });
    cookies.set(
      'trafficType',
      profile.username.includes('@infilect') ? 'internal' : 'external',
      {
        path: '/',
        domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
        sameSite: 'none',
        secure: 'true'
      }
    );
    const hashDigest = SHA512(profile.username);
    const encryptedUserId = Base64.stringify(
      HMACSHA512(hashDigest, process.env.REACT_APP_SHELF_GTAG_ENCKEY)
    );
    cookies.set('userId', encryptedUserId, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });
    yield call(delay, TIMEOUT_DELAY);
    if (profile.analytics_redirect) {
      yield put({
        type: authTypes.analyticsRedirect,
        payload: { response: profile, request: { ...action.payload } }
      });
    } else {
      yield put({
        type: authTypes.login.success,
        payload: { response: profile, request: { ...action.payload } }
      });
    }
  } else {
    yield put({
      type: authTypes.login.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

function* forgotPasswordSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_FORGOT_PASSWORD,
    payload: action.payload
  });
  if (response) {
    const { data } = response;
    yield put({
      type: authTypes.forgotPassword.success,
      payload: { response: data, request: { ...action.payload } }
    });
  } else {
    yield put({
      type: authTypes.forgotPassword.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

function* confirmForgotPassword(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_CONFIRM_FORGOT_PASSWORD,
    payload: action.payload
  });
  if (response) {
    const { data } = response;
    yield put({
      type: authTypes.confirmForgotPassword.success,
      payload: { response: data, request: { ...action.payload } }
    });
  } else {
    yield put({
      type: authTypes.confirmForgotPassword.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

function* resetPasswordSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_RESET_PASSWORD,
    payload: action.payload,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: authTypes.resetPassword.success,
      payload: { response: data, request: { ...action.payload } }
    });
  } else {
    yield put({
      type: authTypes.resetPassword.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

function* userLogoutSaga() {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_LOGOUT,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;

    yield put({
      type: authTypes.logout.success,
      payload: { response: data }
    });
  } else {
    yield put({
      type: authTypes.logout.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

export default watcherSaga;

const types = {
  setSessionFilters: 'SET_SESSION_FILTERS',
  getChartData: {
    request: 'GET_GSE_CHART_DATA_REQUEST',
    success: 'GET_GSE_CHART_DATA_SUCCESS',
    failure: 'GET_GSE_CHART_DATA_FAILURE'
  },
  getChartList: {
    request: 'GET_GSE_CHART_LIST_REQUEST',
    success: 'GET_GSE_CHART_LIST_SUCCESS',
    failure: 'GET_GSE_CHART_LIST_FAILURE',
    clear: 'CLEAR_GSE_CHART_LIST'
  },
  getFilters: {
    request: 'GET_GSE_FILTER_REQUEST',
    success: 'GET_GSE_FILTER_SUCCESS',
    failure: 'GET_GSE_FILTER_FAILURE'
  },
  getBottomUpFilter: {
    request: 'GET_BOTTOM_FILTER_REQUEST',
    success: 'GET_BOTTOM_FILTER_SUCCESS',
    failure: 'GET_BOTTOM_FILTER_FAILURE'
  },
  getFilter: {
    request: 'GET_FILTER_REQUEST',
    success: 'GET_FILTER_SUCCESS',
    failure: 'GET_FILTER_FAILURE'
  },
  getSessionData: {
    request: 'GET_GSE_SESSION_DATA_REQUEST',
    success: 'GET_GSE_SESSION_DATA_SUCCESS',
    failure: 'GET_GSE_SESSION_DATA_FAILURE',
    clear: 'GSE_SESSION_DATA_CLEAR'
  },
  downloadChartData: {
    request: 'DOWNLOAD_GSE_CHART_DATA_REQUEST',
    success: 'DOWNLOAD_GSE_CHART_DATA_SUCCESS',
    failure: 'DOWNLOAD_GSE_CHART_DATA_FAILURE'
  },
  genericSEfilterChange: 'GSE_FILTER_CHANGE_NOTIFICATION',
  sessionFilterValues: {
    request: 'GET_SESSION_FILTER_VALUES_REQUEST',
    success: 'GET_SESSION_FILTER_VALUES_SUCCESS',
    error: 'GET_SESSION_FILTER_VALUES_FAILURE'
  }
};

export default types;

/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// nodejs i18n library
import { withTranslation } from 'react-i18next';
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
// assets
import subHeaderStyle from 'assets/jss/material-ui/components/subHeaderStyle';
// core components
import SubHeaderMenu from './SubHeaderMenu';

class SubHeader extends Component {
  state = {
    sidebarColor: this.props.color
  };

  handleColorSelect = color => {
    this.setState({ sidebarColor: color });
  };

  render() {
    const { props } = this;
    const { classes, navBarStyles } = props;
    const { sidebarColor } = this.state;

    return (
      <div
        className={
          props.routes.length > 7 ? classes.root : classes.centeredRoot
        }
      >
        <SubHeaderMenu
          sidebarColor={sidebarColor}
          {...props}
          navBarStyles={navBarStyles}
        />
      </div>
    );
  }
}

// component properties
SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  navBarStyles: PropTypes.object
};

SubHeader.defaultProps = {
  color: 'blue',
  navBarStyles: {}
};

export default withTranslation('commons')(
  withStyles(subHeaderStyle)(SubHeader)
);

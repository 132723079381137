import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
// assets
import textFieldStyles from 'assets/jss/material-ui/components/textFieldStyles';

/**
 * @class
 * @hideconstructor
 * @description - Core Form TextField component
 */
const Field = ({ classes, fullWidth, colorTheme, ...rest }) => (
  <div>
    <TextField fullWidth={fullWidth} className={classes.root} {...rest} />
  </div>
);

// component properties
Field.propTypes = {
  /**
   * @type {object}
   * @description - Classnames of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {boolean}
   * @description - whether the text field takes full width of the container
   */
  fullWidth: PropTypes.bool.isRequired,
  /**
   * @type {boolean}
   * @description - text field theme
   */
  colorTheme: PropTypes.string.isRequired
};

const InputField = withStyles(textFieldStyles)(props => <Field {...props} />);

InputField.defaultProps = {
  fullWidth: false,
  colorTheme: 'light',
  width: null
};

export default InputField;

import {
  appTypes,
  chartTypes,
  storeExplorerTypes,
  userTypes
} from 'redux-utils/types/index';

const types = {
  store: {
    request: 'STORE_CACHE_IN_DB_REQUEST',
    error: 'STORE_CACHE_IN_DB_ERROR'
  },
  set: {
    request: 'APPEND_CACHED_DATA_TO_STATE'
  },
  cacheable: [
    chartTypes.getChartList.request,
    chartTypes.getChartData.request,
    chartTypes.getStoreCount.request,
    chartTypes.downloadChartData.request,
    appTypes.getLayout.request,
    appTypes.getClientPreferences.request,
    appTypes.AIAccuracySessionFilter.request,
    appTypes.getFilterList.request,
    appTypes.pinToDashboard.request,
    appTypes.getAnalyticsRedirect.request,
    appTypes.unPinFromDashboard.request,
    storeExplorerTypes.getShelfImage.request,
    storeExplorerTypes.getStoreExplorerFilter.request,
    storeExplorerTypes.getCurationStores.request,
    storeExplorerTypes.getCurationSessions.request,
    storeExplorerTypes.getDebugProblems.request,
    storeExplorerTypes.saveDashboardDebug.request,
    storeExplorerTypes.getDashboardDebug.request,
    storeExplorerTypes.updateProductFacing.request,
    storeExplorerTypes.productFilter.request,
    storeExplorerTypes.fixProductFacing.request,
    userTypes.getUserPreference.request,
    userTypes.getUserProfile.request
  ]
};

export default types;

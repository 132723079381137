/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { sumBy, throttle, minBy, property } from 'lodash';
import { Layer, Rect, Stage } from 'react-konva';
// pages
import StitchedImage from './StitchedImage';
import Crops, { getImageObject } from './Crops';

const styles = {
  flex: { display: 'flex' },
  wrapper: {
    overflow: 'auto'
  },
  reference: {
    marginLeft: 50,
    right: 20,
    display: 'flex',
    width: 180,
    justifyContent: 'space-evenly'
  },
  box: {
    height: 20,
    width: 20,
    marginRight: 5,
    alignItems: 'center'
  }
};

const MiniMap = ({
  colors,
  activeBoxes,
  checkBoxState,
  canvasHeight,
  canvasWidth,
  dispatch,
  useDisplayTag,
  list,
  reducerState,
  scaleDownFactor,
  wrapperRef
}) => {
  const throttledEvents = useRef(throttle(q => handleRectDrag(q), 300)).current;
  const [rectCoords, setRectCoords] = useState([0, 0]);

  useEffect(() => {
    if (!reducerState.miniMap.miniMapDragging) {
      const [rectStart, rectEnd] = reducerState.miniMap.activeArea;
      setRectCoords([rectStart, rectEnd]);
    }
  }, [reducerState.miniMap]);

  const handleRectDrag = ({
    position: { x },
    rectEnd: end,
    rectStart: start
  }) => {
    dispatch({
      payload: {
        activeArea: [x, end + x - start],
        scaleDownFactor
      },
      type: 'MINIMAP_WINDOW_DRAG'
    });
  };

  useEffect(() => {
    const firstCrop = minBy(activeBoxes, property('left'));
    if (firstCrop) {
      const image = getImageObject(firstCrop, list.images);
      if (!image) return;
      const imageRange =
        image.index === 0 ? list.images[0] : list.images.slice(0, image.index);
      const offsetX =
        image.index === 0 ? 0 : sumBy(imageRange, x => x.canvasWidth);
      const left = (firstCrop.left / image.width) * image.canvasWidth + offsetX;
      let x =
        (left - 10) / scaleDownFactor > 0 ? (left - 10) / scaleDownFactor : 0;
      const minPos = 0;
      const maxPos = canvasWidth - (rectCoords[1] - rectCoords[0]);
      x = x <= minPos ? 0 : x >= maxPos ? maxPos : x;
      throttledEvents({
        position: { x, y: 0 },
        rectEnd: rectCoords[1],
        rectStart: rectCoords[0]
      });
      setRectCoords(() => [x, rectCoords[1] + x - rectCoords[0]]);
    }
  }, [activeBoxes]);

  const handleImageClick = ({ evt }) => {
    console.log('handleImageClick', evt.layerX);
    // let x1 = 0;
    // let x2 = 0;
    // const center = (rectCoords[1] - rectCoords[0]) / 2;
    // if (evt.layerX < center) {
    //   x1 = rectCoords[0] - center <= 0 ? 0 : rectCoords[0] - center;
    //   x2 = rectCoords[1] - center;
    // } else {
    //   x1 = rectCoords[0] + center;
    //   x2 =
    //     rectCoords[1] + center <= canvasWidth
    //       ? canvasWidth
    //       : rectCoords[1] + center;
    // }

    // dispatch({
    //   payload: {
    //     activeArea: [x1, x2],
    //     scaleDownFactor
    //   },
    //   type: 'MINIMAP_WINDOW_DRAG'
    // });
  };

  return (
    <div ref={wrapperRef} style={{ ...styles.wrapper, ...styles.flex }}>
      <Stage width={canvasWidth} height={canvasHeight}>
        <Layer onClick={handleImageClick}>
          <StitchedImage
            checkBoxState={checkBoxState}
            useDisplayTag={useDisplayTag}
            colors={colors}
            photoList={list.images}
            scaleDownFactor={scaleDownFactor}
          />
          {activeBoxes.length > 0 && (
            <Crops
              activeBoxes={activeBoxes}
              imageList={list.images}
              scaleDownFactor={scaleDownFactor}
            />
          )}
          <Rect
            x={rectCoords[0]}
            y={0}
            width={rectCoords[1] - rectCoords[0]}
            height={canvasHeight}
            stroke="#ffee58"
            draggable
            onDragMove={() => {}}
            onDragEnd={() => {}}
            dragBoundFunc={({ x }) => {
              const minPos = 0;
              const maxPos = canvasWidth - (rectCoords[1] - rectCoords[0]);
              const position = {
                x: x <= minPos ? 0 : x >= maxPos ? maxPos : x,
                y: 0
              };
              throttledEvents({
                position,
                rectEnd: rectCoords[1],
                rectStart: rectCoords[0]
              });
              return position;
            }}
          />
        </Layer>
      </Stage>
      {useDisplayTag && (
        <div style={styles.reference}>
          <div style={{ ...styles.flex, alignItems: 'center' }}>
            <span style={{ ...styles.box, background: colors.shelf }} />
            <p>Shelf</p>
          </div>
          <div style={{ ...styles.flex, alignItems: 'center' }}>
            <span style={{ ...styles.box, background: colors.display }} />
            <p>Display</p>
          </div>
        </div>
      )}
    </div>
  );
};

MiniMap.propTypes = {
  activeBoxes: PropTypes.array.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  useDisplayTag: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  checkBoxState: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  reducerState: PropTypes.object.isRequired,
  scaleDownFactor: PropTypes.number.isRequired,
  wrapperRef: PropTypes.any.isRequired
};

export default MiniMap;

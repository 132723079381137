/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, keys, filter, each, map } from 'lodash';
import { connect } from 'react-redux';
// redux-utils
import {
  appActions,
  chartActions,
  storeExplorerActions
} from 'redux-utils/actions';
import {
  appSelector,
  chartSelector,
  storeExplorerSelector,
  slicerSelector,
  userSelector
} from 'redux-utils/selectors';
// gtag manager utils
import getDataLayer from 'utils/gtagManager';
// core functions
import { getRouteID, getSimilarRoutes } from 'lib/utils/getActiveRoute';
import ls from 'lib/core/storageFactory';
import constants from 'models/constants';
// components
import Loader from 'components/Loader/Loader';
// views
import ChartRenderer from 'pages/Common/ChartRenderer';
import NoDataMessage from 'pages/StoreExplorer/NoDataMessage';
import SEFilterContainer from 'pages/StoreExplorer/SEFilterContainer';
import PhotosContainer from 'pages/StoreExplorer/Photos/PhotosContainer';

const client = ls.get('client');

const hasSessionFilter = filterList =>
  filterList.filter(el => el.value === 'fl_session').length > 0;

class StoreExplorerContainer extends Component {
  routeID = '';

  exportChartName = null;

  state = {
    tableData: null,
    hasDownloadedFile: true
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    const {
      chartParamsValue,
      userPrefs,
      dispatchGetCurationStores
    } = this.props;
    const routeID = getRouteID(pathname);
    this.routeID = routeID;
    if (!isEmpty(userPrefs)) {
      const { fl_date: flDate } = chartParamsValue;
      if (flDate) {
        dispatchGetCurationStores({
          params: {
            date: flDate.fl_start_date.split('/').join('-')
          },
          menuModel: getSimilarRoutes('store-explorer')
        });
      }
    }
  }

  componentWillReceiveProps = nextProps => {
    const {
      shelfData: { imageList, variantList },
      chartList,
      granularities,
      chartParamsValue,
      filters,
      slicers,
      defaultFilters
    } = nextProps;
    const {
      dispatchGetImages,
      dispatchGetCurationStores,
      dispatchGetCurationSessions
    } = this.props;
    if (variantList && imageList) {
      this.computeTableData(variantList);
    }
    if (
      this.props.chartList === undefined &&
      nextProps.chartList !== undefined &&
      !isEmpty(chartParamsValue) &&
      !isEmpty(granularities) &&
      !isEmpty(chartList)
    ) {
      if (hasSessionFilter(defaultFilters)) {
        if (
          chartParamsValue.fl_date &&
          chartParamsValue.fl_store &&
          chartParamsValue.fl_session
        ) {
          this.getChartData(chartList, chartParamsValue, slicers);
        }
      } else {
        if (chartParamsValue.fl_date && chartParamsValue.fl_store) {
          this.getChartData(chartList, chartParamsValue, slicers);
        }
      }
    }
    if (
      this.props.chartList !== undefined &&
      chartList !== undefined &&
      !isEmpty(chartParamsValue) &&
      !isEmpty(granularities) &&
      !isEqual(this.props.chartParamsValue, chartParamsValue)
    ) {
      if (hasSessionFilter(defaultFilters)) {
        if (chartParamsValue.fl_category && chartParamsValue.fl_session) {
          this.getChartData(chartList, chartParamsValue, slicers);
        }
      } else {
        if (chartParamsValue.fl_category) {
          this.getChartData(chartList, chartParamsValue, slicers);
        }
      }
    }
    if (!isEmpty(chartParamsValue) && filters) {
      if (!isEqual(this.props.filters, filters) && !filters.fl_store) {
        const { fl_date: flDate, fl_store: flStore } = chartParamsValue;
        if (flDate && !isEmpty(flStore)) {
          dispatchGetCurationStores({
            params: { date: flDate.fl_start_date.split('/').join('-') },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
    if (
      !isEmpty(chartParamsValue) &&
      !isEqual(this.props.chartParamsValue, chartParamsValue)
    ) {
      const {
        fl_date: flDate,
        fl_category: flCategory,
        fl_placement: flPlacement,
        fl_type: flType,
        fl_store: flStore,
        fl_session: flSession
      } = chartParamsValue;

      if ((flDate && flCategory && flType) || flDate) {
        if (hasSessionFilter(defaultFilters)) {
          if (!isEmpty(flStore)) {
            if (this.props.chartParamsValue.fl_session) {
              if (
                this.props.chartParamsValue.fl_session.value === flSession.value
              ) {
                dispatchGetCurationSessions({
                  params: {
                    date: flDate.fl_start_date.split('/').join('-'),
                    fl_store: flStore.value
                  },
                  menuModel: getSimilarRoutes('store-explorer')
                });
              }
            } else {
              dispatchGetCurationSessions({
                params: {
                  date: flDate.fl_start_date.split('/').join('-'),
                  fl_store: flStore.value
                },
                menuModel: getSimilarRoutes('store-explorer')
              });
            }
            if (!isEmpty(flSession)) {
              dispatchGetImages({
                params: {
                  fl_date: flDate.fl_start_date,
                  fl_placement: flPlacement?.value,
                  fl_category: flCategory?.value,
                  fl_type: flType?.value,
                  fl_store: flStore.value,
                  fl_session: flSession.value
                },
                cacheRequest: false
              });
              return;
            }
          }
        } else {
          if (flStore && flCategory) {
            dispatchGetImages({
              params: {
                fl_date: flDate.fl_start_date,
                fl_placement: flPlacement?.value,
                fl_category: flCategory?.value,
                fl_type: flType?.value,
                fl_store: flStore.value
              },
              cacheRequest: false
            });
          }
        }
      }
      if (flDate && isEmpty(flStore)) {
        if (!isEqual(this.props.chartParamsValue.fl_date, flDate)) {
          dispatchGetCurationStores({
            params: { date: flDate.fl_start_date.split('/').join('-') },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
  };

  fetchChartList = (props = this.props, category = undefined) => {
    const {
      location: { pathname }
    } = window;
    const { dispatchGetChartList, defaultFilters } = props;
    const routeID = getRouteID(pathname);
    const params = { route_id: routeID };
    if (category) {
      const { length } = defaultFilters.filter(
        el => el.value === 'fl_category'
      );
      if (length > 0) {
        params.fl_category = category;
      }
    }
    dispatchGetChartList({
      params,
      cacheRequest: false
    });
  };

  getChartData = (chartList, chartParamsValue, slicers) => {
    const { dispatchGetChartData } = this.props;
    each(chartList, chart => {
      const requestObject = this.computeRequestParams(
        chart,
        chartParamsValue,
        slicers
      );
      // requestObject.fl_end_date = '25/02/2019';
      // requestObject.fl_start_date = '25/01/2019';
      // requestObject.fl_time_type = 'week';
      dispatchGetChartData({
        params: requestObject,
        routeID: this.routeID
      });
    });
  };

  computeRequestParams = (
    chart,
    chartParamsValue,
    slicers = this.props.slicers
  ) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValue[chart.name]) {
          if (chartParamsValue[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValue[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        requestObject.fl_end_date = chartParamsValue.fl_date.fl_end_date;
        requestObject.fl_start_date = chartParamsValue.fl_date.fl_start_date;
      } else if (paramID === 'fl_type') {
        if (window.location.pathname === '/display') {
          requestObject[paramID] = 'display';
        } else if (window.location.pathname === '/on-shelf-availability') {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else if (!isEmpty(slicers) && slicers[chart.name][paramID]) {
        requestObject[paramID] = slicers[chart.name][paramID].value.value;
      } else {
        requestObject[paramID] =
          chartParamsValue[paramID] === undefined
            ? ''
            : chartParamsValue[paramID].value;
      }
    });
    requestObject.fl_space_type = 'fl_store';
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  computeTableData = data => {
    const columns = [];
    const ignoreKeys = [
      'color',
      'min',
      'max',
      'delta',
      'цвет',
      'Мин. цена',
      'Макс. цена',
      'Отличие цены'
    ];
    const tableData = {
      datasets: [],
      columns: []
    };
    each(data[0].details, item => {
      map(item, (_v, key) => {
        if (!ignoreKeys.includes(key)) {
          columns.push(key);
          tableData.columns.push({
            label: key.split('_').join(' '),
            dataKey: key
          });
        }
      });
    });
    each(data, el => {
      const { bounding_boxes: bnBox, details } = el;
      const tempItem = { bnBox };
      map(details, item => {
        const itemKeys = Object.keys(item);
        const arrays = [itemKeys, columns];
        const result = arrays
          .sort((a, b) => {
            return a.length - b.length;
          })
          .shift()
          .filter(v => {
            return arrays.every(a => {
              return a.indexOf(v) !== -1;
            });
          });
        if (itemKeys.includes('color')) {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = (
            <p style={{ color: item.color, margin: 0 }}>{item[result[0]]}</p>
          );
        } else if (
          itemKeys.includes(
            client.includes('kcc-ru') ? 'Соответствие цены' : 'price_compliance'
          )
        ) {
          tempItem[result[0]] = item;
        } else {
          if (result[0] === 'count') {
            if (item.count !== 0) {
              tempItem[result[0]] = item[result[0]];
            }
          } else {
            tempItem[result[0]] = item[result[0]];
          }
          // eslint-disable-next-line prefer-destructuring
        }
      });
      tableData.datasets.push({ ...tempItem, hide: !tempItem.count });
    });
    // concat other brand to last of array
    const position =
      tableData.datasets.map(e => e.brand).indexOf('Others') ||
      tableData.datasets.map(e => e['Категория']).indexOf('Другое');
    tableData.datasets = tableData.datasets.concat(
      tableData.datasets.splice(position, 1)
    );
    this.setState({ tableData });
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} val - Changed value
   * @param {string} key - value key
   * @return {undefined}
   */
  handleFilterChange = (val, key) => {
    if (!isEmpty(val)) {
      const { routeID } = this;
      const {
        chartList,
        chartParamsValue,
        dispatchFilterChange,
        dispatchGetCurationStores,
        dispatchGetCurationSessions,
        defaultFilters
      } = this.props;
      const dataLayer = getDataLayer();
      if (hasSessionFilter(defaultFilters)) {
        const value = key !== undefined ? { [key]: val } : val;
        if (value.fl_category) {
          [value.fl_brand] = value.fl_category.brands;
          this.fetchChartList(this.props, value.fl_category.value);
        }
        if (dataLayer !== null && this.props.chartData) {
          let params = {};
          // eslint-disable-next-line camelcase
          const { fl_start_date, fl_end_date, label } = val;
          each(chartList, chart => {
            const requestObject = this.computeRequestParams(chart, {
              ...chartParamsValue,
              ...value
            });
            params = { ...requestObject };
          });
          // eslint-disable-next-line camelcase
          if (fl_start_date && fl_end_date) {
            params.fl_date_label = label;
          }
          dataLayer.push({
            event: 'filter_change',
            ...params
          });
        }
        dispatchFilterChange({
          routeID,
          value
        });
        if (key === 'fl_date') {
          this.fetchChartList(this.props, chartParamsValue.fl_category.value);
          dispatchGetCurationStores({
            params: { date: val.fl_start_date.split('/').join('-') },
            menuModel: getSimilarRoutes('store-explorer')
          });
        } else if (key === 'fl_category') {
          this.fetchChartList(this.props, value.fl_category.value);
        } else if (chartParamsValue.fl_session || value.fl_session) {
          this.getChartData(chartList, { ...chartParamsValue, ...value });
        } else if (chartParamsValue.fl_store || value.fl_store) {
          dispatchGetCurationSessions({
            params: {
              date: chartParamsValue.fl_date.fl_start_date.split('/').join('-'),
              fl_store: chartParamsValue.fl_store.value
            },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      } else {
        const value = key !== undefined ? { [key]: val } : val;
        if (value.fl_category) {
          [value.fl_brand] = value.fl_category.brands;
          this.fetchChartList(this.props, value.fl_category.value);
        }
        if (dataLayer !== null && this.props.chartData) {
          let params = {};
          // eslint-disable-next-line camelcase
          const { fl_start_date, fl_end_date, label } = val;
          each(chartList, chart => {
            const requestObject = this.computeRequestParams(chart, {
              ...chartParamsValue,
              ...value
            });
            params = { ...requestObject };
          });
          // eslint-disable-next-line camelcase
          if (fl_start_date && fl_end_date) {
            params.fl_date_label = label;
          }
          dataLayer.push({
            event: 'filter_change',
            ...params
          });
        }
        dispatchFilterChange({
          routeID,
          value
        });
        if (key === 'fl_date') {
          if (chartParamsValue.fl_category) {
            this.fetchChartList(this.props, chartParamsValue.fl_category.value);
            dispatchGetCurationStores({
              params: { date: val.fl_start_date.split('/').join('-') },
              menuModel: getSimilarRoutes('store-explorer')
            });
          }
        } else if (key === 'fl_category') {
          this.fetchChartList(this.props, value.fl_category.value);
        } else if (chartParamsValue.fl_store || value.fl_store) {
          this.getChartData(chartList, { ...chartParamsValue, ...value });
        }
      }
    }
  };

  handleGranularityClick = (grObj, grKey, value) => {
    const { routeID } = this;
    const {
      chartList,
      chartParamsValue,
      dispatchGranularityChange
    } = this.props;
    const [chartName] = keys(value);
    const chartObject = filter(chartList, chart => chart.name === chartName);
    dispatchGranularityChange({ routeID, value });
    this.getChartData(chartObject, {
      ...chartParamsValue,
      [chartName]: {
        ...chartParamsValue[chartName],
        [grKey]: { type: grKey, value: grObj.value }
      }
    });
  };

  handlePinClick = ({ isPinned, chartName }) => {
    const { dispatchPinToDashboard, dispatchUnPinFromDashboard } = this.props;
    if (!isPinned) {
      return dispatchPinToDashboard({
        params: { chart_name: chartName },
        routeID: this.routeID
      });
    }
    return dispatchUnPinFromDashboard({
      params: { chart_name: chartName },
      routeID: this.routeID
    });
  };

  handleDataDownload = () => {
    const { dispatchDownloadChartData, chartParamsValue } = this.props;
    const {
      fl_date: flDate,
      fl_category: flCategory,
      fl_placement: flPlacement,
      fl_type: flType,
      fl_store: flStore
    } = chartParamsValue;
    if ((flDate && flCategory && flType) || flDate) {
      if (flStore) {
        const dataLayer = getDataLayer();
        dispatchDownloadChartData({
          params: {
            fl_date: flDate.fl_start_date,
            fl_category: flCategory?.value,
            fl_placement: flPlacement?.value,
            fl_type: flType?.value,
            fl_store: flStore.value,
            report_format: 'excel'
          },
          cacheRequest: false
        });
        if (dataLayer !== null) {
          dataLayer.push({
            event: 'excel_download',
            fl_date: flDate.fl_start_date,
            fl_category: flCategory?.value,
            fl_placement: flPlacement?.value,
            fl_type: flType?.value,
            fl_store: flStore.value,
            report_format: 'excel'
          });
        }
      }
    }
    this.setState(() => ({ hasDownloadedFile: false }));
  };

  handleSlicerChange = ({ selectedValue, slicerType, chartName }) => {
    const {
      chartList,
      chartParamsValue,
      slicers,
      dispatchSlicerChange
    } = this.props;
    dispatchSlicerChange({
      routeID: this.routeID,
      chartName,
      updates: { [slicerType]: selectedValue }
    });
    const chartObject = chartList.filter(chart => chart.name === chartName);
    this.getChartData(chartObject, chartParamsValue, {
      ...slicers,
      [chartName]: {
        ...slicers[chartName],
        [slicerType]: {
          ...slicers[chartName][slicerType],
          value: selectedValue
        }
      }
    });
  };

  render() {
    const { tableData, hasDownloadedFile } = this.state;
    const {
      chartList,
      granularities,
      chartData: chartDataList,
      loadingState,
      filters,
      slicers,
      useDisplayTag,
      shelfData: { imageList, excelReport },
      reportData
    } = this.props;

    if (!hasDownloadedFile && !isEmpty(reportData)) {
      const url = reportData.report_file;
      const a = document.createElement('a');
      a.href = url;
      a.click();
      this.setState(() => ({ hasDownloadedFile: true }));
    }

    return (
      <>
        <SEFilterContainer
          routeID={this.routeID}
          handleFilterChange={this.handleFilterChange}
        />
        {loadingState ? (
          <>
            <Loader plain circular centered color="secondary" inline />
          </>
        ) : filters.fl_store ? (
          filters.fl_store.options.length === 0 ? null : imageList &&
            tableData ? (
            <div
              style={{
                height: '83vh',
                overflow: 'auto',
                overflowX: 'hidden',
                maxWidth: constants.mainWindowWidth
              }}
            >
              <PhotosContainer
                useExport={excelReport}
                useDisplayTag={useDisplayTag}
                photosList={imageList}
                tableData={tableData}
                handleDataDownload={this.handleDataDownload}
              />
              <ChartRenderer
                routeID={this.routeID}
                usePin={false}
                chartList={chartList}
                granularities={granularities}
                chartDataList={chartDataList}
                slicers={slicers}
                handleSlicerChange={this.handleSlicerChange}
                handleGranularityClick={this.handleGranularityClick}
                handlePinClick={this.handlePinClick}
                handleDataDownload={this.handleDataDownload}
              />
            </div>
          ) : (
            <NoDataMessage />
          )
        ) : null}
      </>
    );
  }
}

StoreExplorerContainer.propTypes = {
  dispatchGetImages: PropTypes.func.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getChartData = chartSelector.makeGetChartData();
  const getChartList = chartSelector.makeGetChartList();
  const getGranularitiesList = chartSelector.makeGetGranularitiesList();
  const getFilterList = appSelector.makeGetFilterList();
  const getDefaultFilters = appSelector.makeGetDefaultFilters();
  const getSlicers = slicerSelector.selectSlicers();

  const mapStateToProps = (state, props) => ({
    chartData: getChartData(state, { routeID }),
    chartList: getChartList(state, { routeID }),
    chartParamsValue: getParamsValueList(state, { routeID }),
    granularities: getGranularitiesList(state, { routeID }),
    userPrefs: userSelector.getUserPrefs(state),
    loadingState: storeExplorerSelector.getImageFetchingStatus(state),
    device: state.deviceMode,
    useDisplayTag: appSelector.getClientPreferences(state)
      .store_explorer_display_tag,
    filters: getFilterList(state),
    slicers: getSlicers(state, { routeID }),
    shelfData: storeExplorerSelector.getShelfImages(state),
    reportData: storeExplorerSelector.getReportData(state),
    defaultFilters: getDefaultFilters(state, props)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetImages: payload =>
    dispatch(storeExplorerActions.getShelfImageAction(payload)),
  dispatchGetCurationStores: payload =>
    dispatch(
      storeExplorerActions.getCurationStoresAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchGetCurationSessions: payload =>
    dispatch(
      storeExplorerActions.getCurationSessionsAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchSlicerChange: payload =>
    dispatch(chartActions.slicerChangeAction(payload)),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchDownloadChartData: payload =>
    dispatch(chartActions.downloadChartDataAction(payload)),
  dispatchGetChartList: payload =>
    dispatch(chartActions.getChartListAction(payload)),
  dispatchGetChartData: payload =>
    dispatch(chartActions.getChartDataAction(payload)),
  dispatchGranularityChange: payload =>
    dispatch(chartActions.granularityChangeAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(StoreExplorerContainer);

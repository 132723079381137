/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
import { each, isEmpty, isEqual, keys, filter, map } from 'lodash';
// redux-utils
import { appActions, chartActions } from 'redux-utils/actions';
// gtag manager utils
import getDataLayer from 'utils/gtagManager';
import {
  appSelector,
  userSelector,
  chartSelector,
  slicerSelector
} from 'redux-utils/selectors/index';
// views
import FilterContainer from 'layout/Filter/FilterContainer';
import ChartRenderer from 'pages/Common/ChartRenderer';
import Loader from 'components/Loader/Loader';
// utils
import { getRouteID } from 'lib/utils/getActiveRoute';
import grModel from 'models/granularityModel';
import Notification from 'components/Notification/Notification';

// class
class ChartRendererContainer extends Component {
  routeID = getRouteID(window.location.pathname);

  exportChartName = null;

  constructor(props) {
    super(props);
    const { granularities, chartParamsValue } = this.props;
    const grObj = this.computeGranularities({
      granularities,
      chartParamsValue
    });

    this.state = {
      granularities: grObj,
      previousRoute: this.props.previousRoute,
      activeRoute: this.props.activeRoute,
      hasDownloadedFile: true,
      excelDownloadToast: {
        display: false,
        message: ''
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      chartList,
      granularities,
      chartParamsValue,
      chartListState,
      filters,
      defaultFilters,
      slicers,
      filterPreferences
    } = nextProps;
    if (
      this.props.chartList === undefined &&
      chartList === undefined &&
      !isEmpty(filters) &&
      !chartListState.isFetching
    ) {
      let category;
      if (chartParamsValue.fl_category) {
        const {
          fl_category: { value }
        } = chartParamsValue;
        category = value;
      }
      const { length } = defaultFilters.filter(
        el => el.value === 'fl_category'
      );
      if (length > 0) {
        if (!isEmpty(chartParamsValue)) {
          this.fetchChartList(this.props, category);
        }
      } else {
        this.fetchChartList(this.props, category);
      }
    }

    if (
      (!isEmpty(granularities) && !this.props.granularities) ||
      !isEqual(this.props.filterPreferences, filterPreferences)
    ) {
      const grObj = this.computeGranularities({
        granularities,
        chartParamsValue
      });
      this.setState(prev => ({ ...prev, granularities: grObj }));
    }

    if (
      this.props.chartList === undefined &&
      chartList !== undefined &&
      !isEmpty(chartParamsValue) &&
      !isEmpty(granularities) &&
      !isEmpty(chartList)
    ) {
      this.getChartData(chartList, chartParamsValue, slicers);
    }

    if (
      this.props.chartList !== undefined &&
      chartList !== undefined &&
      !isEmpty(chartParamsValue) &&
      !isEmpty(granularities) &&
      !isEqual(this.props.chartParamsValue, chartParamsValue) &&
      isEqual(this.props.granularities, granularities)
    ) {
      this.getChartData(chartList, chartParamsValue, slicers);
    }
    const { hasDownloadedFile } = this.state;
    const { reports } = this.props;

    if (this.exportChartName && !hasDownloadedFile && reports) {
      if (reports[this.exportChartName]) {
        if (reports[this.exportChartName].on_mail) {
          this.setState({
            excelDownloadToast: {
              display: true,
              message: reports[this.exportChartName].message
            }
          });
        } else {
          const { report_file: url } = reports[this.exportChartName].data;
          const a = document.createElement('a');
          a.href = url;
          a.click();
          this.setState({ hasDownloadedFile: true });
          this.exportChartName = null;
        }
      }
    }
  }

  computeGranularities = ({ granularities, chartParamsValue }) => {
    const gr = { ...granularities };
    map(granularities, (gran, chart) => {
      map(gran, (value, type) => {
        if (value.filter_id) {
          const pickFrom = value.pick_from
            ? value.pick_from
            : value.filter_id.includes('brand')
            ? 'fl_category'
            : value.filter_id.includes('fl_posm')
            ? 'fl_channel'
            : 'fl_brand';
          if (pickFrom === 'fl_channel') {
            const options = chartParamsValue[pickFrom][type];
            const filterValue =
              options.filter(el => el.is_default === true).length === 0
                ? options[0]
                : options.filter(el => el.is_default === true)[0];
            gr[chart] = {
              [type]: {
                ...value,
                options,
                value: filterValue
              }
            };
          } else {
            const options = chartParamsValue[pickFrom][type];
            const filterValue =
              options.filter(el => el.is_default === true).length === 0
                ? options[0]
                : options.filter(el => el.is_default === true)[0];
            gr[chart] = {
              [type]: {
                ...value,
                options,
                value: filterValue
              }
            };
          }
        }
      });
    });
    return gr;
  };

  fetchChartList = (props = this.props, category = undefined) => {
    const { dispatchGetChartList, defaultFilters } = props;
    const params = { route_id: this.routeID };
    if (category) {
      const { length } = defaultFilters.filter(
        el => el.value === 'fl_category'
      );
      if (length > 0) {
        params.fl_category = category;
      }
    }
    dispatchGetChartList({
      params,
      cacheRequest: false
    });
  };

  getChartData = (chartList, chartParamsValue, slicers) => {
    const { dispatchGetChartData } = this.props;
    each(chartList, chart => {
      const requestObject = this.computeRequestParams(
        chart,
        chartParamsValue,
        slicers
      );
      // requestObject.fl_end_date = '24/03/2020';
      // requestObject.fl_start_date = '10/03/2020';
      // requestObject.fl_time_type = 'week';
      dispatchGetChartData({
        params: requestObject,
        routeID: this.routeID
      });
    });
  };

  computeRequestParams = (
    chart,
    chartParamsValue,
    slicers = this.props.slicers
  ) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValue[chart.name]) {
          if (chartParamsValue[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValue[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        requestObject.fl_end_date = chartParamsValue.fl_time.fl_end_date;
        requestObject.fl_start_date = chartParamsValue.fl_time.fl_start_date;
        requestObject.fl_time_type = chartParamsValue.fl_time.fl_time_type;
      } else if (paramID === 'fl_type') {
        if (
          window.location.pathname.includes('/display') ||
          chart.name.includes(getRouteID('/app/display'))
        ) {
          requestObject[paramID] = 'display';
        } else if (
          window.location.pathname.includes('/on-shelf-availability') ||
          chart.name.includes(getRouteID('/app/on-shelf-availability'))
        ) {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else if (!isEmpty(slicers) && slicers[chart.name][paramID]) {
        requestObject[paramID] = slicers[chart.name][paramID].value.value;
      } else {
        requestObject[paramID] =
          chartParamsValue[paramID] === undefined
            ? ''
            : chartParamsValue[paramID].value;
      }
      if (param.depend_on) {
        requestObject[paramID] =
          chartParamsValue[chart.name][paramID]?.value?.value;
      }
    });
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  computeDataLayerParams = (chart, chartParamsValue) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValue[chart.name]) {
          if (chartParamsValue[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValue[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        requestObject.fl_end_date = chartParamsValue.fl_time.fl_end_date;
        requestObject.fl_start_date = chartParamsValue.fl_time.fl_start_date;
        requestObject.fl_time_type = chartParamsValue.fl_time.fl_time_type;
      } else if (paramID === 'fl_type') {
        if (
          window.location.pathname.includes('/display') ||
          chart.name.includes(getRouteID('/app/display'))
        ) {
          requestObject[paramID] = 'display';
        } else if (
          window.location.pathname.includes('/on-shelf-availability') ||
          chart.name.includes(getRouteID('/app/on-shelf-availability'))
        ) {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else if (paramID === 'fl_brand') {
        requestObject[paramID] =
          chartParamsValue[paramID] === undefined
            ? ''
            : chartParamsValue[paramID].label;
      } else {
        requestObject[paramID] =
          chartParamsValue[paramID] === undefined
            ? ''
            : chartParamsValue[paramID].value;
      }
      if (param.depend_on) {
        requestObject[paramID] =
          chartParamsValue[chart.name][paramID].value.value;
      }
    });
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  handleGranularityChange = (
    grObj,
    grKey,
    chartValue,
    updateActiveValue = true,
    useChartParams = null
  ) => {
    const { routeID } = this;
    const {
      chartList,
      chartParamsValue,
      slicers,
      dispatchGranularityChange
    } = this.props;
    const [chartName] = keys(chartValue);
    const chartObject = filter(chartList, chart => chart.name === chartName);

    dispatchGranularityChange({ routeID, value: chartValue });

    const chartParams = useChartParams || chartParamsValue;
    this.getChartData(
      chartObject,
      {
        ...chartParams,
        [chartName]: {
          ...chartParams[chartName],
          [grKey]: { type: grKey, value: grObj.value }
        }
      },
      slicers
    );

    if (!grKey.includes('gr_') && updateActiveValue) {
      const updatedGranularities = { ...this.props.granularities };
      map(this.props.granularities, (gran, chart) => {
        map(gran, (value, type) => {
          if (value.filter_id) {
            const pickFrom = value.pick_from
              ? value.pick_from
              : value.filter_id.includes('brand')
              ? 'fl_category'
              : 'fl_brand';
            const options = chartParamsValue[pickFrom][type];
            updatedGranularities[chart] = {
              [type]: {
                ...value,
                options,
                value: grObj.value
              }
            };
          }
        });
      });
      this.setState(() => ({ granularities: updatedGranularities }));
    }
    return null;
  };

  handleSlicerChange = ({ selectedValue, slicerType, chartName }) => {
    const {
      chartList,
      chartParamsValue,
      slicers,
      dispatchSlicerChange
    } = this.props;
    dispatchSlicerChange({
      routeID: this.routeID,
      chartName,
      updates: { [slicerType]: selectedValue }
    });
    const chartObject = chartList.filter(chart => chart.name === chartName);
    this.getChartData(chartObject, chartParamsValue, {
      ...slicers,
      [chartName]: {
        ...slicers[chartName],
        [slicerType]: {
          ...slicers[chartName][slicerType],
          value: selectedValue
        }
      }
    });
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} val - Changed value
   * @param {string} key - value key
   * @return {undefined}
   */
  handleFilterChange = (val, key) => {
    const { routeID } = this;
    const {
      chartList,
      chartParamsValue,
      dispatchFilterChange,
      granularities
    } = this.props;
    const dataLayer = getDataLayer();
    const value = key !== undefined ? { [key]: val } : val;
    const chartParams = { ...chartParamsValue };
    const { chartName, grType, filterType } = grModel;
    if (value[filterType]) {
      const grValue =
        value[filterType].value === 'display' ? 'brand-form' : 'brand';
      chartName.map(chname => {
        if (chartParams[chname] !== undefined) {
          chartParams[chname][grType] = {
            ...chartParams[chname][grType],
            type: grType,
            value: grValue
          };
        }
        return chname;
      });
    }
    if (dataLayer !== null && this.props.filterPreferences) {
      let params = {};
      // eslint-disable-next-line camelcase
      const { fl_start_date, fl_end_date, label } = val;
      each(chartList, chart => {
        const requestObject = this.computeDataLayerParams(chart, {
          ...chartParams,
          ...value
        });
        params = { ...requestObject };
      });
      // eslint-disable-next-line camelcase
      if (fl_start_date && fl_end_date) {
        params.fl_date_label = label;
      }
      // eslint-disable-next-line camelcase
      if (!fl_start_date && !fl_end_date) {
        params.fl_date_label = this.props.filterPreferences.fl_time.label;
      }
      dataLayer.push({
        event: 'filter_change',
        ...params
      });
    }
    dispatchFilterChange({
      routeID,
      value
    });
    if (value.fl_brand || value.fl_category) {
      if (value.fl_category) {
        [value.fl_brand] = value.fl_category.brands;
        this.fetchChartList(this.props, value.fl_category.value);
      }
      map(granularities, (charts, chartsKey) => {
        map(charts, (gran, granKey) => {
          if (gran.filter_id) {
            const pickFrom = gran.pick_from
              ? gran.pick_from
              : gran.filter_id.includes('brand')
              ? 'fl_category'
              : 'fl_brand';
            const options = this.props.filters[pickFrom].options.filter(
              e => e.value === value[pickFrom].value
            )[0][granKey];
            const filterValue =
              options.filter(el => el.is_default === true).length === 0
                ? options[0]
                : options.filter(el => el.is_default === true)[0];
            this.handleGranularityChange(
              {
                value: filterValue
              },
              granKey,
              { [chartsKey]: { [granKey]: filterValue } },
              false,
              { ...chartParams, ...value }
            );
            const chartObject = filter(
              chartList,
              chart => chart.name !== chartsKey
            );
            this.getChartData(
              chartObject,
              { ...chartParams, ...value },
              this.props.slicers
            );
          }
        });
      });
    } else {
      this.getChartData(
        chartList,
        { ...chartParams, ...value },
        this.props.slicers
      );
    }
  };

  handlePinClick = ({ isPinned, chartName }) => {
    const { dispatchPinToDashboard, dispatchUnPinFromDashboard } = this.props;
    if (!isPinned) {
      return dispatchPinToDashboard({
        params: { chart_name: chartName },
        routeID: this.routeID
      });
    }
    return dispatchUnPinFromDashboard({
      params: { chart_name: chartName },
      routeID: this.routeID
    });
  };

  handleDataDownload = ({ chart }) => {
    const { dispatchDownloadChartData, chartParamsValue } = this.props;
    const requestObject = this.computeRequestParams(chart, chartParamsValue);
    requestObject.report_format = 'excel';
    const dataLayer = getDataLayer();
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'excel_download',
        ...requestObject
      });
    }
    dispatchDownloadChartData({
      params: requestObject,
      cacheRequest: false,
      routeID: this.routeID
    });
    this.exportChartName = chart.name;
    this.setState({ hasDownloadedFile: false });
  };

  handleNotificationClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      hasDownloadedFile: true,
      excelDownloadToast: {
        display: false,
        message: ''
      }
    });
  };

  render() {
    const {
      device,
      chartList,
      filters,
      chartData: chartDataList,
      location,
      slicers,
      dashboardType
    } = this.props;
    const { granularities, excelDownloadToast } = this.state;
    const chartWidth =
      device.device === 'mobile' ? device.size - 70 : device.size - 300;
    return (
      <>
        <Notification
          message={excelDownloadToast.message}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          isOpen={excelDownloadToast.display}
          handleClose={this.handleNotificationClose}
        />
        {dashboardType !== 'v2' ? (
          <FilterContainer
            location={location}
            routeID={this.routeID}
            handleFilterChange={this.handleFilterChange}
          />
        ) : null}

        {!isEmpty(filters) ? (
          <ChartRenderer
            slicers={slicers}
            routeID={this.routeID}
            chartList={chartList}
            granularities={granularities}
            chartWidth={chartWidth}
            chartDataList={chartDataList}
            handleGranularityChange={this.handleGranularityChange}
            handleSlicerChange={this.handleSlicerChange}
            handlePinClick={this.handlePinClick}
            handleDataDownload={this.handleDataDownload}
          />
        ) : (
          <Loader plain circular centered color="secondary" inline />
        )}
      </>
    );
  }
}

// component properties
ChartRendererContainer.propTypes = {
  device: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatchGetChartList: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Redux Filter change action dispatcher
   */
  dispatchFilterChange: PropTypes.func.isRequired
};

ChartRendererContainer.defaultProps = {
  device: { device: 'mobile' }
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getChartData = chartSelector.makeGetChartData();
  const downloadChartData = chartSelector.makeDownloadChartData();
  const getChartList = chartSelector.makeGetChartList();
  const getGranularitiesList = chartSelector.makeGetGranularitiesList();
  const getSlicers = slicerSelector.selectSlicers();
  const getFilterList = appSelector.makeGetFilterList();
  const getDefaultFilters = appSelector.makeGetDefaultFilters();

  const mapStateToProps = (state, props) => ({
    chartData: getChartData(state, { routeID }),
    reports: downloadChartData(state, { routeID }),
    chartList: getChartList(state, { routeID }),
    chartListState: chartSelector.getChartListLoadingState(state),
    chartParamsValue: getParamsValueList(state, { routeID }),
    granularities: getGranularitiesList(state, { routeID }),
    slicers: getSlicers(state, { routeID }),
    filterPreferences: chartSelector.getFilterPreferences(state, { routeID }),
    userPrefs: userSelector.getUserPrefs(state),
    loadingState: appSelector.getLoadingState(state),
    device: state.deviceMode,
    activeRoute: appSelector.getActiveRoute(state),
    previousRoute: appSelector.getPreviousRoute(state),
    filters: getFilterList(state),
    defaultFilters: getDefaultFilters(state, props),
    dashboardType: state.layoutStore.routes?.dashboardConfig?.version
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchGetChartList: payload =>
    dispatch(chartActions.getChartListAction(payload)),
  dispatchGetChartData: payload =>
    dispatch(chartActions.getChartDataAction(payload)),
  dispatchDownloadChartData: payload =>
    dispatch(chartActions.downloadChartDataAction(payload)),
  dispatchGranularityChange: payload =>
    dispatch(chartActions.granularityChangeAction(payload)),
  dispatchSlicerChange: payload =>
    dispatch(chartActions.slicerChangeAction(payload)),
  dispatchPinToDashboard: payload =>
    dispatch(appActions.pinToDashboardAction(payload)),
  dispatchUnPinFromDashboard: payload =>
    dispatch(appActions.unPinFromDashboardAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ChartRendererContainer);

import SecureLS from 'secure-ls';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const secureLs = new SecureLS({
  encodingType: 'aes',
  isCompression: true,
  encryptionSecret: process.env.REACT_APP_SHELF_LS_ENCKEY
});

function storageFactory() {
  let inMemoryStorage = {};

  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      secureLs.set(testKey, testKey);
      secureLs.remove(testKey);
      cookies.set(testKey, testKey);
      cookies.remove(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear() {
    if (isSupported()) {
      secureLs.clear();
      const cookiesList = Object.keys(cookies.getAll());
      for (let i = 0; i < cookiesList.length; i += 1) {
        const el = cookiesList[i];
        remove(el);
      }
    } else {
      inMemoryStorage = {};
    }
  }

  function get(name) {
    if (isSupported()) {
      if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV !== 'local') {
        if (
          name.includes('authToken') ||
          name.includes('firstLogin') ||
          name.includes('domain') ||
          name.includes('client') ||
          name.includes('isLoggedIn') ||
          name.includes('authProfile') ||
          name.includes('enableCache') ||
          name.includes('baseURL') ||
          name.includes('showResetPassword')
        ) {
          if (name.includes('isLoggedIn') || name.includes('enableCache')) {
            return cookies.get(name) === 'true' || null;
          }
          return cookies.get(name) || null;
        }
      }
      return secureLs.get(name) || null;
    }
    if (inMemoryStorage[name] !== undefined || inMemoryStorage[name] !== null) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index) {
    if (isSupported()) {
      return secureLs.getAllKeys()[index] || null;
    }
    return Object.keys(inMemoryStorage)[index] || null;
  }

  function remove(name) {
    if (isSupported()) {
      secureLs.remove(name);
      cookies.remove(name, {
        path: '/',
        domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
        sameSite: 'none',
        secure: 'true'
      });
    } else {
      delete inMemoryStorage[name];
    }
  }

  function set(name, value, avoidCookies) {
    if (isSupported()) {
      secureLs.set(name, value);
      if (!avoidCookies) {
        cookies.set(name, value, {
          path: '/',
          domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
          sameSite: 'none',
          secure: 'true'
        });
      }
    } else {
      inMemoryStorage[name] = String(value);
    }
  }

  function length() {
    if (isSupported()) {
      return secureLs.getAllKeys().length || 0;
    }
    return Object.keys(inMemoryStorage).length;
  }

  return {
    get,
    set,
    remove,
    isSupported,
    clear,
    key,
    get length() {
      return length();
    }
  };
}

const ls = storageFactory();

export default ls;

import { uniqBy } from 'lib/core/array';
import { map, each, intersectionBy } from 'lodash';
import ls from 'lib/core/storageFactory';

const client = ls.get('client');

const optionAll = {
  value: 'all',
  label: client ? (client.includes('kcc-ru') ? 'Все' : 'All') : 'All'
};

const handleSpaceFilterChange = props => {
  const { appSpaceData, value, spaceData } = props;
  const { value: spaceTypeValueType } = value;
  const spaceTypeValueFilter = {
    options: [],
    value: {}
  };

  spaceData[spaceTypeValueType].fl_channel.forEach(el => {
    el[spaceTypeValueType].forEach(st => {
      spaceTypeValueFilter.options.push(st);
    });
  });

  spaceData[spaceTypeValueType].fl_customer.forEach(el => {
    el[spaceTypeValueType].forEach(st => {
      spaceTypeValueFilter.options.push(st);
    });
  });

  spaceTypeValueFilter.options = uniqBy(spaceTypeValueFilter.options, 'value');
  spaceTypeValueFilter.value =
    spaceTypeValueFilter.options.filter(el => el.is_default === true).length ===
    0
      ? spaceTypeValueFilter.options[0]
      : spaceTypeValueFilter.options.filter(el => el.is_default === true)[0];
  const spaceValues = {
    channelFilter: {
      options: [...appSpaceData.channelFilter.options],
      value: appSpaceData.channelFilter.value
    },
    customerFilter: {
      options: [...appSpaceData.customerFilter.options],
      value: appSpaceData.customerFilter.value
    },
    spaceTypeFilter: {
      options: [...appSpaceData.spaceTypeFilter.options],
      value
    },
    spaceTypeValueFilter: {
      options: spaceTypeValueFilter.options,
      value: spaceTypeValueFilter.value
    }
  };
  return handleSpaceTypeFilterChange({
    ...props,
    value: {
      f: spaceTypeValueType,
      value: spaceTypeValueFilter.value.value,
      label: spaceTypeValueFilter.value.label
    },
    appSpaceData: spaceValues
  });
};

const handleSpaceTypeFilterChange = props => {
  const { appSpaceData, value, spaceData, callback } = props;

  const { value: channelValue } = appSpaceData.channelFilter.value;
  const { value: customerValue } = appSpaceData.customerFilter.value;
  const { f: spaceTypeValueType } = value;
  const { spaceTypeValueFilter, spaceTypeFilter } = appSpaceData;
  const filteredData = {
    fl_channel: {
      options: []
    },
    fl_customer: {
      options: []
    },
    fl_space_type: {
      options: spaceTypeFilter.options,
      ...spaceTypeFilter.value
    },
    [spaceTypeValueType]: {
      options: [...spaceTypeValueFilter.options],
      ...value
    }
  };

  /* --------filter the channel based on the [fl_space_type] value-------*/
  filteredData.fl_channel.options = map(
    spaceData.fl_channel.filter(
      ch =>
        ch[spaceTypeValueType].filter(st => st.value === value.value).length !==
        0
    ),
    ({ value: val, label, is_default: isDefault, fl_posm: flPosm }) => ({
      value: val,
      fl_posm: flPosm,
      label,
      is_default: isDefault
    })
  );
  /* --------retain the channel filter state or choose the 1st element from array-------*/
  if (spaceTypeValueType !== 'fl_store') {
    if (filteredData.fl_channel.options.length > 1) {
      filteredData.fl_channel.options.unshift(optionAll);
      if (channelValue !== 'all') {
        const filteredChArray = filteredData.fl_channel.options.filter(
          ch => ch.value === channelValue
        );
        const { length } = filteredChArray;
        if (length > 0) {
          const val =
            filteredChArray.filter(el => el.is_default === true).length === 0
              ? filteredChArray[0]
              : filteredChArray.filter(el => el.is_default === true)[0];
          filteredData.fl_channel = {
            ...filteredData.fl_channel,
            ...val
          };
        } else {
          const val =
            filteredData.fl_channel.options.filter(el => el.is_default === true)
              .length === 0
              ? filteredData.fl_channel.options[0]
              : filteredData.fl_channel.options.filter(
                  el => el.is_default === true
                )[0];
          filteredData.fl_channel = {
            ...filteredData.fl_channel,
            ...val
          };
        }
      } else {
        filteredData.fl_channel = {
          ...filteredData.fl_channel,
          ...appSpaceData.channelFilter.value
        };
      }
    } else {
      const val =
        filteredData.fl_channel.options.filter(el => el.is_default === true)
          .length === 0
          ? filteredData.fl_channel.options[0]
          : filteredData.fl_channel.options.filter(
              el => el.is_default === true
            )[0];
      filteredData.fl_channel = {
        ...filteredData.fl_channel,
        ...val
      };
    }
  } else {
    const val =
      filteredData.fl_channel.options.filter(el => el.is_default === true)
        .length === 0
        ? filteredData.fl_channel.options[0]
        : filteredData.fl_channel.options.filter(
            el => el.is_default === true
          )[0];
    filteredData.fl_channel = {
      ...filteredData.fl_channel,
      ...val
    };
  }
  /* --------filter the customer based on the [fl_space_type] value and channel value-------*/
  const spaceBasedCustomer = [
    ...map(
      spaceData.fl_customer.filter(
        e =>
          e[spaceTypeValueType].filter(item => item.value === value.value)
            .length !== 0
      ),
      ({ value: val, label, is_default: isDefault }) => ({
        value: val,
        label,
        is_default: isDefault
      })
    )
  ];

  let channelBasedCustomer = [];
  if (
    filteredData.fl_channel.value !== 'all' &&
    spaceTypeValueType !== 'fl_store'
  ) {
    channelBasedCustomer = [
      ...map(
        spaceData.fl_customer.filter(
          e =>
            e.fl_channel.filter(
              item => item.value === filteredData.fl_channel.value
            ).length !== 0
        ),
        ({ value: val, label, is_default: isDefault }) => ({
          value: val,
          label,
          is_default: isDefault
        })
      )
    ];
  } else {
    each(spaceData.fl_channel, ch =>
      each(ch.fl_customer, cus => channelBasedCustomer.push(cus))
    );
  }

  channelBasedCustomer = [...uniqBy(channelBasedCustomer, 'value')];
  filteredData.fl_customer.options = intersectionBy(
    channelBasedCustomer,
    spaceBasedCustomer,
    'value'
  );
  /* --------retain the channel filter state or choose the 1st element from array-------*/
  if (spaceTypeValueType !== 'fl_store') {
    if (filteredData.fl_customer.options.length > 1) {
      filteredData.fl_customer.options.unshift(optionAll);
      if (customerValue !== 'all') {
        const filteredCusArray = filteredData.fl_customer.options.filter(
          ch => ch.value === customerValue
        );
        const { length } = filteredCusArray;
        if (length > 0) {
          const val =
            filteredCusArray.filter(el => el.is_default === true).length === 0
              ? filteredCusArray[0]
              : filteredCusArray.filter(el => el.is_default === true)[0];
          filteredData.fl_customer = {
            ...filteredData.fl_customer,
            ...val
          };
        } else {
          const val =
            filteredData.fl_customer.options.filter(
              el => el.is_default === true
            ).length === 0
              ? filteredData.fl_customer.options[0]
              : filteredData.fl_customer.options.filter(
                  el => el.is_default === true
                )[0];
          filteredData.fl_customer = {
            ...filteredData.fl_customer,
            ...val
          };
        }
      } else {
        filteredData.fl_customer = {
          ...filteredData.fl_customer,
          ...appSpaceData.customerFilter.value
        };
      }
    } else {
      const val =
        filteredData.fl_customer.options.filter(el => el.is_default === true)
          .length === 0
          ? filteredData.fl_customer.options[0]
          : filteredData.fl_customer.options.filter(
              el => el.is_default === true
            )[0];
      filteredData.fl_customer = {
        ...filteredData.fl_customer,
        ...val
      };
    }
  } else {
    const val =
      filteredData.fl_customer.options.filter(el => el.is_default === true)
        .length === 0
        ? filteredData.fl_customer.options[0]
        : filteredData.fl_customer.options.filter(
            el => el.is_default === true
          )[0];
    filteredData.fl_customer = {
      ...filteredData.fl_customer,
      ...val
    };
  }
  callback(filteredData);
};

const handleChannelFilterChange = props => {
  const { appSpaceData, value, spaceData, callback } = props;

  const { value: channelValue } = value;
  const { value: customerValue } = appSpaceData.customerFilter.value;
  const { spaceTypeValueFilter, spaceTypeFilter } = appSpaceData;
  const { value: spaceTypeValueType } = spaceTypeFilter.value;
  const { value: spaceTypeValueValue } = spaceTypeValueFilter.value;
  const filteredData = {
    fl_channel: {
      options: [...appSpaceData.channelFilter.options],
      ...value
    },
    fl_customer: {
      options: []
    },
    fl_space_type: {
      options: [...appSpaceData.spaceTypeFilter.options],
      ...appSpaceData.spaceTypeFilter.value
    },
    [spaceTypeValueType]: {
      options: []
    }
  };

  if (channelValue === 'all') {
    spaceData[spaceTypeValueType].fl_channel.forEach(ch => {
      ch[spaceTypeValueType].forEach(st => {
        filteredData[spaceTypeValueType].options.push(st);
      });
    });
    filteredData[spaceTypeValueType].options = [
      ...uniqBy(filteredData[spaceTypeValueType].options, 'value')
    ];
  } else {
    filteredData[spaceTypeValueType].options = spaceData[
      spaceTypeValueType
    ].fl_channel.filter(ch => ch.value === channelValue)[0][spaceTypeValueType];
  }
  const filteredStArray = filteredData[spaceTypeValueType].options.filter(
    st => st.value === spaceTypeValueValue
  );
  filteredData[spaceTypeValueType] =
    filteredStArray.length === 0
      ? {
          ...filteredData[spaceTypeValueType],
          ...filteredData[spaceTypeValueType][0]
        }
      : {
          ...filteredData[spaceTypeValueType],
          ...spaceTypeValueFilter.value
        };

  /* --------filter the customer based on the [fl_space_type] value and channel value-------*/
  const spaceBasedCustomer = [
    ...map(
      spaceData.fl_customer.filter(
        e =>
          e[spaceTypeValueType].filter(
            item => item.value === filteredData[spaceTypeValueType].value
          ).length !== 0
      ),
      ({ value: val, label, is_default: isDefault }) => ({
        value: val,
        label,
        is_default: isDefault
      })
    )
  ];

  let channelBasedCustomer = [];
  if (value.value !== 'all' && spaceTypeValueType !== 'fl_store') {
    channelBasedCustomer = [
      ...map(
        spaceData.fl_customer.filter(
          e =>
            e.fl_channel.filter(
              item => item.value === filteredData.fl_channel.value
            ).length !== 0
        ),
        ({ value: val, label, is_default: isDefault }) => ({
          value: val,
          label,
          is_default: isDefault
        })
      )
    ];
  } else {
    each(spaceData.fl_channel, ch =>
      each(ch.fl_customer, cus => channelBasedCustomer.push(cus))
    );
  }

  channelBasedCustomer = [...uniqBy(channelBasedCustomer, 'value')];
  filteredData.fl_customer.options = intersectionBy(
    channelBasedCustomer,
    spaceBasedCustomer,
    'value'
  );

  if (filteredData.fl_customer.options.length > 1) {
    const filteredCusArray = filteredData.fl_customer.options.filter(
      cus => cus.value === customerValue
    );
    filteredData.fl_customer.options.unshift(optionAll);
    const { length } = filteredCusArray;
    if (length > 0) {
      filteredData.fl_customer = {
        ...filteredData.fl_customer,
        ...appSpaceData.customerFilter.value
      };
    } else {
      const val =
        filteredData.fl_customer.options.filter(el => el.is_default === true)
          .length === 0
          ? filteredData.fl_customer.options[0]
          : filteredData.fl_customer.options.filter(
              el => el.is_default === true
            )[0];
      filteredData.fl_customer = {
        ...filteredData.fl_customer,
        ...val
      };
    }
  } else {
    const val =
      filteredData.fl_customer.options.filter(el => el.is_default === true)
        .length === 0
        ? filteredData.fl_customer.options[0]
        : filteredData.fl_customer.options.filter(
            el => el.is_default === true
          )[0];
    filteredData.fl_customer = {
      ...filteredData.fl_customer,
      ...val
    };
    filteredData.fl_customer.options.unshift(optionAll);
  }
  callback(filteredData);
};

const handleCustomerFilterChange = props => {
  const { appSpaceData, callback, value } = props;

  callback({
    fl_customer: {
      options: [...appSpaceData.customerFilter.options],
      ...value
    }
  });
};

export {
  handleCustomerFilterChange,
  handleChannelFilterChange,
  handleSpaceTypeFilterChange,
  handleSpaceFilterChange
};

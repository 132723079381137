import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';

import Button from 'components/Button/Button';
import CreatableDropdown from 'components/Dropdown/CreatableDropdown';
import Loader from 'components/Loader/Loader';
import InputField from 'components/TextField/TextField';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';

const styles = theme => ({
  checkbox: {
    color: theme.palette.type === 'light' && '#0f0d36 !important'
  },
  button: {
    background: theme.palette.type === 'light' ? '#18c7fe' : 'rgb(39, 42, 61)',
    color: theme.palette.type === 'light' && 'white',
    boxShadow: 'none',
    '&:hover': {
      background:
        theme.palette.type === 'light' ? '#18c7fe' : 'hsla(0, 1%, 28%, 0.2)',
      color: theme.palette.type === 'light' && 'white',
      boxShadow: 'none'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white',
      boxShadow: 'none'
    }
  }
});
const StyledRadio = styled(Radio)`
  && {
    color: ${props => `${props.checkboxColor} !important`};
  }
`;
const DebugComponent = ({
  debugProblems,
  handleReportProblem,
  saveDebug,
  classes,
  dropdownStyles,
  dashboardPanelStyles,
  dashboardType
}) => {
  const [comments, setComment] = useState('');
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [selectedSignal, setSelectedSignal] = useState(1);

  useEffect(() => {
    setSelectedProblems([]);
    setComment('');
    setSelectedSignal(1);
  }, []);

  const saveReport = () => {
    handleReportProblem({
      problem: JSON.stringify(selectedProblems.map(problem => problem.value)),
      comments,
      signal: selectedSignal
    });
  };

  return (
    <div>
      <CreatableDropdown
        width="inherit"
        isMulti
        title="Problem"
        handleValueChange={problem => setSelectedProblems(problem)}
        options={debugProblems}
        value={selectedProblems}
        dropdownStyles={dropdownStyles}
      />
      <VSpacer />
      <InputField
        onChange={e => setComment(e.target.value)}
        id="comment"
        type="text"
        label="Comments"
        margin="normal"
        colorTheme={dashboardType === 'v2' ? 'light' : 'dark'}
        fullWidth
        variant="outlined"
      />
      <VSpacer />
      <span>Debug Bucket</span>
      <VSpacer />
      <div>
        <StyledRadio
          checked={selectedSignal === 1}
          onChange={() => setSelectedSignal(1)}
          value="green"
          name="radio-button-green"
          inputProps={{ 'aria-label': 'Green' }}
          className={classes.checkbox}
          checkboxColor={dashboardPanelStyles?.checkboxColor}
        />
        <span>Green</span>
        <StyledRadio
          checked={selectedSignal === 3}
          onChange={() => setSelectedSignal(3)}
          value="yellow"
          name="radio-button-yellow"
          inputProps={{ 'aria-label': 'Yellow' }}
          className={classes.checkbox}
          checkboxColor={dashboardPanelStyles?.checkboxColor}
        />
        <span>Yellow</span>
        <StyledRadio
          checked={selectedSignal === 2}
          onChange={() => setSelectedSignal(2)}
          value="red"
          name="radio-button-red"
          inputProps={{ 'aria-label': 'Red' }}
          className={classes.checkbox}
          checkboxColor={dashboardPanelStyles?.checkboxColor}
        />
        <span>Red</span>
      </div>
      <VSpacer />

      <Button
        className={classes.button}
        disabled={saveDebug.isLoading}
        onClick={saveReport}
      >
        Report problem
      </Button>

      {saveDebug.isLoading && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader circular plain color="secondary" inline />
          <span>Sending feedback</span>
        </div>
      )}
      {saveDebug.error && (
        <Typography>Internal server error occurred</Typography>
      )}
    </div>
  );
};

DebugComponent.propTypes = {
  debugProblems: PropTypes.array.isRequired,
  saveDebug: PropTypes.object.isRequired,
  handleReportProblem: PropTypes.func.isRequired
};

export default withStyles(styles)(DebugComponent);

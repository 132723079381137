/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Typography from 'components/Typography/Typography';
import Icon from 'components/Icon/Icon';

import ls from 'lib/core/storageFactory';
import { Pin, DownloadIcon, SKUIcon, TesterIcon } from 'assets/icons/appIcons';

import Stats from './Stats';

const StatsComponent = props => {
  const {
    classes,
    data,
    chartName,
    title,
    isPinned,
    usePin,
    useExport,
    handlePinClick,
    handleDataDownload
  } = props;
  const client = ls.get('client');

  return (
    <Card className={classes.card} stats>
      <CardHeader chart float="right">
        <Typography className={classes.title} cardTitle>
          {title}
        </Typography>
        <div className={classes.actionButtons}>
          {useExport ? (
            <Tooltip title="Download data" aria-label="Download data">
              <Icon color="light" id={`${chartName}-excel-download-button`}>
                <DownloadIcon onClick={handleDataDownload} />
              </Icon>
            </Tooltip>
          ) : null}
          {usePin ? (
            <Tooltip
              title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
              aria-label={
                isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
              }
            >
              <Icon color={isPinned ? 'primary' : 'light'}>
                <Pin onClick={() => handlePinClick({ isPinned, chartName })} />
              </Icon>
            </Tooltip>
          ) : null}
        </div>
      </CardHeader>
      <CardBody height="80px" chart>
        {data.value === -1 ? (
          <Typography center color="light25" size="xs">
            {client.includes('kcc-ru')
              ? 'Нет данных для отображения'
              : 'No data to display'}
          </Typography>
        ) : (
          <Stats
            data={data}
            icon={
              chartName.includes('promotional-sku')
                ? SKUIcon
                : chartName.includes('scent-tester')
                ? TesterIcon
                : SKUIcon
            }
          />
        )}
      </CardBody>
    </Card>
  );
};

StatsComponent.propTypes = {
  classes: PropTypes.object,
  chartName: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
  isPinned: PropTypes.bool,
  useExport: PropTypes.bool,
  usePin: PropTypes.bool,
  handlePinClick: PropTypes.func,
  handleDataDownload: PropTypes.func
};

StatsComponent.defaultProps = {
  isPinned: false,
  useExport: false,
  usePin: false
};

export default StatsComponent;

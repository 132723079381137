import React from 'react';
import PropTypes from 'prop-types';
import Konva from './Konva';

const IMAGE_HEIGHT = 500;

const Photos = ({ list, ...rest }) => {
  return (
    <>
      <Konva
        {...rest}
        list={{
          images: list.map(el => ({
            ...el,
            canvasWidth: el.width * (IMAGE_HEIGHT / el.height),
            canvasHeight: IMAGE_HEIGHT
          }))
        }}
      />
    </>
  );
};

// component proptypes
Photos.propTypes = {
  list: PropTypes.array.isRequired,
  activeBoxes: PropTypes.array.isRequired,
  useDisplayTag: PropTypes.bool.isRequired
};

export default Photos;

const calendarTheme = {
  accentColor: 'rgb(61, 63, 88)',
  floatingNav: {
    background: 'rgb(85, 85, 107)',
    chevron: 'transparent',
    color: '#fefefe'
  },
  headerColor: '#27293e',
  selectionColor: 'rgb(85, 85, 107)',
  textColor: {
    active: '#ffffff',
    default: '#ffffff'
  },
  todayColor: '#FFA726',
  weekdayColor: '#27293e'
};

const options = {
  displayOptions: {
    showHeader: false
  },
  locale: {
    headerFormat: 'MMM Do',
    weekdays: ['Su', 'M', 'T', 'W', 'T', 'F', 'Sa']
  }
};

const formatDate = date => {
  return date
    .split('/')
    .reverse()
    .join('-');
};

export { calendarTheme, options, formatDate };

/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// nodejs i18n library
import { withTranslation } from 'react-i18next';
// @material-ui/core components
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
// assets
import metadata from 'metadata';
import sidebarStyle from 'assets/jss/material-ui/components/sidebarStyle';
// core components
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
import SidebarMenu from './SidebarMenu';

const getPathName = props => {
  let name;
  props.routes.map(prop => {
    if (prop.path === props.location.pathname) {
      name = prop.navbarName;
    }
    return null;
  });
  return name;
};

const Brand = ({ classes, logo, logoText }) => (
  <div className={classes.logo}>
    <a href="/app" className={classes.logoLink}>
      <div className={classes.logoImage}>
        <img src={logo} alt="logo" />
      </div>
      <div className={classes.logoText}>{logoText}</div>
    </a>
  </div>
);

class SideBar extends Component {
  state = {
    sidebarColor: this.props.color
  };

  handleColorSelect = color => {
    this.setState({ sidebarColor: color });
  };

  render() {
    const { props } = this;
    const {
      classes,
      handleDrawerToggle,
      drawerState,
      handleDrawerExpand,
      icon: ViewIcon,
      clientPreferences
    } = props;
    const { sidebarColor } = this.state;
    const { isDrawerExpanded, isDrawerOpen } = drawerState;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <ViewIcon
            className={classes.menuButton}
            onClick={handleDrawerExpand}
          />
          <Typography
            className={classes.navTitle}
            size="md"
            color="rgba(255, 255, 255, 0.8)"
          >
            {getPathName(this.props) || ''}
          </Typography>
        </div>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            className={classNames(classes.root, {
              [classes.drawerOpen]: isDrawerExpanded,
              [classes.drawerClose]: !isDrawerExpanded
            })}
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerOpen]: isDrawerExpanded,
                [classes.drawerClose]: !isDrawerExpanded
              })
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            open={isDrawerOpen}
          >
            <Brand {...props} />
            <SidebarMenu
              sidebarColor={sidebarColor}
              drawerRight={false}
              {...props}
            />
            <VSpacer amount={10} />
            {clientPreferences.client_logo ? (
              <>
                {clientPreferences.client_logo.show ? (
                  <div className={classes.clientLogo}>
                    <img
                      alt="client logo"
                      src={
                        clientPreferences.client_logo
                          ? clientPreferences.client_logo.logo
                          : ''
                      }
                    />
                    <Typography
                      className={classes.versionLabel}
                      align="center"
                      weight="400"
                      size={12}
                    >{`v${metadata.version}`}</Typography>
                  </div>
                ) : (
                  <Typography
                    className={classes.versionLabel}
                    align="center"
                    weight="400"
                    size={12}
                  >{`v${metadata.version}`}</Typography>
                )}
              </>
            ) : (
              <Typography
                className={classes.versionLabel}
                align="center"
                weight="400"
                size={12}
              >{`v${metadata.version}`}</Typography>
            )}
          </Drawer>
        </Hidden>
        <Hidden mdUp implementation="css">
          <Drawer
            anchor="right"
            variant="temporary"
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onBackdropClick: handleDrawerToggle
            }}
            open={isDrawerOpen}
          >
            <Brand {...props} />
            <SidebarMenu sidebarColor={sidebarColor} drawerRight {...props} />
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

// component properties
SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerState: PropTypes.object,
  color: PropTypes.string,
  /**
   * @type {function}
   * @description Drawer open icon
   */
  icon: PropTypes.object.isRequired,
  /**
   * @type {function}
   * @description Handler function to handle the drawer open on sm devices
   */
  handleDrawerExpand: PropTypes.func.isRequired,
  clientPreferences: PropTypes.object.isRequired
};

SideBar.defaultProps = {
  drawerState: {
    isDrawerExpanded: true,
    isDrawerOpen: false
  },
  color: 'blue'
};

Brand.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired,
  logoText: PropTypes.string.isRequired
};

export default withTranslation('commons')(withStyles(sidebarStyle)(SideBar));

import { takeLatest, call, put } from 'redux-saga/effects';
// redux-utils
import { appTypes, cacheTypes } from 'redux-utils/types/index';
// api helpers
import { get, post } from 'api/index';
import apiModel from 'models/apiModel';
import endpoints from 'api/endpoints';
import { toggleChartPinStatus } from 'db/helpers';

/* Actions */
import { initializeAppImages } from 'redux-utils/actions/appImagesActions';

/**
 * @name appWatcherSaga
 * @description Catches the action dispatched of certain type.
 */
const watcherSaga = [
  takeLatest(appTypes.getLayout.fromAPI, layoutWorker),
  takeLatest(appTypes.getFilterList.fromAPI, filterListWorker),
  takeLatest(
    appTypes.AIAccuracySessionFilter.fromAPI,
    AISessionFilterListWorker
  ),
  takeLatest(appTypes.getAnalyticsRedirect.fromAPI, getAnalyticsRedirectWorker),
  takeLatest(appTypes.getClientPreferences.fromAPI, clientPreferencesWorker),
  takeLatest(appTypes.pinToDashboard.fromAPI, pinToDashboardWorker),
  takeLatest(appTypes.unPinFromDashboard.fromAPI, unPinToDashboardWorker),
  takeLatest(appTypes.routeChange, routeChangeWorker),

  takeLatest(appTypes.surveyDetails.request, SurveyDetailsWorker)
];

/**
 * @description Worker saga for when route changes
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* routeChangeWorker() {
  yield put(initializeAppImages());
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* unPinToDashboardWorker(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_UNPIN_DASHBOARD,
    headers: ['auth'],
    payload: action.payload.params
  });
  if (response) {
    const { data } = response;
    const dbKey = {
      apiID: apiModel.getChartList,
      params: JSON.stringify({
        route_id: action.payload.routeID
      })
    };
    toggleChartPinStatus(dbKey, action.payload.params.chart_name, false);
    yield put({
      type: appTypes.unPinFromDashboard.success,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.unPinFromDashboard.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* pinToDashboardWorker(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_PIN_DASHBOARD,
    headers: ['auth'],
    payload: action.payload.params
  });
  if (response) {
    const { data } = response;
    const dbKey = {
      apiID: apiModel.getChartList,
      params: JSON.stringify({
        route_id: action.payload.routeID
      })
    };
    toggleChartPinStatus(dbKey, action.payload.params.chart_name, true);
    yield put({
      type: appTypes.pinToDashboard.success,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.pinToDashboard.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* getAnalyticsRedirectWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: endpoints.analyticsRedirect,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.getClientPreferences.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* clientPreferencesWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CLIENT_PREFERENCES,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data.data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.getClientPreferences.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the layout structure
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* layoutWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_LAYOUT,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.getLayout.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* filterListWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_FILTER_LIST,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.getFilterList.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* AISessionFilterListWorker(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_AI_SESSION_FILTER,
    headers: ['auth'],
    useCachedInstance: false,
    payload: action.payload.params
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: { response: data, ...action.payload },
      meta: { ...action.meta }
    });
  } else {
    yield put({
      type: appTypes.AIAccuracySessionFilter.failure,
      payload: { message: error.data },
      error: true,
      meta: { ...action.meta }
    });
  }
}

/**
 * @description Worker saga to get the survey details of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* SurveyDetailsWorker(action) {
  // console.log(action,'action');
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_GET_SURVEY_DETAILS,
    headers: ['auth'],
    useCachedInstance: false,
    payload: action.payload.params
  });
  if (response) {
    const { data } = response;
    // console.log('SurveyDetailsWorker', data, 'success');
    yield put({
      type: appTypes.surveyDetails.success,
      payload: { response: data, ...action.payload },
      // meta: { ...action.meta }
    });
  } else {
    // console.log('SurveyDetailsWorker', error, 'failed');
    yield put({
      type: appTypes.surveyDetails.failure,
      payload: { message: error.data },
      error: true,
      // meta: { ...action.meta }
    });
  }
}

export default watcherSaga;

import React from 'react';
import { PropTypes } from 'prop-types';
import { each, map } from 'lodash';

const styles = {
  wrapper: {
    zIndex: 10,
    background: '#fff',
    borderRadius: '6px',
    padding: '10px'
  },
  title: {
    color: '#000',
    fontWeight: '600'
  },
  label: {
    fill: 'rgb(113, 113, 121)',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px'
  }
};

const ChartTooltip = props => {
  const { active, payload, label } = props;
  if (payload) {
    if (active && payload.length !== 0) {
      const obj = {};
      each(payload, data => {
        obj[data.dataKey] = data.value;
        return data;
      });
      return (
        <div style={styles.wrapper}>
          <div style={styles.title}>{label}</div>
          {map(obj, (val, key) => (
            <div key={val} style={styles.tooltip}>
              {`${key} - ${val}`}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }
  return null;
};

// component properties
ChartTooltip.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ChartTooltip.defaultProps = {
  active: false,
  payload: [],
  label: ''
};

export default ChartTooltip;

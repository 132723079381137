import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'metrics';

// redux utils
import { Provider } from 'react-redux';
import store from 'redux-utils/store';
// i18n utils
import 'lib/i18n';
// styles
import 'index.scss';
import 'react-infinite-calendar/styles.css';
// db
import ls from 'lib/core/storageFactory';
import 'db';
// main
import App from 'App';
import smoothscroll from 'smoothscroll-polyfill';
import { setBaseUrl } from 'api/index';
// polyfill for smoothscroll
smoothscroll.polyfill();

window.ls = ls;

if (!ls.get('isLoggedIn')) {
  if (!window.location.pathname.includes('forgotPassword')) {
    ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
    setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
  }
}

const rootElement = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(rootElement, document.getElementById('root'));

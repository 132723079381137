import { createSelector } from '@reduxjs/toolkit';

const getAppImagesStore = state => state.appImages;

/* Selector Creators */
const getLoadingState = createSelector(
  [getAppImagesStore],
  state => state.isFetching
);

const getMessage = createSelector([getAppImagesStore], state => state.message);

const getStores = createSelector(
  [getAppImagesStore],
  state => state.preferences.stores
);

const getSessions = createSelector(
  [getAppImagesStore],
  state => state.preferences.sessions
);

const getPhotos = createSelector(
  [getAppImagesStore],
  state => state.preferences.photos
);

const getPhotoAttributes = createSelector(
  [getAppImagesStore],
  state => state.preferences.photoAttributes
);

const getBulkStores = createSelector(
  [getAppImagesStore],
  state => state.preferences.bulkStores
);

const getMessages = createSelector(
  [getAppImagesStore],
  state => state.messages
);

const getSuccess = createSelector([getAppImagesStore], state => state.success);
const getErrors = createSelector([getAppImagesStore], state => state.errors);

const getLoading = createSelector(
  [getAppImagesStore],
  state => state.isFetching
);

const appImageDownloadOptions = createSelector(
  [getAppImagesStore],
  state => state.appImageDownloadOptions
);
const appImageDownloadOptionsState = createSelector(
  [getAppImagesStore],
  state => state.appImageDownloadOptionsState
);
const getAppImagesDownloadOptionState = createSelector(
  [getAppImagesStore],
  state => state.appImagesDownloadOptionState
);
const getAppImagesFilters = createSelector(
  [getAppImagesStore],
  state => state.appImageFilters
);
export {
  getLoadingState,
  getMessage,
  getStores,
  getSessions,
  getPhotos,
  getPhotoAttributes,
  getBulkStores,
  getMessages,
  getSuccess,
  getErrors,
  getLoading,
  appImageDownloadOptions,
  appImageDownloadOptionsState,
  getAppImagesDownloadOptionState,
  getAppImagesFilters
};

import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { map, pick } from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
// @material-ui/core
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
// icons
import { DeleteAltIcon } from 'assets/icons/appIcons';

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1e1e2b',
        maxWidth: 'calc(100vw - 400px)!important',
        overflow: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgb(65, 65, 66) transparent',
        '&::-webkit-scrollbar': {
          display: 'block',
          // backgroundColor: 'white',
          height: '10px',
          width: '10px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(65, 65, 66)',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          borderRadius: '10px'
        }
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiDropzoneArea: {
      root: {
        outline: 'none',
        backgroundColor: '#1e1e2b',
        minHeight: 130,
        border: '1px dashed',
        borderColor: 'rgb(189 174 174 / 34%)'
      },
      text: {
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 8,
        paddingTop: 0,
        '&>button': {
          textTransform: 'capitalize'
        },
        '&>button:nth-of-type(1)': {
          color: '#f36'
        },
        '&>button:nth-of-type(2)': {
          color: '#afa'
        },
        '&>button:nth-of-type(2):disabled': {
          color: 'gray'
        }
      }
    },
    MuiDropzonePreviewList: {
      root: {
        marginTop: 10
      }
    },
    MuiChip: {
      root: {
        backgroundColor: '#52596b !important',
        color: 'rgba(255,255,255,0.8)'
      }
    }
  }
});

const styles = {
  font: {
    color: 'rgba(255, 255, 255, 0.8) !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
  },
  closeButton: { right: '12px', top: '8px', position: 'absolute' },
  submitButton: {
    color: '#afa'
  },
  disabledButton: {
    color: 'rgb(189 174 174 / 34%)'
  },
  checkboxGroup: {
    color: 'rgba(255, 255, 255, 0.6) !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
  },
  dialogContent: {
    overflow: 'visible',
    minWidth: '550px'
  },
  referencesContainer: {
    display: 'flex',
    width: 'inherit',
    overflow: 'auto',
    margin: '10px 0',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      // backgroundColor: 'white',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  imageWrapper: {
    position: 'relative',
    display: 'inline-block',
    fontSize: 0,
    '&:hover': {
      '&$close': {
        opacity: 1
      }
    }
  },
  deleteButton: {
    position: 'absolute',
    color: '#f36',
    cursor: 'pointer'
  },
  image: {
    maxHeight: 150,
    maxWidth: 200,
    padding: 3
  }
};

const SecondaryCheckbox = withStyles({
  root: {
    color: 'rgba(255,255, 255, 0.6)',
    '&$checked': {
      color: '#f36'
    }
  },
  checked: {}
})(props => <Checkbox color="secondary" {...props} />);

const CheckboxLabel = withStyles({
  label: {
    color: 'rgba(255, 255, 255, 0.8) !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
  }
})(props => <FormControlLabel {...props} />);

const ReferenceImageUpload = ({
  classes,
  setOpen,
  stickerRow,
  stickerValues,
  handleSubmit,
  isOpen
}) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [checkBoxState, setCheckBoxState] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    channel: {},
    'store-brand': {}
  });
  const [options, setOptions] = useState({});
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [
    isStoreBrandCheckboxesSelected,
    setIsStoreBrandCheckboxesSelected
  ] = useState(false);
  const [stickerData, setStickerData] = useState({});
  const [deletedImages, setDeletedImages] = useState([]);
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleOKButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    const checkbox = {};
    map(checkBoxState, (value, group) => {
      checkbox[group] = JSON.stringify(
        value.filter(el => el.mapped).map(el => el.key)
      );
    });
    handleSubmit({
      fileObjects: fileObjects || [],
      checkBoxState: checkbox,
      deletedImages,
      stickerRow
    });
    closeDialog();
  };

  const handleCancelButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
  };

  const resetToInitialState = () => {
    setStickerData({});
    setCheckBoxState({});
    setFileObjects([]);
    setDeletedImages([]);
  };

  useEffect(() => {
    if (selectedCheckboxes.channel) {
      const channelKeys = Object.keys(selectedCheckboxes.channel);
      for (let i = 0; i < channelKeys.length; i += 1) {
        if (
          options.channel[channelKeys[i]] !==
          selectedCheckboxes.channel[channelKeys[i]]
        ) {
          setIsCheckboxSelected(true);
          break;
        }
        if (i === channelKeys.length - 1) {
          setIsCheckboxSelected(false);
        }
      }
    }
  }, [selectedCheckboxes.channel]);

  useEffect(() => {
    if (selectedCheckboxes['store-brand']) {
      const storeBrandKeys = Object.keys(selectedCheckboxes['store-brand']);
      for (let i = 0; i < storeBrandKeys.length; i += 1) {
        if (
          options['store-brand'][storeBrandKeys[i]] !==
          selectedCheckboxes['store-brand'][storeBrandKeys[i]]
        ) {
          setIsStoreBrandCheckboxesSelected(true);
          break;
        }
        if (i === storeBrandKeys.length - 1) {
          setIsStoreBrandCheckboxesSelected(false);
        }
      }
    }
  }, [selectedCheckboxes['store-brand']]);

  useEffect(() => {
    const checkboxes = {};
    const result = { channel: {}, 'store-brand': {} };
    stickerValues.forEach(value => {
      checkboxes[value.key] = value.options.map(option =>
        pick(option, ['key', 'mapped'])
      );
    });
    if (stickerValues[0] && stickerValues[0].options.length !== 0) {
      for (let i = 0; i < stickerValues[0].options.length; i += 1) {
        result.channel[stickerValues[0].options[i].label] =
          stickerValues[0].options[i].mapped;
      }
      for (let i = 0; i < stickerValues[1].options.length; i += 1) {
        result['store-brand'][stickerValues[1].options[i].label] =
          stickerValues[1].options[i].mapped;
      }
      setOptions(result);
    }
    setCheckBoxState(checkboxes);
  }, [stickerValues]);

  useEffect(() => {
    setStickerData(stickerRow);
    return () => {
      resetToInitialState();
    };
  }, [stickerRow]);

  const closeDialog = () => {
    setSelectedCheckboxes({});
    setIsCheckboxSelected(false);
    resetToInitialState();
    setOpen(false);
    if (snackBarState.isOpen) {
      handleSnackBarClose();
    }
  };

  const handleSave = () => {
    setSnackBarState({
      isOpen: true,
      message: `This will modify the existing data. Are you sure?`,
      severity: 'info'
    });
  };

  const handleCheckboxChange = ({ evt, option, type }) => {
    evt.persist();
    setSelectedCheckboxes(prev => ({
      ...prev,
      [type.key]: { ...prev[type.key], [option.label]: evt.target.checked }
    }));
    setCheckBoxState(prev => ({
      ...prev,
      [type.key]: []
        .concat(prev[type.key])
        .map(el =>
          el.key === option.key
            ? { ...pick(option, ['key', 'mapped']), mapped: evt.target.checked }
            : el
        )
    }));
  };

  const handleDeleteImage = image => {
    setStickerData(prev => {
      if (prev.references) {
        return {
          ...prev,
          references: prev.references.filter(img => img.id !== image.id)
        };
      }
      return prev;
    });
    setDeletedImages(prev => [...prev, image]);
  };

  const dialogTitle = (
    <>
      <span className={classes.font}>Upload reference images zip file</span>
      <div>
        {stickerValues.map(value => {
          return (
            <div key={value.key} className={classes.checkboxContainer}>
              <span className={classes.font}>{value.title}</span>
              <div className={classes.checkboxGroup}>
                {value.type === 'checkbox' && (
                  <>
                    {value.options.map(option => (
                      <CheckboxLabel
                        key={option.key}
                        control={
                          <SecondaryCheckbox
                            defaultChecked={option.mapped}
                            onChange={evt =>
                              handleCheckboxChange({
                                evt,
                                option,
                                type: value
                              })
                            }
                            name={option.label}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <IconButton className={classes.closeButton} onClick={() => closeDialog()}>
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          action={
            <>
              <Button color="inherit" size="small" onClick={handleOKButton}>
                OK
              </Button>
              <Button color="inherit" size="small" onClick={handleCancelButton}>
                Cancel
              </Button>
            </>
          }
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <MuiThemeProvider theme={theme}>
        <>
          <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {dialogTitle}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DropzoneArea
                filesLimit={1}
                acceptedFiles={['.zip']}
                fileObjects={fileObjects}
                maxFileSize={50000000000}
                useChipsForPreview
                previewText=""
                showPreviews
                showFileNamesInPreview
                showPreviewsInDropzone={false}
                onAdd={newFile => {
                  setFileObjects(newFile);
                }}
                onDrop={newFile => {
                  setFileObjects(newFile);
                }}
                onDelete={() => {
                  setFileObjects([]);
                }}
              />
              <div className={classes.referencesContainer}>
                {stickerData.references
                  ? stickerData.references.map(image => {
                      return (
                        <div key={image.id} className={classes.imageWrapper}>
                          <span
                            className={classes.deleteButton}
                            onClick={() => handleDeleteImage(image)}
                          >
                            <DeleteAltIcon />
                          </span>
                          <img
                            alt={image.label}
                            src={image.url}
                            className={classes.image}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                autoFocus
                disabled={
                  !fileObjects.length > 0 &&
                  !isCheckboxSelected &&
                  !isStoreBrandCheckboxesSelected &&
                  !deletedImages.length > 0
                }
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </MuiThemeProvider>
    </>
  );
};

ReferenceImageUpload.propTypes = {
  classes: object.isRequired,
  stickerRow: object,
  isOpen: bool,
  stickerValues: array,
  setOpen: func,
  handleSubmit: func
};

ReferenceImageUpload.defaultProps = {
  isOpen: false,
  stickerRow: {},
  stickerValues: [],
  setOpen: () => {},
  handleSubmit: () => {}
};

export default withStyles(styles)(ReferenceImageUpload);

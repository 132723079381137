/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import { appTypes, adminTypes } from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  subPanel: [],
  subPanelPageData: undefined,
  uploadPackshot: {},
  uploadReferenceImages: {},
  uploadExcel: {},
  pollTask: {},
  pollTaskData: {},
  stickerValues: [],
  displayValues: [],
  pageTotal: 0,
  downloadAllTemplates: undefined,
  downloadTemplates: undefined,
  downloadExcel: undefined,
  downloadPackshots: undefined,
  apiRequestStatus: {
    getSubPanel: { request: false, success: false, error: false },
    getSubPanelPageData: { request: false, success: false, error: false },
    getStickerValues: { request: false, success: false, error: false },
    getDisplayValues: { request: false, success: false, error: false },
    downloadAllTemplates: { request: false, success: false, error: false },
    downloadTemplate: { request: false, success: false, error: false },
    downloadExcel: { request: false, success: false, error: false },
    downloadPackshots: { request: false, success: false, error: false },
    postSubPanelPageData: { request: false, success: false, error: false },
    pollTask: { start: false, success: false, error: false, stop: false },
    uploadPackshot: {
      request: false,
      success: false,
      error: false,
      progress: 0
    },
    uploadReferenceImages: {
      request: false,
      success: false,
      error: false,
      progress: 0
    },
    uploadExcel: {
      request: false,
      success: false,
      error: false,
      progress: 0
    }
  }
};

const reducer = createReducer(initialState, {
  [appTypes.routeChange]: state => {
    state.subPanelPageData = undefined;
    state.apiRequestStatus = initialState.apiRequestStatus;
  },
  [adminTypes.routeChange]: state => {
    state.subPanelPageData = undefined;
    state.apiRequestStatus = initialState.apiRequestStatus;
  },
  [adminTypes.updateSidePanel]: (state, action) => {
    const { other, params } = action.payload;
    state.subPanel = state.subPanel.map(panel => {
      if (panel.name === other.subpanel) {
        return { ...panel, ...params };
      }
      return panel;
    });
  },
  [adminTypes.getSubPanel.request]: state => {
    state.apiRequestStatus.getSubPanel = {
      request: true,
      success: false,
      error: false
    };
  },
  [adminTypes.getSubPanel.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.getSubPanel = {
      request: true,
      success: true,
      error: false
    };
    state.subPanel = response.data;
  },
  [adminTypes.getSubPanel.failure]: state => {
    state.apiRequestStatus.getSubPanel = {
      request: true,
      success: false,
      error: true
    };
    state.subPanel = [];
  },
  [adminTypes.getSubPanelPageData.request]: state => {
    state.apiRequestStatus.getSubPanelPageData = {
      request: true,
      success: false,
      error: false
    };
    state.subPanelPageData = undefined;
  },
  [adminTypes.getSubPanelPageData.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.getSubPanelPageData = {
      request: true,
      success: true,
      error: false
    };
    state.subPanelPageData = response.data;
    state.pageTotal = response.data.total;
  },
  [adminTypes.getSubPanelPageData.failure]: state => {
    state.apiRequestStatus.getSubPanelPageData = {
      request: true,
      success: false,
      error: true
    };
    state.subPanelPageData = undefined;
  },
  [adminTypes.getDisplayValues.request]: state => {
    state.apiRequestStatus.getDisplayValues = {
      request: true,
      success: false,
      error: false
    };
    state.displayValues = undefined;
  },
  [adminTypes.getDisplayValues.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.getDisplayValues = {
      request: true,
      success: true,
      error: false
    };
    state.displayValues = response.data;
  },
  [adminTypes.getDisplayValues.failure]: state => {
    state.apiRequestStatus.getDisplayValues = {
      request: true,
      success: false,
      error: true
    };
    state.displayValues = undefined;
  },
  [adminTypes.getStickerValues.request]: state => {
    state.apiRequestStatus.getStickerValues = {
      request: true,
      success: false,
      error: false
    };
    state.stickerValues = [];
  },
  [adminTypes.getStickerValues.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.getStickerValues = {
      request: true,
      success: true,
      error: false
    };
    state.stickerValues = response.data;
  },
  [adminTypes.getStickerValues.failure]: state => {
    state.apiRequestStatus.getStickerValues = {
      request: true,
      success: false,
      error: true
    };
    state.stickerValues = [];
  },
  [adminTypes.postSubPanelPageData.request]: state => {
    state.apiRequestStatus.postSubPanelPageData = {
      request: true,
      success: false,
      error: false
    };
    state.postSubPanelPageData = undefined;
  },
  [adminTypes.postSubPanelPageData.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.postSubPanelPageData = {
      request: true,
      success: true,
      error: false
    };
    state.postSubPanelPageData = response;
  },
  [adminTypes.postSubPanelPageData.failure]: state => {
    state.apiRequestStatus.postSubPanelPageData = {
      request: true,
      success: false,
      error: true
    };
    state.postSubPanelPageData = undefined;
  },
  [adminTypes.downloadAllTemplates.request]: state => {
    state.apiRequestStatus.downloadAllTemplates = {
      request: true,
      success: false,
      error: false
    };
    state.downloadAllTemplates = undefined;
  },
  [adminTypes.downloadAllTemplates.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.downloadAllTemplates = {
      request: true,
      success: true,
      error: false
    };
    state.downloadAllTemplates = response.data;
  },
  [adminTypes.downloadAllTemplates.failure]: state => {
    state.apiRequestStatus.downloadAllTemplates = {
      request: true,
      success: false,
      error: true
    };
    state.downloadAllTemplates = undefined;
  },
  [adminTypes.downloadTemplate.request]: state => {
    state.apiRequestStatus.downloadTemplate = {
      request: true,
      success: false,
      error: false
    };
    state.downloadTemplate = undefined;
  },
  [adminTypes.downloadTemplate.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.downloadTemplate = {
      request: true,
      success: true,
      error: false
    };
    state.downloadTemplate = response.data;
  },
  [adminTypes.downloadTemplate.failure]: state => {
    state.apiRequestStatus.downloadTemplate = {
      request: true,
      success: false,
      error: true
    };
    state.downloadTemplate = undefined;
  },
  [adminTypes.downloadExcel.request]: state => {
    state.apiRequestStatus.downloadExcel = {
      request: true,
      success: false,
      error: false
    };
    state.downloadExcel = undefined;
  },
  [adminTypes.downloadExcel.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.downloadExcel = {
      request: true,
      success: true,
      error: false
    };
    state.downloadExcel = response.data;
  },
  [adminTypes.downloadExcel.failure]: state => {
    state.apiRequestStatus.downloadExcel = {
      request: true,
      success: false,
      error: true
    };
    state.downloadExcel = undefined;
  },
  [adminTypes.downloadPackshots.request]: state => {
    state.apiRequestStatus.downloadPackshots = {
      request: true,
      success: false,
      error: false
    };
    state.downloadPackshots = undefined;
  },
  [adminTypes.downloadPackshots.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.downloadPackshots = {
      request: true,
      success: true,
      error: false
    };
    state.downloadPackshots = response.data;
  },
  [adminTypes.downloadPackshots.failure]: state => {
    state.apiRequestStatus.downloadPackshots = {
      request: true,
      success: false,
      error: true
    };
    state.downloadPackshots = undefined;
  },
  [adminTypes.uploadPackshot.request]: state => {
    state.apiRequestStatus.uploadPackshot = {
      request: true,
      success: false,
      progress: 0,
      error: false
    };
    state.apiRequestStatus.pollTask = {
      start: false,
      stop: false,
      success: false,
      error: false
    };
    state.pollTask = {};
    state.pollTaskData = {};
    state.uploadPackshot = {};
  },
  [adminTypes.uploadPackshot.progress]: (state, action) => {
    const { progress } = action.payload;
    state.apiRequestStatus.uploadPackshot = {
      request: true,
      success: false,
      progress,
      error: false
    };
  },
  [adminTypes.uploadPackshot.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.uploadPackshot = {
      request: true,
      success: true,
      progress: 100,
      error: false
    };
    state.uploadPackshot = response;
  },
  [adminTypes.uploadPackshot.failure]: (state, action) => {
    const {
      error: { error }
    } = action.payload;
    state.apiRequestStatus.uploadPackshot = {
      request: true,
      success: false,
      progress: 0,
      error: true,
      message: error
    };
    state.uploadPackshot = {};
  },
  [adminTypes.uploadReferenceImages.request]: state => {
    state.apiRequestStatus.uploadReferenceImages = {
      request: true,
      success: false,
      progress: 0,
      error: false
    };
    state.apiRequestStatus.pollTask = {
      start: false,
      stop: false,
      success: false,
      error: false
    };
    state.pollTask = {};
    state.pollTaskData = {};
    state.uploadReferenceImages = {};
  },
  [adminTypes.uploadReferenceImages.progress]: (state, action) => {
    const { progress } = action.payload;
    state.apiRequestStatus.uploadReferenceImages = {
      request: true,
      success: false,
      progress,
      error: false
    };
  },
  [adminTypes.uploadReferenceImages.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.uploadReferenceImages = {
      request: true,
      success: true,
      progress: 100,
      error: false
    };
    state.uploadReferenceImages = response;
  },
  [adminTypes.uploadReferenceImages.failure]: state => {
    state.apiRequestStatus.uploadReferenceImages = {
      request: true,
      success: false,
      progress: 0,
      error: true
    };
    state.uploadReferenceImages = {};
  },
  [adminTypes.uploadExcel.request]: state => {
    state.apiRequestStatus.uploadExcel = {
      request: true,
      success: false,
      progress: 0,
      error: false
    };
    state.apiRequestStatus.pollTask = {
      start: false,
      stop: false,
      success: false,
      error: false
    };
    state.pollTask = {};
    state.pollTaskData = {};
    state.uploadExcel = {};
  },
  [adminTypes.uploadExcel.progress]: (state, action) => {
    const { progress } = action.payload;
    state.apiRequestStatus.uploadExcel = {
      request: true,
      success: false,
      progress,
      error: false
    };
  },
  [adminTypes.uploadExcel.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.uploadExcel = {
      request: true,
      success: true,
      progress: 100,
      error: false
    };
    state.uploadExcel = response;
  },
  [adminTypes.uploadExcel.failure]: (state, action) => {
    const {
      error: { error }
    } = action.payload;
    state.apiRequestStatus.uploadExcel = {
      request: true,
      success: false,
      progress: 0,
      error: true,
      message: error
    };
    state.uploadExcel = {};
  },
  [adminTypes.pollTask.start]: (state, action) => {
    const { params, other } = action.payload;
    state.apiRequestStatus.pollTask = {
      start: true,
      stop: false,
      success: false,
      error: false
    };
    state.pollTask = { ...params, ...other };
    state.pollTaskData = {};
  },
  [adminTypes.pollTask.stop]: state => {
    state.apiRequestStatus.pollTask = {
      start: false,
      stop: true,
      success: false,
      error: false
    };
  },
  [adminTypes.pollTask.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.pollTask = {
      start: true,
      stop: false,
      success: true,
      error: false
    };
    state.pollTaskData = response;
  },
  [adminTypes.pollTask.failure]: (state, action) => {
    const {
      error: { error }
    } = action.payload;
    state.apiRequestStatus.pollTask = {
      start: true,
      stop: false,
      success: false,
      error: true,
      message: error
    };
    state.pollTaskData = {};
  }
});

export default reducer;

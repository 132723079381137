/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import { appTypes, SETypes } from 'redux-utils/types/index';

// initial reducer state
const initialState = {
  preferences: {
    filters: {},
    granularities: {}
  },
  filters: {
    list: {}
  },
  chartList: {
    isFetching: false,
    list: []
  },
  chartData: {
    isFetching: false,
    data: {}
  },
  images: {
    isFetching: false,
    list: []
  },
  variants: {
    list: []
  },
  apiRequestStatus: {
    filters: { request: false, success: false, error: false }
  }
};

const reducer = createReducer(initialState, {
  [SETypes.getFilters.request]: state => {
    state.filters.list = {};
    state.apiRequestStatus.filters = {
      request: true,
      success: false,
      error: false
    };
  },
  [SETypes.getFilters.success]: (state, action) => {
    state.filters.list = action.payload.response.data;
    state.apiRequestStatus.filters = {
      request: false,
      success: true,
      error: false
    };
  },
  [SETypes.getFilters.failure]: state => {
    state.filters.list = {};
    state.apiRequestStatus.filters = {
      request: false,
      success: false,
      error: true
    };
  },
  [appTypes.filterChange]: state => {
    state.images = { list: [], isFetching: false };
    state.variants = { list: [] };
  }
});

export default reducer;

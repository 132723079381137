import { all } from 'redux-saga/effects';
import watchNetworkStatus from 'redux-utils/sagas/networkSaga';
import accuracySaga from 'redux-utils/sagas/accuracySaga';
import adminSaga from 'redux-utils/sagas/adminSaga';
import authSaga from 'redux-utils/sagas/authSaga';
import appSaga from 'redux-utils/sagas/appSaga';
import appImagesSaga from 'redux-utils/sagas/appImagesSaga';
import chartSaga from 'redux-utils/sagas/chartSaga';
import fileUploadSaga from 'redux-utils/sagas/fileUploadSaga';
import merchSaga from 'redux-utils/sagas/merchSaga';
import storeExplorerSaga from 'redux-utils/sagas/storeExplorerSaga';
import taskPollSaga from 'redux-utils/sagas/taskPollSaga';
import SESaga from 'redux-utils/sagas/SESaga';
import genericSESaga from 'redux-utils/sagas/genericSESaga';
import userSaga from 'redux-utils/sagas/userSaga';

function* rootSaga() {
  yield all([
    watchNetworkStatus(),
    taskPollSaga(),
    ...accuracySaga,
    ...merchSaga,
    ...fileUploadSaga,
    ...authSaga,
    ...appSaga,
    ...chartSaga,
    ...storeExplorerSaga,
    ...SESaga,
    ...genericSESaga,
    ...userSaga,
    ...appImagesSaga,
    ...adminSaga
  ]);
}

const actionTypeFormatter = (type, target) =>
  `${type.slice(0, type.indexOf('REQUEST') - 1)}_${target}`;

export default rootSaga;
export { actionTypeFormatter };

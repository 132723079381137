const PermissionsEnum = Object.freeze({
  read: 'READ',
  update: 'UPDATE',
  create: 'CREATE',
  delete: 'DELETE',
  all: '*'
});

const idPrefix = '*#@$';

// eslint-disable-next-line import/prefer-default-export
export { idPrefix, PermissionsEnum };

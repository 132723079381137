import React from 'react';
import PropTypes from 'prop-types';
// core components
import TinyLineChart from 'charts/line/TinyLineChart';
import Typography from 'components/Typography/Typography';

import './__styles.scss';

const ComponentTable = ({ data }) => {
  return (
    <>
      {data.datasets.map((item, i) => (
        <div
          key={i}
          className="Rtable Rtable-row Rtable--2cols Rtable--collapse"
        >
          <div className="Rtable-cell Rtable-cell--head">
            <h1>{item.title}</h1>
            <Typography color="#fff" size="l">
              {item.current}
            </Typography>
          </div>
          <div className="Rtable-cell">
            <TinyLineChart data={item.data} />
          </div>
        </div>
      ))}
    </>
  );
};

ComponentTable.propTypes = {
  data: PropTypes.object.isRequired
};

export default ComponentTable;

import {
  addDays,
  getPreviousDate,
  getLastPeriod,
  getThisPeriod
} from 'lib/core/dateUtils';

/**
 * @method
 * @param {number} value - value of the previous period
 * @description Calculates the start and end date based on the given days to subtract from current date
 * @returns {undefined}
 */
const getStartEndDate = value => {
  let startDate = getPreviousDate(value, 'days');
  let timeType = 'day';
  let endDate = getPreviousDate(1, 'days');
  const dateMap = {
    l7: () => {
      startDate = getPreviousDate(7, 'days');
      timeType = 'day';
    },
    l30: () => {
      startDate = getPreviousDate(1, 'month');
      timeType = 'week';
    },
    l90: () => {
      startDate = getPreviousDate(3, 'month');
      timeType = 'month';
    },
    lw: () => {
      startDate = getLastPeriod(7, 'days');
      timeType = 'day';
      endDate = addDays(6, 'days', getLastPeriod(7, 'days', false));
    },
    lm: () => {
      [startDate, endDate] = getLastPeriod(1, 'months');
      timeType = 'week';
    },
    lq: () => {
      [startDate] = getLastPeriod(3, 'months');
      [, endDate] = getLastPeriod(1, 'months');
      timeType = 'month';
    },
    tw: () => {
      [startDate] = getThisPeriod({ type: 'week' });
      timeType = 'day';
    },
    tm: () => {
      [startDate] = getThisPeriod({ type: 'month' });
      timeType = 'week';
    },
    yesterday: () => {
      startDate = getPreviousDate(1, 'days');
      endDate = getPreviousDate(0, 'days');
    },
    today: () => {
      startDate = getPreviousDate(0, 'days');
      endDate = addDays(1, 'days');
    }
  };
  dateMap[value]();
  return {
    fl_start_date: startDate,
    fl_end_date: endDate,
    fl_time_type: timeType
  };
};
export default getStartEndDate;

/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// lodash wrapper for immutable.js
import { filter, isEmpty, map, forEach, pick, findIndex } from 'lodash';
// @material-ui/core
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Dropdown from 'components/Dropdown/Dropdown';
import Toggle from 'components/Toggle/Toggle';
import { HSpacer } from 'components/Spacer/Spacer';
// core lib
import { uniqBy, searchByKey } from 'lib/core/array';
import {
  handleCustomerFilterChange,
  handleChannelFilterChange,
  handleSpaceTypeFilterChange,
  handleSpaceFilterChange
} from 'lib/filter/helper';
// layout
import TimeFilter from 'layout/Filter/TimeFilter';

/**
 * @ignore
 * @name FilterToolbarStyles
 * @description toolbarStyles for Filter component
 */
const toolbarStyles = theme => ({
  root: {
    marginBottom: '4px',
    // marginTop: '-19px',
    minHeight: '50px',
    paddingLeft: 0,
    // paddingRight: 0,
    overflowX: 'auto !important',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    marginTop: theme.palette.type === 'light' ? '0px' : '0px',
    display: theme.palette.type === 'light' && 'flex',
    flexDirection: theme.palette.type === 'light' && 'column',
    paddingRight: theme.palette.type === 'light' && '0px',
    '& div[class*="control"]': {
      width: theme.palette.type === 'light' && '140px'
    },
    '& div[class*="TimeFilter"]': {
      marginLeft: theme.palette.type === 'light' && '0px'
    },
    '& > div': {
      marginTop: theme.palette.type === 'light' && '10px'
    },
    paddingBottom: '4px',
    '&::-webkit-scrollbar': {
      display: 'block',
      // backgroundColor: 'white',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    },
    height: theme.palette.type === 'light' ? '100%' : null
  }
});

const handleChange = (val, key, filters, callback) => {
  if (filters[key].use_value_dropdown) {
    const valueObject =
      filters[val.value].options.filter(el => el.is_default === true).length ===
      0
        ? filters[val.value].options[0]
        : filters[val.value].options.filter(el => el.is_default === true)[0];
    const value = {
      [key]: val,
      [val.value]: valueObject
    };
    callback(value);
  } else callback(val, key);
};

/**
 * @class Filter
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
const Filter = ({
  classes,
  filters,
  defaultFilters,
  state,
  handleFilterChange,
  handleToggleChange,
  initialSelectedDate,
  routeID,
  dashboardType
}) => (
  <Toolbar className={classes.root}>
    {!isEmpty(filters)
      ? map(defaultFilters, def => {
          const { value: type, label: title } = def;
          if (type === 'fl_space') {
            let spaceTypeValueType = '';
            const spaceTypeFilter = {
              options: filters.fl_space_type
                ? filters.fl_space_type.options
                : [] || [],
              value: {}
            };
            const spaceTypeValueFilter = {
              options: [],
              value: {}
            };
            const channelFilter = { options: [], value: {} };
            const customerFilter = { options: [], value: {} };
            if (
              isEmpty(state.fl_channel) &&
              isEmpty(state.fl_customer) &&
              isEmpty(state.fl_space_type)
            ) {
              spaceTypeFilter.value =
                spaceTypeFilter.options.filter(el => el.is_default === true)
                  .length === 0
                  ? spaceTypeFilter.options[0]
                  : spaceTypeFilter.options.filter(
                      el => el.is_default === true
                    )[0];
              spaceTypeValueType = spaceTypeFilter.value.value;
              forEach(filters.fl_space.fl_channel, channel => {
                forEach(channel[spaceTypeValueType], region => {
                  spaceTypeValueFilter.options.push({
                    ...region,
                    f: spaceTypeValueType
                  });
                });
              });
              spaceTypeValueFilter.options = uniqBy(
                spaceTypeValueFilter.options,
                'value'
              );
              spaceTypeValueFilter.value =
                spaceTypeValueFilter.options.filter(
                  el => el.is_default === true
                ).length === 0
                  ? spaceTypeValueFilter.options[0]
                  : spaceTypeValueFilter.options.filter(
                      el => el.is_default === true
                    )[0];
              if (spaceTypeValueFilter.value) {
                const { value: searchBy } = spaceTypeValueFilter.value;
                channelFilter.options = map(
                  filter(
                    filters.fl_space.fl_region.fl_channel,
                    channel =>
                      filter(
                        channel.fl_region,
                        region => region.value === searchBy
                      ).length !== 0
                  ),
                  ch => pick(ch, ['value', 'label', 'is_default'])
                );
                channelFilter.value =
                  channelFilter.options.filter(el => el.is_default === true)
                    .length === 0
                    ? channelFilter.options[0]
                    : channelFilter.options.filter(
                        el => el.is_default === true
                      )[0];
                customerFilter.options = map(
                  filter(
                    filters.fl_space.fl_region.fl_customer,
                    customer =>
                      filter(
                        customer.fl_region,
                        region => region.value === searchBy
                      ).length !== 0
                  ),
                  cus => pick(cus, ['value', 'label', 'is_default'])
                );
                customerFilter.value =
                  customerFilter.options.filter(el => el.is_default === true)
                    .length === 0
                    ? customerFilter.options[0]
                    : customerFilter.options.filter(
                        el => el.is_default === true
                      )[0];
              }
            } else {
              customerFilter.options = state.fl_customer.options;
              customerFilter.value = {
                value: state.fl_customer.value,
                label: state.fl_customer.label
              };
              channelFilter.options = state.fl_channel.options;
              channelFilter.value = {
                value: state.fl_channel.value,
                label: state.fl_channel.label,
                fl_posm: state.fl_channel.fl_posm
              };
              spaceTypeFilter.options = state.fl_space_type.options;
              spaceTypeFilter.value = {
                value: state.fl_space_type.value,
                label: state.fl_space_type.label
              };
              spaceTypeValueFilter.options =
                state[state.fl_space_type.value].options;
              spaceTypeValueFilter.value = {
                value: state[state.fl_space_type.value].value,
                label: state[state.fl_space_type.value].label
              };
            }

            const appSpaceData = {
              customerFilter,
              spaceTypeValueFilter,
              spaceTypeFilter,
              channelFilter
            };

            return (
              <React.Fragment key={type}>
                <Dropdown
                  useMenuPortalTarget={!(dashboardType === 'v2')}
                  title={
                    searchByKey({
                      key: 'value',
                      value: 'fl_space_type',
                      array: defaultFilters
                    })
                      ? searchByKey({
                          key: 'value',
                          value: 'fl_space_type',
                          array: defaultFilters
                        }).label
                      : ' '
                  }
                  routeID={routeID}
                  type={type}
                  handleValueChange={val => {
                    handleSpaceFilterChange({
                      value: { ...val, f: 'fl_space_type' },
                      spaceData: filters.fl_space,
                      appSpaceData,
                      callback: handleFilterChange
                    });
                  }}
                  {...appSpaceData.spaceTypeFilter}
                />
                <HSpacer amount={5} />
                <Dropdown
                  useMenuPortalTarget={!(dashboardType === 'v2')}
                  title={title || ' '}
                  routeID={routeID}
                  type={type}
                  handleValueChange={val => {
                    handleSpaceTypeFilterChange({
                      value: {
                        ...val,
                        f: appSpaceData.spaceTypeFilter.value.value
                      },
                      spaceData: filters.fl_space,
                      appSpaceData,
                      callback: handleFilterChange
                    });
                  }}
                  {...appSpaceData.spaceTypeValueFilter}
                />
                <HSpacer amount={5} />
                <Dropdown
                  useMenuPortalTarget={!(dashboardType === 'v2')}
                  title={
                    searchByKey({
                      key: 'value',
                      value: 'fl_channel',
                      array: defaultFilters
                    })
                      ? searchByKey({
                          key: 'value',
                          value: 'fl_channel',
                          array: defaultFilters
                        }).label
                      : 'Channel'
                  }
                  routeID={routeID}
                  type="fl_channel"
                  handleValueChange={val => {
                    handleChannelFilterChange({
                      value: {
                        ...val,
                        f: 'fl_channel'
                      },
                      spaceData: filters.fl_space,
                      appSpaceData,
                      callback: handleFilterChange
                    });
                  }}
                  {...appSpaceData.channelFilter}
                />
                <HSpacer amount={5} />
                <Dropdown
                  useMenuPortalTarget={!(dashboardType === 'v2')}
                  title={
                    searchByKey({
                      key: 'value',
                      value: 'fl_customer',
                      array: defaultFilters
                    })
                      ? searchByKey({
                          key: 'value',
                          value: 'fl_customer',
                          array: defaultFilters
                        }).label
                      : 'Customer'
                  }
                  routeID={routeID}
                  type="fl_customer"
                  handleValueChange={val => {
                    handleCustomerFilterChange({
                      value: {
                        ...val,
                        f: 'fl_customer'
                      },
                      spaceData: filters.fl_space,
                      appSpaceData,
                      callback: handleFilterChange
                    });
                  }}
                  {...appSpaceData.customerFilter}
                />
                <HSpacer amount={5} />
              </React.Fragment>
            );
          }
          if (
            type !== 'fl_space' &&
            type !== 'fl_channel' &&
            type !== 'fl_customer'
          ) {
            if (filters[type]) {
              const {
                options,
                use_calendar: useCalendar,
                use_toggle: useToggle,
                use_value_dropdown: useValueDropDown
              } = filters[type];
              const value =
                state[type] !== undefined && state[type] !== null
                  ? state[type]
                  : options.filter(e => e.is_default === true)[0];
              let valueDropDownKey = '';
              let valueDropDownOptions = [];
              let valueDropDownValue = {};
              if (useValueDropDown) {
                const defaultItem =
                  state[type] !== undefined && state[type] !== null
                    ? options.filter(e => e === state[type])[0]
                    : options.filter(e => e.is_default === true)[0];
                valueDropDownKey = defaultItem.value;
                valueDropDownOptions = filters[defaultItem.value].options;
                valueDropDownValue =
                  state[valueDropDownKey] !== undefined &&
                  state[valueDropDownKey] !== null
                    ? state[valueDropDownKey]
                    : valueDropDownOptions.filter(
                        e => e.is_default === true
                      )[0];
              }
              const toggleIndex = useToggle
                ? state[type] !== undefined
                  ? findIndex(options, opt => opt.value === state[type].value)
                  : 0
                : 0;
              return useCalendar && value ? (
                <TimeFilter
                  title={title}
                  key={type}
                  options={options}
                  value={value}
                  routeID={routeID}
                  type={type}
                  defaultDateRange={initialSelectedDate}
                  handleDateChange={range =>
                    handleChange(range, type, filters, handleFilterChange)
                  }
                  useMenuPortalTarget={!(dashboardType === 'v2')}
                  parentComponentClass=".dashboardPanel"
                />
              ) : useToggle ? (
                <React.Fragment key={type}>
                  <Toggle
                    key={type}
                    name={type}
                    label={options[0].label}
                    labelRight={options[1].label}
                    isChecked={toggleIndex === 1}
                    handleToggle={val => {
                      handleToggleChange({ options, val, type });
                    }}
                  />
                  <HSpacer amount={5} />
                </React.Fragment>
              ) : (
                <React.Fragment key={type}>
                  <Dropdown
                    title={title}
                    handleValueChange={val =>
                      handleChange(val, type, filters, handleFilterChange)
                    }
                    options={options}
                    value={value}
                    routeID={routeID}
                    type={type}
                    useMenuPortalTarget={!(dashboardType === 'v2')}
                  />
                  {useValueDropDown ? (
                    <React.Fragment key={type}>
                      <HSpacer amount={5} />
                      <Dropdown
                        handleValueChange={val =>
                          handleChange(
                            val,
                            valueDropDownKey,
                            filters,
                            handleFilterChange
                          )
                        }
                        options={valueDropDownOptions}
                        value={valueDropDownValue}
                        routeID={routeID}
                        type={type}
                        useMenuPortalTarget={!(dashboardType === 'v2')}
                      />
                    </React.Fragment>
                  ) : null}
                  <HSpacer amount={5} />
                </React.Fragment>
              );
            }
          }
        })
      : null}
  </Toolbar>
);

// component properties
Filter.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {array}
   * @description filter list
   */
  defaultFilters: PropTypes.array.isRequired,
  routeID: PropTypes.string,
  dashboardType: PropTypes.string.isRequired
};

Filter.defaultProps = {
  routeID: ''
};

export default withStyles(toolbarStyles)(Filter);

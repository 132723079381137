import moment from 'moment';

/**
 * @name formatDate
 * @method
 * @example
 * // returns 07 Jan 2019
 * formatDate(
 *  Mon Jan 07 2019 00:00:00 GMT+0530 (India Standard Time),
 * )
 * @param {Date} date - ISO format Date
 * @param {boolean} long - use dd mm yyyy format
 * @description Formats the date in either dd mm yyyy or dd/mm/yyyy format
 * @returns {string} - Formatted date string
 */
const formatDate = (date, long = false) =>
  long ? moment(date).format('DD MMM YYYY') : moment(date).format('DD/MM/YYYY');

const getDaysBetweenRange = (end, start) =>
  moment.duration(moment(start).diff(moment(end))).asDays() + 1;

/**
 * @name DateRangeStringFormatter
 * @method
 * @example
 * // returns 7 Jan 2019 - 8 Jan 2019
 * dateRangeString(
 *  Mon Jan 07 2019 00:00:00 GMT+0530 (India Standard Time),
 *  Tue Jan 08 2019 20:09:49 GMT+0530 (India Standard Time),
 * )
 * @param {Date} start - ISO format Start date
 * @param {Date} end - ISO format End date
 * @param {bool} isString - Whether to return string value or numeric value
 * @description Formats the date range in the {start} - {end} string format
 * @returns {string} - Formatted date range string
 */
const dateRangeString = (start, end) =>
  `${dateString(start)} - ${dateString(end)}`;

const dateString = date => formatDate(moment(date), true);

/**
 * @name addHoursToTimestamp
 * @method
 * @example
 * // returns 1548284191254
 * addHours(6)
 * // returns 1376850600
 * let date = Math.round(new Date('12/12/12').getTime()/1000);
 * addHours(6,date)
 * @param {number} h - hours to add
 * @param {number} timestamp - epoch timestamp
 * @description Adds the given amount of hours to the given timestamp
 * @returns {number} - timestamp
 */
const addHours = (h, timestamp = Date.now()) => timestamp + h * 60 * 60 * 1000;

/**
 * @name getTotalDaysInMonth
 * @method
 * @example
 * // 31
 * getTotalDaysInMonth(0)
 * // 30
 * getTotalDaysInMonth(3)
 * @param {number} monthCode - month index starting from 0
 * @description Calculates the no of days in the given month
 * @returns {number} - no of days
 */
const getTotalDaysInMonth = (monthCode = new Date().getMonth()) => {
  const now = new Date();
  return new Date(now.getFullYear(), monthCode + 1, 0).getDate();
};

/**
 * @name getPreviousDate
 * @method
 * @example
 * // 23/12/2018
 * getPreviousDate(1,'month')
 * // 23/01/2017
 * getPreviousDate(1,'year')
 * // 16/01/2019
 * getPreviousDate(7,'days')
 * @param {number} count - count to subtract from current date
 * @param {string} type - type to subtract
 * @param {boolean} format - type to subtract
 * @description Subtracts the given number of type from the current date
 * @returns {string} - date in dd/mm/yyyy format
 */
const getPreviousDate = (count, type = 'days', format = true) =>
  format
    ? formatDate(moment().subtract(count, type))
    : moment().subtract(count, type);

const getPreviousMonthRange = date =>
  `01/0${
    date.getMonth() + 1 !== 1 ? date.getMonth() - 1 : 1
  }/${date.getFullYear()}`;

const getLastPeriod = (count, type = 'days', format = true) => {
  switch (type) {
    case 'days':
      return format
        ? formatDate(moment().subtract(count + new Date().getDay(), type))
        : moment().subtract(count + new Date().getDay(), type);
    case 'months': {
      const today = new Date();
      if (today.getMonth() - count >= 0) {
        const previousMonthDate = moment([
          today.getFullYear(),
          today.getMonth() - count,
          10
        ]);
        return format
          ? [
              formatDate(previousMonthDate.startOf('month')),
              formatDate(previousMonthDate.endOf('month'))
            ]
          : [
              previousMonthDate.startOf('month'),
              previousMonthDate.endOf('month')
            ];
      }
      const previousMonthDate = moment([
        today.getFullYear() - 1,
        12 - (count - today.getMonth()),
        10
      ]);
      return format
        ? [
            formatDate(previousMonthDate.startOf('month')),
            formatDate(previousMonthDate.endOf('month'))
          ]
        : [
            previousMonthDate.startOf('month'),
            previousMonthDate.endOf('month')
          ];
    }
    default:
      return 0;
  }
};

const getThisPeriod = ({ type = 'days', format = true }) => {
  return format
    ? [formatDate(moment().startOf(type)), formatDate(moment())]
    : [moment().startOf(type), moment()];
};

const addDays = (count, type = 'days', start = new Date(), format = true) => {
  switch (type) {
    case 'days':
      return format
        ? formatDate(moment(start).add(count, type))
        : moment(start).add(count, type);
    default:
      return 0;
  }
};

export {
  formatDate,
  addHours,
  addDays,
  getPreviousMonthRange,
  getDaysBetweenRange,
  getTotalDaysInMonth,
  getPreviousDate,
  getLastPeriod,
  getThisPeriod,
  dateRangeString,
  dateString
};

// const getPreviousDate = (daysToSubtract, startDate = new Date()) => startDate.setDate(startDate.getDate() - daysToSubtract);

const calendarStyles = theme => ({
  card: {
    color: 'rgba(0, 0, 0, 0.87)',
    zIndex: 1,
    position: 'absolute',
    // background: '#363848',
    background: theme.palette.type === 'light' ? '#ffffff' : '#363848',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    right: 18,
    top: 15
  },
  heading: {
    color: theme.palette.type === 'light' ? '#767676' : '#fff',
    padding: '14px 0 0 33px',
    margin: 0,
    font: "500 16px 'Google Sans',Arial,Helvetica,sans-serif"
  },
  right: {
    marginLeft: 'auto'
  },
  calendar: {
    position: 'relative'
  },
  absolute: {
    display: 'block',
    zIndex: 10003,
    '& div[class*="Cal__Month__rows"]': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5 !important' : 'inherit',
      color: theme.palette.type === 'light' ? '#767676 !important' : 'inherit'
    },
    '& ul[class*="Cal__Weekdays__root"]': {
      backgroundColor:
        theme.palette.type === 'light' ? '#f5f5f5 !important' : 'inherit',
      color: theme.palette.type === 'light' ? '#767676 !important' : 'inherit'
    }
  }
});

export default calendarStyles;

import React from 'react';
import { PropTypes } from 'prop-types';
import { each, map } from 'lodash';
import Icon from 'components/Icon/Icon';
import { ArrowUpward, ArrowDownward } from 'assets/icons/appIcons';
import withStyles from '@material-ui/core/styles/withStyles';

const pieTooltipStyles = {
  wrapper: {
    zIndex: 10,
    background: '#fff',
    borderRadius: '6px',
    padding: '10px'
  },
  red: {
    color: 'rgb(233, 30, 99)',
    '& $icon': {
      '& svg': {
        fill: 'rgb(233, 30, 99)'
      }
    }
  },
  value: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
  green: {
    color: 'green',
    '& $icon': {
      '& svg': {
        fill: 'green'
      }
    }
  },
  root: {
    display: 'flex'
  },
  icon: {
    '& div': {
      fontSize: '10px !important'
    },
    '& svg': {
      width: '15px !important',
      height: '19px !important'
    }
  }
};

const computeTooltipObject = (payload, classes) => {
  const obj = {};
  each(payload, data => {
    obj[data.name] = data.value;

    if (data.payload.length !== 0) {
      map(data.payload, (val, key) => {
        if (key.includes('previous')) {
          const difference = Math.round(Math.abs(data.value - val) * 100) / 100;
          obj.previous = true;
          let differenceIcon;
          if (data.value > val) {
            differenceIcon = (
              <Icon>
                <ArrowUpward />
              </Icon>
            );
          } else if (data.value < val) {
            differenceIcon = (
              <Icon>
                <ArrowDownward />
              </Icon>
            );
          }
          const toolTipValue = (
            <div
              className={`${classes.root} ${
                data.value < val ? classes.red : classes.green
              }`}
            >
              <p className={classes.value}>{`${data.value}%`}</p>
              {difference !== 0 ? (
                <>
                  <span className={classes.icon}>{differenceIcon}</span>
                  <p>{`${difference}%`}</p>
                </>
              ) : (
                <p>-</p>
              )}
            </div>
          );
          obj[data.name] = toolTipValue;
        }
      });
    }
    return data;
  });
  return obj;
};

const CustomPieTooltip = props => {
  const { active, payload, classes } = props;
  if (payload) {
    if (active && payload.length !== 0) {
      const obj = computeTooltipObject(payload, classes);
      return (
        <div className={classes.wrapper}>
          {obj.previous
            ? map(obj, (Val, key) =>
                key !== 'previous' ? (
                  <div key={key} className={classes.root}>
                    <p>{`${key} :`}</p>
                    &nbsp;
                    {Val}
                  </div>
                ) : null
              )
            : map(obj, (val, key) => (
                <div key={key + val}>{`${key} : ${val}`}</div>
              ))}
        </div>
      );
    }
    return null;
  }
  return null;
};

// component properties
CustomPieTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.any,
  payload: PropTypes.array
};

CustomPieTooltip.defaultProps = {
  active: false,
  payload: []
};

export default withStyles(pieTooltipStyles)(CustomPieTooltip);

import { transition } from '../material-ui';

const dashboardStyle = theme => ({
  actionButtons: {
    marginLeft: '20px'
  },
  hoverGrid: {
    '&:hover': {
      '& $card': {
        transform: 'translateY(-20%)',
        ...transition
      }
    }
  },
  gridContainer: {
    minHeight: '82.3vh',
    overflow: 'auto'
  },
  granularities: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'start',
    paddingTop: 8
  },
  card: {
    ...transition,
    marginBottom: '8px',
    backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
    // color: theme.palette.type === 'light' && '#767676',
    color: theme.palette.type === 'light' && '#424242',
    '& h4,div': {
      // color: theme.palette.type === 'light' && '#767676'
      color: theme.palette.type === 'light' && '#424242'
    }
  },
  title: {
    fontSize: theme.palette.type === 'light' ? 16 : 14,
    fontWeight: theme.palette.type === 'light' ? 'bold' : '400',
    color:
      theme.palette.type === 'light'
        ? '#282828 !important'
        : 'rgba(255,255,255,.8)'
  }
});

export default dashboardStyle;

import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// colors
import colors from 'styles/__variables';

const styles = {
  vertical: {
    width: '25px',
    height: '300px',
    marginLeft: 'auto',
    marginRight: 10,
    background:
      'linear-gradient(to top, #f00 0%, #ff0 20%, #0f0 40%, #0ff 60%, #00f 80%)',
    '&:before': {
      display: 'block',
      position: 'relative',
      top: 283,
      left: '-27px',
      content: '"0%"',
      color: colors.dark.light26
    },
    '&:after': {
      display: 'block',
      position: 'relative',
      top: '-15px',
      left: '-41px',
      width: '60px',
      content: '"100%"',
      color: colors.dark.light26
    }
  }
};

const GradientScale = ({ direction, classes }) => (
  <div className={classes[direction]} />
);

GradientScale.propTypes = {
  direction: PropTypes.string,
  classes: PropTypes.object.isRequired
};

GradientScale.defaultProps = {
  direction: 'vertical'
};

export default withStyles(styles)(GradientScale);

import { createSelector } from '@reduxjs/toolkit';
// accuracy store selector
const merchStoreSelector = state => state.merchEvaluation;

const selectActiveSession = createSelector(
  [merchStoreSelector],
  ({ activeSession }) => activeSession
);

const selectApiRequestStatus = createSelector(
  [merchStoreSelector],
  ({ apiRequestStatus }) => apiRequestStatus
);

const selectPageComponents = createSelector(
  [merchStoreSelector],
  ({ pageComponents }) => pageComponents.layout || {}
);

const selectMetricTabs = createSelector(
  [merchStoreSelector],
  ({ metricTabs }) => metricTabs.metric_tab || {}
);

const selectPerformanceTable = createSelector(
  [merchStoreSelector],
  ({ performanceTable }) => performanceTable
);

const selectScheduledReports = createSelector(
  [merchStoreSelector],
  ({ scheduledReports }) => scheduledReports
);

const selectScheduledReportsMetaData = createSelector(
  [merchStoreSelector],
  ({ scheduledReportsMetaData }) => scheduledReportsMetaData
);

const selectPostScheduledReport = createSelector(
  [merchStoreSelector],
  ({ postScheduledReport }) => postScheduledReport
);

const selectUpdateScheduledReport = createSelector(
  [merchStoreSelector],
  ({ updateScheduledReport }) => updateScheduledReport
);

const selectDeleteScheduledReport = createSelector(
  [merchStoreSelector],
  ({ deleteScheduledReport }) => deleteScheduledReport
);

const selectDownloadReport = createSelector(
  [merchStoreSelector],
  ({ downloadReport }) => downloadReport
);

const selectCumulativeCards = createSelector(
  [merchStoreSelector, selectPageComponents],
  ({ cumulativeCards }, { cumulative_cards: cumulativeConfig }) => {
    if (!cumulativeConfig || !cumulativeCards) {
      return {};
    }
    const data = {
      title: cumulativeConfig.title,
      enable: cumulativeConfig.enable,
      previousText: cumulativeConfig.previous.title,
      currentText: cumulativeConfig.current.title
    };
    data.cards = cumulativeCards.map(card => ({
      title: card.title,
      chart: card.chart,
      current: card[cumulativeConfig.current.key],
      previous: card[cumulativeConfig.previous.key]
    }));
    return data;
  }
);

const filterListSelector = state => state.accuracy.filters;

const selectFilterOptions = createSelector(
  [filterListSelector],
  ({ list }) => list
);

const filterStateSelector = state => state.merchEvaluation.filters.currentState;

const layoutSelector = state => state.layoutStore.routes.layout;

const selectFilterState = createSelector(
  [filterStateSelector, layoutSelector],
  (filterState, layout) => {
    const configuredFilters =
      layout.filter(e => e.routeID === 'merchandiser-performance')?.[0]
        .defaults || [];
    // remove filters which are not configured for the merch performance page
    const initialFilterState = {};
    configuredFilters.forEach(({ value: filterID }) => {
      if (filterState[filterID]) {
        initialFilterState[filterID] = filterState[filterID];
      }
    });
    return initialFilterState;
  }
);

export {
  selectActiveSession,
  selectPageComponents,
  selectCumulativeCards,
  selectFilterOptions,
  selectFilterState,
  selectApiRequestStatus,
  selectMetricTabs,
  selectPerformanceTable,
  selectDownloadReport,
  selectScheduledReports,
  selectScheduledReportsMetaData,
  selectPostScheduledReport,
  selectUpdateScheduledReport,
  selectDeleteScheduledReport
};

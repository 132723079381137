import React, {
  useCallback,
  createRef,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce, isEmpty, sumBy, map, throttle } from 'lodash';
import { Stage, Layer } from 'react-konva';
import Draggable from 'react-draggable'; // The default
import useDynamicRefs from 'use-dynamic-refs';

// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// components
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import InputField from 'components/TextField/TextField';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
// views
import Crops from './Crops';
import StitchedImage from './StitchedImage';

const styles = theme => ({
  wrapper: {
    overflow: 'auto'
    // maxWidth: '75vw'
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  canvas: {
    display: 'flex'
  },
  draggable: {
    zIndex: 999,
    position: 'fixed',
    overflow: 'scroll',
    whiteSpace: 'pre-wrap',
    maxHeight: '80vh',
    border: '1px',
    background: theme.palette.type === 'light' ? 'white' : '#2e2e31',
    boxShadow:
      theme.palette.type === 'light' && '10px 10px 50px rgb(0 0 0 / 15%)',
    left: theme.palette.type === 'light' && '30%',
    '& h4': {
      color: theme.palette.type === 'light' && '#767676'
    },
    '& label': {
      color: theme.palette.type === 'light' && '#767676 !important'
    },
    '& input': {
      color: theme.palette.type === 'light' && '#767676 !important'
    }
  },
  dropdown: {
    '& h4': {
      fontSize: theme.palette.type === 'light' && '14px !important',
      fontWeight: theme.palette.type === 'light' && 'unset !important'
    }
  },
  selectedCrops: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  cropDetails: {
    width: 200,
    height: 'fit-content',
    padding: 10,
    overflow: 'auto'
  },
  item: {
    width: 260,
    display: 'grid',
    gridTemplateColumns: '140px 140px',
    '& h4': {
      whiteSpace: 'pre-line',
      wordBreak: 'break-all'
    },
    '& a': {
      color: theme.palette.type === 'light' ? 'blue' : 'aqua'
    },
    '& img': {
      maxHeight: 160,
      maxWidth: '100%',
      paddingTop: 10
    }
  }
});

const StyledDiv = styled.div`
  && {
    background-color: ${props => props.bg};
  }
  && h4 {
    color: ${props => props.textColor};
  }
`;

const ShelfCanvas = ({
  isCropInfoHandlerSelected,
  colors,
  classes,
  useDisplayTag,
  showStickers,
  activeBoxes,
  stickerBoxes,
  activeControls,
  productFilter,
  fixProductFacingState,
  canvasHeight,
  canvasWidth,
  dispatch,
  handleFixProductFacing,
  list,
  reducerState,
  wrapperRef,
  handleCropClick,
  dashboardType,
  tooltipStyles,
  dropdownStyles,
  dropdownTitleStyles
}) => {
  const [activeImageSet, setActiveImageSet] = useState(null);
  const [userScroll, setUserScroll] = useState(false);
  const [imagesSet, setImagesSet] = useState([]);
  const [stickerPrice, setStickerPrice] = useState('');
  const [selectedCrops, setSelectedCrops] = useState({});
  const [cropDetails, setCropDetails] = useState({
    display: false,
    data: {}
  });
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    if (fixProductFacingState.isLoading && fixProductFacingState.success) {
      setSelectedCrops({});
      setFilterValues({});
      setStickerPrice('');
    }
  }, [fixProductFacingState]);

  useEffect(() => {
    setSelectedCrops({});
    setStickerPrice('');
  }, [showStickers]);

  useEffect(() => {
    setCropDetails({ display: false, data: {} });
    setSelectedCrops({});
    setFilterValues({});
    setStickerPrice('');
  }, [isCropInfoHandlerSelected]);

  const throttledMouseEvents = useRef(throttle(q => handleMouseEvents(q), 800))
    .current;

  const handleMouseEvents = e => {
    setUserScroll(e);
  };

  useEffect(() => {
    if (!userScroll) {
      const { current: canvasRef } = wrapperRef;
      canvasRef.scrollTo({
        top: 0,
        left: reducerState.shelfCanvas.activeArea[0],
        behavior: 'smooth'
      });
    }
  }, [reducerState.shelfCanvas, wrapperRef, userScroll]);

  useEffect(() => {
    if (activeControls.reset) {
      resetImageToDefault();
    }
  }, [activeControls]);

  const resetImageToDefault = () => {
    imagesSet.map(item => {
      const stageRef = getStageRef(item);

      if (stageRef && stageRef.current) {
        const { current: stage } = stageRef;
        stage.position({ x: 0, y: 0 });
        stage.to({
          scaleX: 1,
          scaleY: 1,
          x: 0,
          y: 0,
          duration: 0.2
        });
      }
    });
  };

  const handleStageMouseWheel = (e, item, index) => {
    const stageRef = getStageRef(item);
    if (activeControls.zoomIn) {
      const { current: stage } = stageRef;
      const scaleBy = 1.04;
      e.evt.preventDefault();
      const oldScale = stage ? stage.scaleX() : {};

      const pointer = stage ? stage.getPointerPosition() : {};

      const mousePointTo = stage
        ? {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale
          }
        : { x: 0, y: 0 };

      const newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage &&
        stage.scale({
          x: newScale,
          y: newScale
        });

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      };

      stage && stage.position(newPos);
      stage && stage.batchDraw();
    }
  };

  const handleCanvasScroll = ({ event, toDispatch }) => {
    resetImageToDefault();
    if (event && toDispatch) {
      const { target } = event;
      dispatch({
        payload: { event: target },
        type: 'SHELF_CANVAS_SCROLL_EVENT'
      });
    }
  };

  const throttledScroll = useCallback(
    useRef(debounce(q => handleCanvasScroll(q), 0)).current
  );

  const [mouseEvent, setMouseEvent] = useState(null);

  useEffect(() => {
    throttledScroll({
      event: mouseEvent,
      toDispatch: userScroll
    });
  }, [mouseEvent, userScroll, throttledScroll]);

  useEffect(() => {
    const { current: canvasRef } = wrapperRef;
    const scrollListener = canvasRef.addEventListener('scroll', e => {
      setMouseEvent(e);
    });
    return () => {
      canvasRef.removeEventListener('scroll', scrollListener);
    };
  }, [wrapperRef]);

  const handleCropClickEvent = ({ crop }) => {
    if (!isCropInfoHandlerSelected) {
      if (crop.is_sku) {
        return handleCropClick({ crop });
      }
      return 0;
    }
    if (selectedCrops[crop.id]) {
      const crops = { ...selectedCrops };
      delete crops[crop.id];
      return setSelectedCrops(crops);
    }
    if (crop.is_sticker) {
      return setSelectedCrops(() => ({ [crop.id]: { ...crop } }));
    }
    return setSelectedCrops(prev => ({ ...prev, [crop.id]: { ...crop } }));
  };

  const handleCropHoverEvent = ({ crop, image }) => {
    const { columns = [], rowDetails = {} } = crop;
    const arr = [];
    arr.push({ key: 'CropId', value: crop.id });
    if (crop.new_tooltip) {
      map(crop.new_tooltip, (value, key) => {
        arr.push({ key, value });
      });
    }
    arr.push({ key: 'image_url', value: image });
    setCropDetails({
      display: true,
      data: {
        ...crop,
        columns: [...columns],
        rowDetails: { ...rowDetails, cropId: crop.id },
        new_tooltip: arr
      }
    });
  };

  const handleProductFilterChange = ({
    value,
    id,
    productFilter,
    dependentOn,
    currentFilter
  }) => {
    setFilterValues(prev => ({ ...prev, [id]: value }));

    const parentFilter = productFilter.find(item => item.key === dependentOn);

    const childFilter = productFilter.find(
      item => item.key === currentFilter.child_filter
    );

    // add 'Other' value to sku dropdown when brand is selected
    if (childFilter) {
      setFilterValues(prev => ({
        ...prev,
        [childFilter.label]: {
          label: 'Other',
          value: 9000000
        }
      }));
    }

    if (parentFilter && value.value !== 9000000) {
      setFilterValues(prev => ({
        ...prev,
        [parentFilter.label]: value.parentOption
      }));
    }
  };

  const handleSaveFacing = () => {
    const payload = [];
    let params = {};
    map(selectedCrops, crop => {
      if (crop.is_sku) {
        const fixes = {
          facing_id: crop.id
        };
        map(filterValues, (val, key) => {
          fixes[
            key
              .toLowerCase()
              .split(' ')
              .join('_')
          ] = val.value === 'select' ? -1 : val.value;
        });
        payload.push(fixes);
      }
      if (crop.is_sticker) {
        const fixes = {
          is_sku: false,
          ...(stickerPrice !== '' && { price: stickerPrice }),
          sticker_crop_id: crop.id
        };
        map(filterValues, (val, key) => {
          fixes[
            key
              .toLowerCase()
              .split(' ')
              .join('_')
          ] = val.value === 'select' ? -1 : val.value;
        });
        params = { ...fixes };
      }
      return null;
    });
    handleFixProductFacing({ payload, params });
  };

  useEffect(() => {
    splitImagesIntoSets();
  }, [list.images]);

  const splitImagesIntoSets = () => {
    const chunks = [];
    let i = 0;
    const n = list.images.length;
    while (i < n) {
      chunks.push(list.images.slice(i, (i += 10)));
    }

    setImagesSet(chunks);
  };

  const [getStageRef, setStageRef] = useDynamicRefs();

  const getFilterOptions = (parentFilter, dependentOn) => {
    const concernedFilter = parentFilter.find(item => item.key === dependentOn);
    const brandOption = filterValues[concernedFilter.label]
      ? concernedFilter.options.find(
          item => item.value === filterValues[concernedFilter.label].value
        )
      : { sku: [] };
    const optionsList = brandOption.sku.map(item => {
      return {
        ...item,
        parentOption: { label: brandOption.label, value: brandOption.value }
      };
    });
    return optionsList;
  };

  const isParentSelected = (parentFilter, dependentOn, filter) => {
    const concernedFilter = parentFilter.find(item => item.key === dependentOn);
    return (
      concernedFilter &&
      isEmpty(filterValues[concernedFilter.label]) &&
      filter.dependent_on
    );
  };

  const getSKUList = (parentFilter, dependentOn) => {
    const concernedFilter = parentFilter.find(item => item.key === dependentOn);
    const rawOptions = concernedFilter.options.reduce((optionsList, option) => {
      const modifiedSKUList = option.sku.map(item => {
        let skuOption = { ...item };
        skuOption.parentOption = { label: option.label, value: option.value };
        return skuOption;
      });
      optionsList.push(...modifiedSKUList);
      return optionsList;
    }, []);

    const optionsList = [
      ...new Map(rawOptions.map(item => [item['value'], item])).values()
    ];
    return optionsList;
  };

  const isFilterSelected = Object.keys(filterValues)
    .map(item => !isEmpty(filterValues[item]))
    .includes(true);
  return (
    <>
      <div
        ref={wrapperRef}
        className={classes.wrapper}
        style={{ display: 'flex' }}
        onScroll={resetImageToDefault}
      >
        {imagesSet.map((item, index) => {
          const shelfCanvasHeight = item[0].canvasHeight + 50;
          const shelfCanvasWidth = sumBy(item, x => x.canvasWidth);
          return (
            <Stage
              ref={setStageRef(item)}
              width={shelfCanvasWidth}
              height={shelfCanvasHeight}
              onWheel={e => handleStageMouseWheel(e, item, index)}
              draggable={activeControls.zoomIn}
            >
              <Layer
                onDragMove={() => {}}
                onDragEnd={() => {}}
                onMouseOver={() => throttledMouseEvents(true)}
                onMouseLeave={() => throttledMouseEvents(false)}
              >
                <StitchedImage
                  useDisplayTag={useDisplayTag}
                  colors={colors}
                  photoList={item}
                  activeImageSet={activeImageSet}
                  index={index}
                  shelfCanvasWidth={shelfCanvasWidth}
                  dashboardType={dashboardType}
                />
                <Crops
                  showStickers={showStickers}
                  stickerBoxes={stickerBoxes}
                  selectedCrops={selectedCrops}
                  handleCropClick={handleCropClickEvent}
                  handleCropHover={handleCropHoverEvent}
                  activeBoxes={activeBoxes}
                  imageList={item}
                />
              </Layer>
            </Stage>
          );
        })}
      </div>
      {/* view info */}
      <Draggable
        defaultPosition={
          dashboardType === 'v2' ? { x: -427, y: -639 } : { x: -213, y: -646 }
        }
      >
        <StyledDiv
          className={classes.draggable}
          bg={tooltipStyles?.backgroundColor}
        >
          {cropDetails.display && isCropInfoHandlerSelected && (
            <div className={classes.cropDetails}>
              <div>
                <Typography size={12} weight={400}>
                  {`Total crops selected ${
                    selectedCrops ? Object.keys(selectedCrops).length : 0
                  }`}
                </Typography>
              </div>
              {cropDetails.data.columns && (
                <>
                  <div className={classes.dropdown}>
                    {Object.keys(productFilter.data).map(item => {
                      return productFilter.data[item].map(filter => {
                        const isDisabled = filter.controlling_filter
                          .map(item => {
                            return !isEmpty(filterValues[item]);
                          })
                          .includes(true);
                        return (
                          <Dropdown
                            useMenuPortalTarget={false}
                            key={filter.label}
                            width={100}
                            title={filter.label}
                            dropdownStyles={dropdownStyles}
                            dropdownTitleStyles={dropdownTitleStyles}
                            handleValueChange={v =>
                              handleProductFilterChange({
                                value: v,
                                id: filter.label,
                                productFilter: productFilter.data[item],
                                dependentOn: filter.dependent_on,
                                currentFilter: filter
                              })
                            }
                            options={
                              isParentSelected(
                                productFilter.data[item],
                                filter.dependent_on,
                                filter
                              )
                                ? getSKUList(
                                    productFilter.data[item],
                                    filter.dependent_on
                                  )
                                : filter.dependent_on
                                ? getFilterOptions(
                                    productFilter.data[item],
                                    filter.dependent_on
                                  )
                                : [...filter.options]
                            }
                            isDisabled={
                              isDisabled ||
                              Object.keys(selectedCrops || {}).length < 1
                            }
                            value={
                              filterValues[filter.label]
                                ? filterValues[filter.label]
                                : {}
                            }
                          />
                        );
                      });
                    })}
                    <InputField
                      onChange={e => setStickerPrice(e.target.value)}
                      id="price"
                      value={stickerPrice}
                      type="text"
                      label="Price"
                      margin="normal"
                      colorTheme="dark"
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.actionButtons}>
                    <Button
                      size="sm"
                      onClick={handleSaveFacing}
                      disabled={
                        selectedCrops && isFilterSelected
                          ? Object.keys(selectedCrops).length < 1
                          : true
                      }
                      className={classes.saveButton}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => setFilterValues({})}
                      className={classes.saveButton}
                    >
                      Reset
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </StyledDiv>
      </Draggable>
      <Draggable defaultPosition={{ x: 412, y: -450 }}>
        <StyledDiv
          className={classes.draggable}
          bg={tooltipStyles?.backgroundColor}
          textColor={tooltipStyles?.textColor}
        >
          {cropDetails.display && isCropInfoHandlerSelected && (
            <div style={{ width: 300 }} className={classes.cropDetails}>
              {cropDetails.data.new_tooltip &&
                cropDetails.data.new_tooltip.map(({ key, value }) =>
                  key.toLowerCase() === 'pack shot' ? (
                    <div key={key} className={classes.item}>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${key}:`}</Typography>
                      <img src={value} alt="packshot" />
                    </div>
                  ) : key.toLowerCase() === 'image_url' ? (
                    <div key={key} className={classes.item}>
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        image_url
                      </a>
                    </div>
                  ) : (
                    <div key={key} className={classes.item}>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${key}:`}</Typography>
                      <Typography
                        size={14}
                        weight={400}
                      >{`${value}`}</Typography>
                    </div>
                  )
                )}

              <VSpacer amount={20} />
              {cropDetails.data.columns && (
                <>
                  {cropDetails.data.columns.map(col => {
                    if (
                      col.dataKey === 'упаковать фото' ||
                      col.dataKey === 'packshot'
                    ) {
                      return (
                        <div key={col.dataKey} className={classes.item}>
                          <Typography
                            size={14}
                            weight={400}
                          >{`${col.label}:`}</Typography>
                          <img
                            src={
                              typeof cropDetails.data.rowDetails[
                                col.dataKey
                              ] !== 'object'
                                ? cropDetails.data.rowDetails[col.dataKey] || ''
                                : ''
                            }
                            alt="packshot"
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={col.dataKey} className={classes.item}>
                        <Typography
                          size={14}
                          weight={400}
                        >{`${col.label}:`}</Typography>
                        <Typography size={14} weight={400}>
                          {typeof cropDetails.data.rowDetails[col.dataKey] !==
                          'object'
                            ? cropDetails.data.rowDetails[col.dataKey] || 'NA'
                            : 'NA'}
                        </Typography>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </StyledDiv>
      </Draggable>
    </>
  );
};

ShelfCanvas.propTypes = {
  colors: PropTypes.object.isRequired,
  useDisplayTag: PropTypes.bool.isRequired,
  showStickers: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  productFilter: PropTypes.object.isRequired,
  fixProductFacingState: PropTypes.object.isRequired,
  isCropInfoHandlerSelected: PropTypes.bool,
  activeBoxes: PropTypes.array.isRequired,
  stickerBoxes: PropTypes.array.isRequired,
  activeControls: PropTypes.object.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleFixProductFacing: PropTypes.func.isRequired,
  handleCropClick: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  reducerState: PropTypes.object.isRequired,
  wrapperRef: PropTypes.any.isRequired,
  dashboardType: PropTypes.string,
  tooltipStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object
};

ShelfCanvas.defaultProps = {
  isCropInfoHandlerSelected: false,
  dashboardType: '',
  tooltipStyles: {},
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

export default withStyles(styles)(ShelfCanvas);

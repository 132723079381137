import { map } from 'lodash';
import { eventChannel, END } from 'redux-saga';
import { put, take, takeEvery, call, fork } from 'redux-saga/effects';
import { adminTypes } from 'redux-utils/types/index';
import { formData, endpoints } from 'api/index';
import { actionTypeFormatter } from './index';

function createUploader(action) {
  const requestBody = new FormData();
  map(action.payload.params, (value, key) => {
    requestBody.append([key], value);
  });
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = formData({
    uriPath:
      action.payload.other.type === 'packshot' ||
      action.payload.other.type === 'promotionalStickers'
        ? endpoints.packshotUpload
        : endpoints.excelUpload,
    payload: requestBody,
    headers: ['auth', 'formData'],
    cb: uploadProgressCb
  });
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan, action) {
  console.log('upload progress 1', chan);
  while (true) {
    const progress = yield take(chan);
    console.log('upload progress 2');
    yield put({
      meta: { ...action.meta },
      type: actionTypeFormatter(action.type, 'PROGRESS'),
      payload: { progress }
    });
  }
}

function* uploadFiles(action) {
  let responseData = {};
  try {
    const [uploadPromise, chan] = yield call(createUploader, action);
    yield fork(uploadProgressWatcher, chan, action);
    // const {
    //   response: { data }
    // } = yield call(() => uploadPromise);
    responseData = yield call(() => uploadPromise);
    yield put({
      meta: { ...action.meta },
      type: actionTypeFormatter(action.type, 'SUCCESS'),
      payload: {
        response: responseData.response.data,
        request: { ...action.payload }
      }
    });
  } catch (error) {
    yield put({
      meta: { ...action.meta },
      type: actionTypeFormatter(action.type, 'FAILURE'),
      payload: {
        request: { ...action.payload },
        message: error,
        error: responseData.error.data
      }
    });
  }
}

/**
 * @name appWatcherSaga
 * @description Catches the action dispatched of certain type.
 */
const watcherSaga = [
  takeEvery(adminTypes.uploadPackshot.request, uploadFiles),
  takeEvery(adminTypes.uploadReferenceImages.request, uploadFiles),
  takeEvery(adminTypes.uploadExcel.request, uploadFiles)
];

export default watcherSaga;

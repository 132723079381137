import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  snackbar: {
    '& div[class*="MuiSnackbarContent"]': {
      backgroundColor: theme.palette.type === 'light' && 'white',
      color: theme.palette.type === 'light' && '#767676'
    }
  }
});

/**
 * @class
 * @hideconstructor
 * @description Notification bar stateless component
 */
const Notification = ({
  isOpen,
  handleClose,
  anchorOrigin,
  message,
  duration,
  classes
}) => (
  <Snackbar
    className={classes.snackbar}
    open={isOpen}
    anchorOrigin={anchorOrigin}
    autoHideDuration={duration}
    ContentProps={{
      'aria-describedby': 'message-id'
    }}
    onClose={handleClose}
    message={<span id="message-id">{message}</span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    ]}
  />
);

// component properties
Notification.propTypes = {
  /**
   * @type {object}
   * @description - Vertical and horizontal position of the snackbar
   */
  anchorOrigin: PropTypes.object,
  /**
   * @type {boolean}
   * @description - Duration of the snackbar in milliseconds
   */
  duration: PropTypes.number,
  /**
   * @type {boolean}
   * @description - To display or hide snackbar
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * @type {boolean}
   * @description - Callback function to handle Snackbar close
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * @type {boolean}
   * @description - Callback function to handle Snackbar close
   */
  message: PropTypes.string,
  classes: PropTypes.object.isRequired
};

Notification.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  message: '',
  duration: 6000
};

export default withStyles(styles)(Notification);

import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
// redux utils
import { connect } from 'react-redux';
import { appSelector } from 'redux-utils/selectors/index';
import { appActions, authActions } from 'redux-utils/actions/index';
// components
import Typography from 'components/Typography/Typography';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import Notification from 'components/Notification/Notification';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Loader from 'components/Loader/Loader';

import ls from 'lib/core/storageFactory';
import { setBaseUrl } from 'api/index';
import './index.css';

// assets
import infivizLogo from 'assets/svg/infiviz_logo.svg';
import mailIcon from 'assets/svg/mail_icon.svg';
import backIcon from 'assets/svg/login_back_icon.svg';
import lockIcon from 'assets/svg/lock_icon.svg';
const style = {
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 500,
    '&>h4:nth-of-type(1)': {
      width: 500
    }
  },
  header: {
    position: 'absolute',
    top: 10,
    right: 15,
    '& button': {
      height: 40,
      background: '#272a3d'
    }
  },
  inputFields: {
    width: 500,
    '& button': {
      height: 35
    }
  }
};

const ResetPassword = ({
  authState,
  classes,
  dispatchResetPassword,
  dashboardType
}) => {
  const [inputValues, setInputValues] = useState({
    newPassword: '',
    oldPassword: ''
  });

  const [notification, setNotification] = useState({
    display: false,
    message: ''
  });

  useEffect(() => {
    if (authState.apiStatus.resetPassword.error) {
      setNotification({
        display: true,
        message: authState.apiStatus.resetPassword.message
      });
    }
  }, [authState.apiStatus]);

  useEffect(() => {
    if (authState.resetPassword) {
      if (authState.resetPassword.message) {
        ls.clear();
        ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
        setBaseUrl(process.env.REACT_APP_SHELF_DOMAIN);
        window.location.replace('/login');
      }
    }
  }, [authState.resetPassword]);

  const handleInputChange = ({ value, field }) => {
    setInputValues(p => ({ ...p, [field]: value }));
  };

  const handleSubmit = () => {
    dispatchResetPassword({
      new_password: inputValues.newPassword.trim(),
      old_password: inputValues.oldPassword.trim()
    });
  };

  const handleBack = () => {
    window.location = '/app';
  };

  const handleNotificationClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({
      display: false,
      message: ''
    });
  };

  return (
    <div
      className={dashboardType === 'v2' ? 'loginWrapper' : classes.root}
      style={{
        backgroundColor: dashboardType === 'v2' ? '#dfe7f9' : 'inherit'
      }}
    >
      <Notification
        duration={3000}
        message={notification.message}
        isOpen={notification.display}
        handleClose={handleNotificationClose}
      />
      <div className={classes.header}>
        {dashboardType !== 'v2' && (
          <Button
            type="submit"
            onClick={handleBack}
            font="normal"
            color="gray"
            fit
            rect
          >
            <Typography color="rgba(255,255,255,0.8)" size={14} weight={500}>
              Back to Dashboard
            </Typography>
          </Button>
        )}
      </div>
      {dashboardType === 'v2' ? (
        <div className="loginContainerWrapper ">
          <div className="loginContainerHeader">
            <img src={infivizLogo} alt="infiviz_logo"></img>
          </div>

          <div className="loginContainerBody ">
            <div role="button" className="loginBackButton">
              <img src={backIcon} alt="backIcon" onClick={handleBack} />
            </div>

            <div className="forgotPassowrdHeadings">
              <h2>Change your password</h2>
            </div>

            <div className="inputRow">
              <label className="inputRowLable" for="username">
                Old Password
                <img src={lockIcon} alt="lock_icon"></img>
              </label>
              <div className="clientInputWrapper">
                <input
                  type="password"
                  className="inputRowComponent"
                  id="password"
                  placeholder="Type your password"
                  onChange={e =>
                    handleInputChange({
                      field: 'oldPassword',
                      value: e.target.value
                    })
                  }
                  value={inputValues.oldPassword}
                ></input>
              </div>
            </div>
            <div className="inputRow">
              <label className="inputRowLable" for="username">
                New Password
                <img src={lockIcon} alt="lock_icon"></img>
              </label>
              <div className="clientInputWrapper">
                <input
                  type="password"
                  className="inputRowComponent"
                  id="password"
                  placeholder="Type your password"
                  onChange={e =>
                    handleInputChange({
                      field: 'newPassword',
                      value: e.target.value
                    })
                  }
                  value={inputValues.newPassword}
                ></input>
              </div>
            </div>
            <button
              disabled={
                inputValues.newPassword.trim() === '' ||
                inputValues.oldPassword.trim() === ''
              }
              type="submit"
              onClick={handleSubmit}
              className="actionButton"
            >
              Reset
              {authState.apiStatus.resetPassword.request &&
                !authState.apiStatus.resetPassword.success &&
                !authState.apiStatus.resetPassword.error && (
                  <Loader
                    plain
                    simple
                    inline
                    circular
                    secondary
                    className="login-loader"
                  />
                )}
            </button>
          </div>
        </div>
      ) : (
        <>
          <Typography
            color={dashboardType === 'v2' ? '#424242' : 'rgba(255,255,255,0.8)'}
          >
            {' '}
            Change your password
          </Typography>
          <div className={classes.inputFields}>
            <TextField
              onChange={e =>
                handleInputChange({
                  field: 'oldPassword',
                  value: e.target.value
                })
              }
              id="old-password"
              type="password"
              label="Old Password"
              placeholder="Old Password"
              margin="normal"
              colorTheme="dark"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              onChange={e =>
                handleInputChange({
                  field: 'newPassword',
                  value: e.target.value
                })
              }
              id="new-password"
              type="password"
              label="New Password"
              placeholder="New Password"
              margin="normal"
              colorTheme="dark"
              fullWidth
              required
              variant="outlined"
            />
            <Button
              disabled={
                inputValues.newPassword.trim() === '' ||
                inputValues.oldPassword.trim() === ''
              }
              type="submit"
              onClick={handleSubmit}
              font="normal"
              color="rose"
              loader
              rect
            >
              <Typography>Reset</Typography>
              {authState.apiStatus.resetPassword.request &&
                !authState.apiStatus.resetPassword.success &&
                !authState.apiStatus.resetPassword.error && (
                  <Loader plain simple inline circular secondary />
                )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

/*
    Connect redux store state to props so that you can access the state
    from the scope of the component's props
  */
const mapStateToProps = state => ({
  authState: appSelector.getAuthState(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version
});

/*
      Connect dispatch methods to props so that you can call the methods
      from the scope of the component's props
    */
const mapDispatchToProps = dispatch => ({
  dispatchResetPassword: payload =>
    dispatch(authActions.resetPasswordRequest(payload))
});

ResetPassword.propTypes = {
  authState: object.isRequired,
  classes: object.isRequired,
  dispatchResetPassword: func.isRequired
};

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);

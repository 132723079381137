import React from 'react';
import { bool, string, func } from 'prop-types';

import Toggle from 'react-switch';

const ToggleComponent = ({ isChecked, label, handleChange, ...rest }) => {
  return (
    <div>
      <label>
        {label.length > 0 && <span>{label}</span>}
        <Toggle {...rest} onChange={handleChange} checked={isChecked} />
      </label>
    </div>
  );
};

ToggleComponent.propTypes = {
  isChecked: bool,
  label: string,
  handleChange: func
};

ToggleComponent.defaultProps = {
  isChecked: false,
  label: '',
  handleChange: () => {}
};

export default ToggleComponent;

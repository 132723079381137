import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import ReactInfiniteCalendar from 'components/DateRangePicker/ReactInfiniteCalendar';
import CustomDropdown from 'components/Dropdown/Dropdown';
// core functions
import {
  formatDate,
  getDaysBetweenRange,
  dateRangeString,
  dateString,
  addDays,
  getPreviousDate
} from 'lib/core/dateUtils';
import getStartEndDate from 'lib/utils/getStartEndDate';
import { dropDownStyles } from 'components/Dropdown/styles';

/**
 * @type {object}
 * @name TimeFilterStyle
 * @description Style object for the TimeFilter wrapper component.
 */
const style = theme => ({
  root: {
    // marginLeft: 'auto'
  },
  newRoot: {
    marginLeft: theme.palette.type === 'light' && '5px !important',
    marginBottom: theme.palette.type === 'light' && '20px'
  }
});

/**
 * @class
 * @hideconstructor
 * @name TimeFilter
 * @description An class component that is used to apply the time filter to the view.
 */
class TimeFilter extends React.Component {
  state = {
    tempDate: this.props.defaultDateRange.end,
    selected: this.props.value,
    range: this.props.defaultDateRange,
    isCalendarOpen: false
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.setState({ selected: nextProps.value });
    }
  }

  /**
   * @method
   * @param {number} days - total number of days
   * @description Calculates the filter time type based on the given number of days
   * @returns {string} type - filter time type
   */
  getTimeTypeFromDays = days => {
    if (days < 33) {
      return 'week';
    }
    if (days >= 33 && days < 90) {
      return 'month';
    }
    if (days >= 90 && days < 365) {
      return 'quarter';
    }
    if (days >= 365) {
      return 'year';
    }
    return 'day';
  };

  /**
   * @method
   * @param {object} ev - An event object containing the range of hovered dates
   * @description Sets the hovered date range to component state
   * @returns {undefined}
   */
  handleDateRangeHover = ev => {
    this.setState({ tempDate: ev });
  };

  /**
   * @method
   * @param {object} ev - An event object containing the range of hovered dates
   * @description Sets the hovered date range to component state
   * @returns {undefined}
   */
  handleDateSelect = ev => {
    this.setState({ tempDate: ev });
  };

  handleApply = () => {
    const { handleDateChange, options, useRange } = this.props;

    if (useRange) {
      this.handleCalendarClose('apply');
    } else {
      handleDateChange({
        fl_start_date: formatDate(this.state.tempDate),
        fl_end_date: addDays(1, 'days', this.state.tempDate),
        ...this.state.option,
        label: dateString(this.state.tempDate)
      });
      this.setState(prevState => ({
        isCalendarOpen: false,
        selected: {
          value: options[options.length - 1].value,
          label: dateString(prevState.tempDate),
          args: options[options.length - 1].args
        }
      }));
    }
  };

  /**
   * @method
   * @param {string} type - Cancel event type
   * @description Sets the hovered date range to component state and
   * pass the value to callback props. Also closes the Calendar.
   * @returns {undefined}
   */
  handleCalendarClose = type => {
    const { handleDateChange, options } = this.props;
    if (type === 'apply') {
      const daysInRange = getDaysBetweenRange(
        this.state.tempDate.start,
        this.state.tempDate.end
      );
      const timeType = this.getTimeTypeFromDays(daysInRange);
      handleDateChange({
        fl_start_date: formatDate(this.state.tempDate.start),
        fl_end_date: formatDate(this.state.tempDate.end),
        fl_time_type: timeType,
        ...this.state.option,
        label: dateRangeString(
          this.state.tempDate.start,
          this.state.tempDate.end
        )
      });
      this.setState(prevState => ({
        isCalendarOpen: false,
        range: {
          start: prevState.tempDate.start,
          end: prevState.tempDate.end
        },
        selected: {
          value: options[options.length - 1].value,
          label: dateRangeString(
            prevState.tempDate.start,
            prevState.tempDate.end
          ),
          args: options[options.length - 1].args
        }
      }));
      return;
    }
    this.setState({ isCalendarOpen: false });
  };

  /**
   * @method
   * @param {object} option - Selected dropdown option
   * @description Sets the option to component state and open/close calendar
   * if the custom date was selected
   * @returns {undefined}
   */
  handleDropdownClose = option => {
    if (option !== null) {
      if (option.value === 'custom') {
        this.setState({ isCalendarOpen: true, option });
      } else {
        if (this.state.selected.value !== option.value) {
          const dateRange = getStartEndDate(option.value);
          this.props.handleDateChange({ ...dateRange, ...option });
        }
        this.setState({
          selected: option,
          isCalendarOpen: false
        });
      }
    } else this.setState({ isCalendarOpen: false });
  };

  render() {
    const { selected, isCalendarOpen, range, tempDate } = this.state;
    const {
      options,
      classes,
      useRange,
      title,
      float,
      minDate,
      useMenuPortalTarget,
      routeID,
      type,
      parentComponentClass,
      minSelectableDate,
      maxSelectableDate,
      dropdownStyles: dpStyles,
      dropdownTitleStyles,
      dashboardType
    } = this.props;

    return (
      <div
        className={`${float === 'right' ? classes.root : ''} ${
          routeID === 'app_images' ? classes.newRoot : ''
        }`}
      >
        <CustomDropdown
          title={title}
          handleValueChange={this.handleDropdownClose}
          options={options}
          value={selected}
          useMenuPortalTarget={useMenuPortalTarget}
          width={
            useRange ? (selected ? (selected.args ? 240 : 150) : 160) : 160
          }
          routeID={routeID}
          type={type}
          dropdownStyles={dpStyles}
          dropdownTitleStyles={dropdownTitleStyles}
          dashboardType={dashboardType}
        />
        {isCalendarOpen ? (
          <ReactInfiniteCalendar
            minDate={minDate ? getPreviousDate(30, 'days', false) : null}
            minSelectableDate={minSelectableDate}
            maxSelectableDate={maxSelectableDate}
            useRange={useRange}
            usePortal={useMenuPortalTarget}
            parentComponentClass={parentComponentClass}
            handleApply={() => this.handleApply('apply')}
            handleCancel={() => this.handleCalendarClose('close')}
            handleDateRangeSelect={e => this.handleDateRangeHover(e)}
            handleDateSelect={e => this.handleDateSelect(e)}
            selected={useRange ? range : tempDate || new Date()}
            routeID={routeID}
          />
        ) : null}
      </div>
    );
  }
}

// component properties
TimeFilter.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @callback
   * @description Callback function to handle the date change
   */
  handleDateChange: PropTypes.func.isRequired,
  /**
   * @type {object}
   * @description Default selected date range
   */
  defaultDateRange: PropTypes.object.isRequired,
  /**
   * @type {string}
   * @description filter alignment
   */
  float: PropTypes.string,
  /**
   * @type {array}
   * @description Dropdown options
   */
  options: PropTypes.array.isRequired,
  /**
   * @type {object}
   * @description Value of the dropdown
   */
  value: PropTypes.object.isRequired,
  /**
   * @type {bool}
   * @description - Whether to use range picker
   */
  useRange: PropTypes.bool,
  /**
   * @type {string}
   * @description - Title of the dropdown
   */
  title: PropTypes.string,
  /**
   * @type {bool}
   * @description - Whether to use menuPortalTarget prop
   */
  useMenuPortalTarget: PropTypes.bool,
  parentComponentClass: PropTypes.string,
  minDate: PropTypes.bool,
  routeID: PropTypes.string,
  type: PropTypes.string,
  minSelectableDate: PropTypes.any,
  maxSelectableDate: PropTypes.any,
  dropdownStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object
};

TimeFilter.defaultProps = {
  useRange: true,
  title: ' ',
  float: 'right',
  minDate: false,
  useMenuPortalTarget: true,
  routeID: '',
  type: '',
  parentComponentClass: '',
  minSelectableDate: null,
  maxSelectableDate: null,
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

export default withStyles(style)(TimeFilter);

/* eslint-disable react/prop-types */
/* eslint-disable valid-jsdoc */
import React, { Component } from 'react';
// library to set component properties
import PropTypes from 'prop-types';
// redux library for react
import { connect } from 'react-redux';
// lodash wrapper for immutable.js
import { isEmpty, isEqual, map } from 'lodash';
// core library
import getStartEndDate from 'lib/utils/getStartEndDate';
import { getPreviousDate } from 'lib/core/dateUtils';
import { removeUndefined } from 'lib/core/object';
import { uniqBy } from 'lib/core/array';
// redux-utils
import { appSelector } from 'redux-utils/selectors';
// core components
import Filter from 'layout/Filter/Filter';
import { appActions } from 'redux-utils/actions';
import ls from 'lib/core/storageFactory';

const initialSelectedDate = {
  start: getPreviousDate(1, 'days', false),
  end: getPreviousDate(0, 'days', false)
};

const client = ls.get('client');

const optionAll = {
  value: 'all',
  label: client ? (client.includes('kcc-ru') ? 'Все' : 'All') : 'All'
};

/**
 * @class FilterContainer
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
class FilterContainer extends Component {
  state = {};

  componentWillMount() {
    const { dispatchGetFilterRequest, filterPreferences } = this.props;
    if (isEmpty(filterPreferences)) {
      dispatchGetFilterRequest();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.computeFilterValues(nextProps);
    }
  }

  /**
   * @method
   * @description Handles the date change event
   * @param {object} options - Changed object
   * @param {bool} val - is selected
   * @param {bool} key - Changed object key
   * @return {undefined}
   */
  handleToggleChange = ({ options, val, def: key }) => {
    const { handleFilterChange } = this.props;
    handleFilterChange({
      [key]: val ? options[1] : options[0]
    });
  };

  computeFilterValues = (nextProps = this.props) => {
    const {
      filterPreferences,
      filters,
      defaultFilters,
      handleFilterChange
    } = nextProps;
    // || !isEqual(filterPreferences, this.props.filterPreferences)
    if (
      isEmpty(filterPreferences) &&
      !isEmpty(filters) &&
      !isEmpty(defaultFilters)
    ) {
      const defaultItems = {};
      map(defaultFilters, def => {
        const { value: key } = def;
        if (key === 'fl_space') {
          const { fl_space: spaceData } = filters;
          const spaceTypeVal =
            filters.fl_space_type.options.filter(el => el.is_default === true)
              .length === 0
              ? filters.fl_space_type.options[0]
              : filters.fl_space_type.options.filter(
                  el => el.is_default === true
                )[0];
          const spaceTypeValueFilter = {
            options: [],
            value: {}
          };
          const appSpaceData = {
            channelFilter: {
              options: {}
            },
            customerFilter: {
              options: {}
            },
            spaceTypeFilter: {
              options: filters.fl_space_type.options.map(e => e),
              value: spaceTypeVal
            }
          };
          const { value: spaceTypeValueType } = spaceTypeVal;
          /* --------fill the space type value options with the channel's and customer's union [fl_space_type] data-------*/
          spaceData.fl_channel.forEach(element => {
            element[spaceTypeValueType].forEach(el => {
              spaceTypeValueFilter.options.push(el);
            });
          });
          spaceData.fl_customer.forEach(element => {
            element[spaceTypeValueType].forEach(el => {
              spaceTypeValueFilter.options.push(el);
            });
          });
          spaceTypeValueFilter.options = uniqBy(
            spaceTypeValueFilter.options,
            'value'
          );
          spaceTypeValueFilter.value =
            spaceTypeValueFilter.options.filter(el => el.is_default === true)
              .length === 0
              ? spaceTypeValueFilter.options[0]
              : spaceTypeValueFilter.options.filter(
                  el => el.is_default === true
                )[0];
          /* --------filter the customer based on the [fl_space_type] value-------*/
          appSpaceData.customerFilter.options = map(
            filters.fl_space[spaceTypeVal.value].fl_customer.filter(
              e =>
                e[spaceTypeVal.value].filter(
                  item => item.value === spaceTypeValueFilter.value.value
                ).length !== 0
            ),
            ({ value, label, is_default: isDefault }) => ({
              value,
              label,
              is_default: isDefault
            })
          );
          if (appSpaceData.spaceTypeFilter.value.value !== 'fl_store') {
            appSpaceData.customerFilter.options.unshift(optionAll);
          }
          appSpaceData.customerFilter.value =
            appSpaceData.customerFilter.options.filter(
              el => el.is_default === true
            ).length === 0
              ? appSpaceData.customerFilter.options[0]
              : appSpaceData.customerFilter.options.filter(
                  el => el.is_default === true
                )[0];
          /* --------filter the channel based on the [fl_space_type] value-------*/
          let allPosms = [{}];
          appSpaceData.channelFilter.options = map(
            filters.fl_space[spaceTypeVal.value].fl_channel.filter(
              e =>
                e[spaceTypeVal.value].filter(
                  item => item.value === spaceTypeValueFilter.value.value
                ).length !== 0
            ),
            ({ value, label, is_default: isDefault, fl_posm: flPosm }) => {
              if (flPosm) {
                allPosms = [...allPosms, ...flPosm];
              }
              return {
                fl_posm: flPosm,
                value,
                label,
                is_default: isDefault
              };
            }
          );
          if (appSpaceData.spaceTypeFilter.value.value !== 'fl_store') {
            appSpaceData.channelFilter.options.unshift({
              ...optionAll,
              fl_posm: uniqBy(allPosms, 'value')
            });
          }
          appSpaceData.channelFilter.value =
            appSpaceData.channelFilter.options.filter(
              el => el.is_default === true
            ).length === 0
              ? appSpaceData.channelFilter.options[0]
              : appSpaceData.channelFilter.options.filter(
                  el => el.is_default === true
                )[0];
          /* --------format the filter data and dispatch-------*/
          defaultItems.fl_channel = {
            options: [...appSpaceData.channelFilter.options],
            ...appSpaceData.channelFilter.value
          };
          defaultItems.fl_customer = {
            options: [...appSpaceData.customerFilter.options],
            ...appSpaceData.customerFilter.value
          };
          defaultItems.fl_space_type = {
            options: [...appSpaceData.spaceTypeFilter.options],
            ...appSpaceData.spaceTypeFilter.value
          };
          defaultItems[spaceTypeValueType] = {
            options: spaceTypeValueFilter.options,
            ...spaceTypeValueFilter.value
          };
        }
        if (filters[key]) {
          if (
            key !== 'fl_space' &&
            key !== 'fl_channel' &&
            key !== 'fl_customer'
          ) {
            const {
              options,
              use_value_dropdown: useValueDropDown,
              use_calendar: useCalendar
            } = filters[key];
            let value =
              options.filter(el => el.is_default === true).length === 0
                ? options[0]
                : options.filter(el => el.is_default === true)[0];
            let valueDropDownKey;
            let valueDropDownOptions = [];
            let valueDropDownValue;
            if (useValueDropDown) {
              const defaultItem =
                options.filter(el => el.is_default === true).length === 0
                  ? options[0]
                  : options.filter(el => el.is_default === true)[0];
              valueDropDownKey = defaultItem.value;
              valueDropDownOptions = filters[defaultItem.value].options;
              valueDropDownValue =
                valueDropDownOptions.filter(el => el.is_default === true)
                  .length === 0
                  ? valueDropDownOptions[0]
                  : valueDropDownOptions.filter(
                      el => el.is_default === true
                    )[0];
            }
            if (useCalendar) {
              const dateRange = getStartEndDate(value.value);
              value = { ...dateRange, ...value };
            }
            defaultItems[key] = value;
            defaultItems[valueDropDownKey] = valueDropDownValue;
          }
        }
      });
      handleFilterChange(removeUndefined(defaultItems));
    }
  };

  render() {
    const {
      filters,
      defaultFilters,
      filterPreferences,
      handleFilterChange,
      routeID,
      dashboardType
    } = this.props;
    return (
      <Filter
        handleFilterChange={handleFilterChange}
        handleSpaceChange={handleFilterChange}
        handleToggleChange={this.handleToggleChange}
        initialSelectedDate={initialSelectedDate}
        filters={filters}
        defaultFilters={defaultFilters}
        state={{ ...filterPreferences }}
        routeID={routeID}
        dashboardType={dashboardType}
      />
    );
  }
}

// component properties
FilterContainer.propTypes = {
  /**
   * @type {object}
   * @description dropdown filter data
   */
  filters: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description user preferences of the filter
   */
  filterPreferences: PropTypes.object,
  /**
   * @type {array}
   * @description dropdown filter data
   */
  defaultFilters: PropTypes.array,
  /**
   * @type {function}
   * @description Callback function to handle the filter change event
   */
  handleFilterChange: PropTypes.func.isRequired,
  routeID: PropTypes.string,
  dashboardType: PropTypes.string.isRequired
};

FilterContainer.defaultProps = {
  defaultFilters: [],
  filterPreferences: {},
  routeID: ''
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const getDefaultFilters = appSelector.makeGetDefaultFilters();
  const getFilterPreferences = appSelector.makeGetFilterPreferences();
  const getFilterList = appSelector.makeGetFilterList();
  const mapStateToProps = (state, props) => ({
    defaultFilters: getDefaultFilters(state, props),
    filterPreferences: getFilterPreferences(state, props),
    filters: getFilterList(state)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchGetFilterRequest: () => dispatch(appActions.getFilterListAction())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(FilterContainer);

import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import { DropzoneArea } from 'material-ui-dropzone';
import MuiButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/Button/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
  grayButton: {
    background: '#27293d',
    boxShadow: 'none !important',
    padding: '20px 60px !important',
    margin: '30px 10px',
    '&:hover': {
      background: '#ffffff33'
    },
    '&:focus': {
      background: '#ffffff33'
    },
    '& span[class*="MuiButton-label"]': {
      display: 'block'
    }
  },
  modalContainer: {
    '& div[class*="MuiDropzoneArea-root"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important'
    },
    '& div[class*="MuiDropzoneArea-textContainer"]': {
      '& p': {
        color: theme.palette.type === 'light' && '#767676 !important'
      }
    },
    '& div[class*="MuiDialogTitle"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important',
      color: theme.palette.type === 'light' && '#767676 !important',
      '& span': {
        color: theme.palette.type === 'light' && '#767676 !important'
      }
    },
    '& div[class*="MuiDialogContent"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important',
      color: theme.palette.type === 'light' && '#767676 !important',
      '& span': {
        color: theme.palette.type === 'light' && '#767676 !important'
      },
      '& button': {
        backgroundColor: theme.palette.type === 'light' && '#ffffff !important',
        color: theme.palette.type === 'light' && '#767676 !important'
      }
    },
    '& div[class*="MuiDialogActions"]': {
      backgroundColor: theme.palette.type === 'light' && '#f5f5f5 !important',
      color: theme.palette.type === 'light' && '#767676 !important',
      '& span': {
        color: theme.palette.type === 'light' && '#767676 !important'
      }
    }
  },
  largeText: {
    textTransform: 'capitalize !important',
    fontSize: '24px',
    display: 'block',
    '& svg': {
      fill: 'yellow'
    }
  },
  smallText: {
    textTransform: 'lowercase !important',
    fontSize: '14px'
  },
  dialogButton: {
    color: 'white',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cancelButton: {
    color: '#f36',
    marginRight: '20px'
  },
  disabledButton: {
    color: 'rgb(189 174 174 / 34%)',
    marginRight: '12px'
  },
  actionButtons: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#afa',
    marginRight: '12px'
  },
  warningText: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    border: '1px dashed rgb(189 174 174 / 34%)',
    padding: '10px',
    marginBottom: '5px',
    '& span': {
      fontSize: '12px'
    },
    '& svg': {
      fill: 'yellow'
    }
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiIconButton: {
      root: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#1e1e2b'
      }
    },
    MuiDropzoneArea: {
      root: {
        outline: 'none',
        backgroundColor: '#1e1e2b',
        minHeight: 130,
        border: '1px dashed',
        borderColor: 'rgb(189 174 174 / 34%)',
        width: '550px'
      },
      text: {
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.8)'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 8,
        paddingTop: 0,
        '&>button': {
          textTransform: 'capitalize'
        },
        '&>button:nth-of-type(1)': {
          color: '#f36'
        },
        '&>button:nth-of-type(2)': {
          color: '#afa'
        },
        '&>button:nth-of-type(2):disabled': {
          color: 'gray'
        }
      }
    },
    MuiDropzonePreviewList: {
      root: {
        marginTop: 10
      }
    },
    MuiChip: {
      root: {
        backgroundColor: '#52596b !important',
        color: 'rgba(255,255,255,0.8)'
      }
    }
  }
});

const ExcelUpload = ({ setOpen, handleSubmit, isOpen, classes }) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [snackBarState, setSnackBarState] = useState({
    isOpen: false,
    message: '',
    duration: 4000
  });
  const [isExcelUploadOpen, setIsExcelUploadOpen] = useState(false);
  const [isAppendData, setIsAppendData] = useState(false);

  const handleSnackBarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarState({
      isOpen: false,
      message: '',
      duration: 4000,
      handleClose: () => {}
    });
  };

  const handleOKButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
    handleSubmit(fileObjects, !isAppendData);
    closeDialog();
  };

  const handleCancelButton = (_e, reason) => {
    handleSnackBarClose(_e, reason);
  };

  const closeDialog = () => {
    setIsAppendData(false);
    setIsExcelUploadOpen(false);
    setFileObjects([]);
    setOpen(false);
  };
  // const dialogTitle = (
  //   <>
  //     <span
  //       style={{
  //         color: 'rgba(255, 255, 255, 0.8)',
  //         fontSize: 14,
  //         fontWeight: 500
  //       }}
  //     >
  //       Upload excel file
  //     </span>
  //     <IconButton
  //       style={{ right: '12px', top: '8px', position: 'absolute' }}
  //       onClick={() => closeDialog()}
  //     >
  //       <CloseIcon />
  //     </IconButton>
  //   </>
  // );

  const handleAppendClick = () => {
    setIsExcelUploadOpen(true);
    setIsAppendData(true);
  };

  const handleReplaceClick = () => {
    setIsExcelUploadOpen(true);
  };

  const handleCancelDialog = () => {
    setIsExcelUploadOpen(false);
    setIsAppendData(false);
    handleSnackBarClose();
  };

  const handleSubmitDialog = () => {
    if (fileObjects.length === 0) {
      return;
    }
    if (isAppendData) {
      handleOKButton();
    }
    if (!isAppendData) {
      setSnackBarState({
        isOpen: true,
        message: `This will replace the existing data in the table with the new data in the uploaded excel file. Are you sure, you want to do this?`,
        severity: 'warning'
      });
    }
  };

  return (
    <>
      <Snackbar
        open={snackBarState.isOpen}
        autoHideDuration={snackBarState.duration}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          action={
            <>
              <MuiButton color="inherit" size="small" onClick={handleOKButton}>
                OK
              </MuiButton>
              <MuiButton
                color="inherit"
                size="small"
                onClick={handleCancelButton}
              >
                Cancel
              </MuiButton>
            </>
          }
          onClose={handleSnackBarClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <MuiThemeProvider theme={theme}>
        <div>
          <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
            className={classes.modalContainer}
          >
            <DialogTitle id="responsive-dialog-title">
              <span
                style={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  fontSize: 14,
                  fontWeight: 500
                }}
              >
                {isAppendData
                  ? 'Append to the existing data'
                  : isExcelUploadOpen && !isAppendData
                  ? 'Replace the existing data'
                  : 'Upload excel file'}
              </span>
              <IconButton
                style={{ right: '12px', top: '8px', position: 'absolute' }}
                onClick={() => closeDialog()}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {!isExcelUploadOpen ? (
                <>
                  <Button
                    className={classes.grayButton}
                    onClick={handleAppendClick}
                  >
                    <span className={classes.largeText}>Append</span>
                    <span className={classes.smallText}>
                      to the existing data
                    </span>
                  </Button>
                  <Tooltip title="This will remove the existing data and replace it with the data in the uploaded file!">
                    <div style={{ display: 'inline-block' }}>
                      <Button
                        className={classes.grayButton}
                        onClick={handleReplaceClick}
                      >
                        <span className={classes.largeText}>
                          <WarningIcon />
                          Replace
                        </span>
                        <span className={classes.smallText}>
                          the existing data
                        </span>
                      </Button>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  {!isAppendData ? (
                    <div className={classes.warningText}>
                      <WarningIcon />
                      <span>
                        This will replace the existing data in the table with
                        the new data in the uploaded excel file
                      </span>
                    </div>
                  ) : null}
                  <DropzoneArea
                    filesLimit={1}
                    showAlerts={['error']}
                    previewText=""
                    acceptedFiles={[
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel'
                    ]}
                    fileObjects={fileObjects}
                    maxFileSize={50000000000}
                    onAdd={newFile => {
                      setFileObjects(newFile);
                    }}
                    onDrop={newFile => {
                      setFileObjects(newFile);
                    }}
                    onDelete={() => {
                      setFileObjects([]);
                    }}
                    useChipsForPreview
                    showPreviews
                    showFileNamesInPreview
                    showPreviewsInDropzone={false}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              {isExcelUploadOpen ? (
                <>
                  <span
                    onClick={handleCancelDialog}
                    className={`${classes.dialogButton} ${classes.cancelButton} ${classes.actionButtons}`}
                  >
                    Cancel
                  </span>
                  <span
                    onClick={handleSubmitDialog}
                    className={`${classes.dialogButton} ${
                      classes.actionButtons
                    } ${
                      fileObjects.length > 0
                        ? classes.submitButton
                        : classes.disabledButton
                    }`}
                  >
                    Submit
                  </span>
                </>
              ) : null}
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
      {/* <MuiThemeProvider theme={theme}>
        <DropzoneDialogBase
          filesLimit={1}
          dialogTitle={dialogTitle()}
          showAlerts={['error']}
          previewText=""
          acceptedFiles={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel'
          ]}
          fileObjects={fileObjects}
          cancelButtonText="cancel"
          submitButtonText="submit"
          maxFileSize={50000000000}
          open={isOpen}
          onAdd={newFile => {
            setFileObjects(newFile);
          }}
          onDelete={() => {
            setFileObjects([]);
          }}
          onClose={() => {
            closeDialog();
            handleSnackBarClose();
          }}
          onSave={() => {
            setSnackBarState({
              isOpen: true,
              message: `This will create a new version with the current data. Are you sure?`,
              severity: 'info'
            });
          }}
          useChipsForPreview
          showPreviews
          showFileNamesInPreview
        />
      </MuiThemeProvider> */}
    </>
  );
};

ExcelUpload.propTypes = {
  isOpen: bool,
  setOpen: func,
  handleSubmit: func,
  classes: object.isRequired
};

ExcelUpload.defaultProps = {
  isOpen: false,
  setOpen: () => {},
  handleSubmit: () => {}
};

export default withStyles(styles)(ExcelUpload);

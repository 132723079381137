import { createSelector } from '@reduxjs/toolkit';
import { getImageFetchingStatus } from './storeExplorerSelector';
// store selectors
const getStoreExplorerStore = state => state.SE;

const selectApiRequestStatus = createSelector(
  [getStoreExplorerStore],
  ({ apiRequestStatus }) => apiRequestStatus
);

const selectLoadingStatus = createSelector(
  [getImageFetchingStatus, selectApiRequestStatus],
  (fetchingStatus, apiStatus) => {
    return fetchingStatus || apiStatus.filters.request;
  }
);

const selectFilters = createSelector(
  getStoreExplorerStore,
  ({ filters }) => filters.list
);

const getFilterPreferences = (state, props) =>
  state.storeExplorer.preferences.filters[props.routeID];

const makeGetFilterPreferences = () =>
  createSelector([getFilterPreferences], prefs => prefs);

export {
  selectApiRequestStatus,
  selectFilters,
  selectLoadingStatus,
  makeGetFilterPreferences
};

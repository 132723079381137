/* eslint-disable react/prop-types */
import React from 'react';
// library to set component properties
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
// core
import ls from 'lib/core/storageFactory';
// charts
import BarChartComponent from 'charts/bar/BarChartComponent';
import CountComponent from 'charts/count/CountComponent';
import LineChartComponent from 'charts/line/LineChartComponent';
import PieChartComponent from 'charts/pie/PieChartComponent';
import RegionMapComponent from 'maps/RegionMapComponent';
import RadarChartComponent from 'charts/radar/RadarChartComponent';
import StatsComponent from 'charts/stats/StatsComponent';
// tables
import TableWithLineComponent from 'table/TableWithLineWrapper';
import TableComponent from 'table/TableWrapper';
// component styles
import dashboardStyle from 'assets/jss/material-ui/views/dashboardViewStyle';

const client = ls.get('client');

const ChartRenderer = ({
  classes,
  chartList,
  chartDataList: chartData,
  granularities,
  slicers,
  handleGranularityChange,
  handleSlicerChange,
  handlePinClick,
  handleDataDownload,
  cardStyles,
  dashboardPanelStyles,
  dashboardType
}) => {
  let chartsWithDataCount = 0;
  const chartDataList = {};
  map(chartList, el => {
    if (chartData) {
      if (chartData[el.name]) {
        if (el.type !== 'stats' && el.type !== 'count') {
          if (
            !isEmpty(granularities[el.name]) ||
            (slicers && !isEmpty(slicers[el.name])) ||
            chartData[el.name].datasets.length > 0
          ) {
            chartDataList[el.name] = { ...chartData[el.name] };
            chartDataList[el.name].display = true;
          }
          if (chartData[el.name].datasets.length > 0) {
            chartsWithDataCount += 1;
          }
        } else {
          chartDataList[el.name] = { ...chartData[el.name] };
          chartDataList[el.name].display = true;
        }
      }
    }
  });

  return chartsWithDataCount > 0 ? (
    <GridContainer
      classNames={classes.gridContainer}
      style={{ marginBottom: '200px', width: '100%' }}
    >
      {chartDataList !== undefined &&
        chartList.map(chart =>
          (chart.type === 'pie' ||
            chart.type === 'piefull' ||
            chart.type === 'pie_half') &&
          chartDataList[chart.name] ? (
            <GridItem
              key={chart.name}
              xs={12}
              sm={12}
              md={
                chart.type === 'piefull'
                  ? 12
                  : chart.type === 'pie_half'
                  ? 4
                  : 6
              }
            >
              <PieChartComponent
                chartName={chart.name}
                chartType={chart.type}
                classes={classes}
                data={chartDataList[chart.name]}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                handlePinClick={handlePinClick}
                isPinned={chart.is_pinned}
                title={chartDataList[chart.name].title}
                useExport={chart.excel_report}
                cardStyles={cardStyles}
              />
            </GridItem>
          ) : (chart.type === 'bar' || chart.type === 'barfull') &&
            chartDataList[chart.name] ? (
            <GridItem
              key={chart.name}
              xs={12}
              sm={12}
              md={chart.type === 'barfull' ? 12 : 6}
            >
              <BarChartComponent
                classes={classes}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                chartName={chart.name}
                title={chartDataList[chart.name].title}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                cardStyles={cardStyles}
              />
            </GridItem>
          ) : chart.type === 'line' && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={12}>
              <LineChartComponent
                classes={classes}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                chartName={chart.name}
                title={chartDataList[chart.name].title}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                cardStyles={cardStyles}
              />
            </GridItem>
          ) : chart.type.includes('map') && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={12}>
              <RegionMapComponent
                classesList={classes}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                chartName={chart.name}
                chartType={chart.type}
                title={chartDataList[chart.name].title}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                cardStyles={cardStyles}
              />
            </GridItem>
          ) : chart.type === 'radar' && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={6}>
              <RadarChartComponent
                classes={classes}
                granularities={{}}
                chartName={chart.name}
                title={chartDataList[chart.name].title}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                cardStyles={cardStyles}
              />
            </GridItem>
          ) : chart.type === 'tableWithLine' && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={6}>
              <TableWithLineComponent
                classesList={classes}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                chartName={chart.name}
                title={chartDataList[chart.name].title}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
              />
            </GridItem>
          ) : (chart.type === 'table' || chart.type === 'tablefull') &&
            chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={12}>
              <TableComponent
                type={chart.type}
                classesList={classes}
                granularities={granularities[chart.name]}
                slicers={slicers[chart.name]}
                title={chartDataList[chart.name].title}
                chartName={chart.name}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                height={
                  // dashboardType === 'v2'
                  //   ? '500px'
                  //   :
                  chartDataList[chart.name].height
                }
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                handleGranularityClick={handleGranularityChange}
                handleSlicerChange={handleSlicerChange}
                cardStyles={cardStyles}
                dashboardPanelStyles={dashboardPanelStyles}
              />
            </GridItem>
          ) : chart.type === 'stats' && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={6}>
              <StatsComponent
                title={chartDataList[chart.name].title}
                chartName={chart.name}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                classes={classes}
              />
            </GridItem>
          ) : chart.type === 'count' && chartDataList[chart.name] ? (
            <GridItem key={chart.name} xs={12} sm={12} md={6}>
              <CountComponent
                title={chartDataList[chart.name].title}
                chartName={chart.name}
                data={chartDataList[chart.name]}
                isPinned={chart.is_pinned}
                useExport={chart.excel_report}
                handlePinClick={handlePinClick}
                handleDataDownload={() => {
                  handleDataDownload({ chart });
                }}
                classes={classes}
              />
            </GridItem>
          ) : null
        )}
    </GridContainer>
  ) : (
    <div style={{ minHeight: '300px' }}>
      <VSpacer amount={30} />
      <Typography align="center">
        {client.includes('kcc-ru')
          ? 'Для выбранных фильтров данные отсутствуют'
          : 'No data available for the selected filters'}
      </Typography>
    </div>
  );
};

// component properties
ChartRenderer.propTypes = {
  classes: PropTypes.object.isRequired,
  chartDataList: PropTypes.object,
  granularities: PropTypes.object,
  slicers: PropTypes.object,
  chartList: PropTypes.array,
  handleGranularityChange: PropTypes.func,
  handleSlicerChange: PropTypes.func,
  handlePinClick: PropTypes.func,
  handleDataDownload: PropTypes.func,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object,
  dashboardType: PropTypes.string
};

ChartRenderer.defaultProps = {
  chartDataList: {},
  chartList: [],
  granularities: {},
  handleGranularityChange: () => {},
  handleSlicerChange: () => {},
  handlePinClick: () => {},
  handleDataDownload: () => {},
  slicers: {},
  cardStyles: {},
  dashboardPanelStyles: {},
  dashboardType: ''
};

export default withStyles(dashboardStyle, { withTheme: true })(ChartRenderer);

/* eslint-disable no-param-reassign */
import { includes } from 'lodash';
import axiosCacheInstance, { axiosInstance } from 'api/axios';
import ls from 'lib/core/storageFactory';
import endpoints from 'api/endpoints';

if (ls.get('baseURL') === null || ls.get('baseURL') === undefined) {
  ls.set('baseURL', process.env.REACT_APP_SHELF_DOMAIN);
}

let BASE_URL = ls.get('baseURL');
const setBaseUrl = url => {
  BASE_URL = url;
};

const get = ({
  uriPath,
  headers = [],
  payload = {},
  useCachedInstance = true,
  cancelToken = false,
  useBaseURL = true
}) => {
  const config = { headers: {} };
  if (includes(headers, 'auth')) {
    config.headers.Authorization = `token ${ls.get('authToken')}`;
  }
  if (cancelToken) {
    config.cancelToken = cancelToken;
  }
  config.params = payload;
  if (useCachedInstance) {
    return axiosCacheInstance
      .get(useBaseURL ? BASE_URL + uriPath : uriPath, config)
      .then(response => ({ params: payload, response }))
      .catch(error => ({ params: payload, error }));
  }
  return axiosInstance
    .get(useBaseURL ? BASE_URL + uriPath : uriPath, config)
    .then(response => ({ params: payload, response }))
    .catch(error => ({ params: payload, error }));
};

const post = ({
  uriPath,
  headers = [],
  payload = {},
  cb,
  useBaseURL = true
}) => {
  const config = { headers: {} };
  if (includes(headers, 'auth')) {
    config.headers.Authorization = `token ${ls.get('authToken')}`;
  }
  if (cb) {
    config.onUploadProgress = cb;
  }
  return axiosInstance
    .post(useBaseURL ? BASE_URL + uriPath : uriPath, payload, config)
    .then(response => ({ params: payload, response }))
    .catch(error => ({ params: payload, error }));
};

const putRequest = ({
  uriPath,
  headers = [],
  payload = {},
  cb,
  useBaseURL = true
}) => {
  const config = { headers: {} };
  if (includes(headers, 'auth')) {
    config.headers.Authorization = `token ${ls.get('authToken')}`;
  }
  if (cb) {
    config.onUploadProgress = cb;
  }
  return axiosInstance
    .put(useBaseURL ? BASE_URL + uriPath : uriPath, payload, config)
    .then(response => ({ params: payload, response }))
    .catch(error => ({ params: payload, error }));
};

const deleteRequest = ({
  uriPath,
  headers = [],
  payload = {},
  cb,
  useBaseURL = true
}) => {
  const config = { headers: {} };
  if (includes(headers, 'auth')) {
    config.headers.Authorization = `token ${ls.get('authToken')}`;
  }
  if (cb) {
    config.onUploadProgress = cb;
  }
  config.data = payload;
  return axiosInstance
    .delete(useBaseURL ? BASE_URL + uriPath : uriPath, config)
    .then(response => ({ params: payload, response }))
    .catch(error => ({ params: payload, error }));
};

const formData = ({ uriPath, headers = [], payload = {}, cb }) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  if (includes(headers, 'auth')) {
    config.headers.Authorization = `token ${ls.get('authToken')}`;
  }
  if (cb) {
    config.onUploadProgress = cb;
  }
  return axiosInstance
    .post(BASE_URL + uriPath, payload, config)
    .then(response => ({ params: payload, response }))
    .catch(error => ({ params: payload, error }));
};

export {
  deleteRequest,
  get,
  post,
  putRequest,
  formData,
  setBaseUrl,
  endpoints
};

import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
// redux-utils
import {
  crashReporterMiddleware,
  createCacheMiddleware
} from 'redux-utils/middlewares';
import { cacheTypes } from 'redux-utils/types';
import rootReducer from 'redux-utils/reducers';
// root saga
import rootSaga from 'redux-utils/sagas';

const logger = createLogger({
  predicate: (_getState, action) =>
    !action.type.includes('@@saga-network-status')
});
const sagaMiddleware = createSagaMiddleware();
const cacheMiddleware = createCacheMiddleware(cacheTypes.cacheable);

const middleware = [crashReporterMiddleware, cacheMiddleware, sagaMiddleware];

if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV === 'local') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

sagaMiddleware.run(rootSaga);

export default store;

// @TODO
// on master branch merge change middleware to
// const middleware = process.env.NODE_ENV !== 'production'
//   ? [cacheMiddleware, sagaMiddleware]
//   : [logger, cacheMiddleware, sagaMiddleware];

import { takeLatest, call, put } from 'redux-saga/effects';
// action types
import { storeExplorerTypes, cacheTypes } from 'redux-utils/types/index';
import { get, post } from 'api';

/**
 * @name storeExplorerWatcherSaga
 * @description Catches the action dispatched of certain type.
 */
const watcherSaga = [
  takeLatest(storeExplorerTypes.getShelfImage.fromAPI, shelfImagesSaga),
  takeLatest(storeExplorerTypes.getStoreExplorerFilter.fromAPI, filterSaga),
  takeLatest(storeExplorerTypes.getDebugProblems.fromAPI, debugProblemsSaga),
  takeLatest(storeExplorerTypes.saveDashboardDebug.fromAPI, saveDebugSaga),
  takeLatest(storeExplorerTypes.getDashboardDebug.fromAPI, saveDebugSaga),
  takeLatest(
    storeExplorerTypes.updateProductFacing.fromAPI,
    updateProductFacingSaga
  ),
  takeLatest(storeExplorerTypes.fixProductFacing.fromAPI, fixProductFacingSaga),
  takeLatest(storeExplorerTypes.productFilter.fromAPI, productFilterSaga),
  takeLatest(storeExplorerTypes.getCurationStores.fromAPI, curationStoresSaga),
  takeLatest(
    storeExplorerTypes.getCurationSessions.fromAPI,
    curationSessionsSaga
  )
];

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* productFilterSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_PRODUCT_FILTER,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.productFilter.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* fixProductFacingSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_FIX_PRODUCT_FACING,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  try {
    if (response) {
      const { data } = response;
      yield put({
        ...action,
        type: cacheTypes.store.request,
        payload: {
          ...action.payload,
          response: data
        }
      });
    } else {
      yield put({
        ...action,
        type: storeExplorerTypes.fixProductFacing.failure,
        payload: { message: error.data },
        error: true
      });
    }
  } catch (er) {
    yield put({
      ...action,
      type: storeExplorerTypes.fixProductFacing.failure,
      payload: { message: er },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* updateProductFacingSaga(action) {
  const { response, error } = yield call(post, {
    uriPath: process.env.REACT_APP_SHELF_UPDATE_PRODUCT_FACING,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.updateProductFacing.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* debugProblemsSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_TEST_STORE_EXPLORER_DEBUG_PROBLEMS,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.getDebugProblems.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* saveDebugSaga(action) {
  let method = post;
  if (action.type === storeExplorerTypes.getDashboardDebug.fromAPI) {
    method = get;
  }
  const { response, error } = yield call(method, {
    uriPath: process.env.REACT_APP_TEST_STORE_EXPLORER_DASHBOARD_DEBUG,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type:
        action.type === storeExplorerTypes.getDashboardDebug.fromAPI
          ? storeExplorerTypes.getDashboardDebug.failure
          : storeExplorerTypes.saveDashboardDebug.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* shelfImagesSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_STORE_IMAGES,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.getShelfImage.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the curation store list.
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get curation store list success/error action.
 */
function* curationStoresSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CURATION_STORES,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.getShelfImage.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the curation store list.
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get curation store list success/error action.
 */
function* filterSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_STORE_EXPLORER_FILTER,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.getStoreExplorerFilter.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the curation store list.
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get curation store list success/error action.
 */
function* curationSessionsSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CURATION_SESSIONS,
    payload: action.payload.params,
    headers: ['auth'],
    useCachedInstance: false
  });
  if (response) {
    const { data } = response;
    yield put({
      ...action,
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      ...action,
      type: storeExplorerTypes.getCurationSessions.failure,
      payload: { message: error.data },
      error: true
    });
  }
}

export default watcherSaga;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import MuiTypography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
// core components
import CustomTableCell from 'table/CustomCell';
import Typography from 'components/Typography/Typography';
import Button from 'components/Button/Button';
import { VSpacer, HSpacer } from 'components/Spacer/Spacer';
// styles
import tableStyles from 'assets/jss/material-ui/components/accuracyTableStyle';

const styles = {
  value: {
    width: '50px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& h4': {
      fontSize: '12px'
    }
  },
  currentValue: {
    border: '2px solid #797983',
    color: '#797983'
  },
  previousValue: {
    border: '2px solid #488b97',
    color: '#488b97'
  }
};

const HtmlTooltip = withStyles(() => ({
  popper: {
    zIndex: 2,
    top: '-150px !important'
  },
  tooltip: {
    backgroundColor: '#eaeaea',
    color: 'black',
    borderRadius: '10px',
    '& h4': {
      fontSize: '12px'
    },
    padding: '0px',
    maxWidth: 860,
    width: 'auto',
    height: 'auto',
    '& div[class*="p20"]': {
      paddingLeft: '20px',
      paddingRight: '5px',
      '& > div': {
        marginRight: '15px'
      }
    },
    '& h4[class*="valueCount"]': {
      display: 'inline'
    },
    '& div[class*="closeButtonContainer"]': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        width: '22px',
        height: '22px',
        minWidth: '22px'
      }
    },
    '& div[class*="previousVersionShapeColor"]': {
      backgroundColor: '#a3c6ca !important'
    },
    '& div[class*="previousVersionColor"]': {
      backgroundColor: '#c8dcdd !important'
    },
    '& div[class*="svg"]': {
      '& svg': {
        fill: '#fa989d',
        fontSize: '1.2rem'
      }
    },
    '& div[class*="nameContainer"]': {
      display: 'flex',
      justifyContent: 'flex-start',
      maxWidth: '210px',
      '& p': {
        wordBreak: 'break-word'
      }
    },
    '& div[class*="tooltipRow"]': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#eaeaea'
    },
    '& div[class*="trapezoid"]': {
      borderBottom: '35px solid #d7d7d7',
      borderLeft: '25px solid transparent',
      height: '0px',
      width: '150px',
      position: 'relative'
    },
    '& div[class*="previousTrapezoid"]': {
      backgroundColor: '#c8dcdd',
      borderBottom: '35px solid #a3c6ca',
      borderLeft: '25px solid transparent',
      height: '0px',
      width: '150px',
      position: 'relative'
    },
    '& div[class*="layerTitle"]': {
      backgroundColor: '#d7d7d7',
      height: '30px',
      width: '120px',
      position: 'absolute',
      top: '-20px',
      left: '30%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      paddingBottom: '5px',
      '& svg': {
        fill: '#fa989d',
        fontSize: '1.2rem'
      }
    }
  }
}))(Tooltip);

const InfoTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#eaeaea',
    borderRadius: '5px',
    '& h4': {
      fontSize: '12px',
      whiteSpace: 'break-spaces',
      color: 'black !important'
    },
    maxWidth: '200px',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.3)'
  }
}))(Tooltip);

const CustomTable = ({
  classes,
  data,
  enablePreviousVersion,
  height,
  setTableTooltipStatus,
  tableTooltipStatus,
  chartName
}) => {
  const [open, setOpen] = useState(false);
  const [openInfoTooltip, setOpenInfoTooltip] = useState({
    status: false,
    label: ''
  });
  const [tooltipKey, setTooltipKey] = useState(null);

  const handleTooltipClose = event => {
    if (event.target.id.includes('tooltipCol')) {
      return;
    }
    setOpen(false);
    setTooltipKey(null);
  };

  const handleTooltipOpen = (tKey, enableConfusionMatrix) => {
    if (!enableConfusionMatrix) {
      return;
    }
    setOpen(true);
    setTooltipKey(tKey);
  };

  const handleInfoTooltipClose = event => {
    if (event.target.id.includes('infoIcon')) {
      return;
    }
    setOpenInfoTooltip({
      status: false,
      label: ''
    });
  };

  const handleInfoTooltipOpen = iconLabel => {
    setOpenInfoTooltip({
      status: true,
      label: iconLabel
    });
  };

  useEffect(() => {
    if (
      tableTooltipStatus.status === true &&
      tableTooltipStatus.key !== chartName
    ) {
      setOpen(false);
      setTooltipKey(null);
    }
  }, [tableTooltipStatus]);

  return (
    <Paper className={classes.root} style={{ height }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {data.columns.map(col => {
              if (
                col.type === 'confusion_matrix_button' ||
                col.type === 'icon'
              ) {
                return (
                  <CustomTableCell key={col.dataKey}>
                    <div style={{ width: '200px', textAlign: 'center' }}>
                      {col.label}
                    </div>
                  </CustomTableCell>
                );
              }
              return (
                <CustomTableCell key={col.dataKey}>{col.label}</CustomTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.datasets.map((row, i) => {
            return (
              <TableRow className={`${classes.row}`} key={i}>
                {data.columns.map(col => {
                  if (col.type === 'confusion_matrix_button') {
                    return (
                      <CustomTableCell key={col.dataKey}>
                        <div style={{ display: 'inline-block' }}>
                          <HtmlTooltip
                            PopperProps={{
                              disablePortal: true
                            }}
                            onClose={handleTooltipClose}
                            onOpen={handleTooltipOpen}
                            open={i === tooltipKey && open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            interactive
                            title={
                              <ClickAwayListener
                                onClickAway={handleTooltipClose}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                  }}
                                >
                                  <div style={{ marginLeft: '20px' }}>
                                    <VSpacer amount={20} />
                                    <div className="closeButtonContainer">
                                      <Button
                                        color="dark"
                                        round
                                        justIcon
                                        onClick={handleTooltipClose}
                                      >
                                        <CloseIcon />
                                      </Button>
                                      <div style={{ margin: 'auto' }}>
                                        <Typography
                                          color="#646464"
                                          align="center"
                                          weight={700}
                                        >
                                          Current AI Version
                                        </Typography>
                                      </div>
                                    </div>

                                    <VSpacer amount={10} />
                                    <div className="tooltipRow">
                                      <div className="nameContainer">
                                        <Typography color="black">
                                          {data.columns[0].label} -
                                        </Typography>
                                        <HSpacer amount={5} />
                                        <MuiTypography
                                          style={{
                                            fontSize: '12px',
                                            color: '#646464',
                                            fontWeight: 700,
                                            lineHeight: 'inherit'
                                          }}
                                          // noWrap
                                        >
                                          {row[data.columns[0].dataKey]}
                                        </MuiTypography>
                                      </div>

                                      <div className="trapezoid">
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '12px'
                                          }}
                                        >
                                          {data.columns[1].label} -{' '}
                                          {row[col.dataKey].current}
                                        </div>
                                      </div>
                                    </div>
                                    <VSpacer amount={25} />
                                    {row[col.dataKey].current_confusion_matrix
                                      .layer1 && (
                                      <div
                                        className="tooltipRow svg"
                                        style={{ marginRight: '20px' }}
                                      >
                                        {row[
                                          col.dataKey
                                        ].current_confusion_matrix.layer1.map(
                                          layer1 => (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'baseline',
                                                paddingRight: '12px'
                                              }}
                                            >
                                              <Typography color="black">
                                                {layer1.label} -
                                              </Typography>
                                              <HSpacer amount={5} />
                                              <Typography
                                                color="#646464"
                                                weight={700}
                                              >
                                                {layer1.value}
                                              </Typography>
                                              <InfoTooltip
                                                PopperProps={{
                                                  disablePortal: true
                                                }}
                                                interactive
                                                open={
                                                  layer1.description !== '' &&
                                                  openInfoTooltip.status &&
                                                  openInfoTooltip.label ===
                                                    `${layer1.label}current`
                                                }
                                                title={
                                                  <ClickAwayListener
                                                    onClickAway={
                                                      handleInfoTooltipClose
                                                    }
                                                  >
                                                    <div>
                                                      <Typography>
                                                        {layer1.description}
                                                      </Typography>
                                                    </div>
                                                  </ClickAwayListener>
                                                }
                                              >
                                                <InfoOutlinedIcon
                                                  id="infoIcon"
                                                  onClick={() =>
                                                    handleInfoTooltipOpen(
                                                      `${layer1.label}current`
                                                    )
                                                  }
                                                />
                                              </InfoTooltip>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                    {row[col.dataKey].current_confusion_matrix
                                      .layer2 && (
                                      <>
                                        {/* <VSpacer amount={40} /> */}
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            paddingRight: '20px'
                                          }}
                                        >
                                          {row[
                                            col.dataKey
                                          ].current_confusion_matrix.layer2.map(
                                            layer2 => (
                                              <>
                                                <VSpacer amount={40} />
                                                <div
                                                  style={{
                                                    border:
                                                      '1px solid lightgrey',
                                                    position: 'relative'
                                                  }}
                                                >
                                                  <div className="layerTitle">
                                                    {layer2.label}
                                                    <InfoTooltip
                                                      PopperProps={{
                                                        disablePortal: true
                                                      }}
                                                      interactive
                                                      open={
                                                        layer2.description !==
                                                          '' &&
                                                        openInfoTooltip.status &&
                                                        openInfoTooltip.label ===
                                                          `${layer2.label}current`
                                                      }
                                                      title={
                                                        <ClickAwayListener
                                                          onClickAway={
                                                            handleInfoTooltipClose
                                                          }
                                                        >
                                                          <div>
                                                            <Typography>
                                                              {
                                                                layer2.description
                                                              }
                                                            </Typography>
                                                          </div>
                                                        </ClickAwayListener>
                                                      }
                                                    >
                                                      <InfoOutlinedIcon
                                                        id="infoIcon"
                                                        onClick={() =>
                                                          handleInfoTooltipOpen(
                                                            `${layer2.label}current`
                                                          )
                                                        }
                                                      />
                                                    </InfoTooltip>
                                                  </div>
                                                  <VSpacer amount={20} />
                                                  <div className="tooltipRow p20">
                                                    {layer2.values.map(
                                                      value => (
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                              'flex-start',
                                                            // width: '56px',
                                                            maxWidth: '160px',
                                                            wordBreak:
                                                              'break-word'
                                                          }}
                                                        >
                                                          <MuiTypography
                                                            style={{
                                                              fontSize: '12px',
                                                              color: `${value.color}`,
                                                              lineHeight:
                                                                'inherit'
                                                            }}
                                                            // noWrap
                                                          >
                                                            {value.item}
                                                            <HSpacer
                                                              amount={2}
                                                            />
                                                            <Typography
                                                              color={
                                                                value.color
                                                              }
                                                              className="valueCount"
                                                            >
                                                              {`(${value.count})`}
                                                            </Typography>
                                                          </MuiTypography>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                  <VSpacer amount={10} />
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      </>
                                    )}

                                    <VSpacer amount={30} />
                                  </div>
                                  {enablePreviousVersion &&
                                  row[col.dataKey].previous !== undefined &&
                                  row[col.dataKey].previous !== '' ? (
                                    <div
                                      style={{
                                        paddingLeft: '20px',
                                        backgroundColor: '#c8dcdd',
                                        borderTopRightRadius: '10px',
                                        borderBottomRightRadius: '10px'
                                      }}
                                    >
                                      <VSpacer amount={20} />
                                      <div
                                        style={{
                                          height: '34px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          color="#646464"
                                          align="center"
                                          weight={700}
                                        >
                                          Previous AI Version
                                        </Typography>
                                      </div>
                                      <VSpacer amount={10} />
                                      <div className="tooltipRow previousVersionColor">
                                        <div className="nameContainer">
                                          <Typography color="black">
                                            {data.columns[0].label} -
                                          </Typography>
                                          <HSpacer amount={5} />
                                          <MuiTypography
                                            style={{
                                              fontSize: '12px',
                                              color: '#646464',
                                              fontWeight: 700,
                                              lineHeight: 'inherit'
                                            }}
                                            // noWrap
                                          >
                                            {row[data.columns[0].dataKey]}
                                          </MuiTypography>
                                        </div>

                                        <div className="previousTrapezoid">
                                          <div
                                            style={{
                                              position: 'absolute',
                                              top: '12px'
                                            }}
                                          >
                                            {data.columns[1].label} -{' '}
                                            {row[col.dataKey].previous}
                                          </div>
                                        </div>
                                      </div>
                                      <VSpacer amount={25} />
                                      {row[col.dataKey]
                                        .previous_confusion_matrix.layer1 && (
                                        <div
                                          className="tooltipRow svg"
                                          style={{
                                            marginRight: '20px',
                                            backgroundColor:
                                              'rgb(200, 220, 221)'
                                          }}
                                        >
                                          {row[
                                            col.dataKey
                                          ].previous_confusion_matrix.layer1.map(
                                            layer1 => (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-start',
                                                  alignItems: 'baseline',
                                                  backgroundColor: '#c8dcdd',
                                                  paddingRight: '12px'
                                                }}
                                              >
                                                <Typography color="black">
                                                  {layer1.label} -
                                                </Typography>
                                                <HSpacer amount={5} />
                                                <Typography
                                                  color="#646464"
                                                  weight={700}
                                                >
                                                  {layer1.value}
                                                </Typography>
                                                <InfoTooltip
                                                  PopperProps={{
                                                    disablePortal: true
                                                  }}
                                                  interactive
                                                  open={
                                                    layer1.description !== '' &&
                                                    openInfoTooltip.status &&
                                                    openInfoTooltip.label ===
                                                      `${layer1.label}previous`
                                                  }
                                                  title={
                                                    <ClickAwayListener
                                                      onClickAway={
                                                        handleInfoTooltipClose
                                                      }
                                                    >
                                                      <div>
                                                        <Typography>
                                                          {layer1.description}
                                                        </Typography>
                                                      </div>
                                                    </ClickAwayListener>
                                                  }
                                                >
                                                  <InfoOutlinedIcon
                                                    id="infoIcon"
                                                    onClick={() =>
                                                      handleInfoTooltipOpen(
                                                        `${layer1.label}previous`
                                                      )
                                                    }
                                                  />
                                                </InfoTooltip>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {row[col.dataKey]
                                        .previous_confusion_matrix.layer2 && (
                                        <>
                                          {/* <VSpacer amount={40} /> */}
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'flex-start',
                                              paddingRight: '20px'
                                            }}
                                          >
                                            {row[
                                              col.dataKey
                                            ].previous_confusion_matrix.layer2.map(
                                              layer2 => (
                                                <>
                                                  <VSpacer amount={40} />
                                                  <div
                                                    style={{
                                                      border:
                                                        '1px solid #C0C0C0',
                                                      position: 'relative'
                                                    }}
                                                  >
                                                    <div className="layerTitle previousVersionShapeColor">
                                                      {layer2.label}
                                                      <InfoTooltip
                                                        PopperProps={{
                                                          disablePortal: true
                                                        }}
                                                        interactive
                                                        open={
                                                          layer2.description !==
                                                            '' &&
                                                          openInfoTooltip.status &&
                                                          openInfoTooltip.label ===
                                                            `${layer2.label}previous`
                                                        }
                                                        title={
                                                          <ClickAwayListener
                                                            onClickAway={
                                                              handleInfoTooltipClose
                                                            }
                                                          >
                                                            <div>
                                                              <Typography>
                                                                {
                                                                  layer2.description
                                                                }
                                                              </Typography>
                                                            </div>
                                                          </ClickAwayListener>
                                                        }
                                                      >
                                                        <InfoOutlinedIcon
                                                          id="infoIcon"
                                                          onClick={() =>
                                                            handleInfoTooltipOpen(
                                                              `${layer2.label}previous`
                                                            )
                                                          }
                                                        />
                                                      </InfoTooltip>
                                                    </div>
                                                    <VSpacer amount={20} />
                                                    <div className="tooltipRow p20 previousVersionColor">
                                                      {layer2.values.map(
                                                        value => (
                                                          <div
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent:
                                                                'flex-start',
                                                              // width: '56px',
                                                              maxWidth: '160px',
                                                              wordBreak:
                                                                'break-word'
                                                            }}
                                                          >
                                                            <MuiTypography
                                                              style={{
                                                                fontSize:
                                                                  '12px',
                                                                color: `${value.color}`,
                                                                lineHeight:
                                                                  'inherit'
                                                              }}
                                                              // noWrap
                                                            >
                                                              {value.item}
                                                              <HSpacer
                                                                amount={2}
                                                              />
                                                              <Typography
                                                                color={
                                                                  value.color
                                                                }
                                                                className="valueCount"
                                                              >
                                                                {`(${value.count})`}
                                                              </Typography>
                                                            </MuiTypography>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <VSpacer amount={10} />
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </div>
                                        </>
                                      )}

                                      <VSpacer amount={30} />
                                    </div>
                                  ) : null}
                                </div>
                              </ClickAwayListener>
                            }
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: '200px',
                                justifyContent: 'center'
                              }}
                              onClick={() => {
                                setTableTooltipStatus({
                                  status: true,
                                  key: chartName
                                });
                                handleTooltipOpen(
                                  i,
                                  row[col.dataKey].enable_confusion_matrix
                                );
                              }}
                            >
                              <div
                                style={{
                                  ...styles.value,
                                  ...styles.currentValue
                                }}
                                id="tooltipCol1"
                              >
                                {row[col.dataKey].current}
                              </div>
                              {enablePreviousVersion &&
                              row[col.dataKey].previous !== undefined &&
                              row[col.dataKey].previous !== '' ? (
                                <div
                                  style={{
                                    ...styles.value,
                                    ...styles.previousValue
                                  }}
                                  id="tooltipCol2"
                                >
                                  {row[col.dataKey].previous}
                                </div>
                              ) : null}
                            </div>
                          </HtmlTooltip>
                        </div>
                      </CustomTableCell>
                    );
                  }
                  if (col.type === 'icon') {
                    return (
                      <CustomTableCell
                        key={col.dataKey}
                        className={`${
                          row[col.dataKey] ? 'doneIcon' : 'closeIcon'
                        }`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '200px',
                            justifyContent: 'center'
                          }}
                        >
                          {row[col.dataKey].current === true ? (
                            <DoneIcon style={{ fill: '#5cb85c' }} />
                          ) : row[col.dataKey].current === 'NA' ? (
                            row[col.dataKey].current
                          ) : (
                            <CloseIcon style={{ fill: 'red' }} />
                          )}
                          {enablePreviousVersion ? (
                            row[col.dataKey].previous === true ? (
                              <DoneIcon
                                style={{ fill: '#5cb85c', marginLeft: '10px' }}
                              />
                            ) : row[col.dataKey].previous === 'NA' ? (
                              <span style={{ marginLeft: '10px' }}>
                                {row[col.dataKey].previous}
                              </span>
                            ) : (
                              <CloseIcon
                                style={{ fill: 'red', marginLeft: '10px' }}
                              />
                            )
                          ) : null}
                        </div>
                      </CustomTableCell>
                    );
                  }
                  return (
                    <CustomTableCell key={col.dataKey}>
                      {row[col.dataKey]}
                    </CustomTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  enablePreviousVersion: PropTypes.bool.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setTableTooltipStatus: PropTypes.func.isRequired,
  tableTooltipStatus: PropTypes.object.isRequired,
  chartName: PropTypes.string.isRequired
};

const TableWrapper = withStyles(tableStyles)(props =>
  props.data.datasets.length === 0 ? (
    <Typography center color="light25" size="xs">
      No data to display
    </Typography>
  ) : (
    CustomTable({
      ...props,
      height: props.height,
      setTableTooltipStatus: props.setTableTooltipStatus,
      tableTooltipStatus: props.tableTooltipStatus,
      chartName: props.chartName
    })
  )
);

TableWrapper.propTypes = {
  /**
   * @type {string}
   * @description - Width of the table
   */
  width: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setTableTooltipStatus: PropTypes.func.isRequired,
  tableTooltipStatus: PropTypes.object.isRequired,
  chartName: PropTypes.string.isRequired
};

TableWrapper.defaultProps = {
  width: '90%',
  height: '300px'
};

export default TableWrapper;

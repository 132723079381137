/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, filter, map, each, keys } from 'lodash';
import styled from 'styled-components';
import classNames from 'classnames';
// redux-utils
import {
  appImageActions,
  genericSEActions,
  storeExplorerActions,
  appActions,
  chartActions,
  adminActions
} from 'redux-utils/actions/index';
import {
  appImageSelector,
  genericSESelector,
  appSelector,
  chartSelector,
  slicerSelector,
  adminSelector
} from 'redux-utils/selectors/index';
// nodejs i18n library
import { withTranslation } from 'react-i18next';
// lib
import { getFilterValue } from 'utils/filters';
import Stack from 'lib/core/stack';
// @material-ui/core components
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
// assets
import sidebarStyle from 'assets/jss/material-ui/components/sidebarStyleNew';
// core components
import { VSpacer } from 'components/Spacer/Spacer';
import getDataLayer from 'utils/gtagManager';
// core functions
import { getRouteID, getSimilarRoutes } from 'lib/utils/getActiveRoute';
import grModel from 'models/granularityModel';

import SidebarMenuNew from './SidebarMenuNew';

const hasSessionFilter = filterList =>
  filterList.filter(el => el.value === 'fl_session').length > 0;

const computeFilterData = (filtersConfiguredFromAPI, filterData) => {
  const filtersToRender = new Stack();
  let previousFilterValue = null;
  filtersConfiguredFromAPI.forEach(({ label, value }) => {
    let options = filterData[value];
    if (filtersToRender && !filtersToRender.isEmpty()) {
      const parentFilter = filtersToRender.peek();
      const parentValue = (parentFilter.value || {}).value;

      if (!previousFilterValue) {
        previousFilterValue = `${parentValue}`;
      } else {
        previousFilterValue += `+${parentValue}`;
      }
      options = (options || []).reduce((options, option) => {
        if (option.previous_filter_value === previousFilterValue) {
          options.push(option);
        }
        return options;
      }, []);
    }
    const obj = { label, key: value, options };
    obj.value = getFilterValue({ options: obj.options });
    filtersToRender.push(obj);
  });
  return filtersToRender;
};

let filterStack = [];

const StyledDrawer = styled(Drawer)`
  & div[class*='MuiDrawer-paper'] {
    background-color: ${props => props.bg};
  }
`;

const SideBarNew = ({
  color,
  classes,
  handleDrawerToggle,
  drawerState,
  clientPreferences,
  location,
  t,
  getStoreSessions,
  getStores,
  getPhotos,
  stores,
  sessions,
  layout,
  filterData,
  routeID,
  chartList,
  dispatchChartListRequest,
  dispatchSessionDataRequest,
  dispatchChartDataRequest,
  dispatchGetCurationStores,
  dispatchGetCurationSessions,
  dispatchFilterChange,
  defaultFilters,
  chartParamsValue,
  filterPreferences,
  slicers,
  chartListCommon,
  granularities,
  dispatchGetChartData,
  dispatchGranularityChange,
  filters,
  dispatchGetChartList,
  dashboardType,
  subPanel,
  stopTaskPoll,
  routeChange,
  backgroundColor,
  dropdownTitleStyles,
  dropdownStyles,
  routes,
  sessionFilterValues,
  layoutValue
}) => {
  const [sidebarColor, setSidebarColor] = useState(color);
  const [isFiltersReadyToRender, setFiltersRenderState] = useState(false);
  const [filtersToRender, setFiltersToRender] = useState({ data: [] });
  const [date, setDate] = useState({});
  const [store, setStore] = useState({});
  const [session, setSession] = useState({});
  const [activeSubRoute, setActiveSubRoute] = useState();
  const [routeIDParam, setRouteIDParam] = useState(null);

  // const routeIDParam = getRouteID(window.location.pathname);
  const currentLayoutValue = layoutValue.find(
    item => item.routeID === routeIDParam
  );

  const isDefaultsAvailable =
    routeIDParam && currentLayoutValue
      ? currentLayoutValue.defaults?.length
      : false;

  const { isDrawerExpanded, isDrawerOpen } = drawerState;

  useEffect(() => {
    setRouteIDParam(getRouteID(window.location.pathname));
  }, [window.location.pathname]);

  // const routeIDParam =
  const filtersConfiguredFromAPI =
    routeIDParam && routes
      ? (routes.find(route => route.routeID === routeIDParam) || {}).defaults
      : [];

  useEffect(() => {
    if (sessionStorage.getItem('activeAdminSidePanel')) {
      const panelLastUsed = JSON.parse(
        sessionStorage.getItem('activeAdminSidePanel')
      );
      const [menuProps] = subPanel.filter(
        panel => panel.name === panelLastUsed.name
      );
      setActiveSubRoute(menuProps);
    } else {
      setActiveSubRoute(subPanel[0]);
    }
  }, [subPanel]);

  const handleItemClick = item => {
    const dataLayer = getDataLayer();
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'mdm_history_change',
        path: item.name
      });
    }
    setActiveSubRoute(item);
    sessionStorage.setItem('activeAdminSidePanel', JSON.stringify(item));
    stopTaskPoll({});
    routeChange();
  };

  useEffect(() => {
    if (
      routeIDParam === 'store-explorer-v2' &&
      !isEmpty(filtersConfiguredFromAPI) &&
      !isEmpty(filterData)
    ) {
      const filterParams = routes.filter(
        l => l.routeID === 'store-explorer-v2'
      )[0].defaults;
      filterStack = computeFilterData(filterParams, filterData);

      setFiltersToRender({ data: filterStack.data, key: Math.random() });
      setFiltersRenderState(true);
      return;
    }

    if (
      routeIDParam === 'store-explorer-v2' &&
      !isEmpty(filtersConfiguredFromAPI) &&
      !isEmpty(filterData)
    ) {
      filterStack = computeFilterData(filtersConfiguredFromAPI, filterData);

      setFiltersToRender({ data: filterStack.data, key: Math.random() });
      setFiltersRenderState(true);
    }
  }, [filtersConfiguredFromAPI, filterData]);

  const getFilterParamsForAPI = (filtersValue = filterStack.data) => {
    const params = {};
    filtersValue.forEach(f => {
      params[f.key] = (f.value || {}).value;
    });
    return params;
  };

  const prepareSessionDataAPICall = () => {
    if (!filterStack.data.some(filter => !filter.value)) {
      dispatchSessionDataRequest({
        params: getFilterParamsForAPI(filterStack.data)
      });
    }
  };

  const prepareChartListAPICall = () => {
    if (!filterStack.data.some(filter => !filter.value)) {
      dispatchChartListRequest({
        params: {
          route_id: routeID,
          ...getFilterParamsForAPI(filterStack.data)
        }
      });
    }
  };

  const prepareChartDataAPICall = ({ chart }) => {
    if (!filterStack.data.some(filter => !filter.value)) {
      dispatchChartDataRequest({
        params: {
          chart_name: chart.name,
          fl_chart_type: chart.type,
          ...getFilterParamsForAPI(filterStack.data)
        }
      });
    }
  };

  useEffect(() => {
    // condition check for filter availability
    if (
      chartList.length > 0 &&
      !isEmpty(filterData) &&
      routeID === 'store-explorer-v2'
    ) {
      chartList.forEach(chart => {
        prepareChartDataAPICall({ chart });
      });
    }
  }, [chartList]);

  const computeRequestParams = (
    chart,
    chartParamsValueCommon,
    slicersCommon = slicers
  ) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValueCommon[chart.name]) {
          if (chartParamsValueCommon[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValueCommon[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        requestObject.fl_end_date = chartParamsValueCommon.fl_time.fl_end_date;
        requestObject.fl_start_date =
          chartParamsValueCommon.fl_time.fl_start_date;
        requestObject.fl_time_type =
          chartParamsValueCommon.fl_time.fl_time_type;
      } else if (paramID === 'fl_type') {
        if (
          window.location.pathname.includes('/display') ||
          chart.name.includes(getRouteID('/app/display'))
        ) {
          requestObject[paramID] = 'display';
        } else if (
          window.location.pathname.includes('/on-shelf-availability') ||
          chart.name.includes(getRouteID('/app/on-shelf-availability'))
        ) {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else if (
        !isEmpty(slicersCommon) &&
        slicersCommon[chart.name][paramID]
      ) {
        requestObject[paramID] = slicersCommon[chart.name][paramID].value.value;
      } else {
        requestObject[paramID] =
          chartParamsValueCommon[paramID] === undefined
            ? ''
            : chartParamsValueCommon[paramID].value;
      }
      if (param.depend_on) {
        requestObject[paramID] =
          chartParamsValueCommon[chart.name][paramID]?.value?.value;
      }
    });
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  const fetchChartList = (category = undefined) => {
    const params = { route_id: routeID };
    if (category) {
      const { length } = filtersConfiguredFromAPI.filter(
        el => el.value === 'fl_category'
      );
      if (length > 0) {
        params.fl_category = category;
      }
    }
    dispatchGetChartList({
      params,
      cacheRequest: false
    });
  };

  const getChartData = (
    chartListArray,
    chartParamsValueCommon,
    slicersCommon
  ) => {
    each(chartListArray, chart => {
      const requestObject = computeRequestParams(
        chart,
        chartParamsValueCommon,
        slicersCommon
      );

      dispatchGetChartData({
        params: requestObject,
        routeID
      });
    });
  };

  const handleFilterChangeForAllPages = (val, key) => {
    const dataLayer = getDataLayer();
    const value = key !== undefined ? { [key]: val } : val;
    const chartParams = { ...chartParamsValue };
    const { chartName, grType, filterType } = grModel;
    if (value[filterType]) {
      const grValue =
        value[filterType].value === 'display' ? 'brand-form' : 'brand';
      chartName.map(chname => {
        if (chartParams[chname] !== undefined) {
          chartParams[chname][grType] = {
            ...chartParams[chname][grType],
            type: grType,
            value: grValue
          };
        }
        return chname;
      });
    }
    if (dataLayer !== null && filterPreferences) {
      let params = {};
      // eslint-disable-next-line camelcase
      const { fl_start_date, fl_end_date, label } = val;
      each(chartListCommon, chart => {
        const requestObject = computeDataLayerParams(chart, {
          ...chartParams,
          ...value
        });
        params = { ...requestObject };
      });
      // eslint-disable-next-line camelcase
      if (fl_start_date && fl_end_date) {
        params.fl_date_label = label;
      }
      // eslint-disable-next-line camelcase
      if (!fl_start_date && !fl_end_date) {
        params.fl_date_label = filterPreferences.fl_time.label;
      }
      dataLayer.push({
        event: 'filter_change',
        ...params
      });
    }
    dispatchFilterChange({
      routeID,
      value
    });
    if (value.fl_brand || value.fl_category) {
      if (value.fl_category) {
        [value.fl_brand] = value.fl_category.brands;
        fetchChartList(value.fl_category.value);
      }
      map(granularities, (charts, chartsKey) => {
        map(charts, (gran, granKey) => {
          if (gran.filter_id) {
            const pickFrom = gran.pick_from
              ? gran.pick_from
              : gran.filter_id.includes('brand')
              ? 'fl_category'
              : 'fl_brand';
            const options = filters[pickFrom].options.filter(
              e => e.value === value[pickFrom].value
            )[0][granKey];
            const filterValue =
              options.filter(el => el.is_default === true).length === 0
                ? options[0]
                : options.filter(el => el.is_default === true)[0];
            handleGranularityChange(
              {
                value: filterValue
              },
              granKey,
              { [chartsKey]: { [granKey]: filterValue } },
              false,
              { ...chartParams, ...value }
            );
            const chartObject = filter(
              chartListCommon,
              chart => chart.name !== chartsKey
            );
            getChartData(chartObject, { ...chartParams, ...value }, slicers);
          }
        });
      });
    } else {
      getChartData(chartListCommon, { ...chartParams, ...value }, slicers);
    }
  };

  const computeDataLayerParams = (chart, chartParamsValueCommon) => {
    const requestObject = {};
    each(chart.params, param => {
      const { param_id: paramID } = param;
      if (paramID.includes('gr_')) {
        if (chartParamsValueCommon[chart.name]) {
          if (chartParamsValueCommon[chart.name][paramID]) {
            requestObject[paramID] =
              chartParamsValueCommon[chart.name][paramID].value;
          }
        }
      } else if (
        paramID === 'fl_start_date' ||
        paramID === 'fl_end_date' ||
        paramID === 'fl_time_type'
      ) {
        requestObject.fl_end_date = chartParamsValueCommon.fl_time.fl_end_date;
        requestObject.fl_start_date =
          chartParamsValueCommon.fl_time.fl_start_date;
        requestObject.fl_time_type =
          chartParamsValueCommon.fl_time.fl_time_type;
      } else if (paramID === 'fl_type') {
        if (
          window.location.pathname.includes('/display') ||
          chart.name.includes(getRouteID('/app/display'))
        ) {
          requestObject[paramID] = 'display';
        } else if (
          window.location.pathname.includes('/on-shelf-availability') ||
          chart.name.includes(getRouteID('/app/on-shelf-availability'))
        ) {
          requestObject[paramID] = 'shelf';
        }
      } else if (paramID.includes('chart_name')) {
        requestObject[paramID] = chart.name;
      } else if (paramID === 'fl_brand') {
        requestObject[paramID] =
          chartParamsValueCommon[paramID] === undefined
            ? ''
            : chartParamsValueCommon[paramID].label;
      } else {
        requestObject[paramID] =
          chartParamsValueCommon[paramID] === undefined
            ? ''
            : chartParamsValueCommon[paramID].value;
      }
      if (param.depend_on) {
        requestObject[paramID] =
          chartParamsValueCommon[chart.name][paramID].value.value;
      }
    });
    requestObject.fl_chart_type = chart.type;
    return requestObject;
  };

  const handleGranularityChange = (
    grObj,
    grKey,
    chartValue,
    updateActiveValue = true,
    useChartParams = null
  ) => {
    const [chartName] = keys(chartValue);
    const chartObject = filter(chartList, chart => chart.name === chartName);

    dispatchGranularityChange({ routeID, value: chartValue });

    const chartParams = useChartParams || chartParamsValue;
    getChartData(
      chartObject,
      {
        ...chartParams,
        [chartName]: {
          ...chartParams[chartName],
          [grKey]: { type: grKey, value: grObj.value }
        }
      },
      slicers
    );

    if (!grKey.includes('gr_') && updateActiveValue) {
      const updatedGranularities = { ...granularities };
      map(granularities, (gran, chart) => {
        map(gran, (value, type) => {
          if (value.filter_id) {
            const pickFrom = value.pick_from
              ? value.pick_from
              : value.filter_id.includes('brand')
              ? 'fl_category'
              : 'fl_brand';
            const options = chartParamsValue[pickFrom][type];
            updatedGranularities[chart] = {
              [type]: {
                ...value,
                options,
                value: grObj.value
              }
            };
          }
        });
      });
    }
    return null;
  };

  const handleFilterChangeTestStoreExplorer = (val, key) => {
    if (!isEmpty(val)) {
      if (hasSessionFilter(defaultFilters)) {
        const value = key !== undefined ? { [key]: val } : val;
        dispatchFilterChange({
          routeIDParam,
          value
        });
        if (key === 'fl_date') {
          if (chartParamsValue.fl_category) {
            dispatchGetCurationStores({
              params: { date: val.fl_start_date.split('/').join('-') },

              menuModel: getSimilarRoutes('store-explorer')
            });
          }
        } else if (chartParamsValue.fl_store || value.fl_store) {
          dispatchGetCurationSessions({
            params: {
              date: chartParamsValue.fl_date.fl_start_date.split('/').join('-'),
              fl_store: chartParamsValue.fl_store.value
            },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      } else {
        const value = key !== undefined ? { [key]: val } : val;
        dispatchFilterChange({
          routeIDParam,
          value
        });
        if (key === 'fl_date') {
          dispatchGetCurationStores({
            params: { date: val.fl_start_date.split('/').join('-') },

            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
  };

  const handleFilterChange = p => {
    while (filterStack.peek().key !== p.key) {
      filterStack.pop();
    }
    const sliceIndex = filtersConfiguredFromAPI.findIndex(
      el => el.value === p.key
    );
    const slicedFiltersFromAPI = filtersConfiguredFromAPI.slice(
      sliceIndex,
      filtersConfiguredFromAPI.length
    );

    const filterValues = getFilterParamsForAPI();
    const retainedFilters = filtersConfiguredFromAPI.slice(0, sliceIndex);
    let previousFilterValue = null;
    retainedFilters.forEach(filterValue => {
      if (!previousFilterValue) {
        previousFilterValue = `${filterValues[filterValue.value]}`;
      } else {
        previousFilterValue += `+${filterValues[filterValue.value]}`;
      }
    });
    slicedFiltersFromAPI.forEach(({ label, value }, index) => {
      if (index > 0) {
        const parentFilter = filterStack.peek();
        const parentValue = (parentFilter.value || {}).value;
        if (!previousFilterValue) {
          previousFilterValue = `${parentValue}`;
        } else {
          previousFilterValue += `+${parentValue}`;
        }
        const obj = {
          label,
          key: value,
          options: filterData[value]?.reduce((options, option) => {
            if (option.previous_filter_value === previousFilterValue) {
              options.push(option);
            }
            return options;
          }, [])
        };
        obj.value = getFilterValue({ options: obj.options });
        filterStack.push(obj);
      } else {
        const changedFilter = filterStack.pop();
        const obj = { ...changedFilter };
        obj.value = p.val;
        filterStack.push(obj);
      }
    });
    setFiltersToRender({ data: filterStack.data, key: Math.random() });
    prepareSessionDataAPICall();
    prepareChartListAPICall();
  };

  const handleDateChange = dateValue => {
    const dataLayer = getDataLayer();
    setDate(dateValue);
    setStore({});
    setSession({});
    getStores(dateValue);
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        date
      });
    }
  };

  const handleStoreChange = storeValue => {
    const dataLayer = getDataLayer();
    setStore(storeValue);
    setSession({});
    getStoreSessions({ store: storeValue, date });
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        store,
        date
      });
    }
  };

  const handleSessionChange = sessionValue => {
    const dataLayer = getDataLayer();
    setSession(sessionValue);

    getPhotos({ session: sessionValue, date, store });
    if (dataLayer !== null) {
      dataLayer.push({
        event: 'filter_change',
        session,
        date,
        store
      });
    }
  };

  useEffect(() => {
    if (Object.keys(sessionFilterValues).length && filterStack.data) {
      const newState = {
        ...filtersToRender.data
      };
      Object.keys(newState).reduce((accu, item) => {
        const filterKey = newState[item].key;
        newState[item].value = sessionFilterValues[filterKey];
      }, {});
      // required for showing filter values
      let obj = {
        ...sessionFilterValues
      };
      Object.keys(obj).map(p => {
        const sliceIndex = filtersConfiguredFromAPI.findIndex(
          el => el.value === p
        );

        const filterValues = getFilterParamsForAPI();
        const retainedFilters = filtersConfiguredFromAPI.slice(0, sliceIndex);

        let previousFilterValue = null;
        retainedFilters.forEach(filterValue => {
          if (!previousFilterValue) {
            previousFilterValue = `${filterValues[filterValue.value]}`;
          } else {
            previousFilterValue += `+${filterValues[filterValue.value]}`;
          }
        });

        let options = filterData[p];

        options = (options || []).reduce((options, option) => {
          if (option.previous_filter_value === previousFilterValue) {
            options.push(option);
          }
          return options;
        }, []);

        obj[p] = Object.assign(
          {
            previous_filter_value: previousFilterValue,
            options
          },
          obj[p]
        );
      });

      filterStack.data.map(filter => {
        filter.value = obj[filter.key];
        filter.options = obj[filter.key].options;
      });
      setFiltersToRender({
        data: filterStack.data,
        key: Math.random()
      });
      setFiltersRenderState(true);
    }
  }, [sessionFilterValues, filterStack.data]);
  return (
    <div className={classes.root}>
      <Hidden smDown implementation="css">
        <StyledDrawer
          bg={backgroundColor}
          variant="permanent"
          className={classNames(classes.root, {
            [classes.drawerOpen]: isDrawerExpanded,
            [classes.drawerClose]: !isDrawerExpanded
          })}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: isDrawerExpanded,
              [classes.drawerClose]: !isDrawerExpanded
            })
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          open={isDrawerOpen}
        >
          <SidebarMenuNew
            sidebarColor={sidebarColor}
            drawerRight={false}
            classes={classes}
            location={location}
            t={t}
            handleDateChange={handleDateChange}
            handleStoreChange={handleStoreChange}
            handleSessionChange={handleSessionChange}
            stores={stores}
            sessions={sessions}
            store={store}
            session={session}
            value={date}
            handleFilterChange={handleFilterChange}
            filtersToRender={filtersToRender.data || []}
            isFiltersReadyToRender={isFiltersReadyToRender}
            handleFilterChangeTestStoreExplorer={
              handleFilterChangeTestStoreExplorer
            }
            handleFilterChangeForAllPages={handleFilterChangeForAllPages}
            // routeIDParam={routeIDParam}
            dashboardType={dashboardType}
            activeSubRoute={activeSubRoute}
            subPanel={subPanel}
            handleItemClick={handleItemClick}
            dropdownStyles={dropdownStyles}
            dropdownTitleStyles={dropdownTitleStyles}
          />
          <VSpacer amount={10} />
        </StyledDrawer>
      </Hidden>
      {/* <Hidden mdUp implementation="css">
        <StyledDrawer
          bg={backgroundColor}
          anchor="right"
          variant="temporary"
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            onBackdropClick: handleDrawerToggle
          }}
          open={isDrawerOpen}
        >
          <SidebarMenuNew
            sidebarColor={sidebarColor}
            drawerRight
            classes={classes}
            location={location}
            t={t}
            handleDateChange={handleDateChange}
            handleStoreChange={handleStoreChange}
            handleSessionChange={handleSessionChange}
            stores={stores}
            sessions={sessions}
            store={store}
            session={session}
            value={date}
            handleFilterChange={handleFilterChange}
            filtersToRender={filtersToRender.data || []}
            isFiltersReadyToRender={isFiltersReadyToRender}
            handleFilterChangeForAllPages={handleFilterChangeForAllPages}
            handleFilterChangeTestStoreExplorer={
              handleFilterChangeTestStoreExplorer
            }
            // routeIDParam={routeIDParam}
            dashboardType={dashboardType}
            activeSubRoute={activeSubRoute}
            subPanel={subPanel}
            handleItemClick={handleItemClick}
            dropdownStyles={dropdownStyles}
            dropdownTitleStyles={dropdownTitleStyles}
          />
        </StyledDrawer>
      </Hidden> */}
    </div>
  );
};

// component properties
SideBarNew.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerState: PropTypes.object,
  color: PropTypes.string,
  clientPreferences: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.any.isRequired,
  getStores: PropTypes.func.isRequired,
  getStoreSessions: PropTypes.func.isRequired,
  getPhotos: PropTypes.func.isRequired,
  stores: PropTypes.array,
  sessions: PropTypes.array,
  layout: PropTypes.object.isRequired,
  filterData: PropTypes.object.isRequired,
  routeID: PropTypes.string.isRequired,
  dispatchChartListRequest: PropTypes.func.isRequired,
  dispatchSessionDataRequest: PropTypes.func.isRequired,
  dispatchChartDataRequest: PropTypes.func.isRequired,
  dispatchGetCurationStores: PropTypes.func.isRequired,
  dispatchGetCurationSessions: PropTypes.func.isRequired,
  dispatchFilterChange: PropTypes.func.isRequired,
  chartList: PropTypes.array.isRequired,
  defaultFilters: PropTypes.any.isRequired,
  chartParamsValue: PropTypes.any.isRequired,
  filterPreferences: PropTypes.any.isRequired,
  slicers: PropTypes.any.isRequired,
  chartListCommon: PropTypes.any.isRequired,
  granularities: PropTypes.any.isRequired,
  dispatchGetChartData: PropTypes.func.isRequired,
  dispatchGranularityChange: PropTypes.func.isRequired,
  filters: PropTypes.any.isRequired,
  dispatchGetChartList: PropTypes.func.isRequired,
  dashboardType: PropTypes.string.isRequired,
  subPanel: PropTypes.array.isRequired,
  stopTaskPoll: PropTypes.func.isRequired,
  routeChange: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  routes: PropTypes.array.isRequired
};

SideBarNew.defaultProps = {
  drawerState: {
    isDrawerExpanded: true,
    isDrawerOpen: false
  },
  color: 'blue',
  stores: [],
  sessions: [],
  backgroundColor: '',
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeIDForChart = getRouteID(pathname);
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getDefaultFilters = appSelector.makeGetDefaultFilters();
  const getSlicers = slicerSelector.selectSlicers();
  const getChartList = chartSelector.makeGetChartList();
  const getGranularitiesList = chartSelector.makeGetGranularitiesList();
  const getFilterList = appSelector.makeGetFilterList();

  const mapStateToProps = (state, props) => ({
    chartParamsValue: getParamsValueList(state, { routeIDForChart }),
    stores: appImageSelector.getStores(state),
    sessions: appImageSelector.getSessions(state),
    photos: appImageSelector.getPhotos(state),
    layout: genericSESelector.selectLayout(state),
    sessionFilterValues: genericSESelector.selectFilterValues(state),
    filterData: genericSESelector.selectFilters(state),
    routeID: genericSESelector.getRouteID(),
    chartList: genericSESelector.selectChartList(state),
    subPanel: adminSelector.selectSubPanel(state),
    chartListCommon: getChartList(state, { routeIDForChart }),
    defaultFilters: getDefaultFilters(state, props),
    filterPreferences: chartSelector.getFilterPreferences(state, {
      routeIDForChart
    }),
    slicers: getSlicers(state, { routeIDForChart }),
    granularities: getGranularitiesList(state, { routeIDForChart }),
    filters: getFilterList(state),
    dashboardType: state.layoutStore.routes?.dashboardConfig?.version,
    routes: state.layoutStore.routes?.layout,
    layoutValue: genericSESelector.getLayout(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  getStores: payload => dispatch(appImageActions.getStores(payload)),
  getStoreSessions: payload =>
    dispatch(appImageActions.getStoreSessions(payload)),
  getPhotos: payload => dispatch(appImageActions.getPhotos(payload)),
  dispatchChartListRequest: payload =>
    dispatch(genericSEActions.getChartList(payload)),
  dispatchSessionDataRequest: payload =>
    dispatch(genericSEActions.getSessionData(payload)),
  dispatchChartDataRequest: payload =>
    dispatch(genericSEActions.getChartData(payload)),
  dispatchGetCurationStores: payload =>
    dispatch(
      storeExplorerActions.getCurationStoresAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchGetCurationSessions: payload =>
    dispatch(
      storeExplorerActions.getCurationSessionsAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchGetChartData: payload =>
    dispatch(chartActions.getChartDataAction(payload)),
  dispatchGranularityChange: payload =>
    dispatch(chartActions.granularityChangeAction(payload)),
  dispatchGetChartList: payload =>
    dispatch(chartActions.getChartListAction(payload)),
  stopTaskPoll: payload => dispatch(adminActions.stopPollTaskAction(payload)),
  routeChange: payload => dispatch(adminActions.routeChangeAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withStyles(sidebarStyle)(SideBarNew));

import { createSelector } from '@reduxjs/toolkit';

// store selectors
const getAdminStore = state => state.adminStore;

const selectSubPanel = createSelector(
  [getAdminStore],
  ({ subPanel }) => subPanel
);

const selectSubPanelPageData = createSelector(
  [getAdminStore],
  ({ subPanelPageData }) => subPanelPageData
);

const selectStickerValues = createSelector(
  [getAdminStore],
  ({ stickerValues }) => stickerValues
);

const selectDisplayValues = createSelector(
  [getAdminStore],
  ({ displayValues }) => displayValues
);

const selectPageTotal = createSelector(
  [getAdminStore],
  ({ pageTotal }) => pageTotal
);

const selectTransactionData = createSelector(
  [getAdminStore],
  ({ postSubPanelPageData }) => postSubPanelPageData
);

const selectDownloadAllTemplates = createSelector(
  [getAdminStore],
  ({ downloadAllTemplates }) => downloadAllTemplates
);

const selectDownloadTemplate = createSelector(
  [getAdminStore],
  ({ downloadTemplate }) => downloadTemplate
);

const selectDownloadExcel = createSelector(
  [getAdminStore],
  ({ downloadExcel }) => downloadExcel
);

const selectDownloadPackshots = createSelector(
  [getAdminStore],
  ({ downloadPackshots }) => downloadPackshots
);

const selectUploadPackshot = createSelector(
  [getAdminStore],
  ({ uploadPackshot }) => uploadPackshot
);

const selectReferenceImageUploadData = createSelector(
  [getAdminStore],
  ({ uploadReferenceImages }) => uploadReferenceImages
);

const selectUploadExcel = createSelector(
  [getAdminStore],
  ({ uploadExcel }) => uploadExcel
);

const selectApiRequestStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) => apiRequestStatus
);

const selectPollTask = createSelector(
  [getAdminStore],
  ({ pollTask: task, pollTaskData: data }) => ({ task, data })
);

const selectDownloadExcelLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.downloadExcel.request &&
    !(
      apiRequestStatus.downloadExcel.success ||
      apiRequestStatus.downloadExcel.error
    )
);

const selectGetSubPanelPageDataLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.getSubPanelPageData.request &&
    !(
      apiRequestStatus.getSubPanelPageData.success ||
      apiRequestStatus.getSubPanelPageData.error
    )
);

const selectGetStickerValuesLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.getStickerValues.request &&
    !(
      apiRequestStatus.getStickerValues.success ||
      apiRequestStatus.getStickerValues.error
    )
);

const selectGetDisplayValuesLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.getDisplayValues.request &&
    !(
      apiRequestStatus.getDisplayValues.success ||
      apiRequestStatus.getDisplayValues.error
    )
);

const selectDownloadAllTemplatesLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.downloadAllTemplates.request &&
    !(
      apiRequestStatus.downloadAllTemplates.success ||
      apiRequestStatus.downloadAllTemplates.error
    )
);

const selectDownloadTemplateLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.downloadTemplate.request &&
    !(
      apiRequestStatus.downloadTemplate.success ||
      apiRequestStatus.downloadTemplate.error
    )
);

const selectDownloadPackshotsLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.downloadPackshots.request &&
    !(
      apiRequestStatus.downloadPackshots.success ||
      apiRequestStatus.downloadPackshots.error
    )
);

const selectPostSubPanelPageDataLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.postSubPanelPageData.request &&
    !(
      apiRequestStatus.postSubPanelPageData.success ||
      apiRequestStatus.postSubPanelPageData.error
    )
);

const selectUploadPackshotLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.uploadPackshot.request &&
    !(
      apiRequestStatus.uploadPackshot.success ||
      apiRequestStatus.uploadPackshot.error
    )
);

const selectUploadReferenceImagesLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.uploadReferenceImages.request &&
    !(
      apiRequestStatus.uploadReferenceImages.success ||
      apiRequestStatus.uploadReferenceImages.error
    )
);

const selectUploadExcelLoaderStatus = createSelector(
  [getAdminStore],
  ({ apiRequestStatus }) =>
    apiRequestStatus.uploadExcel.request &&
    !(
      apiRequestStatus.uploadExcel.success || apiRequestStatus.uploadExcel.error
    )
);

const makeSelectLoaderStatus = () =>
  createSelector(
    [
      selectDownloadExcelLoaderStatus,
      selectGetSubPanelPageDataLoaderStatus,
      selectGetStickerValuesLoaderStatus,
      selectGetDisplayValuesLoaderStatus,
      selectDownloadAllTemplatesLoaderStatus,
      selectDownloadTemplateLoaderStatus,
      selectDownloadPackshotsLoaderStatus,
      selectPostSubPanelPageDataLoaderStatus,
      selectUploadExcelLoaderStatus,
      selectUploadPackshotLoaderStatus,
      selectUploadReferenceImagesLoaderStatus
    ],
    (
      downloadExcelLoaderStatus,
      getSubPanelPageDataLoaderStatus,
      getStickerValuesLoaderStatus,
      getDisplayValuesLoaderStatus,
      downloadAllTemplatesLoaderStatus,
      downloadTemplateLoaderStatus,
      downloadPackshotsLoaderStatus,
      postSubPanelPageDataLoaderStatus,
      uploadExcelLoaderStatus,
      uploadPackshotLoaderStatus,
      uploadReferenceImagesLoaderStatus
    ) =>
      downloadExcelLoaderStatus ||
      getSubPanelPageDataLoaderStatus ||
      getStickerValuesLoaderStatus ||
      getDisplayValuesLoaderStatus ||
      downloadAllTemplatesLoaderStatus ||
      downloadTemplateLoaderStatus ||
      downloadPackshotsLoaderStatus ||
      postSubPanelPageDataLoaderStatus ||
      uploadExcelLoaderStatus ||
      uploadPackshotLoaderStatus ||
      uploadReferenceImagesLoaderStatus
  );

export {
  selectSubPanel,
  selectPageTotal,
  selectSubPanelPageData,
  selectStickerValues,
  selectDisplayValues,
  selectTransactionData,
  selectDownloadAllTemplates,
  selectDownloadTemplate,
  selectDownloadExcel,
  selectDownloadPackshots,
  selectPollTask,
  selectUploadPackshot,
  selectReferenceImageUploadData,
  selectUploadExcel,
  selectApiRequestStatus,
  makeSelectLoaderStatus
};

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// redux-utils
import { connect } from 'react-redux';
import { appActions, userActions } from 'redux-utils/actions';
import {
  appSelector,
  chartSelector,
  userSelector
} from 'redux-utils/selectors';
import UserProfile from 'pages/UserProfile/UserProfile';
// core functions
import { uniqBy } from 'lib/core/array';
import {
  handleCustomerFilterChange,
  handleChannelFilterChange,
  handleSpaceTypeFilterChange,
  handleSpaceFilterChange
} from 'lib/filter/helper';
import { getRouteID } from 'lib/utils/getActiveRoute';
import grModel from 'models/granularityModel';

const optionAll = { value: 'all', label: 'All' };

class UserProfileContainer extends Component {
  appSpaceData = {};

  filterChangeValue = {
    fl_time: {
      fl_end_date: '30/03/2019',
      fl_start_date: '24/03/2019',
      fl_time_type: 'day',
      is_default: false,
      label: 'Last Week',
      value: 'lw'
    }
  };

  state = {
    prefsList: null
  };

  componentWillMount() {
    const {
      dispatchGetUserProfile,
      dispatchGetFilterRequest,
      userProfile
    } = this.props;
    if (userProfile.profile === null || userProfile.profile === undefined) {
      dispatchGetUserProfile();
    }
    dispatchGetFilterRequest();
    this.computeValues();
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.userProfile, nextProps.userProfile)) {
      this.computeValues(nextProps);
    }
  }

  computeValues = (props = this.props) => {
    const {
      userProfile: { userPrefs, filters, profile }
    } = props;
    if (profile !== null && userPrefs !== null && filters !== null) {
      let filterArray = [];
      _.each(userPrefs, (_val, key) => {
        if (
          key === 'fl_space_type' ||
          key === 'fl_customer' ||
          key === 'fl_channel'
        ) {
          const { fl_space: spaceData } = filters;
          const spaceTypeVal =
            filters.fl_space_type.options.filter(el => el.is_default === true)
              .length === 0
              ? filters.fl_space_type.options[0]
              : filters.fl_space_type.options.filter(
                  el => el.is_default === true
                )[0];
          const spaceTypeValueFilter = {
            options: [],
            value: {}
          };
          const appSpaceData = {
            fl_channel: {
              options: {},
              name: 'Channel'
            },
            fl_customer: {
              options: {},
              name: 'Customer'
            },
            fl_space_type: {
              options: filters.fl_space_type.options.map(e => e),
              value: spaceTypeVal,
              name: 'Space Type'
            }
          };
          const { value: spaceTypeValueType } = spaceTypeVal;
          /* --------fill the space type value options with the channel's and customer's union [fl_space_type] data-------*/
          spaceData.fl_channel.forEach(element => {
            element[spaceTypeValueType].forEach(el => {
              spaceTypeValueFilter.options.push(el);
            });
          });
          spaceData.fl_customer.forEach(element => {
            element[spaceTypeValueType].forEach(el => {
              spaceTypeValueFilter.options.push(el);
            });
          });
          spaceTypeValueFilter.options = uniqBy(
            spaceTypeValueFilter.options,
            'value'
          );
          spaceTypeValueFilter.options.unshift(optionAll);
          spaceTypeValueFilter.value =
            spaceTypeValueFilter.options.filter(el => el.is_default === true)
              .length === 0
              ? spaceTypeValueFilter.options[0]
              : spaceTypeValueFilter.options.filter(
                  el => el.is_default === true
                )[0];
          /* --------filter the customer based on the [fl_space_type] value-------*/
          appSpaceData.fl_customer.options = _.map(
            filters.fl_space[spaceTypeVal.value].fl_customer.filter(
              e =>
                e[spaceTypeVal.value].filter(
                  item => item.value === spaceTypeValueFilter.value.value
                ).length !== 0
            ),
            ({ value, label, is_default: isDefault }) => ({
              value,
              label,
              is_default: isDefault
            })
          );
          appSpaceData.fl_customer.options.unshift(optionAll);
          appSpaceData.fl_customer.value =
            appSpaceData.fl_customer.options.filter(
              el => el.is_default === true
            ).length === 0
              ? appSpaceData.fl_customer.options[0]
              : appSpaceData.fl_customer.options.filter(
                  el => el.is_default === true
                )[0];
          /* --------filter the channel based on the [fl_space_type] value-------*/
          appSpaceData.fl_channel.options = _.map(
            filters.fl_space[spaceTypeVal.value].fl_channel.filter(
              e =>
                e[spaceTypeVal.value].filter(
                  item => item.value === spaceTypeValueFilter.value.value
                ).length !== 0
            ),
            ({ value, label, is_default: isDefault }) => ({
              value,
              label,
              is_default: isDefault
            })
          );
          appSpaceData.fl_channel.options.unshift(optionAll);
          appSpaceData.fl_channel.value =
            appSpaceData.fl_channel.options.filter(el => el.is_default === true)
              .length === 0
              ? appSpaceData.fl_channel.options[0]
              : appSpaceData.fl_channel.options.filter(
                  el => el.is_default === true
                )[0];
          this.appSpaceData = appSpaceData;
          _.map(appSpaceData, (val, fl) => {
            filterArray.push({ ...val, key: fl });
          });
          appSpaceData.spaceTypeValueFilter = {
            options: spaceTypeValueFilter.options,
            value: { ...spaceTypeValueFilter.value }
          };
          filterArray.push({
            options: spaceTypeValueFilter.options,
            value: { ...spaceTypeValueFilter.value },
            key: spaceTypeValueType,
            name: appSpaceData.fl_space_type.value.label
          });
        } else {
          const filter = filters[key];
          let obj = {
            name: '',
            key: ''
          };
          if (filter) {
            if (filter.options) {
              obj.value =
                filter.options.filter(el => el.is_default === true).length === 0
                  ? filter.options[0]
                  : filter.options.filter(el => el.is_default === true)[0];
            }
          }
          obj = { ...obj, key, ...filters[key] };
          filterArray.push(obj);
        }
      });

      filterArray = filterArray.filter(
        el => el.options !== undefined && el.options !== null
      );
      filterArray = [...uniqBy(filterArray, 'key')];
      this.setState(() => ({ prefsList: filterArray }));
    }
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} val - Changed value
   * @param {string} key - value key
   * @param {boolean} useCallback - Whether to use the callback
   * @return {undefined}
   */
  handlePrefsChange = (val, key, useCallback = true) => {
    if (useCallback) {
      this.handleFilterCallback(val);
    }
    const { chartParamsValue } = this.props;
    const value = key !== undefined ? { [key]: val } : val;
    const chartParams = { ...chartParamsValue };
    const { chartName, grType, filterType } = grModel;
    if (value[filterType]) {
      const grValue =
        value[filterType].value === 'display' ? 'brand-form' : 'brand';
      chartName.map(chname => {
        if (chartParams[chname] !== undefined) {
          chartParams[chname][grType] = {
            ...chartParams[chname][grType],
            type: grType,
            value: grValue
          };
        }
        return chname;
      });
    }
    this.filterChangeValue = { ...this.filterChangeValue, ...value };
  };

  handleFilterCallback = spaceValue => {
    const spaceList = [];
    _.map(spaceValue, (val, key) => {
      const updateObj = {};
      const { value, label, is_default: isDefault, options } = val;
      updateObj.key = key;
      updateObj.value = {
        value,
        label,
        is_default: isDefault
      };
      updateObj.options = options;
      spaceList.push(updateObj);
    });
    this.setState(prev => {
      const prevPrefList = [...prev.prefsList];
      spaceList.map(fl => {
        const index = prevPrefList.findIndex(x => x.key === fl.key);
        if (fl.key === 'fl_space_type') {
          const prevSpaceType = {
            ...prevPrefList.filter(el => el.key === 'fl_space_type')[0]
          };
          if (prevSpaceType) {
            const prevVal = prevSpaceType.value.value;
            const spliceIndex = prevPrefList.findIndex(x => x.key === prevVal);
            const itemValue = spaceValue[spaceValue.fl_space_type.value];
            const obj = {
              value: { value: itemValue.value, label: itemValue.label },
              options: itemValue.options,
              key: itemValue.f,
              name: spaceValue.fl_space_type.label
            };
            prevPrefList[spliceIndex].value = undefined;
            prevPrefList.splice(spliceIndex, 0, obj);
          }
        }
        prevPrefList[index].value = fl.value;
        prevPrefList[index].options = fl.options;
        return fl;
      });
      let cleanedList = prevPrefList.filter(
        el => el.options !== undefined && el.options !== null
      );
      cleanedList = [...uniqBy(cleanedList, 'key')];
      return { ...prev, prefsList: cleanedList };
    });
  };

  handleFilterChange = val => {
    const {
      userProfile: { filters }
    } = this.props;
    const appSpaceData = {
      channelFilter: this.appSpaceData.fl_channel,
      customerFilter: this.appSpaceData.fl_customer,
      spaceTypeFilter: this.appSpaceData.fl_space_type,
      spaceTypeValueFilter: this.appSpaceData.spaceTypeValueFilter
    };
    const args = {
      value: val.value,
      spaceData: filters.fl_space,
      appSpaceData,
      callback: this.handlePrefsChange
    };
    if (val.value.f === 'fl_channel') {
      handleChannelFilterChange(args);
    } else if (val.value.f === 'fl_customer') {
      handleCustomerFilterChange(args);
    } else if (val.value.f === 'fl_space_type') {
      handleSpaceFilterChange(args);
    } else {
      const { length } = filters.fl_space_type.options.filter(
        el => el.value === val.value.f
      );
      if (length !== 0) {
        handleSpaceTypeFilterChange(args);
      } else {
        this.handleChange(
          val.value,
          val.value.f,
          filters,
          this.handlePrefsChange
        );
        this.handleFilterCallback({
          [val.value.f]: {
            ...val.value,
            options: [...filters[val.value.f].options]
          }
        });
      }
    }
  };

  handleChange = (val, key, filters, callback) => {
    if (filters[key].use_value_dropdown) {
      const valueObject =
        filters[val.value].options.filter(el => el.is_default === true)
          .length === 0
          ? filters[val.value].options[0]
          : filters[val.value].options.filter(el => el.is_default === true)[0];
      const value = {
        [key]: val,
        [val.value]: valueObject
      };
      callback(value, undefined, false);
    } else callback(val, key, false);
  };

  handlePreferenceSave = () => {
    const { prefsList } = this.state;
    const { dispatchSaveUserPrefs, dispatchClearFilterList } = this.props;
    const requestObject = {};
    _.each(prefsList, fl => {
      if (fl.value) {
        requestObject[fl.key] = fl.value.value;
      }
    });
    dispatchClearFilterList();
    // _.map(filterPrefs, (_val, key) => {
    //   // dispatchFilterChange({
    //   //   routeID: key,
    //   //   value: this.filterChangeValue,
    //   // });
    // });
    dispatchSaveUserPrefs({
      params: requestObject
    });
  };

  render() {
    const {
      userProfile: { profile, userPrefs, filters },
      loadingState
    } = this.props;
    const { prefsList } = this.state;
    return (
      <div>
        {profile !== null &&
        userPrefs !== null &&
        filters !== null &&
        prefsList !== null ? (
          <UserProfile
            isLoading={loadingState}
            handleFilterChange={this.handleFilterChange}
            handlePreferenceSave={this.handlePreferenceSave}
            profile={profile}
            userPrefs={userPrefs}
            prefsList={prefsList}
          />
        ) : null}
      </div>
    );
  }
}

UserProfileContainer.propTypes = {
  dispatchGetUserProfile: PropTypes.func.isRequired,
  dispatchSaveUserPrefs: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  loadingState: PropTypes.bool.isRequired
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const routeID = getRouteID('/share-of-shelf');
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getChartList = chartSelector.makeGetChartList();
  const getFilterPrefs = appSelector.makeGetAllFilterPreferences();
  const mapStateToProps = state => ({
    chartList: getChartList(state, { routeID }),
    chartParamsValue: getParamsValueList(state, { routeID }),
    filterPrefs: getFilterPrefs(state),
    userProfile: userSelector.getUserProfile(state),
    loadingState: userSelector.getLoadingState(state)
  });
  return mapStateToProps;
};
/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  dispatchClearFilterList: () => dispatch(appActions.clearFilterList()),
  dispatchGetFilterRequest: () => dispatch(appActions.getFilterListAction()),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  dispatchGetUserProfile: payload =>
    dispatch(userActions.getUserProfileAction(payload)),
  dispatchSaveUserPrefs: payload =>
    dispatch(userActions.saveUserPreferenceAction(payload))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);

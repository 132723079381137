import { createAction } from '@reduxjs/toolkit';
import { storeExplorerTypes } from 'redux-utils/types/index';
import apiModel from 'models/apiModel';
import reducerModel from 'models/reducerModel';

const getDebugProblemsAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.getDebugProblems.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getDebugProblems,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    cacheRequest: false,
    targetAction: storeExplorerTypes.getDebugProblems.success,
    requestAction: storeExplorerTypes.getDebugProblems.fromAPI
  });
  action.meta = meta;
  return action;
};

const updateProductFacingAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.updateProductFacing.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.updateProductFacing,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    cacheRequest: false,
    targetAction: storeExplorerTypes.updateProductFacing.success,
    requestAction: storeExplorerTypes.updateProductFacing.fromAPI
  });
  action.meta = meta;
  return action;
};

const fixProductFacingAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.fixProductFacing.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.fixProductFacing,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    cacheRequest: false,
    targetAction: storeExplorerTypes.fixProductFacing.success,
    requestAction: storeExplorerTypes.fixProductFacing.fromAPI
  });
  action.meta = meta;
  return action;
};

const productFilterAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.productFilter.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.productFilter,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    cacheRequest: false,
    targetAction: storeExplorerTypes.productFilter.success,
    requestAction: storeExplorerTypes.productFilter.fromAPI
  });
  action.meta = meta;
  return action;
};

const getDashboardDebugAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.getDashboardDebug.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getDashboardDebug,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getDashboardDebug.success,
    requestAction: storeExplorerTypes.getDashboardDebug.fromAPI
  });
  action.meta = meta;
  return action;
};

const saveDashboardDebugAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.saveDashboardDebug.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.saveDashboardDebug,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.saveDashboardDebug.success,
    requestAction: storeExplorerTypes.saveDashboardDebug.fromAPI
  });
  action.meta = meta;
  return action;
};

const getShelfImageAction = (
  payload,
  meta = {
    reducerID: [reducerModel.storeExplorerNew.id]
  }
) => {
  const action = createAction(storeExplorerTypes.getShelfImage.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getShelfImage,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getShelfImage.success,
    requestAction: storeExplorerTypes.getShelfImage.fromAPI
  });
  action.meta = meta;
  return action;
};

const getStoreExplorerFilterAction = (
  payload,
  meta = { reducerID: [reducerModel.storeExplorerNew.id] }
) => {
  const action = createAction(
    storeExplorerTypes.getStoreExplorerFilter.request
  )({
    ...payload,
    dbKey: {
      apiID: apiModel.getStoreExplorerFilter,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: storeExplorerTypes.getStoreExplorerFilter.success,
    requestAction: storeExplorerTypes.getStoreExplorerFilter.fromAPI
  });
  action.meta = meta;
  return action;
};
const resetProductFacingDetails = () => {
  const action = createAction(storeExplorerTypes.updateProductFacing.reset)();
  return action;
};

const resetFixProductFacingDetails = () => {
  const action = createAction(storeExplorerTypes.fixProductFacing.reset)();
  return action;
};

export {
  getShelfImageAction,
  getStoreExplorerFilterAction,
  getDebugProblemsAction,
  saveDashboardDebugAction,
  getDashboardDebugAction,
  fixProductFacingAction,
  productFilterAction,
  updateProductFacingAction,
  resetProductFacingDetails,
  resetFixProductFacingDetails
};

import React from 'react';
// library to set properties for components
import PropTypes from 'prop-types';
// toggle component for react
import { Toggle } from 'react-toggle-component';
// styles
import './styles.scss';

/**
 * @class
 * @hideconstructor
 * @description Core Toggle component
 */
const ToggleComponent = ({
  handleToggle,
  label,
  labelRight,
  isChecked,
  name
}) => (
  <Toggle
    name={name}
    label={label}
    labelRight={labelRight}
    checked={isChecked}
    onToggle={handleToggle}
  />
);

// component properties
ToggleComponent.propTypes = {
  /**
   * @type {boolean}
   * @description - to turn on the the toggle true | false
   */
  isChecked: PropTypes.bool,
  /**
   * @type {string}
   * @description - label to display on the left
   */
  label: PropTypes.string,
  /**
   * @type {string}
   * @description - label to display on the right
   */
  labelRight: PropTypes.string,
  /**
   * @type {string}
   * @description - name of the label component
   */
  name: PropTypes.string,
  /**
   * @type {string}
   * @description - callback to handle the toggle state change
   */
  handleToggle: PropTypes.func.isRequired
};

ToggleComponent.defaultProps = {
  isChecked: false,
  label: '',
  labelRight: '',
  name: 'toggle-1'
};

export default ToggleComponent;

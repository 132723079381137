const types = {
  getData: {
    request: 'GET_SE_DATA_REQUEST',
    success: 'GET_SE_DATA_SUCCESS',
    failure: 'GET_SE_DATA_FAILURE'
  },
  getFilters: {
    request: 'GET_SE_FILTER_REQUEST',
    success: 'GET_SE_FILTER_SUCCESS',
    failure: 'GET_SE_FILTER_FAILURE'
  }
};

export default types;

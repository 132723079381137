/* eslint-disable import/prefer-default-export */
import { db } from 'db';

const toggleChartPinStatus = (dbKey, chartname, isPinned) => {
  db.apiCache
    .where(dbKey)
    .modify((value, ref) => {
      const cachedResponse = { ...value };
      const targetIndex = cachedResponse.response.data
        .map(el => el.name)
        .indexOf(chartname);
      cachedResponse.response.data[targetIndex].is_pinned = isPinned;
      // eslint-disable-next-line no-param-reassign
      ref.value = cachedResponse;
    })
    .catch(err => {
      console.log('modify error', err);
    });
};

export { toggleChartPinStatus };

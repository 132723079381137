import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import cardBodyStyle from 'assets/jss/material-ui/components/cardBodyStyle';
import { drawerWidth } from 'assets/jss/material-ui/material-ui';

/**
 * @class
 * @hideconstructor
 * @description Core Card component
 */
const Body = ({ ...props }) => {
  const {
    classes,
    className,
    color,
    children,
    chart,
    plain,
    map,
    table,
    stats,
    maxWidth: _mw,
    ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes[`${color}CardBody`]]: color,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyChart]: chart,
    [classes.cardBodyStats]: stats,
    [classes.cardBodyMap]: map,
    [classes[`${color}CardHeader`]]: color,
    [classes.table]: table,
    [className]: className !== undefined
  });

  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};

// component properties
Body.propTypes = {
  /**
   * @type {object}
   * @description - Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @description - Children elements
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  /**
   * @type {string}
   * @description - Background color of the card - 'warning' | 'success' | 'danger'|
   * 'info' | 'primary' | 'dark'
   */
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'dark',
    ''
  ]),
  /**
   * @type {string}
   * @description - Class name to apply for the root element
   */
  className: PropTypes.string,
  /**
   * @type {string}
   * @description - maximum width of the root element
   */
  maxWidth: PropTypes.string,
  /**
   * @type {boolean}
   * @description - Plain card without any background and shadow
   */
  plain: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a chart
   */
  chart: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a map
   */
  map: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains statistics
   */
  stats: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the card contains a table
   */
  table: PropTypes.bool
};

Body.defaultProps = {
  maxWidth: `calc(${window.innerWidth}px - ${drawerWidth}px - 50px)`,
  color: '',
  className: '',
  plain: false,
  chart: false,
  map: false,
  stats: false,
  table: false
};

const CardBody = withStyles(cardBodyStyle)(props => <Body {...props} />);

CardBody.propTypes = {
  /**
   * @type {string}
   * @description - Height of the card
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CardBody.defaultProps = {
  height: 300
};

export default CardBody;

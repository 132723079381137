/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
// library to set component properties
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
// @material-ui/core
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Dropdown from 'components/Dropdown/Dropdown';
import { HSpacer } from 'components/Spacer/Spacer';
import ls from 'lib/core/storageFactory';

/**
 * @ignore
 * @name FilterToolbarStyles
 * @description toolbarStyles for Filter component
 */
const toolbarStyles = theme => ({
  root: {
    paddingBottom: '13px',
    // marginTop: '-19px',
    marginTop: theme.palette.type === 'light' ? '20px' : '0px',
    paddingRight: theme.palette.type === 'light' ? '0px' : 'inherit',
    minHeight: '50px',
    paddingLeft: 0,
    position: theme.palette.type === 'dark' ? 'sticky' : 'static',
    top: '0',
    backgroundColor:
      theme.palette.type === 'dark' ? 'rgb(30, 30, 43)' : 'inherit',
    zIndex: '6',
    display: theme.palette.type === 'light' && 'flex',
    flexDirection: theme.palette.type === 'light' ? 'column' : 'none',
    alignItems: theme.palette.type === 'light' ? 'center' : 'none',
    '& div[class*="container"]': {
      marginBottom: theme.palette.type === 'light' ? '20px' : 'inherit'
    }
  }
});

/**
 * @class GenericSEFilters
 * @hideconstructor
 * @description Class component for space, time, region global filters
 */
const GenericSEFilters = ({
  classes,
  filtersToRender,
  handleFilterChange,
  dropdownStyles,
  dropdownTitleStyles,
  dashboardType,
  handleCurrentFilterChange,
  filterState,
  isFetchingFilter
}) => {
  const [sessionId, setSessionId] = useState(null);
  const { search } = useLocation();
  useEffect(() => {
    const session = new URLSearchParams(search).get('q');
    const sessionIdFromLS = ls.get('sessionId');
    setSessionId(session || sessionIdFromLS);
  }, [search]);
  return (
    <Toolbar className={classes.root}>
      {Object.keys(filterState).map(item => {
        const { label: title, value, key, options } = filterState[item];
        const optionsFromStorage = ls.get(key);
        // console.log('childFilter', item, filterState);
        if (options.length && isEmpty(value)) {
          handleCurrentFilterChange({ val: options[0], key });
        }
        return (
          <React.Fragment key={title}>
            <Dropdown
              title={title}
              handleValueChange={val => handleCurrentFilterChange({ val, key })}
              options={
                options.length
                  ? options
                  : optionsFromStorage && optionsFromStorage.length && sessionId
                  ? optionsFromStorage
                  : []
              }
              value={value}
              useMenuPortalTarget={
                false
              } /* wihout this sidemenu filters dropdown won't open  */
              dropdownTitleStyles={dropdownTitleStyles}
              dropdownStyles={dropdownStyles}
              dashboardType={dashboardType}
              isDisabled={!options.length && isFetchingFilter}
              placeholder={options.length ? 'Select...' : 'Select ↑'}
              isLoading={isFetchingFilter && !options.length}
              showCopy={true}
            />
            <HSpacer amount={20} />
          </React.Fragment>
        );
      })}
    </Toolbar>
  );
};

// component properties
GenericSEFilters.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {array}
   * @description filter list
   */
  filtersToRender: PropTypes.array.isRequired,
  /**
   * @type {callback}
   * @description callback function
   */
  handleFilterChange: PropTypes.func.isRequired,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object
};

GenericSEFilters.defaultProps = {
  dropdownTitleStyles: {},
  dropdownStyles: {}
};

export default withStyles(toolbarStyles)(GenericSEFilters);

import React, { useState } from 'react';
import { array, func, object } from 'prop-types';
import { isEmpty, map } from 'lodash';

import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Icon from 'components/Icon/Icon';
import ToggleComponent from 'components/Toggle/Toggle';
import Typography from 'components/Typography/Typography';
import Loader from 'components/Loader/Loader';
import { VSpacer } from 'components/Spacer/Spacer';

import { DownloadIcon } from 'assets/icons/appIcons';
import { drawerWidth } from 'assets/jss/material-ui/material-ui';

import enums from 'models/enums';
import AccuracyCards from './AccuracyCards';
import AccuracyFilter from './AccuracyFilter';
import AccuracyTable from './AccuracyTableWrapper';
import CanvasWrapper from './CanvasWrapper';
import SaveExcelModal from './SaveExcelModal';

const ITEM_HEIGHT = 48;

const styles = {
  root: {
    height: '87vh',
    width: `calc(100vw - ${drawerWidth + 30}px)`,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  toolbar: {
    position: 'fixed',
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    width: 'inherit',
    minHeight: 50,
    padding: 0,
    paddingBottom: 10,
    marginBottom: 13,
    background: '#1e1e2b',
    zIndex: 3,
    '&>label': {
      marginTop: 25,
      color: 'rgba(255,255,255,0.6)'
    }
  },
  title: {
    margin: '15px 0'
  },
  excelIcon: {
    marginTop: 25,
    marginLeft: 'auto'
  },
  content: {
    marginTop: 80
  }
};

const AccuracyPage = props => {
  const {
    activeSession,
    classes,
    chartData,
    chartList,
    filtersToRender,
    handleFilterChange,
    handleSessionChange,
    sessionDetails,
    sessionList,
    apiRequestStatus,
    postScheduledReport,
    updateScheduledReport,
    deleteScheduledReport,
    dispatchPostScheduledReport,
    dispatchUpdateScheduledReport,
    dispatchDeleteScheduledReport,
    dispatchDownloadReport,
    scheduledReports,
    scheduledReportsMetaData
  } = props;

  const [compareVersions, setCompareVersions] = useState(false);
  const [excelModalState, setExcelModalState] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [tableTooltipStatus, setTableTooltipStatus] = useState({
    status: false,
    key: ''
  });

  const handleExcelClick = event => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleDownloadMenuClose = () => {
    setDownloadAnchorEl(null);
  };

  const handleScheduleClick = () => {
    setDownloadAnchorEl(null);
    setExcelModalState(prev => !prev);
  };

  const handleDownloadNowClick = () => {
    setDownloadAnchorEl(null);
    dispatchDownloadReport({ fl_session: activeSession.value });
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <AccuracyFilter
          filtersToRender={filtersToRender}
          handleFilterChange={handleFilterChange}
        />
        {sessionDetails.show_toggle === true ? (
          <label htmlFor="compare-versions-toggle">
            Compare Versions
            <ToggleComponent
              handleToggle={() => {
                setCompareVersions(prev => !prev);
              }}
              isChecked={compareVersions}
              name="compare-versions-toggle"
            />
          </label>
        ) : null}
        <span className={classes.excelIcon}>
          <Icon color="light">
            <DownloadIcon
              onClick={handleExcelClick}
              aria-haspopup="true"
              aria-controls="download-menu"
            />
          </Icon>
          <Menu
            id="download-menu"
            anchorEl={downloadAnchorEl}
            open={Boolean(downloadAnchorEl)}
            onClose={handleDownloadMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem
              onClick={() => handleDownloadNowClick()}
              disabled={
                !(activeSession !== undefined && activeSession.value !== '')
              }
            >
              Download now
            </MenuItem>
            <MenuItem onClick={() => handleScheduleClick()}>Schedule</MenuItem>
          </Menu>
          <SaveExcelModal
            apiRequestStatus={apiRequestStatus}
            postScheduledReport={postScheduledReport}
            updateScheduledReport={updateScheduledReport}
            deleteScheduledReport={deleteScheduledReport}
            filtersToRender={filtersToRender}
            isModalOpen={excelModalState}
            setIsModalOpen={setExcelModalState}
            scheduledReports={scheduledReports}
            scheduledReportsMetaData={scheduledReportsMetaData}
            dispatchUpdateScheduledReport={dispatchUpdateScheduledReport}
            dispatchPostScheduledReport={dispatchPostScheduledReport}
            dispatchDeleteScheduledReport={dispatchDeleteScheduledReport}
          />
        </span>
      </Toolbar>
      <div className={classes.content}>
        {isEmpty(chartData) &&
        isEmpty(sessionDetails) &&
        isEmpty(sessionList) ? (
          <>
            <VSpacer amount={20} />
            <Typography align="center">
              Data not available for the selected filters
            </Typography>
          </>
        ) : (
          <>
            <AccuracyCards
              compareVersions={compareVersions}
              accuracyNumbers={sessionDetails.accuracy_banners}
              apiRequestStatus={apiRequestStatus}
            />
            {!isEmpty(sessionList) && (
              <CanvasWrapper
                activeSession={activeSession}
                compareVersions={compareVersions}
                sessionList={sessionList}
                IRData={sessionDetails.images}
                handleSessionChange={handleSessionChange}
              />
            )}
            {!isEmpty(chartData) && (
              <Typography
                className={classes.title}
                transform="capitalize"
                weight={400}
                size={16}
                color="rgba(255, 255, 255, 0.8)"
              >
                {map(filtersToRender, fl => {
                  return `${fl.name}: ${fl.value.label} | `;
                })}
              </Typography>
            )}
            <>
              {!isEmpty(chartData) &&
                chartList.map(
                  chart =>
                    chartData[chart.name] && (
                      <AccuracyTable
                        key={chart.name}
                        chartName={chart.name}
                        enablePreviousVersion={compareVersions}
                        tableData={chartData[chart.name]}
                        setTableTooltipStatus={setTableTooltipStatus}
                        tableTooltipStatus={tableTooltipStatus}
                      />
                    )
                )}
            </>
          </>
        )}
      </div>
      {apiRequestStatus.getChartData.status === enums.apiRequest.REQUEST ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader color="primary" simple circular />
        </div>
      ) : null}
    </div>
  );
};

AccuracyPage.propTypes = {
  activeSession: object,
  classes: object.isRequired,
  chartData: object,
  chartList: array,
  filtersToRender: object.isRequired,
  handleFilterChange: func,
  handleSessionChange: func,
  sessionDetails: object,
  sessionList: array.isRequired,
  apiRequestStatus: object.isRequired,
  postScheduledReport: object.isRequired,
  updateScheduledReport: object.isRequired,
  deleteScheduledReport: object.isRequired,
  dispatchPostScheduledReport: func.isRequired,
  dispatchUpdateScheduledReport: func.isRequired,
  dispatchDeleteScheduledReport: func.isRequired,
  dispatchDownloadReport: func.isRequired,
  scheduledReports: array,
  scheduledReportsMetaData: object
};

AccuracyPage.defaultProps = {
  activeSession: {},
  chartData: {},
  chartList: [],
  scheduledReports: [],
  scheduledReportsMetaData: {}
};

AccuracyPage.defaultProps = {
  handleSessionChange: () => {},
  handleFilterChange: () => {},
  sessionDetails: { accuracyNumbers: {}, images: {} }
};

export default withStyles(styles)(AccuracyPage);

import React from 'react';
// library to concatenates classes
import classNames from 'classnames';
import styled from 'styled-components';
// library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
// assets
import buttonStyle from 'assets/jss/material-ui/components/buttonStyle';

const StyledButton = styled(Button)`
  && {
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    font-size: ${props => props.fontSize};
    :hover {
      color: ${props => props.color};
      background-color: ${props => props.bgColor};
      font-size: ${props => props.fontSize};
    }
  }
`;

/**
 * @class
 * @hideconstructor
 * @description Core Button component
 */
const RegularButton = ({ ...props }) => {
  const {
    classes,
    color,
    font,
    round,
    outline,
    children,
    disabled,
    simple,
    size,
    block,
    active,
    link,
    loader,
    justIcon,
    className,
    muiClasses,
    rect,
    fit,
    primaryButtonStyles,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes[font]]: font,
    [classes.round]: round,
    [classes.outline]: outline,
    [classes.loader]: loader,
    [classes.active]: active,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.rect]: rect,
    [classes.fit]: fit,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  });
  return (
    <StyledButton
      {...rest}
      classes={muiClasses}
      className={btnClasses}
      bgColor={primaryButtonStyles?.backgroundColor}
      fontSize={primaryButtonStyles?.fontSize}
      color={primaryButtonStyles?.textColor}
    >
      {children}
    </StyledButton>
  );
};

// component properties
RegularButton.propTypes = {
  /**
   * @type {object}
   * @description - Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @description - Children
   */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  /**
   * @type {string}
   * @description - Font style option - styled | normal
   */
  font: PropTypes.string,
  /**
   * @type {string}
   * @description - Color of the button - 'primary' | 'dark' | 'info'| 'success' |
   * 'warning' | 'danger' | 'rose' | 'white' | 'transparent' | 'home'
   */
  color: PropTypes.oneOf([
    'primary',
    'dark',
    'gray',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'transparent',
    'home'
  ]),
  /**
   * @type {string}
   * @description - Size of the button - 'sm' | 'lg'
   */
  size: PropTypes.oneOf(['sm', 'lg', '']),
  /**
   * @type {boolean}
   * @description - Whether to apply simple button style
   */
  simple: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is round
   */
  round: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button has loader
   */
  loader: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is outlines
   */
  outline: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is active
   */
  active: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is a block
   */
  block: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether the button is used as link
   */
  link: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Whether it is a icon button
   */
  justIcon: PropTypes.bool,
  /**
   * @type {string}
   * @description - Classname to apply to the root element
   */
  className: PropTypes.string,
  /**
   * @type {boolean}
   * @description - A rectangular button with no border radius
   */
  rect: PropTypes.bool,
  /**
   * @type {boolean}
   * @description - Fit to the container
   */
  fit: PropTypes.bool,
  /**
   * @type {object}
   * @description - use this to pass the classes props from Material-UI
   */
  muiClasses: PropTypes.object,
  primaryButtonStyles: PropTypes.object
};

RegularButton.defaultProps = {
  color: 'primary',
  size: '',
  font: 'styled',
  simple: false,
  loader: false,
  round: false,
  outline: false,
  active: false,
  disabled: false,
  block: false,
  link: false,
  justIcon: false,
  className: '',
  rect: false,
  fit: false,
  muiClasses: {},
  primaryButtonStyles: {}
};

export default withStyles(buttonStyle)(RegularButton);

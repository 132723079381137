import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
// redux-utils
import { chartTypes, cacheTypes } from 'redux-utils/types';
import { chartActions } from 'redux-utils/actions';
import { get } from 'api';

/**
 * @name chartWatcherSaga
 * @description Catches the action dispatched of certain type.
 */
const watcherSaga = [
  takeLatest(chartTypes.getChartList.fromAPI, chartListSaga),
  takeEvery(chartTypes.getChartData.fromAPI, chartDataSaga),
  takeEvery(chartTypes.getStoreCount.fromAPI, storeCountSaga),
  takeEvery(chartTypes.downloadChartData.fromAPI, downloadChartDataSaga)
];

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* downloadChartDataSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_GENERATE_REPORT,
    payload: action.payload.params,
    useCachedInstance: false,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      type: chartTypes.downloadChartData.failure,
      payload: { message: error.data, request: action.payload },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* chartDataSaga(action) {
  const { routeID } = action.payload;
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CHART_DATA,
    payload: action.payload.params,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
    if (!routeID.includes('store-explorer')) {
      yield put(
        chartActions.getStoreCountAction({
          ...action.payload
        })
      );
    }
  } else {
    yield put({
      type: chartTypes.getChartData.failure,
      payload: { message: error.data, request: action.payload },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* storeCountSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_STORE_COUNT,
    payload: action.payload.params,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      type: chartTypes.getStoreCount.failure,
      payload: { message: error.data, request: action.payload },
      error: true
    });
  }
}

/**
 * @description Worker saga to get the filter list of a route
 * @param {object} action - dispatched action
 * @returns {undefined}
 * @yields put - get filter list success/error action.
 */
function* chartListSaga(action) {
  const { response, error } = yield call(get, {
    uriPath: process.env.REACT_APP_SHELF_CHART_LIST,
    payload: action.payload.params,
    useCachedInstance: false,
    headers: ['auth']
  });
  if (response) {
    const { data } = response;
    yield put({
      type: cacheTypes.store.request,
      payload: {
        ...action.payload,
        response: data
      }
    });
  } else {
    yield put({
      type: chartTypes.getChartList.failure,
      payload: { ...action.payload, message: error.data },
      error: true
    });
  }
}

export default watcherSaga;

import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
// redux-utils
import { genericSETypes as actionTypes } from 'redux-utils/types/index';
import { actionTypeFormatter } from 'redux-utils/sagas/index';
// api helpers
import { get, endpoints, post } from 'api/index';

/**
 * @name appWatcherSaga
 * @description Watches for the action dispatched of certain type.
 */
const watcherSaga = [
  takeEvery(actionTypes.getChartData.request, getWorkerSaga),
  takeEvery(actionTypes.getChartList.request, getWorkerSaga),
  takeEvery(actionTypes.getFilters.request, getWorkerSaga),
  takeLatest(actionTypes.getSessionData.request, getWorkerSaga),
  takeEvery(actionTypes.downloadChartData.request, getWorkerSaga),
  takeEvery(actionTypes.sessionFilterValues.request, getWorkerSaga),
  takeEvery(actionTypes.getFilter.request, getWorkerSaga),
  takeEvery(actionTypes.getBottomUpFilter.request, getWorkerSaga)
];

function getWorkerSaga(action) {
  switch (action.type) {
    case actionTypes.getChartData.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.chartData,
        method: get,
        headers: ['auth']
      });
    }
    case actionTypes.getFilter.request: {
      const apiVersion = action.payload.params.api_version;
      return apiWorkerSaga(action, {
        uriPath:
          apiVersion === 'v4'
            ? endpoints.storeExplorer.v4filter
            : endpoints.storeExplorer.filter,
        method: post,
        headers: ['auth']
      });
    }
    case actionTypes.getBottomUpFilter.request: {
      const filterType = action.payload.params.filter_type;
      const apiVersion = action.payload.params.api_version;
      console.log('the filter type is', filterType, apiVersion);
      const shouldCallOldApi = apiVersion === 'v2';

      return apiWorkerSaga(action, {
        uriPath: shouldCallOldApi
          ? endpoints.storeExplorer.filter
          : endpoints.storeExplorer.v4filter,
        method: post,
        headers: ['auth']
      });
    }
    case actionTypes.downloadChartData.request: {
      return apiWorkerSaga(action, {
        uriPath: process.env.REACT_APP_SHELF_GENERATE_REPORT,
        method: get,
        headers: ['auth']
      });
    }
    case actionTypes.getChartList.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.chartList,
        method: get,
        headers: ['auth']
      });
    }
    case actionTypes.getFilters.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.storeExplorer.filters,
        method: get,
        headers: ['auth']
      });
    }
    case actionTypes.getSessionData.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.storeExplorer.sessionData,
        method: get,
        headers: ['auth']
      });
    }
    case actionTypes.sessionFilterValues.request: {
      return apiWorkerSaga(action, {
        uriPath: endpoints.storeExplorer.sessionFilterValues,
        method: get,
        headers: ['auth']
      });
    }
    default:
      return null;
  }
}

/**
 * @description Get layout worker saga
 * @param {object} action - dispatched action
 * @param {object} params - api params
 * @returns {undefined}
 * @yields put - get layout success/error action.
 */
function* apiWorkerSaga(action, params) {
  try {
    const { response, error, request } = yield call(params.method, {
      payload: action.payload.params,
      useCachedInstance: false,
      ...params
    });
    if (response) {
      // store the filters after successfull session data call - it is required in case of light theme (quick fix)
      if (action.type === actionTypes.getSessionData.request) {
        yield put({
          meta: action.meta,
          type: actionTypes.setSessionFilters,
          payload: action.payload.params
        });
      }
      yield put({
        meta: action.meta,
        type: actionTypeFormatter(action.type, 'SUCCESS'),
        payload: {
          ...action.payload,
          error: null,
          request,
          response: response.data
        }
      });
    } else {
      yield put({
        meta: action.meta,
        type: actionTypeFormatter(action.type, 'FAILURE'),
        payload: {
          ...action.payload,
          error: error.data,
          request,
          response: null
        }
      });
    }
  } catch (error) {
    yield put({
      meta: action.meta,
      type: 'SAGA_ERROR',
      payload: {
        type: action.type,
        ...action.payload,
        error
      }
    });
  }
}

export default watcherSaga;

import React from 'react';
import { ReactSVG } from 'react-svg';

import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import ResizeIcon from '@material-ui/icons/Height';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import DownloadAltIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DeleteAltIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLessIcon from '@material-ui/icons/ArrowDropUp';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowLeft from '@material-ui/icons/ArrowBack';
import ArrowRight from '@material-ui/icons/ArrowForward';
import Ballot from '@material-ui/icons/Ballot';
import BorderOuter from '@material-ui/icons/BorderOuter';
import Explore from '@material-ui/icons/Explore';
import Home from '@material-ui/icons/Home';
import Pin from '@material-ui/icons/Stars';
import PreferencesIcon from '@material-ui/icons/Tune';
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewColumn from '@material-ui/icons/ViewColumn';
import VerticalSplit from '@material-ui/icons/VerticalSplit';
import FormatAlignCenter from '@material-ui/icons/FormatAlignCenter';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Close from '@material-ui/icons/Close';
import shelf from 'assets/icons/svg/shelf.svg';
import check from 'assets/icons/svg/icon.svg';
import position from 'assets/icons/svg/pp.svg';
import display from 'assets/icons/svg/display.svg';
import explorer from 'assets/icons/svg/explorer.svg';
import dashboard from 'assets/icons/svg/dashboard.svg';
import tester from 'assets/icons/svg/tester.svg';
import promotion from 'assets/icons/svg/promotion.svg';
import excel from 'assets/icons/svg/excel.svg';
import tick from 'assets/svg/tick.svg';

const ShelfIcon = <ReactSVG src={shelf} />;
const OSAIcon = <ReactSVG src={check} />;
const PositionIcon = <ReactSVG src={position} />;
const DisplayIcon = <ReactSVG src={display} />;
const ExplorerIcon = <ReactSVG src={explorer} />;
const DashboardIcon = <ReactSVG src={dashboard} />;
const TesterIcon = <ReactSVG src={tester} />;
const SKUIcon = <ReactSVG src={promotion} />;
const TickIcon = props => <ReactSVG src={tick} {...props} />;
const DownloadIcon = props => <ReactSVG src={excel} {...props} />;

export {
  ArrowDownward,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  Ballot,
  BorderOuter,
  DashboardIcon,
  DisplayIcon,
  DownloadIcon,
  ResizeIcon,
  DeleteIcon,
  DeleteAltIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  Explore,
  ExplorerIcon,
  FormatAlignCenter,
  Home,
  LanguageIcon,
  ListIcon,
  MenuIcon,
  MoreVertIcon,
  OSAIcon,
  PersonIcon,
  RefreshIcon,
  EditIcon,
  SearchIcon,
  DownloadAltIcon,
  NavigateNextIcon,
  NavigateBeforeIcon,
  UploadIcon,
  AddIcon,
  Pin,
  PositionIcon,
  PreferencesIcon,
  ShelfIcon,
  SKUIcon,
  TesterIcon,
  TickIcon,
  ViewColumn,
  VerticalSplit,
  Close
};

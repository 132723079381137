import { createAction } from '@reduxjs/toolkit';
import { chartTypes } from 'redux-utils/types/index';
import apiModel from 'models/apiModel';
import reducerModel from 'models/reducerModel';

const getStoreCountAction = (
  payload,
  meta = { reducerID: [reducerModel.chart.id] }
) => {
  const action = createAction(chartTypes.getStoreCount.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getStoreCount,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: chartTypes.getStoreCount.success,
    requestAction: chartTypes.getStoreCount.fromAPI
  });
  action.meta = meta;
  return action;
};

const getChartDataAction = (
  payload,
  meta = { reducerID: [reducerModel.chart.id] }
) => {
  const action = createAction(chartTypes.getChartData.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getChartData,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: chartTypes.getChartData.success,
    requestAction: chartTypes.getChartData.fromAPI
  });
  action.meta = meta;
  return action;
};

const downloadChartDataAction = (
  payload,
  meta = {
    reducerID: [
      reducerModel.chart.id,
      reducerModel.storeExplorer.id,
      reducerModel.storeExplorerNew.id
    ]
  }
) => {
  const action = createAction(chartTypes.downloadChartData.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.downloadChartData,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: chartTypes.downloadChartData.success,
    requestAction: chartTypes.downloadChartData.fromAPI
  });
  action.meta = meta;
  return action;
};

const getChartListAction = (
  payload,
  meta = {
    reducerID: [
      reducerModel.app.id,
      reducerModel.chart.id,
      reducerModel.slicer.id
    ]
  }
) => {
  const action = createAction(chartTypes.getChartList.request)({
    ...payload,
    dbKey: {
      apiID: apiModel.getChartList,
      params: JSON.stringify(
        payload ? (payload.params ? payload.params : {}) : {}
      )
    },
    targetAction: chartTypes.getChartList.success,
    requestAction: chartTypes.getChartList.fromAPI
  });
  action.meta = meta;
  return action;
};

const granularityChangeAction = (
  payload,
  meta = { reducerID: [reducerModel.app.id] }
) => {
  const action = createAction(chartTypes.granularityChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const slicerChangeAction = (
  payload,
  meta = { reducerID: [reducerModel.slicer.id] }
) => {
  const action = createAction(chartTypes.slicerChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  getStoreCountAction,
  getChartDataAction,
  downloadChartDataAction,
  getChartListAction,
  granularityChangeAction,
  slicerChangeAction
};

import { forOwn } from 'lodash';
import menuManifest from 'models/menuModel';

const getRouteID = (path = window.location.pathname, routes = menuManifest) => {
  let routeID = '';
  forOwn(routes, (val, key) => {
    if (val.path === path) {
      routeID = key;
    }
  });
  return routeID;
};

const getSimilarRoutes = (string, routes = menuManifest) => {
  const routeID = [];
  forOwn(routes, (_v, key) => {
    if (key.includes(string)) {
      routeID.push(key);
    }
  });
  return routeID;
};

const getRoutePath = (routeID, routes = menuManifest) => {
  return routes[routeID] ? routes[routeID].path : '/';
};

export { getRouteID, getRoutePath, getSimilarRoutes };

import { createAction } from '@reduxjs/toolkit';
import { genericSETypes } from 'redux-utils/types/index';
import reducerModel from 'models/reducerModel';

const getChartData = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getChartData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getChartList = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getChartList.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getBottomUpFilter = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getBottomUpFilter.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getCurrentFilter = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getFilter.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getFilters = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getFilters.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getSessionData = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.getSessionData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const downloadChartData = (
  payload,
  meta = { reducerID: [reducerModel.genericSE.id] }
) => {
  const action = createAction(genericSETypes.downloadChartData.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const filterChangeAction = (
  payload,
  meta = {
    reducerID: [reducerModel.genericSE.id]
  }
) => {
  const action = createAction(genericSETypes.genericSEfilterChange)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const getSessionFilterValues = (
  payload,
  meta = {
    reducerID: [reducerModel.genericSE.id]
  }
) => {
  const action = createAction(genericSETypes.sessionFilterValues.request)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const clearSessionData = (
  payload,
  meta = {
    reducerID: [reducerModel.genericSE.id]
  }
) => {
  const action = createAction(genericSETypes.getSessionData.clear)({
    ...payload
  });
  action.meta = meta;
  return action;
};

const clearChartList = (
  payload,
  meta = {
    reducerID: [reducerModel.genericSE.id]
  }
) => {
  const action = createAction(genericSETypes.getChartList.clear)({
    ...payload
  });
  action.meta = meta;
  return action;
};

export {
  getFilters,
  getChartData,
  getChartList,
  getSessionData,
  getCurrentFilter,
  downloadChartData,
  filterChangeAction,
  getSessionFilterValues,
  clearSessionData,
  clearChartList,
  getBottomUpFilter

};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, map } from 'lodash';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
// core components
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Dropdown from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon/Icon';
import CardHeader from 'components/Card/CardHeader';
import Typography from 'components/Typography/Typography';
// chart
import LineChart from 'charts/line/LineChart';
// assets
import { Pin, DownloadIcon } from 'assets/icons/appIcons';
import { HSpacer } from 'components/Spacer/Spacer';
import ls from 'lib/core/storageFactory';

class LineChartComponent extends Component {
  state = {};

  client = ls.get('client');

  componentWillMount() {
    this.setActiveGranularity(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { granularities } = this.props;
    if (!isEqual(granularities, nextProps.granularities)) {
      this.setActiveGranularity(nextProps);
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  setActiveGranularity = props => {
    const { granularities } = props;
    map(granularities, gr => {
      this.setState(() => ({ [gr.type]: gr.value }));
    });
  };

  handleGranularityClick = ({
    chartName,
    grType,
    selectedValue,
    option: grObject,
    value,
    type
  }) => {
    this.setState(() => ({ [grType]: selectedValue }));
    if (type === 'filter') {
      return this.props.handleGranularityClick({ value }, grType, {
        [chartName]: { [grType]: value }
      });
    }
    return this.props.handleGranularityClick(grObject, grType, {
      [chartName]: { [grType]: selectedValue }
    });
  };

  handleSlicerChange = ({ chartName, slicerType, value: selectedValue }) =>
    this.props.handleSlicerChange({
      selectedValue,
      slicerType,
      chartName
    });

  render() {
    const {
      classes,
      data,
      chartName,
      title,
      granularities,
      slicers,
      isPinned,
      usePin,
      useExport,
      handlePinClick,
      handleDataDownload,
      cardStyles
    } = this.props;
    return (
      <>
        <Card className={classes.card} chart cardStyles={cardStyles}>
          <CardHeader chart float="right">
            <Typography className={classes.title} cardTitle>
              {title}
            </Typography>
            <div className={classes.granularities}>
              {map(slicers, (slicer, key) => (
                <>
                  <Dropdown
                    key={key}
                    title=""
                    handleValueChange={value => {
                      this.handleSlicerChange({
                        chartName,
                        slicerType: key,
                        value
                      });
                    }}
                    options={slicer.options}
                    value={slicer.value}
                  />
                  <HSpacer amount={20} />
                </>
              ))}
              {map(granularities, gr =>
                gr.filter_id ? (
                  <Dropdown
                    key={gr.filter_id}
                    title=""
                    handleValueChange={value => {
                      this.handleGranularityClick({
                        chartName,
                        grType: gr.filter_id,
                        value,
                        type: 'filter'
                      });
                    }}
                    options={gr.options}
                    value={gr.value}
                  />
                ) : (
                  <React.Fragment key={gr.type}>
                    {map(gr.options, option => {
                      const active = this.state[gr.type] === option.value;
                      return !option.isHidden ? (
                        <Button
                          key={option.value}
                          onClick={() =>
                            this.handleGranularityClick({
                              chartName,
                              grType: gr.type,
                              selectedValue: option.value,
                              option
                            })
                          }
                          active={active}
                          outline
                          size="sm"
                          color="primary"
                        >
                          {option.label}
                        </Button>
                      ) : null;
                    })}
                  </React.Fragment>
                )
              )}
            </div>
            <div className={classes.actionButtons}>
              {useExport ? (
                <Tooltip title="Download data" aria-label="Download data">
                  <Icon color="light" id={`${chartName}-excel-download-button`}>
                    <DownloadIcon onClick={handleDataDownload} />
                  </Icon>
                </Tooltip>
              ) : null}
              {usePin ? (
                <Tooltip
                  title={isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'}
                  aria-label={
                    isPinned ? 'Unpin from dashboard' : 'Pin to Dashboard'
                  }
                >
                  <Icon color={isPinned ? 'primary' : 'light'}>
                    <Pin
                      onClick={() => handlePinClick({ isPinned, chartName })}
                    />
                  </Icon>
                </Tooltip>
              ) : null}
            </div>
          </CardHeader>
          <CardBody chart>
            {data.datasets.length === 0 ? (
              <Typography center color="light25" size="xs">
                {this.client.includes('kcc-ru')
                  ? 'Нет данных для отображения'
                  : 'No data to display'}
              </Typography>
            ) : (
              <LineChart data={data} />
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

// component properties
LineChartComponent.propTypes = {
  classes: PropTypes.object,
  chartName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  granularities: PropTypes.object,
  slicers: PropTypes.object,
  isPinned: PropTypes.bool,
  useExport: PropTypes.bool,
  usePin: PropTypes.bool,
  handlePinClick: PropTypes.func.isRequired,
  handleGranularityClick: PropTypes.func.isRequired,
  handleSlicerChange: PropTypes.func,
  handleDataDownload: PropTypes.func.isRequired,
  cardStyles: PropTypes.object
};

LineChartComponent.defaultProps = {
  classes: {},
  granularities: {},
  slicers: {},
  handleSlicerChange: () => {},
  isPinned: false,
  useExport: false,
  usePin: false,
  cardStyles: {}
};

export default LineChartComponent;

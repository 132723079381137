const types = {
  filterChange: 'ACCURACY_FILTER_CHANGE',
  sessionChange: 'ACCURACY_SESSION_CHANGE',
  resetSessionDetails: 'RESET_SESSION_DETAILS',
  getSessionDetails: {
    request: 'GET_SESSION_DETAILS_REQUEST',
    success: 'GET_SESSION_DETAILS_SUCCESS',
    failure: 'GET_SESSION_DETAILS_FAILURE'
  },
  getSessionList: {
    request: 'GET_SESSION_LIST_REQUEST',
    success: 'GET_SESSION_LIST_SUCCESS',
    failure: 'GET_SESSION_LIST_FAILURE'
  },
  getFilters: {
    request: 'GET_ACCURACY_PAGE_FILTERS_REQUEST',
    success: 'GET_ACCURACY_PAGE_FILTERS_SUCCESS',
    failure: 'GET_ACCURACY_PAGE_FILTERS_FAILURE'
  },
  getChartList: {
    request: 'GET_ACCURACY_CHART_LIST_REQUEST',
    success: 'GET_ACCURACY_CHART_LIST_SUCCESS',
    failure: 'GET_ACCURACY_CHART_LIST_FAILURE'
  },
  getChartData: {
    request: 'GET_ACCURACY_CHART_DATA_REQUEST',
    success: 'GET_ACCURACY_CHART_DATA_SUCCESS',
    failure: 'GET_ACCURACY_CHART_DATA_FAILURE'
  },
  getScheduledReports: {
    request: 'GET_SCHEDULED_REPORTS_REQUEST',
    success: 'GET_SCHEDULED_REPORTS_SUCCESS',
    failure: 'GET_SCHEDULED_REPORTS_FAILURE'
  },
  getScheduledReportsMetaData: {
    request: 'GET_SCHEDULED_REPORTS_META_DATA_REQUEST',
    success: 'GET_SCHEDULED_REPORTS_META_DATA_SUCCESS',
    failure: 'GET_SCHEDULED_REPORTS_META_DATA_FAILURE'
  },
  postScheduledReport: {
    request: 'POST_SCHEDULED_REPORT_REQUEST',
    success: 'POST_SCHEDULED_REPORT_SUCCESS',
    failure: 'POST_SCHEDULED_REPORT_FAILURE'
  },
  updateScheduledReport: {
    request: 'UPDATE_SCHEDULED_REPORT_REQUEST',
    success: 'UPDATE_SCHEDULED_REPORT_SUCCESS',
    failure: 'UPDATE_SCHEDULED_REPORT_FAILURE'
  },
  deleteScheduledReport: {
    request: 'DELETE_SCHEDULED_REPORT_REQUEST',
    success: 'DELETE_SCHEDULED_REPORT_SUCCESS',
    failure: 'DELETE_SCHEDULED_REPORT_FAILURE'
  },
  downloadReport: {
    request: 'DOWNLOAD_REPORT_REQUEST',
    success: 'DOWNLOAD_REPORT_SUCCESS',
    failure: 'DOWNLOAD_REPORT_FAILURE'
  }
};

export default types;

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { cacheTypes, userTypes } from 'redux-utils/types';

const initialState = {
  isPageLoading: false,
  profile: {},
  preference: {
    isLoading: false,
    prefs: {}
  },
  error: {
    state: false,
    type: ''
  }
};

const reducer = createReducer(initialState, {
  [userTypes.getUserPreference.success]: (state, action) => {
    const { payload } = action;
    state.preference.prefs = payload.response.data;
  },
  [userTypes.getUserProfile.request]: state => {
    state.isPageLoading = true;
  },
  [userTypes.getUserProfile.success]: (state, action) => {
    state.isPageLoading = false;
    state.profile = action.payload.response.data;
  },
  [userTypes.getUserProfile.failure]: (state, action) => {
    state.isPageLoading = false;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [userTypes.saveUserPreference.request]: state => {
    state.isPageLoading = true;
    state.preference.isLoading = true;
  },
  [userTypes.saveUserPreference.success]: state => {
    state.isPageLoading = false;
    state.preference.isLoading = false;
  },
  [userTypes.saveUserPreference.failure]: (state, action) => {
    state.isPageLoading = false;
    state.preference.isLoading = false;
    state.error = {
      state: true,
      type: action.type
    };
  },
  [cacheTypes.set.request]: (state, action) => {
    const { payload } = action;
    const dispatchAction = {
      type: payload.targetAction,
      payload
    };
    return reducer(state, dispatchAction);
  }
});

export default reducer;

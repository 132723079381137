/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Dropdown from 'components/Dropdown/Dropdown';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import InputField from 'components/TextField/TextField';
import Loader from 'components/Loader/Loader';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
// colors
import colors from 'styles/__variables';

const TextField = ({ label, value, width }) => (
  <InputField
    inputProps={{
      readOnly: true
    }}
    colorTheme="dark"
    id={label}
    width={width}
    type="name"
    label={label}
    placeholder={label}
    margin="normal"
    value={value}
    variant="outlined"
  />
);

const DropdownField = ({ title, options, value, handleValueChange, width }) => (
  <Dropdown
    width={width}
    title={title}
    handleValueChange={val => handleValueChange(val)}
    options={options}
    titleColor={colors.dark.light26}
    value={value}
  />
);

DropdownField.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DropdownField.defaultProps = {
  width: '100%'
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextField.defaultProps = {
  width: '100%'
};

const styles = {
  saveButton: {
    fontSize: 13,
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: 6,
    width: 105,
    height: 35,
    padding: 0,
    margin: 0
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    background: 'transparent',
    transform: 'translate(-50%,-50%)'
  }
};

const UserProfile = ({
  classes,
  profile,
  handleFilterChange,
  handlePreferenceSave,
  prefsList,
  isLoading
}) => (
  <>
    {isLoading ? (
      <Loader
        className={classes.loader}
        circular
        centered
        color="secondary"
        inline
      />
    ) : null}
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography color={colors.dark.light24}>User Profile</Typography>
            </CardHeader>
            <CardBody height={500}>
              <GridContainer className={classes.profile}>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField label="Company" value={profile.project} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField label="Username" value={profile.username} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField label="Email address" value={profile.email} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField label="First Name" value={profile.first_name} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField label="Last Name" value={profile.last_name} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography color={colors.dark.light24}>Preferences</Typography>
            </CardHeader>
            <CardBody height={500}>
              <GridContainer className={classes.profile}>
                {prefsList.map(item =>
                  item.value ? (
                    <GridItem key={item.key} xs={12} sm={12} md={4}>
                      <DropdownField
                        title={item.name}
                        options={item.options}
                        value={item.value}
                        handleValueChange={val => {
                          handleFilterChange({
                            value: {
                              ...val,
                              f: item.key
                            }
                          });
                        }}
                      />
                      <VSpacer />
                    </GridItem>
                  ) : null
                )}
              </GridContainer>
              <VSpacer amount={30} />
              <Button
                className={classes.saveButton}
                onClick={handlePreferenceSave}
              >
                Save
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  </>
);

UserProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handlePreferenceSave: PropTypes.func.isRequired,
  prefsList: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default withStyles(styles)(UserProfile);

import React from 'react';
// import GaugeChart from 'react-gauge-chart';
import withStyles from '@material-ui/core/styles/withStyles';
// import PerformanceTableContainer from './PerformanceTableContainer';

const styles = {};

const tabsData = {
  enable: true,
  tabs: [
    {
      title: 'Photo Quality',
      key: 'photo-quality',
      has_chart: true,
      has_table: true,
      performance_table: {
        title: 'Photo Quality in %',
        filters: [
          {
            label: 'Supervisor',
            key: 'gr_supervisor',
            options: [
              {
                label: 'Supervisor 1',
                value: '27362'
              },
              {
                label: 'Supervisor 2',
                value: '27363'
              },
              {
                label: 'Supervisor 3',
                value: '27378'
              }
            ]
          },
          {
            label: 'Channel',
            key: 'gr_channel',
            options: [
              {
                label: 'Hypermarket',
                value: 'hypermarket'
              },
              {
                label: 'Modern Trade',
                value: 'modern-trade'
              }
            ]
          }
        ]
      }
    },
    {
      title: 'SOS Compliance',
      key: 'sos-compliance',
      has_chart: true,
      has_table: true,
      performance_table: {
        title: 'SOS Compliance in %',
        filters: [
          {
            label: 'Supervisor',
            key: 'gr_supervisor',
            options: [
              {
                label: 'Supervisor 1',
                value: '27362'
              },
              {
                label: 'Supervisor 2',
                value: '27363'
              },
              {
                label: 'Supervisor 3',
                value: '27378'
              }
            ]
          },
          {
            label: 'Channel',
            key: 'gr_channel',
            options: [
              {
                label: 'Hypermarket',
                value: 'hypermarket'
              },
              {
                label: 'Modern Trade',
                value: 'modern-trade'
              }
            ]
          }
        ]
      }
    },
    {
      title: 'OSA Compliance',
      key: 'osa-compliance',
      has_chart: true,
      has_table: true,
      performance_table: {
        title: 'OSA Compliance in %',
        filters: [
          {
            label: 'Supervisor',
            key: 'gr_supervisor',
            options: [
              {
                label: 'Supervisor 1',
                value: '27362'
              },
              {
                label: 'Supervisor 2',
                value: '27363'
              },
              {
                label: 'Supervisor 3',
                value: '27378'
              }
            ]
          },
          {
            label: 'Channel',
            key: 'gr_channel',
            options: [
              {
                label: 'Hypermarket',
                value: 'hypermarket'
              },
              {
                label: 'Modern Trade',
                value: 'modern-trade'
              }
            ]
          }
        ]
      }
    }
  ]
};

const tableData = {
  status: 200,
  data: {
    'photo-quality': {
      performance_table: {
        columns: [
          {
            dataKey: 'merchandizers',
            type: 'text',
            label: 'Merchandizers'
          },
          {
            dataKey: 'invalid',
            type: 'performance_number',
            label: 'Invalid',
            subheader: '(0)'
          },
          {
            dataKey: 'blur',
            type: 'performance_number',
            label: 'Blur',
            subheader: '(0)'
          },
          {
            dataKey: 'slant',
            type: 'performance_number',
            label: 'Slant',
            subheader: '(0)'
          },
          {
            dataKey: 'wrong_direction',
            type: 'performance_number',
            label: 'Wrong Direction',
            subheader: '(0)'
          },
          {
            dataKey: 'wrong_task',
            type: 'performance_number',
            label: 'Wrong Task',
            subheader: '(0)'
          }
        ],
        datasets: [
          {
            merchandizers: 'Max',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          },
          {
            merchandizers: 'John',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          },
          {
            merchandizers: 'Susan',
            invalid: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'invalid',
                    type: 'text',
                    label: 'INVALID %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    invalid: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    invalid: '20 %'
                  }
                ]
              }
            },
            blur: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'blur',
                    type: 'text',
                    label: 'BLUR %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    blur: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    blur: '20 %'
                  }
                ]
              }
            },
            slant: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'slant',
                    type: 'text',
                    label: 'Slant %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    slant: '20 %'
                  },
                  {
                    stores: 'Store 2',
                    slant: '30 %'
                  }
                ]
              }
            },
            wrong_direction: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_direction',
                    type: 'text',
                    label: 'WRONG DIRECTION %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_direction: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_direction: '20 %'
                  }
                ]
              }
            },
            wrong_task: {
              value: '17.4',
              color: '#f36',
              tooltip_data: {
                columns: [
                  {
                    dataKey: 'stores',
                    type: 'text',
                    label: 'Stores'
                  },
                  {
                    dataKey: 'wrong_task',
                    type: 'text',
                    label: 'WRONG TASK %'
                  }
                ],
                datasets: [
                  {
                    stores: 'Store 1',
                    wrong_task: '10 %'
                  },
                  {
                    stores: 'Store 2',
                    wrong_task: '20 %'
                  }
                ]
              }
            }
          }
        ]
      }
    }
  }
};

const apiRequestStatus = {
  getTableData: {
    status: 'success'
  }
};

const TestPage = () =>
  // <GaugeChart
  //   id="gauge-chart"
  //   nrOfLevels={20}
  //   colors={['#2c4ac7', '#f1f1f1']}
  //   arcWidth={0.3}
  //   percent={0.5}
  //   arcPadding={0}
  //   cornerRadius={0}
  // />
  // <PerformanceTableContainer
  //   tabsData={tabsData}
  //   tableData={tableData}
  //   apiRequestStatus={apiRequestStatus}
  //   handleTableFilterChange={data => console.log('filterChange', data)}
  // />
  null;

TestPage.propTypes = {};

export default withStyles(styles)(TestPage);

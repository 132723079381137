/* eslint-disable valid-jsdoc */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// redux utils
import { appImageActions } from 'redux-utils/actions';
import { appSelector } from 'redux-utils/selectors/index';
import { connect } from 'react-redux';
// javascript utility for conditionally joining classNames together
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
// core components
import Button from 'components/Button/Button';
import Menu from 'components/Menu/Menu';
// assets
import headerStyle from 'assets/jss/material-ui/components/headerStyle';
import { MenuIcon, PersonIcon, Home } from 'assets/icons/appIcons';
// storage
import ls from 'lib/core/storageFactory';

const generateRightMenuItems = ({
  routes,
  handleRouteClick,
  handleLogout,
  handleProfileClose
}) => {
  const client = ls.get('client') || '';
  const clientResponse = ls.get('clientResponse');
  const showResetPasswordLs = ls.get('showResetPassword');
  const menuItems = [];
  routes.map(route => {
    if (!route.redirect) {
      menuItems.push(
        <MenuItem key={route.path} onClick={() => handleRouteClick(route)}>
          {route.navbarName}
        </MenuItem>
      );
    }
    return route;
  });
  menuItems.push(
    <MenuItem key={1} onClick={() => handleProfileClose('logout')}>
      {client.includes('kcc-ru') ? 'Выйти из системы' : 'Logout'}
    </MenuItem>
  );
  try {
    if (showResetPasswordLs) {
      menuItems.push(
        <MenuItem key={2} onClick={() => handleProfileClose('reset')}>
          {client.includes('kcc-ru') ? 'Reset Password' : 'Reset Password'}
        </MenuItem>
      );
    }
  } catch (error) {
    handleLogout();
  }

  return menuItems;
};

/**
 * @class
 * @hideconstructor
 * @description Page AppHeader class component
 */

const AppHeader = ({
  classes,
  color,
  handleLogout,
  handleDrawerToggle,
  rightMenuRoutes,
  useHomeIcon,
  logoutUser,
  logoutRedirectionData
}) => {
  const history = useHistory();

  const [profEl, setProfEl] = useState(null);

  const handleProfileClick = event => {
    setProfEl(event.currentTarget);
  };

  const handleRouteClick = route => {
    history.push(route.path);
    setProfEl(null);
  };

  const handleHomeClick = () => {
    const { initializeAppImages } = this.props;
    history.push('/app');
    initializeAppImages();
    setProfEl(null);
  };

  const handleProfileClose = option => {
    if (option === 'logout') {
      handleLogout();
    }
    if (option === 'reset') {
      history.push('/reset_password');
    }
    setProfEl(null);
  };

  const appBarClasses = classNames({
    [` ${classes[color]}`]: color
  });
  const profileOpen = Boolean(profEl);
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Hidden
            className={classes.rightFlexContainer}
            smDown
            implementation="css"
          >
            <div>
              {useHomeIcon && (
                <Button
                  className={classes.menuButtonRight}
                  color="dark"
                  round
                  justIcon
                  onClick={handleHomeClick}
                >
                  <Home />
                </Button>
              )}
              <Button
                className={useHomeIcon ? '' : classes.menuButtonRightPerson}
                aria-haspopup="true"
                aria-owns={profileOpen ? 'profile-menu' : undefined}
                aria-label="open profile"
                color="dark"
                round
                justIcon
                onClick={handleProfileClick}
              >
                <PersonIcon />
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              className={classes.menuButton}
              aria-label="open drawer"
              color="dark"
              round
              justIcon
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </Button>
          </Hidden>
          <Menu
            id="menu-profile"
            anchorEl={profEl}
            handleClose={handleProfileClose}
          >
            {generateRightMenuItems({
              routes: rightMenuRoutes,
              handleProfileClose: handleProfileClose,
              handleRouteClick: handleRouteClick,
              handleLogout
            }).map(RightMenu => RightMenu)}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

// component properties
AppHeader.propTypes = {
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  classes: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description browser history object
   */
  history: PropTypes.object.isRequired,
  /**
   * @type {object}
   * @description Class names of the styles generated with jss
   */
  rightMenuRoutes: PropTypes.array.isRequired,

  useHomeIcon: PropTypes.bool,
  /**
   * @type {string}
   * @description App bar color
   */
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    ''
  ]),
  /**
   * @type {function}
   * @description Handler function to handle the drawer toggle on md devices
   */
  handleDrawerToggle: PropTypes.func,
  /**
   * @type {function}
   * @description Handler function to handle the user logout
   */
  handleLogout: PropTypes.func.isRequired,
  /**
   * @type {function}
   * @description Redux function to initialize the app images state
   */
  initializeAppImages: PropTypes.func.isRequired
};

AppHeader.defaultProps = {
  color: '',
  handleDrawerToggle: () => {},
  useHomeIcon: false
};

const mapDispatchToProps = dispatch => {
  return {
    initializeAppImages: payload =>
      dispatch(appImageActions.initializeAppImages(payload))
  };
};
const mapStateToProps = state => ({
  logoutRedirectionData: appSelector.getLogoutRedirectionData(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(headerStyle)(AppHeader));

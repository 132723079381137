/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
// core functions
import ls from 'lib/core/storageFactory';

const cookies = new Cookies();

const PrivateRoute = React.memo(({ component: Component, ...rest }) => {
  if (window.location.pathname !== '/login') {
    if (ls.get('isLoggedIn') === true) {
      return (
        <Route
          {...rest}
          render={props => (
            <>
              {(function() {
                if (ls.get('authProfile')) {
                  window.FreshworksWidget('hide', 'ticketForm', ['name']);
                  if (ls.get('authProfile').email) {
                    window.FreshworksWidget('disable', 'ticketForm', ['email']);
                    window.FreshworksWidget('identify', 'ticketForm', {
                      email: ls.get('authProfile').email
                    });
                  }
                }
              })()}
              <Component {...props} />
            </>
          )}
        />
      );
    }

    cookies.set('prevPath', rest.location.pathname, {
      path: '/',
      domain: `.${process.env.REACT_APP_SHELF_REDIRECT_URI}`,
      sameSite: 'none',
      secure: 'true'
    });

    if (process.env.REACT_APP_SHELF_DEPLOYMENT_ENV === 'local') {
      window.location.href = `http://${process.env.REACT_APP_SHELF_REDIRECT_URI}/login`;
    } else {
      window.location.href = `https://${process.env.REACT_APP_SHELF_REDIRECT_URI}/login`;
    }
  } else {
    return <>Something</>;
  }
});

export default PrivateRoute;

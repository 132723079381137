/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useEffect, useRef, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, throttle } from 'lodash';
import { Stage, Layer } from 'react-konva';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// views
import StitchedImage from './StitchedImage';
import CropTooltip from './CropTooltip';

const styles = {
  wrapper: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(65, 65, 66) transparent',
    '&::-webkit-scrollbar': {
      display: 'block',
      height: '10px',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(65, 65, 66)',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },
  canvas: {
    display: 'flex'
  },
  cropDetails: {
    width: '10vw',
    maxWidth: '22vw',
    border: '1px dotted',
    marginLeft: 10,
    height: 'fit-content',
    overflow: 'auto',
    padding: '20px 5px'
  },
  item: {
    display: 'flex',
    '& h4': {
      width: 200,
      margin: '0',
      whiteSpace: 'pre-line'
    },
    '& img': {
      maxHeight: 160,
      maxWidth: '100%',
      paddingTop: 10
    }
  }
};

const ShelfCanvas = ({
  activeControls,
  colors,
  compareVersions,
  classes,
  canvasHeight,
  canvasWidth,
  dispatch,
  list,
  reducerState,
  wrapperRef
}) => {
  const stageRef = useRef(null);
  const layerRef = useRef(null);

  const [cropTooltipState, setCropTooltipState] = useState({
    isOpen: false,
    data: undefined
  });
  const [userScroll, setUserScroll] = useState(false);
  const throttledMouseEvents = useRef(throttle(q => handleMouseEvents(q), 800))
    .current;

  const handleMouseEvents = useCallback(e => {
    setUserScroll(e);
  }, []);

  useEffect(() => {
    if (!userScroll) {
      const { current: canvasRef } = wrapperRef;
      canvasRef.scrollTo({
        top: 0,
        left: reducerState.shelfCanvas.activeArea[0],
        behavior: 'smooth'
      });
    }
  }, [reducerState.shelfCanvas, wrapperRef, userScroll]);

  useEffect(() => {
    if (activeControls.reset) {
      resetImageToDefault();
    }
  }, [activeControls.reset]);

  const resetImageToDefault = () => {
    const { current: stage } = stageRef;
    const { current: layer } = layerRef;
    stage.to({
      scaleX: 1,
      scaleY: 1,
      x: 0,
      y: 0,
      duration: 0.2
    });
    layer.to({
      scaleX: 1,
      scaleY: 1,
      x: 0,
      y: 0,
      duration: 0.2
    });
  };

  const handleStageMouseWheel = e => {
    if (activeControls.zoomIn) {
      const { current: stage } = stageRef;
      const scaleBy = 1.04;
      e.evt.preventDefault();
      const oldScale = stage.scaleX();

      const pointer = stage.getPointerPosition();

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale
      };

      const newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  };

  const handleCanvasScroll = ({ event, toDispatch }) => {
    if (event && toDispatch) {
      const { target } = event;
      dispatch({
        payload: { event: target },
        type: 'SHELF_CANVAS_SCROLL_EVENT'
      });
    }
  };

  const throttledScroll = useCallback(
    useRef(debounce(q => handleCanvasScroll(q), 0)).current
  );

  const [mouseEvent, setMouseEvent] = useState(null);

  useEffect(() => {
    throttledScroll({
      event: mouseEvent,
      toDispatch: userScroll
    });
  }, [mouseEvent, userScroll, throttledScroll]);

  useEffect(() => {
    const { current: canvasRef } = wrapperRef;
    const scrollListener = canvasRef.addEventListener('scroll', e => {
      setMouseEvent(e);
    });
    return () => {
      canvasRef.removeEventListener('scroll', scrollListener);
    };
  }, [wrapperRef]);

  const handleBoxClick = ({ box }) => {
    setCropTooltipState({ isOpen: true, data: box.details });
  };

  const handleCropTooltipClose = bool => {
    setCropTooltipState({ isOpen: bool, data: undefined });
  };

  return (
    <div style={{ position: 'relative' }}>
      <CropTooltip
        activeCropData={cropTooltipState.data}
        enablePreviousVersionCol={compareVersions}
        imageClickedStatus={cropTooltipState.isOpen}
        setImageClickedStatus={handleCropTooltipClose}
      >
        <div className={classes.canvas}>
          <div ref={wrapperRef} className={classes.wrapper}>
            <Stage
              ref={stageRef}
              onWheel={handleStageMouseWheel}
              width={canvasWidth}
              height={canvasHeight}
            >
              <Layer
                ref={layerRef}
                draggable={activeControls.zoomIn}
                onDragMove={() => {}}
                onDragEnd={() => {}}
                onMouseOver={() => throttledMouseEvents(true)}
                onMouseLeave={() => throttledMouseEvents(false)}
              >
                <StitchedImage
                  scaleDownFactor={1}
                  colors={colors}
                  handleBoxClick={handleBoxClick}
                  photoList={list}
                />
              </Layer>
            </Stage>
          </div>
        </div>
      </CropTooltip>
    </div>
  );
};

ShelfCanvas.propTypes = {
  activeControls: PropTypes.object,
  compareVersions: PropTypes.bool,
  colors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  reducerState: PropTypes.object.isRequired,
  wrapperRef: PropTypes.any.isRequired
};

ShelfCanvas.defaultProps = {
  activeControls: {
    zoomIn: true,
    reset: false
  },
  compareVersions: false
};

export default withStyles(styles)(memo(ShelfCanvas));

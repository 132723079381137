/* eslint-disable import/no-extraneous-dependencies */
require('dotenv').config();
const packageJson = require('../package.json');

const metadata = {
  version: packageJson.version,
  build: 1,
  releaseEnvironment: process.env.REACT_APP_SHELF_RELEASE_STAGE,
  deploymentEnvironment: process.env.REACT_APP_SHELF_DEPLOYMENT_ENV,
  releaseDate: ''
};

module.exports = metadata;

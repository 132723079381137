import { transition } from '../material-ui';

const dashboardStyle = theme => ({
  root: {
    display: 'flex'
  },
  analyticsContainer: {
    height: '91vh',
    overflow: 'hidden'
  },
  container: {
    height: '91vh',
    overflow: 'auto',
    position: 'relative'
    // top: '-60px'
  },
  lightThemeContainer: {
    height: '91vh',
    backgroundColor: 'white'
    // height: '82.5vh',
    // width: '89%'
  },
  content: {
    flexGrow: 1,
    paddingLeft: '0.1rem',
    // position: 'relative',
    // paddingBottom: '50px',
    overflow: 'hidden',
    height: '95.5vh'
  },
  lightThemeContent: {
    flexGrow: 1,
    paddingLeft: '0.1rem',
    // position: 'relative',
    // paddingBottom: '50px',
    overflow: 'hidden'
    // height: 'inherit'
  },
  adminSideBar: {
    '& div[class*="MuiDrawer-paper"]': {
      backgroundColor: '#f5f5f5 !important'
    }
  },
  toolbar: theme.mixins.toolbar,
  toolbarContainer: {
    minHeight: '0px !important'
  },
  analyticsPanel: {
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${theme.props.sideBarMini}px)`,
      width: '100%',
      padding: '1rem',
      paddingRight: '0.6rem',
      paddingBottom: 0,
      height: '100vh',
      overflowY: 'hidden !important'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '1rem'
    },
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
    height: '100vh',
    overflowY: 'hidden !important',
    ...transition
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${theme.props.sideBarMini}px)`,
      width: 'calc(100% - 0px)',
      // width: 'calc(100% - 250px)',
      padding: '1rem',
      paddingRight: '0.6rem',
      paddingBottom: 0,
      height: '100vh',
      overflowY: 'hidden !important'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '1rem'
    },
    position: 'relative',
    maxHeight: '100%',
    width: 'calc(100% - 250px)',
    height: '100vh',
    overflowY: 'hidden !important',
    ...transition
  },
  adminPanel: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0'
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  }
});

export default dashboardStyle;

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
// action types
import { merchTypes as types } from 'redux-utils/types/index';
import { map } from 'lodash';

import enums from 'models/enums';
import { getFilterValue } from 'utils/filters';

// initial reducer state
const initialState = {
  activeSession: {},
  cumulativeCards: [],
  downloadReport: undefined,
  filters: {
    list: {},
    currentState: {}
  },
  pageComponents: {},
  metricTabs: {},
  performanceTable: {},
  scheduledReports: [],
  scheduledReportsMetaData: {},
  apiRequestStatus: {
    getCumulativeCards: { status: enums.apiRequest.IDLE },
    getPageComponents: { status: enums.apiRequest.IDLE },
    getPageFilters: { status: enums.apiRequest.IDLE },
    getTableData: { status: enums.apiRequest.IDLE },
    getMetricTabs: { status: enums.apiRequest.IDLE },
    downloadReport: { status: enums.apiRequest.IDLE },
    getScheduledReports: { status: enums.apiRequest.IDLE },
    getScheduledReportsMetaData: { status: enums.apiRequest.IDLE },
    postScheduledReport: { status: enums.apiRequest.IDLE },
    updateScheduledReport: { status: enums.apiRequest.IDLE },
    deleteScheduledReport: { status: enums.apiRequest.IDLE }
  }
};

const reducer = createReducer(initialState, {
  [types.filterChange]: (state, action) => {
    const { payload } = action;
    const updatesState = {
      ...state.filters.currentState,
      ...payload
    };
    if (payload.fl_me_region && payload.fl_me_region.value.fl_me_state) {
      const stateFilter = payload.fl_me_region.value.fl_me_state;
      updatesState.fl_me_state = {
        ...stateFilter,
        value: getFilterValue({
          options: stateFilter.options
        })
      };
    }
    state.filters.currentState = updatesState;
    state.pageComponents = {};
  },
  [types.sessionChange]: (state, action) => {
    const { payload } = action;
    state.activeSession = payload;
  },
  [types.getFilters.request]: state => {
    state.filters.list = {};
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getFilters.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.filters.list = response.data;
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.SUCCESS
    };
    // logic to set the default value for filters on page load
    const initialFilterState = {};
    map(response.data, (data, filterID) => {
      if (data && data.options) {
        const defaultOption = getFilterValue({
          options: data.options || []
        });
        initialFilterState[filterID] = {
          ...data,
          value: defaultOption
        };
        if (filterID === 'fl_me_region') {
          initialFilterState.fl_me_state = {
            ...defaultOption.fl_me_state,
            value: getFilterValue({
              options: defaultOption.fl_me_state.options || []
            })
          };
        }
      }
    });
    state.filters.currentState = initialFilterState;
  },
  [types.getFilters.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.filters.list = {};
    state.apiRequestStatus.getPageFilters = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getPageComponents.request]: state => {
    state.pageComponents = {};
    state.apiRequestStatus.getPageComponents = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getPageComponents.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.pageComponents = response.data;
    state.apiRequestStatus.getPageComponents = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getPageComponents.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.pageComponents = {};
    state.apiRequestStatus.getPageComponents = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getCumulativeCards.request]: state => {
    state.cumulativeCards = [];
    state.apiRequestStatus.getCumulativeCards = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getCumulativeCards.success]: (state, action) => {
    const {
      payload: { response }
    } = action;
    state.cumulativeCards = response.data.cumulative_cards;
    state.apiRequestStatus.getCumulativeCards = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getCumulativeCards.failure]: (state, action) => {
    const {
      payload: { error }
    } = action;
    state.cumulativeCards = [];
    state.apiRequestStatus.getCumulativeCards = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getMetricTabs.request]: state => {
    state.metricTabs = {};
    state.apiRequestStatus.getMetricTabs = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getMetricTabs.success]: (state, action) => {
    const { response } = action.payload;
    state.metricTabs = response.data;
    state.apiRequestStatus.getMetricTabs = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getMetricTabs.failure]: (state, action) => {
    const { error } = action.payload;
    state.metricTabs = {};
    state.apiRequestStatus.getMetricTabs = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getPerformanceTable.request]: state => {
    state.performanceTable = {};
    state.apiRequestStatus.getTableData = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getPerformanceTable.success]: (state, action) => {
    const { response } = action.payload;
    state.performanceTable = response.data;
    state.apiRequestStatus.getTableData = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getPerformanceTable.failure]: (state, action) => {
    const { error } = action.payload;
    state.performanceTable = {};
    state.apiRequestStatus.getTableData = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.downloadReport.request]: state => {
    state.downloadReport = undefined;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.downloadReport.success]: (state, action) => {
    const { response } = action.payload;
    state.downloadReport = response;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.downloadReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.downloadReport = undefined;
    state.apiRequestStatus.downloadReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getScheduledReports.request]: state => {
    state.scheduledReports = [];
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getScheduledReports.success]: (state, action) => {
    const { response } = action.payload;
    state.scheduledReports = response.data;
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getScheduledReports.failure]: (state, action) => {
    const { error } = action.payload;
    state.scheduledReports = [];
    state.apiRequestStatus.getScheduledReports = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.getScheduledReportsMetaData.request]: state => {
    state.scheduledReportsMetaData = {};
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.REQUEST
    };
  },
  [types.getScheduledReportsMetaData.success]: (state, action) => {
    const { response } = action.payload;
    state.scheduledReportsMetaData = response.data;
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.SUCCESS
    };
  },
  [types.getScheduledReportsMetaData.failure]: (state, action) => {
    const { error } = action.payload;
    state.scheduledReportsMetaData = {};
    state.apiRequestStatus.getScheduledReportsMetaData = {
      status: enums.apiRequest.FAILURE,
      error
    };
  },
  [types.postScheduledReport.request]: state => {
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.postScheduledReport = undefined;
  },
  [types.postScheduledReport.success]: (state, action) => {
    const { response } = action.payload;
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    if (response.data.is_success) {
      state.scheduledReports.push({
        filters: action.payload.filters,
        subscribed_users: action.payload.subscribed_users,
        frequency: action.payload.frequency,
        name: action.payload.name,
        first_report_date: action.payload.first_report_date,
        due_date: response.data.due_date,
        id: response.data.report_id
      });
    }
    state.postScheduledReport = response;
  },
  [types.postScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.postScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.postScheduledReport = undefined;
  },
  [types.updateScheduledReport.request]: state => {
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.updateScheduledReport = undefined;
  },
  [types.updateScheduledReport.success]: (state, action) => {
    const { response } = action.payload;
    if (response.data.is_success) {
      state.scheduledReports = state.scheduledReports.map(report => {
        if (report.id === action.payload.report_id) {
          return {
            filters: action.payload.filters,
            subscribed_users: action.payload.subscribed_users,
            frequency: action.payload.frequency,
            name: action.payload.name,
            first_report_date: action.payload.first_report_date,
            due_date: response.data.due_date,
            id: action.payload.report_id
          };
        }
        return report;
      });
    }
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    state.updateScheduledReport = response;
  },
  [types.updateScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.updateScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.updateScheduledReport = undefined;
  },
  [types.deleteScheduledReport.request]: state => {
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.REQUEST
    };
    state.deleteScheduledReport = undefined;
  },
  [types.deleteScheduledReport.success]: (state, action) => {
    const { response, report_id: reportId } = action.payload;
    let updatedScheduledReports = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const id of reportId) {
      updatedScheduledReports = [
        ...updatedScheduledReports,
        ...state.scheduledReports.filter(report => report.id !== id)
      ];
    }
    state.scheduledReports = updatedScheduledReports;
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.SUCCESS
    };
    state.deleteScheduledReport = response;
  },
  [types.deleteScheduledReport.failure]: (state, action) => {
    const { error } = action.payload;
    state.apiRequestStatus.deleteScheduledReport = {
      status: enums.apiRequest.FAILURE,
      error
    };
    state.deleteScheduledReport = undefined;
  }
});

export default reducer;

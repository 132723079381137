import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// assets
import resetSvg from 'assets/svg/reset.svg';
import zoomInSVG from 'assets/svg/zoom_in.svg';

const defaultControls = {
  zoomIn: {
    key: 'zoomIn',
    src: zoomInSVG,
    useActive: true
  },
  reset: {
    key: 'reset',
    src: resetSvg,
    useActive: false
  }
};

const styles = {
  toolBar: {
    marginLeft: 'auto',
    border: '1px solid #585454',
    height: 40,
    display: 'flex',
    background: '#3c3c40',
    alignItems: 'baseline',
    borderRadius: 3,
    justifyContent: 'space-between'
  },
  svgWrapper: {
    width: 50,
    height: '100%',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      background: '#282829'
    },
    '& svg': {
      height: 16,
      width: 'auto',
      outline: 'none'
    }
  },
  activeControl: {
    outline: 'none',
    background: '#000'
  }
};

const ImageControls = ({
  className,
  classes,
  handleControls,
  activeControls,
  controls
}) => {
  const actions = controls.map(item => defaultControls[item]);
  return (
    <div className={`${className} ${classes.toolBar}`}>
      {actions.map(item => (
        <Tooltip key={item.key} title={item.key}>
          <ReactSVG
            className={`${classes.svgWrapper} ${
              item.useActive && activeControls[item.key] === true
                ? classes.activeControl
                : ''
            }`}
            tabIndex={0}
            role="button"
            onKeyDown={() =>
              handleControls({
                e: item.key,
                isActive: activeControls[item.key]
              })
            }
            onClick={() =>
              handleControls({
                e: item.key,
                isActive: activeControls[item.key]
              })
            }
            src={item.src}
          />
        </Tooltip>
      ))}
    </div>
  );
};

ImageControls.propTypes = {
  classes: PropTypes.object.isRequired,
  handleControls: PropTypes.func.isRequired,
  activeControls: PropTypes.object.isRequired,
  controls: PropTypes.array,
  className: PropTypes.string
};

ImageControls.defaultProps = {
  className: '',
  controls: []
};

export default withStyles(styles)(ImageControls);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { Layer, Rect, Stage } from 'react-konva';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/Button/Button';
import { VSpacer } from 'components/Spacer/Spacer';
// pages
import StitchedImage from './StitchedImage';

const styles = {
  wrapper: {
    overflow: 'auto',
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  button: {
    background: '#2a2a3c'
  },
  colorItem: {
    display: 'flex',
    alignItems: 'center'
  },
  color: {
    width: 30,
    height: 30,
    margin: '0 10px'
  }
};

const colorMaps = [
  { label: 'Shelf', color: '#673ab7' },
  { label: 'Display', color: '#F57C00' },
  { label: 'Correctly identified', color: 'rgba(200, 204, 3, 0.3)' },
  { label: 'Ground truth mismatch', color: 'rgba(255, 51, 102, 0.3)' },
  { label: 'Exclusions or Overlaps', color: 'rgba(0, 93, 184, 0.3)' },
  { label: 'Selected crop', color: 'rgba(0, 189, 211, 0.3)' }
];

const HtmlTooltip = withStyles(() => ({
  popper: {
    zIndex: 2,
    top: '40px !important'
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '3px',
    '& p': {
      fontSize: '13px'
    },
    padding: '8px 21px',
    maxWidth: 800,
    width: 'auto',
    height: 'auto',
    maxHeight: '350px !important',
    overflow: 'auto !important'
  }
}))(Tooltip);

const Minimap = ({
  classes,
  colors,
  canvasHeight,
  canvasWidth,
  dispatch,
  list,
  reducerState,
  scaleDownFactor,
  wrapperRef
}) => {
  const throttledEvents = useRef(throttle(q => handleRectDrag(q), 300)).current;
  const [rectCoords, setRectCoords] = useState([0, 0]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!reducerState.minimap.minimapDragging) {
      const [rectStart, rectEnd] = reducerState.minimap.activeArea;
      setRectCoords([rectStart, rectEnd]);
    }
  }, [reducerState.minimap]);

  const handleRectDrag = ({
    position: { x },
    rectEnd: end,
    rectStart: start
  }) => {
    dispatch({
      payload: {
        activeArea: [x, end + x - start],
        scaleDownFactor
      },
      type: 'MINIMAP_WINDOW_DRAG'
    });
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Stage width={canvasWidth} height={canvasHeight}>
        <Layer>
          <StitchedImage
            scaleDownFactor={scaleDownFactor}
            colors={colors}
            photoList={list}
          />
          <Rect
            x={rectCoords[0]}
            y={0}
            width={rectCoords[1] - rectCoords[0]}
            height={canvasHeight}
            stroke="#ffee58"
            draggable
            onDragMove={() => {}}
            onDragEnd={() => {}}
            dragBoundFunc={({ x }) => {
              const minPos = 0;
              const maxPos = canvasWidth - (rectCoords[1] - rectCoords[0]);
              const position = {
                x: x <= minPos ? 0 : x >= maxPos ? maxPos : x,
                y: 0
              };
              throttledEvents({
                position,
                rectEnd: rectCoords[1],
                rectStart: rectCoords[0]
              });
              return position;
            }}
          />
        </Layer>
      </Stage>
      <div className={classes.colorMaps}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          title={
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <VSpacer amount={5} />
                {colorMaps.map(({ label, color }) => (
                  <>
                    <div
                      className={`${classes.flex} ${classes.colorItem}`}
                      key={label}
                    >
                      <span
                        className={classes.color}
                        style={{
                          color,
                          background: color,
                          border: '2px solid'
                        }}
                      />
                      <p>{label}</p>
                    </div>
                    <VSpacer amount={5} />
                  </>
                ))}
              </div>
            </ClickAwayListener>
          }
        >
          <div>
            <Button
              color="gray"
              className={classes.button}
              onClick={() => handleTooltipOpen()}
            >
              Color Reference
            </Button>
          </div>
        </HtmlTooltip>
      </div>
    </div>
  );
};

Minimap.propTypes = {
  classes: PropTypes.object.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  reducerState: PropTypes.object.isRequired,
  scaleDownFactor: PropTypes.number.isRequired,
  wrapperRef: PropTypes.any.isRequired
};

export default withStyles(styles)(Minimap);

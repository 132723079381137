import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import tooltipStyles from 'assets/jss/material-ui/components/tooltipStyle';

class CustomTooltip extends React.Component {
  state = {
    arrowRef: null
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  render() {
    const { classes, content, open } = this.props;

    const Title = (
      <>
        {content}
        <span className={classes.arrow} ref={this.handleArrowRef} />
      </>
    );

    return (
      <div>
        <Tooltip
          title={Title}
          classes={{ popper: classes.arrowPopper }}
          open={open}
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(this.state.arrowRef),
                  element: this.state.arrowRef
                }
              }
            }
          }}
        />
      </div>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(tooltipStyles)(CustomTooltip);
